import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../modal-wrapper/ModalWrapper'
import './delete-modal.scss'

interface ConfirmDeleteI {
  open: boolean
  onClose: () => void
  onYes: () => void
  title?: string
  text?: string
  noTitle?: boolean
}
const ConfirmDelete: FC<ConfirmDeleteI> = ({
  open,
  onClose,
  onYes,
  text,
  title,
  noTitle,
}) => {
  return (
    <ModalWrapper open={open} handleClose={onClose} wide>
      <div className="d-flex justify-content-center align-items-center flex-column delete-modal">
        {!noTitle && (
          <div className="w-100">
            <h1 className="text-center delete-modal__main mb-3">{`Delete ${title}?`}</h1>
          </div>
        )}
        <p className="delete-modal__title">
          Are you sure you want to delete {text}?
        </p>
        <div>
          <Button color="success" className="modal-button" onClick={onClose}>
            No
          </Button>
          <Button color="success" className="modal-button" onClick={onYes}>
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmDelete
