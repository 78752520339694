import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Checkbox, IconButton } from '@mui/material'
import {
  DrawingManager,
  GoogleMap,
  LoadScriptProps,
  Polygon,
  useJsApiLoader,
} from '@react-google-maps/api'
import _ from 'lodash'
import { FC, useEffect, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { BiPlus } from 'react-icons/bi'
import { GrFormClose } from 'react-icons/gr'
import { Col, Row } from 'reactstrap'
import * as Yup from 'yup'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { GeneralObj } from '../../../../types/global.type'
import { api, checkNumbersOnly } from '../../../../utils'
import './add-new-area-modal.scss'
import { GetZipCodeRes } from './zip-code.type'

interface AddNewAreaModalIProps {
  open: boolean
  onClose: () => void
  onAdd?: () => void
}

interface FormValues {
  marketId: string
  marketName: string
  isAcceptingParents: boolean
  zipCode: { value: string }[]
  type: 'map' | 'zipCode' | ''
}

const defaultValues: FormValues = {
  marketId: '',
  marketName: '',
  isAcceptingParents: true,
  zipCode: [],
  type: '',
}

const LIBRARIES: LoadScriptProps['libraries'] = ['drawing']

interface DefaultState {
  center: GeneralObj | undefined
  allCoord: any[]
  backendFormat: any[]
  isAdded: boolean
  zipName: string
  previousValue: string
  isRemoved: boolean
  currentRecord: string
  latLngArray: number[][]
}

const defaultState: DefaultState = {
  center: undefined,
  allCoord: [],
  backendFormat: [],
  isAdded: false,
  zipName: '',
  previousValue: '',
  isRemoved: false,
  currentRecord: '',
  latLngArray: [],
}

const AddNewAreaModal: FC<AddNewAreaModalIProps> = ({
  open,
  onClose,
  onAdd,
}) => {
  const [typeAsForm, setTypeAsForm] = useState<'' | 'map' | 'zipCode'>('')
  let schema = Yup.object({
    marketId: Yup.string()
      .max(5)
      .required('Market id is required.')
      .label('Market id'),
    marketName: Yup.string()
      .matches(/^[a-zA-Z0-9-_ ]*$/, {
        message: 'Market name is invalid.',
      })
      .required('Market name is required.'),
    isAcceptingParents: Yup.bool().label('Is Accepting Parents'),
    type: Yup.string().required().label('Type'),
  })

  const zipCode = Yup.array()
    .of(
      Yup.object({
        value: Yup.string()
          .required('Zip code is required.')
          .min(5, 'Minimum length of zip code is 5.')
          .max(5),
      }),
    )
    .min(1)
    .label('Zip Code')

  if (typeAsForm === 'zipCode') {
    schema = schema.shape({
      zipCode,
    })
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onChange',
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'zipCode',
  })
  const { updateAppState } = useAppContext()
  const polygonRef = useRef<google.maps.Polygon>()
  const listenerRef = useRef<google.maps.MapsEventListener[]>([])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAnjAy4DscTX_L1AWbofjMUZuM050DLjcw',
    libraries: LIBRARIES,
  })
  const [state, setState] = useState(defaultState)
  const type = watch('type')

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { longitude, latitude } }) => {
        setState((prev) => ({
          ...prev,
          center: { lng: longitude, lat: latitude },
        }))
      },
    )
  }, [])

  useEffect(() => {
    setTypeAsForm(type)
  }, [type])

  const updateState = (newState: Partial<DefaultState>) => {
    setState((prev) => ({ ...prev, ...newState }))
  }

  const onSubmit = async (values: FormValues) => {
    const { isAcceptingParents, type, marketId, marketName } = values
    const isNotAcceptingParent = !isAcceptingParents
    const data = new FormData()
    data.append('marketId', marketId)
    data.append('marketName', marketName)
    data.append('isNotAcceptingParents', isNotAcceptingParent + '')
    if (type === 'map') {
      const area = [{ coordinates: [[state.latLngArray]] }]
      data.append('areas', JSON.stringify(area))
    } else if (type === 'zipCode') {
      if (state.backendFormat[0]?.coordinates?.length)
        data.append('areas', JSON.stringify(state.backendFormat))
    }
    updateAppState({ loading: true })
    const res = await api.post('/admin/addAreas', data)
    if (res.ok && res.data) {
      onAdd?.()
      onClose()
    }
    updateAppState({ loading: false })
  }

  const computePaths = (polyArray: google.maps.LatLng[]) => {
    const paths = polyArray.map((path) => [path.lng(), path.lat()])
    paths.push(paths[0])
    updateState({ latLngArray: paths })
  }

  const onPolygonComplete = (poly: google.maps.Polygon) => {
    let polyArray = poly.getPath().getArray()
    computePaths(polyArray)
    poly.setMap(null)
  }

  const handlePolygonEdit = () => {
    if (polygonRef.current) {
      const polyArray = polygonRef.current.getPath().getArray()
      computePaths(polyArray)
    }
  }

  const handlePolygonLoad = (polygon: google.maps.Polygon) => {
    polygonRef.current = polygon
    const path = polygon.getPath()
    listenerRef.current.push(
      path.addListener('set_at', handlePolygonEdit),
      path.addListener('insert_at', handlePolygonEdit),
      path.addListener('remove_at', handlePolygonEdit),
    )
  }

  const handlePolygonUnmount = (_polygon: google.maps.Polygon) => {
    listenerRef.current.forEach((lis) => lis.remove())
    polygonRef.current = undefined
  }

  const removeZip = (newState: DefaultState) => {
    let count1 = -1
    let coordinatesArrayMap: any[] = []
    newState.backendFormat.forEach((element1) => {
      count1++
      if (element1.zipCode === newState.zipName || element1.zipCode == '') {
        newState.backendFormat.splice(count1, 1)
      }
    })

    newState.allCoord = []
    newState.backendFormat.forEach((element1) => {
      element1.coordinates.forEach((element2: any[]) => {
        element2.forEach((element3: any[]) => {
          element3.forEach((element: any[]) => {
            coordinatesArrayMap.push({
              lng: element[0],
              lat: element[1],
            })
          })
          newState.allCoord.push(coordinatesArrayMap)
          coordinatesArrayMap = []
        })
      })
    })
  }

  const getZipCodeCoordinate = async (
    newState: DefaultState,
    zipCode: string,
    index: number,
  ) => {
    const url = `https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary`
    updateAppState({ loading: true })
    const zipRes = await api.get<GetZipCodeRes>(
      url,
      {
        zipcode: zipCode,
        and: false,
        combine: false,
        includepostal: false,
        limit: 30,
        showCenter: false,
        showDetails: false,
      },
      {
        headers: {
          'x-rapidapi-host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com',
          'x-rapidapi-key':
            'DCOzqtzQ8qmshcst78y3HCZuqik5p1F6uGgjsnc1FYAbstHXns',
        },
      },
    )
    if (zipRes.ok && zipRes.data) {
      const data = zipRes.data
      let latLng: any[] = []
      let covetBackendFormat1: any[] = []
      let tempLatLng: any[] = []

      if (!data.features.length) {
        toast.error('Invalid or missing zip code.')
        updateAppState({ loading: false })
        return
      }

      data.features.forEach((feature) => {
        if (feature.geometry.type === 'Polygon') {
          feature.geometry.coordinates.forEach((element1) => {
            let coordinatesArrayMap: any = []
            let coordinatesArrayMap1: any = []
            element1.forEach((element3) => {
              coordinatesArrayMap.push({
                lng: element3[0],
                lat: element3[1],
              })
              coordinatesArrayMap1.push(element3[0])
              coordinatesArrayMap1.push(element3[1])
              latLng.push(coordinatesArrayMap1)
              coordinatesArrayMap1 = []
              newState.center = { lng: element3[0], lat: element3[1] }
            })
            covetBackendFormat1.push(latLng)
            tempLatLng.push(covetBackendFormat1)
            covetBackendFormat1 = []
            latLng = []
            newState.allCoord.push(coordinatesArrayMap)
            coordinatesArrayMap = []
          })
        } else {
          feature.geometry.coordinates.forEach((element1) => {
            let coordinatesArrayMap: any[] = []
            let coordinatesArrayMap1: any[] = []
            element1.forEach((element3) => {
              element3.forEach((element2) => {
                coordinatesArrayMap.push({
                  lng: element2[0],
                  lat: element2[1],
                })
                coordinatesArrayMap1.push(element2[0])
                coordinatesArrayMap1.push(element2[1])
                latLng.push(coordinatesArrayMap1)
                coordinatesArrayMap1 = []
                newState.center = { lng: element2[0], lat: element2[1] }
              })
              covetBackendFormat1.push(latLng)
              tempLatLng.push(covetBackendFormat1)
              covetBackendFormat1 = []
              latLng = []
              newState.allCoord.push(coordinatesArrayMap)
              coordinatesArrayMap = []
            })
          })
        }
      })
      if (newState.backendFormat.length > index) {
        let tempState = newState
        tempState.backendFormat[index] = {
          zipCode: newState.zipName,
          coordinates: tempLatLng,
        }
      } else {
        newState.backendFormat.push({
          zipCode: newState.zipName,
          coordinates: tempLatLng,
        })
      }
      newState.isAdded = false
    }
    updateAppState({ loading: false })
  }

  const getZipCode = async (index: number) => {
    const data = watch('zipCode').map((obj) => obj.value)
    const newState = _.cloneDeep(state)
    if (data[index].length !== 5 && newState.previousValue !== '') {
      newState.isRemoved = true
      newState.zipName = newState.previousValue
      removeZip(newState)
    }
    if (
      data[1] !== '' &&
      data[index].length === 5 &&
      newState.previousValue?.length === 5 &&
      newState.previousValue !== data[index]
    ) {
      newState.isRemoved = true
      newState.zipName = newState.previousValue
      removeZip(newState)
      newState.isAdded = true
      await getZipCodeCoordinate(newState, data[index], index)
    }
    if (data[index].length !== 5 && newState.previousValue !== '') {
      newState.isRemoved = true
      newState.zipName = newState.previousValue
      removeZip(newState)
    }

    if (
      newState.currentRecord === index.toString() &&
      newState.previousValue === data[index]
    ) {
    } else if (
      data[index].length === 5 &&
      newState.previousValue !== data[index]
    ) {
      newState.zipName = data[index]
      newState.isAdded = true
      if (newState.previousValue?.length === 5) {
        newState.isRemoved = true
        removeZip(newState)
      }
      await getZipCodeCoordinate(newState, data[index], index)
    } else if (
      data[index] &&
      newState.previousValue &&
      data[index].length !== 5 &&
      newState.previousValue !== data[index] &&
      newState.previousValue.length === 5
    ) {
      newState.zipName = data[index]
      newState.isRemoved = true
      removeZip(newState)
    }
    setState(newState)
  }

  const getZipCodeFocusIn = (index: number) => {
    const data = watch('zipCode').map((obj) => obj.value)
    updateState({ currentRecord: index.toString(), previousValue: data[index] })
  }

  const removeZipCode = (index: number) => {
    const newState = _.cloneDeep(state)
    const data = watch('zipCode').map((obj) => obj.value)
    if (data[index]?.length === 5) {
      newState.isRemoved = true
      newState.zipName = data[index]
      removeZip(newState)
    }
    setState(newState)
    remove(index)
  }

  const addZipCode = () => {
    append({ value: '' })
  }

  const choiceChange = (choice: 'map' | 'zipCode') => {
    const newState = _.cloneDeep(state)
    if (choice === 'zipCode') {
      newState.allCoord = []
      newState.backendFormat = []
      addZipCode()
    } else if (choice === 'map') {
      setValue('zipCode', [])
    }
    setValue('type', choice)
  }

  const isSubmitDisabled =
    !isValid ||
    !(type === 'map' ? state.latLngArray.length : state.backendFormat.length)

  return (
    <ModalWrapper open={open} handleClose={onClose} wide title="Add New Region">
      <div className="add-new-area-modal">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col sm={4} className="add-new-area-modal__label">
              <label
                className="add-new-area-modal__label-text"
                htmlFor="marketId"
              >
                Market Id
              </label>
            </Col>
            <Col sm={7}>
              <Controller
                name="marketId"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    max={5}
                    placeholder="Enter market id"
                    onChange={(event) => {
                      const isPassed = checkNumbersOnly(event.target.value)
                      if (isPassed) field.onChange(event)
                      return isPassed
                    }}
                    isError={!!errors.marketId}
                    errorMessage={errors.marketId?.message?.toString()}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} className="add-new-area-modal__label">
              <label
                className="add-new-area-modal__label-text"
                htmlFor="marketName"
              >
                Market Name
              </label>
            </Col>
            <Col sm={7}>
              <Controller
                name="marketName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter market name"
                    isError={!!errors.marketName}
                    errorMessage={errors.marketName?.message?.toString()}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} className="add-new-area-modal__label">
              <label
                className="add-new-area-modal__label-text"
                htmlFor="isAcceptingParents"
              >
                Accepting Parents?
              </label>
            </Col>
            <Col sm={7}>
              <Controller
                name="isAcceptingParents"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    color="success"
                    size="medium"
                    {...field}
                    checked={field.value}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} className="add-new-area-modal__label">
              <label className="add-new-area-modal__label-text" htmlFor="type">
                Select Type
              </label>
            </Col>
            <Col sm={7}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <StyledDropdown
                    {...field}
                    onChange={(e) =>
                      choiceChange(e.target.value as 'zipCode' | 'map')
                    }
                    option={[
                      { id: 'map', value: 'map', option: 'Map' },
                      { id: 'zipCode', value: 'zipCode', option: 'Zip Code' },
                    ]}
                    placeholder="---Select Type---"
                    isError={!!errors.type}
                  />
                )}
              />
            </Col>
          </Row>
          {type === 'zipCode' && (
            <>
              {fields.map((field, index) => (
                <Row key={field.id} className="mb-3">
                  <Col sm={4} className="add-new-area-modal__label">
                    <label
                      className="add-new-area-modal__label-text"
                      htmlFor={`zipCode.${index}.value`}
                    >
                      Zip Code
                    </label>
                  </Col>
                  <Col sm={7}>
                    <Controller
                      name={`zipCode.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <StyledTextfield
                          {...field}
                          placeholder="Enter zip code"
                          max={5}
                          onBlur={() => {
                            getZipCode(index)
                            field.onBlur()
                          }}
                          onFocus={() => getZipCodeFocusIn(index)}
                          onChange={(event) => {
                            const isPassed = checkNumbersOnly(
                              event.target.value,
                            )
                            if (isPassed) field.onChange(event)
                            return isPassed
                          }}
                          isError={!!errors.zipCode?.[index]}
                          errorMessage={errors.zipCode?.[
                            index
                          ]?.value?.message?.toString()}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={1}>
                    {index !== 0 && (
                      <IconButton onClick={() => removeZipCode(index)}>
                        <GrFormClose size={25} />
                      </IconButton>
                    )}
                  </Col>
                </Row>
              ))}
              <Row className="mt-4 mb-5">
                <Col
                  sm={11}
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button
                    onClick={() => addZipCode()}
                    type="button"
                    className="px-3"
                  >
                    <BiPlus /> Add Zip Code
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {!!(type === 'zipCode' && type) && (
            <Row>
              <Col>
                {isLoaded ? (
                  <GoogleMap
                    key={state.center?.toString()}
                    mapContainerStyle={{ height: '500px' }}
                    center={state.center as google.maps.LatLng}
                    zoom={10}
                    options={{
                      streetViewControl: true,
                      mapTypeControl: true,
                    }}
                  >
                    {state.allCoord.map((paths, index) => (
                      <Polygon
                        key={index}
                        paths={paths}
                        options={{
                          strokeColor: '#db1825',
                          strokeOpacity: 1,
                          strokeWeight: 2,
                          fillColor: '#db1825',
                          fillOpacity: 0.35,
                        }}
                      />
                    ))}
                  </GoogleMap>
                ) : (
                  <p>Loading...</p>
                )}
              </Col>
            </Row>
          )}
          {!!(type === 'map' && type) && (
            <Row>
              <Col>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{ height: '500px' }}
                    center={{ lat: 42.432608, lng: -77.133209 }}
                    zoom={4}
                    options={{
                      streetViewControl: true,
                      mapTypeControl: true,
                    }}
                  >
                    {state.latLngArray.length ? (
                      <Polygon
                        options={{
                          fillColor: '#db1825',
                          strokeColor: '#db1825',
                          fillOpacity: 0.7,
                          strokeWeight: 4,
                          zIndex: 5,
                          strokeOpacity: 0.8,
                        }}
                        path={state.latLngArray.map(([lng, lat]) => ({
                          lng,
                          lat,
                        }))}
                        onLoad={handlePolygonLoad}
                        onUnmount={handlePolygonUnmount}
                        onMouseUp={handlePolygonEdit}
                        onDragEnd={handlePolygonEdit}
                      />
                    ) : (
                      <DrawingManager
                        drawingMode={google.maps.drawing.OverlayType.POLYGON}
                        onPolygonComplete={onPolygonComplete}
                        options={{
                          drawingControl: true,
                          drawingControlOptions: {
                            drawingModes: [
                              google.maps.drawing.OverlayType.POLYGON,
                            ],
                          },
                          polygonOptions: {
                            fillColor: '#db1825',
                            fillOpacity: 0.7,
                            strokeWeight: 4,
                            strokeColor: '#db1825',
                            zIndex: 5,
                            strokeOpacity: 0.8,
                            editable: true,
                            draggable: true,
                          },
                        }}
                      />
                    )}
                  </GoogleMap>
                ) : (
                  <p>Loading...</p>
                )}
              </Col>
            </Row>
          )}
          <div className="d-flex justify-content-end mt-4">
            {type === 'map' && (
              <Button
                type="button"
                disabled={!state.latLngArray.length}
                onClick={() => updateState({ latLngArray: [] })}
                className="px-5 mx-3"
                color={!state.latLngArray.length ? 'info' : 'success'}
              >
                Clear Area
              </Button>
            )}
            <Button
              disabled={isSubmitDisabled}
              type="submit"
              className="px-5 mx-3"
              color={isSubmitDisabled ? 'info' : 'success'}
            >
              Add Area
            </Button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  )
}

export default AddNewAreaModal
