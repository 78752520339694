import { useEffect, useState } from 'react'
import { IoIosArrowUp } from 'react-icons/io'

import './back-to-top.scss'

function BackToTop() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setShow(window.scrollY > 400)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    return false
  }

  return (
    <div
      onClick={handleClick}
      className={`back-to-top ${show ? 'back-to-top__fade-in' : ''}`}
    >
      <IoIosArrowUp size={35} />
    </div>
  )
}

export default BackToTop
