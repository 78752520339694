import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'

import Logo from '../../assets/logo/header_image_new.png'
import routes from '../../common/routes'
import { useAppContext } from '../../contexts/AppContext'
import './app-topbar.scss'
import NotificationPopover from './components/notification-popover/NotificationPopover'
import UserPopover from './components/user-popover/UserPopover'

const TopBar = () => {
  const { updateAppState, appState } = useAppContext()
  const navigate = useNavigate()

  return (
    <div className="app-top d-flex align-items-center justify-content-between w-100">
      <span className="d-flex align-items-center">
        <GiHamburgerMenu
          size={25}
          className="d-sm-none ms-3 "
          color="white"
          onClick={() => {
            appState.collapse
              ? updateAppState({ collapse: false })
              : updateAppState({ collapse: true })
          }}
        />
        <img
          src={Logo}
          className="logo-top"
          onClick={() => {
            navigate(routes.dashboard)
          }}
        />
      </span>
      <div className="top-icons d-flex">
        <NotificationPopover />
        <UserPopover />
      </div>
    </div>
  )
}

export default TopBar
