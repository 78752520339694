import { useEffect, useState } from 'react'

import { useAppContext } from '../../../../contexts/AppContext'
import { BrowseTutorResponse, Tutor } from '../../../../types/tutor.type'
import { api } from '../../../../utils'
import { BrowseTutorParams } from './browseTutor.type'

interface GetAllParentProps {
  filterStates: BrowseTutorParams | null
  debouncedSearch?: string
  dropDown?: boolean
}
const useBrowseTutor = ({
  filterStates,
  debouncedSearch,
  dropDown,
}: GetAllParentProps) => {
  const { updateAppState } = useAppContext()

  const [browseTutor, setBrowseTutors] = useState<Tutor[] | null>()

  const getBrowsedTutor = async () => {
    if (dropDown && !debouncedSearch) {
      setBrowseTutors(null)
      return
    }
    updateAppState({ loading: true })
    const queryObj = {
      searchType: filterStates?.searchType ? filterStates.searchType : null,
      skip: filterStates?.skip ? (filterStates.skip - 1) * 10 : 0,
      limit: filterStates?.limit ? filterStates.limit : 10,
      name: debouncedSearch ? debouncedSearch : null,
      locationDetails: filterStates?.locationDetails
        ? filterStates.locationDetails
        : null,
      distance: filterStates?.distance ? filterStates.distance : null,
      userId: filterStates?.userId ? filterStates.userId : null,
    }
    const res = await api.get<BrowseTutorResponse>(
      'booking/browseTutors',
      queryObj,
    )

    if (res.ok && res.data) {
      const { data } = res.data
      setBrowseTutors(data)
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getBrowsedTutor()
  }, [filterStates, debouncedSearch])
  return { browseTutor, getBrowsedTutor, setBrowseTutors }
}

export default useBrowseTutor
