import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import CustomDatePicker from '../../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../../contexts/AppContext'
import { useDebounce } from '../../../hooks'
import { MarketResponse, Markets } from '../../../types/market.type'
import { api } from '../../../utils'
import NotificationDetails from '../notification-details/NotificationDetails'
import {
  Notification,
  NotificationsData,
  NotificationsResponse,
} from '../notifications.type'
import './all-notifications.scss'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

function AllNotifications() {
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>(null)
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>(null)
  const [startDateOptions, setStartDateOptions] = useState<{
    minDate?: Dayjs
    maxDate?: Dayjs
  }>({})
  const [endDateOptions, setEndDateOptions] = useState<{
    minDate?: Dayjs
    maxDate?: Dayjs
  }>({})
  const [marketData, setMarketData] = useState<Markets[] | null>()
  const [marketId, setMarketId] = useState('')
  const { updateAppState } = useAppContext()
  const [search, setSearch] = useState('')
  const [notificationsData, setNotificationsData] =
    useState<NotificationsData | null>(null)
  const [page, setPage] = useState(1)
  const debouncedSearch = useDebounce({ value: search, milliSeconds: 500 })
  const navigate = useNavigate()
  const [notificationDetail, setNotificationDetail] = useState<Notification>()

  const getNotifications = async ({
    page,
    startDate,
    endDate,
    search,
    marketId,
  }: {
    page: number
    startDate?: Dayjs | null
    endDate?: Dayjs | null
    search?: string
    marketId?: string
  }) => {
    updateAppState({ loading: true })
    const skip = (page - 1) * 10
    const params: { [key: string]: any } = {
      limit: 10,
      skip,
    }
    if (startDate && endDate) {
      params.startDate = `${startDate.format('YYYY-MM-DD')}T00:00:00.000Z`
      params.endDate = `${endDate.format('YYYY-MM-DD')}T23:59:59.999Z`
    }
    if (search) params.searchParam = search
    if (marketId && marketId !== 'none') params.marketId = marketId
    const res = await api.get<NotificationsResponse>(
      '/notifications/getNotifications',
      params,
    )
    if (res.ok && res.data) {
      setNotificationsData(res.data.data)
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getNotifications({
      page,
      search: debouncedSearch,
      marketId,
      startDate,
      endDate,
    })
  }, [page, debouncedSearch, marketId])

  const mutatedNotifications =
    notificationsData?.notifications.map((notification) => {
      notification.type = notification.type.replace(/[_-]/g, ' ').toLowerCase()
      notification.createdAt1 = dayjs(notification.createdAt).tz(
        'America/New_York',
      )
      return notification
    }) ?? []

  const getMarketData = async () => {
    const res = await api.get<MarketResponse>('admin/getMarkets')
    if (res.ok && res.data) {
      const { data } = res.data
      setMarketData(data)
    }
  }

  useEffect(() => {
    getMarketData()
  }, [])

  const totalPages = notificationsData
    ? Math.ceil(notificationsData.totalCount / 10)
    : 0

  const estText = dayjs
    .tz(new Date().toString(), 'America/New_York')
    .format('z')

  const handleStartDateChange = (
    value: dayjs.Dayjs | null | undefined,
    _mode?: string | undefined,
  ) => {
    if (value) {
      setStartDate(value)
      setEndDateOptions({ minDate: value })
      if (
        endDate &&
        endDate.isSame(value, 'year') &&
        endDate.isSame(value, 'month')
      ) {
        if (endDate.isBefore(value, 'date')) {
          setEndDate(null)
          getNotifications({
            marketId,
            page: 1,
            search: debouncedSearch,
            startDate: value,
          })
        } else {
          getNotifications({
            marketId,
            page: 1,
            search: debouncedSearch,
            startDate: value,
            endDate,
          })
        }
        setPage(1)
      }
    } else {
      const tempDate = new Date()
      setEndDateOptions({
        minDate: dayjs(
          new Date(
            tempDate.getFullYear() - 20000,
            tempDate.getMonth(),
            tempDate.getDate() + 1,
          ),
        ),
      })
      setStartDate(null)
      if (endDate) {
        getNotifications({ marketId, page: 1 })
        setPage(1)
      }
    }
  }

  const handleEndDateChange = (
    value: dayjs.Dayjs | null | undefined,
    _mode?: string | undefined,
  ) => {
    if (value) {
      setEndDate(value)
      setStartDateOptions({
        maxDate: dayjs(new Date(value.year(), value.month(), value.date())),
      })
      setPage(1)
      getNotifications({
        marketId,
        page: 1,
        search: debouncedSearch,
        startDate,
        endDate: value,
      })
    } else {
      const tempDate = new Date()
      setStartDateOptions({
        maxDate: dayjs(
          new Date(
            tempDate.getFullYear() + 20000,
            tempDate.getMonth(),
            tempDate.getDate(),
          ),
        ),
      })
      setEndDate(null)
      setPage(1)
      if (startDate) {
        getNotifications({
          marketId,
          page: 1,
          search: debouncedSearch,
          startDate,
        })
      }
    }
  }

  const openDetails = async (notification: Notification) => {
    if (!notification.isRead && notificationsData?.unreadCount !== 0) {
      api
        .put(`/notifications/readNotification/${notification._id}`, {
          id: notification._id,
          key: 'single',
        })
        .then(() => {
          getNotifications({
            page,
            search: debouncedSearch,
            marketId,
            startDate,
            endDate,
          })
        })
    }
    setNotificationDetail(notification)
  }

  return (
    <div className="notifications container-fluid mt-4">
      <Row className="notifications__mb30">
        <Col xs={6} sm={6}>
          <h3 className="notifications__all-text">All Notifications</h3>
        </Col>
        <Col xs={6} sm={6} md={6} className="d-flex justify-content-end">
          <Button onClick={() => navigate('/pages/add-notifications')}>
            Send Notification
          </Button>
        </Col>
      </Row>
      <Row className="notifications__mb30">
        <Col md={3}>
          <CustomDatePicker
            date={startDate}
            onChange={handleStartDateChange}
            onClear={() => handleStartDateChange(null)}
            placeholder="Select Start Date"
            {...startDateOptions}
          />
        </Col>
        <Col md={3}>
          <CustomDatePicker
            date={endDate}
            onChange={handleEndDateChange}
            onClear={() => handleEndDateChange(null)}
            placeholder="Select End Date"
            {...endDateOptions}
          />
        </Col>
        <Col md={3}>
          <MarketDropdown
            option={marketData}
            value={marketId}
            handleChange={(e) => {
              const { value } = e.target
              setMarketId(value)
              if (value !== 'none') setPage(1)
            }}
            placeholder="--Select Market Name--"
          />
        </Col>
        <Col md={3}>
          <div className="d-flex justify-content-end">
            <SearchBar
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setPage(1)
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ '&:hover': { background: 'none' } }}>
                <TableCell>Type</TableCell>
                <TableCell>Parent Name</TableCell>
                <TableCell>Tutor Name</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mutatedNotifications.map((notification) => (
                <TableRow
                  key={notification._id}
                  sx={{ '& td': { padding: '2px 10px' } }}
                  onClick={() => openDetails(notification)}
                >
                  <TableCell>{notification.type || '-'}</TableCell>
                  <TableCell>{`${notification.parent?.firstName || ''} ${
                    notification.parent?.lastName || ''
                  }`}</TableCell>
                  <TableCell>{`${notification.tutor?.firstName || ''} ${
                    notification.tutor?.lastName || ''
                  }`}</TableCell>
                  <TableCell>
                    {notification.createdAt1.format('MM-DD-YYYY hh:mmA')}{' '}
                    {estText}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Row>
      <div className="mt-2 d-flex justify-content-end">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_event, page) => setPage(page)}
        />
      </div>
      {notificationDetail && (
        <NotificationDetails
          open={!!notificationDetail}
          onClose={() => setNotificationDetail(undefined)}
          notification={notificationDetail}
        />
      )}
    </div>
  )
}

export default AllNotifications
