import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import CKEditorComponent from '../../../../../components/ckEditor-component/CKEditorComponent'
import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../../components/styled-textfield/StyledTextfield'
import { FAQ } from '../../../../../types/constants.type'
import { api } from '../../../../../utils'

interface AddEditFaqI {
  open: boolean
  onClose: () => void
  mode: 'Add' | 'Edit'
  onSuccess: () => void
  editFaq?: FAQ
  buttonType: 'PARENT' | 'TUTOR'
}

const AddEditFaq: FC<AddEditFaqI> = ({
  open,
  mode,
  onClose,
  onSuccess,
  editFaq,
  buttonType,
}) => {
  const type = [
    { id: 'PARENT', option: 'Parent', value: 'PARENT' },
    { id: 'TUTOR', option: 'Tutor', value: 'TUTOR' },
  ]

  const [editorData, setEditorData] = useState<string>('')
  const [qsType, setQsType] = useState('')
  const [question, setQuestion] = useState('')
  const [questionError, setQuestionError] = useState(false)
  const [editorError, setEditorError] = useState(false)

  const handleEditorChange = (data: string) => {
    setEditorData(data)
  }

  useEffect(() => {
    if (mode === 'Edit' && editFaq) {
      setEditorData(editFaq.ans)
      setQuestion(editFaq.query)
    }
  }, [editFaq])

  const handleFaq = async () => {
    const formData = new FormData()
    formData.append('query', question)
    formData.append('ans', editorData)
    let res
    if (mode === 'Add') {
      formData.append('type', qsType)
      res = await api.post('admin/addFaqs', formData)
    } else {
      formData.append('type', buttonType)
      res = await api.put(`admin/updateFaq/${editFaq?._id}`, formData)
    }
    if (res && res?.ok) {
      onClose()
      onSuccess()
    }
  }

  const isButtonValid = () => {
    if (mode === 'Add') {
      return !!(qsType && editorData && question)
    }
    if (mode === 'Edit') {
      return !!(editorData && question)
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={mode + " FAQ's"}
      wide
    >
      <div className="row">
        {mode === 'Add' && (
          <div className="form-group row align-items-center">
            <div className="col-sm-3 text-left">
              <label className="add-child__label">Type</label>
            </div>

            <div className="col-sm-9">
              <StyledDropdown
                option={type}
                placeholder="--Select Type--"
                value={qsType}
                onChange={(e) => {
                  setQsType(e.target.value)
                }}
              />
            </div>
          </div>
        )}
        <div className="form-group row align-items-start">
          <div className="col-sm-3 text-left">
            <label className="add-child__label">Question</label>
          </div>

          <div className="col-sm-9">
            <StyledTextfield
              multiline
              placeholder="Enter Question"
              rows={3}
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value)
              }}
              onKeyDown={() => {
                setQuestionError(true)
              }}
              isError={!!(questionError && !question)}
              errorMessage={
                questionError && !question ? 'Question is required.' : ''
              }
            />
          </div>
        </div>
        <div className="form-group row align-items-start">
          <div className="col-sm-3 text-left">
            <label className="add-child__label">Answer</label>
          </div>

          <div className="col-sm-9">
            {mode === 'Add' ? (
              <CKEditorComponent
                onChange={handleEditorChange}
                value={editorData}
                onBlur={() => {
                  setEditorError(true)
                }}
              />
            ) : (
              <>
                {editorData || editorError ? (
                  <>
                    <CKEditorComponent
                      onChange={handleEditorChange}
                      value={editorData}
                      noBgColor
                      onFocus={() => {
                        setEditorError(true)
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            {editorError && !editorData ? (
              <div className="editor-error">
                {' '}
                <small>Description is Required</small>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="form-group row ps-0 mt-4">
          <div className=" col-sm-9 offset-sm-3 text-center mt30">
            <Button
              color={isButtonValid() ? 'success' : 'info'}
              disabled={!isButtonValid()}
              className="w-100"
              onClick={handleFaq}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditFaq
