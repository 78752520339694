import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import * as _ from 'lodash'

dayjs.extend(utc)
dayjs.extend(tz)

export const formatedResponse = (apiResponse: any) => {
  let tempslotArray: any[] = []
  apiResponse.data?.forEach((value: any) => {
    value.slots.forEach((obj: any) => {
      let tempdate = dayjs
        .utc(value.date)
        .startOf('day')
        .add(obj.startTime)
        .format()
      let slot = {
        _id: obj._id,
        availableSlot: tempdate.split('T').reverse()[0].substring(0, 5),
        date: tempdate.split('T')[0],
        status: obj.status,
        bookingId: obj.bookingId,
      }
      tempslotArray.push(slot)
    })
  })

  return tempslotArray
}
