import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../modal-wrapper/ModalWrapper'
import './delete-modal.scss'

interface DeleteModalProps {
  open: boolean
  handleClose: () => void
  role: 'Tutor' | 'Parent' | 'Child'
  onDelete?: () => void
}
const DeleteModal: FC<DeleteModalProps> = ({
  open,
  handleClose,
  role,
  onDelete,
}) => {
  const name =
    role === 'Parent'
      ? localStorage.getItem('parentName')
      : role === 'Tutor'
      ? localStorage.getItem('tutorName')
      : localStorage.getItem('childName')
  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      title={`Delete ${role}`}
    >
      <div className="d-flex justify-content-center align-items-center flex-column delete-modal">
        {role === 'Child' ? (
          <p className="delete-modal__title">
            Are you sure you want to delete this child?
          </p>
        ) : (
          <p className="delete-modal__title">
            Are you sure you want to delete {name}? This cannot be undone.
          </p>
        )}

        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button color="success" className="modal-button" onClick={onDelete}>
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default DeleteModal
