import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown, {
  Options,
} from '../../../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../../../contexts/AppContext'
import { api } from '../../../../../../utils'
import { AllSubjectsRes, subject } from '../../all-subjects.types'

interface AddEditSubjectProps {
  open: boolean
  onClose: () => void
  mode: 'Add' | 'Edit'
  selectedSubject?: subject
  subjectType?: 'subject' | 'subSubject'
}

const AddEditSubject: FC<AddEditSubjectProps> = ({
  open,
  onClose,
  mode,
  selectedSubject,
  subjectType,
}) => {
  const { getSubjects } = useAppContext()
  const [subjectName, setSubjectName] = useState('')
  const [parentSubjectName, setParentSubjectName] = useState('')
  const [allSubjects, setAllSubjects] = useState<Options[]>([])
  const [subjectNameTouched, setSubjectNameTouched] = useState(false)

  const getAllSubjects = async () => {
    const res = await api.get<AllSubjectsRes>('admin/getSubjects')
    if (res.ok && res.data) {
      const { data } = res.data

      const newArray = data.map((dt) => {
        return { id: dt._id, value: dt._id, option: dt.name }
      })
      newArray.splice(0, 0, { id: '0', value: '0', option: 'none' })
      setAllSubjects(newArray)
    }
  }

  useEffect(() => {
    if (selectedSubject) setSubjectName(selectedSubject.name)
  }, [selectedSubject])

  useEffect(() => {
    getAllSubjects()
  }, [])

  const isValid = () => {
    if (mode === 'Add') {
      if (!subjectName || !parentSubjectName) return true
      else return false
    } else {
      if (!subjectName) return true
      else false
    }
  }

  const addEditSubject = async () => {
    let formData = new FormData()
    let res
    if (mode === 'Add') {
      if (parentSubjectName === '0') {
        formData.append('name', subjectName)
        res = await api.post('admin/subject', formData)
      } else {
        let newSub = []
        newSub.push({ name: subjectName })
        formData.append('subjectId', parentSubjectName)
        formData.append('subcategories', JSON.stringify(newSub))
        res = await api.post('admin/subSubject', formData)
      }
    } else {
      formData.append('name', subjectName)
      if (subjectType === 'subject') {
        res = await api.put(`admin/subject/${selectedSubject?._id}`, formData)
      } else {
        res = await api.put(
          `admin/subSubject/${selectedSubject?._id}`,
          formData,
        )
      }
    }
    if (res && res.ok) {
      getSubjects()
      onClose()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={mode === 'Add' ? 'Add Subject' : 'Edit Subject'}
      wide
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div
            className={`form-group row ${
              !subjectName && subjectNameTouched
                ? 'align-items-start'
                : 'align-items-center'
            } `}
          >
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Subject Name</label>
            </div>

            <StyledTextfield
              placeholder="Subject Name"
              className="col-sm-8"
              value={subjectName}
              onChange={(e) => {
                setSubjectName(e.target.value)
              }}
              onKeyDown={() => {
                setSubjectNameTouched(true)
              }}
              isError={!subjectName && subjectNameTouched ? true : false}
              errorMessage={
                !subjectName && subjectNameTouched
                  ? 'Subject name is required'
                  : ''
              }
            />
          </div>
          {mode === 'Add' && (
            <div className="form-group row align-items-center">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">Parent Subject Name</label>
              </div>

              <StyledDropdown
                option={allSubjects}
                placeholder="--Select Parent Subject--"
                className="col-sm-8"
                value={parentSubjectName}
                onChange={(event) => {
                  setParentSubjectName(event.target.value)
                }}
              />
            </div>
          )}
        </div>
        <div className="form-group row p-0">
          <div className="offset-sm-9 col-sm-3 mt-4 text-end p-0">
            <Button
              color={!isValid() ? 'success' : 'info'}
              disabled={isValid()}
              className="w-100"
              onClick={addEditSubject}
            >
              {mode === 'Add' ? 'Add' : 'Save'}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditSubject
