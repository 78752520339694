import dayjs from 'dayjs'
import { FC } from 'react'
import Calendar from 'react-calendar'

import './calendar-component.scss'

interface calendarComponentProps {
  setDate?: React.Dispatch<React.SetStateAction<Date | null | undefined>>
  date?: Date | null
}

const CalendarComponent: FC<calendarComponentProps> = ({ date, setDate }) => {
  return (
    <div className="d-flex justify-content-center">
      <Calendar
        minDate={new Date()}
        maxDate={dayjs().add(120, 'days').toDate()}
        calendarType="US"
        onChange={(value) => {
          if (!Array.isArray(value)) {
            setDate?.(value)
          }
        }}
        value={date}
      />
    </div>
  )
}

export default CalendarComponent
