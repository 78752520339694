import { FC } from 'react'

import '../dashboard.scss'
import { CurrentMatricesData } from '../dashboard.type'

interface CurrentMatricesI {
  currentMatricesData: CurrentMatricesData
}
const CurrentMetrics: FC<CurrentMatricesI> = ({ currentMatricesData }) => {
  const rows = [
    {
      field: 'New & Accepted Sessions',
      description: currentMatricesData?.newAndAcceptedSessions,
    },
    {
      field: 'Finished & Completed Sessions',
      description: currentMatricesData?.finishedAndCompletedSessions,
    },
    {
      field: 'Total Bright Horizons Hours Used',
      description: currentMatricesData?.totalBrightHorizonHoursUsed,
    },
    {
      field: 'Expired Bright Horizons Hours',
      description: currentMatricesData?.expiredBrightHorizonHours,
    },
    {
      field: 'Canceled Bright Horizons Hours',
      description: currentMatricesData?.canceledBrightHorizonHours,
    },
  ]
  return (
    <div>
      <table className="custom-table">
        {rows.map((row) => {
          return (
            <tr key={row.field}>
              <td className="col-sm-8">{row.field}</td>
              <td className="col-sm-3">{row.description}</td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export default CurrentMetrics
