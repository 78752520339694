import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'

interface TutoringMethodErrorProps {
  open: boolean
  handleClose: () => void
}

const TutoringMethodError: FC<TutoringMethodErrorProps> = ({
  open,
  handleClose,
}) => {
  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      showCloseButton={false}
      wide
    >
      <div className="mt-4 d-flex justify-content-center align-items-center flex-column">
        <p className="delete-modal__title">
          A tutor must have at least one tutoring delivery method selected.
          Please select if they offer online and/or in-person tutoring.
        </p>
        <div className="w-25">
          <Button
            color="success"
            className="modal-button w-100"
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default TutoringMethodError
