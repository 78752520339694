import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Checkbox,
  Select as MUISelect,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import routes from '../../../common/routes'
import {
  timeArray,
  weekCount,
} from '../../../components/calendar-events/components/data'
import ModalWrapper from '../../../components/modal-wrapper/ModalWrapper'
import SearchedDropDown from '../../../components/styled-dropdown/SearchedDropDown'
import StyledDropdown from '../../../components/styled-dropdown/StyledDropdown'
import StyledReactSelect from '../../../components/styled-react-select/StyledReactSelect'
import StyledTextfield from '../../../components/styled-textfield/StyledTextfield'
import { useDebounce } from '../../../hooks'
import { AvailabilityResponse } from '../../../types/availablity.type'
import { Student, StudentResponse } from '../../../types/child.type'
import { Parent, SubjectsAndAddressRes } from '../../../types/parent.type'
import { Data as PromoCodeType } from '../../../types/promo.type'
import { Sub, SubjectsResponse, Tutor } from '../../../types/tutor.type'
import { api, checkDateIn120, dropDownSearch } from '../../../utils'
import useAllParents from '../../parent/components/get-all-parents/GetAllParents'
import { BrowseTutorParams } from '../components/useBrowseTutor/browseTutor.type'
import useBrowseTutor from '../components/useBrowseTutor/useBrowseTutor'
import CardSection from './components/card-section/CardSection'
import ConfirmAutoAccept from './components/confirm-auto-accept/ConfirmAutoAccept'
import ParentSchedularModal from './components/parent-schedular-modal/ParentSchedularModal'
import TimeSlotComponent from './components/time-slot-component/TimeSlotComponent'
import TutorSessionsTable from './components/tutor-sessions-table/TutorSessionsTable'
import ZeroTutoringHours from './components/zero-tutoring-hours/ZeroTutoringHours'
import './create-session.scss'
import {
  CreateSessionBooleans,
  LocationZoneType,
  SelectType,
  SessionFormValues,
  SessionParentParams,
  defaultBooleans,
  defaultValues,
  paramsInit,
  sessionSchema,
  tutorParamsInit,
} from './createSession.type'
import { onBehalf, radioButtons, slotTime } from './data'

dayjs.extend(utc)
dayjs.extend(tz)

const CreateSession = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(true)
  const [parentSearch, setParentSearch] = useState('')
  const [tutorSearch, setTutorSearch] = useState('')
  const [parentSearchedBy, setParentSearchedBy] = useState<
    'typed' | 'selected'
  >('selected')
  const [tutorSearchedBy, setTutorSearchedBy] = useState<'typed' | 'selected'>(
    'selected',
  )
  const [filterStates, setFilterStates] =
    useState<SessionParentParams>(paramsInit)
  const [tutorFilters, setTutorFilters] =
    useState<BrowseTutorParams>(tutorParamsInit)

  let bookingDate = new Date()
  let slotStatus: any = []
  let weekSlotStatus: any = []
  let weekDataBackend = []
  let allids: any = []

  const methods = useForm<SessionFormValues>({
    resolver: yupResolver(sessionSchema),
    mode: 'all',
    defaultValues,
  })

  const [selectedParent, setSelectedParent] = useState<Parent | null>(null)
  const [selectedTutor, setSelectedTutor] = useState<Tutor | null>(null)
  const [students, setStudents] = useState<Student[] | null>([])
  const [address, setAddress] = useState<any | null>([])
  const [subjects, setSubjects] = useState<Sub[] | null>([])
  const [subjectArray, setSubjectArray] = useState<SelectType[] | null>([])
  const [tutorEndDate, setTutorEndDate] = useState<string | undefined>('')
  const [locationZone, setLocationZone] = useState<LocationZoneType>()
  const [slotStatusArray, setSlotStatusArray] = useState<any>([])
  const [weekSlotArray, setWeekSlotArray] = useState<any>([])
  const [weekDataArray, setWeekDataArray] = useState<any>([])
  const [hourlyRate, setHourlyRate] = useState<any>()
  const [selectedCard, setSelectedCard] = useState('')
  const [sessionArray, setSessionArray] = useState<any[]>([])
  const [allIds, setAllIds] = useState<any>()
  const [promoCodeValue, setPromoCodeValue] = useState<PromoCodeType>()
  const [bookingBy, setBookingBy] = useState<'day' | 'week'>('day')
  const [partnerMsg, setPartnerMsg] = useState('')
  const [addressSearch, setAddressSearch] = useState('')
  const [searchedAddress, setSearchedAddress] = useState([])
  const [childSearch, setChildSearch] = useState('')
  const [searchedChild, setSearchedChild] = useState<Student[] | null>([])
  const [calendarOptions, setCalendarOptions] = useState<any>()
  const [createSessionBoolean, setCreateSessionBoolean] =
    useState<CreateSessionBooleans>(defaultBooleans)
  const [timeZone, settimeZone] = useState('')
  const values = methods.watch()

  useEffect(() => {
    if (
      selectedParent?.partnershipCode &&
      selectedParent.partnershipCode.length &&
      selectedParent.partnershipCode[0].isRateChargeSuppress
    ) {
      setCreateSessionBoolean({
        ...createSessionBoolean,
        isRateChargesSuppress:
          !selectedParent.parent.isParentOutOfTutoringHours,
      })
    } else {
      setCreateSessionBoolean({
        ...createSessionBoolean,
        isRateChargesSuppress: false,
      })
    }
  }, [selectedParent])

  useEffect(() => {
    setOpen(true)
    setSlotStatusArray([])
    slotTime.forEach(() => {
      slotStatus.push(1)
    })

    setSlotStatusArray([...slotStatus])
    initializeWeekArray()
  }, [values.sessionDate])

  const initializeWeekArray = () => {
    weekSlotStatus = []
    for (let i = 0; i < 7; i++) {
      let newslots: any = []
      slotTime.forEach(() => {
        newslots.push({ element: 1, time: '', id: '' })
      })
      weekSlotStatus.push(newslots)
    }
    setWeekSlotArray(weekSlotStatus)
  }

  useEffect(() => {
    if (createSessionBoolean.isRateChargesSuppress)
      methods.setValue('sessionDuration', '1.0')
    else methods.setValue('sessionDuration', '')
  }, [createSessionBoolean.isRateChargesSuppress])

  const handleClose = () => {
    setOpen(!open)
    navigate('/pages/' + routes.allSessions)
  }

  const debouncedSearch = useDebounce({
    value: parentSearchedBy === 'typed' ? parentSearch : '',
    milliSeconds: 500,
  })
  const tutorDebouncedSearch = useDebounce({
    value: tutorSearchedBy === 'typed' ? tutorSearch : '',
    milliSeconds: 500,
  })

  const { allParents, setAllParents } = useAllParents({
    debouncedSearch,
    filterStates,
    isSearchForBooking: true,
    dropDown: true,
  })

  const { browseTutor, setBrowseTutors } = useBrowseTutor({
    debouncedSearch: tutorDebouncedSearch,
    filterStates: tutorFilters,
    dropDown: true,
  })

  const slotChange = (data: any, i: number) => {
    slotStatus = [...slotStatusArray]
    if (i == 0 || i == 2) {
      let count = 0
      let temp = data
      let time = parseFloat(values.sessionDuration as string)
      while (count <= slotStatusArray.length) {
        if (slotStatus[count] == 2) {
          slotStatus[count] = 0
        }
        count++
      }
      count = 0

      while (time > count && slotStatus[temp] == 0) {
        count += 0.5
        temp++
        if (slotStatus[temp] == undefined) {
          count += 0.5
        }
      }
      if (count == time) {
        while (count > 0) {
          slotStatus[data] = 2
          data++
          count = count - 0.5
        }
      }

      setSlotStatusArray(slotStatus)
    }
  }

  const slotChangeWeek = (j: any, i: number) => {
    weekSlotStatus = [...weekSlotArray]
    weekDataBackend = []
    let count = 0
    let temp = i
    let time = parseFloat(values.sessionDuration as string)
    for (let ii = 0; ii < 7; ii++) {
      while (count <= weekSlotStatus[ii].length - 1) {
        if (weekSlotStatus[ii][count].element == 2) {
          weekSlotStatus[ii][count].element = 0
        }
        count++
      }
      count = 0
    }
    count = 0
    while (
      time >= count &&
      weekSlotStatus[j][temp] &&
      weekSlotStatus[j][temp].element == 0
    ) {
      count += 0.5
      temp++

      if (
        weekSlotStatus[j][temp] &&
        weekSlotStatus[j][temp].element == undefined
      ) {
        count += 0.5
      }
    }
    if (count != 0 && time >= count) count = count + 0.5

    count = count - 0.5

    if (count == time) {
      while (count > 0) {
        weekSlotStatus[j][i].element = 2
        weekDataBackend.push(weekSlotStatus[j][i])
        i++
        count = count - 0.5
      }
    }

    setWeekDataArray(weekDataBackend)
    setWeekSlotArray(weekSlotStatus)
  }

  const getAddress = async () => {
    const res = await api.get<SubjectsAndAddressRes>(
      'parent/addressesAndStudents',
      {
        parentId: selectedParent?._id,
        tutorId: selectedTutor?._id,
      },
    )

    if (res.ok && res.data) {
      const { addresses } = res.data.data

      let address = ''
      let arr: any = []
      addresses.forEach((element) => {
        address =
          (element.addressLine1 ? element.addressLine1 : '') +
          (element.addressLine2 ? ', ' + element.addressLine2 : '') +
          (element.city ? ', ' + element.city : '') +
          (element.state ? ', ' + element.state : '') +
          (element.zipCode ? ', ' + element.zipCode : '')

        arr.push({
          label: address,
          value: {
            label: element.label,
            zipCode: element.zipCode,
            country: element.country,
            city: element.city,
            state: element.state,
            addressLine1: element.addressLine1,
            addressLine2: element.addressLine2,
            longitude: element.location.coordinates[0],
            latitude: element.location.coordinates[1],
            parentTimezoneOffsetZone: element.timezoneOffsetZone
              ? element.timezoneOffsetZone
              : 'America/New_York',
          },
        })
      })
      let onlineSessionObj = {
        label: 'Online Session',
        value: {
          label: addresses[0].label,
          zipCode: addresses[0].zipCode,
          country: addresses[0].country,
          city: addresses[0].city,
          state: addresses[0].state,
          addressLine1: addresses[0].addressLine1,
          addressLine2: addresses[0].addressLine2,
          longitude: addresses[0].location.coordinates[0],
          latitude: addresses[0].location.coordinates[1],
          parentTimezoneOffsetZone: addresses[0].timezoneOffsetZone
            ? addresses[0].timezoneOffsetZone
            : 'America/New_York',
          onlineSession: 'true',
        },
      }
      if (selectedTutor?.tutor.isTutorOfferOnlineClasses) {
        arr.splice(0, 0, onlineSessionObj)
      }
      if (
        selectedParent?.marketId.marketName === 'Nationwide' ||
        !selectedTutor?.tutor.isOfferInPersonTutoring
      ) {
        setAddress([onlineSessionObj])
      } else {
        setAddress(arr)
      }
    }
  }

  useEffect(() => {
    if (selectedTutor) {
      getSubjects()
      getAddress()
    }
  }, [selectedTutor])

  const getChild = async () => {
    const res = await api.get<StudentResponse>('parent/getStudents', {
      parentId: selectedParent?._id,
    })

    if (res.ok && res.data) {
      const { data } = res.data
      let studentArr: Student[] = []
      data
        .sort(
          (a, b) =>
            a.firstName.localeCompare(b.firstName) ||
            a.lastName.localeCompare(b.lastName),
        )
        .forEach((element) => {
          if (element.dob) {
            studentArr.push(element)
          }
        })

      setStudents(studentArr)
    }
  }
  useEffect(() => {
    if (selectedParent) getChild()
  }, [selectedParent])
  const getSubjects = async () => {
    const res = await api.get<SubjectsResponse>('booking/getTutorSubjects', {
      tutorId: selectedTutor?._id,
    })

    if (res.ok && res.data) {
      const tempArray = res.data.data
      let arr: SelectType[] = []
      tempArray.forEach((element) => {
        if (element.subcategories)
          element.subcategories.forEach((element2) => {
            arr.push({
              label: element2.name,
              value: element2._id,
              id: element2._id,
            })
          })
      })
      setSubjectArray(arr)
      setSubjects(tempArray)
    }
  }

  const getAvailability = async () => {
    let date = new Date(dayjs(values.sessionDate).format())

    let startDate1: any = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
    )
    let endDate1: any = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      0,
      0,
      0,
    )

    bookingDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
    )

    endDate1 = dayjs
      .tz(
        dayjs(endDate1).format('YYYY-MM-DDTHH:mm:ss'),
        locationZone?.parentTimezoneOffsetZone
          ? locationZone?.parentTimezoneOffsetZone
          : 'America/New_York',
      )
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss')

    if (createSessionBoolean.isRateChargesSuppress) {
      selectedParent?.parent.sessionCreditsFromExternalApi.forEach(
        (val, key) => {
          if (val.reservationNumber === values?.sessionReservation) {
            if (checkDateIn120(val.endDate)) {
              endDate1 = val.endDate
            }
          }
        },
      )
    }

    if (tutorEndDate) {
      if (tutorEndDate < endDate1) {
        let split = tutorEndDate.split('T')
        endDate1 = split[0] + 'T23:59:59'
      }
    }
    let currentDate = dayjs(dayjs().format())
      .tz(
        locationZone?.parentTimezoneOffsetZone
          ? locationZone?.parentTimezoneOffsetZone
          : 'America/New_York',
      )
      .format('YYYY-MM-DDTHH:mm:ss')
    if (
      startDate1.getFullYear() <= dayjs(currentDate).year() &&
      startDate1.getMonth() <= dayjs(currentDate).month() &&
      startDate1.getDate() <= dayjs(currentDate).date()
    ) {
      startDate1 = dayjs(dayjs().format())
        .tz(
          locationZone?.parentTimezoneOffsetZone
            ? locationZone?.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    const res = await api.get<AvailabilityResponse>('tutor/getAvailability', {
      tutorId: selectedTutor?._id,
      parentId: selectedParent?._id,
      startDate: dayjs
        .tz(
          dayjs(startDate1).format('YYYY-MM-DDTHH:mm:ss'),
          locationZone?.parentTimezoneOffsetZone
            ? locationZone?.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),

      endDate: dayjs(endDate1).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
      duration: values.sessionDuration,

      parentTimezoneOffsetZone: locationZone?.parentTimezoneOffsetZone
        ? locationZone?.parentTimezoneOffsetZone
        : 'America/New_York',
    })

    if (res.ok && res.data && res.data.data.length > 0) {
      let { data } = res.data
      let availableDates = data
      let currentDateArray
      let availableDate = availableDates[0].date

      if (
        locationZone &&
        locationZone.parentTimezoneOffsetZone &&
        dayjs.tz.guess() == locationZone.parentTimezoneOffsetZone
      ) {
        if (
          new Date(availableDate).getUTCDate() == new Date().getDate() &&
          new Date(availableDate).getUTCMonth() == new Date().getMonth()
        ) {
          let currentDate = new Date()
          currentDateArray = JSON.parse(JSON.stringify(availableDates[0]))
          currentDateArray.slots = currentDateArray.slots.filter(
            (value: any) => {
              if (
                value.endTime / 60000 >
                (currentDate.getHours() * 3600000 +
                  currentDate.getMinutes() * 60000 +
                  1800000) /
                  60000
              ) {
                return true
              } else return false
            },
          )
        }
      }
      let count = 0
      let slots: any = []
      if (true) {
        if (
          availableDates &&
          availableDates.length != 0 &&
          availableDates[0].slots
        ) {
          count = 0

          let firstdate = new Date(
            new Date(availableDate).getUTCFullYear(),
            new Date(availableDate).getUTCMonth(),
            new Date(availableDate).getUTCDate(),
          )

          if (
            firstdate.getFullYear() == bookingDate.getFullYear() &&
            firstdate.getMonth() == bookingDate.getMonth() &&
            firstdate.getDate() == bookingDate.getDate()
          ) {
            availableDates[0].slots.forEach((element1) => {
              if (
                element1.status == 'AVAILABLE' &&
                element1.startTime <= 86400000
              ) {
                let d1 = new Date()
                let milliseconds = element1.startTime
                let houss = milliseconds / 3600000

                let timeString = houss + ''
                let time = timeString.split('.')

                d1.setHours(parseInt(time[0], 10))
                if (time[1] == undefined) d1.setMinutes(0)
                else d1.setMinutes(30)

                let currentDate = new Date()
                currentDate.setDate(currentDate.getDate() + 1)
                slots.push({
                  min: d1.getMinutes(),
                  hour: d1.getHours(),
                  second: d1.getSeconds(),
                  id: element1._id,
                })
              }
            })
            slotTime.forEach((element1) => {
              let newTemp1 = element1.split('.')
              let newTemp: any = []
              newTemp[0] = parseInt(newTemp1[0], 10)
              newTemp[1] = parseInt(newTemp1[1], 10)

              slots.forEach((element: any) => {
                let shouldInclude = true

                if (values.onBehalfOf && values.onBehalfOf === 'PARENT') {
                  shouldInclude = !sessionArray.find((s) =>
                    s.slots.find((slot: any) => slot === element.id),
                  )
                }

                if (
                  newTemp[0] == element.hour &&
                  newTemp[1] == element.min &&
                  shouldInclude
                ) {
                  slotStatus[count] = 0
                  allids[count] = element.id
                }
              })
              count++
            })
            setSlotStatusArray(slotStatus)
          }
          setAllIds(allids)
        }
      }

      if (true) {
        weekSlotStatus = weekSlotArray
        let bookingCount = 0
        initializeWeekArray()
        if (true)
          for (let i = 0; i < 7; i++) {
            let firstdate = new Date(
              new Date(availableDates[bookingCount].date).getUTCFullYear(),
              new Date(availableDates[bookingCount].date).getUTCMonth(),
              new Date(availableDates[bookingCount].date).getUTCDate(),
            )

            if (
              firstdate.getFullYear() == bookingDate.getFullYear() &&
              firstdate.getMonth() == bookingDate.getMonth() &&
              firstdate.getDate() == bookingDate.getDate()
            ) {
              if (bookingCount < availableDates.length) {
                availableDates[bookingCount].slots.forEach((element1) => {
                  if (element1.status == 'AVAILABLE') {
                    let d1 = new Date()
                    let milliseconds = element1.startTime
                    let houss = milliseconds / 3600000

                    let timeString = houss + ''
                    let time = timeString.split('.')

                    d1.setHours(parseInt(time[0], 10))
                    if (time[1] == undefined) d1.setMinutes(0)
                    else d1.setMinutes(30)

                    let currentDate = new Date()
                    currentDate.setDate(currentDate.getDate() + 1)

                    slots.push({
                      min: d1.getMinutes(),
                      date: availableDates[bookingCount].date,
                      hour: d1.getHours(),
                      second: d1.getSeconds(),
                      id: element1._id,
                    })
                  }
                })
                let count1 = 0
                slotTime.forEach((element1: any) => {
                  let newTemp1 = element1.split('.')
                  let newTemp: any = []
                  newTemp[0] = parseInt(newTemp1[0], 10)
                  newTemp[1] = parseInt(newTemp1[1], 10)
                  slots.forEach((element: any) => {
                    let shouldInclude = true
                    if (values.onBehalfOf && values.onBehalfOf === 'PARENT') {
                      shouldInclude = !sessionArray.find((s) =>
                        s.slots.find((slot: any) => slot === element.id),
                      )
                    }
                    if (
                      newTemp[0] == element.hour &&
                      newTemp[1] == element.min &&
                      shouldInclude
                    ) {
                      weekSlotStatus[i][count1].element = 0
                      weekSlotStatus[i][count1].time = {
                        hour: element.hour,
                        min: element.min,
                        second: element.second,
                      }
                      weekSlotStatus[i][count1].id = element.id
                      weekSlotStatus[i][count1].date = element.date
                    }
                  })
                  count1++
                })
                slots = []
                bookingCount++
              }
            }
            bookingDate.setDate(bookingDate.getDate() + 1)
          }
        setWeekSlotArray(weekSlotStatus)
      }
    }
  }

  const getAvailableDates = async () => {
    const res = await api.get<any>('booking/getAvailableDates', {
      tutorId: selectedTutor?._id,
      startDate: new Date().toString(),
      endDate: new Date(dayjs().add(121, 'days').format('')).toString(),
      duration: values.sessionDuration,
    })

    if (res.ok && res.data && res.data.data.length > 0) {
      let tempDates = res.data.data

      setTutorEndDate(tempDates.at(-1))
      if (values.sessionDuration) {
        checkTutorEndDate(tempDates.at(-1))
      }
    }
  }

  useEffect(() => {
    if (values.sessionDuration && selectedTutor) getAvailableDates()
    if (
      values.sessionDate &&
      values.sessionLocation &&
      values.sessionDuration &&
      selectedParent &&
      selectedTutor
    ) {
      getAvailability()
    }
  }, [
    values.sessionDuration,
    selectedTutor,
    selectedParent,
    values.sessionDate,
    values.sessionLocation,
  ])

  const checkHoursZero = () => {
    if (createSessionBoolean.isRateChargesSuppress) {
      if (selectedParent?.parent.numberOfTutoringHours === 0) {
        setCreateSessionBoolean({
          ...createSessionBoolean,
          isZeroHours: true,
        })

        return true
      }
    } else {
      return false
    }
  }
  const handleSubmit = methods.handleSubmit(async (data) => {
    if (checkHoursZero()) {
      return
    }
    slotStatus = [...slotStatusArray]
    let submissionArray: any = []
    let formData = new FormData()
    if (data.onBehalfOf === 'PARENT') {
      if (data.autoAccept && !createSessionBoolean.isAcceptModalShown) {
        let temp = {
          isAutoAccept: true,
          isAcceptModalShown: true,
        }
        setCreateSessionBoolean({ ...createSessionBoolean, ...temp })

        return
      }

      slotStatus = [...slotStatusArray]
      sessionArray.forEach((session, i) => {
        submissionArray[i] = {}

        let count = -1
        let slots: any = []
        let ids: any = []
        let startEndTime = ''
        let time: any = []
        let d = new Date()
        let totalValue = Number(values.sessionDuration as string) * hourlyRate

        if (bookingBy == 'day') {
          slotTime.forEach((element) => {
            count = count + 1
            if (slotStatus[count] == 2) {
              ids.push(allIds[count])
              slots.push(element)
            }
          })
          startEndTime = slots[0]
          if (startEndTime) {
            time = startEndTime.split('.')
          }
          d = new Date(session.startTime)
          submissionArray[i]['startTime'] = dayjs(d).format(
            'YYYY-MM-DDTHH:mm:ss',
          )
          startEndTime = slots[slots.length - 1]
          if (startEndTime) {
            time = startEndTime.split('.')
          }
          if (time && time[1] == '30') {
            time[0] = parseInt(time[0], 10) + 1 + ''
            time[1] = '00'
          } else if (time && time[1] == '00') time[1] = '30'
          d = new Date(session.endTime)
          submissionArray[i]['endTime'] = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')
        } else if (bookingBy == 'week') {
          weekDataArray.forEach((element: any) => {
            ids.push(element.id)
          })
          let newDate = new Date(weekDataArray[0].date)
          if (new Date().getTimezoneOffset() * 60000 * -1 < 0)
            d = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate() + 1,
              weekDataArray[0].time.hour,
              weekDataArray[0].time.min,
              0,
            )
          else
            d = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              weekDataArray[0].time.hour,
              weekDataArray[0].time.min,
              0,
            )

          submissionArray[i]['startTime'] = dayjs(d).format(
            'YYYY-MM-DDTHH:mm:ss',
          )
          d = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate(),
            weekDataArray[weekDataArray.length - 1].time.hour,
            weekDataArray[weekDataArray.length - 1].time.min,
            0,
          )
          if (d.getMinutes() == 30) {
            d = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              weekDataArray[weekDataArray.length - 1].time.hour + 1,
              0,
              0,
            )
          } else if (d.getMinutes() == 0) {
            d = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              weekDataArray[weekDataArray.length - 1].time.hour,
              30,
              0,
            )
          }
          if (new Date().getTimezoneOffset() * 60000 * -1 < 0)
            d.setDate(d.getDate() + 1)

          submissionArray[i]['endTime'] = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')
        }

        submissionArray[i]['tutorId'] = selectedTutor?._id
        submissionArray[i]['parentId'] = selectedParent?._id
        submissionArray[i]['studentId'] = data.childName

        if (locationZone?.onlineSession) {
          submissionArray[i]['isSessionOnline'] = locationZone?.onlineSession
        }
        submissionArray[i]['slots'] = session.slots
        let subject: string[] = []
        let subSubject: string[] = []

        subjects?.forEach((element1) => {
          data.sessionSubject?.forEach((element) => {
            if (!subSubject.includes(element.value))
              subSubject.push(element.value)
            if (element1.subcategories) {
              element1.subcategories.forEach((sub) => {
                if (element.value === sub._id) {
                  if (!subject?.includes(element1._id)) {
                    subject.push(element1._id)
                  }
                }
              })
            }
          })
        })
        submissionArray[i]['subjects'] = JSON.stringify(subject)
        submissionArray[i]['subSubjects'] = JSON.stringify(subSubject)
        let tempLocation
        if (locationZone?.parentTimezoneOffsetZone) {
          tempLocation = {
            ...locationZone,
            parentTimezoneOffsetZone: selectedParent?.metaData
              .timezoneOffsetZone as string,
          }
          const { onlineSession, ...restAddress } = tempLocation
          tempLocation = restAddress
        }

        submissionArray[i]['locationDetails'] = JSON.stringify(tempLocation)
        submissionArray[i]['duration'] = data.sessionDuration
        if (data.onBehalfOf != 'TUTOR') {
          if (createSessionBoolean.isRateChargesSuppress) {
            if (data.sessionReservation && data.onBehalfOf === 'PARENT') {
              submissionArray[i]['reservationNumber'] =
                session.reservationNumber
            }
          } else {
            submissionArray[i]['cardId'] = data.sessionPayment
          }

          let discount: any = 0
          submissionArray[i]['additionalNotes'] = data.additionalInfo
          submissionArray[i]['autoAccept'] = data.autoAccept ? true : false
          if (data.flexibleLocation) {
            submissionArray[i]['isFlexibleOnSessionLocation'] =
              data.flexibleLocation
          }
          submissionArray[i]['promoDiscount'] = discount
          if (promoCodeValue != undefined) {
            if (promoCodeValue.type == 'FLAT') {
              discount = promoCodeValue.credits
                ? promoCodeValue.credits + ''
                : 0 + ''
              totalValue =
                Number(data.sessionDuration as string) * hourlyRate -
                parseInt(discount as string, 10)
            } else {
              let temp =
                Number(data.sessionDuration as string) * hourlyRate -
                promoCodeValue.credits
              discount =
                (temp * promoCodeValue.credits) / 100 -
                promoCodeValue.credits +
                ''
              totalValue = temp - parseInt(discount, 10)
            }
            if (session.promocode) {
              submissionArray[i]['promocode'] = session.promocode
            }
          }

          submissionArray[i]['amount'] = totalValue
        } else {
          submissionArray[i]['type'] = data.onBehalfOf
        }
      })
      formData.append('bookings', JSON.stringify(submissionArray))
      formData.append('type', data.onBehalfOf)
    } else {
      const sessionVals = addASession()

      formData.append(
        'endTime',
        dayjs(sessionVals?.endTime).format('YYYY-MM-DDTHH:mm:ss'),
      )

      formData.append(
        'startTime',
        dayjs(sessionVals?.startTime).format('YYYY-MM-DDTHH:mm:ss'),
      )
      if (selectedTutor) formData.append('tutorId', selectedTutor._id as string)
      if (selectedParent)
        formData.append('parentId', selectedParent._id as string)
      formData.append('studentId', data.childName as string)
      if (locationZone && locationZone?.onlineSession) {
        formData.append(
          'isSessionOnline',
          locationZone.onlineSession.toString(),
        )
      }
      formData.append('slots', JSON.stringify(sessionVals?.ids))

      let subject: string[] = []
      let subSubject: string[] = []

      subjects?.forEach((element1) => {
        data.sessionSubject?.forEach((element) => {
          if (!subSubject.includes(element.value))
            subSubject.push(element.value)
          if (element1.subcategories) {
            element1.subcategories.forEach((sub) => {
              if (element.value === sub._id) {
                if (!subject?.includes(element1._id)) {
                  subject.push(element1._id)
                }
              }
            })
          }
        })
      })

      formData.append('subjects', JSON.stringify(subject))
      formData.append('subSubjects', JSON.stringify(subSubject))
      let tempLocation
      if (locationZone?.parentTimezoneOffsetZone) {
        tempLocation = {
          ...locationZone,
          parentTimezoneOffsetZone: selectedParent?.metaData
            .timezoneOffsetZone as string,
        }
        const { onlineSession, ...restAddress } = tempLocation
        tempLocation = restAddress

        formData.append('locationDetails', JSON.stringify(tempLocation))
      }
      formData.append('duration', data.sessionDuration as string)
    }
    let res
    if (data.onBehalfOf === 'TUTOR') {
      res = await api.post('booking/createBookingByTutor', formData)
      if (res.ok) {
        handleClose()
      }
    } else {
      res = await api.post('booking/createBooking', formData)
      if (res.ok) {
        handleClose()
      }
    }
  })

  const addASession = (paramsData?: {
    sessionBy?: 'parent' | 'tutor'
    paramPromo?: string
    paramReserve?: string
  }) => {
    if (checkHoursZero()) {
      return
    }
    slotStatus = [...slotStatusArray]
    let count = -1
    let slots: any = []
    let ids: any = []
    let startEndTime = ''
    let time = []
    let d = new Date()

    const data = {
      startTime: '',
      endTime: '',
      slots: [],
      promocode: paramsData?.paramPromo,
      reservationNumber: paramsData?.paramReserve,
    }

    if (bookingBy == 'day') {
      slotTime.forEach((element) => {
        count = count + 1
        if (slotStatus[count] == 2) {
          ids.push(allIds[count])
          slots.push(element)
        }
      })
      startEndTime = slots[0]

      time = startEndTime.split('.')

      let newDate = new Date(dayjs(values.sessionDate).format())
      d = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        parseInt(time[0], 10),
        parseInt(time[1], 10),
        0,
      )

      data.startTime = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')
      startEndTime = slots[slots.length - 1]
      time = startEndTime.split('.')

      if (time[1] == '30') {
        time[0] = parseInt(time[0], 10) + 1 + ''
        time[1] = '00'
      } else if (time[1] == '00') time[1] = '30'

      d = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        parseInt(time[0], 10),
        parseInt(time[1], 10),
        0,
      )
      data.endTime = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')
    } else if (bookingBy == 'week') {
      weekDataArray.forEach((element: any) => {
        ids.push(element.id)
      })

      let newDate = new Date(weekDataArray[0].date)

      if (new Date().getTimezoneOffset() * 60000 * -1 < 0)
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate() + 1,
          weekDataArray[0].time.hour,
          weekDataArray[0].time.min,
          0,
        )
      else
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          weekDataArray[0]?.time?.hour,
          weekDataArray[0]?.time?.min,
          0,
        )
      data.startTime = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')

      d = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        weekDataArray[weekDataArray.length - 1]?.time?.hour,
        weekDataArray[weekDataArray.length - 1]?.time?.min,
        0,
      )

      if (d.getMinutes() == 30) {
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          weekDataArray[weekDataArray.length - 1].time.hour + 1,
          0,
          0,
        )
      } else if (d.getMinutes() == 0) {
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          weekDataArray[weekDataArray.length - 1].time.hour,
          30,
          0,
        )
      }
      if (new Date().getTimezoneOffset() * 60000 * -1 < 0)
        d.setDate(d.getDate() + 1)
      data.endTime = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')
    }

    data.slots = ids

    setSessionArray([...sessionArray, data])
    setCreateSessionBoolean({
      ...createSessionBoolean,
      isParenAddSession: false,
    })
    if (paramsData?.sessionBy === 'parent') {
      methods.setValue('promoCode', '')
      methods.setValue('sessionDate', null)
      clearSlots()
    }

    return { endTime: data.endTime, startTime: data.startTime, ids: data.slots }
  }

  const clearSlots = () => {
    setSlotStatusArray([])
    setWeekSlotArray([])
  }

  const isButtonValid = () => {
    let isSlotSelected = false

    if (bookingBy === 'day') {
      isSlotSelected = slotStatusArray?.some((slot: any) => slot == 2)
    } else {
      timeArray.map((_time, i) =>
        weekCount.map((_week, j) => {
          if (weekSlotArray?.[j]?.[i]?.element === 2) {
            isSlotSelected = true
          }
        }),
      )
    }
    if (values.onBehalfOf === 'PARENT') {
      let cardRequired = true
      if (createSessionBoolean.isRateChargesSuppress) {
        cardRequired = false
      }

      if (
        !sessionArray.length ||
        values.flexibleLocation == null ||
        (cardRequired && !values.sessionPayment)
      ) {
        return true
      }
      return false
    } else {
      if (
        !values.sessionSubject ||
        !values.parentName ||
        !values.tutorName ||
        !values.onBehalfOf ||
        !values.sessionDuration ||
        !values.sessionLocation ||
        !values.sessionDate ||
        !isSlotSelected
      ) {
        return true
      }
      return false
    }
  }
  const isAddSessionValid = () => {
    const {
      childName,
      onBehalfOf,
      sessionDuration,
      sessionLocation,
      sessionSubject,
    } = values
    if (
      !childName ||
      !onBehalfOf ||
      !sessionDuration ||
      !sessionLocation ||
      !sessionSubject?.length
    ) {
      return true
    } else return false
  }

  useEffect(() => {
    isAddSessionValid()
    isButtonValid()
  }, [values])

  const onSearchAddress = (searchVal: string) => {
    const filterAddress = address.filter((addr: { [key: string]: string }) =>
      dropDownSearch(addr.label, searchVal),
    )

    setSearchedAddress(filterAddress)
  }

  const onSearchChild = (searchVal: string) => {
    const filterChild = students?.filter((std: any) =>
      dropDownSearch(`${std.firstName} ${std.lastName}`, searchVal),
    )

    setSearchedChild(filterChild || [])
  }

  useEffect(() => {
    methods.setValue('childName', '')
    methods.setValue('sessionLocation', '')
    methods.setValue('tutorName', '')
    methods.setValue('sessionSubject', [])
    methods.setValue('sessionDate', null)
    if (!createSessionBoolean.isRateChargesSuppress)
      methods.setValue('sessionDuration', '')
    setSessionArray([])
    setTutorSearch('')
  }, [selectedParent])

  const checkTutorEndDate = (tutorEndDate?: string) => {
    if (tutorEndDate) {
      let startDate = dayjs()
        .tz(
          locationZone && locationZone.parentTimezoneOffsetZone
            ? locationZone.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .format('YYYY-MM-DDTHH:mm:ss')
      let endDate: any = dayjs(tutorEndDate)
        .tz(
          locationZone && locationZone.parentTimezoneOffsetZone
            ? locationZone.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss')

      let tempNew = dayjs(calendarOptions?.endDate)

      let setDate = dayjs(
        new Date().setFullYear(tempNew.year(), tempNew.month(), tempNew.date()),
      )
      let prevDate = setDate.format('YYYY-MM-DDTHH:mm:ss')

      if (dayjs(endDate).isBefore(prevDate)) {
        endDate = dayjs(endDate).add(1, 'day')
        let tempOption = {
          startDate: startDate,
          endDate: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss'),
        }

        setCalendarOptions(tempOption)
      }
    }
  }

  const onLocationChange = () => {
    let nd = dayjs
      .tz(
        new Date(),
        locationZone && locationZone.parentTimezoneOffsetZone
          ? locationZone.parentTimezoneOffsetZone
          : 'America/New_York',
      )
      .format('YYYY-MM-DDTHH:mm:ss')

    if (localStorage.getItem('partnerEndDate')) {
      let endDate = dayjs
        .tz(
          localStorage.getItem('partnerEndDate'),
          locationZone && locationZone.parentTimezoneOffsetZone
            ? locationZone.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .format('YYYY-MM-DDTHH:mm:ss')

      let tempOption = {
        startDate: dayjs(nd).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: dayjs(endDate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss'),
      }

      setCalendarOptions(tempOption)
    } else {
      let tempOption = {
        startDate: dayjs(nd).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: dayjs(nd).add(121, 'days').format('YYYY-MM-DDTHH:mm:ss'),
      }
      setCalendarOptions(tempOption)
    }

    setDisableBHEndDate()
    checkTutorEndDate(tutorEndDate)
  }

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const setDisableBHEndDate = () => {
    if (createSessionBoolean.isRateChargesSuppress) {
      if (
        selectedParent?.parent.sessionCreditsFromExternalApi.length &&
        values.onBehalfOf === 'PARENT'
      ) {
        selectedParent.parent.sessionCreditsFromExternalApi.forEach(
          (val, key) => {
            if (val.reservationNumber === values.sessionReservation) {
              let nd = dayjs(new Date())
                .tz(
                  locationZone && locationZone.parentTimezoneOffsetZone
                    ? locationZone.parentTimezoneOffsetZone
                    : 'America/New_York',
                )
                .format('YYYY-MM-DDTHH:mm:ss')

              let setEndDate: any = dayjs()
                .add(120, 'days')
                .format('YYYY-MM-DDTHH:mm:ss')
              if (checkDateIn120(val.endDate)) {
                setEndDate = dayjs(
                  new Date(val.endDate).getUTCDate() +
                    '-' +
                    new Date(val.endDate).getUTCMonth() +
                    1 +
                    '-' +
                    new Date(val.endDate).getUTCFullYear(),
                  'DD-MM-YYYY',
                ).format('YYYY-MM-DDTHH:mm:ss[.640Z]')

                setEndDate = dayjs(new Date(val.endDate))
                  .tz(
                    locationZone && locationZone.parentTimezoneOffsetZone
                      ? locationZone.parentTimezoneOffsetZone
                      : 'America/New_York',
                  )
                  .format('YYYY-MM-DDTHH:mm:ss[.640Z]')
              }

              if (
                localStorage.getItem('partnerEndDate') &&
                localStorage.getItem('partnerEndDate') != null
              ) {
                //@ts-ignore
                if (localStorage.getItem('partnerEndDate') < setEndDate) {
                  setEndDate = localStorage.getItem('partnerEndDate')
                }
              }

              let tempOption = {
                startDate: nd,
                endDate: setEndDate,
              }
              setCalendarOptions(tempOption)
              checkTutorEndDate()
            }
          },
        )
      }
    }
  }

  return (
    <>
      {createSessionBoolean.isZeroHours ? (
        <ZeroTutoringHours
          open={createSessionBoolean.isZeroHours}
          onClose={() => {
            setCreateSessionBoolean({
              ...createSessionBoolean,
              isZeroHours: false,
            })
            handleClose()
          }}
        />
      ) : (
        <>
          <ModalWrapper
            wide
            open={open}
            handleClose={handleClose}
            title="Create Session"
          >
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-md-12">
                <FormProvider {...methods}>
                  <form className="w-auto" onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-sm-3 text-left">
                        <label className="add-parent__label">
                          On Behalf of
                        </label>
                      </div>
                      <Controller
                        name="onBehalfOf"
                        control={methods.control}
                        render={({ field }) => (
                          <StyledDropdown
                            {...field}
                            option={onBehalf}
                            placeholder="Select User"
                            className="col-sm-9"
                            onBlur={() => {
                              setCreateSessionBoolean({
                                ...createSessionBoolean,
                                ['onBehalfOfTouched']: true,
                              })
                            }}
                            isError={createSessionBoolean.onBehalfOfTouched}
                            errorMessage={
                              createSessionBoolean.onBehalfOfTouched &&
                              !values.onBehalfOf
                                ? '  '
                                : ''
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="form-group row position-relative align-items-start">
                      <div className="col-sm-3 text-left">
                        <label className="add-parent__label">Parent Name</label>
                      </div>

                      <div className="col-sm-9 position-relative">
                        {!!parentSearch && !values.parentName && (
                          <div className="helper-text">
                            Please Select from List
                          </div>
                        )}
                        <StyledTextfield
                          value={parentSearch}
                          onChange={(e) => {
                            setParentSearch(e.target.value)
                            setParentSearchedBy('typed')
                            if (!e.target.value) setSelectedParent(null)
                          }}
                          onKeyDown={() => {
                            setCreateSessionBoolean({
                              ...createSessionBoolean,
                              ['parentTouched']: true,
                            })
                          }}
                          placeholder="Select Parent"
                          required={true}
                          className="w-100"
                          isError={
                            createSessionBoolean.parentTouched && !parentSearch
                          }
                          errorMessage={
                            createSessionBoolean.parentTouched && !parentSearch
                              ? 'Parent name is required'
                              : ''
                          }
                        />
                        {!!allParents?.length && (
                          <SearchedDropDown
                            options={allParents}
                            className="searched-dropdown bg-white black-border"
                            onSelect={(value: Parent) => {
                              methods.setValue('parentName', value._id)

                              setParentSearch(
                                value.firstName + ' ' + value.lastName,
                              )
                              setAllParents(null)
                              setParentSearchedBy('selected')
                              setSelectedParent(value)
                              settimeZone(value.metaData.abbreviation)

                              if (
                                value.partnershipCode &&
                                value.partnershipCode.length &&
                                value.partnershipCode[0].partnercode
                              ) {
                                setPartnerMsg(
                                  value.partnershipCode[0].partnercode,
                                )

                                if (
                                  value.partnershipCode[0].partnercodeId.endTime
                                ) {
                                  let partnerEndDate =
                                    value.partnershipCode[0].partnercodeId
                                      .endTime
                                  localStorage.setItem(
                                    'partnerEndDate',
                                    partnerEndDate.toString(),
                                  )
                                } else {
                                  localStorage.removeItem('partnerEndDate')
                                }
                              } else {
                                setPartnerMsg('')
                              }

                              let address = value.parent.addresses.find(
                                (addr: any) =>
                                  addr.label.toLowerCase() === 'home',
                              )

                              let tempFilter: BrowseTutorParams = {
                                locationDetails: {
                                  latitude: address?.location
                                    .coordinates[1] as number,
                                  longitude: address?.location
                                    .coordinates[0] as number,
                                  zipCode: address?.zipCode as string,
                                },
                                userId: value._id,
                              }
                              setTutorFilters({
                                ...tutorFilters,
                                ...tempFilter,
                              })
                            }}
                          />
                        )}
                        {!!partnerMsg && (
                          <div className="modal-msgs">
                            This parent is associated with Partnership Code [
                            {partnerMsg}]
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3 text-left">
                        <label className="add-parent__label">Tutor Name</label>
                      </div>
                      <div className="col-sm-9 position-relative">
                        {!!tutorSearch && !values.tutorName && (
                          <div className="helper-text">
                            Please Select from List
                          </div>
                        )}
                        <StyledTextfield
                          value={tutorSearch}
                          onChange={(e) => {
                            setTutorSearch(e.target.value)
                            setTutorSearchedBy('typed')
                            if (!e.target.value) setSelectedTutor(null)
                          }}
                          placeholder="Select Tutor"
                          disabled={!values.parentName || !selectedParent}
                          onKeyDown={() => {
                            setCreateSessionBoolean({
                              ...createSessionBoolean,
                              ['tutorTouched']: true,
                            })
                          }}
                          className="w-100"
                          isError={
                            createSessionBoolean.tutorTouched &&
                            createSessionBoolean.tutorTouched &&
                            !tutorSearch
                          }
                          errorMessage={
                            createSessionBoolean.tutorTouched && !tutorSearch
                              ? 'Tutor name is required'
                              : ''
                          }
                        />
                        {!!browseTutor?.length && (
                          <SearchedDropDown
                            options={browseTutor}
                            className="searched-dropdown bg-white black-border"
                            onSelect={(value: any) => {
                              methods.setValue('tutorName', value._id)
                              setSelectedTutor(value)
                              setTutorSearchedBy('selected')
                              setTutorSearch(
                                value.firstName + ' ' + value.lastName,
                              )
                              setBrowseTutors(null)
                              setHourlyRate(value.tutor.hourlyRate)
                            }}
                          />
                        )}
                        {!!selectedTutor &&
                          !selectedTutor?.tutor?.acceptingNewFamilies && (
                            <div className="helper-text">
                              This tutor is not accepting new families.
                            </div>
                          )}
                        {!!selectedTutor &&
                          selectedTutor?.tutor.notSeenInOtherMarket && (
                            <div className="helper-text">
                              This tutor is flagged to not be eligible for other
                              markets.
                            </div>
                          )}
                        {!!selectedTutor &&
                          !selectedTutor?.tutor.isTutorOfferOnlineClasses && (
                            <div className="helper-text">
                              This tutor does not offer online tutoring.
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3 text-left">
                        <label className="add-parent__label">Child Name</label>
                      </div>
                      <div className="col-sm-9">
                        <Controller
                          name="childName"
                          control={methods.control}
                          render={({ field }) => (
                            <MUISelect {...field} displayEmpty fullWidth>
                              <MenuItem disabled value="" className="d-none">
                                --Select Child Name--
                              </MenuItem>
                              <span>
                                <TextField
                                  value={childSearch}
                                  className="p-2"
                                  onClickCapture={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                  }}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    setChildSearch(e.target.value)
                                    onSearchChild(e.target.value)
                                  }}
                                />
                              </span>
                              {childSearch && searchedChild?.length ? (
                                searchedChild?.map(
                                  (std: any, index: number) => {
                                    return (
                                      <MenuItem key={index} value={std._id}>
                                        {std.firstName + ' ' + std.lastName}
                                      </MenuItem>
                                    )
                                  },
                                )
                              ) : !childSearch && students?.length ? (
                                students?.map((std: any, index: number) => {
                                  return (
                                    <MenuItem key={index} value={std._id}>
                                      {std.firstName + ' ' + std.lastName}
                                    </MenuItem>
                                  )
                                })
                              ) : (
                                <div className="p-2">
                                  parent does not have any child or selected
                                  parent is invalid
                                </div>
                              )}
                            </MUISelect>
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3 text-left">
                        <label className="add-parent__label">
                          Session Subject
                        </label>
                      </div>
                      {createSessionBoolean.subjectError && (
                        <div className="helper-text">
                          You can't select more than three subjects
                        </div>
                      )}
                      <div className="col-sm-9">
                        <Controller
                          name="sessionSubject"
                          control={methods.control}
                          render={({ field }) => (
                            <StyledReactSelect
                              {...field}
                              //@ts-ignore
                              value={field?.value}
                              //@ts-ignore
                              options={subjectArray}
                              isError={(error: boolean) => {
                                setCreateSessionBoolean({
                                  ...createSessionBoolean,
                                  subjectError: error,
                                })
                              }}
                              max={3}
                              onChange={(value) => {
                                //@ts-ignore
                                methods.setValue('sessionSubject', value)
                              }}
                              isMulti
                              placeholder="--Select Subject--"
                              classNamePrefix="react-select"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3 text-left">
                        <label className="add-parent__label">
                          Session Location
                        </label>
                      </div>
                      <div className="col-sm-9">
                        <Controller
                          name="sessionLocation"
                          control={methods.control}
                          render={({ field }) => {
                            return (
                              <MUISelect
                                {...field}
                                value={values.sessionLocation}
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem disabled value="" className="d-none">
                                  --Select Address--
                                </MenuItem>

                                <span>
                                  <TextField
                                    className="p-2"
                                    value={addressSearch}
                                    onClickCapture={(e) => {
                                      e.stopPropagation()
                                      e.preventDefault()
                                    }}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onChange={(e) => {
                                      setAddressSearch(e.target.value)
                                      onSearchAddress(e.target.value)
                                    }}
                                  />
                                </span>

                                {addressSearch && searchedAddress?.length ? (
                                  searchedAddress?.map(
                                    (addr: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={addr.value}
                                          onClick={() => {
                                            setLocationZone(addr.value)
                                            onLocationChange()
                                          }}
                                        >
                                          {addr.label}
                                        </MenuItem>
                                      )
                                    },
                                  )
                                ) : !addressSearch && address?.length ? (
                                  address?.map((addr: any, index: number) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={addr.value}
                                        onClick={() => {
                                          setLocationZone(addr.value)
                                          onLocationChange()
                                        }}
                                      >
                                        {addr.label}
                                      </MenuItem>
                                    )
                                  })
                                ) : (
                                  <div className="p-2">
                                    parent does not have any address or selected
                                    parent is invalid
                                  </div>
                                )}
                              </MUISelect>
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row align-items-baseline">
                      <div className="col-sm-3 text-left">
                        <label className="add-parent__label">
                          Session Duration
                        </label>
                      </div>

                      <div className="col-sm-9">
                        <Controller
                          control={methods.control}
                          name="sessionDuration"
                          render={({ field }) => (
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              value={field.value?.toString()}
                              onChange={(e) => {
                                methods.setValue(
                                  'sessionDuration',
                                  e.target.value.toString(),
                                )
                              }}
                            >
                              <div className="row session-modal-color">
                                {createSessionBoolean.isRateChargesSuppress ? (
                                  <div className="col-sm-6  ">
                                    <Radio value="1.0" className="p-0" />
                                    <span>01:00</span>
                                  </div>
                                ) : (
                                  <>
                                    {radioButtons.map((radBtn) => {
                                      return (
                                        <div
                                          className="col-sm-4"
                                          key={radBtn.value}
                                        >
                                          <Radio value={radBtn.value} />
                                          <span>{radBtn.title}</span>
                                        </div>
                                      )
                                    })}
                                  </>
                                )}
                              </div>
                            </RadioGroup>
                          )}
                        />
                      </div>
                    </div>
                    {values.onBehalfOf !== 'PARENT' ? (
                      <TimeSlotComponent
                        calendarOptions={calendarOptions}
                        onSlotChange={(data, i, state) => {
                          setBookingBy(state)
                          if (state === 'day') slotChange(data, i)
                          if (state === 'week') slotChangeWeek(data, i)
                        }}
                        selectedParent={selectedParent as Parent}
                        slotStatusArray={slotStatusArray}
                        weekSlotArray={weekSlotArray}
                        startDate={values.sessionDate}
                        isTutor={!!selectedTutor}
                        locationZone={locationZone}
                      />
                    ) : (
                      <>
                        <div className="form-group row">
                          <div className="text-center">
                            {sessionArray.length < 10 && (
                              <Button
                                color={isAddSessionValid() ? 'info' : 'success'}
                                disabled={isAddSessionValid()}
                                className="text-center"
                                onClick={() => {
                                  setCreateSessionBoolean({
                                    ...createSessionBoolean,
                                    isParenAddSession: true,
                                  })
                                }}
                              >
                                Add Session
                              </Button>
                            )}
                          </div>
                        </div>

                        {createSessionBoolean.isParenAddSession && (
                          <ParentSchedularModal
                            mode="parent"
                            isTutor={!!selectedTutor}
                            open={createSessionBoolean.isParenAddSession}
                            isRateChargesSuppress={
                              createSessionBoolean.isRateChargesSuppress
                            }
                            calendarOptions={calendarOptions}
                            handleClose={() => {
                              setCreateSessionBoolean({
                                ...createSessionBoolean,
                                isParenAddSession: false,
                              })
                              methods.setValue('sessionDate', null)
                            }}
                            promoCodeValueSetter={(value) => {
                              setPromoCodeValue(value)
                            }}
                            selectedParent={selectedParent as Parent}
                            slotStatusArray={slotStatusArray}
                            weekSlotArray={weekSlotArray}
                            onDateClear={() => {
                              clearSlots()
                            }}
                            onAddSession={(promo, reserve) => {
                              addASession({
                                sessionBy: 'parent',
                                paramPromo: promo,
                                paramReserve: reserve,
                              })
                            }}
                            onSlotChange={(data, i, state) => {
                              setBookingBy(state)
                              if (state === 'day') slotChange(data, i)
                              if (state === 'week') slotChangeWeek(data, i)
                            }}
                          />
                        )}
                        <div className="form-group row align-items-baseline">
                          <div className="col-sm-3 text-left">
                            <label className="add-parent__label">
                              Sessions
                            </label>
                          </div>
                          <div className="col-sm-9 modal-msgs">
                            <TutorSessionsTable
                              timeZone={timeZone}
                              sessions={sessionArray}
                              isRateChargeSuppress={
                                createSessionBoolean.isRateChargesSuppress
                              }
                              onDelete={(id) => {
                                setSessionArray(
                                  sessionArray.filter(
                                    (_sessions, index) => index !== id,
                                  ),
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-3 text-left">
                            <label className="add-parent__label">
                              Auto-Accept <br /> Session(s)
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <Controller
                              control={methods.control}
                              name="autoAccept"
                              render={({ field }) => (
                                <Checkbox {...field} checked={field.value} />
                              )}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-3 text-left">
                            <label className="add-parent__label">
                              Parent is flexible <br />
                              on session <br /> location
                            </label>
                          </div>
                          <div className="col-sm-9">
                            <Controller
                              control={methods.control}
                              name="flexibleLocation"
                              render={({ field }) => (
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  value={
                                    field.value
                                      ? 'true'
                                      : field.value != null
                                      ? 'false'
                                      : ''
                                  }
                                  onChange={(e) => {
                                    methods.setValue(
                                      'flexibleLocation',
                                      e.target.value === 'true',
                                    )
                                  }}
                                >
                                  <div className="row session-modal-color">
                                    <div className="col-sm-4 ">
                                      <Radio value="true" />
                                      <span>Yes</span>
                                    </div>
                                    <div className="col-sm-4 ">
                                      <Radio value="false" />
                                      <span>No</span>
                                    </div>
                                  </div>
                                </RadioGroup>
                              )}
                            />
                          </div>
                        </div>
                        <CardSection
                          isRateChargesSuppress={
                            createSessionBoolean.isRateChargesSuppress
                          }
                          selectedParent={selectedParent}
                          selectedCard={selectedCard}
                          onCardSelection={(id) => {
                            setSelectedCard(id)
                            methods.setValue('sessionPayment', id)
                          }}
                        />

                        <div className="form-group row align-items-baseline">
                          <div className="col-sm-3 text-left">
                            <label className="add-parent__label">
                              Additional
                              <br /> Information
                            </label>
                          </div>
                          <Controller
                            name="additionalInfo"
                            control={methods.control}
                            render={({ field }) => (
                              <StyledTextfield
                                {...field}
                                multiline
                                rows={3}
                                placeholder="Enter additional note(optional)"
                                className="col-sm-9"
                                isError={
                                  !!methods.formState.errors.additionalInfo
                                }
                                errorMessage={methods.formState.errors.additionalInfo?.message?.toString()}
                              />
                            )}
                          />
                        </div>
                      </>
                    )}

                    <div className="row">
                      <div className="col-sm-4 offset-sm-8 text-center button-margin">
                        <Button
                          ref={buttonRef}
                          className="w-100"
                          disabled={isButtonValid()}
                          color={isButtonValid() ? 'info' : 'success'}
                          type="submit"
                        >
                          Create Session Request
                        </Button>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </ModalWrapper>
          <ConfirmAutoAccept
            open={createSessionBoolean.isAutoAccept}
            isAcceptAuto={() => {
              setCreateSessionBoolean({
                ...createSessionBoolean,
                isAutoAccept: true,
              })
              if (buttonRef.current) {
                buttonRef.current.click()
              }
            }}
            notAcceptAuto={() => {
              methods.setValue('autoAccept', false)
              let temp = {
                isAutoAccept: false,
                isAcceptModalShown: false,
              }
              setCreateSessionBoolean({ ...createSessionBoolean, ...temp })
            }}
          />
        </>
      )}
    </>
  )
}

export default CreateSession
