import routes from '../../common/routes'

export const categories = [
  { name: 'Set/Edit Subject', route: routes.categoriesAllSubjects },
  { name: 'Set/Edit fee payable by tutors', route: routes.categoriesAllFee },
  {
    name: 'Set/Edit cancelation policy',
    route: routes.categoriesAllCancelationPolicy,
  },
  {
    name: 'Set/Edit cancelation reason',
    route: routes.categoriesAllCancelationReason,
  },
  {
    name: 'Add/Edit terms of use/licence for parent and tutors to agree',
    route: routes.categoriesTermCondition,
  },
  {
    name: 'Set/Edit Referral terms and conditions',
    route: routes.categoriesReferralTermCondition,
  },
  { name: 'Set/Edit Dispute Reason', route: routes.categoriesDisputeReason },
  { name: 'Set/Edit Decline Reason', route: routes.categoriesDeclineReason },
  { name: 'Set/Edit Reject Reason', route: routes.categoriesRejectReason },
  { name: 'Partnership Code', route: routes.categoriesPartnerShipCode },
  {
    name: 'Bright Horizons Reservation Expirations',
    route: routes.categoriesBhExpiration,
  },
]
