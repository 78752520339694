import { Tutor } from '../../../../types/tutor.type'

export interface TutorTableProps {
  tutorData?: Tutor[] | null
  sortBy?: string | null | undefined
  buttonType?: string | null | undefined
  getTutorDetails: () => Promise<void>
  handleSortBy?: (value: string) => void
}

export interface TutorModalsProps {
  viewDetail?: boolean
  additionalNote?: boolean
  block?: boolean
  unblock?: boolean
  delete?: boolean
  hrStatus?: boolean
  notApproved?: boolean
  runChecker?: boolean
  assignMarketId?: boolean
  bgCheck?: boolean
  sylvanSync?: boolean
  stateCertified?: boolean
  rejectTutor?: boolean
  approveTutor?: boolean
  unRejectTutor?: boolean
}

export interface HandleModalParams {
  e?: any
  icon?: string
  id?: string
  tutor?: Tutor | undefined
}
export const modalInitialValues = {
  viewDetail: false,
  additionalNote: false,
  block: false,
  unblock: false,
  delete: false,
  hrStatus: false,
  notApproved: false,
  runChecker: false,
  assignMarketId: false,
  bgCheck: false,
  stateCertified: false,
  sylvanSync: false,
  rejectTutor: false,
  approveTutor: false,
  unRejectTutor: false,
}
