import { Button } from '@mui/material'
import dayjs from 'dayjs'
import { FC } from 'react'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import { StyledRow } from '../../../custom-bordered-row/StyledRow'
import { Notification } from '../notification-popover/notification.type'
import './notification-details.scss'

interface NotificationDetailsProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<Notification | undefined>>
  notification: Notification | undefined
}

const NotificationDetails: FC<NotificationDetailsProps> = ({
  open,
  setOpen,
  notification,
}) => {
  let duration = notification?.booking?.slots?.length
    ? notification?.booking?.slots?.length / 2
    : 0

  const handleClose = () => {
    setOpen(undefined)
  }
  return (
    <ModalWrapper open={open} handleClose={handleClose} title="View Detail">
      <div className="w-100">
        <StyledRow field="Field Name" description="Description" />
        <StyledRow
          field="Parent Name"
          description={
            <>
              {notification?.parent?.firstName +
                ' ' +
                notification?.parent?.lastName}
            </>
          }
        />
        <StyledRow
          field="Child Name"
          description={
            <>
              {notification?.student?.firstName +
                ' ' +
                notification?.student?.lastName}
            </>
          }
        />
        <StyledRow
          field="Tutor Name"
          description={<>{notification?.tutor?.firstName}</>}
        />
        <StyledRow
          field="Subject"
          description={
            <>
              {notification?.booking?.subjects.map((subject) => subject.name)}
            </>
          }
        />
        <StyledRow
          field="Subsubject"
          description={
            <>
              {notification?.booking?.subjects.map((subject) =>
                subject?.subcategories?.map((sub) => sub.name),
              )}
            </>
          }
        />
        <StyledRow field="Type" description={<>{notification?.type}</>} />
        <StyledRow field="Message" description={<>{notification?.message}</>} />
        <StyledRow
          field="Partial Amount Left"
          description={<>{notification?.booking?.payments.partialAmountLeft}</>}
        />
        <StyledRow
          field="Payment Status"
          description={<>{notification?.booking?.payments.paymentStatus}</>}
        />
        <StyledRow
          field="Session Duration"
          description={<>{duration + duration > 1 ? 'hours' : 'hour'}</>}
        />
        <StyledRow
          field="Session Date and Time"
          description={
            <>
              {dayjs
                .tz(
                  notification?.booking?.startTime,
                  notification?.booking?.parentTimezoneOffsetZone,
                )
                .format('MMMM D, YYYY')}
              &nbsp; &#124; &nbsp;
              {dayjs
                .tz(
                  notification?.booking?.startTime,
                  notification?.booking?.parentTimezoneOffsetZone,
                )
                .format('h:mm A')}
              -
              {dayjs
                .tz(
                  notification?.booking?.endTime,
                  notification?.booking?.parentTimezoneOffsetZone,
                )
                .format('h:mm A')}
              &nbsp;
              {notification?.booking?.parentAbbreviation}
            </>
          }
        />
        <StyledRow
          field="Cancelation Date"
          description={
            <>
              {dayjs
                .tz(
                  notification?.booking?.cancelledAt,
                  notification?.booking?.parentTimezoneOffsetZone,
                )
                .format('MM-DD-YYYY')}
            </>
          }
        />
        <StyledRow
          field="Cancelation Status"
          description={
            <>
              {notification?.booking?.status === 'CANCELED_BY_PARENT'
                ? 'Parent'
                : notification?.booking?.status === 'CANCELED_BY_Tutor'
                ? 'Tutor'
                : 'Admin'}
            </>
          }
        />
        <StyledRow
          field="Promo Code"
          description={
            <>
              {notification?.booking &&
              notification.booking.payments &&
              notification.booking.payments.promocode
                ? notification.booking.payments.promocode
                : '-'}
            </>
          }
        />
        <StyledRow
          field="Address"
          description={
            <>
              {notification?.booking?.locationDetails?.addressLine1
                ? notification?.booking?.locationDetails?.addressLine1
                : ''}
              {notification?.booking?.locationDetails?.addressLine2
                ? ',' + notification?.booking?.locationDetails?.addressLine2
                : ''}
              {notification?.booking?.locationDetails?.city
                ? ',' + notification?.booking?.locationDetails?.city
                : ''}
              {notification?.booking?.locationDetails?.state
                ? ',' + notification?.booking?.locationDetails?.state
                : ''}
              {notification?.booking?.locationDetails?.zipCode
                ? ',' + notification?.booking?.locationDetails?.zipCode
                : ''}
            </>
          }
        />
        <StyledRow
          field="Admin Fee"
          description={
            <>
              {notification?.booking?.payments.adminFee.discount
                ? notification?.booking.payments.adminFee.type == 'FLAT'
                  ? '$'
                  : ''
                : ''}
              {notification?.booking?.payments.adminFee.discount}
              {notification?.booking?.payments.adminFee.type == 'PERCENT'
                ? '%'
                : ''}
            </>
          }
        />
        <StyledRow
          field="Status"
          description={<>{notification?.booking?.status}</>}
        />
        <div className="mt-4 me-3 offset-8 row">
          <Button onClick={handleClose} color="success">
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default NotificationDetails
