import { RadioGroup, Radio, Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { CancellationReason } from '../../../../../../types/constants.type'
import { api } from '../../../../../../utils'
import './add-edit-cancellation-reason.scss'

interface AddEditCancellationReasonI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  editReason?: CancellationReason
  mode: 'Edit' | 'Add'
  reasonType: 'PARENT' | 'TUTOR'
}

const AddEditCancellationReason: FC<AddEditCancellationReasonI> = ({
  mode,
  onClose,
  open,
  onSuccess,
  editReason,
  reasonType,
}) => {
  const [cancellationReason, setCancellationReason] = useState('')
  const [reasonError, setReasonError] = useState(false)
  const [reasonLabel, setReasonLabel] = useState('')

  useEffect(() => {
    if (mode === 'Edit') {
      if (editReason?.text) setCancellationReason(editReason.text)
      if (editReason?.label) setReasonLabel(editReason.label)
    }
  }, [editReason])

  const isValid = () => {
    if (!cancellationReason || !reasonLabel) {
      return false
    } else return true
  }

  const addEditCancellationReason = async () => {
    const formData = new FormData()

    formData.append('reason', cancellationReason)
    formData.append('label', reasonLabel)
    formData.append('type', reasonType)

    let res
    if (mode === 'Add') {
      res = await api.post('admin/addCancellationReason', formData)
    } else {
      res = await api.put(
        `admin/editCancellationReason/${editReason?._id}`,
        formData,
      )
    }
    if (res && res.ok) {
      onClose()
      onSuccess()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      wide
      title={mode + ' Cancelation Reason'}
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div
            className={`form-group row ${
              !cancellationReason && reasonError
                ? 'align-items-start'
                : 'align-items-center'
            }`}
          >
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Cancelation Reason</label>
            </div>

            <StyledTextfield
              placeholder="Cancelation Reason"
              className="col-sm-8"
              value={cancellationReason}
              onChange={(e) => {
                setCancellationReason(e.target.value)
              }}
              onKeyDown={() => {
                setReasonError(true)
              }}
              isError={!cancellationReason && reasonError}
              errorMessage={
                !cancellationReason && reasonError
                  ? 'Cancelation reason is required'
                  : ''
              }
            />
          </div>
          <div className={`form-group row align-items-center`}>
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Label</label>
            </div>
            <div className="col-sm-8 line-height-1">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={reasonLabel}
                onChange={(e) => {
                  setReasonLabel(e.target.value)
                }}
              >
                <div className="row">
                  <div className="col-sm-4 mart20px">
                    <Radio value="NO_SHOW" />
                    <span>No Show</span>
                  </div>
                  <div className="col-sm-4 mart20px">
                    <Radio value="OTHER" />
                    <span>Other</span>
                  </div>
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="form-group row pe-1">
            <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-2">
              <Button
                color={isValid() ? 'success' : 'info'}
                disabled={!isValid()}
                className="w-100"
                onClick={addEditCancellationReason}
              >
                {mode === 'Add' ? 'Add' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditCancellationReason
