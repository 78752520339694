import { Button, SelectChangeEvent } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import useSingleTutor from '../../../../components/get-single-tutor/GetSingleTutor'
import ImageComponent from '../../../../components/image-component/ImageComponent'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import MarketDropdown from '../../../../components/styled-dropdown/MarketDropdown'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { Tutor } from '../../../../types/tutor.type'
import ConfirmNationWide from '../assign-marketId/components/confirm-nation-wide/ConfirmNationWide'
import { TutorModalsProps } from '../tutor-table/tutor-table.type'
import ConfirmApprove from './components/ConfirmApprove'

interface ApproveTutorProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<TutorModalsProps>>
  modalName: string
  tutorId?: string
  tutorData?: Tutor[] | null | undefined
  onSuccess?: () => void
}

const ApproveTutor: FC<ApproveTutorProps> = ({
  open,
  setOpen,
  modalName,
  tutorData,
  tutorId,
  onSuccess,
}) => {
  const { marketData } = useAppContext()
  const [marketId, setMarketId] = useState('')
  const [confirmApprove, setConfirmApprove] = useState(false)
  const [confirmNationWide, setConfirmNationWide] = useState(false)

  const singleTutor = useSingleTutor({
    tutorData,
    tutorId,
    dependant: open.approveTutor,
  })

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  useEffect(() => {
    setMarketId(
      marketId
        ? marketId
        : singleTutor?.marketId && singleTutor?.marketId.marketId
        ? singleTutor?.marketId._id
        : singleTutor?.marketId && singleTutor?.marketId._id
        ? singleTutor?.marketId._id
        : '',
    )
  }, [singleTutor])
  const marketChange = (event: SelectChangeEvent) => {
    setMarketId(event.target.value)
  }

  const checkMarket = () => {
    const marketName = marketData?.filter((market) => {
      if (market._id === marketId) return market
    })
    if (
      marketName?.[0]?.marketName?.toLowerCase() === 'nationwide' &&
      singleTutor?.tutor.isOfferInPersonTutoring
    ) {
      setConfirmNationWide(true)
    } else {
      setConfirmApprove(true)
    }
  }

  return (
    <>
      <ModalWrapper
        open={open.approveTutor}
        handleClose={handleClose}
        title="Approve Tutor"
        wide
      >
        <div className="w-100 d-flex justify-content-center ">
          <ImageComponent profilePic={singleTutor?.profilePicture} />
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <form className="w-auto">
              <div className="form-group row">
                <div className="col-sm-4 text-left">Name</div>
                <StyledTextfield
                  className="col-sm-8"
                  disabled
                  value={singleTutor?.firstName + ' ' + singleTutor?.lastName}
                />
              </div>

              <div className="form-group row">
                <div className="col-sm-4 text-left">Market Name</div>
                <div className="col-sm-8">
                  <MarketDropdown
                    option={marketData}
                    value={marketId}
                    placeholder="--Select Market Name--"
                    handleChange={marketChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8 offset-sm-4 text-center button-margin">
                  <Button
                    className="w-100"
                    color={!!marketId ? 'success' : 'info'}
                    onClick={checkMarket}
                  >
                    Assign Market
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ModalWrapper>
      {confirmApprove && (
        <ConfirmApprove
          open={confirmApprove}
          setOpen={setConfirmApprove}
          marketId={marketId}
          tutorId={tutorId}
          onSuccess={() => {
            onSuccess?.()
            handleClose()
          }}
          onNo={handleClose}
        />
      )}
      {confirmNationWide && (
        <ConfirmNationWide
          open={confirmNationWide}
          handleNo={handleClose}
          handleYes={() => {
            setConfirmApprove(true)
          }}
          modalText={
            'This tutor has selected that they offer in-person tutoring. If the tutor is put in the Nationwide market, this setting will be changed to No. Do you want to continue?'
          }
        />
      )}
    </>
  )
}

export default ApproveTutor
