import { TableCell, TableRow, Tooltip } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'

import { api } from '../../../../../../utils'
import { ReservationExpiration } from '../AllBhExpiration.types'
import AddEditExpiration from '../add-edit-expiration/AddEditExpiration'

interface ExpirationTableBodyI {
  reservation: ReservationExpiration
  getExpirations: () => Promise<void>
  disableDates?: Dayjs[]
}

const size = 15
const ExpirationTableBody: FC<ExpirationTableBodyI> = ({
  reservation,
  getExpirations,
  disableDates,
}) => {
  const isSuperAdmin = localStorage.getItem('isSuperAdmin') ? true : false
  let todayDate = new Date().toUTCString()
  const today = new Date(todayDate).toISOString()

  const [editExpiration, setEditExpiration] = useState(false)

  const deleteExpiration = async () => {
    const formData = new FormData()
    formData.append('isDeleted', 'true')
    const res = await api.put(
      `admin/deleteReservationExpiration/${reservation._id}`,
      formData,
    )
    if (res.ok) {
      toast.success('Deleted Successfully!', { id: 'global' })
      getExpirations()
    }
  }

  const val: any =
    new Date(reservation.reservationDate).getUTCMonth() +
    1 +
    '/' +
    new Date(reservation.reservationDate).getUTCDate() +
    '/' +
    new Date(reservation.reservationDate).getUTCFullYear()
  return (
    <>
      <TableRow className="body-table">
        <TableCell>{val}</TableCell>
        <TableCell>{reservation.expirationDays}</TableCell>
        <TableCell>
          {isSuperAdmin && (
            <>
              {dayjs(today).isBefore(dayjs(reservation.reservationDate)) && (
                <span className="d-flex align-items-center">
                  <Tooltip title="Edit">
                    <span
                      className="ms-3 me-3 menu-icon text-center"
                      onClick={() => {
                        setEditExpiration(true)
                      }}
                    >
                      <FaPencilAlt size={size} />
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <span
                      className="ms-3 me-3 menu-icon text-center"
                      onClick={() => {
                        deleteExpiration()
                      }}
                    >
                      <FaTrashAlt size={size} />
                    </span>
                  </Tooltip>
                </span>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      {editExpiration && (
        <AddEditExpiration
          open={editExpiration}
          onClose={() => {
            setEditExpiration(false)
          }}
          onSuccess={getExpirations}
          mode="Edit"
          editExpiration={reservation}
          disableDates={disableDates}
        />
      )}
    </>
  )
}

export default ExpirationTableBody
