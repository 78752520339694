import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import {
  CaseType1,
  ReferralCases,
} from '../../../../../../types/constants.type'
import { api } from '../../../../../../utils'
import { CONSTANT } from '../../../../../../utils/constants'
import { referralTerms } from '../../data'
import '../rTerm-and-conditions-component/rTerm-and-conditions-component.scss'

interface EditRTermAndConditionI {
  caseNumber: string
  open: boolean
  onClose: () => void
  referralCases?: ReferralCases
  getAHeading: () => 'Tutor A Discount:' | 'Parent A Discount:' | undefined
  getBHeading: () => 'Tutor B Discount:' | 'Parent B Discount:' | undefined
  getTriggerPoints: () => string | undefined
  onSuccess: () => void
}

const EditRTermAndCondition: FC<EditRTermAndConditionI> = ({
  caseNumber,
  getAHeading,
  getBHeading,
  getTriggerPoints,
  onClose,
  open,

  onSuccess,
  referralCases,
}) => {
  const [discount1, setDiscount1] = useState('')
  const [discount2, setDiscount2] = useState('')
  const [type1, setType1] = useState('')
  const [type2, setType2] = useState('')

  const getPolicyName = () => {
    if (caseNumber === '1') {
      return referralTerms.case1.policyName
    } else if (caseNumber === '2') {
      return referralTerms.case2.policyName
    } else if (caseNumber === '3') {
      return referralTerms.case3.policyName
    } else if (caseNumber === '4') {
      return referralTerms.case4.policyName
    } else if (caseNumber === '5') {
      return referralTerms.case5.policyName
    }
  }
  const feeTypeOption = [
    { id: '$', value: 'FLAT', option: '$' },
    { id: '%', value: 'PERCENT', option: '%' },
  ]

  useEffect(() => {
    if (referralCases?.case1 && caseNumber === '1') {
      setDiscount1(referralCases?.case1.tutorADiscount.discount.toString())
      setType1(
        referralCases?.case1.tutorADiscount.type === 'FLAT'
          ? 'FLAT'
          : 'PERCENT',
      )
      setDiscount2(referralCases?.case1.tutorBDiscount.discount.toString())
      setType2(
        referralCases?.case1.tutorBDiscount.type === 'FLAT'
          ? 'FLAT'
          : 'PERCENT',
      )
    }
    if (referralCases?.case2 && caseNumber === '2') {
      setDiscount1(referralCases?.case2.tutorDiscount.discount.toString())
      setType1(
        referralCases?.case2.tutorDiscount.type === 'FLAT' ? 'FLAT' : 'PERCENT',
      )
      setDiscount2(referralCases?.case2.parentDiscount.discount.toString())
      setType2(
        referralCases?.case2.parentDiscount.type === 'FLAT'
          ? 'FLAT'
          : 'PERCENT',
      )
    }
    if (referralCases?.case3 && caseNumber === '3') {
      setDiscount1(referralCases?.case3.tutorDiscount.discount.toString())
      setType1(
        referralCases?.case3.tutorDiscount.type === 'FLAT' ? 'FLAT' : 'PERCENT',
      )
      setDiscount2(referralCases?.case3.parentDiscount.discount.toString())
      setType2(
        referralCases?.case3.parentDiscount.type === 'FLAT'
          ? 'FLAT'
          : 'PERCENT',
      )
    }
    if (referralCases?.case4 && caseNumber === '4') {
      setDiscount2(referralCases?.case4.tutorDiscount.discount.toString())
      setType2(
        referralCases?.case4.tutorDiscount.type === 'FLAT' ? 'FLAT' : 'PERCENT',
      )
      setDiscount1(referralCases?.case4.parentDiscount.discount.toString())
      setType1(
        referralCases?.case4.parentDiscount.type === 'FLAT'
          ? 'FLAT'
          : 'PERCENT',
      )
    }
    if (referralCases?.case5 && caseNumber === '5') {
      setDiscount1(referralCases?.case5.parentADiscount.discount.toString())
      setType1(
        referralCases?.case5.parentADiscount.type === 'FLAT'
          ? 'FLAT'
          : 'PERCENT',
      )
      setDiscount2(referralCases?.case5.parentBDiscount.discount.toString())
      setType2(
        referralCases?.case5.parentBDiscount.type === 'FLAT'
          ? 'FLAT'
          : 'PERCENT',
      )
    }
  }, [])

  const isValid = () => {
    if (!discount1 || !discount2 || !type1 || !type2) {
      return false
    } else return true
  }

  const updateTerms = async () => {
    const formData = new FormData()

    formData.append('case1', JSON.stringify(referralCases?.case1))
    if (referralCases?.case2) {
      const { _id, ...restCase2 } = referralCases?.case2
      formData.append('case2', JSON.stringify(restCase2))
    }
    if (referralCases?.case3) {
      const { _id, ...restCase3 } = referralCases?.case3
      formData.append('case3', JSON.stringify(restCase3))
    }
    if (referralCases?.case4) {
      const { _id, ...restCase4 } = referralCases?.case4
      formData.append('case4', JSON.stringify(restCase4))
    }

    formData.append('case5', JSON.stringify(referralCases?.case5))
    if (referralCases?.case1 && caseNumber === '1') {
      formData.delete('case1')
      let tempCase1: CaseType1 = {
        tutorADiscount: { discount: parseInt(discount1), type: type1 },
        tutorBDiscount: { discount: parseInt(discount2), type: type2 },
      }
      formData.append('case1', JSON.stringify(tempCase1))
    }
    if (referralCases?.case2 && caseNumber === '2') {
      formData.delete('case2')
      let tempCase2 = {
        tutorDiscount: { discount: parseInt(discount1), type: type1 },
        parentDiscount: { discount: parseInt(discount2), type: type2 },
      }
      formData.append('case2', JSON.stringify(tempCase2))
    }
    if (referralCases?.case3 && caseNumber === '3') {
      formData.delete('case3')
      let tempCase3 = {
        tutorDiscount: { discount: parseInt(discount1), type: type1 },
        parentDiscount: { discount: parseInt(discount2), type: type2 },
      }
      formData.append('case3', JSON.stringify(tempCase3))
    }
    if (referralCases?.case4 && caseNumber === '4') {
      formData.delete('case4')
      let tempCase4 = {
        parentDiscount: { discount: parseInt(discount1), type: type1 },
        tutorDiscount: { discount: parseInt(discount2), type: type2 },
      }
      formData.append('case4', JSON.stringify(tempCase4))
    }
    if (referralCases?.case5 && caseNumber === '5') {
      formData.delete('case5')
      let tempCase = {
        parentADiscount: { discount: parseInt(discount1), type: type1 },
        parentBDiscount: { discount: parseInt(discount2), type: type2 },
      }
      formData.append('case5', JSON.stringify(tempCase))
    }

    const res = await api.put('admin/updateReferralCases', formData)

    if (res.ok) {
      onClose()
      onSuccess()
    }
  }

  const getAError = () => {
    if (
      (referralCases?.case1 && caseNumber === '1') ||
      (referralCases?.case2 && caseNumber === '2') ||
      (referralCases?.case3 && caseNumber === '3')
    ) {
      return 'Tutor A discount is required'
    } else if (
      (referralCases?.case4 && caseNumber === '4') ||
      (referralCases?.case5 && caseNumber === '5')
    ) {
      return 'Parent A discount is required'
    }
  }
  const getBError = () => {
    if (
      (referralCases?.case1 && caseNumber === '1') ||
      (referralCases?.case4 && caseNumber === '4')
    ) {
      return 'Tutor B discount is required'
    } else if (
      (referralCases?.case2 && caseNumber === '2') ||
      (referralCases?.case3 && caseNumber === '3') ||
      (referralCases?.case5 && caseNumber === '5')
    ) {
      return 'Parent B discount is required'
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="Edit Referral Term and Conditions"
      wide
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div className={`form-group row align-items-center`}>
            <div className="col-sm-3 text-left">
              <label className="add-child__label">Policy Name</label>
            </div>

            <StyledTextfield
              placeholder="Enter Policy Name"
              className="col-sm-9"
              disabled
              value={getPolicyName()}
            />
          </div>
          <div className={`form-group row align-items-center`}>
            <div className="col-sm-3 text-left">
              <label className="add-child__label">Trigger Point</label>
            </div>

            <StyledTextfield
              placeholder="Enter Policy Name"
              className="col-sm-9"
              disabled
              value={getTriggerPoints()}
            />
          </div>
          <div className={`form-group row align-items-center`}>
            <div className="col-sm-3 text-left">
              <label className="add-child__label">{getAHeading()}</label>
            </div>

            <div className="col-sm-1 pe-0 ">
              <StyledDropdown
                className="dropDown-width"
                option={feeTypeOption}
                value={type1}
                onChange={(e) => {
                  setType1(e.target.value)
                }}
              />
            </div>

            <StyledTextfield
              placeholder={`Enter ${getAHeading()}`}
              className="col-sm-8 ps-1 "
              value={discount1}
              onChange={(e) => {
                if (
                  CONSTANT.numberRegex.test(e.target.value) ||
                  e.target.value === ''
                )
                  setDiscount1(e.target.value)
              }}
            />
            {!discount1 && referralCases && (
              <div className="helper-text">{getAError()}</div>
            )}
          </div>
          <div className={`form-group row align-items-center`}>
            <div className="col-sm-3 text-left">
              <label className="add-child__label">{getBHeading()}</label>
            </div>
            <div className="col-sm-1 pe-0 ">
              <StyledDropdown
                className="dropDown-width"
                option={feeTypeOption}
                value={type2}
                onChange={(e) => {
                  setType2(e.target.value)
                }}
              />
            </div>

            <StyledTextfield
              placeholder={`Enter ${getBHeading()}`}
              className="col-sm-8 ps-1 "
              value={discount2}
              onChange={(e) => {
                if (
                  CONSTANT.numberRegex.test(e.target.value) ||
                  e.target.value === ''
                )
                  setDiscount2(e.target.value)
              }}
              isError={!discount2}
            />
            {!discount2 && referralCases && (
              <div className="helper-text">{getBError()}</div>
            )}
          </div>
          <div className="form-group row pe-0">
            <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-0">
              <Button
                color={isValid() ? 'success' : 'info'}
                disabled={!isValid()}
                className="w-100"
                onClick={updateTerms}
              >
                Save{' '}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default EditRTermAndCondition
