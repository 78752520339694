import { Button, TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useState } from 'react'

import { Disputes } from '../../hooks/disputes.types'
import AddDisputeComment from '../add-dispute-comment/AddDisputeComment'
import DisputeDetails from '../dispute-details/DisputeDetails'
import ResolveDisputes from '../resolve-disputes/ResolveDisputes'
import { getDisputeButtons } from './data'

dayjs.extend(utc)
dayjs.extend(tz)

interface DisputeReasonTableBodyI {
  dispute: Disputes
  status?: 'UNRESOLVED' | 'RESOLVED'
  getAllDisputes: () => Promise<void>
}

interface DisputeModalsI {
  addComment: boolean
  viewDetails: boolean
  resolveDispute: boolean
}

const defaultModals: DisputeModalsI = {
  addComment: false,
  resolveDispute: false,
  viewDetails: false,
}

const DisputeTableBody: FC<DisputeReasonTableBodyI> = ({
  status,
  dispute,
  getAllDisputes,
}) => {
  const [disputeModals, setDisputeModals] =
    useState<DisputeModalsI>(defaultModals)

  const startTime1 = dayjs(dispute.bookingId.startTime)
    .tz(dispute.bookingId.parentTimezoneOffsetZone)
    .format('LLLL')

  const resolvedAt1 = dayjs
    .tz(dispute.resolvedAt, dispute.bookingId.parentTimezoneOffsetZone)
    .format('LLLL')

  return (
    <>
      <TableRow className="row-bb cell-no-bb">
        <TableCell>{dispute.disputeId}</TableCell>
        <TableCell>
          {dispute.parentId && dispute.parentId.firstName
            ? dispute.parentId.firstName + ' ' + dispute.parentId.lastName
            : '-'}
        </TableCell>
        <TableCell>
          {dispute.tutorId && dispute.tutorId.firstName
            ? dispute.tutorId.firstName + ' ' + dispute.tutorId.lastName
            : '-'}
        </TableCell>
        <TableCell>
          {dayjs(dispute.createdAt).utc().format('MM-DD-YYYY')}
        </TableCell>
        <TableCell>
          {dispute.bookingId && dispute.bookingId.startTime
            ? dayjs(startTime1).format('MM-DD-YYYY')
            : '-'}
        </TableCell>
        <TableCell>
          {dispute.marketId && dispute.marketId.marketName
            ? dispute.marketId.marketName
            : '-'}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'break-spaces' }}>
          {dispute.reason}
        </TableCell>
        {status === 'RESOLVED' && (
          <TableCell>{dayjs(resolvedAt1).format('MM-DD-YYYY')}</TableCell>
        )}
        <TableCell sx={{ display: 'flex', alignItems: 'start' }}>
          <span
            className="d-flex flex-wrap dispute-buttons w-100 "
            style={{ paddingRight: '16px' }}
          >
            {getDisputeButtons(status as string).map((button) => {
              return (
                <Button
                  key={button.name}
                  variant="text"
                  name={button.name}
                  className="me-1 ms-1"
                  onClick={() => {
                    setDisputeModals({ ...disputeModals, [button.name]: true })
                  }}
                >
                  {button.title}
                </Button>
              )
            })}
          </span>
        </TableCell>
      </TableRow>

      {disputeModals.viewDetails && (
        <DisputeDetails
          dispute={dispute}
          open={disputeModals.viewDetails}
          onClose={() => {
            setDisputeModals({ ...disputeModals, viewDetails: false })
          }}
        />
      )}
      {disputeModals.addComment && (
        <AddDisputeComment
          open={disputeModals.addComment}
          editDispute={dispute}
          onClose={() => {
            setDisputeModals({ ...disputeModals, addComment: false })
          }}
          onSuccess={getAllDisputes}
        />
      )}
      {disputeModals.resolveDispute && (
        <ResolveDisputes
          open={disputeModals.resolveDispute}
          onClose={() => {
            setDisputeModals({ ...disputeModals, resolveDispute: false })
          }}
          dispute={dispute}
          onSuccess={getAllDisputes}
        />
      )}
    </>
  )
}

export default DisputeTableBody
