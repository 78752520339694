import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import { useMemo } from 'react'

import { AlteredBooking } from '../all-sessions/components/sessions-table/SessionsTable'
import { Booking } from '../sessions.type'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(isSameOrAfter)

interface Props {
  sessionsData: Booking[]
}

export const getAlteredBooking = (s: Booking) => {
  const session = _.cloneDeep(s) as AlteredBooking

  let totalAmount = 0
  if (session.payments?.amountCharged) {
    totalAmount += session.payments.amountCharged
  }
  if (session.payments?.partialAmountLeft) {
    totalAmount += session.payments.partialAmountLeft
  }
  session.totalCost = totalAmount

  switch (session.status) {
    case 'NEW': {
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.cancelVisible = true
      session.rescheduleVisible = false
      session.acceptSession = true
      session.declineSession = true
      session.status = 'New'
      break
    }
    case 'NEW_TUTOR': {
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.cancelVisible = true
      session.rescheduleVisible = false
      session.acceptSession = true
      session.declineSession = true
      session.status = 'New'
      session.oldStatus = 'NEW_TUTOR'
      break
    }
    case 'ACCEPTED_BY_TUTOR': {
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.cancelVisible = true
      session.rescheduleVisible = true

      session.acceptSession = false
      session.declineSession = false
      session.status = 'Accepted By Tutor'
      break
    }

    case 'RESCHEDULE_REQUEST_BY_PARENT': {
      session.cancelVisible = true
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.rescheduleData.startTime1 = dayjs(
        session.rescheduleData.startTime,
      ).tz(session.parentTimezoneOffsetZone)
      session.rescheduleData.endTime1 = dayjs(
        session.rescheduleData.endTime,
      ).tz(session.parentTimezoneOffsetZone)
      if (!session.rescheduleData.parentAbbreviation) {
        session.rdAbb = dayjs.tz(
          session.rescheduleData.startTime,
          session.parentTimezoneOffsetZone,
        )
      }
      session.cancelVisible = true
      session.rescheduleVisible = true
      session.acceptSession = false
      session.declineSession = false
      session.status = 'Reschedule Request By Parent'
      break
    }

    case 'RESCHEDULE_REQUEST_BY_TUTOR': {
      session.cancelVisible = true
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.rescheduleData.startTime1 = dayjs(
        session.rescheduleData.startTime,
      ).tz(session.parentTimezoneOffsetZone)
      session.rescheduleData.endTime1 = dayjs(
        session.rescheduleData.endTime,
      ).tz(session.parentTimezoneOffsetZone)
      session.cancelVisible = true
      session.rescheduleVisible = true
      session.acceptSession = false
      session.declineSession = false
      session.status = 'Reschedule Request By Tutor'
      break
    }

    case 'RESCHEDULE_REJECTED': {
      session.cancelVisible = true
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      let sessionDate = dayjs
        .tz(session.startTime1, session.parentTimezoneOffsetZone)
        .format('LLL')
      let curruntDate = dayjs
        .tz(new Date(), session.parentTimezoneOffsetZone)
        .format('LLL')

      if (dayjs(sessionDate).isSameOrAfter(curruntDate)) {
        session.cancelVisible = true

        if (dayjs(sessionDate).isSameOrAfter(curruntDate))
          session.rescheduleVisible = true
        else session.rescheduleVisible = false
      } else {
        session.cancelVisible = false
        session.rescheduleVisible = false
      }
      if (session.rescheduleRejectedBy == 'BY_TUTOR')
        session.status = 'Reschedule Rejected By Tutor'
      else if (session.rescheduleRejectedBy == 'BY_PARENT')
        session.status = 'Reschedule Rejected By Parent'
      session.cancelVisible = true
      session.rescheduleVisible = true
      break
    }

    case 'REJECTED_BY_TUTOR': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      if (session.noResponseByTutor) {
        session.status = 'No Response By Tutor'
      } else {
        session.status = 'Rejected By Tutor'
      }
      break
    }
    case 'REJECTED_BY_PARENT': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      if (session.noResponseByParent) {
        session.status = 'No Response By Parent'
      } else {
        session.status = 'Rejected By Parent'
      }
      break
    }

    case 'CANCELLED_BY_PARENT': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      session.status = 'Canceled By Parent'
      break
    }
    case 'CANCELLED_BY_TUTOR': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      session.status = 'Canceled By Tutor'
      break
    }
    case 'DISPUTE_RAISED': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      session.status = 'Dispute Raised'
      break
    }
    case 'CANCELLED_BY_ADMIN': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      session.status = 'Canceled By Admin'
      break
    }

    case 'SESSION_STARTED': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.sessionStartTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.editActualTimes = true

      session.declineSession = false
      session.status = 'Session Started'
      break
    }

    case 'HOLD_FAIL': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.acceptSession = false
      session.declineSession = false
      session.status = 'Hold Fail'
      break
    }

    case 'ON_THE_WAY': {
      session.cancelVisible = true
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )

      let sessionDate = new Date(session.startTime1.toISOString())
      let curruntDate = new Date()
      if (sessionDate >= curruntDate) {
        session.cancelVisible = true
        session.rescheduleVisible = true
      } else {
        session.cancelVisible = false
        session.rescheduleVisible = false
      }

      session.acceptSession = false
      session.declineSession = false
      session.status = 'On The Way'
      break
    }

    case 'FINISHED': {
      session.rescheduleVisible = false
      session.cancelVisible = false

      session.startTime1 = dayjs(session.sessionStartTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.endTime1 = dayjs(session.sessionEndTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.acceptSession = false
      session.declineSession = false
      session.cancelVisible = true
      session.editActualTimes = true
      session.status = 'Finished'
      break
    }
    case 'COMPLETED': {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.sessionStartTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.endTime1 = dayjs(session.sessionEndTime).tz(
        session.parentTimezoneOffsetZone,
      )

      session.acceptSession = false
      session.declineSession = false
      session.status = 'Completed'
      if (session.payments.partialAmountCharged) {
        session.totalCost =
          session.totalCost + session.payments.partialAmountCharged
      }
      break
    }
    default: {
      session.rescheduleVisible = false
      session.cancelVisible = false
      session.startTime1 = dayjs(session.startTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.endTime1 = dayjs(session.endTime).tz(
        session.parentTimezoneOffsetZone,
      )
      session.acceptSession = false
      session.declineSession = false
      session.cancelVisible = true
      session.rescheduleVisible = true
      break
    }
  }
  return session
}

export const useAlteredSessions = ({ sessionsData }: Props) => {
  const alteredSessionData = useMemo(() => {
    return sessionsData.map(getAlteredBooking)
  }, [sessionsData])

  return alteredSessionData
}
