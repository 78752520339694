import { Button } from '@mui/material'
import { FC, useState } from 'react'

import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../components/styled-textfield/StyledTextfield'
import { ContactClass } from '../../../../../types/constants.type'
import { api } from '../../../../../utils'
import { CONSTANT } from '../../../../../utils/constants'

interface UpdateInfoI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  editInfo?: ContactClass
  buttonType: 'PARENT' | 'TUTOR'
}

const UpdateInfo: FC<UpdateInfoI> = ({
  open,
  editInfo,
  onClose,
  onSuccess,
  buttonType,
}) => {
  const [email, setEmail] = useState(editInfo?.email)
  const [phoneNumber, setPhoneNumber] = useState(editInfo?.phoneNumber)

  const heading =
    buttonType === 'PARENT'
      ? 'Update parent Information'
      : 'Update tutor Information'

  const isValid = () => {
    return !!(
      email &&
      phoneNumber &&
      phoneNumber.length >= 10 &&
      CONSTANT.EMAIL_REGEXP.test(email)
    )
  }

  const updateData = async () => {
    let res
    const formData = new FormData()
    if (buttonType === 'PARENT') {
      formData.append('parentEmail', email as string)
      formData.append('parentPhoneNumber', phoneNumber as string)
    } else {
      formData.append('tutorEmail', email as string)
      formData.append('tutorPhoneNumber', phoneNumber as string)
    }

    res = await api.put('admin/updateConstants', formData)
    if (res && res.ok) {
      onClose()
      onSuccess()
    }
  }

  return (
    <ModalWrapper open={open} handleClose={onClose} title={heading} wide>
      <div className="row">
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-3 text-left">
            <label className="add-child__label ">Email</label>
          </div>
          <StyledTextfield
            className="col-sm-9"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            isError={!email || !CONSTANT.EMAIL_REGEXP.test(email)}
          />
          {(!email || !CONSTANT.EMAIL_REGEXP.test(email)) && (
            <div className="helper-text">
              {!email ? 'Email is required' : 'Email is not valid.'}
            </div>
          )}
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-3 text-left">
            <label className="add-child__label ">Phone Number</label>
          </div>
          <StyledTextfield
            className="col-sm-9"
            value={phoneNumber}
            onChange={(e) => {
              if (
                e.target.value === '' ||
                CONSTANT.numberOnly.test(e.target.value)
              ) {
                setPhoneNumber(e.target.value)
              }
            }}
            isError={!phoneNumber || phoneNumber.length < 10}
          />
          {(!phoneNumber || phoneNumber.length < 10) && (
            <div className="helper-text">
              {!phoneNumber
                ? 'Phone number is required'
                : 'Phone number is not valid.'}
            </div>
          )}
        </div>
        <div className="form-group row ps-0">
          <div className=" col-sm-9 offset-sm-3 text-center mt30">
            <Button
              disabled={!isValid()}
              color={isValid() ? 'success' : 'info'}
              className="mt-4 w-100"
              onClick={updateData}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default UpdateInfo
