import { Button } from '@mui/material'

import { StyledRow } from '../../../components/custom-bordered-row/StyledRow'
import ModalWrapper from '../../../components/modal-wrapper/ModalWrapper'
import { Notification } from '../notifications.type'
import { useManipulatedNotification } from './hooks/useManipulatedNotification'
import './notification-details.scss'

interface NotificationDetailsProps {
  open: boolean
  onClose: () => void
  notification: Notification
}

const NotificationDetails = ({
  open,
  onClose,
  notification: rawNotification,
}: NotificationDetailsProps) => {
  const notification = useManipulatedNotification(rawNotification)

  const evaluateValue = (
    value: string | undefined | null,
    prepend?: string,
  ) => {
    return value ? (prepend ? `${prepend}${value}` : value) : ''
  }

  const handleViewTutor = () => {
    localStorage.setItem('tutorId', notification.tutor._id)
    window.open('/pages/session/view-tutor', '_blank')
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="View Detail"
      wide
      className="notification-details"
    >
      <StyledRow field="Field Name" description="Description" />
      {!!notification.parent && (
        <StyledRow
          field="Parent Name"
          description={`${
            notification.parent.firstName ? notification.parent.firstName : ''
          } ${
            notification.parent.lastName ? notification.parent.lastName : ''
          }`}
        />
      )}
      {!!notification.student && (
        <StyledRow
          field="Child Name"
          description={`${notification.student.firstName} ${notification.student.lastName}`}
        />
      )}
      {!!notification.tutor && (
        <StyledRow
          field="Tutor Name"
          description={
            <a
              target="_blank"
              className="app-link"
              onClick={handleViewTutor}
            >{`${
              notification.tutor.firstName ? notification.tutor.firstName : ''
            } ${
              notification.tutor.lastName ? notification.tutor.lastName : ''
            }`}</a>
          }
        />
      )}
      {!!notification.booking?.subjects?.length && (
        <>
          <StyledRow
            field="Subject"
            description={notification.booking.subjects.map((subject, index) => (
              <div key={index}>
                {subject.name}
                {index === notification.booking.subjects.length - 1 ? '' : ', '}
              </div>
            ))}
          />
          <StyledRow
            field="Sub Subject"
            description={notification.booking.subjects.map((subject, idx) => (
              <div key={idx}>
                {subject.subcategories.map((subSubject, index) => (
                  <div key={idx + index}>
                    {subSubject.name}
                    {index === subject.subcategories.length - 1 ? '' : ', '}
                  </div>
                ))}
              </div>
            ))}
          />
        </>
      )}
      <StyledRow
        field="Type"
        description={
          <span className="text-capitalize">{notification.type}</span>
        }
      />
      <StyledRow field="Message" description={notification.message} />
      {!!notification.booking?.payments && (
        <StyledRow
          field="Partial Amount Left"
          description={
            <span className="wordBreak">
              {notification.booking.payments.partialAmountLeft >= 0
                ? notification.booking.payments.partialAmountLeft.toFixed(2)
                : '-'}
            </span>
          }
        />
      )}
      {!!notification.booking?.payments?.paymentStatus && (
        <StyledRow
          field="Payment Status"
          description={notification.booking.payments.paymentStatus}
        />
      )}
      {notification.duration >= 0 && (
        <StyledRow
          field="Session Duration"
          description={`${notification.duration} ${
            notification.duration <= 1 ? 'hour' : 'hours'
          }`}
        />
      )}
      {!!notification.booking?.startTime1 && (
        <StyledRow
          field="Session Date and Time"
          description={`${notification.booking.startTime1.format(
            'MMM D, YYYY [|] h:mmA',
          )}-${notification.booking.endTime1?.format('h:mmA')} ${
            notification.booking.parentAbbreviation
          }`}
        />
      )}
      {!!notification.booking?.cancelledAt1 && (
        <StyledRow
          field="Cancelation Date"
          description={notification.booking.cancelledAt1.format('MM-DD-YYYY')}
        />
      )}
      {!!notification.booking?.status && (
        <StyledRow
          field="Cancelation Status"
          description={
            notification.booking.status === 'CANCELLED_BY_PARENT'
              ? 'Parent'
              : notification.booking.status === 'CANCELLED_BY_TUTOR'
              ? 'Tutor'
              : 'Admin'
          }
        />
      )}
      {!!notification.booking?.payments && (
        <StyledRow
          field="Promo Code"
          description={
            notification.booking.payments.promocode
              ? notification.booking.payments.promocode
              : '-'
          }
        />
      )}
      {!!notification.booking?.locationDetails && (
        <StyledRow
          field="Address"
          description={
            Object.keys(notification.booking.locationDetails).length ? (
              <span className="wordBreak">
                {evaluateValue(
                  notification.booking.locationDetails.addressLine1,
                )}
                {evaluateValue(
                  notification.booking.locationDetails.addressLine2,
                  ', ',
                )}
                {evaluateValue(notification.booking.locationDetails.city, ', ')}
                {evaluateValue(
                  notification.booking.locationDetails.state,
                  ', ',
                )}
                {evaluateValue(
                  notification.booking.locationDetails.zipCode,
                  ', ',
                )}
              </span>
            ) : null
          }
        />
      )}
      {!!notification.booking?.payments?.adminFee?.discount && (
        <StyledRow
          field="Admin Fee"
          description={
            (notification.booking.payments.adminFee.type === 'FLAT'
              ? '$'
              : '') +
            notification.booking.payments.adminFee.discount +
            (notification.booking.payments.adminFee.type === 'PERCENT'
              ? '%'
              : '')
          }
        />
      )}
      {!!notification.booking?.payments?.adminEarnings && (
        <StyledRow
          field="Amount Earned By Admin"
          description={notification.booking.payments.adminEarnings.toFixed(2)}
        />
      )}
      {!!notification.booking?.payments?.tutorEarnings && (
        <StyledRow
          field="Amount Earned By Tutor"
          description={notification.booking.payments.tutorEarnings.toFixed(2)}
        />
      )}
      {!!notification.booking?.status && (
        <StyledRow
          field="Status"
          description={
            <span className="text-capitalize">
              {notification.booking.status}
            </span>
          }
        />
      )}
      <div className="col-md-10 col-sm-10 text-end">
        <Button
          color="success"
          className="view-session-button m-1"
          onClick={onClose}
        >
          OK
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default NotificationDetails
