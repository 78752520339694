import { Button, TableCell, TableRow, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'

import { api } from '../../../../utils'
import { Promocode } from '../../allPromos.types'
import AddEditPromo from '../add-edit-promo/AddEditPromo'
import ExpirePromoCode from '../expire-promo-code/ExpirePromoCode'

interface promoTableBodyI {
  promoCode: Promocode
  getAllPromos: () => Promise<void>
  status?: 'ACTIVE' | 'INACTIVE'
}

const promoTableBody: FC<promoTableBodyI> = ({
  promoCode,
  status,
  getAllPromos,
}) => {
  const [editPromoCode, setEditPromoCode] = useState(false)
  const [expirePromoCode, setExpirePromoCode] = useState(false)

  const currentDate = new Date()
  const setDate = (date: Date) => {
    let d = new Date(date).getTime()
    return d
  }

  const checkStatus = () => {
    if (promoCode.maxCount === promoCode.usedCount) return 'Used Up'
    else {
      if (setDate(currentDate) > setDate(promoCode.endDate)) return 'Expired'
      else if (setDate(currentDate) < setDate(promoCode.endDate)) {
        {
          return promoCode.isActive == false
            ? 'Expired'
            : setDate(currentDate) > setDate(promoCode.startDate)
            ? promoCode &&
              promoCode.maxCount &&
              promoCode.usedCount &&
              promoCode.maxCount == promoCode.usedCount
              ? 'Used Up'
              : 'Past'
            : setDate(currentDate) < setDate(promoCode.startDate)
            ? 'Future'
            : 'Used Up'
        }
      }
    }
  }

  const expirePromo = async () => {
    const formData = new FormData()
    formData.append('isActive', 'false')
    const res = await api.put(
      `admin/updatePromocode/${promoCode?._id}`,
      formData,
    )
    if (res.ok) {
      setExpirePromoCode(false)
      getAllPromos()
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>{promoCode.promocode}</TableCell>
        <TableCell
          sx={{ whiteSpace: status === 'ACTIVE' ? 'noWrap' : 'normal' }}
        >
          {promoCode.startDate
            ? dayjs(promoCode.startDate).utc().format('MM-DD-YYYY')
            : '-'}
        </TableCell>
        <TableCell
          sx={{ whiteSpace: status === 'ACTIVE' ? 'noWrap' : 'normal' }}
        >
          {promoCode.endDate
            ? dayjs(promoCode.endDate).utc().format('MM-DD-YYYY')
            : '-'}
        </TableCell>
        <TableCell>{promoCode.perUserCount}</TableCell>
        <TableCell>{promoCode.maxCount}</TableCell>
        <TableCell>{promoCode.usedCount}</TableCell>
        <TableCell
          sx={{ whiteSpace: status === 'ACTIVE' ? 'noWrap' : 'normal' }}
        >
          {promoCode.marketIds.map((market) => {
            return (
              <div key={market._id}>
                {market.marketName
                  ? market.marketName
                  : market.marketId
                  ? market.marketId
                  : '-'}
              </div>
            )
          })}
        </TableCell>
        <TableCell>{promoCode.type}</TableCell>
        <TableCell>
          {promoCode.type == 'PERCENT' ? promoCode.credits + '%' : ''}
          {promoCode.type == 'FLAT' ? '$' + promoCode.credits : ''}
        </TableCell>
        {status === 'INACTIVE' && <TableCell>{checkStatus()}</TableCell>}
        {status === 'ACTIVE' ? (
          <TableCell>
            <span>
              <Tooltip title="Edit">
                <span className="ms-3 me-3 menu-icon text-center">
                  <FaPencilAlt
                    size={15}
                    onClick={() => {
                      setEditPromoCode(true)
                    }}
                  />
                </span>
              </Tooltip>
              <Button
                variant="text"
                className="ms-3 me-3"
                onClick={() => {
                  setExpirePromoCode(true)
                }}
              >
                Expire Promo Code
              </Button>
            </span>
          </TableCell>
        ) : (
          <TableCell>
            <span>
              {promoCode &&
              promoCode.startDate &&
              currentDate < promoCode.startDate ? (
                <Tooltip title="Edit">
                  <span className="ms-3 me-3 menu-icon text-center">
                    <FaPencilAlt
                      size={15}
                      onClick={() => {
                        setEditPromoCode(true)
                      }}
                    />
                  </span>
                </Tooltip>
              ) : (
                <></>
              )}
              {promoCode &&
                promoCode.startDate &&
                currentDate > promoCode.startDate && <div>-</div>}
            </span>
          </TableCell>
        )}
        {editPromoCode && (
          <AddEditPromo
            mode="Edit"
            open={editPromoCode}
            onClose={() => {
              setEditPromoCode(false)
            }}
            onSuccess={getAllPromos}
            editPromo={promoCode}
          />
        )}
      </TableRow>
      {expirePromoCode && (
        <ExpirePromoCode
          open={expirePromoCode}
          onClose={() => {
            setExpirePromoCode(false)
          }}
          title="Promo Code"
          text="the promo code"
          onYes={expirePromo}
        />
      )}
    </>
  )
}

export default promoTableBody
