import { Badge } from '@mui/material'
import Popover from '@mui/material/Popover'
import dayjs from 'dayjs'
import { MouseEvent, useEffect, useState } from 'react'
import { AiOutlineBell } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

import routes from '../../../../common/routes'
import { useAppContext } from '../../../../contexts/AppContext'
import { api } from '../../../../utils'
import NotificationDetails from '../notification-details/NotificationDetails'
import './notification-popover.scss'
import { GetNotificationResponse, Notification } from './notification.type'

const NotificationPopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [notificationDetails, setNotificationDetails] = useState<
    Notification | undefined
  >()
  const { updateAppState } = useAppContext()
  const navigate = useNavigate()

  const getNotifications = async () => {
    const res = await api.get<GetNotificationResponse>(
      '/notifications/getNotifications',
      {
        limit: 10,
        skip: 0,
      },
    )
    if (res.ok && res.data) {
      const { notifications, unreadCount } = res.data.data
      setNotifications(notifications)
      setUnreadNotifications(unreadCount)
    }
  }
  useEffect(() => {
    getNotifications()
  }, [])

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const notificationAll = async () => {
    const res = await api.put(`/notifications/readNotifications`, {
      key: 'all',
    })
    if (res.ok) {
      navigate(routes.allNotifications)
      setAnchorEl(null)
    }
  }

  const readNotification = async (notification: Notification) => {
    if (!notification.isRead) {
      const res = await api.put(
        `/notifications/readNotification/${notification._id}`,
        {
          id: notification,
          key: 'single',
        },
      )
      if (res.ok) {
        getNotifications()
      }
    }
    handleClose()
    setNotificationDetails(notification)
  }

  return (
    <>
      <span onClick={handleClick} className="me-3 ms-3">
        <span>
          <Badge badgeContent={unreadNotifications} color="error" showZero>
            <AiOutlineBell size={25} />
          </Badge>
        </span>
      </span>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="notification-container">
          <div className="notification-header">Notifications</div>
          <div className="d-flex flex-column content-container">
            {notifications.map((notification) => {
              return (
                <div
                  className={`notification-content d-flex flex-column ${
                    notification.isRead ? 'notification-content--read' : ''
                  }`}
                  key={notification._id}
                  onClick={() => {
                    readNotification(notification)
                  }}
                >
                  <span className="notification-text">
                    {notification.message}
                  </span>
                  <span className="notification-date">
                    {dayjs(notification.createdAt).format('MMM. D [at] h:mmA')}
                  </span>
                </div>
              )
            })}
          </div>
          <div
            className="notification-footer"
            onClick={() => {
              notificationAll()
            }}
          >
            See all notifications
          </div>
        </div>
      </Popover>
      <NotificationDetails
        open={!!notificationDetails}
        setOpen={setNotificationDetails}
        notification={notificationDetails}
      />
    </>
  )
}

export default NotificationPopover
