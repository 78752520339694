import FirstSession from '@/pages/reports/first-session/FirstSession'
import RejectedCanceledSession from '@/pages/reports/rejected-canceled-session/RejectedCanceledSession'
import ReturningCustomers from '@/pages/reports/returning-customers/ReturningCustomers'
import StudentTutorFeedback from '@/pages/reports/student-tutor-feedback/StudentTutorFeedback'
import TutorLastAvailability from '@/pages/reports/tutor-last-availability/TutorLastAvailability'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import routes from '../../common/routes'
import { useAppContext } from '../../contexts/AppContext'
import AllFAQs from '../../pages/CMS/FAQ/AllFAQs'
import BackGroundCheck from '../../pages/CMS/background-check/BackGroundCheck'
import CMSLogin from '../../pages/CMS/cms-login/CMSLogin'
import ContactInfo from '../../pages/CMS/contact-info/ContactInfo'
import Resources from '../../pages/CMS/tutor-resources/Resources'
import ApiKeys from '../../pages/admin-settings/api-keys/ApiKeys'
import CategoryCommission from '../../pages/category-commission/CategoryCommission'
import AllBhExpirations from '../../pages/category-commission/components/all-bh-expiration/AllBhExpirations'
import AllCancellationPolicy from '../../pages/category-commission/components/all-cancellation-policy/AllCancellationPolicy'
import AllCancellationReasons from '../../pages/category-commission/components/all-cancellation-reasons/AllCancellationReasons'
import AllDeclines from '../../pages/category-commission/components/all-decline-reason/AllDeclines'
import AllDisputeReasons from '../../pages/category-commission/components/all-dispute-reason/AllDisputesReasons'
import AllFee from '../../pages/category-commission/components/all-fee/AllFee'
import AllPartnershipCodes from '../../pages/category-commission/components/all-partnership-codes/AllPartnershipCodes'
import AllReferralTC from '../../pages/category-commission/components/all-referral-tc/AllReferralTC'
import AllRejects from '../../pages/category-commission/components/all-reject-reason/AllRejects'
import AllSubjects from '../../pages/category-commission/components/all-subjects/AllSubjects'
import AllTermAndCondition from '../../pages/category-commission/components/all-term-and-conditions/AllTermAndCondition'
import ViewChild from '../../pages/child/ViewChild'
import Contacts from '../../pages/contacts/Contacts'
import Dashboard from '../../pages/dashboard/Dashboard'
import AllDisputes from '../../pages/disputes/AllDisputes'
import Feedbacks from '../../pages/feedbacks/Feedbacks'
import GeoFencing from '../../pages/geofencing/GeoFencing'
import { AddNotification, AllNotifications } from '../../pages/notifications'
import AllParents from '../../pages/parent/AllParents'
import AllParentBookings from '../../pages/parent/components/all-parent-bookings/AllParentBookings'
import AllReservations from '../../pages/parent/components/all-reservations/AllReservations'
import EditParent from '../../pages/parent/components/edit-parent/EditParent'
import ManageCards from '../../pages/parent/components/manage-cards/ManageCards'
import AllPromos from '../../pages/promo-management/AllPromos'
import SessionData from '../../pages/reports/session-data/SessionData'
import AllSessions from '../../pages/sessions/all-sessions/AllSessions'
import ViewTutor from '../../pages/sessions/components/view-tutor/ViewTutor'
import CreateSession from '../../pages/sessions/create-session/CreateSession'
import SignIn from '../../pages/signin/SignIn'
import AllTutors from '../../pages/tutor/AllTutors'
import EditTutor from '../../pages/tutor/components/edit-tutor/EditTutor'
import PendingProfileUpdate from '../../pages/tutor/components/pending-profile-update/PendingProfileUpdate'
import ViewSession from '../../pages/tutor/components/view-details/components/view-sessions/ViewSession'
import { api, clearLocalStorage } from '../../utils'
import AddParentTutor from '../add-parent-tutor/AddParentTutor'
import AppLoader from '../app-loader/AppLoader'
import PagesLayout from '../pages-layout/PagesLayout'
import ProtectedRoute from '../protected-route/ProtectedRoute'
import RootPathComponent from '../root-path-component/RootPathComponent'
import AdminList from './../../pages/admin-settings/admin-users/AdminList'
import TutorSchedules from './../../pages/tutor/components/tutor-schedules/TutorSchedules'

function AppRouter() {
  const {
    appState: { isLoggedIn, loading },
    updateAppState,
  } = useAppContext()

  const location = useLocation()

  useEffect(() => {
    if (location.pathname.split('/')[2] !== 'parent') {
      localStorage.removeItem('ParentFilters')
      return
    }
  }, [location])
  useEffect(() => {
    if (location.pathname.split('/')[2] !== 'tutors') {
      localStorage.removeItem('TutorFilters')
      return
    }
  }, [location])

  useEffect(() => {
    api.addAsyncResponseTransform(async (response) => {
      if (response.status === 401) {
        updateAppState({ isLoggedIn: false, currentUser: null })
        clearLocalStorage()
      }
    })
  }, [])

  useEffect(() => {
    updateAppState({ loading: true })
    const token =
      localStorage.getItem('token') || localStorage.getItem('tokenSession')
    if (token && !isLoggedIn) {
      updateAppState({ isLoggedIn: true })
    }
    updateAppState({ loading: false })
  }, [isLoggedIn])

  return (
    <>
      <AppLoader />
      <Routes>
        <Route path="/" element={<RootPathComponent />} />
        <Route path={routes.login} element={<SignIn />} />
        <Route
          path="/pages"
          element={
            <ProtectedRoute>
              <PagesLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="/pages/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path={routes.allParents} element={<AllParents />} />
          <Route path={routes.editParent} element={<EditParent />} />
          <Route
            path={`${routes.viewChild}/:parentId`}
            element={<ViewChild />}
          />
          <Route path={routes.cardDetail} element={<ManageCards />} />
          <Route path={routes.addParent} element={<AddParentTutor />} />
          <Route path={routes.addTutor} element={<AddParentTutor />} />
          <Route path={routes.allReservations} element={<AllReservations />} />
          <Route path={routes.allTutors} element={<AllTutors />} />
          <Route path={routes.editTutors} element={<EditTutor />} />
          <Route path={routes.scheduleSession} element={<TutorSchedules />} />
          <Route path={routes.tutorBookings} element={<ViewSession />} />
          <Route
            path={routes.pendingProfile}
            element={<PendingProfileUpdate />}
          />
          <Route
            path={routes.allParentBookings}
            element={<AllParentBookings />}
          />

          <Route path={routes.viewTutor} element={<ViewTutor />} />
          <Route path={routes.allSessions} element={<AllSessions />} />
          <Route path={routes.createSession} element={<CreateSession />} />
          <Route path={routes.geoFencing} element={<GeoFencing />} />

          <Route path={routes.allCategories} element={<CategoryCommission />} />
          <Route
            path={routes.categoriesAllSubjects}
            element={<AllSubjects />}
          />
          <Route path={routes.categoriesAllFee} element={<AllFee />} />
          <Route
            path={routes.categoriesAllCancelationPolicy}
            element={<AllCancellationPolicy />}
          />
          <Route
            path={routes.categoriesAllCancelationReason}
            element={<AllCancellationReasons />}
          />
          <Route
            path={routes.categoriesTermCondition}
            element={<AllTermAndCondition />}
          />
          <Route
            path={routes.categoriesReferralTermCondition}
            element={<AllReferralTC />}
          />
          <Route
            path={routes.categoriesDisputeReason}
            element={<AllDisputeReasons />}
          />
          <Route
            path={routes.categoriesRejectReason}
            element={<AllRejects />}
          />
          <Route
            path={routes.categoriesDeclineReason}
            element={<AllDeclines />}
          />
          <Route
            path={routes.categoriesPartnerShipCode}
            element={<AllPartnershipCodes />}
          />
          <Route
            path={routes.categoriesBhExpiration}
            element={<AllBhExpirations />}
          />
          <Route path={routes.promoManagement} element={<AllPromos />} />
          <Route path={routes.faq} element={<AllFAQs />} />
          <Route path={routes.bgCheck} element={<BackGroundCheck />} />
          <Route path={routes.contactInfo} element={<ContactInfo />} />
          <Route path={routes.tutorResources} element={<Resources />} />
          <Route path={routes.parentResources} element={<Resources />} />
          <Route path={routes.cmsLogin} element={<CMSLogin />} />

          <Route path={routes.disputes} element={<AllDisputes />} />
          <Route path={routes.sessionData} element={<SessionData />} />
          <Route path={routes.firstSession} element={<FirstSession />} />
          <Route
            path={routes.rejectedCanceledSession}
            element={<RejectedCanceledSession />}
          />
          <Route
            path={routes.returningCustomers}
            element={<ReturningCustomers />}
          />
          <Route
            path={routes.tutorLastAvailability}
            element={<TutorLastAvailability />}
          />
          <Route
            path={routes.studentTutorFeedback}
            element={<StudentTutorFeedback />}
          />

          <Route
            path={routes.allNotifications}
            element={<AllNotifications />}
          />
          <Route path={routes.addNotification} element={<AddNotification />} />
          <Route path={routes.contacts} element={<Contacts />} />
          <Route path={routes.feedbacks} element={<Feedbacks />} />
          <Route path={routes.adminUsers} element={<AdminList />} />
          <Route path={routes.apiKeys} element={<ApiKeys />} />
        </Route>
      </Routes>
    </>
  )
}

export default AppRouter
