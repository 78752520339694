import ModalWrapper from '@/components/modal-wrapper/ModalWrapper'
import { Button } from '@mui/material'
import { FC } from 'react'

export type DeleteErrorMsg =
  | 'HAS_UPCOMING'
  | 'AVAILABLE_MARKETPLACE'
  | 'CONFIRMATION'
  | ''

const ERROR_MESSAGES = {
  HAS_UPCOMING:
    'Accounts with upcoming sessions or sessions currently being processed cannot be deleted. Please cancel any upcoming sessions and allow all sessions to finish processing before proceeding.',
  AVAILABLE_MARKETPLACE:
    'This tutor is currently shared with mySylvan Marketplace. Please disable this option before deleting this tutor.',
  CONFIRMATION:
    "Are you sure you want to fully delete this tutor's account? This will clear all data for this tutor and cannot be undone.",
}

interface UpcomingSessionModalProps {
  open: boolean
  onClose: () => void
  onDelete: () => void
  deleteErrorMsg: DeleteErrorMsg
}
const UpcomingSessionModal: FC<UpcomingSessionModalProps> = ({
  open,
  onClose,
  onDelete,
  deleteErrorMsg,
}) => {
  if (!deleteErrorMsg) return null

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      boldTitle
      wide
      innerContainerPadding="pb-4"
    >
      <div className="d-flex justify-content-center align-items-center flex-column blueText">
        <p className="text-center">{ERROR_MESSAGES[deleteErrorMsg]}</p>
        <div>
          {deleteErrorMsg === 'CONFIRMATION' ? (
            <>
              <Button
                color="success"
                className="modal-button"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="modal-button"
                onClick={onDelete}
              >
                Delete Tutor
              </Button>
            </>
          ) : (
            <Button color="success" className="modal-button" onClick={onClose}>
              OK
            </Button>
          )}
        </div>
      </div>
    </ModalWrapper>
  )
}

export default UpcomingSessionModal
