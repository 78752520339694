import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../modal-wrapper/ModalWrapper'

interface ConfirmationModalIProps {
  open: boolean
  onClose: () => void
  onDecline?: () => void
  onSuccess?: () => void
  title?: string
  topMessage?: string
  successTitle?: string
  declineTitle?: string
  noSuccessBtn?: boolean
}

const ConfirmationModal: FC<ConfirmationModalIProps> = (props) => {
  const {
    open,
    title,
    topMessage,
    declineTitle,
    successTitle,
    noSuccessBtn,
    onClose,
    onDecline,
    onSuccess,
  } = props

  return (
    <ModalWrapper open={open} handleClose={onClose} title={title} wide>
      <div className="d-flex justify-content-center align-items-center flex-column">
        {topMessage && <p className="text-center blueText">{topMessage}</p>}

        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={onDecline || onClose}
          >
            {declineTitle || 'No'}
          </Button>
          {!successTitle && !noSuccessBtn && (
            <Button
              color="success"
              className="modal-button"
              onClick={onSuccess}
            >
              {successTitle || 'Yes'}
            </Button>
          )}
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmationModal
