import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { Tutor } from '../../../../../../types/tutor.type'
import { EditTutorData } from '../../EditTutor'
import './partnership-code.scss'
import { DataPartnershipCodesData } from './partnership-code.type'

dayjs.extend(isSameOrAfter)

interface PartnershipCodeIProps {
  codes: DataPartnershipCodesData | null
  singleTutor: Tutor | undefined
}

const PartnershipCode: FC<PartnershipCodeIProps> = ({ codes, singleTutor }) => {
  const isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true'
  const { register, setValue, watch } = useFormContext<EditTutorData>()
  let leftPartnershipCodes = codes?.partnerCodes

  if (singleTutor?.partnershipCode) {
    let arrayP = codes?.partnerCodes.filter((element) => {
      let pIndex = singleTutor.partnershipCode.findIndex((element1) => {
        return element1.partnercode == element.partnercode
      })
      return !(pIndex > -1)
    })
    leftPartnershipCodes = arrayP
  }

  leftPartnershipCodes = leftPartnershipCodes?.filter((code) => {
    if (!code.endTime) return code
    const currentDate = dayjs(new Date())
    const endDate = dayjs(new Date(code.endTime))
    return endDate.isSameOrAfter(currentDate)
  })
  const selectedPartnershipCodes = codes?.partnerCodes?.filter((code) =>
    singleTutor?.partnershipCode?.some(
      (code2) => code?._id === code2.partnercodeId?._id,
    ),
  )

  const deletePartnershipCode = watch('deletePartnershipCode')

  return (
    <div className="partnership-code">
      <h5 className="heading-container mt-4">
        Alternate Pricing/Partnership Code Opt-Ins
      </h5>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              <TableCell>Select</TableCell>
              <TableCell>Partnership Code</TableCell>
              <TableCell>Alternate Price</TableCell>
              <TableCell>Admin Fee</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!codes?.partnerCodes && (
              <TableRow>
                <TableCell colSpan={6} className="text-center">
                  No codes found
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={6} className="partnership-code__heading">
                Partnership Codes currently participating in <br />
                (You may unselect one code at a time to remove the tutor's
                participation.)
              </TableCell>
            </TableRow>
            {selectedPartnershipCodes?.map((code) => (
              <TableRow key={code._id}>
                <TableCell>
                  <Checkbox
                    sx={{ padding: '4px' }}
                    disabled={
                      !!(
                        deletePartnershipCode &&
                        deletePartnershipCode !== code._id
                      )
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue('deletePartnershipCode', code._id)
                      } else {
                        if (code._id === deletePartnershipCode) {
                          setValue('deletePartnershipCode', '')
                        }
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{code.partnercode}</TableCell>
                <TableCell>
                  {code.hourlyRate ? code.hourlyRate : ''}{' '}
                  {code.lastName ? code.lastName : ''}
                </TableCell>
                <TableCell>
                  {code.case1?.discount ? code.case1.discount : '-'}
                </TableCell>
                <TableCell>
                  {dayjs(code.startTime).utc().format('MM-DD-YYYY')}
                </TableCell>
                <TableCell>
                  {code?.endTime
                    ? dayjs(code.endTime)?.utc().format('MM-DD-YYYY')
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={6} className="partnership-code__heading">
                Partnership Codes available to participate in
                <br />
                (You may select multiple new codes for a tutor to participate
                in.)
              </TableCell>
            </TableRow>
            {leftPartnershipCodes?.map((code) => (
              <TableRow key={code._id}>
                <TableCell>
                  <Checkbox
                    sx={{ padding: '4px' }}
                    value={code._id}
                    {...register('partnershipCodeId')}
                  />
                </TableCell>
                <TableCell>{code.partnercode}</TableCell>
                <TableCell>
                  {code.hourlyRate ? code.hourlyRate : ''}{' '}
                  {code.lastName ? code.lastName : ''}
                </TableCell>
                <TableCell>
                  {code.case1?.discount ? code.case1.discount : '-'}
                </TableCell>
                <TableCell>
                  {dayjs(code.startTime).utc().format('MM-DD-YYYY')}
                </TableCell>
                <TableCell>
                  {code.endTime
                    ? dayjs(code.endTime)?.utc().format('MM-DD-YYYY')
                    : 'n/a'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PartnershipCode
