import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as Yup from 'yup'

import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../../../contexts/AppContext'
import { Parent } from '../../../../../../types/parent.type'
import { api, dataURLtoBlob } from '../../../../../../utils'
import { grades as Grades } from '../../data'
import KeepSessionsModal from './KeepSessionsModal'
import './detail-form.scss'
import { CheckParentUpcomingForPartnership } from './detail-form.type'

interface DetailFormProps {
  singleParent: Parent
  setPageState: React.Dispatch<React.SetStateAction<boolean>>
  imageBase64: string
  getSingleParent: () => void
}

interface FormValues {
  firstName: string
  lastName: string
  email: string
  phone: string
  partnershipCode: string
  utmSource: string
  utmContent: string
  grades: string[]
  subjects: string[]
}

const schema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('First Name is required'),
  email: Yup.string().required('Email is required'),
  phone: Yup.string()
    .min(10, 'Phone number minimum length is 10')
    .max(10)
    .required('Phone Number is required'),
})

const DetailForm: FC<DetailFormProps> = ({
  singleParent,
  setPageState,
  imageBase64,
  getSingleParent,
}) => {
  const { subjectsArray } = useAppContext()
  const [checkboxState, setCheckboxState] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(
    singleParent?.parent.interestOtherFactor !== '-'
      ? singleParent?.parent.interestOtherFactor
      : '',
  )
  const [keepSessions, setKeepSessions] = useState<boolean | null>(null)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [disableCode, setDisableCode] = useState(false)
  const [isRateChargeSuppress, setIsRateChargeSuppress] = useState(false)
  const [partnershipCodeError, setPartnershipCodeError] = useState('')
  const [partnershipRemoved, setPartnershipRemoved] = useState(false)
  const { updateAppState } = useAppContext()

  useEffect(() => {
    if (
      singleParent?.parent.interestOtherFactor !== '-' &&
      singleParent?.parent.interestOtherFactor
    ) {
      setCheckboxState(true)
    }
    if (
      singleParent &&
      singleParent.partnershipCode &&
      singleParent.partnershipCode.length &&
      singleParent.partnershipCode[0].partnercode
    ) {
      setIsRateChargeSuppress(
        !!singleParent.partnershipCode[0].isRateChargeSuppress,
      )

      setDisableCode(true)
    } else {
      setDisableCode(false)
    }
  }, [singleParent])

  const defaultValues: FormValues = {
    firstName: singleParent.firstName ? singleParent.firstName : '',
    lastName: singleParent.lastName ? singleParent.lastName : '',
    email: singleParent.email ? singleParent.email : '',
    phone: singleParent ? singleParent.phoneNumber : '',
    partnershipCode: singleParent?.partnershipCode
      ? singleParent.partnershipCode[0]?.partnercode
      : '',
    utmSource: singleParent
      ? singleParent?.userSource && singleParent?.userSource.utm_source
        ? singleParent?.userSource.utm_source
        : ''
      : '',
    utmContent: singleParent
      ? singleParent?.userSource && singleParent?.userSource.utm_content
        ? singleParent?.userSource.utm_content
        : ''
      : '',
    grades: singleParent?.parent.gradeLevel?.map((grade) => grade),
    subjects: singleParent?.parent.subjects?.map((subject) => subject._id),
  }

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  })

  const onSubmit = async (values: FormValues) => {
    if (checkboxState && !checkboxValue) {
      toast.error(
        "Please let us know more about what other subjects you're interested in",
      )
      return
    }

    const {
      firstName,
      lastName,
      email,
      phone,
      utmSource,
      utmContent,
      grades,
      subjects,
      partnershipCode,
    } = values

    const formData = new FormData()
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('email', email)
    formData.append('phoneNumber', phone)
    formData.append('utm_source', utmSource)

    formData.append('utm_content', utmContent)
    formData.append(
      'interestOtherFactor',
      checkboxState ? (checkboxValue as string) : '-',
    )

    if (imageBase64)
      formData.append('profilePicture', dataURLtoBlob(imageBase64))

    if (partnershipRemoved) {
      formData.append(
        'deletePartnershipCode',
        singleParent.partnershipCode[0].partnercodeId._id,
      )
      if (!keepSessions) formData.append('deleteExistingSessions', 'true')
    }

    if (!disableCode && partnershipCode) {
      if (
        singleParent.partnershipCode && singleParent.partnershipCode.length
          ? singleParent.partnershipCode[0].partnercode != partnershipCode
          : true
      ) {
        formData.append('partnershipCodeId', partnershipCode)
      }
    }
    let tempGrade = grades?.map((grade) => grade)
    formData.append('gradeLevel', JSON.stringify(tempGrade))

    let tempSubjects = subjects?.map((subject) => subject)
    formData.append('subjects', JSON.stringify(tempSubjects))

    const res = await api.put(
      `/admin/editProfileParent/${singleParent._id}`,
      formData,
    )

    if (res.ok) {
      setPageState(false)
      getSingleParent()
    } else {
      const { data } = res
      const text = data as any
      setPartnershipCodeError(text.message)
    }
  }

  const isSuperAdmin = localStorage.getItem('isSuperAdmin')
  let showRemoveCode = false

  if (
    isSuperAdmin === 'true' &&
    singleParent?.parent?.addresses?.[0]?.country !== 'Canada' &&
    singleParent?.partnershipCode?.[0]?.partnercode &&
    !!getValues('partnershipCode')
  ) {
    showRemoveCode = true
  }

  const removeCode = async () => {
    const partnerShipCode = getValues('partnershipCode')
    const partnershipCodeId = singleParent.partnershipCode[0].partnercodeId._id

    const query = {
      parentId: singleParent._id,
      partnershipCodeId: partnershipCodeId,
    }
    updateAppState({ loading: true })
    const res = await api.get<CheckParentUpcomingForPartnership>(
      '/admin/checkParentUpcomingSessionForPartnerCode',
      query,
    )
    updateAppState({ loading: false })
    if (res.ok && res.data) {
      if (res.data.data.isUpcomingSession) {
        setShowCancelModal(true)
      } else {
        setValue('partnershipCode', '')
        setPartnershipRemoved(true)
      }
    }
  }

  const handleCancelSessions = () => {
    setKeepSessions(false)
    setShowCancelModal(false)
    setPartnershipRemoved(true)
    setValue('partnershipCode', '')
  }

  return (
    <div>
      <form className="w-auto" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <div className="col-sm-4 text-left">First Name</div>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="First Name"
                isError={!!errors.firstName}
                className="col-sm-8"
                errorMessage={errors.firstName?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">Last Name</div>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Last Name"
                isError={!!errors.lastName}
                className="col-sm-8"
                errorMessage={errors.lastName?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">Email</div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Email"
                isError={!!errors.email}
                className="col-sm-8"
                errorMessage={errors.email?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">Phone Number</div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Phone Number"
                isError={!!errors.phone}
                className="col-sm-8"
                max={10}
                errorMessage={errors.phone?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">Partnership Code</div>
          <div className="col-sm-8 d-flex flex-column">
            <Controller
              name="partnershipCode"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  placeholder="Partnership Code"
                  disabled={
                    disableCode && isSuperAdmin !== 'true' ? true : false
                  }
                  isError={!!errors.partnershipCode}
                  errorMessage={errors.partnershipCode?.message?.toString()}
                />
              )}
            />
            {showRemoveCode && (
              <a onClick={removeCode} className="remove-code-button text-end">
                Remove Code
              </a>
            )}
            {!!partnershipCodeError && (
              <p className="helper-text text-end">{partnershipCodeError}</p>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">utm_source</div>
          <Controller
            name="utmSource"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="utm_source"
                isError={!!errors.utmSource}
                className="col-sm-8"
                errorMessage={errors.utmSource?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">utm_content</div>
          <Controller
            name="utmContent"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="utm_content"
                isError={!!errors.utmContent}
                className="col-sm-8"
                errorMessage={errors.utmContent?.message?.toString()}
              />
            )}
          />
        </div>
        <hr className="hr-border" />
        <h3>Interests</h3>
        <div className="row">
          <div className="col-sm-5 col-xs-12 ms-4">
            <span className="checkbox-heading">Grades:</span>
            {Grades?.map((grade) => {
              return (
                <div className="d-flex align-items-center" key={grade.id}>
                  <input
                    {...register('grades')}
                    type="checkbox"
                    value={grade.value}
                  />
                  <label className="grade-text">{grade.value}</label>
                </div>
              )
            })}
          </div>

          <div className="col-sm-5 col-xs-12 ms-4">
            <span className="checkbox-heading">Subjects:</span>
            {subjectsArray?.map((subject) => {
              return (
                <div className="d-flex align-items-center" key={subject._id}>
                  <input
                    {...register('subjects')}
                    type="checkbox"
                    value={subject._id}
                  />
                  <label className="grade-text">{subject.name}</label>
                </div>
              )
            })}
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={checkboxState}
                onChange={() => {
                  setCheckboxState(!checkboxState)
                }}
              />
              <label className="grade-text">Other</label>
              {checkboxState ? (
                <>
                  <input
                    className="ms-2 p-2"
                    value={checkboxValue}
                    placeholder="Other.."
                    onChange={(e) => {
                      setCheckboxValue(e.target.value)
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="offset-sm-9 col-sm-3 mt-5">
          <Button color="success" type="submit">
            Save and Next
          </Button>
        </div>
      </form>
      <KeepSessionsModal
        open={showCancelModal}
        partnershipCode={defaultValues.partnershipCode}
        onClose={() => setShowCancelModal(false)}
        onCancelSessions={handleCancelSessions}
      />
    </div>
  )
}

export default DetailForm
