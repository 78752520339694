import { Checkbox } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { Tutor } from '../../../../../../types/tutor.type'
import { EditTutorData } from '../../EditTutor'

interface EditAdditionalInfoIProps {
  tutor: Tutor | undefined
}

const EditAdditionalInfo: FC<EditAdditionalInfoIProps> = ({ tutor }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<EditTutorData>()

  const isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true'

  return (
    <div>
      <h5 className="heading-container mt-2">Additional Information</h5>
      <div className="row">
        <div className="form-group col-md-6  ">
          <div className="row p-0">
            <div className="col-sm-12">
              <label>utm_source</label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="utm_source"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter utm_source"
                    isError={!!errors.utm_source}
                    errorMessage={errors.utm_source?.message?.toString()}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6 form-group">
          <div className="row p-0">
            <div className="col-sm-12">
              <label>utm_content</label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="utm_content"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter utm_content"
                    isError={!!errors.utm_content}
                    errorMessage={errors.utm_content?.message?.toString()}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="form-group col-sm-6">
          <div className="row">
            <div className="col-sm-12">
              <span className="lightText">
                Do not show tutor in other markets?&nbsp;
              </span>
              {!isSuperAdmin ? (
                <span className="lightText">
                  {tutor?.tutor.notSeenInOtherMarket ? 'Yes' : 'No'}
                </span>
              ) : (
                <>
                  <Controller
                    control={control}
                    name="isShownInOtherMarkets"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditAdditionalInfo
