import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'

import { StudentTutorFeedBackType } from '../../student-tutor-feedback.type'

dayjs.extend(tz)

const StudentTutorFeedbackTable = ({
  data,
}: {
  data: StudentTutorFeedBackType[]
}) => {
  const getDate = (time: Date, timeZone: string) => {
    const date = dayjs(time).tz(timeZone)
    if (date.format('MMM').toLowerCase() === 'may') {
      return date.format('MMM D, YYYY')
    } else {
      return date.format('MMM. D, YYYY')
    }
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ '&:hover': { background: 'none' } }}>
            <TableCell>Tutor</TableCell>
            <TableCell>Session Date</TableCell>
            <TableCell>Subject(s)</TableCell>
            <TableCell>Feedback</TableCell>
            <TableCell>Attachment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row._id} className="student-feedback-row">
              <TableCell>{row.tutor}</TableCell>
              <TableCell>
                {getDate(row.startTime, row.parentTimezoneOffsetZone)}
              </TableCell>
              <TableCell>
                {row.subjects.map((subject, index) => (
                  <div key={index}>
                    {subject.subcategories?.map((subSubject, innerIdx) => (
                      <div key={innerIdx}>
                        {subSubject.name}
                        {index !== row.subjects.length - 1 ||
                        (subject.subcategories &&
                          innerIdx !== subject.subcategories.length - 1)
                          ? ','
                          : ''}
                        &nbsp;
                      </div>
                    ))}
                  </div>
                ))}
              </TableCell>
              <TableCell>
                <p
                  style={{
                    maxWidth: '100%',
                    // @ts-ignore
                    textWrap: 'wrap',
                    lineHeight: '20px',
                  }}
                >
                  {row.sessionFeedback.comments}
                </p>
              </TableCell>
              <TableCell>
                {row.sessionFeedback.documents.map((doc, index) => (
                  <>
                    <a
                      key={index}
                      href={doc.url}
                      target="_blank"
                      className="app-link"
                    >
                      {doc.name}
                    </a>
                    {!!row.sessionFeedback.documents[index + 1] && <br />}
                  </>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StudentTutorFeedbackTable
