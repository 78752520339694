import { useAppContext } from '@/contexts/AppContext'
import BookingDetailModal from '@/pages/parent/components/booking-detail-modal/BookingDetailModal'
import ParentViewDetails from '@/pages/parent/components/view-details/ParentViewDetails'
import TutorViewDetails from '@/pages/tutor/components/view-details/TutorViewDetails'
import { Booking } from '@/types/getBooking.type'
import { Parent, ParentReq } from '@/types/parent.type'
import { SingleBookingRes } from '@/types/single-booking.type'
import { api } from '@/utils'
import { TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { FC, useState } from 'react'

import { Customer } from '../returning-customers.type'

dayjs.extend(timezone)

interface ReturningCustomersTableBodyI {
  customer: Customer
}
const ReturningCustomersTableBody: FC<ReturningCustomersTableBodyI> = ({
  customer,
}) => {
  const [singleParent, setSingleParent] = useState<Parent | null>(null)
  const [viewTutorDetail, setViewTutorDetail] = useState(false)
  const [showBooking, setShowBooking] = useState<Booking | null>(null)
  const { updateAppState } = useAppContext()
  const {
    firstName,
    lastName,
    latestCompletedBooking,
    latestRecentBooking,
    _id: parentId,
  } = customer
  const { student, subjects, tutor } = latestRecentBooking

  const parentName = firstName + ' ' + lastName
  const tutorName = tutor?.firstName + ' ' + tutor?.lastName
  const studentName = student?.firstName + ' ' + student?.lastName
  const subSubjects: string[] = []

  subjects?.forEach((subject) => {
    subject?.subcategories?.forEach((subcategory) => {
      subSubjects?.push(subcategory?.name)
    })
  })

  const getSingleParent = async (parentId: string) => {
    updateAppState({ loading: true })
    const singleParentReq = await api.get<ParentReq>(
      'admin/getParentById/' + parentId,
    )
    const { data } = singleParentReq
    if (data) {
      setSingleParent(data.data)
    }
    updateAppState({ loading: false })
  }

  const getSessionById = async (id: string) => {
    updateAppState({ loading: true })
    const res = await api.get<SingleBookingRes>('/admin/getBookingById', {
      bookingId: id,
    })

    if (res.data) {
      const { data } = res.data
      //@ts-ignore
      setShowBooking(data)
    }
    updateAppState({ loading: false })
  }

  return (
    <TableRow className="parent-booking-row">
      <TableCell
        className="cursor-pointer text-underline"
        onClick={() => {
          getSingleParent(parentId)
        }}
      >
        {parentName}
      </TableCell>
      <TableCell
        className="cursor-pointer text-underline"
        onClick={() => {
          getSessionById(latestRecentBooking._id)
        }}
      >
        {dayjs(latestRecentBooking.startTime)
          .tz(latestRecentBooking.parentTimezoneOffsetZone)
          .format('MMM DD, YYYY h:mmA')}{' '}
        {latestRecentBooking.parentAbbreviation}
      </TableCell>
      <TableCell
        className="cursor-pointer text-underline"
        onClick={() => {
          getSessionById(latestCompletedBooking._id)
        }}
      >
        {dayjs(latestCompletedBooking.startTime)
          .tz(latestCompletedBooking.parentTimezoneOffsetZone)
          .format('MMM DD, YYYY h:mmA')}{' '}
        {latestCompletedBooking.parentAbbreviation}
      </TableCell>
      <TableCell>{studentName}</TableCell>
      <TableCell>
        {subSubjects?.map(
          (subSubject, index) => (index === 0 ? '' : ', ') + subSubject,
        )}
      </TableCell>

      <TableCell
        className="cursor-pointer text-underline"
        onClick={() => {
          localStorage.setItem('tutorId', tutor?._id as string)
          setViewTutorDetail(true)
        }}
      >
        {tutorName}
      </TableCell>
      {singleParent && (
        <ParentViewDetails
          singleParent={singleParent}
          setSingleParent={setSingleParent}
          key={!!singleParent + 'viewDetail'}
          parentId={singleParent._id}
          open={!!singleParent}
          handleClose={() => {
            setSingleParent(null)
          }}
        />
      )}
      {viewTutorDetail && (
        <TutorViewDetails
          open={viewTutorDetail}
          key={viewTutorDetail + 'viewDetail'}
          handleClose={() => {
            setViewTutorDetail(false)
          }}
        />
      )}
      {!!showBooking && (
        <BookingDetailModal
          open={!!showBooking}
          onClose={() => setShowBooking(null)}
          booking={showBooking as Booking}
          bookingState={{ pageType: 'detail' }}
        />
      )}
    </TableRow>
  )
}

export default ReturningCustomersTableBody
