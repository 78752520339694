import { FC } from 'react'

import { Tutor } from '../../types/tutor.type'

interface AdditionalInfoProps {
  singleTutor: Tutor | null | undefined
}

const AdditionalInfo: FC<AdditionalInfoProps> = ({ singleTutor }) => {
  return (
    <div>
      <div className="heading-container">Additional Information</div>
      <div className="additionalText">
        <div className="view-rows text-capitalize">
          <b>Status:</b> {localStorage.getItem('tutorStatus')}
        </div>
        <div className="view-rows">
          <b>Rejected Reason:</b> {singleTutor?.rejectReason}
        </div>
        <div className="view-rows">
          <b>HR Status:</b> {singleTutor?.tutor.hrStatus}
        </div>
        <div className="view-rows">
          <b>utm_source:</b>{' '}
          {singleTutor?.userSource && singleTutor?.userSource.utm_source
            ? singleTutor?.userSource.utm_source
            : ''}
        </div>
        <div className="view-rows">
          <b>utm_content:</b>{' '}
          {singleTutor?.userSource && singleTutor?.userSource.utm_content
            ? singleTutor?.userSource.utm_content
            : ''}
        </div>
        <div className="view-rows">
          <b>Show tutor in other markets?</b>{' '}
          {singleTutor?.tutor && singleTutor?.tutor.notSeenInOtherMarket
            ? 'No'
            : 'Yes'}
        </div>
        <br />
      </div>
    </div>
  )
}

export default AdditionalInfo
