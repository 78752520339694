import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from './../../../../../components/modal-wrapper/ModalWrapper'
import './confirm-auto-accept.scss'

interface ConfirmAutoAcceptProps {
  open: boolean
  isAcceptAuto?: () => void
  notAcceptAuto?: () => void
}
const ConfirmAutoAccept: FC<ConfirmAutoAcceptProps> = ({
  open,
  isAcceptAuto,
  notAcceptAuto,
}) => {
  return (
    <ModalWrapper wide open={open} showCloseButton={false}>
      {' '}
      <div className="d-flex justify-content-center align-items-center flex-column modal-text">
        <p className="text-center mt-3">
          Are you sure you want to have this session automatically accepted on
          behalf of the tutor?
        </p>
        <div className="d-flex justify-content-center">
          <Button
            color="success"
            className="modal-button auto-accept-button"
            onClick={notAcceptAuto}
          >
            No, do not auto-accept
          </Button>
          <Button
            color="success"
            className="modal-button auto-accept-button"
            onClick={isAcceptAuto}
          >
            Yes, auto-accept session(s)
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmAutoAccept
