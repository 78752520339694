export const filterButtons = [
  { id: 0, name: 'ALL', title: 'All', type: 'ALL' },
  {
    id: 1,
    name: 'INCOMPLETE',
    title: 'Incomplete Profile',
    type: 'INCOMPLETE',
  },
  { id: 2, name: 'PENDING', title: 'Unapproved Tutors', type: 'PENDING' },
  { id: 3, name: 'APPROVED', title: 'Approved Tutors', type: 'APPROVED' },
  { id: 4, name: 'INACTIVE', title: 'Inactive', type: 'INACTIVE' },
  { id: 5, name: 'BLOCKED', title: 'Blocked ', type: 'BLOCKED' },
  { id: 6, name: 'DECLINED', title: 'Rejected ', type: 'DECLINED' },
  { id: 7, name: 'DELETED', title: 'Deleted ', type: 'DELETED' },
]

export const liveDropDownArray = [
  { id: 0, option: 'Both', value: 'none' },
  { id: 1, option: 'Yes', value: 'yes' },
  { id: 2, option: 'No', value: 'no' },
]
export const acceptingNewArray = [
  { id: 0, option: 'Both', value: 'none' },
  { id: 1, option: 'Yes', value: 'yes' },
  { id: 2, option: 'No', value: 'no' },
]
export const hrStatusArray = [
  { id: 0, option: 'Under Review', value: 'Under Review' },
  { id: 1, option: 'Contacted', value: 'Contacted' },
  { id: 2, option: 'Phone Screen Scheduled', value: 'Phone Screen Scheduled' },
  { id: 3, option: 'Contract Sent', value: 'Contract Sent' },
  { id: 4, option: 'Rejected', value: 'Rejected' },
  { id: 5, option: 'Unresponsive', value: 'Unresponsive' },
  { id: 6, option: 'BC in Progress', value: 'BC in Progress' },
  { id: 7, option: 'Approved', value: 'Approved' },
  { id: 8, option: 'No Status', value: 'No Status' },
]
