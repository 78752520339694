import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material'
import { FC } from 'react'

import { PaginatedBooking } from '../first-session.type'
import FirstSessionTableBody from './FirstSessionTableBody'
import { firstSessionHeading } from './data'

interface FirstSessionTableI {
  firstSessions: PaginatedBooking[]
}

const FirstSessionTable: FC<FirstSessionTableI> = ({ firstSessions }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          {firstSessionHeading.map((heading, index) => {
            return <TableCell key={index}>{heading}</TableCell>
          })}
        </TableHead>
        <TableBody>
          {firstSessions.map((session, index) => {
            return (
              <FirstSessionTableBody firstSession={session} key={session._id} />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FirstSessionTable
