import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { TutorElement } from '../../../../../../types/changeRequest.type'
import { pendingProfilesHeading } from '../data'
import PendingProfileTableBody from './PendingProfileTableBody'

interface PendingProfileTableProps {
  allPendingProfiles: TutorElement[] | null | undefined
  getPendingProfiles: () => Promise<void>
}

const PendingProfileTable: FC<PendingProfileTableProps> = ({
  allPendingProfiles,
  getPendingProfiles,
}) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              {pendingProfilesHeading.map((heading) => {
                return <TableCell key={heading}>{heading}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {allPendingProfiles?.map((profiles) => {
              return (
                <PendingProfileTableBody
                  tutor={profiles}
                  key={profiles._id}
                  getPendingProfiles={getPendingProfiles}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PendingProfileTable
