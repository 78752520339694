import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { Booking } from '../../../../../../../types/getBooking.type'
import ViewSessionTableBody from './ViewSessionTableBody'
import { sessionHeadings } from './data'

interface ViewSessionsTableProps {
  bookings?: Booking[]
  getTutorSessions?: () => Promise<void>
}

const ViewSessionsTable: FC<ViewSessionsTableProps> = ({
  bookings,
  getTutorSessions,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ '&:hover': { background: 'none' } }}>
            {sessionHeadings.map((heading) => {
              return <TableCell key={heading.id}>{heading.heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings?.map((booking) => (
            <ViewSessionTableBody
              booking={booking}
              key={booking._id}
              getTutorSessions={getTutorSessions}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ViewSessionsTable
