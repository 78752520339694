import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import CustomDatePicker from '../../../../../components/date-picker/CustomDatePicker'
import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../../utils'
import { Resource } from '../../tutorResources.type'
import './add-edit-resource.scss'

interface AddEditResourceI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  editData?: Resource
  role?: 'PARENT' | 'TUTOR'
  mode: 'Add' | 'Edit'
}

interface FormValues {
  title: string
  category: string
  description: string
  url: string
}

const schema = Yup.object({
  title: Yup.string()
    .max(100, 'Title character length should not be more than 100.')
    .required('This field is required'),
  category: Yup.string()
    .max(50, 'Resource category character length should not be more than 50.')
    .required('This field is required'),
  description: Yup.string()
    .max(250, 'Description character length should not be more than 250.')
    .required('This field is required'),
  url: Yup.string().required('This field is required'),
}).required()

const AddEditResource: FC<AddEditResourceI> = ({
  open,
  mode,
  onClose,
  onSuccess,
  editData,
  role,
}) => {
  const modalTitle = () => {
    if (role === 'PARENT') {
      return mode === 'Add' ? 'Add Parent Resource' : 'Edit Parent Resource'
    }
    if (role === 'TUTOR') {
      return mode === 'Add' ? 'Add Tutor Resource' : 'Edit Tutor Resource'
    }
  }

  const defaultValues: FormValues = {
    title: '',
    category: '',
    description: '',
    url: '',
  }

  const {
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  })

  const values = watch()

  useEffect(() => {
    if (mode === 'Edit' && editData) {
      setValue('title', editData.linkText)
      setValue('description', editData.description)
      setValue('category', editData.category as string)
      setValue('url', editData.url)
      setDate(dayjs(editData.date))
    }
  }, [editData])

  const [date, setDate] = useState<Dayjs | null>(dayjs())

  const handleAdd = async () => {
    const { category, description, title, url } = values
    const formData = new FormData()
    formData.append('linkText', title)
    formData.append('description', description)
    formData.append('category', category)
    formData.append('url', url)
    if (date) formData.append('date', new Date(date.format()).toString())
    let res
    if (role === 'PARENT') {
      if (mode == 'Add')
        res = await api.post('admin/addParentResource', formData)
      else
        res = await api.put(
          `admin/editParentResource/${editData?.id}`,
          formData,
        )
    } else {
      if (mode == 'Add') res = await api.post('admin/addResource', formData)
      else res = await api.put(`admin/editResource/${editData?.id}`, formData)
    }

    if (res && res.ok) {
      onSuccess()
      onClose()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={modalTitle()}
      wide
      showCloseButton={role !== 'PARENT'}
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div className="row">
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">Title</div>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Title"
                    isError={!!errors.title}
                  />
                )}
              />
              {errors.title?.message && (
                <div className="helper-text">
                  {errors.title?.message?.toString()}
                </div>
              )}
            </div>
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">Resource Category</div>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Resource Category"
                    isError={!!errors.category}
                  />
                )}
              />
              {errors.category?.message && (
                <div className="helper-text">
                  {errors.category?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">Description</div>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    multiline
                    rows={2}
                    {...field}
                    placeholder="Description"
                    isError={!!errors.description}
                  />
                )}
              />
              {errors.description?.message && (
                <div className="helper-text">
                  {errors.description?.message?.toString()}
                </div>
              )}
            </div>
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">URL</div>
              <Controller
                name="url"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    multiline
                    rows={2}
                    {...field}
                    placeholder="URL"
                    isError={!!errors.url}
                  />
                )}
              />
              {errors.url?.message && (
                <div className="helper-text">
                  {errors.url?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">
                {role === 'PARENT' ? 'Last Updated Date' : 'Date'}
              </div>
              <CustomDatePicker
                date={date}
                onChange={(value) => {
                  setDate(value as Dayjs)
                }}
                disableClear
              />
              {!date && (
                <div className="helper-text">Reservation date is required.</div>
              )}
            </div>

            {mode === 'Edit' && (
              <div className="col-md-6 mt-3">
                <Button
                  color="success"
                  className="mt-1 w-100"
                  onClick={() => {
                    setDate(dayjs())
                  }}
                >
                  Set to Today
                </Button>
              </div>
            )}
          </div>
          <div className="form-group row mt-5">
            {role === 'TUTOR' ? (
              <div className="offset-sm-8 col-sm-4 mt30">
                <Button
                  className="w-100"
                  color={isValid && date ? 'success' : 'info'}
                  disabled={!(isValid && date)}
                  onClick={handleAdd}
                >
                  Save
                </Button>
              </div>
            ) : (
              <>
                <div className="col-md-6 ps-2">
                  <Button
                    className="w-100"
                    color={'success'}
                    variant="outlined"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-md-6">
                  <Button
                    className="w-100"
                    color={isValid && date ? 'success' : 'info'}
                    disabled={!(isValid && date)}
                    onClick={handleAdd}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditResource
