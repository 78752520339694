import { yupResolver } from '@hookform/resolvers/yup'
import { Radio, RadioGroup, Button } from '@mui/material'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../../utils'
import { CONSTANT } from '../../../../../utils/constants'
import { Admin } from '../../admin.type'
import './add-edit-admin.scss'

interface AddEditAdminI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  editData?: Admin
  mode: 'Add' | 'Edit'
}

interface FormValues {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  isSuperAdmin: boolean | null
}

const schema = Yup.object({
  firstName: Yup.string()
    .max(64, 'First name length should not be more than 64.')
    .required('This field is required'),
  lastName: Yup.string()
    .max(64, 'Last name length should not be more than 64.')
    .required('This field is required'),
  email: Yup.string()
    .required('This field is required')
    .matches(
      CONSTANT.EMAIL_REGEXP,
      'Please provide the correct email address for this admin user.',
    ),
  phoneNumber: Yup.string()
    .min(10, 'Phone number should be 10 characters only.')
    .max(10, 'Phone number should be 10 characters only.')
    .required('This field is required'),
}).required()

const AddEditAdmin: FC<AddEditAdminI> = ({
  open,
  mode,
  onClose,
  onSuccess,
  editData,
}) => {
  const defaultValues: FormValues = {
    email: '',
    firstName: '',
    isSuperAdmin: null,
    lastName: '',
    phoneNumber: '',
  }

  const {
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  })

  const values = watch()

  useEffect(() => {
    if (mode === 'Edit' && editData) {
      setValue('firstName', editData.firstName)
      setValue('lastName', editData.lastName)
      setValue('phoneNumber', editData.phoneNumber)
      setValue('email', editData.email)
      setValue('isSuperAdmin', editData.isSuperAdmin)
    }
  }, [editData])

  const handleAdmin = async () => {
    const { email, firstName, isSuperAdmin, lastName, phoneNumber } = values
    const formData = new FormData()
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('phoneNumber', phoneNumber)
    formData.append('email', email)
    if (isSuperAdmin != null)
      formData.append('isSuperAdmin', isSuperAdmin.toString())

    let res
    if (mode == 'Add') res = await api.post('admin/addAdminUser', formData)
    else res = await api.put(`admin/editAdminUser/${editData?._id}`, formData)

    if (res && res.ok) {
      onSuccess()
      onClose()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={mode + ' Admin Account'}
      wide
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div className="row align-items-start">
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">First Name</div>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="First Name"
                    isError={!!errors.firstName}
                  />
                )}
              />
              {errors.firstName?.message && (
                <div className="error-msg">
                  {errors.firstName?.message?.toString()}
                </div>
              )}
            </div>
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">Last Name</div>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Last Name"
                    isError={!!errors.lastName}
                  />
                )}
              />
              {errors.lastName?.message && (
                <div className="error-msg">
                  {errors.lastName?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="row align-items-start">
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">Email</div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Email"
                    isError={!!errors.email}
                  />
                )}
              />
              <small className="helping-msg">
                This value will be used as the admin's username and it &nbsp;
                <b>will be case-sensitive</b>
              </small>
              {errors.email?.message && (
                <div className="error-msg">
                  {errors.email?.message?.toString()}
                </div>
              )}
              {values.email.length > 256 && (
                <div className="error-msg">
                  Email length should not be more than 256.
                </div>
              )}
            </div>
            <div className="col-md-6 form-group">
              <div className="ps-0 pb-1 font-16">Phone Number</div>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    onChange={(e) => {
                      if (
                        CONSTANT.numberRegex.test(e.target.value) ||
                        e.target.value === ''
                      ) {
                        field.onChange(e)
                      }
                    }}
                    placeholder="Phone Number"
                    isError={!!errors.phoneNumber}
                  />
                )}
              />
              {errors.phoneNumber?.message && (
                <div className="error-msg">
                  {errors.phoneNumber?.message?.toString()}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label className="blueText">Super Admin?</label>
              <Controller
                control={control}
                name="isSuperAdmin"
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={field.value?.toString() || ''}
                    onChange={(e) => {
                      setValue('isSuperAdmin', e.target.value === 'true')
                    }}
                  >
                    <div className="row align-items-center ">
                      <div className="col-sm-3 ps-0  ">
                        <Radio value="true" />
                        <span className="admin-radio">Yes</span>
                      </div>
                      <div className="col-sm-3 ps-0">
                        <Radio value="false" />
                        <span className="admin-radio">No</span>
                      </div>
                    </div>
                  </RadioGroup>
                )}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="offset-sm-8 col-sm-4 mt30">
              <Button
                color={
                  isValid && values.isSuperAdmin != null ? 'success' : 'info'
                }
                disabled={!(isValid && values.isSuperAdmin != null)}
                className="w-100"
                onClick={handleAdmin}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditAdmin
