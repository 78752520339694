export const headings = ['Name', 'User Name', 'Market', 'Action']

export const gender = [
  { id: 0, option: 'Male', value: 'MALE' },
  { id: 1, option: 'Female', value: 'FEMALE' },
  { id: 2, option: 'Other/No Response', value: 'OTHER' },
]
export const grade = [
  { id: 0, option: 'K', value: 'K' },
  { id: 1, option: '1', value: '1' },
  { id: 2, option: '2', value: '2' },
  { id: 3, option: '3', value: '3' },
  { id: 4, option: '4', value: '4' },
  { id: 5, option: '5', value: '5' },
  { id: 6, option: '6', value: '6' },
  { id: 7, option: '7', value: '7' },
  { id: 8, option: '8', value: '8' },
  { id: 9, option: '9', value: '9' },
  { id: 10, option: '10', value: '10' },
  { id: 11, option: '11', value: '11' },
  { id: 12, option: '12', value: '12' },
]

export const filterButtons = [
  { id: 0, name: 'allParent', title: 'All Parent', type: null },
  { id: 1, name: '', title: 'Profile Incomplete', type: 'INCOMPLETE' },
  { id: 2, name: '', title: 'Profile Complete', type: 'COMPLETE' },
  { id: 3, name: '', title: 'Blocked Parent', type: 'BLOCKED' },
  { id: 4, name: '', title: 'Deleted Parent', type: 'DELETED' },
]

export const monthsArray = [
  { id: 0, option: 'January', value: '1' },
  { id: 1, option: 'February', value: '2' },
  { id: 2, option: 'March', value: '3' },
  { id: 3, option: 'April', value: '4' },
  { id: 4, option: 'May', value: '5' },
  { id: 5, option: 'June', value: '6' },
  { id: 6, option: 'July', value: '7' },
  { id: 7, option: 'August', value: '8' },
  { id: 8, option: 'September', value: '9' },
  { id: 9, option: 'October', value: '10' },
  { id: 10, option: 'November', value: '11' },
  { id: 11, option: 'December', value: '12' },
]
