import { Button } from '@mui/material'
import { FC, useState } from 'react'

import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../../utils'
import { CONSTANT } from '../../../../../utils/constants'

interface AddAPIKeyI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  onError: () => void
}

const AddAPIKey: FC<AddAPIKeyI> = ({ onClose, onSuccess, open, onError }) => {
  const [resourceName, setResourceName] = useState('')
  const [resourceError, setResourceError] = useState(false)

  const isButtonDisabled = () => {
    return !!(!resourceName || !CONSTANT.apiKeyName.test(resourceName))
  }

  const addKey = async () => {
    const formData = new FormData()
    formData.append('externalResourceName', resourceName)
    const res = await api.post('admin/keys', formData)
    if (res.ok) {
      onSuccess()
      onClose()
    } else {
      onError()
      onClose()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      wide
      showCloseButton={false}
    >
      {' '}
      <div className="row form-group mt-5">
        <div className="ps-0 pb-1 col-md-4 font-16">Resource Name</div>

        <StyledTextfield
          placeholder="Resource Name"
          className="col-md-8"
          value={resourceName}
          onKeyDown={() => {
            setResourceError(true)
          }}
          onChange={(e) => {
            setResourceName(e.target.value)
          }}
          isError={
            !!(
              resourceError &&
              (!resourceName || !CONSTANT.apiKeyName.test(resourceName))
            )
          }
        />
        {!!(
          resourceError &&
          (!resourceName || !CONSTANT.apiKeyName.test(resourceName))
        ) && (
          <div className="helper-text mt-2">
            {!resourceName
              ? 'Resource Name is required.'
              : 'Resource Name is not valid.'}
          </div>
        )}
      </div>
      <div className="form-group mt-5">
        <div className="offset-4 ps-2 ">
          <Button className="me-2" color="success" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="ms-2"
            color={isButtonDisabled() ? 'info' : 'success'}
            disabled={isButtonDisabled()}
            onClick={addKey}
          >
            Create Key
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddAPIKey
