import { Button } from '@mui/material'
import { FC, useState } from 'react'
import { FaAngleDoubleDown, FaAngleDoubleUp, FaPencilAlt } from 'react-icons/fa'

import CustomCardWrapper from '../../../../../../components/custom-card-wrapper/CustomCardWrapper'
import { Policy } from '../../AllCancellationPolicy.type'
import AddEditCancellationPolicy from '../add-edit-cancellation-policy/AddEditCancellationPolicy'
import './cancellation-policy-component.scss'

interface CancellationPolicyI {
  cancellationPolicy: Policy
  selectedPolicy?: string
  onViewClick?: (id: string) => void
  onHideClick?: () => void
  getCancellationPolices: () => Promise<void>
}
const CancellationPolicyComponent: FC<CancellationPolicyI> = ({
  cancellationPolicy,
  selectedPolicy,
  onViewClick,
  onHideClick,
  getCancellationPolices,
}) => {
  const [editCancellation, setEditCancellation] = useState(false)
  return (
    <CustomCardWrapper className="p-4">
      <div className="col-sm-5">
        <label>Name: </label> <span>{cancellationPolicy.name}</span>
      </div>
      <div className="col-sm-6 text-end">
        <span
          className="mt15 cursor-pointer me-3"
          onClick={() => {
            setEditCancellation(true)
          }}
        >
          <FaPencilAlt size={18} />
        </span>
        {selectedPolicy !== cancellationPolicy._id ? (
          <Button
            endIcon={<FaAngleDoubleDown />}
            onClick={() => {
              onViewClick?.(cancellationPolicy._id)
            }}
          >
            View More
          </Button>
        ) : (
          <Button
            endIcon={<FaAngleDoubleUp />}
            onClick={() => {
              onHideClick?.()
            }}
          >
            Hide Detail
          </Button>
        )}
      </div>
      {selectedPolicy === cancellationPolicy._id && (
        <div className="col-sm-12 policy-text">
          <p>{cancellationPolicy.text}</p>
        </div>
      )}
      {editCancellation && (
        <AddEditCancellationPolicy
          open={editCancellation}
          onClose={() => {
            setEditCancellation(false)
            getCancellationPolices()
          }}
          mode="Edit"
          editPolicy={cancellationPolicy}
        />
      )}
    </CustomCardWrapper>
  )
}

export default CancellationPolicyComponent
