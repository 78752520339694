import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../../components/modal-wrapper/ModalWrapper'
import { MarketID } from '../../../../../../../types/market.type'
import './tutor-outside-market-modal.scss'

interface TutorOutsideMarketModalProps {
  open: boolean
  onClose: () => void
  oldMarket: MarketID | null
  newMarket: MarketID | null
  isChangeMarket: (isChange: boolean) => void
  addressNotChange: () => void
}

const TutorOutsideMarketModal: FC<TutorOutsideMarketModalProps> = ({
  open,
  onClose,
  newMarket,
  oldMarket,
  isChangeMarket,
  addressNotChange,
}) => {
  const sendMarketData = {
    newMarket: newMarket?.marketName,
    oldMarket: oldMarket?.marketName,
  }

  return (
    <ModalWrapper wide open={open} handleClose={onClose} title="Confirmation">
      <div className="d-flex justify-content-center align-items-center flex-column tutor-outside-market-modal">
        <p className="delete-modal__title text-center">
          The new address for this tutor puts them in the{' '}
          {sendMarketData?.newMarket} market instead of the{' '}
          {sendMarketData?.oldMarket} market. How would you like to proceed?
        </p>
        <div className="d-flex">
          <Button
            color="success"
            className="tutor-outside-market-modal__modal-button modal-button"
            onClick={addressNotChange}
          >
            Don't change address
          </Button>
          <Button
            color="success"
            className="tutor-outside-market-modal__modal-button modal-button"
            onClick={() => {
              isChangeMarket(false)
            }}
          >
            Change address but NOT market
          </Button>
          <Button
            color="success"
            className="tutor-outside-market-modal__modal-button modal-button"
            onClick={() => {
              isChangeMarket(true)
            }}
          >
            Change address AND market
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default TutorOutsideMarketModal
