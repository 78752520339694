export const contactsHeading = [
  'First Name',
  'Last Name',
  'Email',
  'Phone Number',
  'Zip Code',
  'User Type',
  'Contact Date',
  'Source',
  'Action',
]
