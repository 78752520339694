import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'

interface ConfirmRevokeI {
  open: boolean
  onClose: () => void
  onYes: () => void
}
const ConfirmRevoke: FC<ConfirmRevokeI> = ({ open, onClose, onYes }) => {
  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      wide
      showCloseButton={false}
    >
      <div className="d-flex justify-content-center align-items-center flex-column delete-modal mt-4">
        <p className="delete-modal__title">
          Are you sure you want to revoke this API Key? This action cannot be
          undone.
        </p>
        <div>
          <Button color="success" className="modal-button" onClick={onClose}>
            Cancel Revoke
          </Button>
          <Button color="success" className="modal-button" onClick={onYes}>
            Revoke Key
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmRevoke
