import { Button, TableCell, TableRow } from '@mui/material'
import { FC } from 'react'

import { bookingStates } from '../../../../../types/getBooking.type'
import { Booking } from '../../../sessions.type'
import { FilterButtonTypes } from '../../data'
import { cancelBookingType } from '../CancelBookingModal'
import { AlteredBooking, InitialModalState } from './SessionsTable'

interface SessionsTableRowIProps {
  session: AlteredBooking
  buttonType: FilterButtonTypes
  modals: InitialModalState
  setBooking: React.Dispatch<React.SetStateAction<Booking | undefined>>
  setModals: React.Dispatch<React.SetStateAction<InitialModalState>>
  setBookingState: React.Dispatch<
    React.SetStateAction<bookingStates | undefined>
  >
  setCancelBookingType: React.Dispatch<
    React.SetStateAction<cancelBookingType | undefined>
  >
}

const SessionsTableRow: FC<SessionsTableRowIProps> = ({
  session,
  buttonType,
  modals,
  setBooking,
  setModals,
  setBookingState,
  setCancelBookingType,
}) => {
  return (
    <TableRow sx={{ verticalAlign: 'baseline' }}>
      <TableCell>
        {session.parentId?.firstName
          ? `${session.parentId.firstName} ${session.parentId.lastName}`
          : '-'}
      </TableCell>
      <TableCell>
        {session.tutorId?.firstName
          ? `${session.tutorId.firstName} ${session.tutorId.lastName}`
          : '-'}
      </TableCell>
      <TableCell>
        {session.subjects.map((subject, index) => (
          <div key={index}>
            {subject.subcategories.map((subSubject, innerIdx) => (
              <div key={innerIdx}>
                {subSubject.name}
                {index !== session.subjects.length - 1 ||
                innerIdx !== subject.subcategories.length - 1
                  ? ','
                  : ''}
                &nbsp;
              </div>
            ))}
          </div>
        ))}
      </TableCell>
      {buttonType !== 'PENDING_RESCHEDULES' && (
        <TableCell>
          {session.payments.actualSessionTime
            ? session.payments.actualSessionTime +
              (session.payments.actualSessionTime <= 1 ? ' hour' : ' hours')
            : session.payments && session.payments.duration
            ? session.payments &&
              session.payments.duration +
                (session.payments.duration <= 1 ? '  hour' : ' hours')
            : ''}
        </TableCell>
      )}
      {buttonType !== 'PENDING_RESCHEDULES' && (
        <TableCell className="wordBreak">
          {Object.keys(session.locationDetails).length
            ? `${
                session.locationDetails.addressLine1
                  ? session.locationDetails.addressLine1
                  : ''
              }${
                session.locationDetails.addressLine2
                  ? ', ' + session.locationDetails.addressLine2
                  : ''
              }${
                session.locationDetails.city
                  ? ', ' + session.locationDetails.city
                  : ''
              }${
                session.locationDetails.state
                  ? ', ' + session.locationDetails.state
                  : ''
              }${
                session.locationDetails.zipCode
                  ? ', ' + session.locationDetails.zipCode
                  : ''
              }`
            : '-'}
        </TableCell>
      )}
      <TableCell>
        {session.startTime1 ? session.startTime1.format('MMM D, YYYY') : '-'}
        <br />
        {session.startTime1.format('h:mmA')}
        {session.endTime1 ? '-' + session.endTime1.format('h:mmA') : ''}{' '}
        {session.parentAbbreviation}
      </TableCell>
      {buttonType === 'PENDING_RESCHEDULES' && (
        <TableCell>
          <>
            {session.rescheduleData.startTime1
              ? session.rescheduleData.startTime1.format('MMM D, YYYY')
              : '-'}
            <br />
            {session.rescheduleData.startTime1?.format('h:mmA')}
            {session.rescheduleData.endTime1
              ? '-' + session.rescheduleData.endTime1?.format('h:mmA')
              : ''}{' '}
            {session.rescheduleData.parentAbbreviation
              ? session.rescheduleData.parentAbbreviation
              : session.rdAbb}
          </>
        </TableCell>
      )}
      {buttonType === 'PENDING_RESCHEDULES' && (
        <TableCell>
          {session.status === 'Reschedule Request By Tutor' ? 'Tutor' : ''}
          {session.status === 'Reschedule Request By Parent' ? 'Parent' : ''}
        </TableCell>
      )}
      {buttonType !== 'PENDING_RESCHEDULES' && (
        <TableCell>
          {session.payments && session.payments.projectedAmount >= 0
            ? '$' + session.payments.projectedAmount.toFixed(2)
            : '-'}
        </TableCell>
      )}
      {[
        'FINISHED',
        'CANCELLED',
        'PAYMENT_FAILED',
        'PARTIAL_PAYMENT',
        'COMPLETED',
      ].some((status) => status === buttonType) && (
        <TableCell>{'$' + session.totalCost?.toFixed(2)}</TableCell>
      )}
      <TableCell>
        <span className="d-flex flex-wrap">
          <Button
            title="View"
            variant="text"
            className="ms-1 me-1 mt-1"
            onClick={() => {
              setBooking(session)
              setModals({ ...modals, details: true })
              setBookingState({
                pageType: 'detail',
                source: 'sessionDetail',
                actionType: 'activeBooking',
              })
            }}
          >
            View Detail
          </Button>
          {buttonType === 'PENDING_RESCHEDULES' && (
            <>
              <Button
                title="accept"
                variant="text"
                className="ms-1 me-1 mt-1"
                onClick={() => {
                  setBooking(session)
                  setModals({ ...modals, details: true })
                  setBookingState({
                    pageType: 'acceptReschedule',
                    source: 'sessionDetail',
                    actionType: 'activeBooking',
                  })
                }}
              >
                Accept Reschedule
              </Button>
              <Button
                title="decline"
                variant="text"
                className="ms-1 me-1 mt-1"
                onClick={() => {
                  setBooking(session)
                  setModals({ ...modals, details: true })
                  setBookingState({
                    pageType: 'rejectReschedule',
                    source: 'sessionDetail',
                    actionType: 'activeBooking',
                  })
                }}
              >
                Decline Reschedule
              </Button>
            </>
          )}
          {buttonType !== 'PENDING_RESCHEDULES' && (
            <>
              {session.acceptSession && (
                <Button
                  title="accept"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setBooking(session)
                    setModals({ ...modals, details: true })
                    setBookingState({
                      pageType: 'accept',
                      source: 'sessionDetail',
                      actionType: 'activeBooking',
                    })
                  }}
                >
                  Accept Session
                </Button>
              )}
              {session.declineSession && (
                <Button
                  title="decline"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setBooking(session)
                    setModals({ ...modals, details: true })
                    setBookingState({
                      pageType: 'reject',
                      source: 'sessionDetail',
                      actionType: 'activeBooking',
                    })
                  }}
                >
                  Decline Session
                </Button>
              )}
              {buttonType === 'COMPLETED' && session.tutorRatings && (
                <Button
                  title="rating"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setBooking(session)
                    setModals({ ...modals, details: true })
                    setBookingState({
                      pageType: 'rating',
                      source: 'sessionDetail',
                      actionType: 'activeBooking',
                    })
                  }}
                >
                  Rating
                </Button>
              )}
              {session.cancelVisible && (
                <Button
                  title="View"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setBooking(session)
                    setCancelBookingType('cancel')
                  }}
                >
                  Cancel Session
                </Button>
              )}
              {!!(session.editActualTimes && !session.isBookingUseCredits) && (
                <Button
                  title="View"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setCancelBookingType('editActualTimes')
                    setBooking(session)
                  }}
                >
                  Edit Actual Times
                </Button>
              )}
              {!!session.payments.adminEarnings && (
                <Button
                  title="Earning"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setBooking(session)
                    setModals({ ...modals, details: true })
                    setBookingState({
                      pageType: 'earning',
                      source: 'sessionDetail',
                      actionType: 'activeBooking',
                    })
                  }}
                >
                  View Earning
                </Button>
              )}
              {session.rescheduleVisible && (
                <Button
                  title="View"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setBooking(session)
                    setModals({ ...modals, reschedule: true })
                    setBookingState({
                      pageType: 'reschedule',
                      source: 'sessionDetail',
                      actionType: 'activeBooking',
                    })
                  }}
                >
                  Reschedule Session
                </Button>
              )}
              {buttonType === 'COMPLETED' && (
                <Button
                  title="feedback"
                  variant="text"
                  className="ms-1 me-1 mt-1"
                  onClick={() => {
                    setBooking(session)
                    setModals({ ...modals, details: true })
                    setBookingState({
                      pageType: 'feedBack',
                      source: 'sessionDetail',
                      actionType: 'activeBooking',
                    })
                  }}
                >
                  Session FeedBack
                </Button>
              )}
            </>
          )}
        </span>
      </TableCell>
    </TableRow>
  )
}

export default SessionsTableRow
