import { FormControl, Select, SelectChangeEvent } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import React, { forwardRef } from 'react'

import './dropdown.scss'

export interface Options {
  id: number | string
  option: string
  value: string
}

interface DropdownProps {
  option?: Options[]
  placeholder?: string
  name?: string
  value?: string | null | undefined
  className?: string | undefined
  isError?: boolean
  isTouched?: boolean
  errorMessage?: string | undefined
  required?: boolean
  noPlaceholder?: boolean
  onBlur?: (e: any) => void
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  onChange?: ((event: SelectChangeEvent<string>) => void) | undefined
  disabled?: boolean
  isSearching?: boolean
  onSearchChange?: (e: any) => void
  isSearchError?: boolean
  searchErrorMessage?: string
  selectablePlaceholder?: boolean
}

const StyledDropdown: React.FC<DropdownProps> = forwardRef(
  (
    {
      value,
      className,
      isError,
      errorMessage,
      onChange,
      option,
      placeholder,
      required,
      disabled,
      noPlaceholder,
      onKeyDown,
      onBlur,
      selectablePlaceholder,
    },
    ref,
  ) => {
    const inputClass = errorMessage ? 'styled-textfield-error' : ''

    const disabledClass = disabled ? 'disabled-class' : ''
    return (
      <>
        <div className={`${className}`}>
          <FormControl fullWidth>
            <Select
              inputRef={ref}
              size="small"
              fullWidth
              displayEmpty
              className={`${inputClass} ${className}`}
              value={value ? value : placeholder ? placeholder : ''}
              onChange={(e) => {
                if (e.target.value === placeholder) {
                  e.target.value = ''
                  onChange?.(e)
                } else onChange?.(e)
              }}
              required={required}
              input={<OutlinedInput />}
              MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              inputProps={{
                className: disabledClass,
              }}
            >
              <MenuItem
                disabled={!selectablePlaceholder}
                value={placeholder || ''}
                className={noPlaceholder ? 'd-none' : ''}
              >
                <>{placeholder}</>
              </MenuItem>
              {option?.map((opt) => {
                return (
                  <MenuItem key={opt.id} value={opt.value}>
                    {opt.option}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
        {isError ? (
          <span className="helper-text mt-1 d-flex justify-content-end w-100">
            {errorMessage}
          </span>
        ) : (
          ''
        )}
      </>
    )
  },
)

export default StyledDropdown
