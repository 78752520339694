import { useAppContext } from '@/contexts/AppContext'
import { downloadUrl } from '@/pages/dashboard/helpers'
import { FileResponse } from '@/types/file.type'
import { api } from '@/utils'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { FC } from 'react'

import { LastTutorData } from '../../tutor-last-availability.type'
import { TutorLastAvailabilityHeading } from './data'

dayjs.extend(timezone)

export interface AvailabilityDatesI {
  startDate: Dayjs | null
  endDate: Dayjs | null
}

interface TutorLastAvailabilityI {
  lastAvailability: LastTutorData[]
  availabilityDates: AvailabilityDatesI
  setIsDateError: React.Dispatch<React.SetStateAction<boolean>>
}
const TutorLastAvailabilityTable: FC<TutorLastAvailabilityI> = ({
  lastAvailability,
  availabilityDates,
  setIsDateError,
}) => {
  const { updateAppState } = useAppContext()
  const downloadCsV = async () => {
    updateAppState({ loading: true })
    const obj = {
      startDate: (availabilityDates?.startDate as Dayjs)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]'),
      endDate: (availabilityDates?.endDate as Dayjs)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]'),
    }

    if (dayjs(obj.startDate).isAfter(dayjs(obj.endDate))) {
      setIsDateError(true)
      return
    }

    const res = await api.get<FileResponse>(
      '/admin/tutorLastAvailabilityReport',
      obj,
    )
    if (res.ok && res.data) {
      const url = res.data.data.url
      downloadUrl(url, 'tutorLastAvailabilityReport.csv')
    }
    updateAppState({ loading: false })
  }
  return (
    <>
      <div className="container-fluid content ps-0">
        <div className="mt-4">
          <Button className="download-csv-button" onClick={downloadCsV}>
            Download CSV
          </Button>
        </div>
      </div>
      <TableContainer className="mt-4">
        <Table>
          <TableHead>
            {TutorLastAvailabilityHeading.map((heading) => {
              return <TableCell key={heading}>{heading}</TableCell>
            })}
          </TableHead>
          <TableBody>
            {lastAvailability.map((lastAvailable) => {
              return (
                <TableRow
                  key={lastAvailable._id}
                  className="parent-booking-row"
                >
                  <TableCell>
                    {lastAvailable.firstName + ' ' + lastAvailable.lastName}
                  </TableCell>
                  <TableCell>
                    {dayjs(lastAvailable.maxStartTime)
                      .tz(lastAvailable.metaData.timezoneOffsetZone)
                      .format('MMM DD, YYYY')}
                  </TableCell>
                  <TableCell>{lastAvailable.phoneNumber}</TableCell>
                  <TableCell>{lastAvailable.email}</TableCell>
                  <TableCell>{lastAvailable.marketName}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TutorLastAvailabilityTable
