import { useEffect, useState } from 'react'

import { useAppContext } from '../../../contexts/AppContext'
import { api } from '../../../utils'
import { ContactsFilterI } from '../Contacts'
import { Contact, ContactsRes } from './contacts.types'

interface useAllDisputesI {
  filterStates: ContactsFilterI | null
  debouncedSearch?: string
  setLoading: (status: boolean) => void
}
const useAllContacts = ({
  filterStates,
  debouncedSearch,
  setLoading,
}: useAllDisputesI) => {
  const { updateAppState } = useAppContext()

  const [allContacts, setAllContacts] = useState<Contact[]>([])
  const [totalPages, setTotalPages] = useState(0)

  const getAllContacts = async () => {
    updateAppState({ loading: true })
    setLoading(true)
    const queryObj = {
      startDate: filterStates?.startDate
        ? new Date(
            filterStates.startDate.startOf('day').toDate().getFullYear(),
            filterStates.startDate.startOf('day').toDate().getMonth(),
            filterStates.startDate.startOf('day').toDate().getDate(),
            0,
            0,
            0,
          ).toUTCString()
        : null,

      endDate: filterStates?.endDate
        ? new Date(
            filterStates.endDate.startOf('day').toDate().getFullYear(),
            filterStates.endDate.startOf('day').toDate().getMonth(),
            filterStates.endDate.startOf('day').toDate().getDate() + 1,
            0,
            0,
            0,
          ).toUTCString()
        : null,

      skip: filterStates?.skip ? (filterStates.skip - 1) * 10 : 0,
      limit: 10,
      searchParam: debouncedSearch ? debouncedSearch : null,
    }
    const res = await api.get<ContactsRes>('admin/adminContacted', queryObj)

    if (res.ok && res.data) {
      const { count, contacts } = res.data.data
      setAllContacts(contacts)
      setTotalPages(Math.ceil(count / 10))
    }
    updateAppState({ loading: false })
    setLoading(false)
  }

  useEffect(() => {
    getAllContacts()
  }, [filterStates, debouncedSearch])
  return { allContacts, totalPages, getAllContacts, setAllContacts }
}

export default useAllContacts
