import { GetMarketsRes } from '@/types/market.type'
import { Button } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import routes from '../../../../../../common/routes'
import CustomGooglePlacesAutoComplete from '../../../../../../components/google-places-auto-complete/GooglePlacesAutoComplete'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { Address, Parent } from '../../../../../../types/parent.type'
import { api } from '../../../../../../utils/api'
import AddressComponent from './components/address-component/AddressComponent'
import AddressError from './components/address-error/AddressError'
import AddressModal from './components/address-modal/AddressModal'
import NotCanadianModal from './components/not-canadian-modal/NotCanadianModal'
import OpenMarketModal from './components/open-market-modal/OpenMarketModal'

interface AddressFormProps {
  singleParent: Parent
  getSingleParent: () => void
  imageBase64: string
}
export interface GeocoderResult {
  address_components: google.maps.GeocoderAddressComponent[]
  formatted_address: string
  geometry: google.maps.GeocoderGeometry
  partial_match?: boolean
  place_id: string
  plus_code?: google.maps.places.PlacePlusCode
  postcode_localities?: string[]
  types: string[]
}

export interface AddressState {
  latitude?: number
  longitude?: number
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  country?: string
  locality?: string
  zipCode?: string
  stateCode?: string
  label?: string
}
const initialValues = {
  city: '',
  state: '',
  country: '',
  locality: '',
  zipCode: '',
  stateCode: '',
  addressLine1: '',
  addressLine2: '',
}

const AddressForm: FC<AddressFormProps> = ({
  singleParent,
  getSingleParent,
  imageBase64,
}) => {
  const navigate = useNavigate()
  const labelInit = singleParent
    ? singleParent?.parent.addresses.length < 1
      ? 'Home'
      : ''
    : ''

  const [address, setAddress] = useState<AddressState>(initialValues)
  const [addressModal, setAddressModal] = useState(false)
  const [addressErrorModal, setAddressErrorModal] = useState(false)
  const [addressError, setAddressError] = useState(false)
  const [clearFlag, setClearFlag] = useState(false)
  const [newAddressSelected, setNewAddressSelected] = useState(false)
  const [labelError, setLabelError] = useState(false)
  const [addressTouched, setAddressTouched] = useState(false)
  const [label, setLabel] = useState(labelInit)
  const [editAddress, setEditAddress] = useState('')
  const [addressMode, setAddressMode] = useState<'Add' | 'Edit'>('Add')
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>()
  const [showCanadianModal, setShowCanadianModal] = useState(false)
  const [showOpenMarket, setShowOpenMarket] = useState(false)
  const openMarketSuccessRef = useRef(false)

  const handleAddress = (placeId: string) => {
    geocodeByPlaceId(placeId)
      .then((results) => getAddress(results[0]))
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const getAddress = (place: GeocoderResult | null) => {
    // @ts-ignore
    let state, stateCode, City, locality, country, postal, route, street_number

    place?.address_components?.map((plc) => {
      plc.types?.map((type) => {
        if (type === 'administrative_area_level_1') {
          state = plc.long_name
          stateCode = plc.short_name
        } else if (type === 'administrative_area_level_2') {
          locality = plc.long_name
        } else if (type === 'locality') {
          City = plc.long_name
          // @ts-ignore
        } else if (type === 'administrative_area_level_3' && !City) {
          City = plc.long_name
        } else if (type === 'neighborhood') {
          City = plc.long_name
        } else if (type === 'sublocality_level_1') {
          City = plc.long_name
        } else if (type === 'sublocality_level_2') {
          City = plc.long_name
        } else if (type === 'sublocality_level_3') {
          City = plc.long_name
        } else if (type === 'sublocality_level_4') {
          City = plc.long_name
        } else if (type === 'sublocality_level_5') {
          City = plc.long_name
        } else if (type === 'country') {
          country = plc.long_name
        } else if (type === 'postal_code') {
          postal = plc.long_name
        } else if (type === 'route') {
          route = plc.long_name
        } else if (type === 'street_number') {
          street_number = plc.long_name
        }
      })
    })

    const tempAddress = {
      state: stateCode,
      stateCode: stateCode,
      city: City,
      country: country,
      zipCode: postal ? postal : '',
      addressLine1:
        (street_number ? street_number : '') +
        (route ? ' ' : '') +
        (route ? route : ''),
      addressLine2: '',
      latitude: place?.geometry.location.lat(),
      longitude: place?.geometry.location.lng(),
    }

    setAddress({ ...address, ...tempAddress })

    if (
      tempAddress.state &&
      tempAddress.addressLine1 &&
      tempAddress.city &&
      tempAddress.zipCode &&
      tempAddress.country
    ) {
      setAddressModal(true)
    } else {
      setAddressErrorModal(true)
    }
    setAddressError(false)
  }
  const onClearVal = () => {
    setAddress({})
  }
  const dataURLtoBlob = (dataurl: string) => {
    let arr = dataurl.split(','),
      mime = arr?.[0]?.match(/:(.*?);/)?.[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    let blob = new Blob([u8arr], { type: mime })
    return blob
  }

  const onSubmit = async () => {
    const formData = new FormData()
    const {
      addressLine1,
      addressLine2,
      city,
      zipCode,
      country,
      state,
      latitude,
      longitude,
    } = address

    if (imageBase64) {
      formData.append('profilePicture', dataURLtoBlob(imageBase64))
      const res = await api.put(
        `/admin/editProfileParent/${singleParent?._id}`,
        formData,
      )
    }

    if (addressMode !== 'Edit') {
      if ((!label && !addressLine1) || (label === 'Home' && !addressLine1)) {
        navigate('/pages/' + routes.allParents, { replace: true })
        return
      }

      if (label === '' && addressLine1) {
        setLabelError(true)
        return
      }

      if (label && !addressLine1) {
        setAddressError(true)
        return
      }
    } else {
      if (addressTouched || label === '') {
        if (label === '') setLabelError(true)
        if (addressTouched) setAddressError(true)
        return
      }
    }

    formData.append('parentId', singleParent?._id as string)
    // @ts-ignore
    formData.append('latitude', latitude)
    // @ts-ignore
    formData.append('longitude', longitude)
    formData.append(
      'addressLine1',
      addressLine1
        ? (addressLine1 as string)
        : (selectedAddress?.addressLine1 as string),
    )
    formData.append(
      'addressLine2',
      addressLine1
        ? (addressLine2 as string)
        : (selectedAddress?.addressLine2 as string),
    )
    formData.append(
      'state',
      state ? (state as string) : (selectedAddress?.state as string),
    )
    formData.append(
      'city',
      city ? (city as string) : (selectedAddress?.city as string),
    )
    formData.append(
      'country',
      country ? (country as string) : (selectedAddress?.country as string),
    )
    formData.append(
      'zipCode',
      zipCode ? (zipCode as string) : (selectedAddress?.zipCode as string),
    )
    formData.append('label', label)

    if (!singleParent.parent.addresses.length) {
      const isRateChargeSuppress =
        singleParent.partnershipCode?.[0]?.isRateChargeSuppress
      const result = await api.get<GetMarketsRes>('admin/getMarketDetails', {
        longitude,
        latitude,
      })
      if (!result.ok) {
        toast.error('Error while getting markets.')
        return
      }
      if (!result.data?.data.length) {
        if (address.country === 'Canada' && !isRateChargeSuppress) {
          setShowCanadianModal(true)
          return
        } else if (!openMarketSuccessRef.current) {
          setShowOpenMarket(true)
          openMarketSuccessRef.current = false
          return
        }
      }
    }

    if (addressMode === 'Add') {
      const addResult = await api.post(`/parent/addAddress`, formData)
      if (addResult.ok) {
        navigate('/pages/' + routes.allParents)
      }
    } else {
      const editRes = await api.put(
        `/parent/editAddress/${selectedAddress?._id}`,
        formData,
      )
      if (editRes.ok) {
        setAddressMode('Add')
        setEditAddress('')
        setLabel('')
        getSingleParent()
      }
    }
    setLabelError(false)
  }

  const isButtonValid = () => {
    if (addressMode === 'Add') {
      if (label?.toLowerCase() === 'home' && singleParent?.parent?.addresses)
        return false
      else true
    } else {
      return false
    }
  }

  return (
    <div>
      <hr className="hr-border" />
      <form className="w-auto">
        <div className="form-group row">
          <div className="col-sm-4 text-left">Address</div>

          <StyledTextfield
            whiteBg
            placeholder="Enter Label"
            className="col-sm-7"
            readOnly={label === 'Home' ? true : false}
            value={label}
            onChange={(e) => {
              setLabel(e.target.value)
            }}
            errorMessage={labelError ? 'Invalid Label' : ''}
            isError={labelError}
          />
        </div>

        <div className="form-group row">
          <div className=" col-sm-4 text-left"></div>
          <div className="col-sm-7">
            <CustomGooglePlacesAutoComplete
              onChange={handleAddress}
              clearValue={clearFlag}
              setClearValue={setClearFlag}
              defaultValue={editAddress}
              setAddressError={setAddressError}
              addressError={addressError}
              addressMode={addressMode}
              newAddressSelected={newAddressSelected}
              onClearVal={onClearVal}
              addressTouched={() => {
                if (!newAddressSelected) setAddressTouched(true)
              }}
              path="addressForm"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className=" col-sm-4 text-left"></div>
          <div className="col-sm-7">
            {!!singleParent &&
            singleParent?.parent?.addresses?.length > 0 &&
            addressMode === 'Add' ? (
              <>
                {singleParent.parent.addresses?.map((address) => {
                  return (
                    <AddressComponent
                      key={address._id}
                      address={address}
                      parentId={singleParent?._id}
                      setEditAddress={setEditAddress}
                      setAddressMode={setAddressMode}
                      setSelectedAddress={setSelectedAddress}
                      setLabel={setLabel}
                      getSingleParent={getSingleParent}
                      setNewAddressSelected={setNewAddressSelected}
                    />
                  )
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="offset-sm-9 col-sm-3 mt-5">
          <Button color="success" onClick={onSubmit} disabled={isButtonValid()}>
            Save
          </Button>
        </div>
      </form>
      <AddressModal
        open={addressModal}
        setOpen={setAddressModal}
        address={address}
        setAddress={setAddress}
        setClearFlag={setClearFlag}
        onAdd={() => {
          setNewAddressSelected(true)
          setAddressError(false)
          setAddressTouched(false)
        }}
      />
      <AddressError
        open={addressErrorModal}
        setOpen={setAddressErrorModal}
        onClose={() => {
          setClearFlag(true)
        }}
      />
      <NotCanadianModal
        open={showCanadianModal}
        onClose={() => setShowCanadianModal(false)}
      />
      <OpenMarketModal
        open={showOpenMarket}
        onClose={() => {
          setShowOpenMarket(false)
          navigate('/pages/' + routes.allParents)
        }}
        onSuccess={() => {
          openMarketSuccessRef.current = true
          setShowOpenMarket(false)
          onSubmit()
        }}
      />
    </div>
  )
}

export default AddressForm
