import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import {
  ConstantResponse,
  ReferralCases,
} from '../../../../types/constants.type'
import { api } from '../../../../utils'
import RTermsAndConditionComponent from './components/rTerm-and-conditions-component/RTermsAndConditionComponent'

const AllReferralTC = () => {
  const [referralCases, setReferralCases] = useState<ReferralCases>()
  const [selectedPolicy, setSelectedPolicy] = useState('')
  const getConstants = async () => {
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      const { referralCases } = constantsRes.data.data
      setReferralCases(referralCases)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
      </div>
      <div className="row">
        <RTermsAndConditionComponent
          referralCases={referralCases}
          getConstants={getConstants}
          selectedPolicy={selectedPolicy}
          caseNumber="1"
          onViewClick={() => {
            setSelectedPolicy('1')
          }}
          onHideClick={() => {
            setSelectedPolicy('')
          }}
        />
        <RTermsAndConditionComponent
          referralCases={referralCases}
          getConstants={getConstants}
          selectedPolicy={selectedPolicy}
          caseNumber="2"
          onViewClick={() => {
            setSelectedPolicy('2')
          }}
          onHideClick={() => {
            setSelectedPolicy('')
          }}
        />
        <RTermsAndConditionComponent
          referralCases={referralCases}
          selectedPolicy={selectedPolicy}
          getConstants={getConstants}
          caseNumber="3"
          onViewClick={() => {
            setSelectedPolicy('3')
          }}
          onHideClick={() => {
            setSelectedPolicy('')
          }}
        />
        <RTermsAndConditionComponent
          getConstants={getConstants}
          referralCases={referralCases}
          selectedPolicy={selectedPolicy}
          caseNumber="4"
          onViewClick={() => {
            setSelectedPolicy('4')
          }}
          onHideClick={() => {
            setSelectedPolicy('')
          }}
        />
        <RTermsAndConditionComponent
          referralCases={referralCases}
          getConstants={getConstants}
          selectedPolicy={selectedPolicy}
          caseNumber="5"
          onViewClick={() => {
            setSelectedPolicy('5')
          }}
          onHideClick={() => {
            setSelectedPolicy('')
          }}
        />
      </div>
    </div>
  )
}

export default AllReferralTC
