import { Button, Pagination, SelectChangeEvent } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useState, useRef } from 'react'

import CustomDatePicker from '../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../contexts/AppContext'
import { useDebounce } from '../../hooks'
import { FileResponse } from '../../types/file.type'
import { api } from '../../utils'
import { downloadUrl } from '../dashboard/helpers'
import './all-parents.scss'
import { filterButtons } from './components/data'
import useAllParents from './components/get-all-parents/GetAllParents'
import ParentTable from './components/parent-table/ParentTable'

export interface ParamType {
  type?: string | null
  marketId?: string | null
  skip?: number | null
  searchParam?: string | null
  sortBy?: string | null
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  limit?: number | null
}

const paramsInit: ParamType = {
  type: null,
  marketId: '',
  skip: 0,
  searchParam: '',
  endDate: null,
  startDate: null,
  sortBy: '',
}
const AllParents = () => {
  const { updateAppState, marketData } = useAppContext()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const oldFilter: ParamType = JSON.parse(
    localStorage.getItem('ParentFilters') as string,
  )
  const [filterStates, setFilterStates] = useState<ParamType>({
    ...paramsInit,
    type: oldFilter?.type ? oldFilter.type : paramsInit.type,
  })

  const debouncedSearch = useDebounce({ value: search, milliSeconds: 500 })

  const prevSearchRef = useRef('')

  if (prevSearchRef.current !== debouncedSearch) {
    setFilterStates((filters) => ({ ...filters, skip: 1 }))
  }
  prevSearchRef.current = debouncedSearch

  const { allParents, totalPages, getParentDetails } = useAllParents({
    debouncedSearch,
    filterStates,
  })

  const onDateChange = (
    value: dayjs.Dayjs | null | undefined,
    mode?: string,
  ) => {
    setFilterStates({ ...filterStates, [mode as string]: value })
  }

  const handleChange = (__event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    setFilterStates({ ...filterStates, skip: value })
  }

  const marketChange = (event: SelectChangeEvent) => {
    setFilterStates({ ...filterStates, marketId: event.target.value, skip: 1 })
  }
  const handleSortBy = (value: string) => {
    setFilterStates({ ...filterStates, sortBy: value })
  }
  const downloadCsV = async () => {
    updateAppState({ loading: true })
    const res = await api.get<FileResponse>('/admin/parentReport')
    if (res.ok && res.data) {
      const url = res.data.data.url
      downloadUrl(url, 'parentReport.csv')
    }
    updateAppState({ loading: false })
  }

  return (
    <div className="main">
      <div className="container-fluid content">
        <div className="container-fluid">
          <div className="mt-4">
            <Button onClick={downloadCsV}>Download CSV</Button>
          </div>
          <div className="d-flex flex-wrap mt-4">
            {filterButtons.map((button) => {
              return (
                <Button
                  key={button.id}
                  variant={
                    button.type === filterStates.type ? 'contained' : 'outlined'
                  }
                  className="me-3 mt-3 blue-button"
                  onClick={() => {
                    setFilterStates({
                      ...filterStates,
                      type: button.type,
                      skip: 1,
                    })
                    setPage(1)
                  }}
                >
                  {button.title}
                </Button>
              )
            })}
          </div>
          <div className="row mt-2">
            <div className="col-md-3 mt-2">
              <CustomDatePicker
                date={filterStates.startDate}
                maxDate={filterStates.endDate}
                placeholder="Select Start Date"
                mode="startDate"
                onChange={(value) => {
                  value && onDateChange(value, 'startDate')
                }}
                onClear={() => onDateChange(null, 'startDate')}
              />
            </div>
            <div className="col-md-3 mt-2">
              <CustomDatePicker
                date={filterStates.endDate}
                minDate={filterStates.startDate}
                placeholder="Select End Date"
                mode="endDate"
                onChange={(value) => {
                  value && onDateChange(value, 'endDate')
                }}
                onClear={() => onDateChange(null, 'endDate')}
              />
            </div>

            <div className="col-md-3 mt-2">
              <MarketDropdown
                option={marketData}
                value={filterStates.marketId}
                handleChange={marketChange}
                placeholder="--Select Market Name--"
              />
            </div>
            <div className="col-md-3 mt-2">
              <SearchBar
                placeholder="Search..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setPage(1)
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <ParentTable
              parentData={allParents}
              buttonType={filterStates.type}
              handleSortBy={handleSortBy}
              getParentDetails={getParentDetails}
              sortBy={filterStates.sortBy ? filterStates.sortBy : ''}
            />
          </div>
          {allParents && allParents?.length > 0 ? (
            <>
              {totalPages > 1 && (
                <div className="mt-2 d-flex justify-content-end">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="mt-2 d-flex justify-content-start">
                <p>No Parent Found</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AllParents
