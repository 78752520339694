import {
  IconButton,
  Pagination,
  PaginationProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { FC, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'

import { DatumWithType } from '../../geo-fencing.type'
import EditRegionModal from '../edit-region-modal/EditRegionModal'
import './geo-fencing-table.scss'

interface GeoFencingTableIProps {
  areas: DatumWithType[]
  totalPages: number
  page: number
  getAreas: () => Promise<void>
  onPageChange: PaginationProps['onChange']
}

const tableHeadings = ['MarketId', 'Market Name', 'Accepting Parents', 'Type']

const GeoFencingTable: FC<GeoFencingTableIProps> = ({
  areas,
  totalPages,
  page,
  onPageChange,
  getAreas,
}) => {
  const [selectedArea, setSelectedArea] = useState<DatumWithType | null>(null)

  return (
    <>
      <TableContainer>
        <Table
          className="geofencing-table"
          sx={{
            '& td, & th': {
              padding: '2px 35px',
              textAlign: 'left',
              width: '250px',
              whiteSpace: 'nowrap',
            },
            '& tr:last-child td': {
              border: 0,
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              {tableHeadings.map((heading) => (
                <TableCell key={heading}>{heading}</TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!areas.length && (
              <TableRow>
                <TableCell colSpan={7}>No area found</TableCell>
              </TableRow>
            )}
            {areas.map((area) => (
              <TableRow key={area._id}>
                <TableCell>{area.marketId}</TableCell>
                <TableCell>{area.marketName}</TableCell>
                <TableCell>
                  {area.isNotAcceptingParents ? 'No' : 'Yes'}
                </TableCell>
                <TableCell>
                  {area.type === 'zipCode' ? 'Zip Code' : 'Map'}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => setSelectedArea(area)}>
                    <FaPencilAlt color="black" size={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div className="mt-2 d-flex justify-content-end">
          <Pagination count={totalPages} page={page} onChange={onPageChange} />
        </div>
      )}
      {!!selectedArea && (
        <EditRegionModal
          open={!!selectedArea}
          onClose={() => setSelectedArea(null)}
          onEdit={getAreas}
          area={selectedArea}
        />
      )}
    </>
  )
}

export default GeoFencingTable
