import { Button } from '@mui/material'
import { FC } from 'react'

import { api } from '../../../utils'
import ModalWrapper from '../../modal-wrapper/ModalWrapper'
import './delete-modal.scss'

interface CardDeleteModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  parentId: string
  cardId: string
  getCardDetails: () => Promise<void>
}
const CardDeleteModal: FC<CardDeleteModalProps> = ({
  open,
  setOpen,
  cardId,
  parentId,
  getCardDetails,
}) => {
  const handleClose = () => {
    setOpen(false)
  }
  const deleteCard = async () => {
    const res = await api.delete(`admin/parent/${parentId}deleteCard/${cardId}`)
    if (res.ok) {
      handleClose()
      getCardDetails()
    }
  }
  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <div className="d-flex justify-content-center align-items-center flex-column delete-modal">
        <p className="card-delete-modal__title">
          Are you sure you want to delete this credit card?
        </p>
        <div>
          <Button
            color="inherit"
            className="modal-button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button color="success" className="modal-button" onClick={deleteCard}>
            Delete Card
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default CardDeleteModal
