import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { FC } from 'react'

import { Parent } from '../../../../../types/parent.type'
import { Tutor } from '../../../../../types/tutor.type'
import './add-notification-table.scss'

interface AddNotificationTableIProps {
  totalPages: number
  currentPage: number
  onPageChange: (page: number) => void
  data: Tutor[] | Parent[] | undefined
  onChange: (index: number, checked: boolean) => void
  usersIds: { [key: string]: boolean }
  isAllChecked: boolean
  loading: boolean
}

const AddNotificationTable: FC<AddNotificationTableIProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  data,
  onChange,
  usersIds,
  isAllChecked,
  loading,
}) => {
  const pageIndex = (currentPage - 1) * 10

  return (
    <>
      <TableContainer>
        <Table sx={{ '& td,th': { padding: '10px 15px' } }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <input
                  type="checkbox"
                  checked={isAllChecked}
                  onChange={(e) => onChange(-1, e.target.checked)}
                />
              </TableCell>
              <TableCell>Sr No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data.map((d, index) => (
                <TableRow key={d._id}>
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={!!usersIds[d._id] && !loading}
                      onChange={(e) => onChange(index, e.target.checked)}
                    />
                  </TableCell>
                  <TableCell>{index + pageIndex + 1}</TableCell>
                  <TableCell>{`${d.firstName} ${d.lastName}`}</TableCell>
                  <TableCell>{`${d.userName}`}</TableCell>
                  <TableCell>{d.changedEmail || d.email}</TableCell>
                  <TableCell>{d.changedPhoneNumber || d.phoneNumber}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <p className="text-center mb-0">No record found</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <div className="mt-2 d-flex justify-content-end">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(_event, page) => onPageChange(page)}
          />
        </div>
      )}
    </>
  )
}

export default AddNotificationTable
