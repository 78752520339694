import { Pagination } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'

import CustomDatePicker from '../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../contexts/AppContext'
import { useDebounce } from '../../hooks'
import FeedbackTable from './components/feedback-table/FeedbackTable'
import useAllFeedbacks from './hooks/useAllFeedbacks'

export interface FeedbackFilterI {
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  marketId?: string
  skip?: number | null
}
const defaultFeedbackFilter: FeedbackFilterI = {
  startDate: null,
  endDate: null,
  marketId: '',
  skip: null,
}

const Feedbacks = () => {
  const { marketData } = useAppContext()

  const [page, setPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [feedbackFilters, setFeedbackFilters] = useState<FeedbackFilterI>(
    defaultFeedbackFilter,
  )

  const onDateChange = (value: Dayjs | null | undefined, mode?: string) => {
    setPage(1)
    let temp: FeedbackFilterI = {
      skip: 0,
    }
    if (mode === 'startDate') {
      temp.startDate = value
    } else {
      temp.endDate = value
    }
    setFeedbackFilters({ ...feedbackFilters, ...temp })
  }

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
    setFeedbackFilters({ ...feedbackFilters, skip: value })
  }
  const debouncedSearch = useDebounce({
    value: search,
    milliSeconds: 500,
  })

  const prevSearchRef = useRef('')

  if (prevSearchRef.current !== debouncedSearch) {
    setFeedbackFilters((filters) => ({ ...filters, skip: 1 }))
  }
  prevSearchRef.current = debouncedSearch

  const { allFeedbacks, totalPages, getAllFeedbacks } = useAllFeedbacks({
    filterStates: feedbackFilters,
    debouncedSearch,
    setLoading(status: boolean) {
      setIsLoading(status)
    },
  })

  return (
    <div className="container-fluid content">
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={feedbackFilters.startDate}
              maxDate={feedbackFilters.endDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'startDate')
              }}
              placeholder="Select Start Date"
              mode="startDate"
            />
          </div>
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={feedbackFilters.endDate}
              minDate={feedbackFilters.startDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'endDate')
              }}
              placeholder="Select End Date"
              mode="endDate"
            />
          </div>

          <div className="col-md-3 mt-2 filters-width">
            <MarketDropdown
              placeholder="--Select Market Name--"
              option={marketData}
              value={feedbackFilters.marketId}
              handleChange={(e) => {
                setPage(1)
                let temp: FeedbackFilterI = {
                  skip: 0,
                  marketId: e.target.value,
                }
                setFeedbackFilters({
                  ...feedbackFilters,
                  ...temp,
                })
              }}
            />
          </div>

          <div className=" d-flex col-md-3 col-xl-2  mt-2">
            <SearchBar
              className="search-box"
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setPage(1)
              }}
            />
          </div>
        </div>
        <div className="mt-2">
          <FeedbackTable
            allFeedbacks={allFeedbacks}
            getAllFeedbacks={getAllFeedbacks}
          />
        </div>
        {allFeedbacks && allFeedbacks?.length > 0 ? (
          <>
            {totalPages > 1 && (
              <div className="mt-2 d-flex justify-content-end">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </>
        ) : (
          <>
            {!isLoading && (
              <div className="mt-2 d-flex justify-content-start">
                <p>Record not found</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Feedbacks
