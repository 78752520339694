import { Button } from '@mui/material'
import { FC } from 'react'

import { ParamType } from '../../../pages/tutor/AllTutors'
import { TutorModalsProps } from '../../../pages/tutor/components/tutor-table/tutor-table.type'
import { api } from '../../../utils'
import ModalWrapper from '../../modal-wrapper/ModalWrapper'

interface SylvanSyncProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<TutorModalsProps>>
  modalName: string
  userId?: string
  role?: string
  name?: string
  checkStatus?: boolean
  getTutorDetails?: (data?: ParamType) => Promise<void>
}

const SylvanSync: FC<SylvanSyncProps> = ({
  open,
  setOpen,
  modalName,
  userId,
  role,
  checkStatus,
  getTutorDetails,
}) => {
  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const markSylvanSync = async () => {
    const res = await api.put('admin/markTutorAsSylvanSyncEnabled/' + userId, {
      flag: !checkStatus,
    })
    if (res.ok) {
      handleClose()
      if (getTutorDetails) getTutorDetails()
    }
  }

  return (
    <ModalWrapper
      open={open.sylvanSync}
      handleClose={handleClose}
      boldTitle
      title={`${!checkStatus ? 'Enable' : 'Disable'}  Sylvan Sync Flag?`}
      wide
    >
      <div className="d-flex justify-content-center align-items-center flex-column blueText">
        <p>{`Are you sure you want to ${
          !checkStatus ? 'enable' : 'disable'
        } the Sylvan Sync Flag this tutor?`}</p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={markSylvanSync}
          >
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default SylvanSync
