import { Button, Radio, RadioGroup } from '@mui/material'
import _ from 'lodash'
import { FC, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { GrFormClose } from 'react-icons/gr'

import StyledDropdown from '../../../../../../components/styled-dropdown/StyledDropdown'
import StyledReactSelect, {
  SelectObjectVal,
} from '../../../../../../components/styled-react-select/StyledReactSelect'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../../../contexts/AppContext'
import { EducationalDegree, Tutor } from '../../../../../../types/tutor.type'
import { STATES } from '../../../../../../utils'
import { EditTutorData } from '../../EditTutor'
import './edu-n-professional.scss'

interface EduNProfessionalBgProps {
  setHighestDegreeFlag: React.Dispatch<React.SetStateAction<number>>
  setResumeDoc: React.Dispatch<React.SetStateAction<File | undefined>>
  setCertificateImage: React.Dispatch<React.SetStateAction<File | null>>
  highestDegreeFlag: number
  singleTutor?: Tutor
}

const EduNProfessionalBg: FC<EduNProfessionalBgProps> = ({
  setHighestDegreeFlag,
  highestDegreeFlag,
  setResumeDoc,
  singleTutor,
  setCertificateImage,
}) => {
  const {
    getValues,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<EditTutorData>()

  const { fields, append, replace } = useFieldArray({
    control,
    name: 'educationalDegrees',
  })
  const { subjectsArray } = useAppContext()

  const values = getValues()

  const gradeLevels = watch('gradeLevel')

  const educationalDegrees = watch('educationalDegrees')

  const addNewEducationDoc = () => {
    append({
      city: '',
      country: '',
      degree: '',
      intstitutionName: '',
      isHighestDegree: false,
      state: '',
      subject: '',
      year: '',
      _id: '',
    })
  }

  const handleRemoveEducationDoc = (index: number) => {
    if (index === highestDegreeFlag) {
      setHighestDegreeFlag(-2)
    }
    const clonedFields = _.cloneDeep(fields)
    clonedFields.splice(index, 1)

    if (index < highestDegreeFlag) {
      clonedFields.forEach((_field, index) => {
        if (index === highestDegreeFlag - 1) {
          setHighestDegreeFlag(highestDegreeFlag - 1)
          clonedFields[index].isHighestDegree = !!index
        } else {
          clonedFields[index].isHighestDegree = false
        }
      })
    }
    replace(clonedFields)
  }

  const currentYear = new Date().getFullYear()
  let range: any[] = []
  for (let i = currentYear - 1; i > 1960; i--) {
    range.push(i)
  }

  range = range.map((value) => ({ id: value, value, option: value }))
  const countries = [
    { id: 'US', value: 'US', option: 'USA' },
    { id: 'Other', value: 'Other', option: 'Other' },
  ]

  const grades = [
    { id: 'K-2', value: 'K-2', label: 'K-2' },
    { id: '3-5', value: '3-5', label: '3-5' },
    { id: '6-8', value: '6-8', label: '6-8' },
    { id: '9-12', value: '9-12', label: '9-12' },
  ]

  const subjects = subjectsArray?.map((subject) => ({
    id: subject._id,
    label: subject.name,
    value: subject._id,
  }))

  const subjectName = watch('subjectName')
  const isStateCertified = watch('stateCertified')

  const subCategories = subjectsArray
    ?.map((subject) => {
      if (subjectName?.find((s) => s.id === subject._id)) {
        return (
          subject.subcategories?.map((subCat) => ({
            id: subCat._id,
            value: subCat._id,
            label: subCat.name,
          })) || []
        )
      }
      return []
    })
    .flat()

  const subSubject = watch('subSubject')

  useEffect(() => {
    const newSubSubjects: SelectObjectVal[] = []
    subjectsArray?.forEach((subject) => {
      const foundSubject = subjectName?.find(
        (subject2) => subject2.id === subject._id,
      )
      if (foundSubject) {
        subject.subcategories?.forEach((sub) => {
          if (subSubject?.some((subCat) => subCat.id === sub._id)) {
            newSubSubjects.push({
              id: sub._id,
              label: sub.name,
              value: sub._id,
            })
          }
        })
      }
    })
    if (!subjectName?.length) {
      setValue('subSubject', [])
    }
    if (!!newSubSubjects.length) setValue('subSubject', newSubSubjects)
  }, [subjectName])

  return (
    <div className="edu-n-professional-bg">
      <h5 className="heading-container">
        Educational and Professional Background
      </h5>
      <div className="row">
        <div className="form-group mb-0 col-sm-6">
          <div className="row">
            <div className="col-sm-12 mt-0 p-0">
              <label className="pad0">
                Briefly describe your relevant professional experience in
                teaching or tutoring, including the length of time, subjects,
                and age of students you’ve worked with.
              </label>
            </div>
          </div>
          <div className="row">
            <Controller
              name="experience"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  multiline
                  rows={4}
                  required={singleTutor?.tutor.profileStep === 7}
                  placeholder="Professional Experience"
                  className="col-sm-10 m-1"
                  isError={!field.value && singleTutor?.tutor.profileStep === 7}
                  errorMessage={
                    !field.value && singleTutor?.tutor.profileStep === 7
                      ? ' Professional experience is required'
                      : ''
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="form-group mb-0 col-sm-6 ">
          <div className="row">
            <div className="col-sm-12 mt-0 p-0">
              <label className="pad0">
                Please upload your resume here (Optional)
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <div className="p-1 styled-textfield bg-light">
                <input
                  type="file"
                  accept=".doc, .docx, .pdf"
                  onChange={(e) => {
                    setResumeDoc(e.target.files?.[0])
                  }}
                />
              </div>

              {values.tutorResume ? (
                <div className="resume-container">
                  <a
                    href={values.tutorResume.url}
                    target="_blank"
                    className="pink-color"
                  >
                    {values.tutorResume.name}
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="form-group mb-0 col-sm-10 mt-3">
            <div className="row">
              <div className="col-sm-12 mt-0 p-0">
                <label className="pad0" style={{ color: '#232f84' }}>
                  Are you a current or former state-certified teacher?
                  (Optional)
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10">
                <div className="row">
                  <Controller
                    control={control}
                    name="stateCertified"
                    render={({ field }) => (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={field.value != undefined ? field.value : ''}
                        onChange={(e) => {
                          setValue('stateCertified', e.target.value === 'true')
                        }}
                      >
                        <div className="row">
                          <div className="col-sm-5 mart20px">
                            <Radio value="true" />
                            <span>Yes</span>
                          </div>
                          <div className="col-sm-5 mart20px">
                            <Radio value="false" />
                            <span>No</span>
                          </div>
                        </div>
                      </RadioGroup>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {isStateCertified && (
            <div className="form-group mb-0">
              <div className="row">
                <div className="col-sm-12 mt-0 p-0">
                  <label className="pad0">
                    Please upload your certificate here
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10">
                  <div className="p-1 styled-textfield bg-light">
                    <input
                      type="file"
                      accept=".doc, .docx, .pdf"
                      onChange={(e) => {
                        setCertificateImage(e.target.files?.[0] as File)
                      }}
                    />
                  </div>

                  {values.tutorCertificate ? (
                    <div className="resume-container">
                      <a
                        href={values.tutorCertificate.url}
                        target="_blank"
                        className="pink-color"
                      >
                        {values.tutorCertificate.name}
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-0  col-sm-6 col-xs-12">
          <div className="row">
            <div className="col-sm-12 mt-0  p-0">
              <label className="pad0">
                Add link to LinkedIn profile (Optional):
              </label>
            </div>
          </div>
          <div className="row">
            <Controller
              name="linkedInProfile"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  placeholder="Linkedin Profile"
                  isError={!!errors.linkedInProfile}
                  className="col-sm-10 m-1"
                  errorMessage={errors.linkedInProfile?.message?.toString()}
                />
              )}
            />
          </div>
        </div>
        <div className="form-group mb-0 col-sm-6 col-xs-12">
          <div className="row ">
            <div className="col-sm-12 mt-0 p-0">
              <label className="pad0">
                What grade level students do you feel most comfortable tutoring?
              </label>
            </div>
          </div>
          <div className="row  ps-2">
            <Controller
              name="gradeLevel"
              control={control}
              render={({ field }) => (
                <StyledReactSelect
                  value={field.value}
                  options={grades}
                  onChange={(value) => {
                    setValue('gradeLevel', value)
                  }}
                  classNamePrefix="react-select"
                  required={singleTutor?.tutor.profileStep === 7}
                  className="col-sm-10 m-1 p-0"
                  placeholder="---Select Grade Level---"
                />
              )}
            />
            {singleTutor?.tutor.profileStep === 7 &&
            gradeLevels &&
            !gradeLevels.length ? (
              <span className="helper-text mt-1 d-flex justify-content-end col-sm-10 m-1 p-0">
                Grade Level is Required
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-0 col-sm-6 col-xs-12">
          <div className="row">
            <div className="col-sm-12 mt-0 p-0">
              <label className="mySubjects">
                What subjects do you want to tutor?
              </label>
            </div>
          </div>
          <div className="row  ps-2 pe-2">
            <Controller
              name="subjectName"
              control={control}
              render={({ field }) => (
                <StyledReactSelect
                  value={field.value}
                  options={subjects}
                  onChange={(value) => {
                    setValue('subjectName', value)
                  }}
                  classNamePrefix="react-select"
                  required={singleTutor?.tutor.profileStep === 7}
                  className="col-sm-10 m-1 p-0"
                  placeholder="---Select Grade Level---"
                />
              )}
            />
            {singleTutor?.tutor.profileStep === 7 &&
            subjectName &&
            !subjectName.length ? (
              <span className="helper-text mt-1 d-flex justify-content-end col-sm-10 m-1 p-0">
                Subjects are Required
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="form-group mb-0 col-sm-6 col-xs-12">
          <div className="row">
            <div className="col-sm-12 mt-0 p-0">
              <label className="pad0">Sub Subject Name</label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 m-1 pe-0 ps-2">
              <Controller
                name="subSubject"
                control={control}
                render={({ field }) => (
                  <StyledReactSelect
                    value={field.value}
                    options={subCategories}
                    onChange={(value) => {
                      setValue('subSubject', value)
                    }}
                    classNamePrefix="react-select"
                    required={singleTutor?.tutor.profileStep === 7}
                    className="col-sm-12 p-0"
                    placeholder="---Select Grade Level---"
                  />
                )}
              />

              {singleTutor?.tutor.profileStep === 7 &&
              subjectName &&
              subjectName.length &&
              subSubject &&
              !subSubject.length ? (
                <span className="helper-text mt-1 d-flex justify-content-end col-sm-10 m-1 p-0 w-100">
                  Sub subject name is required.
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-12 col-xs-12">
          <div className="row p-0">
            <div className="col-sm-12">
              <label htmlFor="educationalDegrees " className="pad0">
                List your educational degrees:
              </label>
            </div>

            <div className="row">
              {/* loop here */}
              {fields.map((field, index) => (
                <div key={field.id} className="col-md-12">
                  <div className="dashpadinner mlr">
                    <div className="row ">
                      <div className="col-sm-12">
                        <div className="row mb33 mt5">
                          <div className="col-sm-5 ">
                            School/University Name
                            <Controller
                              control={control}
                              name={`educationalDegrees.${index}.intstitutionName`}
                              render={({ field }) => (
                                <StyledTextfield
                                  {...field}
                                  placeholder="Institution Name"
                                  required={
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  isError={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  errorMessage={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                      ? 'School/University name is required.'
                                      : ''
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="col-sm-5 marginLeft8">
                            Degree Name
                            <Controller
                              control={control}
                              name={`educationalDegrees.${index}.degree`}
                              render={({ field }) => (
                                <StyledTextfield
                                  {...field}
                                  placeholder="Degree"
                                  required={
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  isError={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  errorMessage={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                      ? 'Degree name is required.'
                                      : ''
                                  }
                                />
                              )}
                            />
                          </div>

                          <div className="col-sm-1 ">
                            {fields.length > 1 && (
                              <GrFormClose
                                size={30}
                                className="cursor-pointer"
                                onClick={() => handleRemoveEducationDoc(index)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row mb33 mt5">
                          <div className="col-sm-5">
                            Subject/Major
                            <Controller
                              control={control}
                              name={`educationalDegrees.${index}.subject`}
                              render={({ field }) => (
                                <StyledTextfield
                                  {...field}
                                  placeholder="Subject Name"
                                  required={
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  isError={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  errorMessage={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                      ? 'Subject Major is required.'
                                      : ''
                                  }
                                />
                              )}
                            />
                          </div>

                          <div className="col-sm-5 marginLeft8">
                            Year
                            <Controller
                              control={control}
                              name={`educationalDegrees.${index}.year`}
                              render={({ field }) => (
                                <StyledDropdown
                                  option={range}
                                  {...field}
                                  selectablePlaceholder
                                  placeholder="--Select Year--"
                                  required={
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  isError={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  errorMessage={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                      ? 'Year is required.'
                                      : ''
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="row mb33 mt5">
                          <div className="col-sm-5 ">
                            Country
                            <Controller
                              control={control}
                              name={`educationalDegrees.${index}.country`}
                              render={({ field }) => (
                                <StyledDropdown
                                  option={countries}
                                  {...field}
                                  onChange={(e) => {
                                    setValue(
                                      `educationalDegrees.${index}.country`,
                                      e.target.value,
                                    )
                                    setValue(
                                      `educationalDegrees.${index}.state`,
                                      '',
                                    )
                                  }}
                                  placeholder="--Select Country--"
                                  required={
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  isError={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  errorMessage={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                      ? 'Country is required.'
                                      : ''
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="col-sm-5 marginLeft8">
                            State
                            <Controller
                              control={control}
                              name={`educationalDegrees.${index}.state`}
                              render={({ field }) => {
                                const stateArr =
                                  educationalDegrees &&
                                  educationalDegrees[index].country !== 'Other'
                                    ? STATES[0]
                                    : STATES[1]
                                const selectedStates = stateArr.map(
                                  ([option, value]) => ({
                                    id: value,
                                    value,
                                    option,
                                  }),
                                )

                                return (
                                  <StyledDropdown
                                    option={selectedStates}
                                    {...field}
                                    placeholder="--Select State--"
                                    required={
                                      singleTutor?.tutor.profileStep === 7
                                    }
                                    isError={
                                      !field.value &&
                                      singleTutor?.tutor.profileStep === 7
                                    }
                                    errorMessage={
                                      !field.value &&
                                      singleTutor?.tutor.profileStep === 7
                                        ? 'State is required.'
                                        : ''
                                    }
                                  />
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className="row  mt5">
                          <div className="col-sm-5">
                            School City
                            <Controller
                              control={control}
                              name={`educationalDegrees.${index}.city`}
                              render={({ field }) => (
                                <StyledTextfield
                                  {...field}
                                  placeholder="City Name"
                                  required={
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  isError={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                  }
                                  errorMessage={
                                    !field.value &&
                                    singleTutor?.tutor.profileStep === 7
                                      ? 'School City is required.'
                                      : ''
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center mb-3 mt-3">
                          <Controller
                            control={control}
                            name={`educationalDegrees.${index}.isHighestDegree`}
                            render={({ field }) => (
                              <Radio
                                {...field}
                                className="p-0 me-2"
                                checked={field.value}
                                onClick={() => setHighestDegreeFlag(index)}
                                onChange={() => {
                                  fields.forEach((_value, innerIndex) => {
                                    setValue(
                                      `educationalDegrees.${innerIndex}.isHighestDegree`,
                                      innerIndex === index,
                                    )
                                  })
                                }}
                              />
                            )}
                          />

                          <span
                            className={`lightText radioText d-inline ${
                              highestDegreeFlag === -2 ? 'text-danger' : ''
                            }`}
                          >
                            Check here if this is your highest completed degree.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-sm-11 edu-n-professional-bg__add-degree-btn">
          <Button type="button" onClick={addNewEducationDoc}>
            Add More Degree
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EduNProfessionalBg
