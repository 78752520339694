import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import { api } from '../../../../utils'
import { AllFeeRes, Fee } from './AllFeeTable.type'
import AddEditFee from './components/add-edit-fee/AddEditFee'
import AllFeeTable from './components/all-fee-table/AllFeeTable'

const AllFee = () => {
  const [allFee, setAllFee] = useState<Fee[]>([])
  const [addFee, setAddFee] = useState(false)

  const getAllFee = async () => {
    const res = await api.get<AllFeeRes>('admin/getSlidingFee')

    if (res && res.data) {
      const { data } = res.data
      setAllFee(data)
    }
  }

  useEffect(() => {
    getAllFee()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setAddFee(true)
            }}
          >
            Add Fee
          </Button>
        </div>
      </div>
      <div className="row">
        <AllFeeTable allFee={allFee} getAllFee={getAllFee} />
      </div>
      {addFee && (
        <AddEditFee
          open={addFee}
          mode="Add"
          onClose={() => {
            setAddFee(false)
            getAllFee()
          }}
        />
      )}
    </div>
  )
}

export default AllFee
