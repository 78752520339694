import { TableCell, TableRow, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'

import ConfirmDelete from '../../../../../components/confirm-delete/ConfirmDelete'
import { api } from '../../../../../utils'
import { Resource } from '../../tutorResources.type'
import AddEditTutorResource from '../add-edit-tutor-resources/AddEditResource'
import './resource.scss'

interface ResourceTableBodyI {
  resource: Resource
  getResources: () => Promise<void>
  role?: 'PARENT' | 'TUTOR'
}

const ResourceTableBody: FC<ResourceTableBodyI> = ({
  resource,
  getResources,
  role,
}) => {
  const isMarketPlaceManager =
    localStorage.getItem('isMarketplaceManager') === 'true' ? true : false

  const [editTutorResource, setEditTutorResource] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const deleteResource = async () => {
    const formData = new FormData()
    formData.append('category', resource.category as string)
    let res
    if (role === 'PARENT') {
      res = await api.put(`admin/deleteParentResource/${resource.id}`, formData)
    } else {
      res = await api.put(`admin/deleteResource/${resource.id}`, formData)
    }
    if (res && res.ok) {
      toast.success('Deleted Successfully!', { id: 'global' })
      setConfirmDelete(false)
      getResources()
    }
  }

  return (
    <>
      <TableRow className="resource-table">
        <TableCell>{resource.linkText}</TableCell>
        <TableCell>{resource.description}</TableCell>
        <TableCell>{resource.category}</TableCell>
        <TableCell>
          <a className="set-link" target="_blank" href={resource.url}>
            URL
          </a>
        </TableCell>
        <TableCell sx={{ minWidth: '100px' }}>
          {dayjs(resource.date).utc().format('MM-DD-YYYY')}
        </TableCell>
        <TableCell>
          {isMarketPlaceManager && (
            <span className="d-flex">
              <Tooltip title="Edit">
                <span className="ms-3 me-3 menu-icon text-center">
                  <FaPencilAlt
                    size={15}
                    onClick={() => {
                      setEditTutorResource(true)
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span className="ms-3 me-3 menu-icon text-center">
                  <FaTrashAlt
                    size={15}
                    onClick={() => {
                      setConfirmDelete(true)
                    }}
                  />
                </span>
              </Tooltip>
            </span>
          )}
        </TableCell>
      </TableRow>
      {editTutorResource && (
        <AddEditTutorResource
          open={editTutorResource}
          mode="Edit"
          onClose={() => {
            setEditTutorResource(false)
          }}
          onSuccess={getResources}
          editData={resource}
          role={role}
        />
      )}
      {confirmDelete && (
        <ConfirmDelete
          open={confirmDelete}
          onClose={() => {
            setConfirmDelete(false)
          }}
          text={`this resource from the ${
            role !== 'PARENT' ? "tutor's" : "Parent's"
          } list`}
          onYes={deleteResource}
          noTitle
        />
      )}
    </>
  )
}

export default ResourceTableBody
