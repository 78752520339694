import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useLayoutEffect, useState } from 'react'
import { MdExpandMore, MdOutlineChevronRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import { IListItem } from '../data'
import './sidebar-list.scss'

interface SideBarListProps {
  listItem: IListItem
  matches: boolean
  smallScreen: boolean
  setSmallScreen: React.Dispatch<React.SetStateAction<boolean>>
}

const SideBarList: React.FC<SideBarListProps> = ({
  listItem,
  matches,
  smallScreen,
  setSmallScreen,
}) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const handleClose = (route: string) => {
    navigate(route)
    setSmallScreen(!smallScreen)
  }

  const handleClick = (buttonName: IListItem) => {
    buttonName.subIcons.length < 1
      ? setSmallScreen(true)
      : setSmallScreen(false)

    buttonName.route && navigate(buttonName.route)
    setOpen(!open)
  }

  useLayoutEffect(() => {
    listItem?.subIcons?.forEach((icon) => {
      if (window.location.pathname === '/pages/' + icon.route) {
        setOpen(!open)
      }
    })
  }, [])

  return (
    <div className="list-item-margin">
      <ListItemButton
        className={`bg-none ${
          window.location.pathname.split('/')[2]?.toLowerCase() ===
          listItem.pathName?.toLowerCase()
            ? 'list-button'
            : ''
        } ${matches ? 'list-sm-view' : ''}`}
        onClick={() => {
          handleClick(listItem)
        }}
      >
        <ListItemIcon className="list-icon ">
          <span className="icon-bg d-flex justify-content-center align-items-center">
            {listItem.icon}
          </span>
        </ListItemIcon>
        <ListItemText
          className="list-color list-text"
          primary={listItem.text}
        />
        {listItem?.subIcons.length > 0 ? (
          <>
            {open ? (
              <MdExpandMore size="20" className="list-icon" />
            ) : (
              <MdOutlineChevronRight size="20" className="list-icon" />
            )}
          </>
        ) : (
          <></>
        )}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={`list-text  ${matches ? 'list-sm-view' : ''}`}
      >
        {listItem?.subIcons.map((subIcon) => {
          return (
            <List
              component="div"
              disablePadding
              key={subIcon.id}
              className={`list-text  ${matches ? 'list-sm-view' : ''}`}
            >
              <ListItemButton
                className={
                  window.location.pathname === '/pages/' + subIcon.route
                    ? 'list-button bg-none'
                    : 'bg-none'
                }
                sx={{ pl: 4 }}
                onClick={() => {
                  subIcon.route && handleClose(subIcon.route)
                }}
              >
                <ListItemText
                  className="list-color ms-4"
                  primary={subIcon.text}
                />
              </ListItemButton>
            </List>
          )
        })}
      </Collapse>
    </div>
  )
}

export default SideBarList
