import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { IoMdClose } from 'react-icons/io'

import './custom-datepicker.scss'

interface DatePickerProps {
  date?: dayjs.Dayjs | null
  minDate?: dayjs.Dayjs | null
  maxDate?: dayjs.Dayjs | null
  placeholder?: string | undefined
  shouldDisableDate?: ((day: dayjs.Dayjs | undefined) => boolean) | undefined
  onChange?: (value: dayjs.Dayjs | null | undefined, mode?: string) => void
  onClear?: () => void
  mode?: string
  isDob?: boolean
  isError?: boolean
  greyBorder?: boolean
  disableClear?: boolean
  disableFutures?: boolean
  disabled?: boolean
}

const CustomDatePicker: FC<DatePickerProps> = ({
  date,
  mode,
  placeholder,
  shouldDisableDate,
  onChange,
  onClear,
  isDob,
  minDate,
  maxDate,
  isError,
  greyBorder,
  disableClear,
  disableFutures,
  disabled,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} className="d-flex">
        <DesktopDatePicker
          disableFuture={isDob || disableFutures}
          open={open}
          value={date}
          minDate={minDate ? minDate : undefined}
          maxDate={maxDate ? maxDate : undefined}
          onYearChange={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          shouldDisableDate={shouldDisableDate}
          onChange={(newValue) => {
            onChange?.(newValue, mode)
            setOpen(false)
          }}
          className={`${disabled ? 'disabled-date-picker' : ''}`}
          disabled={disabled}
          renderInput={(params) => {
            return (
              <>
                <TextField
                  {...params}
                  onClick={(e) => setOpen(true)}
                  variant="standard"
                  sx={{
                    border: `1px solid ${
                      isError ? 'red' : greyBorder ? '#dee2e6' : 'black'
                    }`,
                    background: disabled ? '#eceeef' : 'white',
                    cursor: disabled ? 'pointer' : 'not-allowed',
                    padding: '6px',
                    paddingRight: '20px',
                    '& .MuiInputBase-root': {
                      borderRadius: '3px',
                    },
                    '& .MuiInput-input': {
                      fontSize: '14px !important',
                      fontFamily: 'sans-serif',
                      padding: '0.5px',
                      cursor: 'pointer',
                    },
                    '& .MuiInput-root': {
                      '&:before, :after, :hover:not(.Mui-disabled):before': {
                        borderBottom: 0,
                        padding: 0,
                        cursor: 'not-allowed',
                      },
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: placeholder,
                    readOnly: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: true,
                    endAdornment: (
                      <>
                        {date && !disableClear && (
                          <IoMdClose
                            className="date-picker__close-button"
                            size={24}
                            onClick={(e) => {
                              onClear?.()
                              if (onClear) {
                                onChange?.(null, mode)
                              }
                              e.stopPropagation()
                            }}
                          />
                        )}
                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              </>
            )
          }}
        />
      </LocalizationProvider>
    </>
  )
}

export default CustomDatePicker
