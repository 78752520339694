import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import * as yup from 'yup'

import routes from '../../../common/routes'
import StyledDropdown from '../../../components/styled-dropdown/StyledDropdown'
import StyledReactSelect, {
  SelectObjectVal,
} from '../../../components/styled-react-select/StyledReactSelect'
import { SearchBar } from '../../../components/styled-textfield/SearchBar'
import StyledTextfield from '../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../contexts/AppContext'
import { useDebounce } from '../../../hooks'
import { MarketResponse } from '../../../types/market.type'
import { ParentData, ParentResponse } from '../../../types/parent.type'
import { TutorData, TutorResponse } from '../../../types/tutor.type'
import { api } from '../../../utils'
import './add-notification.scss'
import AddNotificationTable from './components/add-notification-table/AddNotificationTable'

const LIMIT = 10

const validationSchema = yup.object({
  scope: yup.string(),
  message: yup.string().required('Notification text is required.'),
  marketIds: yup.array(yup.object({})),
})

interface FormValues {
  scope: string
  message: string
  marketIds: SelectObjectVal[]
}

type scopeOptions = 'TUTOR' | 'PARENT'

function AddNotification() {
  const [marketData, setMarketData] = useState<SelectObjectVal[]>([])
  const { updateAppState } = useAppContext()
  const [tutorsData, setTutorsData] = useState<TutorData | null>(null)
  const [parentsData, setParentsData] = useState<ParentData | null>(null)
  const [page, setPage] = useState(1)
  const [duplicateScope, setDuplicateScope] = useState<'' | scopeOptions>('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce({ value: search, milliSeconds: 500 })
  const [usersIds, setUsersIds] = useState<{ [key: string]: boolean }>({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const formDefaults = {
    scope: '',
    message: '',
    marketIds: [],
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: formDefaults,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const getMarketData = async () => {
    const res = await api.get<MarketResponse>('admin/getMarkets')
    if (res.ok && res.data) {
      const { data } = res.data
      setMarketData(
        data.map((val) => ({
          id: val._id,
          value: val._id,
          label: val.marketName,
        })),
      )
    }
  }

  useEffect(() => {
    getMarketData()
  }, [])

  const formSubmitHandler = handleSubmit(async (values) => {
    const { scope, marketIds, message } = values
    updateAppState({ loading: true })
    const formData = new FormData()
    formData.append('message', message)
    if (scope && scope !== 'BOTH') formData.append('scope', scope)
    const keys = Object.keys(usersIds)
    if (keys.length) formData.append('userIds', JSON.stringify(keys))

    if (marketIds.length)
      formData.append(
        'marketIds',
        JSON.stringify(marketIds.map((market) => market.id)),
      )
    const res = await api.put('/admin/sendNotifications', formData)
    if (res.ok) {
      navigate('/pages/' + routes.allNotifications)
    }
    updateAppState({ loading: false })
  })

  const getParentsData = useCallback(async () => {
    const skip = (page - 1) * 10
    const queryData: { [key: string]: any } = {
      skip,
      limit: LIMIT,
    }

    if (debouncedSearch) queryData.searchParam = debouncedSearch
    setLoading(true)
    const parentsRes = await api.get<ParentResponse>(
      '/admin/getParents',
      queryData,
    )
    setLoading(false)
    if (parentsRes.ok && parentsRes.data) {
      setParentsData(parentsRes.data.data)
    }
  }, [page, debouncedSearch])

  const getTutorsData = useCallback(async () => {
    const skip = (page - 1) * LIMIT
    const queryData: { [key: string]: any } = {
      status: 'APPROVED',
      limit: LIMIT,
      skip,
    }
    if (debouncedSearch) queryData.searchParam = debouncedSearch
    setLoading(true)
    const tutorsRes = await api.get<TutorResponse>(
      '/admin/getTutors',
      queryData,
    )
    setLoading(false)
    if (tutorsRes.ok && tutorsRes.data) {
      setTutorsData(tutorsRes.data.data)
    }
  }, [debouncedSearch, page, debouncedSearch])

  useEffect(() => {
    if (duplicateScope === 'PARENT') {
      getParentsData()
    } else if (duplicateScope === 'TUTOR') {
      getTutorsData()
    }
  }, [duplicateScope, getParentsData, getTutorsData])

  const parentTotalPages = parentsData ? Math.ceil(parentsData.count / 10) : 0
  const tutorTotalPages = tutorsData ? Math.ceil(tutorsData.count / 10) : 0

  const data =
    duplicateScope === 'PARENT' ? parentsData?.parents : tutorsData?.tutors
  const totalPages =
    duplicateScope === 'PARENT' ? parentTotalPages : tutorTotalPages

  const scopeOptions = [
    { id: '', option: '---Select User---', value: '' },
    { id: 'PARENT', option: 'Parent', value: 'PARENT' },
    { id: 'TUTOR', option: 'Tutor', value: 'TUTOR' },
    { id: 'BOTH', option: 'Both', value: 'BOTH' },
  ]

  const resetPage = () => setPage(1)

  const handleChange = (index: number, checked: boolean) => {
    if (!data) return
    let newUserIds = { ...usersIds }
    if (index === -1) {
      data.forEach((user) => {
        checked
          ? (newUserIds[user._id] = true)
          : (newUserIds = _.omit(newUserIds, user._id))
      })
    } else {
      const user = data[index]
      checked
        ? (newUserIds[user._id] = true)
        : (newUserIds = _.omit(newUserIds, user._id))
    }
    setUsersIds(newUserIds)
  }

  let isAllChecked = false
  data?.forEach((user) => {
    isAllChecked = !!usersIds[user._id]
  })
  isAllChecked = isAllChecked && !loading

  const scope = watch('scope')

  return (
    <div className="add-notifications container-fluid">
      <form className="add-notifications__form" onSubmit={formSubmitHandler}>
        <h3 className="add-notifications__page-title">Create Notification</h3>
        <Row className="add-notifications__row">
          <Col sm={3}>
            <label className="add-notifications__label" htmlFor="scope">
              Select User
            </label>
          </Col>
          <Col sm={9}>
            <Controller
              name="scope"
              control={control}
              render={({ field }) => (
                <StyledDropdown
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    const value = e.target.value as 'PARENT' | 'TUTOR' | 'BOTH'
                    setDuplicateScope(value === 'BOTH' ? 'PARENT' : value)
                    resetPage()
                    setUsersIds({})
                    setSearch('')
                  }}
                  noPlaceholder
                  option={scopeOptions}
                  className="w-100"
                />
              )}
            />
          </Col>
        </Row>

        <Row className="add-notifications__row">
          <Col sm={3}>
            <label className="add-notifications__label" htmlFor="marketName">
              Market Name
            </label>
          </Col>
          <Col sm={9}>
            <Controller
              name="marketIds"
              control={control}
              render={({ field }) => (
                <StyledReactSelect
                  {...field}
                  options={marketData}
                  classNamePrefix="react-select"
                  placeholder="--Market Name--"
                />
              )}
            />
          </Col>
        </Row>

        <Row className="add-notifications__row">
          <Col sm={3}>
            <label
              className="add-notifications__label"
              htmlFor="notificationTitle"
            >
              Notification Title
            </label>
          </Col>
          <Col sm={9}>
            <StyledTextfield
              readOnly
              disabled
              value="Notification from your mySylvan Marketplace+ Team"
            />
          </Col>
        </Row>

        <Row className="add-notifications__row">
          <Col sm={3}>
            <label
              className="add-notifications__label"
              htmlFor="notificationTitle"
            >
              Notification Text
            </label>
          </Col>
          <Col sm={9}>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  multiline
                  rows={4}
                  max={500}
                  placeholder="Notification Text"
                  isError={!!errors.message}
                  errorMessage={errors.message?.message?.toString()}
                />
              )}
            />
          </Col>
        </Row>
        {scope && (
          <div className="mt-5 mb-3 d-flex">
            {scope === 'BOTH' && (
              <>
                <Button
                  {...(duplicateScope === 'PARENT'
                    ? { color: 'primary' }
                    : { variant: 'outlined' })}
                  className="px-5 me-3"
                  onClick={() => {
                    setDuplicateScope('PARENT')
                    resetPage()
                  }}
                >
                  Parent
                </Button>
                <Button
                  {...(duplicateScope === 'TUTOR'
                    ? { color: 'primary' }
                    : { variant: 'outlined' })}
                  className="px-5"
                  onClick={() => {
                    setDuplicateScope('TUTOR')
                    resetPage()
                  }}
                >
                  Tutor
                </Button>
              </>
            )}
            <SearchBar
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setUsersIds({})
              }}
              className="add-notifications__search"
            />
          </div>
        )}
        {duplicateScope && (
          <AddNotificationTable
            {...{ totalPages, data, usersIds, isAllChecked, loading }}
            currentPage={page}
            onPageChange={setPage}
            onChange={handleChange}
          />
        )}
        <div className="d-flex justify-content-end add-notifications__button">
          <Button
            type="submit"
            disabled={!isValid}
            color={!isValid ? 'info' : 'success'}
          >
            Send Notification
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AddNotification
