import { Button, MenuItem, Select } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-hot-toast'

import {
  timeArray,
  weekArray,
  weekCount,
} from '../../../../../components/calendar-events/components/data'
import CustomDatePicker from '../../../../../components/date-picker/CustomDatePicker'
import StyledTextfield from '../../../../../components/styled-textfield/StyledTextfield'
import { Parent } from '../../../../../types/parent.type'
import { PromoRes } from '../../../../../types/promo.type'
import { api } from '../../../../../utils'
import { LocationZoneType } from '../../createSession.type'
import './time-slot-component.scss'

dayjs.extend(utc)
dayjs.extend(tz)

interface TimeSlotComponentProps {
  selectedParent?: Parent
  mode?: 'parent' | 'tutor'
  slotStatusArray?: any
  weekSlotArray?: any
  startDate?: Dayjs | null | undefined
  onSlotChange?: (data: any, i: any, state: 'day' | 'week') => void
  isTutor?: boolean
  onSlotSelection?: (slot: any) => void
  promoCodeValueSetter?: (value: any) => void
  onDateClear?: () => void
  isRateChargesSuppress?: boolean
  calendarOptions?: { startDate: string; endDate: string }
  locationZone?: LocationZoneType
}

const TimeSlotComponent: FC<TimeSlotComponentProps> = ({
  selectedParent,
  mode,
  weekSlotArray,
  slotStatusArray,
  startDate,
  onSlotChange,
  isTutor,
  onSlotSelection,
  promoCodeValueSetter,
  onDateClear,
  isRateChargesSuppress,
  calendarOptions,
}) => {
  const [sessionState, setSessionState] = useState<'day' | 'week'>('day')
  const [weekDates, setWeekDates] = useState<any>()
  const [reservationEndDate, setReservationEndDate] = useState<Date>()

  const { control, setValue, watch } = useFormContext()

  const sessionDate = watch('sessionDate')
  const sessionDuration = watch('sessionDuration')
  const promoCode = watch('promoCode')
  const sessionReservation = watch('sessionReservation')

  const slotChecker = (slot: number) => {
    if (slot === 1) return 'free-color'
    if (slot === 2) return 'booked-color'
    if (slot === 0) return 'busy-color'
    return ''
  }

  useEffect(() => {
    let elmnt = document.getElementById('scrollBaba')
    if (elmnt) {
      elmnt.scrollTop = 650
    }
  }, [sessionState])

  const checkPromoCode = async () => {
    const res = await api.get<PromoRes>('admin/checkPromocode', {
      promocode: promoCode,
    })

    if (res.ok && res.data) {
      toast.success('Promocode applied successfully')
      const { data } = res.data
      promoCodeValueSetter?.(data)
    }
  }

  useEffect(() => {
    let dateCreate = []

    const bookingDate = startDate
      ? new Date(dayjs(startDate).format())
      : new Date()
    for (let i = 0; i <= 6; i++) {
      dateCreate[i] =
        ((bookingDate.getDate() + '').length == 1
          ? '0' + bookingDate.getDate()
          : bookingDate.getDate()) +
        '\n' +
        weekArray[bookingDate.getDay()]
      bookingDate.setDate(bookingDate.getDate() + 1)
    }
    setWeekDates(dateCreate)
  }, [startDate, sessionDuration])

  useEffect(() => {
    if (selectedParent?.parent.sessionCreditsFromExternalApi.length) {
      setValue(
        'sessionReservation',
        selectedParent.parent.sessionCreditsFromExternalApi[0]
          .reservationNumber,
      )
    }
  }, [])

  useEffect(() => {
    if (isRateChargesSuppress) {
      selectedParent?.parent.sessionCreditsFromExternalApi.forEach((val) => {
        if (val.reservationNumber === sessionReservation) {
          if (dayjs(calendarOptions?.endDate).isBefore(dayjs(val.endDate))) {
            setReservationEndDate(new Date(calendarOptions?.endDate as string))
          } else setReservationEndDate(val.endDate)
        }
      })
    }
  }, [sessionReservation])

  const isDisabled = !sessionDuration ? 'div-disabled' : ''

  return (
    <>
      {mode === 'parent' && (
        <>
          {isRateChargesSuppress && mode === 'parent' && (
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">
                  Available Reservations
                </label>
              </div>
              <div className="col-sm-8">
                {!selectedParent?.parent.sessionCreditsFromExternalApi
                  .length ? (
                  <div className={`modal-msgs text-start`}>0</div>
                ) : selectedParent?.parent.sessionCreditsFromExternalApi
                    .length === 1 ? (
                  <div className={`modal-msgs text-start`}>
                    {
                      selectedParent.parent.sessionCreditsFromExternalApi[0]
                        .reservationNumber
                    }
                    &nbsp;:&nbsp;
                    {
                      selectedParent.parent.sessionCreditsFromExternalApi[0]
                        .numberOfTutoringHours
                    }
                    &nbsp;
                    {selectedParent.parent.sessionCreditsFromExternalApi[0]
                      .numberOfTutoringHours > 1
                      ? 'hours'
                      : 'hour'}
                    &nbsp;expiring on&nbsp;
                    {dayjs(
                      selectedParent.parent.sessionCreditsFromExternalApi[0]
                        .endDate,
                    ).format('MM/DD/YYYY')}
                  </div>
                ) : selectedParent?.parent.sessionCreditsFromExternalApi
                    .length &&
                  selectedParent?.parent.sessionCreditsFromExternalApi.length >
                    1 ? (
                  <div>
                    <Controller
                      name="sessionReservation"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} fullWidth>
                          {selectedParent?.parent.sessionCreditsFromExternalApi.map(
                            (res) => {
                              return (
                                <MenuItem
                                  key={res._id}
                                  value={res.reservationNumber}
                                >
                                  {res.reservationNumber}
                                  &nbsp;:&nbsp;
                                  {res.numberOfTutoringHours}
                                  &nbsp;
                                  {res.numberOfTutoringHours > 1
                                    ? 'hours'
                                    : 'hour'}
                                  &nbsp;expiring on&nbsp;
                                  {dayjs(res.endDate).format('MM/DD/YYYY')}
                                </MenuItem>
                              )
                            },
                          )}
                        </Select>
                      )}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          {!(
            selectedParent?.partnershipCode &&
            selectedParent?.partnershipCode.length &&
            selectedParent?.partnershipCode.some(
              (code) => code.isRateChargeSuppress,
            )
          ) && (
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">Promo Code</label>
              </div>
              <div className={`col-sm-8 ${isDisabled}`}>
                <Controller
                  name="promoCode"
                  control={control}
                  render={({ field }) => (
                    <StyledTextfield
                      {...field}
                      placeholder="Promo Code Name"
                      onBlur={() => {
                        if (promoCode) checkPromoCode()
                      }}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className="form-group row">
        <div
          className={`text-left  ${mode === 'parent' ? 'col-sm-4' : 'col-sm-3'}
          `}
        >
          <label className="add-parent__label">Session Date</label>
        </div>
        <div
          className={`${mode === 'parent' ? 'col-sm-8' : 'col-sm-9'} ${
            !sessionDuration || !isTutor ? 'time-slot-disabled' : ''
          }`}
        >
          <Controller
            name="sessionDate"
            control={control}
            render={({ field }) => (
              <CustomDatePicker
                {...field}
                placeholder="mm-dd-yyyy"
                date={field.value}
                minDate={dayjs(calendarOptions?.startDate)}
                maxDate={
                  !isRateChargesSuppress
                    ? dayjs(calendarOptions?.endDate)
                    : dayjs(reservationEndDate)
                }
                onChange={(value) => {
                  setValue('sessionDate', value as Dayjs)
                }}
                onClear={() => {
                  setValue('sessionDate', null)
                  onDateClear?.()
                }}
              />
            )}
          />
        </div>
      </div>
      {!!selectedParent && (
        <div className="row">
          <div
            className={`${mode === 'parent' ? 'col-sm-4' : 'col-sm-3'}`}
          ></div>
          <div
            className={`${
              mode === 'parent' ? 'col-sm-8' : 'col-sm-9'
            } mb-3 text-center session-modal-color`}
          >
            Time Zone Displayed [{selectedParent.metaData.abbreviation}]
            <br />
          </div>
        </div>
      )}
      <div className="form-group row align-items-baseline">
        <div
          className={`text-left  ${mode === 'parent' ? 'col-sm-4' : 'col-sm-3'}
          `}
        >
          <label className="add-parent__label">Session Time</label>
        </div>
        <div
          className={`text-left  ${mode === 'parent' ? 'col-sm-8' : 'col-sm-9'}
          `}
        >
          <div className="d-flex">
            <Button
              className="time-slot-buttons m-1 ms-0"
              color={'primary'}
              variant={sessionState === 'day' ? 'contained' : 'text'}
              onClick={() => {
                setSessionState('day')
              }}
            >
              Day
            </Button>
            <Button
              className="time-slot-buttons m-1"
              color={'primary'}
              variant={sessionState === 'week' ? 'contained' : 'text'}
              onClick={() => {
                setSessionState('week')
              }}
            >
              Week
            </Button>
          </div>
          <div
            className={`slots-container ${
              !sessionDate ? 'time-slot-disabled' : ''
            }`}
            id="scrollBaba"
          >
            <div className="d-flex  black-border-bottom">
              <span className="time-width">Time</span>
              <span className="d-flex justify-content-center w-100">Slots</span>
            </div>

            {sessionState === 'day' ? (
              <>
                {timeArray?.map((time, index) => {
                  return (
                    <div
                      className="d-flex align-items-center black-border-bottom "
                      key={time}
                    >
                      <span className="time-width">{time}</span>
                      <span
                        onClick={() => {
                          onSlotChange?.(index, slotStatusArray[index], 'day')
                          onSlotSelection?.(slotStatusArray[index])
                        }}
                        className={`slots ms-2 ${
                          slotStatusArray &&
                          slotChecker(slotStatusArray?.[index])
                        }`}
                      ></span>
                    </div>
                  )
                })}
              </>
            ) : (
              <>
                <div className="d-flex black-border-bottom">
                  <div className="d-flex offset-3">
                    {weekDates?.map((date: any) => {
                      return (
                        <span
                          className="week-heading col-sm-1 me-2 ms-2 "
                          key={date}
                        >
                          {date}
                        </span>
                      )
                    })}
                  </div>
                </div>
                {timeArray?.map((time, index) => {
                  const i = index
                  return (
                    <div
                      className="d-flex align-items-center black-border-bottom"
                      key={i}
                    >
                      <span className="time-width">{time}</span>
                      <span className="row w-100">
                        <div className="col-sm-12 d-flex justify-content-center">
                          {weekCount?.map((index) => {
                            const j = index
                            return (
                              <span
                                onClick={() => {
                                  onSlotChange?.(j, i, 'week')
                                  onSlotSelection?.(weekSlotArray[j][i])
                                }}
                                key={j}
                                className={`slot col-sm-1 me-2 ms-2 
                                ${slotChecker(
                                  weekSlotArray?.[j]?.[i]?.element,
                                )}`}
                              ></span>
                            )
                          })}
                        </div>
                      </span>
                    </div>
                  )
                })}
              </>
            )}
          </div>

          <div className="row align">
            {!sessionDate && (
              <div className="helper-text col-sm-12">
                Please select session time.
              </div>
            )}
            <div className="col-sm-12">
              <div className="d-inline-block">
                <div className="heightWidth free-color"></div>
                Busy
              </div>
              <div className="d-inline-block">
                <div className="heightWidth busy-color"></div>
                Available
              </div>
              <div className="d-inline-block">
                <div className="heightWidth booked-color"></div>
                Selected
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TimeSlotComponent
