import { Button, Pagination, SelectChangeEvent } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'

import CustomDatePicker from '../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../contexts/AppContext'
import { useDebounce } from '../../hooks'
import { FileResponse } from '../../types/file.type'
import { api } from '../../utils'
import { downloadUrl } from '../dashboard/helpers'
import StyledDropdown from './../../components/styled-dropdown/StyledDropdown'
import './all-tutors.scss'
import useAllTutors from './components/get-all-tutors/GetAllTutors'
import TutorTable from './components/tutor-table/TutorTable'
import { acceptingNewArray, filterButtons, liveDropDownArray } from './data'

export interface ParamType {
  status?: string | null
  marketId?: string | null
  skip?: number | null
  sortBy?: string | null
  acceptingNewFamilies?: string
  isLive?: string
  startDate?: Dayjs | null
  endDate?: Dayjs | null
}
const paramsInit: ParamType = {
  status: 'ALL',
  marketId: '',
  skip: 0,
  endDate: null,
  startDate: null,
  sortBy: '',
  acceptingNewFamilies: '',
  isLive: '',
}
const AllTutors = () => {
  const { marketData, updateAppState } = useAppContext()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const oldFilter: ParamType = JSON.parse(
    localStorage.getItem('TutorFilters') as string,
  )
  const [filterStates, setFilterStates] = useState<ParamType>({
    ...paramsInit,
    status: oldFilter?.status ? oldFilter.status : paramsInit.status,
  })
  const debouncedSearch = useDebounce({ value: search, milliSeconds: 500 })

  const prevSearchRef = useRef('')

  if (prevSearchRef.current !== debouncedSearch) {
    setFilterStates((filters) => ({ ...filters, skip: 1 }))
  }
  prevSearchRef.current = debouncedSearch

  const { allTutors, getTutorDetails, totalPages } = useAllTutors({
    debouncedSearch,
    filterStates,
  })

  const onDateChange = (
    value: dayjs.Dayjs | null | undefined,
    mode?: string,
  ) => {
    setFilterStates({ ...filterStates, [mode as string]: value, skip: 1 })
  }

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    setFilterStates({ ...filterStates, skip: value })
  }

  const marketChange = (event: SelectChangeEvent) => {
    setFilterStates({ ...filterStates, marketId: event.target.value })
  }
  const handleSortBy = (value: string) => {
    setFilterStates({ ...filterStates, sortBy: value })
  }

  const downloadCsV = async () => {
    updateAppState({ loading: true })
    const res = await api.get<FileResponse>('/admin/tutorReport')
    if (res.ok && res.data) {
      const url = res.data.data.url
      downloadUrl(url, 'tutorReport.csv')
    }
    updateAppState({ loading: false })
  }

  return (
    <div className="main">
      <div className="container-fluid content">
        <div className="container-fluid">
          <div className="mt-4">
            <Button onClick={downloadCsV}>Download CSV</Button>
          </div>
          <div className="d-flex flex-wrap mt-4">
            {filterButtons.map((button) => {
              return (
                <Button
                  key={button.id}
                  variant={
                    button.type === filterStates.status
                      ? 'contained'
                      : 'outlined'
                  }
                  className="me-3 mt-3 blue-button"
                  onClick={() => {
                    setFilterStates({
                      ...filterStates,
                      status: button.type,
                      skip: 0,
                    })
                    setPage(1)
                  }}
                >
                  {button.title}
                </Button>
              )
            })}
          </div>
          <div className="row mt-2">
            <div className="col-md-3 col-lg-2 col-xl-2 mt-2">
              <CustomDatePicker
                date={filterStates.startDate}
                maxDate={filterStates.endDate}
                onChange={onDateChange}
                placeholder="Start Date"
                mode="startDate"
                onClear={() => onDateChange(null, 'startDate')}
              />
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mt-2">
              <CustomDatePicker
                date={filterStates.endDate}
                minDate={filterStates.startDate}
                onChange={onDateChange}
                placeholder="End Date"
                mode="endDate"
                onClear={() => onDateChange(null, 'endDate')}
              />
            </div>

            <div className="col-md-4 col-lg-3 mt-2 col-xl-2 filters-width">
              <MarketDropdown
                option={marketData}
                value={filterStates.marketId}
                handleChange={marketChange}
                placeholder="--Market Name--"
              />
            </div>
            <div className="col-md-2 col-lg-1 col-xl-1 mt-2 live">
              <StyledDropdown
                option={liveDropDownArray}
                value={filterStates.isLive}
                onChange={(e) => {
                  setFilterStates({ ...filterStates, isLive: e.target.value })
                }}
                placeholder="--Live--"
              />
            </div>
            <div className="col-md-4 col-lg-3 col-xl-2 mt-2 filters-width">
              <StyledDropdown
                option={acceptingNewArray}
                value={filterStates.acceptingNewFamilies}
                onChange={(e) => {
                  setFilterStates({
                    ...filterStates,
                    acceptingNewFamilies: e.target.value,
                  })
                }}
                placeholder="--Accpt. New?--"
              />
            </div>
            <div className="col-md-3 col-xl-2  mt-2">
              <SearchBar
                placeholder="Search..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setPage(1)
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <TutorTable
              tutorData={allTutors}
              buttonType={filterStates.status}
              getTutorDetails={getTutorDetails}
              handleSortBy={handleSortBy}
              sortBy={filterStates.sortBy}
            />
          </div>
          {allTutors && allTutors?.length > 0 ? (
            <>
              {totalPages > 1 && (
                <div className="mt-2 d-flex justify-content-end">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="mt-2 d-flex justify-content-start">
                <p>No Tutor Found</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AllTutors
