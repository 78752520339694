import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import PaymentCard from '../../../../../../components/payment-card/PaymentCard'
import { Booking } from '../../../../../../types/getBooking.type'
import { api } from '../../../../../../utils'
import ModalWrapper from './../../../../../../components/modal-wrapper/ModalWrapper'

interface ChangeCardModalProps {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
  booking: Booking
}
const ChangeCardModal: FC<ChangeCardModalProps> = ({
  open,
  onClose,
  onSuccess,
  booking,
}) => {
  const { cards } = booking.parentId || {}
  const [selectedCard, setSelectedCard] = useState('')
  const [defaultCard, setDefaultCard] = useState('')

  useEffect(() => {
    if (booking?.payments?.cardId) {
      cards?.forEach((card) => {
        if (card._id === booking.payments.cardId) {
          setSelectedCard(card._id)
          setDefaultCard(card._id)
        }
      })
    } else {
      cards?.forEach((card) => {
        if (card.isDefault) {
          setSelectedCard(card._id)
          setDefaultCard(card._id)
        }
      })
    }
  }, [])

  const onCardSelection = (id: string) => {
    setSelectedCard(id)
  }

  const changePaymentMethod = async () => {
    const formData = new FormData()
    formData.append('cardId', selectedCard)
    const res = await api.put(
      `admin/changePaymentMethod/${booking._id}`,
      formData,
    )
    if (res.ok) {
    }
  }

  return (
    <ModalWrapper
      wide
      open={open}
      handleClose={onClose}
      title="Change Payment Method"
    >
      <div className="row">
        {cards?.map((card) => (
          <div className="col-md-6">
            <span key={card._id}>
              <PaymentCard
                key={card._id}
                card={card}
                mode="changePayment"
                onCardSelection={onCardSelection}
                selectedCard={selectedCard}
              />
            </span>
          </div>
        ))}
      </div>
      <div className="col-md-10 col-sm-10 text-end">
        <Button
          disabled={selectedCard === defaultCard}
          color={selectedCard === defaultCard ? 'info' : 'success'}
          onClick={() => {
            changePaymentMethod()
            onSuccess?.()
          }}
        >
          Set New Payment Method
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default ChangeCardModal
