import { Button } from '@mui/material'
import { FC, useState } from 'react'

import CustomCardWrapper from '../../../../../../components/custom-card-wrapper/CustomCardWrapper'
import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../../../contexts/AppContext'
import { api } from '../../../../../../utils'
import './update-term-and-conditions.scss'

interface UpdateTermAndConditionsI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  privacyPolicy: string
  termAndCondition: string
}

const UpdateTermAndConditions: FC<UpdateTermAndConditionsI> = ({
  onClose,
  onSuccess,
  open,
  privacyPolicy,
  termAndCondition,
}) => {
  const { updateAppState } = useAppContext()
  const [updatedPrivacy, setUpdatedPrivacy] = useState<File>()
  const [updatedTerms, setUpdatedTerms] = useState<File>()
  const privacy = privacyPolicy.split('/user/')[1]
  const term = termAndCondition.split('/user/')[1]

  const updateTerm = async () => {
    if (!updatedTerms && !updatedPrivacy) {
      onClose()
    } else {
      updateAppState({ loading: true })
      const formData = new FormData()
      if (updatedTerms) formData.append('termsAndConditions', updatedTerms)
      if (updatedPrivacy) formData.append('privacyPolicy', updatedPrivacy)
      const res = await api.put('admin/updateConstants', formData)
      if (res.ok) {
        onClose()
        onSuccess()
        updateAppState({ loading: false })
      }
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="Update Privacy Policy"
      wide
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div className={`form-group row align-items-center`}>
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Privacy Policy</label>
            </div>

            <div className="col-sm-8">
              <div className="file-border p-1 ps-2">
                <input
                  className="w-100"
                  type="file"
                  onChange={(e) => {
                    setUpdatedPrivacy(e.target.files?.[0])
                  }}
                />
              </div>
            </div>
            <div className="col-sm-8 offset-sm-4">
              {!updatedPrivacy && (
                <CustomCardWrapper className="margin-terms">
                  <div className="text-end mt-3 blueText">{privacy}</div>
                </CustomCardWrapper>
              )}
            </div>
          </div>
          <div className={`form-group row align-items-center`}>
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Term and Condition</label>
            </div>

            <div className="col-sm-8">
              <div className="file-border p-1 ps-2 ">
                <input
                  className="w-100"
                  type="file"
                  onChange={(e) => {
                    setUpdatedTerms(e.target.files?.[0])
                  }}
                />
              </div>
            </div>
            <div className="col-sm-8 offset-sm-4">
              {!updatedTerms && (
                <CustomCardWrapper className="margin-terms">
                  <div className="text-end mt-3 blueText">{term}</div>
                </CustomCardWrapper>
              )}
            </div>
          </div>
          <div className="form-group row pe-0">
            <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-0">
              <Button color="success" className="w-100" onClick={updateTerm}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default UpdateTermAndConditions
