import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import { useEffect } from 'react'

import { useAppContext } from '../../../../../contexts/AppContext'
import { Booking, bookingStates } from '../../../../../types/getBooking.type'
import { State } from '../BookingDetailModal'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(isSameOrBefore)

interface Props {
  state: State
  booking: Booking
  bookingState?: bookingStates
  updateState: (state: Partial<State>) => void
  setShownAddPromoButton: React.Dispatch<React.SetStateAction<boolean>>
}

export const useAlteredBookingDetail = ({
  state,
  booking,
  bookingState,
  updateState,
  setShownAddPromoButton,
}: Props) => {
  const { constants } = useAppContext()

  useEffect(() => {
    const isMarketplaceManager =
      localStorage.getItem('isMarketplaceManager') || 'false'
    const monthArray = dayjs.months()
    updateState({ isMarketplaceManager, monthArray })
  }, [])

  useEffect(() => {
    let newState = _.cloneDeep(state)

    if (newState.onBehalf === 'Parent') {
      if (!newState.cards) {
        newState.paymentGo = true
      }
      newState.declinedReasons = constants?.parentDeclineReasons
    } else {
      newState.declinedReasons = constants?.tutorDeclineReasons
      newState.paymentGo = false
    }

    // FIXME: maybe need in future
    // if (res.CardsData) {
    //   if (res.CardsData.data.data.cards) {
    //     this.userCards = [];
    //     this.userCards = res.CardsData.data.data.cards;
    //   }
    // }
    // if (res && res.bookingUpdatedData) {
    //   this.allData.payments = res.bookingUpdatedData.payments;
    // }

    newState.allData = ''
    newState.newData = booking
    newState = showPromoCode(newState)
    if (booking.sessionRequestedBy === 'TUTOR') {
      newState.onBehalf = 'Parent'
      if (booking.parentId?.cards) newState.card = []
      booking.parentId?.cards?.forEach((element) => {
        if (newState.cards) {
        } else if (element.isDefault) {
          newState.paymentGo = false
          newState.cards = element._id
        }
        let d1 = new Date(
          parseInt(element.expYear),
          parseInt(element.expMonth) - 1,
        )
        newState.card?.push({
          type: element.brand,
          id: element._id,
          last4Digits: element.last4Digits,
          exdate: d1,
        })
      })
    } else {
      newState.onBehalf = 'Tutor'
    }
    if (bookingState?.pageType === 'noShowTutor') {
      newState.allDataCancelPolicy = booking
      // @ts-ignore
      newState.fd = booking.fd!
    }
    if (bookingState?.pageType === 'ReschudeBooking') {
      newState.allDataCancelPolicy = booking
      if (booking.tutorRatings?.rating) {
        newState.rating = booking.tutorRatings.rating
      }
    }

    if (bookingState?.pageType === 'ReschudeBookingflow') {
      newState.allData = booking
      newState = IsAddPromo(newState)
    } else {
      newState.allData = booking
      if (booking.tutorRatings?.rating) {
        newState.rating = booking.tutorRatings.rating
      }
      if (booking.payments?.amountCharged) {
        newState.totalAmount += booking.payments.amountCharged
      }
      if (booking.payments?.partialAmountLeft) {
        newState.totalAmount += booking.payments.partialAmountLeft
      }
      if (booking.payments?.partialAmountCharged) {
        newState.totalAmount += booking.payments.partialAmountCharged
      }
      if (newState.allData?.status) {
        newState.allData.status1 = newState.allData.status
          .replace(/[_-]/g, ' ')
          .toLowerCase()
      }
      if (newState.allData?.noResponseByTutor) {
        newState.allData.status1 = 'no response by tutor'
      }
      if (newState.allData?.noResponseByParent) {
        newState.allData.status1 = 'no response by parent'
      }
      if (newState.allData?.payments?.paymentStatus) {
        newState.allData.payments.paymentStatus =
          newState.allData.payments.paymentStatus
            .replace(/[_-]/g, ' ')
            .toLowerCase()
      }
      if (newState.allData?.status) {
        newState.allData.status1 = newState.allData.status
          .replace(/[_-]/g, ' ')
          .toLowerCase()
      }
      if (newState.allData.noResponseByTutor) {
        newState.allData.status1 = 'no response by tutor'
      }
      if (newState.allData.noResponseByParent) {
        newState.allData.status1 = 'no response by parent'
      }
      if (newState.allData?.payments?.adminFee?.discount) {
        newState.adminDiscount =
          newState.allData.payments.adminFee.type == 'FLAT'
            ? newState.allData.payments.adminFee.discount *
              newState.allData.payments.duration
            : newState.allData.payments.adminFee.discount
      }
    }

    if (booking.parentId?.cards) {
      newState.userCards = _.cloneDeep(booking.parentId.cards)
      newState.allCards = _.cloneDeep(booking.parentId.cards)
      for (let i = 0; i < newState.userCards.length; i++) {
        if (newState.userCards[i].isDefault) {
          newState.defaultCardId = newState.userCards[i]
        }
      }
    }

    if (bookingState?.pageType === 'accept') {
      checkIsSuppress()
      checkBhExpiring()
    }

    function checkIsSuppress() {
      newState.isRateChargeSuppress =
        !!newState.allData?.partnershipCode?.partnercodeId.isRateChargeSuppress
    }

    function checkBhExpiring() {
      if (newState.isRateChargeSuppress && newState.onBehalf === 'Parent') {
        const parentData = booking.parentId?.parent
        const locationDetails = booking.locationDetails
        if (parentData?.sessionCreditsFromExternalApi?.length) {
          let filteredReservations =
            parentData.sessionCreditsFromExternalApi.filter(
              (val) =>
                dayjs(booking.startTime)
                  .startOf('day')
                  .isBefore(dayjs(val.endDate).endOf('day')) &&
                val.numberOfTutoringHours > 0,
            )
          let currentDate = new Date().toISOString()
          filteredReservations = filteredReservations.filter(
            (val) => val.endDate > currentDate,
          )

          newState.allData.parentId.parent.sessionCreditsFromExternalApi =
            filteredReservations
          if (newState.allData.parentId.parent.sessionCreditsFromExternalApi) {
            newState.filteredReservations =
              newState.allData.parentId.parent.sessionCreditsFromExternalApi.filter(
                (r: any) => !r.isCanceled,
              )
          }

          if (filteredReservations.length) {
            filteredReservations.forEach((val) => {
              val.myDate = new Date(val.endDate)
              val.showEndDate = dayjs(val.endDate)
                .tz(
                  locationDetails?.parentTimezoneOffsetZone
                    ? locationDetails.parentTimezoneOffsetZone
                    : 'America/New_York',
                )
                .format('YYYY-MM-DDTHH:mm:ss')
              newState.reservationAvailableHours =
                newState.reservationAvailableHours + val.numberOfTutoringHours
            })

            filteredReservations = _.cloneDeep(
              filteredReservations.sort(
                (a, b) =>
                  new Date(a.endDate).getTime() - new Date(b.endDate).getTime(),
              ),
            )

            newState.allData.parentId.parent.sessionCreditsFromExternalApi =
              filteredReservations
            if (
              newState.allData.parentId.parent.sessionCreditsFromExternalApi
            ) {
              newState.filteredReservations =
                newState.allData.parentId.parent.sessionCreditsFromExternalApi.filter(
                  (r: any) => !r.isCanceled,
                )
            }
            newState.reservationNumber =
              filteredReservations[0].reservationNumber
          }
        }
      }
    }

    if (bookingState?.pageType === 'accept') {
      newState.isRateChargeSuppress =
        !!booking.partnershipCode?.partnercodeId.isRateChargeSuppress
    }

    updateState(newState)
  }, [bookingState, booking, constants])

  function IsAddPromo(newState: State) {
    const currentDate = dayjs(
      changeTimeZone(new Date(), newState.allData.parentTimezoneOffsetZone),
    )
    const startTime = dayjs(new Date(newState.allData.startTime1))

    if (
      [
        'Session Started',
        'Completed',
        'Finished',
        'Rejected',
        'Canceled By Admin',
        'Canceled By Parent',
        'Canceled By Tutor',
        'Rejected By Parent',
        'Rejected By Tutor',
      ].some((status) => status === newState.allData.status) ||
      (newState.allData.oldStatus == 'NEW_TUTOR' &&
        newState.allData.sessionRequestedBy == 'TUTOR') ||
      (newState.allData.payments &&
        newState.allData.payments.promocode &&
        newState.allData.payments.promocode.length > 0) ||
      !currentDate.isSameOrBefore(startTime)
    ) {
      newState.shownAddPromoButton = false
    } else {
      newState.shownAddPromoButton = true
    }

    if (newState.allData?.payments?.paymentStatus)
      newState.allData.payments.paymentStatus =
        newState.allData.payments.paymentStatus
          .replace(/[_-]/g, ' ')
          .toLowerCase()

    return newState
  }

  const showPromoCode = (newState: State) => {
    const currentDate = dayjs(
      changeTimeZone(new Date(), newState.newData.parentTimezoneOffsetZone),
    )
    const startTime1 = dayjs(newState.newData.startTime)
      .tz(newState.newData.parentTimezoneOffsetZone)
      .format('LLL')
    const startTime = dayjs(new Date(startTime1))

    if (
      [
        'Session Started',
        'Completed',
        'Finished',
        'Rejected',
        'Canceled By Admin',
        'Canceled By Parent',
        'Canceled By Tutor',
        'Rejected By Parent',
        'Rejected By Tutor',
      ].some((status) => newState.newData.status === status) ||
      (newState.newData.oldStatus == 'NEW_TUTOR' &&
        newState.newData.sessionRequestedBy == 'TUTOR') ||
      (newState.newData.payments &&
        newState.newData.payments.promocode &&
        newState.newData.payments.promocode.length > 0) ||
      !(currentDate.isSame(startTime) || currentDate.isBefore(startTime))
    ) {
      setShownAddPromoButton(false)
    } else {
      setShownAddPromoButton(true)
    }
    if (
      newState.newData &&
      newState.newData.payments &&
      newState.newData.payments.paymentStatus
    )
      newState.newData.payments.paymentStatus =
        newState.newData.payments.paymentStatus
          .replace(/[_-]/g, ' ')
          .toLowerCase()

    return newState
  }

  function changeTimeZone(date: any, timeZone: any) {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      )
    }

    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    )
  }
}
