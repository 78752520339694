import Button from '@mui/material/Button/Button'
import React, { FC, useCallback, useEffect, useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import './adjust-image.scss'
import getCroppedImg from './getCroppedImg'

interface AdjustImageProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<File | null>>
  setSelectImageUrl: React.Dispatch<React.SetStateAction<string>>
  setImageBase64: React.Dispatch<React.SetStateAction<string>>
  selectedImage: File
}
const AdjustImage: FC<AdjustImageProps> = ({
  open,
  setOpen,
  selectedImage,
  setSelectImageUrl,
  setImageBase64,
}) => {
  const [imagePreview, setImagePreview] = useState<
    string | ArrayBuffer | null
  >()
  useEffect(() => {
    const image = selectedImage
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(image)
  }, [])

  const handleClose = () => {
    setOpen(null)
  }
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<number>(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    [],
  )

  const showCroppedImage = useCallback(async () => {
    try {
      if (!croppedAreaPixels) return
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(selectedImage),
        croppedAreaPixels,
        0,
      )
      if (croppedImage) {
        setSelectImageUrl(croppedImage.url)
        setImageBase64(croppedImage.base64)
      }
    } catch (e) {
      console.error(e)
    }
    handleClose()
  }, [croppedAreaPixels, selectedImage])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  return (
    <ModalWrapper open={open} handleClose={handleClose} title="Adjust Image">
      <div className="adjust-modal-height">
        <div className="crop-container">
          <Cropper
            image={imagePreview as string}
            crop={crop}
            zoom={zoom}
            aspect={3 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
          />
        </div>
        <div className="controls text-center ">
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(+e.target.value)
            }}
            className="zoom-range"
          />
        </div>
        <div className="controls text-center ">
          <Button color="success" onClick={showCroppedImage}>
            Save
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AdjustImage
