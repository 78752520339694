import { Button } from '@mui/material'
import { FC } from 'react'

import { PendingProfileTableModals } from '../../../pages/tutor/components/pending-profile-update/components/PendingProfileTable/PendingProfileTableBody'
import { api } from '../../../utils'
import ModalWrapper from '../../modal-wrapper/ModalWrapper'
import '../unblock-modal/modal-styling.scss'

interface VerifyChangesProps {
  open: PendingProfileTableModals
  setOpen: React.Dispatch<React.SetStateAction<PendingProfileTableModals>>
  modalName: string
  requestId?: string
  getPendingProfiles: () => Promise<void>
}
const VerifyChanges: FC<VerifyChangesProps> = ({
  open,
  setOpen,
  modalName,
  requestId,
  getPendingProfiles,
}) => {
  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }
  const changesAction = async () => {
    const res = await api.put('admin/verifyChangeRequest/' + requestId, {
      action: modalName === 'accept' ? 'ACCEPT' : 'REJECT',
    })
    if (res.ok) {
      getPendingProfiles()
      handleClose()
    }
  }
  return (
    <ModalWrapper
      open={open.accept || open.reject}
      handleClose={handleClose}
      title={
        modalName === 'accept'
          ? 'Approve Profile Updates?'
          : 'Reject Profile Updates?'
      }
    >
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p>
          {modalName === 'accept'
            ? 'Are you sure you want to approve this tutors profile changes?'
            : 'Are you sure you want to reject this tutors profile changes?'}
        </p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={changesAction}
          >
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default VerifyChanges
