import { FC } from 'react'

import PaymentCard from '../../../../../components/payment-card/PaymentCard'
import { Parent } from '../../../../../types/parent.type'

interface CardSectionI {
  isRateChargesSuppress: boolean
  selectedParent: Parent | null
  selectedCard: string
  onCardSelection: (id: string) => void
}

const CardSection: FC<CardSectionI> = ({
  isRateChargesSuppress,
  selectedParent,
  selectedCard,
  onCardSelection,
}) => {
  return (
    <>
      {!isRateChargesSuppress && (
        <div className="form-group row align-items-baseline">
          <div className="col-sm-3 text-left">
            <label className="add-parent__label">Select Card</label>
          </div>
          <div className="col-sm-9">
            {selectedParent?.cards.length ? (
              <>
                {selectedParent?.cards?.map((card) => (
                  <PaymentCard
                    card={card}
                    greyText
                    key={card._id}
                    className="mt-2 session-modal-color"
                    mode="createSession"
                    selectedCard={selectedCard}
                    onCardSelection={(id) => {
                      onCardSelection(id)
                    }}
                  />
                ))}
              </>
            ) : (
              <span className="helper-text">Card is Missing</span>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default CardSection
