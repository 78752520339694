import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { api } from '../../../../../../utils'
import { AllSubjectsRes, subject } from '../../all-subjects.types'
import SubjectComponentBody from './SubjectComponentBody'
import './subject-component.scss'

export interface ForwardRefProps {
  getAllSubjects: () => Promise<void>
}

const SubjectComponent = forwardRef<ForwardRefProps>((props, ref) => {
  const [allSubjects, setAllSubjects] = useState<subject[]>([])

  const getAllSubjects = async () => {
    const res = await api.get<AllSubjectsRes>('admin/getSubjects')
    if (res.ok && res.data) {
      const { data } = res.data
      setAllSubjects(data)
    }
  }

  useImperativeHandle(ref, () => ({ getAllSubjects }))

  useEffect(() => {
    getAllSubjects()
  }, [])

  const [subjectName, setSubjectName] = useState('')
  return (
    <div className="col-sm-12 col-md-10 offset-md-1">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              className="heading-table"
              sx={{ '&:hover': { background: 'none' } }}
            >
              <TableCell className="subject-name">Subject Name</TableCell>
              <TableCell className="subject-action">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allSubjects.map((subject) => {
              return (
                <SubjectComponentBody
                  subject={subject}
                  subjectName={subjectName}
                  setSubjectName={(name) => {
                    setSubjectName(name)
                  }}
                  getAllSubjects={getAllSubjects}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
})

export default SubjectComponent
