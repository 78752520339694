import { ENV } from './env'

const env = ENV.VITE_ENV || 'local'

const apiUrl = ENV.VITE_API_URL || 'http://localhost:3000'

const apiUrlMap = {
  test: 'https://test.api.mysylvanmarketplaceplus.com',
  dev: 'https://dev.api.mysylvanmarketplaceplus.com',
  qual: 'https://qual.api.mysylvanmarketplaceplus.com',
  demo: 'https://demo.api.mysylvanmarketplaceplus.com',
  live: 'https://api.mysylvanmarketplaceplus.com',
}

export const API_URL = env !== 'local' ? apiUrlMap[env] : apiUrl

const stripeKeyMap = {
  test: 'pk_test_5tu0lAMI2EyovdwAk95ordCK',
  dev: 'pk_test_5tu0lAMI2EyovdwAk95ordCK',
  qual: 'pk_test_5tu0lAMI2EyovdwAk95ordCK',
  demo: 'pk_test_5tu0lAMI2EyovdwAk95ordCK',
  live: 'pk_live_EqNLKgeJct2hLAD9Sy106Z4P',
}

export const STRIPE_PUBLISH_KEY =
  env !== 'local' ? stripeKeyMap[env] : stripeKeyMap.dev
