import { FC, useEffect, useState } from 'react'

import { TutorElement } from '../../types/changeRequest.type'
import { Subject, SubjectResponse } from '../../types/subject.type'
import { Tutor } from '../../types/tutor.type'
import { api } from '../../utils'
import { CONSTANT } from '../../utils/constants'
import { StyledRow2 } from '../custom-bordered-row/StyledRow'

interface EducationProps {
  singleTutor?: Tutor | null | undefined
  pendingTutor?: TutorElement
  path?: string
}

const Education: FC<EducationProps> = ({ singleTutor, pendingTutor, path }) => {
  const [subjects, setSubjects] = useState<Subject[]>()

  const getSubjects = async () => {
    const res = await api.get<SubjectResponse>('booking/getTutorSubjects', {
      tutorId: pendingTutor?.userId._id,
    })
    if (res && res.data) {
      const { data } = res.data
      setSubjects(data)
    }
  }

  useEffect(() => {
    if (pendingTutor) getSubjects()
  }, [])

  const stateName = (stateName?: string) => {
    return CONSTANT.states.map((state) => {
      if (state[1] === stateName) return state[0]
    })
  }

  return (
    <div>
      <div className="heading-container row-margin">
        Educational and Professional Background
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Professional Experience"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.experience
                    ? singleTutor?.tutor.experience
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.experience
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor && pendingTutor?.tutor?.experience
                    ? pendingTutor?.tutor?.experience
                    : pendingTutor?.userId.tutor.experience
                    ? pendingTutor.userId.tutor.experience
                    : '-'}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="Resume"
          description={
            <span className="resume">
              {path !== 'pendingProfile' ? (
                <a href={singleTutor?.tutor?.resume?.url} target="_blank">
                  {singleTutor?.tutor?.resume && singleTutor?.tutor.resume?.name
                    ? singleTutor.tutor?.resume?.name
                    : ''}
                </a>
              ) : (
                <span className="resume">
                  {pendingTutor && pendingTutor?.tutor?.resume ? (
                    <a
                      href={pendingTutor?.tutor?.resume?.url}
                      target="_blank"
                      className="colorChange"
                    >
                      {pendingTutor?.tutor?.resume &&
                      pendingTutor?.tutor?.resume?.name
                        ? pendingTutor.tutor?.resume?.name
                        : ''}
                    </a>
                  ) : (
                    <a
                      href={pendingTutor?.userId.tutor?.resume?.url}
                      target="_blank"
                      className="check"
                    >
                      {pendingTutor?.userId?.tutor?.resume &&
                      pendingTutor?.userId?.tutor?.resume?.name
                        ? pendingTutor?.userId.tutor?.resume?.name
                        : ''}
                    </a>
                  )}
                </span>
              )}
            </span>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="LinkedIn Profile"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor?.linkedInProfile
                    ? singleTutor?.tutor?.linkedInProfile
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.linkedInProfile
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor && pendingTutor.tutor?.linkedInProfile
                    ? pendingTutor?.tutor?.linkedInProfile
                    : pendingTutor?.userId.tutor?.linkedInProfile
                    ? pendingTutor?.userId.tutor.linkedInProfile
                    : '-'}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="State Certified"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.isCertified &&
                  singleTutor?.tutor.isCertified == true ? (
                    <>
                      yes -&nbsp;
                      {
                        <a
                          href={singleTutor?.tutor?.certificate?.url}
                          target="_blank"
                          className="pink-color"
                        >
                          {singleTutor?.tutor?.certificate &&
                          singleTutor?.tutor.certificate?.name
                            ? singleTutor.tutor?.certificate?.name
                            : ''}
                        </a>
                      }
                    </>
                  ) : singleTutor?.tutor.isCertified == false ? (
                    'no'
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.isCertified
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor?.userId?.tutor?.isCertified &&
                  pendingTutor?.userId?.tutor?.isCertified == true ? (
                    <>
                      yes -&nbsp;
                      {
                        <a
                          href={pendingTutor?.userId?.tutor?.certificate?.url}
                          target="_blank"
                          className="pink-color"
                        >
                          {pendingTutor?.userId?.tutor?.certificate &&
                          pendingTutor?.userId?.tutor.certificate?.name
                            ? pendingTutor?.userId?.tutor?.certificate?.name
                            : ''}
                        </a>
                      }
                    </>
                  ) : pendingTutor?.userId?.tutor?.isCertified == false ? (
                    <span className="colorChange">no</span>
                  ) : (
                    '-'
                  )}
                </span>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Grades Tutored"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.gradeLevel?.map((grade, index) => {
                    return (
                      <span key={index}>
                        {index === 0 ? '' : ', '}
                        {grade}
                      </span>
                    )
                  })}
                </>
              ) : (
                <>
                  {pendingTutor && pendingTutor.tutor?.gradeLevel ? (
                    <>
                      {pendingTutor?.tutor.gradeLevel?.map((grade, index) => {
                        return (
                          <span key={index} className="colorChange">
                            {index === 0 ? '' : ', '}
                            {grade}
                          </span>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {pendingTutor?.userId.tutor?.gradeLevel?.map(
                        (grade, index) => {
                          return (
                            <span key={index}>
                              {index === 0 ? '' : ', '}
                              {grade}
                            </span>
                          )
                        },
                      )}
                    </>
                  )}
                </>
              )}
            </>
          }
        />
        <StyledRow2
          field="Subject"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {!!singleTutor?.tutor.subjects.length ? (
                    <>
                      {singleTutor.tutor.subjects.map((subject, index) => (
                        <p key={index}>
                          {subject.subcategories?.map((subCat, index) => (
                            <span key={index}>
                              {index === 0 ? '' : ', '}
                              {subCat.name}
                            </span>
                          ))}
                        </p>
                      ))}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              ) : (
                <>
                  {pendingTutor && pendingTutor.tutor?.subjects ? (
                    <>
                      {!!pendingTutor.tutor?.subjects?.length ? (
                        <>
                          {pendingTutor.tutor?.subjects?.map(
                            (subject, index) => (
                              <p key={index}>
                                {subject.subcategories?.map((subCat, index) => (
                                  <span key={index} className="colorChange">
                                    {index === 0 ? '' : ', '}
                                    {subCat.name}
                                  </span>
                                ))}
                              </p>
                            ),
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    <>
                      {!!pendingTutor?.userId.tutor.subjects.length ? (
                        <>
                          {subjects?.map((subject, index) => (
                            <p key={index}>
                              {subject.subcategories?.map((subCat, index) => (
                                <span key={index}>
                                  {index === 0 ? '' : ', '}
                                  {subCat.name}
                                </span>
                              ))}
                            </p>
                          ))}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  )}
                </>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <div className="col-sm-12 font-size-20">
          <b>Educational Degree</b>
        </div>
      </div>
      {path !== 'pendingProfile' ? (
        <>
          {singleTutor?.tutor?.educationalDegrees?.map((data) => {
            return (
              <div
                className="row row-margin view-detail-font backgroundColor"
                key={data._id}
              >
                <StyledRow2
                  field="School/University Name"
                  description={
                    data.intstitutionName ? data.intstitutionName : '-'
                  }
                />
                <StyledRow2
                  field="Degree Name"
                  description={data.degree ? data.degree : '-'}
                />
                <StyledRow2
                  field="Subject/Major"
                  description={data.subject ? data.subject : '-'}
                />
                <StyledRow2
                  field="Year"
                  description={data.year ? data.year : '-'}
                />
                <StyledRow2
                  field="Country"
                  description={data.country ? data.country : '-'}
                />
                <StyledRow2
                  field="State"
                  description={data.state ? data.state : '-'}
                />
                <StyledRow2
                  field="City"
                  description={data.city ? data.city : '-'}
                />
              </div>
            )
          })}
        </>
      ) : (
        <>
          {pendingTutor?.tutor?.educationalDegrees ? (
            <>
              {pendingTutor?.tutor?.educationalDegrees?.map((data) => {
                return (
                  <div
                    className="row row-margin view-detail-font backgroundColor"
                    key={data._id}
                  >
                    <StyledRow2
                      field="School/University Name"
                      description={
                        <span className="colorChange">
                          {data.intstitutionName ? data.intstitutionName : '-'}
                        </span>
                      }
                    />
                    <StyledRow2
                      field="Degree Name"
                      description={
                        <span className="colorChange">
                          {data.degree ? data.degree : '-'}
                        </span>
                      }
                    />
                    <StyledRow2
                      field="Subject/Major"
                      description={
                        <span className="colorChange">
                          {data.subject ? data.subject : '-'}
                        </span>
                      }
                    />
                    <StyledRow2
                      field="Year"
                      description={
                        <span className="colorChange">
                          {data.year ? data.year : '-'}
                        </span>
                      }
                    />
                    <StyledRow2
                      field="Country"
                      description={
                        <span className="colorChange">
                          {data.country ? data.country : '-'}
                        </span>
                      }
                    />
                    <StyledRow2
                      field="State"
                      description={
                        <span className="colorChange">
                          {data.state ? stateName(data.state) : '-'}
                        </span>
                      }
                    />
                    <StyledRow2
                      field="City"
                      description={
                        <span className="colorChange">
                          {data.city ? data.city : '-'}
                        </span>
                      }
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <>
              {' '}
              {pendingTutor?.userId.tutor?.educationalDegrees?.map((data) => {
                return (
                  <div
                    className="row row-margin view-detail-font backgroundColor"
                    key={data._id}
                  >
                    <StyledRow2
                      field="School/University Name"
                      description={
                        data.intstitutionName ? data.intstitutionName : '-'
                      }
                    />
                    <StyledRow2
                      field="Degree Name"
                      description={data.degree ? data.degree : '-'}
                    />
                    <StyledRow2
                      field="Subject/Major"
                      description={data.subject ? data.subject : '-'}
                    />
                    <StyledRow2
                      field="Year"
                      description={data.year ? data.year : '-'}
                    />
                    <StyledRow2
                      field="Country"
                      description={data.country ? data.country : '-'}
                    />
                    <StyledRow2
                      field="State"
                      description={data.state ? stateName(data.state) : '-'}
                    />
                    <StyledRow2
                      field="City"
                      description={data.city ? data.city : '-'}
                    />
                  </div>
                )
              })}
            </>
          )}
        </>
      )}
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Certificate"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <a href={singleTutor?.tutor.certificate?.url} target="_blank">
                  {singleTutor?.tutor.certificate &&
                  singleTutor?.tutor.certificate?.name
                    ? singleTutor.tutor.certificate?.name
                    : '-'}
                </a>
              ) : (
                <>
                  {pendingTutor && pendingTutor?.tutor?.certificate ? (
                    <a
                      href={pendingTutor?.tutor?.certificate?.url}
                      target="_blank"
                      className="colorChange"
                    >
                      {pendingTutor?.tutor?.certificate &&
                      pendingTutor?.tutor?.certificate?.name
                        ? pendingTutor.tutor?.certificate?.name
                        : ''}
                    </a>
                  ) : (
                    <a
                      href={pendingTutor?.userId.tutor?.certificate?.url}
                      target="_blank"
                    >
                      {pendingTutor?.userId?.tutor?.certificate &&
                      pendingTutor?.userId?.tutor?.certificate?.name
                        ? pendingTutor?.userId.tutor?.certificate?.name
                        : ''}
                    </a>
                  )}
                </>
              )}
            </>
          }
        />
        <div className="col-sm-6 col-lg-6 col-md-6 col-xs-12"></div>
      </div>
    </div>
  )
}

export default Education
