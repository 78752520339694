import { useAppContext } from '@/contexts/AppContext'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'

import DownloadItem from './components/upload-item/DownloadItem'
import './downloader.scss'

const Downloader = () => {
  const {
    appState: { downloads },
    updateAppState,
  } = useAppContext()
  const [showDetail, setShowDetail] = useState(true)

  const toggleShow = () => {
    setShowDetail((prev) => !prev)
  }

  const allDownloaded =
    downloads.length && downloads.every((d) => d.status !== 'loading')

  return (
    <div
      className="download-status"
      style={downloads.length ? { display: 'block' } : { display: 'none' }}
    >
      <div className="head__download">
        <p>
          Downloading {downloads.length}{' '}
          {downloads.length === 1 ? 'file' : 'files'}
        </p>
        <div className="hide__download">
          <IconButton onClick={toggleShow}>
            {showDetail ? (
              <FiChevronDown size={24} color="white" />
            ) : (
              <FiChevronUp size={24} color="white" />
            )}
          </IconButton>
          {allDownloaded && (
            <IconButton onClick={() => updateAppState({ downloads: [] })}>
              <IoClose size={24} color="white" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="content__download">
        {showDetail
          ? downloads.map((d) => <DownloadItem key={d.name} {...d} />)
          : undefined}
      </div>
    </div>
  )
}

export default Downloader
