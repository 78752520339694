import { useEffect, useState } from 'react'

import { useAppContext } from '../../../../contexts/AppContext'
import { Parent, ParentResponse } from '../../../../types/parent.type'
import { api } from '../../../../utils'
import { ParamType } from '../../AllParents'

interface GetAllParentProps {
  filterStates: ParamType | null
  debouncedSearch?: string
  isSearchForBooking?: boolean
  dropDown?: boolean
}
const useAllParents = ({
  filterStates,
  debouncedSearch,
  isSearchForBooking,
  dropDown,
}: GetAllParentProps) => {
  const { updateAppState } = useAppContext()

  const [allParents, setAllParents] = useState<Parent[] | null>()
  const [totalPages, setTotalPages] = useState(0)
  let queryObj: any
  const getParentDetails = async () => {
    if (dropDown && !debouncedSearch) {
      setAllParents(null)
      return
    }
    updateAppState({ loading: true })
    queryObj = {
      type: filterStates?.type ? filterStates.type : null,
      startDate: filterStates?.startDate
        ? filterStates.startDate.format().split('T')[0] + 'T00:00:00.000Z'
        : null,
      endDate: filterStates?.endDate
        ? filterStates.endDate.format().split('T')[0] + 'T23:59:59.999Z'
        : null,
      marketId:
        filterStates?.marketId && filterStates.marketId !== 'none'
          ? filterStates?.marketId
          : null,
      skip: filterStates?.skip ? (filterStates.skip - 1) * 10 : 0,
      limit: filterStates?.limit ? filterStates.limit : 10,
      searchParam: debouncedSearch ? debouncedSearch : null,
      sortBy: filterStates?.sortBy ? filterStates.sortBy : null,
      isSearchForBooking: isSearchForBooking ? isSearchForBooking : null,
    }
    const res = await api.get<ParentResponse>('admin/getParents', queryObj)

    if (res.ok && res.data) {
      const { parents, count } = res.data.data
      setAllParents(parents)
      setTotalPages(Math.ceil(count / 10))
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getParentDetails()
    localStorage.setItem('ParentFilters', JSON.stringify(queryObj))
  }, [filterStates, debouncedSearch])
  return { allParents, totalPages, getParentDetails, setAllParents }
}

export default useAllParents
