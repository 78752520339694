export const partnerShipCode = [
  'Code',
  'Description',
  'Hourly Rate',
  'Admin Fee',
  'Start Date',
  'End Date',
  'Suppress Rates',
  'Prevent Manual',
  'Market Bypass',
  'Action',
]
