import { CancelToken } from 'apisauce'
import { CancelTokenSource } from 'axios'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { ConstantData, ConstantResponse } from '../types/constants.type'
import { MarketResponse, Markets } from '../types/market.type'
import { Parent } from '../types/parent.type'
import { Subject, SubjectResponse } from '../types/subject.type'
import { User } from '../types/user.type'
import { api, downloadSessionCsv } from '../utils'

interface AppContextInterface {
  getSubjects: () => Promise<void>
  appState: IState
  updateAppState: (newState: Partial<IState>) => void
  marketData: Markets[] | null | undefined
  subjectsArray: Subject[] | null | undefined
  constants?: ConstantData
  isMarketplaceManager: boolean | undefined
  downloadCsv: (sessionName: string) => Promise<void>
}

interface AppContextProviderProps {
  children: ReactNode
}

const AppContext = createContext<AppContextInterface | null>(null)

export interface DownloadCsv {
  name: 'session'
  status: 'loading' | 'complete' | 'canceled' | 'error'
  source: CancelTokenSource
}

interface IState {
  loading: boolean
  collapse: boolean
  changePassword: boolean
  currentUser: User | null
  isLoggedIn: boolean
  downloads: DownloadCsv[]
}

const isLoggedIn = !!(
  localStorage.getItem('token') || localStorage.getItem('tokenSession')
)

const initialValues: IState = {
  loading: false,
  collapse: false,
  currentUser: null,
  changePassword: false,
  isLoggedIn,
  downloads: [],
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [appState, setAppState] = useState(initialValues)
  const [marketData, setMarketData] = useState<Markets[] | null>()
  const [subjectsArray, setSubjectsArray] = useState<Subject[]>([])
  const [constants, setConstants] = useState<ConstantData>()

  const downloadCsv = async (sessionName: any) => {
    try {
      const source = CancelToken.source()
      if (
        appState.downloads.some(
          (d) => d.name === sessionName && d.status === 'loading',
        )
      )
        return

      setAppState((prev) => ({
        ...prev,
        downloads: [
          ...prev.downloads.filter((d) => d.name !== sessionName),
          {
            name: sessionName,
            status: 'loading',
            source,
          },
        ],
      }))

      await downloadSessionCsv(sessionName, source.token)
      setAppState((prev) => ({
        ...prev,
        downloads: prev.downloads.map((d) => {
          if (d.name === sessionName) {
            return { ...d, status: 'complete' }
          }
          return d
        }),
      }))
    } catch (error) {
      setAppState((prev) => ({
        ...prev,
        downloads: prev.downloads.map((d) => {
          if (d.name === sessionName) {
            return { ...d, status: error === 'canceled' ? 'canceled' : 'error' }
          }
          return d
        }),
      }))
    }
  }

  const isMarketplaceManager =
    appState.currentUser?.isMarketplaceManager === true ||
    appState.currentUser?.isMarketplaceManager === false
      ? appState.currentUser?.isMarketplaceManager
      : localStorage.getItem('isMarketplaceManager') === 'true'

  const getMarketData = async () => {
    const res = await api.get<MarketResponse>('admin/getMarkets')
    if (res.ok && res.data) {
      const { data } = res.data
      setMarketData(data)
    }
  }
  const getSubjects = async () => {
    const res = await api.get<SubjectResponse>('/admin/getSubjects')
    if (res.ok && res.data) {
      const { data } = res.data
      setSubjectsArray(data)
    }
  }

  const getConstants = async () => {
    const res = await api.get<ConstantResponse>('admin/getConstants')
    if (res.ok && res.data) {
      const { data } = res
      setConstants(data.data)
    }
  }

  useEffect(() => {
    if (appState.isLoggedIn) {
      getMarketData()
      getSubjects()
      getConstants()
    }
  }, [appState.isLoggedIn])

  const updateAppState = (newState: Partial<IState>) => {
    setAppState((prev) => ({ ...prev, ...newState }))
  }

  return (
    <AppContext.Provider
      value={{
        getSubjects,
        appState,
        updateAppState,
        marketData,
        subjectsArray,
        constants,
        isMarketplaceManager,
        downloadCsv,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const appContext = useContext(AppContext)

  if (!appContext) {
    throw new Error('Please provide values to app provider')
  }

  return appContext
}
