import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { MdExpandMore } from 'react-icons/md'

import CustomTimePicker from '../../../../../../components/custom-time-picker/CustomTimePicker'
import CustomDatePicker from '../../../../../../components/date-picker/CustomDatePicker'
import { StyledButton } from '../../../../../../components/styled-button/StyledButtons'
import { api } from '../../../../../../utils'
import { weekDays } from '../data'
import ModalWrapper from './../../../../../../components/modal-wrapper/ModalWrapper'
import './recurring-availability-modal.scss'

interface Errors {
  startDate?: boolean
  endDate?: boolean
  isStartBefore?: boolean
  startTime?: boolean
  endTime?: boolean
  isSelectedDays?: boolean
  repetition?: boolean
}
const errorsInit = {
  startDate: false,
  endDate: false,
  startTime: false,
  endTime: false,
  repetition: false,
  isSelectedDays: false,
  isStartBefore: false,
}
interface RecurringAvailabilityProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: () => void
}

interface RecurringAvailability {
  startDate?: dayjs.Dayjs | null
  endDate?: dayjs.Dayjs | null
  startTime?: string | dayjs.Dayjs
  endTime?: string | dayjs.Dayjs
}

const recurringAvailabilityInit = {
  startDate: null,
  endDate: null,
}

const RecurringAvailabilityModal: FC<RecurringAvailabilityProps> = ({
  open,
  setOpen,
  onSuccess,
}) => {
  const [accordionValue, setAccordionValue] = useState(false)
  const [repetition, setRepitition] = useState('Select repeat frequency')
  const [scheduleTime, setScheduleTime] = useState(false)
  const [selectedDays, setSelectedDays] = useState<number[]>([])
  const [errorStates, setErrorStates] = useState<Errors>(errorsInit)
  const [mode, setMode] = useState<'start' | 'end' | undefined>()
  const [recurringAvailabilityStates, setRecurringAvailabilityStates] =
    useState<RecurringAvailability>(recurringAvailabilityInit)

  const [abbreviation, setAbbreviation] = useState('')

  useEffect(() => {
    const abbreviation = localStorage.getItem('abbreviation')
    setAbbreviation(abbreviation as string)
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepitition((event.target as HTMLInputElement).value)
    setErrorStates({ ...errorStates, ['repetition']: false })
    setAccordionValue(!accordionValue)
  }

  const handleClose = () => {
    setOpen(!open)
    setRepitition('Select repeat frequency')
  }

  const handleHour = (hour: number, timeIndicator: string) => {
    if (timeIndicator === 'AM') return hour
    else return hour + 12
  }

  const handleTime = (
    hour: number | null,
    minute: number | null,
    timeIndicator: string,
    mode: string,
  ) => {
    const date = new Date()
    date.setHours(handleHour(hour as number, timeIndicator))
    date.setMinutes(minute as number)

    setRecurringAvailabilityStates({
      ...recurringAvailabilityStates,
      [mode === 'start' ? 'startTime' : 'endTime']: dayjs(date).format('LT'),
    })
    setScheduleTime(false)
    setErrorStates({
      ...errorStates,
      [mode === 'start' ? 'startTime' : 'endTime']: false,
    })
  }

  const onDateChange = (
    value: dayjs.Dayjs | null | undefined,
    mode?: string,
  ) => {
    setRecurringAvailabilityStates({
      ...recurringAvailabilityStates,
      [mode ? mode : '']: value,
    })
    if (value) {
      setErrorStates({ ...errorStates, [mode as string]: false })
    } else {
      setErrorStates({ ...errorStates, [mode as string]: true })
    }
  }

  const daySelection = (alp: { id: number; option: string; value: string }) => {
    if (!selectedDays.includes(alp.id))
      setSelectedDays([...selectedDays, alp.id])
    else {
      setSelectedDays(selectedDays.filter((day) => day !== alp.id))
    }
    setErrorStates({ ...errorStates, ['isSelectedDays']: true })
  }

  const isValid = () => {
    let startTime = false,
      endTime = false,
      endDate = false,
      startDate = false,
      repetitions = false

    if (!recurringAvailabilityStates.startTime) startTime = true
    if (!recurringAvailabilityStates.endTime) endTime = true
    if (!recurringAvailabilityStates.startDate) startDate = true
    if (!recurringAvailabilityStates.endDate) endDate = true
    if (repetition === 'Select repeat frequency') repetitions = true

    let tempErrorStates = {
      startTime: startTime,
      endTime: endTime,
      endDate: endDate,
      startDate: startDate,
      repetition: repetitions,
    }

    setErrorStates({ ...errorStates, ...tempErrorStates })
    return Object.values(tempErrorStates).every((state) => state === false)
  }

  const setRecurring = async () => {
    if (isValid()) {
      let values = Object.values(errorStates)

      let time1 = recurringAvailabilityStates.startTime
      let time2 = recurringAvailabilityStates.endTime
      let cdt = dayjs(time1, 'hh:mmA')
      let cdt2 = dayjs(time2, 'hh:mmA')

      let arr = []
      if (repetition == 'Repeat Daily') {
        for (let i = 0; i <= 6; i++) {
          let date = new Date(
            new Date(
              recurringAvailabilityStates?.startDate?.toString() || new Date(),
            ).getTime() +
              i * 3600 * 24 * 1000,
          ).getDay()
          let k = {
            day: date,
            startTime:
              new Date(cdt.toString()).getHours() * 3600000 +
              new Date(cdt.toString()).getMinutes() * 60000,
            endTime:
              new Date(cdt2.toString()).getHours() * 3600000 +
              new Date(cdt2.toString()).getMinutes() * 60000 -
              0.5 * 3600000,
          }

          arr.push(k)
        }
      } else {
        for (let i = 0; i <= 6; i++) {
          let date = new Date(
            new Date(
              recurringAvailabilityStates.startDate
                ? recurringAvailabilityStates.startDate.toString()
                : '',
            ).getTime() +
              i * 3600 * 24 * 1000,
          ).getDay()
          if (selectedDays.indexOf(date) != -1) {
            let k = {
              day: date,
              startTime:
                new Date(cdt.toString()).getHours() * 3600000 +
                new Date(cdt.toString()).getMinutes() * 60000,
              endTime:
                new Date(cdt2.toString()).getHours() * 3600000 +
                new Date(cdt2.toString()).getMinutes() * 60000 -
                0.5 * 3600000,
            }

            arr.push(k)
          }
        }
      }
      if (arr) {
        let data = new FormData()
        if (
          recurringAvailabilityStates?.startDate?.isAfter(
            recurringAvailabilityStates?.endDate,
          )
        ) {
          setErrorStates({ ...errorStates, ['isStartBefore']: true })
        } else {
          data.append(
            'startDate',
            dayjs(recurringAvailabilityStates.startDate).format('D MMM YYYY'),
          )
          data.append(
            'endDate',
            dayjs(recurringAvailabilityStates.endDate).format('D MMM YYYY'),
          )
          data.append('slots', JSON.stringify(arr))
          data.append('tutorId', localStorage.getItem('tutorId') as string)

          let res

          if (repetition != 'Repeat Daily') {
            if (selectedDays.length > 0) {
              res = await api.post('tutor/setRecurringAvailability', data)
            } else {
              setErrorStates({ ...errorStates, ['isSelectedDays']: true })
            }
          } else {
            res = await api.post('tutor/setRecurringAvailability', data)
          }
          if (res?.ok) {
            onSuccess()
            handleClose()
          }
        }
      }
    }
  }

  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <div className="w-100">
        <h1 className="text-center modal-title">Set Recurring Availability</h1>
      </div>
      <div className="text-center time-zone-display mb-4">
        TimeZone: [{abbreviation}]
      </div>
      <div className="row">
        <div className="col-lg-6 col-12 form-group">
          <CustomDatePicker
            placeholder="Start Date"
            minDate={dayjs()}
            maxDate={dayjs().add(120, 'days')}
            date={recurringAvailabilityStates.startDate}
            mode="startDate"
            onChange={onDateChange}
            isError={errorStates.startDate}
            greyBorder
          />
        </div>
        <div className="col-lg-6 col-12 form-group">
          <CustomDatePicker
            placeholder="End Date"
            minDate={dayjs()}
            maxDate={dayjs().add(120, 'days')}
            date={recurringAvailabilityStates.endDate}
            mode="endDate"
            onChange={onDateChange}
            isError={errorStates.endDate}
            greyBorder
          />
        </div>
      </div>
      {errorStates.isStartBefore && (
        <div
          className="helper-text text-center
        "
        >
          Start Date cannot be greater than End Date
        </div>
      )}
      <div className="row">
        <div className="col-12 form-group">
          <Accordion
            className={`${
              errorStates.repetition ? 'border-red' : 'border'
            } shadow-none`}
            expanded={accordionValue}
            onChange={() => {
              setAccordionValue(!accordionValue)
            }}
          >
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>{repetition}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {' '}
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={repetition}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Repeat Daily"
                  control={<Radio />}
                  label="Repeat Daily"
                />
                <FormControlLabel
                  value="Repeat Weekly"
                  control={<Radio />}
                  label="Repeat Weekly"
                />
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
          {repetition === 'Repeat Weekly' ? (
            <div>
              <div>Repeats On</div>
              <div className="d-flex">
                {weekDays.map((alp, index) => {
                  return (
                    <div
                      onClick={(event) => {
                        daySelection(alp)
                      }}
                      key={index}
                      className={`day-picker-box m-2 center-align cursor-pointer ${
                        selectedDays.includes(alp.id)
                          ? 'fw-bold border-success-width border-success '
                          : 'border-secondary'
                      } ${
                        selectedDays.length < 1 && errorStates.isSelectedDays
                          ? 'border-danger'
                          : ''
                      }`}
                    >
                      {alp.option}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12 form-group">
          <div
            className={`${
              errorStates.startTime ? 'border-red' : 'border'
            } time-div`}
            onClick={() => {
              setScheduleTime(true)
              setMode('start')
            }}
          >
            <label>Start Time</label>
            <div>{recurringAvailabilityStates.startTime as string}</div>
          </div>
        </div>
        <div className="col-lg-6 col-12 form-group">
          <div
            className={`${
              errorStates.endTime ? 'border-red' : 'border'
            } time-div`}
            onClick={() => {
              setScheduleTime(true)
              setMode('end')
            }}
          >
            <label>End Time</label>
            <div>{recurringAvailabilityStates.endTime as string}</div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="schedular-save m-0">
          <StyledButton
            color="inherit"
            endIcon={<FaArrowRight />}
            onClick={() => {
              setRecurring()
            }}
          >
            Save
          </StyledButton>
        </div>
      </div>
      {scheduleTime && (
        <CustomTimePicker
          open={scheduleTime}
          setOpen={setScheduleTime}
          mode={mode}
          handleTime={handleTime}
        />
      )}
    </ModalWrapper>
  )
}

export default RecurringAvailabilityModal
