import { Button, SelectChangeEvent } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'

import CustomDatePicker from '../../../../../../components/date-picker/CustomDatePicker'
import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import MarketDropdown from '../../../../../../components/styled-dropdown/MarketDropdown'
import { useAppContext } from '../../../../../../contexts/AppContext'
import { api } from '../../../../../../utils'
import { EditDataI } from '../all-fee-table/AllFeeTableBody'
import FeeAndBandTable from './components/fee-band-table/FeeAndBandTable'

interface AddEditFeeProps {
  open: boolean
  onClose: () => void
  mode: 'Add' | 'Edit'
  editData?: EditDataI
}
export interface BandI {
  min: string
  max: string
  fee: string
  type: string | '%' | '$'
}

const defaultBand: BandI = {
  min: '',
  max: '',
  fee: '',
  type: '',
}

const AddEditFee: FC<AddEditFeeProps> = ({ mode, onClose, open, editData }) => {
  const { marketData, updateAppState } = useAppContext()

  const [marketName, setMarketName] = useState('')
  const [effectiveDate, setEffectiveDate] = useState<Dayjs | null>(null)
  const [band1, setBand1] = useState<BandI>(defaultBand)
  const [band2, setBand2] = useState<BandI>(defaultBand)
  const [band3, setBand3] = useState<BandI>(defaultBand)
  const [dateError, setDateError] = useState(false)
  const [error, setError] = useState<{ isError: boolean; errorMsg: string }>({
    isError: false,
    errorMsg: '',
  })

  const marketChange = (event: SelectChangeEvent) => {
    setMarketName(event.target.value)
  }

  const isValid = () => {
    const { max, ...restBand3 } = band3
    if (
      !marketName ||
      !effectiveDate ||
      !Object.values(band1).every((v) => v) ||
      !Object.values(band2).every((v) => v) ||
      !Object.values(restBand3).every((v) => v)
    ) {
      return false
    } else return true
  }

  useEffect(() => {
    if (mode === 'Edit') {
      if (editData?.idData._id) setMarketName(editData?.idData._id)
      if (editData?.editFee.effectiveDate)
        setEffectiveDate(dayjs(editData.editFee.effectiveDate).utc())

      if (editData?.editFee.case1) {
        let tempBand1 = {
          min: editData?.editFee.case1.from.toString(),
          max: editData?.editFee.case1.to?.toString() as string,
          type: editData?.editFee.case1.type.toString(),
          fee: editData?.editFee.case1.discount.toString(),
        }
        setBand1({ ...band1, ...tempBand1 })
      }
      if (editData?.editFee.case2) {
        let tempBand2 = {
          min: editData?.editFee.case2.from.toString(),
          max: editData?.editFee.case2.to?.toString() as string,
          type: editData?.editFee.case2.type.toString(),
          fee: editData?.editFee.case2.discount.toString(),
        }
        setBand2({ ...band2, ...tempBand2 })
      }
      if (editData?.editFee.case3) {
        let tempBand3 = {
          min: editData?.editFee.case3.from.toString(),
          type: editData?.editFee.case3.type.toString(),
          fee: editData?.editFee.case3.discount.toString(),
        }
        setBand3({ ...band3, ...tempBand3 })
      }
    }
  }, [editData])

  const addEditFee = async () => {
    if (parseInt(band1.min) >= parseInt(band1.max)) {
      let tempError = {
        isError: true,
        errorMsg: 'The value of band 1 min should be less than band 1 max',
      }
      setError({ ...error, ...tempError })
    } else if (parseInt(band1.max) >= parseInt(band2.min)) {
      let tempError = {
        isError: true,
        errorMsg: 'The value of band 1 max should be less than band 2 min',
      }
      setError({ ...error, ...tempError })
    } else if (parseInt(band2.min) >= parseInt(band2.max)) {
      let tempError = {
        isError: true,
        errorMsg: 'The value of band 2 min should be less than band 2 max',
      }
      setError({ ...error, ...tempError })
    } else if (parseInt(band2.max) >= parseInt(band3.min)) {
      let tempError = {
        isError: true,
        errorMsg: 'The value of band 2 max should be less than band 3 min',
      }
      setError({ ...error, ...tempError })
    } else {
      setError({ ...error, ['isError']: false })
      let fd = new FormData()
      updateAppState({ loading: true })
      if (mode !== 'Edit') fd.append('marketId', marketName)
      if (
        mode === 'Edit' &&
        dayjs(effectiveDate).isSame(dayjs(editData?.editFee.effectiveDate))
      ) {
      } else {
        if (
          new Date().getTime() !=
          new Date(dayjs(effectiveDate).format()).getTime()
        ) {
          fd.append('effectiveTime', dayjs(effectiveDate).format('MM-DD-YYYY'))
        }
      }
      let case1 = {
        from: band1.min,
        to: band1.max,
        type: band1.type,
        discount: band1.fee,
      }
      let case2 = {
        from: band2.min,
        to: band2.max,
        type: band2.type,
        discount: band2.fee,
      }
      let case3 = {
        from: band3.min,
        type: band3.type,
        discount: band3.fee,
      }

      fd.append('case1', JSON.stringify(case1))
      fd.append('case2', JSON.stringify(case2))
      fd.append('case3', JSON.stringify(case3))
      if (mode === 'Add') {
        const addFeeRes = await api.post('admin/addSlidingFee', fd)
        if (addFeeRes && addFeeRes.ok) {
          onClose()
        }
      }
      if (mode === 'Edit') {
        const editFeeRes = await api.post(
          `admin/editSlidingFee/${editData?.editFee._id}`,
          fd,
        )
        if (editFeeRes && editFeeRes.ok) {
          onClose()
        }
      }
      updateAppState({ loading: false })
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={mode + ' Admin Fee'}
      wide
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div className="form-group row align-items-center">
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Market:</label>
            </div>

            <MarketDropdown
              placeholder="--Select Market Name--"
              className="col-sm-8"
              option={marketData}
              value={marketName}
              handleChange={marketChange}
              excludeNone
              disabled={mode === 'Edit'}
            />
          </div>

          <div className="form-group row align-items-center">
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Effective Date</label>
            </div>

            <div className="col-sm-8">
              <CustomDatePicker
                date={effectiveDate}
                placeholder="mm-dd-yyyy"
                onChange={(value) => {
                  value && setEffectiveDate(value)
                }}
                onClear={() => {
                  setEffectiveDate(null)
                  setDateError(true)
                }}
              />
              {dateError && !effectiveDate && (
                <div className="helper-text">Effective Date is required.</div>
              )}
            </div>
          </div>
          <FeeAndBandTable
            band1={band1}
            setBand1={setBand1}
            band2={band2}
            setBand2={setBand2}
            band3={band3}
            setBand3={setBand3}
          />
        </div>
        {error.isError && (
          <p className="helper-text text-center">{error.errorMsg}</p>
        )}
        <div className="form-group row pe-0">
          <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-0">
            <Button
              color={isValid() ? 'success' : 'info'}
              disabled={!isValid()}
              className="w-100"
              onClick={addEditFee}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditFee
