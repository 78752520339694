import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../../../utils'
import { CONSTANT } from '../../../../../../utils/constants'
import { Policy } from '../../AllCancellationPolicy.type'
import './add-edit-cancellation-policy.scss'

interface AddEditCancellationPolicyProps {
  open: boolean
  onClose: () => void
  mode: 'Add' | 'Edit'
  editPolicy?: Policy
}

interface FormValues {
  cancelLessThan: string
  feeCharged: string
  parentNoShowFee: string
}
interface Errors {
  cancelLessThan: boolean
  feeCharged: boolean
  parentNoShowFee: boolean
  policyNameError: boolean
}

const defaultErrors: Errors = {
  cancelLessThan: false,
  feeCharged: false,
  parentNoShowFee: false,
  policyNameError: false,
}

const schema = Yup.object({
  cancelLessThan: Yup.string(),
  feeCharged: Yup.string(),
  PolicyDetailI: Yup.string(),
}).required()

const AddEditCancellationPolicy: FC<AddEditCancellationPolicyProps> = ({
  mode,
  onClose,
  open,
  editPolicy,
}) => {
  const defaultValues: FormValues = {
    cancelLessThan: '',
    feeCharged: '',
    parentNoShowFee: '',
  }
  const {
    setValue,
    control,
    watch,
    formState: { touchedFields },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  })

  const [policyName, setPolicyName] = useState('')
  const [policyErrors, setPolicyErrors] = useState(defaultErrors)
  const [policyCheck, setPolicyCheck] = useState(defaultErrors)
  const values = watch()
  const isValid = () => {
    if (
      !policyName ||
      !values.cancelLessThan ||
      !values.feeCharged ||
      !values.parentNoShowFee ||
      !!Error()
    ) {
      return false
    } else return true
  }

  useEffect(() => {
    if (mode === 'Edit') {
      if (editPolicy) {
        if (editPolicy.name) setPolicyName(editPolicy.name)
        if (editPolicy.cancelsLessThan)
          setValue('cancelLessThan', editPolicy.cancelsLessThan.toString())
        if (editPolicy.feeCharged)
          setValue('cancelLessThan', editPolicy.cancelsLessThan.toString())
        setValue('feeCharged', editPolicy.feeCharged.toString())
        if (editPolicy.parentNoShowFeeCharged)
          setValue(
            'parentNoShowFee',
            editPolicy.parentNoShowFeeCharged.toString(),
          )
      }
    }
  }, [editPolicy])

  const AddEditCancellationPolicy = async () => {
    let fd = new FormData()
    fd.append('name', policyName)
    fd.append('cancelsLessThan', values.cancelLessThan)
    fd.append('feeCharged', values.feeCharged)
    fd.append('parentNoShowFeeCharged', values.parentNoShowFee)

    let res
    if (mode === 'Add') {
      res = await api.post('admin/addCancellationPolicy', fd)
    } else {
      res = await api.put(`admin/editCancellationPolicy/${editPolicy?._id}`, fd)
    }
    if (res && res.ok) onClose?.()
  }

  const Error = () => {
    const { cancelLessThan, feeCharged, parentNoShowFee } = values
    if (
      policyErrors.cancelLessThan &&
      !cancelLessThan &&
      cancelLessThan !== '0'
    ) {
      return 'Fields are required'
    }
    if (
      (parseInt(cancelLessThan) <= 0 || !cancelLessThan) &&
      (policyCheck.cancelLessThan || touchedFields.cancelLessThan)
    ) {
      return 'Number of hour must be greater than 0.'
    }

    if (policyErrors.feeCharged && !feeCharged && feeCharged !== '0') {
      return 'Fields are required'
    }
    if (
      (parseInt(feeCharged) <= 0 ||
        !feeCharged ||
        parseInt(feeCharged) > 100) &&
      (policyCheck.feeCharged || touchedFields.feeCharged)
    ) {
      return 'Fees must be greater than 0 and less than or equal 100'
    }

    if (
      policyErrors.parentNoShowFee &&
      !parentNoShowFee &&
      parentNoShowFee !== '0'
    ) {
      return 'Fields are required'
    }

    if (
      (!parentNoShowFee ||
        parseInt(parentNoShowFee) <= 0 ||
        parseInt(parentNoShowFee) > 100) &&
      (policyCheck.parentNoShowFee || touchedFields.parentNoShowFee)
    ) {
      return 'Fees must be greater than 0 and less than or equal 100'
    }
    return ''
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={mode + ' Cancelation Policy'}
      wide
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div
            className={`form-group row ${
              !policyName && policyErrors.policyNameError
                ? 'align-items-start'
                : 'align-items-center'
            }`}
          >
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Policy Name</label>
            </div>

            <StyledTextfield
              placeholder="Enter Policy Name"
              className="col-sm-8"
              value={policyName}
              onChange={(e) => {
                setPolicyName(e.target.value)
              }}
              onKeyDown={() => {
                setPolicyErrors({ ...policyErrors, policyNameError: true })
              }}
              isError={!policyName && policyErrors.policyNameError}
              errorMessage={
                !policyName && policyErrors.policyNameError
                  ? 'Policy name is required'
                  : ''
              }
            />
          </div>

          <div className="form-group row align-items-start">
            <div className="col-sm-4 text-left">
              <label className="add-child__label">Policy Detail</label>
            </div>

            <div className="col-sm-8">
              <span>If a parent cancels less than </span>
              <Controller
                name="cancelLessThan"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    onChange={(e) => {
                      if (
                        CONSTANT.numberRegex.test(e.target.value) ||
                        e.target.value == ''
                      ) {
                        field.onChange(e)
                        setPolicyErrors({
                          ...policyErrors,
                          cancelLessThan: true,
                        })
                      }
                      setPolicyCheck({
                        ...policyCheck,
                        cancelLessThan: true,
                      })
                    }}
                    className={`width80 ${
                      touchedFields.cancelLessThan &&
                      !values.cancelLessThan &&
                      'border-red'
                    }`}
                  />
                )}
              />

              <span>&nbsp;hours prior to the session start time, </span>
              <Controller
                name="feeCharged"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    onChange={(e) => {
                      if (
                        CONSTANT.numberRegex.test(e.target.value) ||
                        e.target.value == ''
                      ) {
                        field.onChange(e)
                        setPolicyErrors({
                          ...policyErrors,
                          feeCharged: true,
                        })
                      }
                      setPolicyCheck({
                        ...policyCheck,
                        feeCharged: true,
                      })
                    }}
                    className={`width80 ${
                      touchedFields.feeCharged &&
                      !values.feeCharged &&
                      'border-red'
                    }`}
                  />
                )}
              />
              <span>% &nbsp;of the session fee is charged.</span>
              <span> If the parent is a no show with no communication</span>
              <Controller
                name="parentNoShowFee"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    onChange={(e) => {
                      if (
                        CONSTANT.numberRegex.test(e.target.value) ||
                        e.target.value == ''
                      ) {
                        field.onChange(e)
                        setPolicyErrors({
                          ...policyErrors,
                          parentNoShowFee: true,
                        })
                      }
                      setPolicyCheck({
                        ...policyCheck,
                        parentNoShowFee: true,
                      })
                    }}
                    className={`width80 ${
                      touchedFields.parentNoShowFee &&
                      !values.parentNoShowFee &&
                      'border-red'
                    }`}
                  />
                )}
              />
              <span>%&nbsp; of the fee is charged.</span>
            </div>
          </div>
          <div className="helper-text">{Error()}</div>
        </div>

        <div className="form-group row pe-0">
          <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-0">
            <Button
              color={isValid() ? 'success' : 'info'}
              disabled={!isValid()}
              className="w-100"
              onClick={AddEditCancellationPolicy}
            >
              {mode === 'Add' ? 'Add' : 'Edit'}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditCancellationPolicy
