import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import { api } from '../../../../utils'
import { CancellationRes, Policy } from './AllCancellationPolicy.type'
import AddEditCancellationPolicy from './components/add-edit-cancellation-policy/AddEditCancellationPolicy'
import CancellationPolicyComponent from './components/cancellation-policy-component/CancellationPolicyComponent'

const AllCancellationPolicy = () => {
  const [cancellationPolicies, setCancellationPolicies] = useState<Policy[]>([])
  const [addCancellationPolicy, setAddCancellationPolicy] = useState(false)
  const [selectedPolicy, setSelectedPolicy] = useState('')
  const getCancellationPolices = async () => {
    const res = await api.get<CancellationRes>('admin/getCancellationPolices')
    if (res.ok && res.data) {
      const { policies } = res.data.data
      setCancellationPolicies(policies)
    }
  }

  useEffect(() => {
    getCancellationPolices()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setAddCancellationPolicy(true)
            }}
          >
            Add Cancelation Policy
          </Button>
        </div>
      </div>
      <div className="row">
        {!!cancellationPolicies.length && (
          <>
            {cancellationPolicies.map((cancellationPolicy) => (
              <CancellationPolicyComponent
                key={cancellationPolicy._id}
                getCancellationPolices={getCancellationPolices}
                cancellationPolicy={cancellationPolicy}
                onViewClick={(id) => {
                  setSelectedPolicy(id)
                }}
                selectedPolicy={selectedPolicy}
                onHideClick={() => {
                  setSelectedPolicy('')
                }}
              />
            ))}
          </>
        )}
      </div>
      {addCancellationPolicy && (
        <AddEditCancellationPolicy
          open={addCancellationPolicy}
          onClose={() => {
            setAddCancellationPolicy(false)
            getCancellationPolices()
          }}
          mode="Add"
        />
      )}
    </div>
  )
}

export default AllCancellationPolicy
