import { TableRow, TableCell, Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'

import ConfirmDelete from '../../../../../../components/confirm-delete/ConfirmDelete'
import { IMAGES, api } from '../../../../../../utils'
import { subject } from '../../all-subjects.types'
import AddEditSubject from '../add-edit-subject/AddEditSubject'
import SubCategoriesComponent from './sub-categories/SubCategoriesComponent'
import './subject-component.scss'

interface SubjectComponentBodyProps {
  subject: subject
  subjectName: string
  setSubjectName: (name: string) => void
  getAllSubjects: () => Promise<void>
}
const size = 15
const SubjectComponentBody: FC<SubjectComponentBodyProps> = ({
  subject,
  subjectName,
  setSubjectName,
  getAllSubjects,
}) => {
  const [editSubjectName, setEditSubjectName] = useState(false)
  const [deleteSubjectName, setDeleteSubjectName] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState<subject>()
  const [subjectType, setSubjectType] = useState<'subject' | 'subSubject'>(
    'subject',
  )

  const handleDelete = async () => {
    let res
    if (subjectType === 'subject') {
      res = await api.delete(`admin/subject/${selectedSubject?._id}`)
    } else {
      res = await api.delete(`admin/subSubject/${selectedSubject?._id}`)
    }
    if (res && res.ok) {
      onCloseEditDelete('Delete')
    }
  }

  const subjectAndTypeSetter = (
    mode: 'Edit' | 'Delete',
    subject: subject,
    type: 'subject' | 'subSubject',
  ) => {
    if (mode === 'Edit') setEditSubjectName(true)
    else setDeleteSubjectName(true)
    setSelectedSubject(subject)
    setSubjectType(type)
  }

  const onCloseEditDelete = (mode: 'Edit' | 'Delete') => {
    if (mode === 'Edit') setEditSubjectName(false)
    else setDeleteSubjectName(false)
    getAllSubjects()
  }

  return (
    <>
      <TableRow className="heading-table">
        <TableCell className="subject-name">
          {subject.name}
          {subjectName === subject.name && (
            <>
              {subject?.subcategories?.length ? (
                <div className="mt-2">
                  <div className="subSubject">
                    {subject.subcategories?.map((sub) => {
                      return (
                        <SubCategoriesComponent
                          subCategories={sub}
                          onEdit={(subject) => {
                            subjectAndTypeSetter('Edit', subject, 'subSubject')
                          }}
                          onDelete={(subject) => {
                            subjectAndTypeSetter(
                              'Delete',
                              subject,
                              'subSubject',
                            )
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              ) : (
                <div className="mt-2">
                  <div className="width289">No subject found</div>
                </div>
              )}
            </>
          )}
        </TableCell>
        <TableCell className="subject-action">
          {' '}
          <span className="d-flex align-items-center justify-content-center">
            <Tooltip title="Delete">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={(e) => {
                  subjectAndTypeSetter('Delete', subject, 'subject')
                }}
              >
                <FaTrashAlt size={size} />
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={(e) => {
                  subjectAndTypeSetter('Edit', subject, 'subject')
                }}
              >
                <FaPencilAlt size={size} />
              </span>
            </Tooltip>
            <span
              className="ms-3 me-3 menu-icon text-center"
              onClick={(e) => {
                setSubjectName(subjectName === subject.name ? '' : subject.name)
              }}
            >
              <img
                src={IMAGES.DownArrow}
                className={`down-img ${
                  subjectName === subject.name ? 'down' : ''
                } `}
              />
            </span>
          </span>
        </TableCell>
      </TableRow>
      {editSubjectName && (
        <AddEditSubject
          open={editSubjectName}
          onClose={() => {
            onCloseEditDelete('Edit')
          }}
          mode="Edit"
          selectedSubject={selectedSubject}
          subjectType={subjectType}
        />
      )}
      {deleteSubjectName && (
        <ConfirmDelete
          open={deleteSubjectName}
          onClose={() => {
            onCloseEditDelete('Delete')
          }}
          onYes={handleDelete}
          title={subjectType === 'subject' ? 'Subject' : 'Sub Subject'}
          text={subjectType === 'subject' ? 'the Subject' : 'the Sub Subject'}
        />
      )}
    </>
  )
}

export default SubjectComponentBody
