import { Card, CardContent } from '@mui/material'
import { FC } from 'react'
import { BiCreditCard } from 'react-icons/bi'

import './new-card.scss'

interface NewCardProps {
  setMode?: React.Dispatch<React.SetStateAction<string>>
}

const NewCard: FC<NewCardProps> = ({ setMode }) => {
  return (
    <Card
      className="col-md-5 mt-3 mb-3 ms-3"
      onClick={() => {
        setMode ? setMode('add') : <></>
      }}
    >
      <CardContent className="d-flex flex-column justify-content-center align-items-center">
        <div className="cursor-pointer">
          <BiCreditCard size={30} color="#229bbc" />
        </div>
        <div className="cursor-pointer">Add New Card</div>
      </CardContent>
    </Card>
  )
}

export default NewCard
