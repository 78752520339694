import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import routes from '../../common/routes'
import { api } from '../../utils'
import { CONSTANT } from '../../utils/constants'
import ModalWrapper from '../modal-wrapper/ModalWrapper'
import StyledDropdown from '../styled-dropdown/StyledDropdown'
import StyledTextfield from '../styled-textfield/StyledTextfield'
import './add-parent-tutor.scss'
import { AddParentTutorType } from './addParentTutor.type'
import { timeZone } from './data'

interface FormValues {
  firstName: string
  lastName: string
  email: string
  userName: string
  timeZone: string
  phone: string
  referralCode: string
}

const schema = Yup.object({
  firstName: Yup.string()
    .required('First Name is required')
    .matches(CONSTANT.newNamePattern, 'First Name is not valid'),
  lastName: Yup.string()
    .required('Last Name is required')
    .matches(CONSTANT.newNamePattern, 'Last Name is not valid'),
  phone: Yup.string()
    .required('Phone Number is required')
    .min(10, 'Phone Number minimum length is 10')
    .max(10),
  email: Yup.string().email('Email is not valid').required('Email is required'),
  userName: Yup.string()
    .min(
      6,
      'Minimum length should be 6 in combination of numbers and characters.',
    )
    .max(
      20,
      'Minimum length should be 6 in combination of numbers and characters.',
    )
    .required('UserName is required')
    .matches(/^[a-z0-9]+$/i, 'User name is not valid.'),
  timeZone: Yup.string().required('Time Zone is required'),
  referralCode: Yup.string(),
}).required()

const AddParentTutor = () => {
  const navigate = useNavigate()
  const [role, setRole] = useState('')

  useEffect(() => {
    if (window.location.pathname.split('/')[3] === 'add-parent') {
      setRole('Parent')
    }
    if (window.location.pathname.split('/')[3] === 'add-tutor') {
      setRole('Tutor')
    }
    setOpen(true)
  }, [window.location.pathname.split('/')[3]])

  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(!open)
    role === 'Parent'
      ? navigate('/pages/' + routes.allParents)
      : navigate('/pages/' + routes.allTutors)
  }
  const defaultValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    userName: '',
    timeZone: '',
    phone: '',
    referralCode: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  })

  const onSubmit = async (values: FormValues) => {
    const {
      firstName,
      email,
      lastName,
      phone,
      timeZone,
      userName,
      referralCode,
    } = values
    const queries: { [key: string]: string } = {
      firstName,
      lastName,
      email,
      userName,
      utcoffsetzone: timeZone,
      phoneNumber: phone,
      deviceType: 'WEB',
    }
    if (referralCode) queries.referralCode = referralCode
    const res = await api.post<AddParentTutorType>(
      `/admin/${role === 'Parent' ? 'addParent' : 'addTutor'}`,
      queries,
    )
    const { data, status } = res
    if (res.ok) {
      handleClose()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      wide
      title={role === 'Parent' ? 'Add Parent' : 'Add Tutor'}
    >
      <div className="row without-arrows">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <form className="w-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">First Name</label>
              </div>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="First Name"
                    max={64}
                    isError={!!errors.firstName}
                    className="col-sm-8"
                    errorMessage={errors.firstName?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">Last Name</label>
              </div>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Last Name"
                    max={64}
                    isError={!!errors.lastName}
                    className="col-sm-8"
                    errorMessage={errors.lastName?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">Email</label>
              </div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter Email"
                    isError={!!errors.email}
                    className="col-sm-8"
                    errorMessage={errors.email?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">Phone Number</label>
              </div>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter Phone Number"
                    isError={!!errors.phone}
                    type="number"
                    className="col-sm-8"
                    max={10}
                    errorMessage={errors.phone?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">User Name</label>
              </div>
              <Controller
                name="userName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter User Name"
                    isError={!!errors.userName}
                    max={20}
                    className="col-sm-8"
                    errorMessage={errors.userName?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">Time Zone</label>
              </div>
              <Controller
                name="timeZone"
                control={control}
                render={({ field }) => (
                  <StyledDropdown
                    {...field}
                    option={timeZone}
                    placeholder="Select Time Zone"
                    isError={!!errors.timeZone}
                    className="col-sm-8"
                    errorMessage={errors.timeZone?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-parent__label">
                  {role === 'Parent'
                    ? 'Referral or Partnership Code (if applicable)'
                    : 'Referral Code(Optional)'}
                </label>
              </div>
              <Controller
                name="referralCode"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    className="col-sm-8"
                    placeholder={
                      role === 'Parent'
                        ? 'Referral or Partnership Code (if applicable)'
                        : 'Referral Code(Optional)'
                    }
                  />
                )}
              />
            </div>

            <div className="row">
              <div className="col-sm-8 offset-sm-4 text-center button-margin">
                <Button
                  className="w-100"
                  disabled={!isValid}
                  color={!isValid ? 'info' : 'success'}
                  type="submit"
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddParentTutor
