import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'

interface ConfirmNationWideProps {
  open: boolean
  modalText: string
  handleNo: () => void
  handleYes: () => void
}

const ConfirmNationWide: FC<ConfirmNationWideProps> = ({
  handleNo,
  handleYes,
  open,
  modalText,
}) => {
  return (
    <ModalWrapper open={open} showCloseButton={false} wide>
      {' '}
      <div className="d-flex justify-content-center align-items-center flex-column mt-4">
        <p className="text-center  blueText">{modalText}</p>
        <div>
          <Button color="success" className="modal-button" onClick={handleNo}>
            No, do not change market
          </Button>
          <Button color="success" className="modal-button" onClick={handleYes}>
            Yes, change market and settings
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmNationWide
