export default {
  dashboard: 'dashboard',

  login: 'login',

  addParent: 'parent/add-parent',
  allParents: 'parent/all-parents',
  viewChild: 'parent/view-child',
  editParent: 'parent/edit-parent',
  cardDetail: 'parent/card-detail',
  allReservations: 'parent/all-parent-reservations',
  allParentBookings: 'parent/all-parent-bookings',

  addTutor: 'tutors/add-tutor',
  allTutors: 'tutors/all-tutors',
  editTutors: 'tutors/edit-tutor',
  pendingProfile: 'tutors/pending-profile',
  tutorBookings: 'tutors/All-tutor-bookings',

  allSessions: 'session/all-sessions',
  createSession: 'session/create-session',
  viewTutor: 'session/view-tutor',

  scheduleSession: 'schedule',

  geoFencing: 'geofencing',
  allCategories: 'category/all-category-commission',
  categoriesAllSubjects: 'category/all-subject',
  categoriesAllFee: 'category/all-fee',
  categoriesAllCancelationPolicy: 'category/all-cancelation-policy',
  categoriesAllCancelationReason: 'category/all-cancelation-reason',
  categoriesTermCondition: 'category/term-condition',
  categoriesReferralTermCondition: 'category/referal-term-condition',
  categoriesDisputeReason: 'category/dispute-reason',
  categoriesDeclineReason: 'category/decline-reason',
  categoriesRejectReason: 'category/reject-reason',
  categoriesPartnerShipCode: 'category/all-partner',
  categoriesBhExpiration: 'category/bh-expiring-list',

  promoManagement: 'promo-management/allPromocodes',

  faq: 'CMS/all-faq',
  bgCheck: 'CMS/background-check',
  contactInfo: 'CMS/contact-info',
  tutorResources: 'CMS/tutor-resources',
  parentResources: 'CMS/parent-resources',
  cmsLogin: 'CMS/cms-login',

  disputes: 'disputes/disputes',
  sessionData: 'reports/session-data',
  firstSession: 'reports/first-session',
  rejectedCanceledSession: 'reports/rejected-canceled-session',
  returningCustomers: 'reports/returning-customers',
  tutorLastAvailability: 'reports/tutor-last-availability',
  studentTutorFeedback: 'reports/student-tutor-feedback',

  allNotifications: 'all-notifications',
  addNotification: 'add-notifications',

  contacts: 'contact/contact-us',

  feedbacks: 'feedbacks',
  adminUsers: 'admin-settings/admin-list',
  apiKeys: 'admin-settings/api-keys',
}
