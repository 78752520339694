import { useEffect, useState } from 'react'

import ImageComponent from '../../../../components/image-component/ImageComponent'
import AdditionalNote from '../../../../components/view-tutor-details-components/AdditionalNote'
import BackgroundCheck from '../../../../components/view-tutor-details-components/BackgroundCheck'
import BuildProfile from '../../../../components/view-tutor-details-components/BuildProfile'
import ContactInfo from '../../../../components/view-tutor-details-components/ContactInfo'
import Education from '../../../../components/view-tutor-details-components/Education'
import Policies from '../../../../components/view-tutor-details-components/Policies'
import { useAppContext } from '../../../../contexts/AppContext'
import { SingleTutorResponse, Tutor } from '../../../../types/tutor.type'
import { api } from '../../../../utils'

const ViewTutor = () => {
  const { updateAppState } = useAppContext()
  const [singleTutor, setSingleTutor] = useState<Tutor>()

  const getSingleTutor = async () => {
    updateAppState({ loading: true })
    const res = await api.get<SingleTutorResponse>('booking/getTutorById', {
      tutorId: localStorage.getItem('tutorId'),
    })

    if (res.ok && res.data) {
      const { data } = res.data
      setSingleTutor(data)
    }
    updateAppState({ loading: false })
  }
  useEffect(() => {
    getSingleTutor()
  }, [])
  return (
    <div className="container-fluid">
      <div className="view-datail-main">
        <div className="w-100 d-flex justify-content-center flex-column align-items-center mb-1">
          <h1 className="mt-4 mb-5 text-center">Tutor Detail</h1>
          <ImageComponent profilePic={singleTutor?.profilePicture} />
        </div>
        {singleTutor ? (
          <>
            <ContactInfo singleTutor={singleTutor} />
            <Education singleTutor={singleTutor} />
            <Policies singleTutor={singleTutor} />
            <BuildProfile singleTutor={singleTutor} />
            <BackgroundCheck singleTutor={singleTutor} />
            <AdditionalNote page="session" singleTutor={singleTutor} />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default ViewTutor
