import { FC } from 'react'

import { TutorElement } from '../../types/changeRequest.type'
import { Tutor } from '../../types/tutor.type'
import { StyledRow2 } from '../custom-bordered-row/StyledRow'
import './tutor-details-components.scss'

interface ContactInfoProps {
  singleTutor?: Tutor | null | undefined
  pendingTutor?: TutorElement
  path?: string
}

const ContactInfo: FC<ContactInfoProps> = ({
  singleTutor,
  pendingTutor,
  path,
}) => {
  return (
    <div>
      <div className="heading-container row-margin">Contact Information</div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="First Name"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>{singleTutor?.firstName}</>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor?.firstName ? 'colorChange' : ''
                  }
                >
                  {pendingTutor && pendingTutor?.firstName
                    ? pendingTutor.tutor.firstName
                    : pendingTutor?.userId.firstName}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="Last Name"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>{singleTutor?.lastName}</>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor?.lastName ? 'colorChange' : ''
                  }
                >
                  {pendingTutor && pendingTutor?.lastName
                    ? pendingTutor.tutor.lastName
                    : pendingTutor?.userId.lastName}
                </span>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="User Name"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>{singleTutor?.userName}</>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.userName ? 'colorChange' : ''
                  }
                >
                  {pendingTutor && pendingTutor?.userName
                    ? pendingTutor.userName
                    : pendingTutor?.userId.userName}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="Email"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>{singleTutor?.email ? singleTutor?.email : '-'}</>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor?.email ? 'colorChange' : ''
                  }
                >
                  {pendingTutor && pendingTutor?.email
                    ? pendingTutor?.email
                    : pendingTutor?.userId.email
                    ? pendingTutor?.userId.email
                    : '-'}
                </span>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Phone Number"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>{singleTutor?.phoneNumber ? singleTutor?.phoneNumber : '-'}</>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor?.phoneNumber
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor && pendingTutor?.phoneNumber
                    ? pendingTutor.phoneNumber
                    : pendingTutor?.userId.phoneNumber
                    ? pendingTutor?.userId.phoneNumber
                    : '-'}
                </span>
              )}
            </>
          }
        />

        <StyledRow2
          field="Home Address"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {
                    <span>
                      {singleTutor?.tutor.locationDetails.addressLine1
                        ? singleTutor?.tutor.locationDetails.addressLine1
                        : '-'}
                      {singleTutor?.tutor.locationDetails.addressLine2
                        ? ', ' + singleTutor?.tutor.locationDetails.addressLine2
                        : ''}
                      {singleTutor?.tutor.locationDetails.city
                        ? ', ' + singleTutor?.tutor.locationDetails.city
                        : ''}
                      {singleTutor?.tutor.locationDetails.state
                        ? ', ' + singleTutor?.tutor.locationDetails.state
                        : ''}
                      {singleTutor?.tutor.locationDetails.zipCode
                        ? ', ' + singleTutor?.tutor.locationDetails.zipCode
                        : ''}
                    </span>
                  }
                </>
              ) : (
                <>
                  {pendingTutor?.tutor &&
                  pendingTutor?.tutor?.locationDetails ? (
                    <span className="colorChange">
                      {pendingTutor.tutor?.locationDetails.addressLine1
                        ? pendingTutor.tutor.locationDetails.addressLine1
                        : '-'}
                      {pendingTutor?.tutor?.locationDetails.addressLine2
                        ? ', ' +
                          pendingTutor?.tutor.locationDetails.addressLine2
                        : ''}
                      {pendingTutor?.tutor?.locationDetails.city
                        ? ', ' + pendingTutor?.tutor?.locationDetails.city
                        : ''}
                      {pendingTutor?.tutor?.locationDetails.state
                        ? ', ' + pendingTutor?.tutor?.locationDetails.state
                        : ''}
                      {pendingTutor?.tutor?.locationDetails.zipCode
                        ? ', ' + pendingTutor.tutor?.locationDetails.zipCode
                        : ''}
                    </span>
                  ) : (
                    <span>
                      {pendingTutor?.userId.tutor.locationDetails.addressLine1
                        ? pendingTutor?.userId.tutor.locationDetails
                            .addressLine1
                        : '-'}
                      {pendingTutor?.userId.tutor.locationDetails.addressLine2
                        ? ', ' +
                          pendingTutor?.userId.tutor.locationDetails
                            .addressLine2
                        : ''}
                      {pendingTutor?.userId.tutor.locationDetails.city
                        ? ', ' + pendingTutor?.userId.tutor.locationDetails.city
                        : ''}
                      {pendingTutor?.userId.tutor.locationDetails.state
                        ? ', ' +
                          pendingTutor?.userId.tutor.locationDetails.state
                        : ''}
                      {pendingTutor?.userId.tutor.locationDetails.zipCode
                        ? ', ' +
                          pendingTutor?.userId.tutor.locationDetails.zipCode
                        : ''}
                    </span>
                  )}
                </>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Referred by Code"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.referralCodeUsed
                    ? singleTutor?.referralCodeUsed
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId.referralCodeUsed
                    ? pendingTutor?.userId.referralCodeUsed
                    : '-'}
                </>
              )}
            </>
          }
        />
        <StyledRow2
          field={path !== 'pendingProfile' ? 'Referred By Name' : 'Referred By'}
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.referralCodeUsedId
                    ? singleTutor?.referralCodeUsedId?.firstName +
                      '' +
                      singleTutor?.referralCodeUsedId?.lastName
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId &&
                  pendingTutor?.userId.referralCodeUsedId
                    ? pendingTutor?.userId.referralCodeUsedId.firstName +
                      ' ' +
                      pendingTutor?.userId.referralCodeUsedId.lastName
                    : '-'}
                </>
              )}
            </>
          }
        />
      </div>
      {path !== 'pendingProfile' && (
        <div className="row row-margin view-detail-font">
          <StyledRow2
            field="Referral Code"
            description={
              singleTutor?.referralCode1 ? singleTutor?.referralCode1 : '-'
            }
          />
        </div>
      )}
    </div>
  )
}

export default ContactInfo
