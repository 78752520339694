import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import { ConstantResponse, Reason } from '../../../../types/constants.type'
import { api } from '../../../../utils'
import AddEditDisputeReason from './components/add-edit-dispute-reason/AddEditDisputeReason'
import DisputeReasonTable from './components/dispute-reason-table/DisputeReasonTable'

const AllDisputeReasons = () => {
  const [allDisputes, setAllDisputes] = useState<Reason[]>([])
  const [addDisputeReason, setAddDisputeReason] = useState(false)
  const getConstants = async () => {
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      const { disputeReasons } = constantsRes.data.data
      setAllDisputes(disputeReasons)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])
  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setAddDisputeReason(true)
            }}
          >
            Add Dispute Reason
          </Button>
        </div>
      </div>

      <div className="row">
        {!!allDisputes.length && (
          <DisputeReasonTable
            reasons={allDisputes}
            getConstants={getConstants}
          />
        )}
      </div>
      {addDisputeReason && (
        <AddEditDisputeReason
          open={addDisputeReason}
          onClose={() => {
            setAddDisputeReason(false)
          }}
          mode="Add"
          onSuccess={getConstants}
        />
      )}
    </div>
  )
}

export default AllDisputeReasons
