import { downloadUrl } from '@/pages/dashboard/helpers'
import { FileIdResponse, FileResponse } from '@/types/file.type'
import { isCancel } from 'apisauce'
import { CancelToken as CancelTokenType } from 'axios'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'

import { api } from './api'

export const STATES = [
  [
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arizona', 'AZ'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['District of Columbia', 'DC'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
  ],
  [['Other', 'other']],
]

export function currencyFormat(num: number) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const clearLocalStorage = () => {
  Object.keys({ ...localStorage }).forEach((key) => {
    if (key !== 'email') {
      localStorage.removeItem(key)
    }
  })
}

export const dataURLtoBlob = (dataurl: string) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  let blob = new Blob([u8arr], { type: mime })
  return blob
}

export function minTwoDigits(n: string | number): string {
  const val = parseInt(n + '')
  return (val < 10 ? '0' : '') + n
}

export const dropDownSearch = (object: string, searchedVal: string) => {
  searchedVal = searchedVal.replace(/[\-\[\]\/\{\}\(\)\*\+\?\\\^\$\|]/g, '\\$&')
  const regex = new RegExp(searchedVal.toLowerCase(), 'i')
  return !!object.match(regex)
}

export const checkDateIn120 = (date: Date) => {
  return dayjs(date).diff(dayjs(), 'days') < 120
}

export const checkNumbersOnly = (value: string) => {
  let isPassed = true
  value.split('').forEach((_val, index) => {
    const charCode = value.charCodeAt(index)
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      isPassed = false
    }
  })
  return isPassed
}

export const sortArrayOfStrings = (arr: any, key: string) => {
  return arr.sort((a: any, b: any) => a[key].localeCompare(b[key]))
}

export const downloadSessionCsv = async (
  sessionName: string,
  token: CancelTokenType,
) => {
  const res = await api.get<FileResponse>(
    `/admin/${sessionName}Report`,
    {},
    { cancelToken: token },
  )

  if (res.ok && res.data) {
    const fileId = res.data.data.url

    if (isCancel(res.originalError)) throw 'canceled'

    const result = await new Promise((resolve, reject) => {
      let interval: NodeJS.Timer,
        request = false

      interval = setInterval(async () => {
        if (!request) {
          request = true

          const res = await api.get<FileIdResponse>(
            `/admin/${sessionName}ReportUrl/${fileId}`,
            {},
            { cancelToken: token },
          )

          if (isCancel(res.originalError)) throw 'canceled'

          if (res.ok && res.data) {
            const status = res.data.data.status?.toLowerCase()

            if (status === 'complete') {
              clearInterval(interval)
              const url = res.data.data.url
              downloadUrl(url, `${sessionName}_data.csv`)
              resolve('complete')
            } else if (status === 'error') {
              toast.error("There's something wrong while downloading report.")
              clearInterval(interval)
              reject('error')
            }

            request = false
          } else {
            clearInterval(interval)
            request = false
            reject('error')
          }
        }
      }, 5000)
    })

    return result
  } else {
    throw 'error'
  }
}
