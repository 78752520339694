import { TableRow, TableCell, Button } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { Contact } from '../../hooks/contacts.types'
import ContactDetails from '../contact-details/ContactDetails'
import './contact-table.scss'

interface ContactsTableBodyI {
  contact: Contact
  getAllContacts: () => Promise<void>
}

const ContactsTableBody: FC<ContactsTableBodyI> = ({
  contact,
  getAllContacts,
}) => {
  const handleUser = () => {
    return contact.user.replace(/[_-]/g, ' ').toLowerCase()
  }
  const handleSource = () => {
    if (contact.from) {
      if (contact.from == 'PARENT') {
        return 'Parent'
      } else if (contact.from == 'TUTOR') {
        return 'Tutor'
      } else if (contact.from == 'LEAD_GEN') {
        return 'Webform'
      } else if (contact.from == 'COMING_SOON') {
        return 'Coming Soon'
      } else {
        //@ts-ignore
        return contact.from.replace(/[_-]/g, ' ').toLowerCase()
      }
    }
  }
  const [viewDetails, setViewDetails] = useState(false)
  return (
    <>
      <TableRow className="table-word-break">
        <TableCell>{contact.firstName ? contact.firstName : '-'}</TableCell>
        <TableCell>{contact.lastName ? contact.lastName : '-'}</TableCell>
        <TableCell>{contact.email ? contact.email : '-'}</TableCell>
        <TableCell>{contact.phoneNumber ? contact.phoneNumber : '-'}</TableCell>
        <TableCell>{contact.location ? contact.location : '-'}</TableCell>
        <TableCell className="text-capitalize ">
          {contact.user ? handleUser() : '-'}
        </TableCell>
        <TableCell>
          {contact.createdAt
            ? dayjs(contact.createdAt).format('MM-DD-YYYY')
            : '-'}
        </TableCell>
        <TableCell className="text-capitalize">
          {contact.from
            ? handleSource() + (contact.market ? ' ' + contact.market : '')
            : ''}
        </TableCell>
        <TableCell>
          <span
            className="d-flex flex-wrap dispute-buttons w-100 "
            style={{ paddingRight: '16px' }}
          >
            <Button
              variant="text"
              className="me-1 ms-1"
              onClick={() => {
                setViewDetails(true)
              }}
            >
              View Details
            </Button>
          </span>
        </TableCell>
      </TableRow>
      {viewDetails && (
        <ContactDetails
          open={viewDetails}
          contact={contact}
          onClose={() => {
            setViewDetails(false)
          }}
        />
      )}
    </>
  )
}

export default ContactsTableBody
