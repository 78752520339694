import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import dayjs from 'dayjs'
import { FC } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { Table } from 'reactstrap'

import './../../create-session.scss'

interface sessionTableProps {
  sessions: any
  isRateChargeSuppress: boolean
  onDelete: (id: number) => void
  timeZone: string
}

const TutorSessionsTable: FC<sessionTableProps> = ({
  sessions,
  isRateChargeSuppress,
  onDelete,
  timeZone,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            className="table-font session-table-body"
            sx={{ '&:hover': { background: 'none' } }}
          >
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>
              {!isRateChargeSuppress ? 'Promo Code' : 'Reservation'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="session-table-body">
          {sessions.map((session: any, index: number) => {
            return (
              <TableRow key={index} className="table-font session-table-body">
                <TableCell>
                  {dayjs(session.startTime).format('MM/DD/YYYY')}
                </TableCell>
                <TableCell>
                  {`${
                    dayjs(session.startTime).format('h:mm A') +
                    '-' +
                    dayjs(session.endTime).format('h:mm A')
                  } ${timeZone}`}
                </TableCell>
                {!isRateChargeSuppress ? (
                  <TableCell>{session.promocode}</TableCell>
                ) : (
                  <TableCell>{session.reservationNumber}</TableCell>
                )}
                <TableCell sx={{ border: 'none' }} className="cursor-pointer">
                  <FaTrashAlt
                    className="cursor-pointer"
                    size={15}
                    color="#000000"
                    onClick={() => {
                      onDelete(index)
                    }}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TutorSessionsTable
