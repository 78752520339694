import { FC } from 'react'

import { IMAGES } from '../../utils'
import { ProfilePicture } from './../../types/parent.type'

interface ImageComponentProps {
  profilePic?: ProfilePicture
  tempUrl?: string
  onClick?: () => void
  isChanged?: boolean
}

const ImageComponent: FC<ImageComponentProps> = ({
  profilePic,
  tempUrl,
  onClick,
  isChanged,
}) => {
  return (
    <img
      onClick={() => {
        onClick?.()
      }}
      className={`view-image cursor-pointer ${isChanged ? 'redBorder' : ''}`}
      src={
        tempUrl
          ? tempUrl
          : profilePic?.original != undefined
          ? profilePic?.thumbnail !== null
            ? profilePic?.thumbnail
            : IMAGES.DefaultProfile
          : IMAGES.DefaultProfile
      }
      alt="Default Picture"
    />
  )
}

export default ImageComponent
