import { FC } from 'react'

import '../dashboard.scss'
import { HappeningTodayData } from '../dashboard.type'

interface WhatsHappeningTodayI {
  happeningTodayData: HappeningTodayData
}

const WhatsHappeningToday: FC<WhatsHappeningTodayI> = ({
  happeningTodayData,
}) => {
  const rows = [
    {
      field: 'Sessions Requested',
      description: happeningTodayData?.sessionRequested,
    },
    {
      field: 'Sessions Completed',
      description: happeningTodayData?.sessionCompleted,
    },
    {
      field: 'New Parent Registrations',
      description: happeningTodayData?.newParentRegistration,
    },
    {
      field: 'Parent Profiles Completed',
      description: happeningTodayData?.parentProfileCompleted,
    },
    {
      field: 'New Tutor Registrations',
      description: happeningTodayData?.newTutorRegistration,
    },
    {
      field: 'Tutor Applications Submitted',
      description: happeningTodayData?.tutorsApplicationsSubmitted,
    },
  ]
  return (
    <div>
      <table className="custom-table">
        {rows.map((row) => {
          return (
            <tr key={row.field}>
              <td className="col-sm-8">{row.field}</td>
              <td className="col-sm-3">{row.description}</td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export default WhatsHappeningToday
