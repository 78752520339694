import CustomDatePicker from '@/components/date-picker/CustomDatePicker'
import ModalWrapper from '@/components/modal-wrapper/ModalWrapper'
import StyledTextfield from '@/components/styled-textfield/StyledTextfield'
import { api } from '@/utils'
import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useState } from 'react'

import ConfirmExpChanges from '../confirm-changes/ConfirmExpChanges'
import './extend-exp.scss'

interface ExtendExpI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  data: {
    reservationId: string
    parentId: string
    currentExp: Date
    reservationNum: string
  }
}

const ExtendExp: FC<ExtendExpI> = ({ open, onClose, onSuccess, data }) => {
  const [newExpDate, setNewExpDate] = useState<Dayjs | null>(null)
  const [dateError, setDateError] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const newExpDateFun = async () => {
    const formData = new FormData()
    const expDate = newExpDate?.format().split('T')[0] + 'T23:59:59.640Z'

    formData.append('newExpirationDate', expDate)
    formData.append('parentId', data.parentId)

    const result = await api.put(
      'admin/updateReservationExpirationDate/' + data.reservationId,
      formData,
    )
    if (result.ok) {
      onSuccess()
      onClose()
    }
  }

  return (
    <ModalWrapper open={open} handleClose={onClose} wide>
      <div className={`form-group row align-items-center mt-4`}>
        <div className="col-sm-5 text-left">
          <label className="add-child__label">Reservation Number</label>
        </div>
        <div className="col-sm-7">
          <StyledTextfield value={data.reservationNum} disabled />
        </div>
      </div>
      <div className={`form-group row align-items-center`}>
        <div className="col-sm-5 text-left">
          <label className="add-child__label">Current Expiration Date</label>
        </div>
        <div className="col-sm-7 disable-div">
          <CustomDatePicker
            placeholder="mm-dd-yyyy"
            date={dayjs(data.currentExp)}
            disabled
          />
        </div>
      </div>
      <div className={`form-group row align-items-center`}>
        <div className="col-sm-5 text-left">
          <label className="add-child__label">New Expiration Date</label>
        </div>
        <div className="col-sm-7">
          <CustomDatePicker
            placeholder="mm-dd-yyyy"
            date={newExpDate}
            minDate={dayjs(data.currentExp).add(1, 'days')}
            onChange={(value) => {
              setNewExpDate(value as Dayjs)
            }}
            onClear={() => {
              setNewExpDate(null)
              setDateError(true)
            }}
          />
        </div>
        {dateError && !newExpDate && (
          <div className="helper-text">New Expiration Date is required.</div>
        )}
      </div>
      <div className="form-group row pe-0">
        <div className="offset-sm-6 col-sm-5 mt-4 text-end d-flex">
          <Button className="w-100 ms-3 me-3" onClick={onClose} color="success">
            Cancel
          </Button>
          <Button
            className="w-100 ms-3 "
            onClick={() => {
              setConfirmModal(true)
            }}
            disabled={!newExpDate}
            color={newExpDate ? 'success' : 'info'}
          >
            Set New Expiration Date
          </Button>
        </div>
      </div>
      {confirmModal && (
        <ConfirmExpChanges
          open={confirmModal}
          onClose={() => {
            setConfirmModal(false)
          }}
          onYes={newExpDateFun}
        />
      )}
    </ModalWrapper>
  )
}

export default ExtendExp
