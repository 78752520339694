import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { Fee } from '../../AllFeeTable.type'
import AllFeeTableBody from './AllFeeTableBody'
import { allFeeHeadings } from './data'

interface AllFeeTableProp {
  allFee: Fee[]
  getAllFee: () => Promise<void>
}

const AllFeeTable: FC<AllFeeTableProp> = ({ allFee, getAllFee }) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              {allFeeHeadings.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {allFee.map((singleFee, index) => {
              return (
                <AllFeeTableBody
                  key={index}
                  singleFee={singleFee}
                  getAllFee={getAllFee}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AllFeeTable
