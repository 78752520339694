import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import StyledDropdown from '../../../components/styled-dropdown/StyledDropdown'
import { ConstantResponse, ContentImage } from '../../../types/constants.type'
import { IMAGES, api, dataURLtoBlob } from '../../../utils'
import './cms-login.scss'
import EditDataModal from './components/edit-data-modal/EditDataModal'

interface CMSLoginI {
  image: ContentImage
  templateTitle: string
  templateData: string
  position: 'true' | 'false'
}

const CMSLogin = () => {
  const [buttonType, setButtonType] = useState<'PARENT' | 'TUTOR'>('PARENT')
  const [parentCMS, setParentCMS] = useState<CMSLoginI>()
  const [tutorCMS, setTutorCMS] = useState<CMSLoginI>()
  const [openEditor, setOpenEditor] = useState(false)
  const [editorValue, setEditorValue] = useState('')
  const [position, setPosition] = useState('')
  const [dataType, setDataType] = useState<'title' | 'body'>('title')
  const [image, setImage] = useState<any>(IMAGES.CMSDefaultImage)

  const getConstants = async () => {
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      const {
        templateDataForDynamicRendering,
        templateDataForDynamicRenderingTutor,
        contentImageParent,
        contentImageTutor,
        contentCheckBoxParent,
        contentCheckBoxTutor,
        titleTemplateDataForDynamicRendering,
        titleTemplateDataForDynamicRenderingTutor,
      } = constantsRes.data.data
      let tempTutor: CMSLoginI = {
        image: contentImageTutor,
        position: contentCheckBoxTutor ? 'true' : 'false',
        templateData: templateDataForDynamicRenderingTutor,
        templateTitle: titleTemplateDataForDynamicRenderingTutor,
      }

      let tempParent: CMSLoginI = {
        image: contentImageParent,
        position: contentCheckBoxParent ? 'true' : 'false',
        templateData: templateDataForDynamicRendering,
        templateTitle: titleTemplateDataForDynamicRendering,
      }
      setParentCMS({ ...parentCMS, ...tempParent })
      setTutorCMS({ ...tutorCMS, ...tempTutor })
      setPosition(
        buttonType === 'PARENT'
          ? contentCheckBoxParent
            ? 'true'
            : 'false'
          : contentCheckBoxTutor
          ? 'true'
          : 'false',
      )
      setImage(
        buttonType === 'PARENT'
          ? contentImageParent.original
          : contentImageTutor.original,
      )
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  const onSelectFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])

      reader.onload = () => {
        if (reader.readyState === 2) {
          let newImage = reader.result as string
          setImage(newImage)
          setTimeout(async () => {
            let formData = new FormData()
            if (buttonType === 'PARENT')
              formData.append('contentImageParent', dataURLtoBlob(newImage))
            else formData.append('contentImageTutor', dataURLtoBlob(newImage))

            const res = await api.put('admin/updateConstants', formData)
            if (res.ok) {
              getConstants()
            }
          }, 1000)
        }
      }
    }
  }

  const handleEditorValue = (location: string) => {
    if (buttonType === 'PARENT') {
      if (location === 'title') {
        setEditorValue(parentCMS?.templateTitle as string)
      } else {
        setEditorValue(parentCMS?.templateData as string)
      }
    } else {
      if (location === 'title') {
        setEditorValue(tutorCMS?.templateTitle as string)
      } else {
        setEditorValue(tutorCMS?.templateData as string)
      }
    }
    setOpenEditor(true)
  }

  useEffect(() => {
    setPosition(
      buttonType === 'PARENT'
        ? parentCMS?.position === 'true'
          ? 'true'
          : 'false'
        : tutorCMS?.position === 'true'
        ? 'true'
        : 'false',
    )

    setImage(
      buttonType === 'PARENT'
        ? parentCMS?.image.original
        : tutorCMS?.image.original,
    )
  }, [buttonType])

  const handlePosition = async (e: any) => {
    setPosition(e.target.value)
    const formData = new FormData()
    if (buttonType === 'PARENT')
      formData.append('contentCheckBoxParent', e.target.value)
    else formData.append('contentCheckBoxTutor', e.target.value)

    const res = await api.put('admin/updateConstants', formData)
    if (res.ok) {
      getConstants()
    }
  }
  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2 align-items-end">
        <div className="col-md-7">
          <Button
            className={`upper-button ${
              buttonType === 'PARENT' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('PARENT')
            }}
          >
            Parent
          </Button>
          <Button
            className={`upper-button ${
              buttonType === 'TUTOR' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('TUTOR')
            }}
          >
            Tutor
          </Button>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <h5 className="text-center">
              <u>
                {buttonType === 'PARENT' ? 'Parent Display' : 'Tutor Display'}
              </u>
            </h5>
          </div>
        </div>
        <div className="row  mt-5 mb-2">
          <div className="col-3">
            <label htmlFor="upload-photo">
              <input
                style={{ display: 'none' }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={(e) => {
                  onSelectFile(e)
                }}
              />

              <Button variant="contained" component="span">
                Select Image
              </Button>
            </label>
          </div>
          <div className="col-9">
            <img className="image-uploaded" src={image} />
            <br />
          </div>
          <div className="col-3 mt-5">{/* <!-- <p>position:</p> --> */}</div>
          <div className="col-9 mt-5">
            <label className="label-text">position for image</label>
            <StyledDropdown
              className="width-30"
              option={[
                { id: 'true', value: 'true', option: 'top' },
                { id: 'false', value: 'false', option: 'Bottom' },
              ]}
              value={position}
              onChange={handlePosition}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-3 ">
            <Button
              onClick={() => {
                handleEditorValue('title')
                setDataType('title')
              }}
            >
              Edit Content Title
            </Button>
          </div>
          <div className="col-9">
            <div
              className="text-letter-spacing changeColorAnchor"
              dangerouslySetInnerHTML={{
                __html:
                  buttonType === 'PARENT'
                    ? (parentCMS?.templateTitle as string)
                    : (tutorCMS?.templateTitle as string),
              }}
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-3">
            <Button
              onClick={() => {
                handleEditorValue('body')
                setDataType('body')
              }}
            >
              Edit Content Body
            </Button>
          </div>
          <div className="col-9">
            <div
              className="text-letter-spacing changeColorAnchor"
              dangerouslySetInnerHTML={{
                __html:
                  buttonType === 'PARENT'
                    ? (parentCMS?.templateData as string)
                    : (tutorCMS?.templateData as string),
              }}
            />
          </div>
        </div>
      </div>
      {openEditor && (
        <EditDataModal
          key={openEditor + ''}
          open={openEditor}
          onClose={() => {
            setOpenEditor(false)
          }}
          editData={editorValue}
          onSuccess={getConstants}
          type={dataType}
          buttonType={buttonType}
        />
      )}
    </div>
  )
}

export default CMSLogin
