import { useAppContext } from '../../contexts/AppContext'
import { IMAGES } from '../../utils'
import AppendToBody from '../append-to-body/AppendToBody'
import './app-loader.scss'

interface AppLoaderIProps {
  forceShow?: boolean
}

const AppLoader: React.FC<AppLoaderIProps> = ({ forceShow }) => {
  const {
    appState: { loading },
  } = useAppContext()

  if (!loading && !forceShow) return null

  return (
    <AppendToBody>
      <div className="loader-container">
        <img src={IMAGES.AppLoader} />
      </div>
    </AppendToBody>
  )
}

export default AppLoader
