import { FormControl, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import React, { forwardRef } from 'react'

interface Options {
  _id: string
  text: string
}

interface DropdownProps {
  option?: Options[]
  placeholder?: string
  name?: string
  value?: string | ''
  className?: string | undefined
  isError?: boolean
  isTouched?: boolean
  errorMessage?: string | undefined
  onBlur?: (e: any) => void
  onChange?: ((event: any, child: React.ReactNode) => void) | undefined
}

const RejectReasons: React.FC<DropdownProps> = forwardRef(
  ({ value, className, onChange, option, placeholder }, ref) => {
    return (
      <>
        <div className={`${className}`}>
          <FormControl fullWidth>
            <Select
              inputRef={ref}
              size="small"
              className={`w-100`}
              value={value ? value : placeholder}
              onChange={onChange}
              input={<OutlinedInput />}
              MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
            >
              <MenuItem disabled value={placeholder}>
                <>{placeholder}</>
              </MenuItem>
              {option?.map((opt) => {
                return (
                  <MenuItem key={opt._id} value={opt.text}>
                    {opt.text}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
      </>
    )
  },
)

export default RejectReasons
