import { Button } from '@mui/material'
import React from 'react'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import { useAppContext } from '../../../../contexts/AppContext'
import { api } from '../../../../utils'
import { TutorModalsProps } from '../tutor-table/tutor-table.type'

interface UnRejectTutorModalProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<TutorModalsProps>>
  modalName: string
  tutorId?: string
  onSuccess?: () => void
}

const UnRejectTutorModal: React.FC<UnRejectTutorModalProps> = ({
  open,
  setOpen,
  modalName,
  tutorId,
  onSuccess,
}) => {
  const { updateAppState } = useAppContext()

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const handleYes = async () => {
    if (!tutorId) return
    updateAppState({ loading: true })
    const form = new FormData()
    form.append('action', 'UNDECLINE')
    const res = await api.put(`/admin/approveDeclineTutor/${tutorId}`, form)
    if (res.ok && res.data) {
      handleClose()
      onSuccess?.()
    }
    updateAppState({ loading: false })
  }

  return (
    <ModalWrapper
      open={open.unRejectTutor}
      handleClose={handleClose}
      title="Tutor Unreject?"
      wide
    >
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p className="text-center modal-warning">
          Are you sure you want to unreject this tutor and move him/her to the
          unapproved list?
        </p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button color="success" className="modal-button" onClick={handleYes}>
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default UnRejectTutorModal
