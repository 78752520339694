import { Button, Card } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

import { api } from '../../utils'
import {
  calendarConst,
  createDummySlots,
  getDaysInWeek,
  getTimeStops,
} from './CalendarEventServices'
import './calendar-events.scss'
import { formatedResponse } from './calendarEventsUtils'
import { timeArray } from './components/data'

const size = 30
const iconColor = '#ef6338'
const sixtyDayCheck = dayjs().add(120, 'days')

const newEndDate = dayjs().add(121, 'days')
let setAndRemoveArray: any = {
  slotArraytoSend: [],
  datesToRemove: [],
}
let selectedSlotsDay: any = []

interface CalendarEventsProps {
  eventDate?: Date | null
  setSetAndRemoveState: React.Dispatch<any>
  refresher: 'set' | 'reset'
}

const CalendarEvents: FC<CalendarEventsProps> = ({
  eventDate,
  setSetAndRemoveState,
  refresher,
}) => {
  const [state, setState] = useState<'week' | 'day'>('day')
  const [slotArray, setSlotArray] = useState<any[]>([])

  const [calendar, setCalendar] = useState<any>()

  useEffect(() => {
    return () => {
      setAndRemoveArray = {
        slotArraytoSend: [],
        datesToRemove: [],
      }
      selectedSlotsDay = []
    }
  }, [])

  useEffect(() => {
    changeViewCal(state)
  }, [eventDate, state, slotArray])

  const changeViewCal = (view: any) => {
    if (view == 'day') {
      setDayData('')
      setState('day')
    } else if (view == 'week') {
      setWeekData('')
      setState('week')
    }
  }

  const prvData = () => {
    if (calendar?.changeView == 'day') {
      setDayData('prev')
    } else if (calendar?.changeView == 'week') {
      setWeekData('prev')
    }
  }

  const nextData = () => {
    if (calendar?.changeView == 'day') {
      setDayData('next')
    } else if (calendar?.changeView == 'week') {
      setWeekData('next')
    }
  }

  const updateStatus = (date: Date) => {
    let newdummyslot = createDummySlots()
    slotArray.forEach((obj: any) => {
      if (obj.date == dayjs(date).format('YYYY-MM-DD')) {
        let index = _.findIndex(newdummyslot, {
          startTime: obj.availableSlot,
        })
        if (index != -1) {
          newdummyslot[index].status = obj.status
          newdummyslot[index]._id = obj._id
          newdummyslot[index].bookingId = obj.bookingId
        }
      }
    })

    let len = newdummyslot?.length
    let len1 = selectedSlotsDay.length
    let len2 = setAndRemoveArray.datesToRemove.length
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len1; j++) {
        if (selectedSlotsDay[j].date === dayjs(date).format('YYYY-MM-DD')) {
          if (selectedSlotsDay[j].time === newdummyslot[i].startTime) {
            newdummyslot[i].status = true
          }
        }
      }
      for (let k = 0; k < len2; k++) {
        if (setAndRemoveArray.datesToRemove[k] === newdummyslot[i]._id) {
          newdummyslot[i].status = ''
        }
      }
    }
    return newdummyslot
  }

  const setDayData = (dayFlag: String) => {
    let tempCalendar: any = {}
    tempCalendar.changeView = 'day'
    let date
    switch (dayFlag) {
      case 'prev':
        date = new Date(calendar?.currentTimeDay)
        date = new Date(date.setDate(date.getDate() - 1))
        break
      case 'next':
        date = new Date(calendar?.currentTimeDay)
        if (dayjs(date).isBefore(sixtyDayCheck, 'date'))
          date = new Date(date.setDate(date.getDate() + 1))
        break
      case 'updated':
        date = new Date(eventDate as Date)
        break
      default:
        date = eventDate ? new Date(eventDate) : new Date()
    }

    tempCalendar.currentYear = date.getFullYear()
    tempCalendar.currentMonth = calendarConst.fullMonth[date.getMonth()]
    tempCalendar.currentTimeDay = date
    tempCalendar.dayDataCal = {
      day: calendarConst.fullDay[date.getDay()],
      date: date.getDate(),
      fulldate: date,
      slot: updateStatus(date),
    }

    setCalendar({ ...tempCalendar })
    //hit api to get details
  }

  const setWeekData = (weekFlag: String) => {
    let tempCalendar: any = {}
    tempCalendar.changeView = 'week'
    let date
    switch (weekFlag) {
      case 'prev':
        date = new Date(calendar.weekDataCal[0].fulldate)
        date = new Date(date.setDate(date.getDate() - 1))
        break
      case 'next':
        date = new Date(calendar.weekDataCal[6].fulldate)
        if (dayjs(date).isBefore(sixtyDayCheck, 'date'))
          date = new Date(date.setDate(date.getDate() + 1))
        break
      default:
        date = eventDate ? new Date(eventDate) : new Date()
        weekFlag = eventDate ? 'updated' : ''
    }
    tempCalendar.currentMonthIndex = date.getMonth()
    tempCalendar.currentYear = date.getFullYear()
    tempCalendar.currentMonth = calendarConst.fullMonth[date.getMonth()]
    tempCalendar.currentMonthNext = calendarConst.fullMonth[date.getMonth()]

    let currentWeekList = getDaysInWeek(weekFlag == '' ? 1 : date)
    tempCalendar.weekDataCal = []
    for (let i = 0; i < currentWeekList.length; i++) {
      tempCalendar.weekDataCal.push({
        day: calendarConst.sortDay[i],
        date: currentWeekList[i].getDate(),
        fulldate: currentWeekList[i],
        slot: updateStatus(currentWeekList[i]),
      })
    }
    tempCalendar.currentMonth =
      calendarConst.fullMonth[currentWeekList[0].getMonth()]
    tempCalendar.currentMonthNext =
      calendarConst.fullMonth[currentWeekList[6].getMonth()]

    setCalendar({ ...tempCalendar })
    //hit api to get details
  }

  useEffect(() => {
    const getAvailability = async () => {
      const timeZone = localStorage.getItem('timeZone')
        ? localStorage.getItem('timeZone')
        : 'America/New_York'

      const res = await api.get('/tutor/getAvailability', {
        startDate: dayjs()
          .tz(timeZone as string)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
        endDate: new Date(
          newEndDate.year(),
          newEndDate.month(),
          newEndDate.date(),
          0,
          0,
          0,
        ).toUTCString(),
        tutorId: localStorage.getItem('tutorId'),
      })

      let tempSlot: any = formatedResponse(res.data)

      setSlotArray([...tempSlot])
      if (state === 'day') {
        setDayData('updated')
      } else {
        setWeekData('updated')
      }
    }

    getAvailability()
  }, [refresher])

  useEffect(() => {
    let elmnt = document.getElementById('scrollBaba')
    if (elmnt) {
      elmnt.scrollTop = 610
    }
  }, [eventDate])

  const showEvent = (item: any, data: any, index: any, allslot: any) => {
    let curruntDate = dayjs(dayjs().format())
      .tz(
        localStorage.getItem('timeZone')
          ? (localStorage.getItem('timeZone') as string)
          : 'America/New_York',
      )
      .format('YYYY-MM-DDTHH:mm:ss')
    let nowDate = new Date(curruntDate).getTime()
    let splittedTime = item.startTime.split(':')
    let timeDateRecieved = new Date(data.fulldate)

    timeDateRecieved.setHours(timeDateRecieved.getHours() + splittedTime[0])
    timeDateRecieved.setMinutes(timeDateRecieved.getMinutes() + splittedTime[1])
    if (nowDate > timeDateRecieved.getTime()) {
      return
    }
    let timeToPush = {
      time: item.startTime,
      date: dayjs(data.fulldate).format('YYYY-MM-DD'),
    }

    if (selectedSlotsDay.length) {
      let status
      let index1
      for (let i = 0, len = selectedSlotsDay.length; i < len; i++) {
        index1 = i
        if (
          selectedSlotsDay[i].time === item.startTime &&
          selectedSlotsDay[i].date === dayjs(data.fulldate).format('YYYY-MM-DD')
        ) {
          status = false
          break
        } else {
          status = true
        }
      }
      if (status) {
        selectedSlotsDay.push(timeToPush)
      } else {
        selectedSlotsDay.splice(index1, 1)
      }
    } else {
      selectedSlotsDay.push(timeToPush)
    }

    if (item._id) {
      if (item.status) {
        setAndRemoveArray.datesToRemove.push(item._id)
        item.status = !item.status
        setSetAndRemoveState({ ...setAndRemoveArray })
        return
      } else {
        setAndRemoveArray.datesToRemove.splice(
          setAndRemoveArray.datesToRemove.indexOf(item._id),
          1,
        )
        item.status = !item.status
        setSetAndRemoveState({ ...setAndRemoveArray })
        return
      }
    }
    item.status = !item.status
    let tempGetTimeStop = getTimeStops()

    let startTimeValue = tempGetTimeStop[index]
    let endTimeValue = tempGetTimeStop[(index + 1) % 48]

    let dateToCombine = dayjs(data.fulldate).format('YYYY-MM-DD')

    let tempStart = dayjs
      .tz(
        dateToCombine + ' ' + startTimeValue,
        localStorage.getItem('timeZone') as string,
      )
      .format()

    if (index == 47) {
      dateToCombine = dayjs(data.fulldate).add(1, 'days').format('YYYY-MM-DD')
    }
    let tempEnd = dayjs
      .tz(
        dateToCombine + ' ' + endTimeValue,
        localStorage.getItem('timeZone') as string,
      )
      .format()

    let start = dayjs.utc(tempStart).format('YYYY-MM-DD H:mm:ss Z')
    let end = dayjs.utc(tempEnd).format('YYYY-MM-DD H:mm:ss Z')
    let obj = { startTime: start, endTime: end }

    let newindex = _.findIndex(setAndRemoveArray.slotArraytoSend, obj)
    if (newindex == -1) {
      setAndRemoveArray.slotArraytoSend.push(obj)
    } else {
      setAndRemoveArray.slotArraytoSend.splice(newindex, 1)
    }
    setSetAndRemoveState({ ...setAndRemoveArray })
    return
  }

  return (
    <div className="d-flex justify-content-center">
      <Card className="react-calendar react-event-margin">
        <div className={`${!eventDate ? 'd-none' : ''}`}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <Button
                className="calendar-event-buttons"
                color={state === 'day' ? 'primary' : 'secondary'}
                onClick={() => {
                  changeViewCal('day')
                }}
              >
                Day
              </Button>
              <Button
                className="calendar-event-buttons"
                color={state === 'week' ? 'primary' : 'secondary'}
                onClick={() => {
                  changeViewCal('week')
                }}
              >
                Week
              </Button>
            </div>
            <div className="d-flex">
              <div className="cursor-pointer" onClick={prvData}>
                <MdNavigateBefore size={size} color={iconColor} />
              </div>
              <div className=" cursor-pointer" onClick={nextData}>
                <MdNavigateNext size={size} color={iconColor} />
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2 d-flex justify-content-center slot-date-week ">
              {state === 'day' ? (
                <>
                  {dayjs(calendar?.currentTimeDay).format(
                    'dddd, MMM. DD, YYYY',
                  )}
                </>
              ) : (
                <>
                  {calendar?.weekDataCal.map((day: any, index: number) => {
                    return (
                      <div key={index} className="m-1  week-date-heading">
                        <div>{day.date}</div>
                        <div>{day.day}</div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
            <div className="d-flex slot-selection mt-2" id="scrollBaba">
              <div>
                {timeArray.map((time, index) => {
                  return (
                    <div className="slot-selection-time" key={index}>
                      {time}
                      <br />
                    </div>
                  )
                })}
              </div>
              <div
                className={`w-100 ${state === 'week' ? 'd-flex ms-3' : ''} `}
              >
                {state === 'day' ? (
                  <>
                    {calendar?.dayDataCal?.slot?.map(
                      (time: any, index: number) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              showEvent(
                                time,
                                calendar.dayDataCal,
                                index,
                                calendar.dayDataCal.slot,
                              )
                            }}
                            className={`slot-selection-daily-slot ${
                              time.status == 'AVAILABLE' || time.status == true
                                ? 'bg-avail'
                                : time.status == 'BUSY' && !time.bookingId
                                ? 'bg-mysylvan'
                                : time.status == 'BUSY' && time.bookingId
                                ? 'bg-mysylvan-plus'
                                : 'bg-unavail'
                            }`}
                          ></div>
                        )
                      },
                    )}
                  </>
                ) : (
                  <>
                    {calendar?.weekDataCal?.map((day: any, index: number) => {
                      return (
                        <div key={index}>
                          {day?.slot?.map((time: any, index: number) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  showEvent(time, day, index, day.slot)
                                }}
                                className={`slot-selection-weekly-slot ${
                                  time.status == 'AVAILABLE' ||
                                  time.status == true
                                    ? 'bg-avail'
                                    : time.status == 'BUSY' && !time.bookingId
                                    ? 'bg-mysylvan'
                                    : time.status == 'BUSY' && time.bookingId
                                    ? 'bg-mysylvan-plus'
                                    : 'bg-unavail'
                                }`}
                              ></div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            !eventDate ? 'bottom-title-height' : ''
          } bottom-title mt-2`}
        >
          <div className="d-flex justify-content-end">
            <span className="status-circle-avail">Available</span>
            <span className="status-circle-sylvan-plus">
              Booked in mySylvan+
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <span className="status-circle-unavail">Unvailable</span>
            <span className="status-circle-sylvan">Booked in mySylvan</span>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default CalendarEvents
