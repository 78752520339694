import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaTrashAlt } from 'react-icons/fa'
import * as Yup from 'yup'

import CustomDatePicker from '../../../../components/date-picker/CustomDatePicker'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { Student } from '../../../../types/child.type'
import { api } from '../../../../utils'
import { gender, grade } from '../data'
import { ModalProps } from '../parent-table/ParentTable'
import './add-child.scss'

interface AddChildProps {
  open?: ModalProps
  setOpen?: React.Dispatch<React.SetStateAction<ModalProps>>
  modalName?: string
  parentId?: string
  student?: Student
  getChild?: () => Promise<void | undefined> | undefined
  mode?: 'add' | 'edit'
  modedModal?: boolean
  setModedModal?: React.Dispatch<React.SetStateAction<boolean>>
}

interface FormValues {
  firstName?: string
  lastName?: string
  school?: string
  gender?: string
  grade?: string
  dob?: Dayjs
}

const schema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('First Name is required'),
  school: Yup.string(),
  gender: Yup.string().required('Gender is required'),
  grade: Yup.string().required('Grade is required'),
}).required()

const AddChild: FC<AddChildProps> = ({
  open,
  setOpen,
  modalName,
  parentId,
  mode,
  getChild,
  student,
  modedModal,
  setModedModal,
}) => {
  const { updateAppState } = useAppContext()
  const defaultValues: FormValues = {
    firstName: mode === 'edit' ? student?.firstName : '',
    lastName: mode === 'edit' ? student?.lastName : '',
    gender: mode === 'edit' ? student?.gender : '',
    grade: mode === 'edit' ? student?.gradeLevel : '',
    school:
      mode === 'edit' && student?.school ? (student?.school as string) : '',
  }
  const [dob, setDob] = useState<Dayjs | null>(
    mode === 'edit' && student?.dob ? dayjs(student?.dob) : null,
  )
  const [files, setFiles] = useState<File[]>([])
  const [propFiles, setPropFiles] = useState(
    mode === 'edit' ? student?.academicReports : [],
  )
  const [deletedReports, setDeletedReports] = useState<any[]>([])

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const resetter = () => {
    setValue('firstName', '')
    setValue('lastName', '')
    setValue('gender', '')
    setValue('grade', '')
    setValue('school', '')
    setDob(null)
    setFiles([])
    setPropFiles([])
  }
  const [formMode, setFormMode] = useState<'new' | 'close' | 'save'>()

  const handleClose = () => {
    if (mode === 'edit' && setModedModal) {
      setModedModal(false)
    }
    if (setOpen) {
      setOpen({ ...open, [modalName ? modalName : '']: false })
    }
  }

  var tempFile = files
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      tempFile[tempFile.length] = e.target.files[0]
      setFiles([...tempFile])
    }
  }

  const deleteFile = (i: number, arrayName: 'tempFile' | 'propFiles') => {
    if (tempFile && propFiles) {
      if (arrayName === 'tempFile') {
        setFiles(tempFile?.filter((_file, index) => index !== i))
      } else {
        setPropFiles(propFiles?.filter((_file, index) => index !== i))
        setDeletedReports(propFiles?.filter((_file, index) => index === i))
      }
    }
  }

  const onSubmit = async (values: FormValues) => {
    updateAppState({ loading: true })
    let formData = new FormData()
    if (!dob) return
    var today = new Date()
    const { firstName, lastName, gender, grade, school } = values
    formData.append('firstName', firstName as string)
    formData.append('lastName', lastName as string)
    formData.append('gender', gender as string)
    formData.append('dob', dayjs(dob).format().split('T')[0] + 'T00:00:00.000Z')
    formData.append('age', dayjs(today).diff(dob, 'years').toString())
    formData.append('gradeLevel', grade as string)
    formData.append('school', school as string)
    for (let i = 0; i < files.length; i++) {
      formData.append('academicReports', files[i])
    }

    let res
    if (mode === 'edit') {
      let tempArray = []
      for (let i = 0; i < deletedReports.length; i++) {
        tempArray.push(deletedReports[i]?._id)
      }
      formData.append('academicReportsIdsToDelete', JSON.stringify(tempArray))
      res = await api.put(`/parent/student/${student?._id}`, formData)
    } else {
      formData.append('parentId', parentId as string)
      res = await api.post(`/parent/student`, formData)
    }

    const { data, status } = res
    if (res.ok) {
      if (mode === 'edit' && getChild) {
        getChild()
        handleClose()
      } else if (mode === 'add' && formMode === 'close') {
        handleClose()
      } else if (mode === 'add' && formMode === 'new') {
        resetter()
      }
    }

    updateAppState({ loading: false })
  }

  return (
    <ModalWrapper
      open={mode !== 'edit' ? open?.addChild : modedModal}
      handleClose={handleClose}
      wide
      title={mode === 'edit' ? 'Edit Child' : 'Add Child'}
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <form className="w-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">First Name</label>
              </div>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="First Name"
                    isError={!!errors.firstName}
                    className="col-sm-8"
                    errorMessage={errors.firstName?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">Last Name</label>
              </div>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Last Name"
                    isError={!!errors.lastName}
                    className="col-sm-8"
                    errorMessage={errors.lastName?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">DOB</label>
              </div>
              <div className="col-sm-8">
                <CustomDatePicker
                  date={dob}
                  isDob
                  placeholder="mm-dd-yyyy"
                  onChange={(value) => {
                    value && setDob(value)
                  }}
                  onClear={() => setDob(null)}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">Gender</label>
              </div>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <StyledDropdown
                    {...field}
                    option={gender}
                    placeholder="---Select Gender---"
                    isError={!!errors.gender}
                    className="col-sm-8"
                    errorMessage={errors.gender?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">Grade</label>
              </div>
              <Controller
                name="grade"
                control={control}
                render={({ field }) => (
                  <StyledDropdown
                    {...field}
                    option={grade}
                    placeholder="---Select Grade---"
                    isError={!!errors.grade}
                    className="col-sm-8"
                    errorMessage={errors.grade?.message?.toString()}
                  />
                )}
              />
            </div>
            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">Academic Reports</label>
              </div>
              <div className="col-sm-8">
                <div className="file-border p-1 ">
                  <input
                    type="file"
                    value={''}
                    onChange={(e) => handleFile(e)}
                  />
                </div>

                {propFiles?.map((file, index) => {
                  return (
                    <div
                      key={file.name}
                      className="d-flex justify-content-between align-items-center modal-docs offset-1"
                    >
                      {file.name}
                      <span className="me-1">
                        <FaTrashAlt
                          size={18}
                          color="grey"
                          onClick={() => {
                            deleteFile(index, 'propFiles')
                          }}
                        />
                      </span>
                    </div>
                  )
                })}
                {files.map((file, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center modal-docs offset-1"
                    >
                      {file.name ? file.name : ''}
                      <span className="me-1">
                        <FaTrashAlt
                          size={18}
                          color="grey"
                          onClick={() => {
                            deleteFile(index, 'tempFile')
                          }}
                        />
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label className="add-child__label">School</label>
              </div>
              <Controller
                name="school"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter School Name"
                    isError={!!errors.school}
                    className="col-sm-8"
                    errorMessage={errors.school?.message?.toString()}
                  />
                )}
              />
            </div>
            {mode === 'edit' ? (
              <div className="row">
                <div className="offset-sm-8 col-sm-4  button-margin">
                  <Button
                    disabled={!isValid || !dob}
                    className="w-100"
                    type="submit"
                    color={!isValid || !dob ? 'info' : 'success'}
                    onClick={(e) => {
                      setFormMode('save')
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div className="row offset-sm-4">
                <div className="offset-sm-4 col-sm-4  button-margin">
                  <Button
                    disabled={!isValid || !dob}
                    className="w-100"
                    type="submit"
                    color={!isValid || !dob ? 'info' : 'success'}
                    onClick={(e) => {
                      setFormMode('new')
                    }}
                  >
                    Add and New
                  </Button>
                </div>
                <div className="col-sm-4   button-margin">
                  <Button
                    disabled={!isValid || !dob}
                    className="w-100"
                    type="submit"
                    color={!isValid || !dob ? 'info' : 'success'}
                    onClick={(e) => {
                      setFormMode('close')
                    }}
                  >
                    Add and Close
                  </Button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddChild
