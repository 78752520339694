import { TableRow, TableCell, Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'

import ConfirmDelete from '../../../../../../components/confirm-delete/ConfirmDelete'
import { CancellationReason } from '../../../../../../types/constants.type'
import { api } from '../../../../../../utils'
import AddEditCancellationReason from '../add-edit-cancellation-reason/AddEditCancellationReason'

interface CancellationTableBodyI {
  reason: CancellationReason
  getConstants: () => Promise<void>
  reasonType: 'PARENT' | 'TUTOR'
}
const size = 15

const CancellationTableBody: FC<CancellationTableBodyI> = ({
  reason,
  getConstants,
  reasonType,
}) => {
  const [editReason, setEditReason] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const labelSetter = (label: string) => {
    if (label === 'OTHER') return 'Show'
    if (label === 'NO_SHOW') return 'No Show'
  }

  const deleteReason = async () => {
    const res = await api.delete(
      `admin/deleteCancellationReason/${reason._id}`,
      {},
      { data: { type: reasonType } },
    )
    if (res.ok) {
      setConfirmDelete(false)
      getConstants()
    }
  }
  return (
    <>
      <TableRow>
        <TableCell>{reason.text}</TableCell>
        <TableCell>{labelSetter(reason.label)}</TableCell>
        <TableCell>
          {' '}
          <span className="d-flex align-items-center">
            <Tooltip title="Delete">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={() => {
                  setConfirmDelete(true)
                }}
              >
                <FaTrashAlt size={size} />
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={() => {
                  setEditReason(true)
                }}
              >
                <FaPencilAlt size={size} />
              </span>
            </Tooltip>
          </span>
        </TableCell>
      </TableRow>
      {editReason && (
        <AddEditCancellationReason
          reasonType={reasonType}
          open={editReason}
          editReason={reason}
          onClose={() => {
            setEditReason(false)
          }}
          onSuccess={getConstants}
          mode="Edit"
        />
      )}
      {confirmDelete && (
        <ConfirmDelete
          open={confirmDelete}
          onClose={() => {
            setConfirmDelete(false)
          }}
          text="the cancelation reason"
          title="Cancelation Reason"
          onYes={deleteReason}
        />
      )}
    </>
  )
}

export default CancellationTableBody
