import { useAppContext } from '@/contexts/AppContext'
import { api } from '@/utils'
import { useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import ImageComponent from '../../../../components/image-component/ImageComponent'
import { Parent, ParentReq } from '../../../../types/parent.type'
import { ParamType } from '../../AllParents'
import useAllParents from '../get-all-parents/GetAllParents'
import AddressForm from './components/address-form/AddressForm'
import AdjustImage from './components/adjust-image/AdjustImage'
import DetailForm from './components/detail-form/DetailForm'
import SelectImage from './components/select-image/SelectImage'
import './edit-parent.scss'

const EditParent = () => {
  const navigate = useNavigate()
  const { updateAppState } = useAppContext()
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [imageBase64, setImageBase64] = useState('')
  const [selectImageUrl, setSelectImageUrl] = useState('')
  const [singleParent, setSingleParent] = useState<Parent | null | undefined>()
  const [pageState, setPageState] = useState(true)
  const [profileModal, setProfileModal] = useState(false)

  const parentId = localStorage.getItem('parentId') as string
  const getSingleParent = async () => {
    updateAppState({ loading: true })
    const singleParentReq = await api.get<ParentReq>(
      'admin/getParentById/' + parentId,
    )
    const { data } = singleParentReq
    if (data) {
      setSingleParent(data.data)
    }
    updateAppState({ loading: false })
  }
  useEffect(() => {
    getSingleParent()
  }, [])

  return (
    <>
      <div className="container main-div">
        <div className="view-child-header">
          <div className="d-flex">
            <div className="col-sm-1 ms-5">
              <FaArrowLeft
                size={25}
                className="back-icon"
                onClick={() => {
                  pageState ? navigate(-1) : setPageState(true)
                }}
              />
            </div>
            <div className="col-sm-9 text-center">
              <h1 className="child-heading">Edit Parent</h1>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <ImageComponent
            onClick={() => {
              setProfileModal(true)
            }}
            profilePic={singleParent?.profilePicture}
            tempUrl={selectImageUrl}
          />
        </div>
        {pageState ? (
          <>
            {!!singleParent && (
              <DetailForm
                singleParent={singleParent}
                setPageState={setPageState}
                imageBase64={imageBase64}
                getSingleParent={getSingleParent}
              />
            )}
          </>
        ) : (
          <>
            {!!singleParent && (
              <AddressForm
                getSingleParent={getSingleParent}
                singleParent={singleParent}
                imageBase64={imageBase64}
              />
            )}
          </>
        )}
      </div>
      {profileModal && (
        <SelectImage
          open={profileModal}
          setOpen={setProfileModal}
          setSelectedImage={setSelectedImage}
        />
      )}
      {!!selectedImage && (
        <AdjustImage
          open={!!selectedImage}
          setOpen={setSelectedImage}
          selectedImage={selectedImage}
          setSelectImageUrl={setSelectImageUrl}
          setImageBase64={setImageBase64}
        />
      )}
    </>
  )
}

export default EditParent
