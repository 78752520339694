import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Radio, RadioGroup } from '@mui/material'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../utils'
import { CONSTANT } from '../../../../utils/constants'
import { Disputes } from '../../hooks/disputes.types'

interface ResolveDisputesI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  dispute: Disputes
}

interface FormValues {
  method?: string
  amountToCharge?: string
  comment?: string
  isSessionValid?: boolean | null
}
const defaultValues: FormValues = {
  method: '',
  amountToCharge: '',
  comment: '',
  isSessionValid: null,
}

const schema = Yup.object({
  method: Yup.string().required('Method is required.'),
  amountToCharge: Yup.string().required('Amount to charge is required.'),
  comment: Yup.string().required('Comment is required'),
}).required()

const ResolveDisputes: FC<ResolveDisputesI> = ({
  onClose,
  open,
  dispute,
  onSuccess,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  })

  const isButtonValid = () => {
    const { amountToCharge, isSessionValid } = watch()

    if (dispute.bookingId.isBookingUseCredits) {
      return !!(isValid && isSessionValid != null)
    } else return !!(isValid && amountToCharge)
  }

  const handleResolve = async () => {
    const { method, comment, amountToCharge, isSessionValid } = watch()
    const formData = new FormData()
    formData.append('method', method as string)
    if (dispute.bookingId.isBookingUseCredits) {
      if (isSessionValid != null)
        formData.append('isSessionValid', isSessionValid.toString())
    }
    formData.append('amountToCharge', amountToCharge as string)
    formData.append('comment', comment as string)
    if (dispute) formData.append('disputeId', dispute._id as string)

    const res = await api.post('admin/resolveDispute', formData)
    if (res.ok) {
      onSuccess()
      onClose()
    }
  }
  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="Resolve Dispute"
      wide
    >
      <div className="form-group row">
        <div className="col-sm-4 text-left">
          <label className="add-child__label">Method</label>
        </div>
        <Controller
          name="method"
          control={control}
          render={({ field }) => (
            <StyledTextfield
              {...field}
              placeholder="Enter Method"
              isError={!!errors.method}
              className="col-sm-8"
            />
          )}
        />
        {!!errors.method && (
          <div className="helper-text">
            {errors.method?.message?.toString()}
          </div>
        )}
      </div>
      <div className="form-group row align-items-start">
        <div className="col-sm-4 text-left">
          <label
            className={`add-child__label ${
              dispute.bookingId.isBookingUseCredits ? 'mt-2' : 'mt-1'
            }`}
          >
            Amount to Charge
          </label>
        </div>
        {dispute.bookingId.isBookingUseCredits ? (
          <>
            <div className="col-sm-8">
              <Controller
                control={control}
                name="isSessionValid"
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={field.value?.toString() || ''}
                    onChange={(e) => {
                      setValue('isSessionValid', e.target.value === 'true')
                      setValue('amountToCharge', '0')
                    }}
                  >
                    <div className="row align-items-center ps-2">
                      <div className="col-sm-12 ps-1">
                        <Radio value="true" size="small" className="p-0 pe-3" />
                        <span className="admin-radio">
                          Session Valid – use tutoring hour and pay tuto
                        </span>
                      </div>
                      <div className="col-sm-12 ps-1">
                        <Radio
                          value="false"
                          size="small"
                          className="p-0 pe-3"
                        />
                        <span className="admin-radio">
                          Session Invalid – return tutoring hour to parent and
                          do not pay tutor
                        </span>
                      </div>
                    </div>
                  </RadioGroup>
                )}
              />
            </div>
          </>
        ) : (
          <>
            <Controller
              name="amountToCharge"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  onChange={(e) => {
                    if (
                      e.target.value === '' ||
                      CONSTANT.numberRegex.test(e.target.value)
                    ) {
                      field.onChange(e)
                    }
                  }}
                  placeholder="Enter amount to charge"
                  isError={!!errors.amountToCharge}
                  className="col-sm-8"
                />
              )}
            />
            {!!errors.amountToCharge && (
              <div className="helper-text">
                {errors.amountToCharge?.message?.toString()}
              </div>
            )}
          </>
        )}
      </div>
      <div className="form-group row align-items-baseline">
        <div className="col-sm-4 text-left">
          <label className="add-child__label">Comment</label>
        </div>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <StyledTextfield
              {...field}
              multiline
              rows={4}
              placeholder="Enter Comment"
              isError={!!errors.comment}
              className="col-sm-8"
            />
          )}
        />
        {!!errors.comment && (
          <div className="helper-text">
            {errors.comment?.message?.toString()}
          </div>
        )}
      </div>
      <div className="form-group row">
        <div className="offset-sm-9 col-sm-3 mt-5">
          <Button
            className="w-100"
            disabled={!isButtonValid()}
            color={isButtonValid() ? 'success' : 'info'}
            onClick={handleResolve}
          >
            Add
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ResolveDisputes
