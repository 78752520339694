import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import './keep-sessions-modal.scss'

interface KeepSessionsModalProps {
  open: boolean
  onClose: () => void
  partnershipCode: string
  onCancelSessions: () => void
}
const KeepSessionsModal: FC<KeepSessionsModalProps> = ({
  open,
  onClose,
  partnershipCode,
  onCancelSessions,
}) => {
  const handleClose = () => {
    onClose()
  }
  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p className="text-center">{`This Parent has upcoming sessions associated with code ${partnershipCode}. Would you like to cancel these sessions?`}</p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            Keep Sessions
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={onCancelSessions}
          >
            Cancel Sessions
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default KeepSessionsModal
