import { FormControl, Select, SelectChangeEvent } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import React, { forwardRef } from 'react'

import { Markets } from '../../types/market.type'
import './dropdown.scss'

interface DropdownProps {
  option?: Markets[] | null
  placeholder?: string
  value?: string | null | undefined
  className?: string | undefined
  handleChange?: (event: SelectChangeEvent) => any | undefined
  disabled?: boolean
  excludeNone?: boolean
  noPlaceholder?: boolean
  selectablePlaceholder?: boolean
}
const MarketDropdown: React.FC<DropdownProps> = forwardRef(
  (
    {
      value,
      className,
      option,
      placeholder,
      handleChange,
      disabled,
      excludeNone,
      noPlaceholder,
      selectablePlaceholder,
    },
    ref,
  ) => {
    const disabledClass = disabled ? 'disabled-class' : ''
    return (
      <div className={`${className}`}>
        <FormControl fullWidth>
          <Select
            disabled={disabled}
            inputRef={ref}
            size="small"
            className={`w-100`}
            value={value ? value : placeholder}
            onChange={handleChange}
            input={<OutlinedInput />}
            inputProps={{
              className: disabledClass,
            }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
          >
            <MenuItem
              disabled={!selectablePlaceholder}
              value={placeholder || ''}
              className={noPlaceholder ? 'd-none' : ''}
            >
              <>{placeholder}</>
            </MenuItem>
            {!excludeNone && (
              <MenuItem value="none">
                <>None</>
              </MenuItem>
            )}
            {option?.map((opt) => {
              return (
                <MenuItem key={opt._id} value={opt._id}>
                  {opt.marketName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  },
)

export default MarketDropdown
