import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import { api } from '../../../utils'
import { APIKeyRes, APIKeys } from './apiKeys.type'
import AddAPIKey from './components/add-api-key/AddAPIKey'
import ApiKeyTable from './components/api-keys-table/ApiKeyTable'
import KeyExist from './components/key-exist/KeyExist'

const ApiKeys = () => {
  const [allApiKeys, setAllApiKeys] = useState<APIKeys[]>([])
  const [addApiKey, setAddApiKey] = useState(false)
  const [resourceExist, setResourceExist] = useState(false)

  const getKeys = async () => {
    const res = await api.get<APIKeyRes>('admin/keys')
    if (res.data && res.ok) {
      const { data } = res.data
      setAllApiKeys(data)
    }
  }

  useEffect(() => {
    getKeys()
  }, [])
  return (
    <div className="container">
      {' '}
      <div className="row align-items-center">
        <div className="col-lg-3 mt-4">
          <h3 className="page-heading">All API Keys</h3>
        </div>
        <div className="col-sm-9 col-md-9  text-end">
          <Button
            onClick={() => {
              setAddApiKey(true)
            }}
          >
            Add Key
          </Button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-12">
          <ApiKeyTable allKeys={allApiKeys} getAllKeys={getKeys} />
        </div>
      </div>
      {addApiKey && (
        <AddAPIKey
          open={addApiKey}
          onClose={() => {
            setAddApiKey(false)
          }}
          onSuccess={getKeys}
          onError={() => {
            setResourceExist(true)
          }}
        />
      )}
      {resourceExist && (
        <KeyExist
          open={resourceExist}
          onClose={() => {
            setAddApiKey(true)
            setResourceExist(false)
          }}
        />
      )}
    </div>
  )
}

export default ApiKeys
