import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import CustomDatePicker from '../../../../components/date-picker/CustomDatePicker'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../components/styled-dropdown/StyledDropdown'
import StyledReactSelect, {
  SelectObjectVal,
} from '../../../../components/styled-react-select/StyledReactSelect'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { api } from '../../../../utils'
import { CONSTANT } from '../../../../utils/constants'
import { Promocode } from '../../allPromos.types'

interface AddEditPromoI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  mode: 'Add' | 'Edit'
  editPromo?: Promocode
}

interface NewPromoI {
  code: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  usePerParent: string
  totalUsage: string
  marketName: SelectObjectVal[]
  creditType: string
  credits: string
}

const defaultPromo: NewPromoI = {
  code: '',
  startDate: null,
  endDate: null,
  usePerParent: '',
  totalUsage: '',
  marketName: [],
  creditType: '',
  credits: '',
}

const schema = Yup.object({
  code: Yup.string()
    .required('Promo code name is required.')
    .matches(/^[a-zA-Z0-9-_]*$/, 'Promo code name is not valid.'),
  usePerParent: Yup.string()
    .required('Time of use per parent is required.')
    .matches(
      /^[0-9]*[1-9][0-9]*$/,
      'Time of use per parent must be greater than zero.',
    ),

  totalUsage: Yup.string()
    .required('Total usages is required.')
    .matches(/^[0-9]*[1-9][0-9]*$/, 'Total usages  must be greater than zero.'),
  creditType: Yup.string().required(),
  credits: Yup.string()
    .required('Credits is required.')
    .matches(/^[0-9]*[1-9][0-9]*$/, 'Credits must be greater than zero.'),
}).required()

const AddEditPromo: FC<AddEditPromoI> = ({
  mode,
  editPromo,
  onClose,
  onSuccess,
  open,
}) => {
  const { marketData } = useAppContext()
  const [startDateError, setStartDateError] = useState(false)
  const [endDateError, setEndDateError] = useState(false)
  const [startDatePast, setStartDatePast] = useState(false)
  const [endDatePast, setEndDatePast] = useState(false)

  let tempStartDate = new Date()
  let disableUntilEndDate = new Date()
  let currentDate = new Date()

  const {
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<NewPromoI>({
    resolver: yupResolver(schema),
    defaultValues: defaultPromo,
    mode: 'all',
  })

  const values = watch()

  useEffect(() => {
    if (mode === 'Edit' && editPromo) {
      setValue('code', editPromo.promocode)
      setValue('creditType', editPromo.type)
      setValue('credits', editPromo.credits.toString())
      setValue('startDate', dayjs(editPromo.startDate).utc())
      setValue('endDate', dayjs(editPromo.endDate).utc())
      setValue('usePerParent', editPromo.perUserCount.toString())
      setValue('totalUsage', editPromo.maxCount.toString())

      const tempMarket = editPromo.marketIds.map((market) => {
        return { id: market._id, value: market._id, label: market.marketName }
      })
      setValue('marketName', tempMarket)

      tempStartDate = new Date(editPromo.startDate)
      let d1 = new Date()
      let d2 = new Date()

      let endDate1 = new Date(editPromo.endDate)
      d1.setFullYear(endDate1.getFullYear())
      d1.setDate(endDate1.getDate())
      d1.setMonth(endDate1.getMonth())
      d2.setFullYear(tempStartDate.getFullYear())
      d2.setDate(tempStartDate.getDate())
      d2.setMonth(tempStartDate.getMonth())
      d2.setHours(0)
      d2.setMinutes(0)
      d2.setSeconds(0)

      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
      )

      if (d2 >= currentDate) {
        setStartDatePast(false)
        disableUntilEndDate = tempStartDate
      } else {
        setStartDatePast(true)
        disableUntilEndDate = currentDate
      }
      if (d1 >= currentDate) {
        setEndDatePast(false)
      } else {
        setEndDatePast(true)
      }
    }
  }, [])

  const creditTypeArray = [
    { id: 'FLAT', value: 'FLAT', option: 'Flat' },
    { id: 'PERCENT', value: 'PERCENT', option: 'Percent' },
  ]

  const isDisabled =
    (startDateError === false && !values.startDate) || endDatePast
      ? 'div-disabled'
      : ''

  const handlePromoManagement = async () => {
    const {
      code,
      creditType,
      credits,
      endDate,
      marketName,
      startDate,
      totalUsage,
      usePerParent,
    } = values

    let tempStart = startDate?.format().split('T')[0] + 'T00:00:00.000Z'
    let tempEnd = endDate?.format().split('T')[0] + 'T23:59:59.999Z'

    const tempMarkets = marketName.map((market) => market.id)

    const formData = new FormData()
    formData.append('marketIds', JSON.stringify(tempMarkets))
    formData.append('promocode', code)
    formData.append('startDate', tempStart)
    formData.append('endDate', tempEnd)
    formData.append('credits', credits)
    formData.append('type', creditType)
    formData.append('maxCount', totalUsage)
    formData.append('perUserCount', usePerParent)

    let res
    if (mode === 'Add') {
      res = await api.post('admin/addPromocode', formData)
    } else {
      res = await api.put(`admin/updatePromocode/${editPromo?._id}`, formData)
    }
    if (res && res.ok) {
      onClose()
      onSuccess()
    }
  }

  const isButtonValid = () => {
    const {
      startDate,
      endDate,
      marketName,
      totalUsage,
      usePerParent,
      creditType,
      credits,
    } = values

    let credit = true
    if (creditType === 'PERCENT' && parseInt(credits) > 100) {
      credit = false
    }
    return !!(
      isValid &&
      startDate &&
      endDate &&
      marketName.length &&
      parseInt(totalUsage) >= parseInt(usePerParent) &&
      credit
    )
  }

  return (
    <ModalWrapper
      wide
      open={open}
      handleClose={onClose}
      title={mode + ' Promo Code'}
    >
      <div className="row">
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">Promo Code Name</label>
          </div>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Promo Code Name"
                isError={!!errors.code}
                className="col-sm-8"
                max={20}
              />
            )}
          />
          {errors.code?.message && (
            <div className="helper-text">
              {errors.code?.message?.toString()}
            </div>
          )}
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">Start Date</label>
          </div>
          <div className={`col-sm-8 ${startDatePast ? 'div-disabled' : ''}`}>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  placeholder="mm-dd-yyyy"
                  minDate={
                    mode === 'Add'
                      ? dayjs()
                      : dayjs(tempStartDate.toISOString())
                  }
                  date={field.value}
                  maxDate={values.endDate}
                  onChange={(value) => {
                    setValue('startDate', value as Dayjs)
                  }}
                  onClear={() => {
                    setValue('startDate', null)
                    setStartDateError(true)
                  }}
                />
              )}
            />
          </div>
          {!values.startDate && startDateError && (
            <div className="helper-text">Start Date is required</div>
          )}
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">End Date</label>
          </div>
          <div className={`col-sm-8 ${isDisabled}`}>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  placeholder="mm-dd-yyyy"
                  date={field.value}
                  minDate={
                    mode === 'Add'
                      ? values.startDate
                      : dayjs(disableUntilEndDate.toISOString()) &&
                        values.startDate
                  }
                  onChange={(value) => {
                    setValue('endDate', value as Dayjs)
                  }}
                  onClear={() => {
                    setValue('endDate', null)
                    setEndDateError(true)
                  }}
                />
              )}
            />
          </div>
          {!values.endDate && endDateError && (
            <div className="helper-text">End Date is required</div>
          )}
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">Time of Use Per Parent</label>
          </div>
          <Controller
            name="usePerParent"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Time of Use Per Parent"
                onChange={(e) => {
                  if (
                    CONSTANT.numberRegex.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    field.onChange(e)
                  }
                }}
                isError={!!errors.usePerParent}
                className="col-sm-8"
                max={6}
                min={1}
              />
            )}
          />
          {errors.usePerParent?.message && (
            <div className="helper-text">
              {errors.usePerParent?.message?.toString()}
            </div>
          )}
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">Total Usages</label>
          </div>
          <Controller
            name="totalUsage"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Total Usages"
                onChange={(e) => {
                  if (
                    CONSTANT.numberRegex.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    field.onChange(e)
                  }
                }}
                isError={!!errors.totalUsage}
                className="col-sm-8"
                max={6}
              />
            )}
          />
          {errors.totalUsage?.message && (
            <div className="helper-text">
              {errors.totalUsage?.message?.toString()}
            </div>
          )}
          {values.totalUsage &&
            parseInt(values.totalUsage) < parseInt(values.usePerParent) && (
              <div className="helper-text">
                Total usages must be greater than or equal to time of use per
                parent.
              </div>
            )}
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">Market Name</label>
          </div>
          <Controller
            name="marketName"
            control={control}
            render={({ field }) => (
              <StyledReactSelect
                value={field.value}
                options={marketData?.map((market) => {
                  return {
                    id: market._id,
                    label: market.marketName,
                    value: market._id,
                  }
                })}
                onChange={(value) => {
                  setValue('marketName', value)
                }}
                classNamePrefix="react-select"
                className="col-sm-8 "
                placeholder="---Select Market Name---"
              />
            )}
          />
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">Credits Type</label>
          </div>
          <Controller
            name="creditType"
            control={control}
            render={({ field }) => (
              <StyledDropdown
                {...field}
                placeholder="--Select Credit Type--"
                option={creditTypeArray}
                isError={!!errors.creditType}
                className="col-sm-8"
              />
            )}
          />
          {errors.creditType?.message && (
            <div className="helper-text">
              {errors.creditType?.message?.toString()}
            </div>
          )}
        </div>
        <div className={`form-group row align-items-center`}>
          <div className="col-sm-4 text-left">
            <label className="add-child__label ">Credits</label>
          </div>
          <Controller
            name="credits"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Credits"
                onChange={(e) => {
                  if (
                    CONSTANT.numberRegex.test(e.target.value) ||
                    e.target.value === ''
                  ) {
                    field.onChange(e)
                  }
                }}
                isError={
                  !!(
                    errors.credits ||
                    (values.credits &&
                      values.creditType === 'PERCENT' &&
                      parseInt(values.credits) > 100)
                  )
                }
                className="col-sm-8"
                max={25}
              />
            )}
          />
          {errors.credits?.message && (
            <div className="helper-text">
              {errors.credits?.message?.toString()}
            </div>
          )}
          {values.credits &&
            values.creditType === 'PERCENT' &&
            parseInt(values.credits) > 100 && (
              <div className="helper-text">
                Credits must be greater than zero or less than 100.
              </div>
            )}
        </div>
        <div className="form-group row ps-1 mt-5">
          <div className=" col-sm-8 offset-sm-4 text-center ps-3">
            <Button
              className="w-100"
              color={isButtonValid() ? 'success' : 'info'}
              disabled={!isButtonValid()}
              onClick={handlePromoManagement}
            >
              {mode === 'Add' ? 'Add Promo Code' : 'Save Promo Code'}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditPromo
