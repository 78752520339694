import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
} from '@mui/material'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import {
  FaCheck,
  FaLock,
  FaPencilAlt,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTimes,
  FaTrashAlt,
  FaUndo,
  FaUnlock,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import routes from '../../../../common/routes'
import { CustomSwitch } from '../../../../components/custom-switch/CustomSwitch'
import BackgroundCheck from '../../../../components/modals/background-check/BackgroundCheck'
import BlockModal from '../../../../components/modals/block-modal/BlockModal'
import DeleteModal from '../../../../components/modals/delete-modal/DeleteModal'
import StateCertified from '../../../../components/modals/state-certified/StateCertified'
import SylvanSync from '../../../../components/modals/sylvan-sync/SylvanSync'
import UnblockModal from '../../../../components/modals/unblock-modal/UnblockModal'
import { api } from '../../../../utils'
import AdditionalNote from '../additional-note/AdditionalNote'
import ApproveTutor from '../approve-tutor/ApproveTutor'
import AssignHrStatus from '../assign-hrStatus/AssignHrStatus'
import AssignMarketId from '../assign-marketId/AssignMarketId'
import RejectTutor from '../reject-tutor/RejectTutor'
import UnRejectTutorModal from '../un-reject-tutor-modal/UnRejectTutorModal'
import TutorViewDetails from '../view-details/TutorViewDetails'
import {
  deleteIconCheck,
  emailCheck,
  hrStatusCheck,
  marketIdCheck,
} from './data'
import './tutor-table.scss'
import {
  HandleModalParams,
  TutorModalsProps,
  TutorTableProps,
  modalInitialValues,
} from './tutor-table.type'

const size = 15

const TutorTable: FC<TutorTableProps> = ({
  getTutorDetails,
  tutorData,
  handleSortBy,
  buttonType,
  sortBy,
}) => {
  const navigate = useNavigate()
  const [modals, setModals] = useState<TutorModalsProps>(modalInitialValues)
  const [checkStatus, setCheckStatus] = useState<boolean>()
  const [modalName, setModalName] = useState('')
  const [tutorId, setTutorId] = useState('')
  let todayDate = new Date().toUTCString()
  todayDate = new Date(todayDate).toISOString()

  const modalHandle = (data: HandleModalParams) => {
    if (data.tutor) {
      localStorage.setItem('tutorId', data.tutor?._id)
      localStorage.setItem('tutorStatus', data.tutor.profileStatus)
      localStorage.setItem(
        'tutorName',
        data.tutor?.firstName + ' ' + data.tutor?.lastName,
      )
      localStorage.setItem('timeZone', data.tutor?.metaData.timezoneOffsetZone)
      localStorage.setItem('abbreviation', data.tutor?.metaData.abbreviation)
    }

    if (data.icon === 'edit') navigate('/pages/' + routes.editTutors)

    if (data?.e?.target?.name === 'schedule')
      navigate('/pages/' + routes.scheduleSession)

    setModals({
      ...modals,
      [data.icon ? data.icon : data.e.target.name]: true,
    })
    setModalName(data.icon ? data.icon : data.e.target.name)
  }

  const handleDeleteTutor = async () => {
    const res = await api.delete(
      'admin/deleteUser/' + localStorage.getItem('tutorId'),
    )
    if (res.ok) {
      handleDeleteClose()
      getTutorDetails()
    }
  }

  const handleDeleteClose = () => {
    setModals({ ...open, ['delete']: false })
  }

  return (
    <div>
      <TableContainer>
        <Table className="tutor-table">
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              <TableCell>
                Name
                {sortBy === '' ? (
                  <span
                    onClick={() => {
                      handleSortBy?.('A-Z')
                    }}
                  >
                    <FaSort />
                  </span>
                ) : sortBy === 'Z-A' ? (
                  <span
                    onClick={() => {
                      handleSortBy?.('A-Z')
                    }}
                  >
                    <FaSortDown />
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      handleSortBy?.('Z-A')
                    }}
                  >
                    <FaSortUp />
                  </span>
                )}
              </TableCell>
              {emailCheck.some((value) => value === buttonType) && (
                <TableCell>Email</TableCell>
              )}
              {(buttonType === 'PENDING' || buttonType === 'INCOMPLETE') && (
                <TableCell>HR Status</TableCell>
              )}
              {buttonType === 'DECLINED' && <TableCell>Reason</TableCell>}
              {marketIdCheck.some((value) => value === buttonType) && (
                <TableCell>Market Name</TableCell>
              )}

              {buttonType === 'APPROVED' && (
                <TableCell>
                  Calendar
                  <br />
                  Live
                </TableCell>
              )}
              {buttonType === 'APPROVED' && (
                <TableCell>
                  Stripe
                  <br />
                  Live
                </TableCell>
              )}
              {(buttonType === 'ALL' || buttonType === 'BACKGROUND_CHECK') && (
                <TableCell>Status</TableCell>
              )}
              {buttonType === 'PENDING' && (
                <TableCell>
                  Background
                  <br />
                  Check
                </TableCell>
              )}
              {(buttonType === 'PENDING' || buttonType === 'APPROVED') && (
                <TableCell>
                  State
                  <br />
                  Certified
                </TableCell>
              )}
              {(buttonType === 'UNBLOCKED' || buttonType === 'APPROVED') && (
                <TableCell>
                  SylvanSync
                  <br />
                  Flag
                </TableCell>
              )}
              <TableCell>
                <span className="offset-3">Action</span>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tutorData?.map((data, index) => (
              <TableRow
                key={index}
                sx={
                  buttonType !== 'DELETED' ? { verticalAlign: 'baseline' } : {}
                }
              >
                <TableCell className="tutor-table__name">
                  {data.firstName ? data.firstName : ''}&nbsp;
                  {data.lastName ? data.lastName : ''}
                </TableCell>
                {emailCheck.some((value) => value === buttonType) && (
                  <TableCell sx={{ minWidth: '90px !important' }}>
                    {data.isDeleted ? '-' : data.email}
                  </TableCell>
                )}

                {(buttonType === 'PENDING' || buttonType === 'INCOMPLETE') && (
                  <TableCell>
                    {data && data.tutor && data.tutor.hrStatus
                      ? data.tutor.hrStatus
                      : '-'}
                  </TableCell>
                )}

                {buttonType === 'DECLINED' && (
                  <TableCell>
                    {data && data?.rejectReason ? data?.rejectReason : '-'}
                  </TableCell>
                )}

                {marketIdCheck.some((value) => value === buttonType) && (
                  <TableCell>
                    {data.marketId && data.marketId.marketId
                      ? data.marketId.marketName
                      : data.marketId && data.marketId.marketName
                      ? data.marketId.marketName
                      : '-'}
                  </TableCell>
                )}

                {/* Testing Tutor Live  */}

                {buttonType === 'APPROVED' && (
                  <TableCell>
                    {data.tutor &&
                    data.tutor.availability &&
                    data.tutor.availability.length
                      ? dayjs(data.tutor.availability.at(-1)?.startTime).diff(
                          todayDate,
                          'hour',
                          true,
                        ) > 0
                        ? 'Yes'
                        : 'No'
                      : 'No'}
                  </TableCell>
                )}
                {buttonType === 'APPROVED' && (
                  <TableCell>
                    {data.tutor && data.tutor.stripeAccountId ? 'Yes' : 'No'}
                  </TableCell>
                )}
                {(buttonType === 'ALL' ||
                  buttonType === 'BACKGROUND_CHECK') && (
                  <TableCell>
                    {data.isDeleted == true
                      ? 'DELETED'
                      : data.isBlocked == true
                      ? 'BLOCKED'
                      : data && data.profileStatus
                      ? data.profileStatus
                      : '-'}
                  </TableCell>
                )}

                {buttonType === 'PENDING' && (
                  <TableCell>
                    <CustomSwitch
                      name="bgCheck"
                      checked={data.tutor?.backgroundCheck}
                      onClick={(e) => {
                        modalHandle({ e, tutor: data })
                        setCheckStatus(data.tutor.backgroundCheck)
                        setTutorId(data._id)
                      }}
                    />
                  </TableCell>
                )}

                {(buttonType === 'PENDING' || buttonType === 'APPROVED') && (
                  <TableCell>
                    <CustomSwitch
                      name="stateCertified"
                      checked={data.tutor.adminCertified}
                      onChange={(e) => {
                        modalHandle({ e, tutor: data })
                        setCheckStatus(data.tutor.adminCertified)
                        setTutorId(data._id)
                      }}
                    />
                  </TableCell>
                )}
                {(buttonType === 'UNBLOCKED' || buttonType === 'APPROVED') && (
                  <TableCell>
                    <CustomSwitch
                      name="sylvanSync"
                      checked={data.tutor.sylvanSyncEnabled}
                      onChange={(e) => {
                        modalHandle({ e, tutor: data })
                        setCheckStatus(data.tutor.sylvanSyncEnabled)
                        setTutorId(data._id)
                      }}
                    />
                  </TableCell>
                )}

                <TableCell>
                  <div
                    style={{
                      marginLeft: 'auto',
                      padding: '0px',
                      display: 'flex',
                    }}
                  >
                    <span
                      className="d-flex justify-content-end flex-wrap col-sm-3"
                      // style={{ width: '100px', marginLeft: 'auto' }}
                    >
                      {buttonType === 'PENDING' && (
                        <Tooltip title="Not Approved">
                          <span
                            className="ms-3 me-3 mt-3 menu-icon"
                            onClick={(e) => {
                              setTutorId(data._id)
                              modalHandle({ tutor: data, icon: 'rejectTutor' })
                            }}
                          >
                            <FaTimes size={size} />
                          </span>
                        </Tooltip>
                      )}
                      {buttonType == 'PENDING' &&
                        data.tutor.backgroundCheck == true && (
                          <Tooltip title="Approve">
                            <span
                              className="ms-3 me-3 mt-3 menu-icon"
                              onClick={(e) => {
                                setTutorId(data._id)
                                modalHandle({
                                  tutor: data,
                                  icon: 'approveTutor',
                                })
                              }}
                            >
                              <FaCheck size={size} />
                            </span>
                          </Tooltip>
                        )}
                      {data.isBlocked == true &&
                        (buttonType === 'BLOCKED' ||
                          buttonType === 'APPROVED') && (
                          <Tooltip title="Unblock">
                            <span
                              className="ms-3 me-3 mt-3 menu-icon"
                              onClick={(e) => {
                                setTutorId(data._id)
                                modalHandle({ tutor: data, icon: 'unblock' })
                              }}
                            >
                              <FaUnlock size={size} />
                            </span>
                          </Tooltip>
                        )}
                      {data.isBlocked == false &&
                        (buttonType === 'APPROVED' ||
                          buttonType === 'UNBLOCKED') && (
                          <Tooltip title="Block">
                            <span
                              className="ms-3 me-3 mt-3 menu-icon"
                              onClick={(e) => {
                                setTutorId(data._id)
                                modalHandle({ tutor: data, icon: 'block' })
                              }}
                            >
                              <FaLock size={size} />
                            </span>
                          </Tooltip>
                        )}

                      {buttonType === 'DECLINED' && (
                        <Tooltip title="Undo">
                          <span
                            className="ms-3 me-3 mt-3 menu-icon"
                            onClick={() => {
                              setTutorId(data._id)
                              modalHandle({
                                icon: 'unRejectTutor',
                                tutor: data,
                              })
                            }}
                          >
                            <FaUndo size={size} />
                          </span>
                        </Tooltip>
                      )}
                      {!data.isDeleted && (
                        <Tooltip title="Edit">
                          <span
                            className="ms-3 me-3 mt-3 menu-icon"
                            onClick={(e) => {
                              setTutorId(data._id)
                              modalHandle({
                                icon: 'edit',
                                tutor: data,
                              })
                            }}
                          >
                            <FaPencilAlt size={size} />
                          </span>
                        </Tooltip>
                      )}
                      {deleteIconCheck.some(
                        (value) => value === buttonType,
                      ) && (
                        <Tooltip title="Delete">
                          <span
                            className="ms-3 me-3 mt-3 menu-icon"
                            onClick={(e) => {
                              setTutorId(data._id)
                              modalHandle({ tutor: data, icon: 'delete' })
                            }}
                          >
                            <FaTrashAlt size={size} />
                          </span>
                        </Tooltip>
                      )}
                    </span>
                    <span className="d-flex flex-wrap col-sm-9 p-1">
                      <Button
                        variant="text"
                        className="ms-1 me-1 mt-1"
                        name="viewDetail"
                        onClick={(e) => {
                          modalHandle({ e, tutor: data })
                        }}
                      >
                        View Detail
                      </Button>
                      {(buttonType !== 'DELETED' && buttonType === 'PENDING') ||
                      buttonType === 'APPROVED' ||
                      data.profileStatus == 'APPROVED' ||
                      buttonType === 'BACKGROUND_CHECK' ? (
                        <Button
                          variant="text"
                          className="ms-1 me-1 mt-1"
                          name="assignMarketId"
                          onClick={(e) => {
                            modalHandle({ e, tutor: data })
                          }}
                        >
                          Assign Market Id
                        </Button>
                      ) : (
                        <></>
                      )}

                      {!data.isDeleted && (
                        <Button
                          variant="text"
                          className="ms-1 me-1 mt-1"
                          name="additionalNote"
                          onClick={(e) => {
                            modalHandle({ e, tutor: data })
                            setTutorId(data._id)
                          }}
                        >
                          Additional Note
                        </Button>
                      )}
                      {!data.isDeleted && buttonType === 'APPROVED' ? (
                        <>
                          <Button
                            variant="text"
                            className="ms-1 me-1 mt-1"
                            name="schedule"
                            onClick={(e) => {
                              modalHandle({ e, tutor: data })
                            }}
                          >
                            Schedule
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      {hrStatusCheck.some((value) => value === buttonType) && (
                        <>
                          <Button
                            variant="text"
                            className="ms-1 me-1 mt-1"
                            name="hrStatus"
                            onClick={(e) => {
                              modalHandle({ e, tutor: data })
                              setTutorId(data._id)
                            }}
                          >
                            HR Status
                          </Button>
                        </>
                      )}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AdditionalNote
        open={modals}
        key={modals.additionalNote + 'additionalNote'}
        setOpen={setModals}
        modalName={modalName}
        tutorId={tutorId}
        tutorData={tutorData}
      />

      {modals.viewDetail && (
        <TutorViewDetails
          open={modals.viewDetail}
          key={modals.viewDetail + 'viewDetail'}
          handleClose={() => {
            setModals({ ...modals, viewDetail: false })
          }}
        />
      )}
      <BlockModal
        open={modals}
        key={modals.block + 'block'}
        setOpen={setModals}
        modalName={modalName}
        userId={tutorId}
        role="Tutor"
        onBlock={getTutorDetails}
      />
      <UnblockModal
        open={modals}
        key={modals.unblock + 'unblock'}
        setOpen={setModals}
        modalName={modalName}
        userId={tutorId}
        role="Tutor"
        onUnblock={getTutorDetails}
      />
      <BackgroundCheck
        open={modals}
        key={modals.bgCheck + 'bgCheck'}
        setOpen={setModals}
        modalName={modalName}
        userId={tutorId}
        getTutorDetails={getTutorDetails}
        checkStatus={checkStatus}
      />
      <StateCertified
        open={modals}
        key={modals.stateCertified + 'stateCertified'}
        setOpen={setModals}
        modalName={modalName}
        userId={tutorId}
        getTutorDetails={getTutorDetails}
        checkStatus={checkStatus}
      />
      <SylvanSync
        open={modals}
        key={modals.sylvanSync + 'sylvanSync'}
        setOpen={setModals}
        modalName={modalName}
        userId={tutorId}
        getTutorDetails={getTutorDetails}
        checkStatus={checkStatus}
      />

      {modals.delete && (
        <DeleteModal
          open={modals.delete}
          role="Tutor"
          handleClose={handleDeleteClose}
          key={modals.delete + 'delete'}
          onDelete={handleDeleteTutor}
        />
      )}
      <AssignMarketId
        open={modals}
        key={modals.assignMarketId + 'assignMarketId'}
        setOpen={setModals}
        modalName={modalName}
        tutorData={tutorData}
        onChange={() => getTutorDetails?.()}
      />
      <AssignHrStatus
        open={modals}
        key={modals.hrStatus + 'hrStatus'}
        setOpen={setModals}
        modalName={modalName}
        tutorId={tutorId}
        tutorData={tutorData}
        onChange={() => getTutorDetails?.()}
      />
      <RejectTutor
        open={modals}
        key={modals.rejectTutor + 'rejectTutor'}
        setOpen={setModals}
        modalName={modalName}
        tutorId={tutorId}
        tutorData={tutorData}
        onSuccess={getTutorDetails}
      />
      <ApproveTutor
        open={modals}
        key={modals.approveTutor + 'approveTutor'}
        setOpen={setModals}
        modalName={modalName}
        tutorId={tutorId}
        onSuccess={getTutorDetails}
        tutorData={tutorData}
      />
      <UnRejectTutorModal
        open={modals}
        key={modals.unRejectTutor + 'unRejectTutor'}
        setOpen={setModals}
        modalName={modalName}
        tutorId={tutorId}
        onSuccess={() => getTutorDetails()}
      />
    </div>
  )
}

export default TutorTable
