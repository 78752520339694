import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import { api } from '../../../../utils'
import {
  ExpirationRes,
  ReservationExpiration,
} from './components/AllBhExpiration.types'
import AddEditExpiration from './components/add-edit-expiration/AddEditExpiration'
import ExpirationTable from './components/expiration-table/ExpirationTable'

const AllBhExpirations = () => {
  const [addExpiration, setAddExpiration] = useState(false)
  const [disableDates, setDisableDates] = useState<Dayjs[]>([])
  const [allExpirations, setAllExpirations] = useState<ReservationExpiration[]>(
    [],
  )
  const getExpirations = async () => {
    const res = await api.get<ExpirationRes>('admin/getReservationExpiration')
    if (res.ok && res.data) {
      const { reservationExpiration } = res.data?.data
      setAllExpirations(reservationExpiration)
      let tempDisable: Dayjs[] = []
      reservationExpiration.forEach((val) => {
        tempDisable.push(dayjs(val.reservationDate).utc())
      })
      setDisableDates(tempDisable)
    }
  }

  useEffect(() => {
    getExpirations()
  }, [])
  return (
    <div className="container">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setAddExpiration(true)
            }}
          >
            Add Expiration Record
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center my-4">
        <div className="col-sm-8 text-center font-bold">
          Records listed after 3/22/2022 indicate the number of additional days
          that will be added to the Bright Horizons supplied expiration date. It
          will no longer indicate the full number of days that a customer has to
          use their reservation.
        </div>
      </div>
      <div className="row">
        <ExpirationTable
          reservationExpiration={allExpirations}
          getExpirations={getExpirations}
          disableDates={disableDates}
        />
      </div>
      {addExpiration && (
        <AddEditExpiration
          open={addExpiration}
          mode="Add"
          onClose={() => {
            setAddExpiration(false)
          }}
          onSuccess={getExpirations}
          disableDates={disableDates}
        />
      )}
    </div>
  )
}

export default AllBhExpirations
