import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import { api } from '../../../../../utils'

interface ConfirmApproveProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  tutorId?: string
  marketId?: string
  onSuccess: () => void
  onNo: () => void
}
const ConfirmApprove: FC<ConfirmApproveProps> = ({
  open,
  setOpen,
  tutorId,
  marketId,
  onSuccess,
  onNo,
}) => {
  const handleClose = () => {
    setOpen(false)
    onNo()
  }
  const approveTutor = async () => {
    const res = await api.put('admin/approveDeclineTutor/' + tutorId, {
      action: 'APPROVE',
      marketId: marketId,
    })
    if (res.ok) {
      onSuccess?.()
      handleClose()
    }
  }
  return (
    <ModalWrapper
      wide
      open={open}
      handleClose={handleClose}
      title="Approve Tutor?"
    >
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p>Are you sure you want to approve this tutor?</p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={approveTutor}
          >
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmApprove
