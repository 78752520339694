import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { useAppContext } from '../../../../contexts/AppContext'
import { api } from '../../../../utils'
import { PromoFilterI } from '../../AllPromos'
import { PromoCodeRes, Promocode } from '../../allPromos.types'

interface useAllPromosI {
  filterStates: PromoFilterI | null
  debouncedSearch?: string
}
const useAllPromos = ({ filterStates, debouncedSearch }: useAllPromosI) => {
  const { updateAppState } = useAppContext()

  const [allPromos, setAllPromos] = useState<Promocode[]>([])
  const [totalPages, setTotalPages] = useState(0)

  const getAllPromos = async () => {
    updateAppState({ loading: true })
    const queryObj = {
      status: filterStates?.status,
      startDate: filterStates?.startDate
        ? filterStates.startDate.format().split('T')[0] + 'T00:00:00.000Z'
        : null,
      endDate: filterStates?.endDate
        ? filterStates.endDate.format().split('T')[0] + 'T23:59:59.999Z'
        : null,
      marketId:
        filterStates?.marketId && filterStates.marketId !== 'none'
          ? filterStates?.marketId
          : null,

      skip: filterStates?.skip ? (filterStates.skip - 1) * 10 : 0,
      limit: 10,
      searchParam: debouncedSearch ? debouncedSearch : null,
    }
    const res = await api.get<PromoCodeRes>('admin/getPromocodes', queryObj)

    if (res.ok && res.data) {
      const { promocodes, count } = res.data.data
      setAllPromos(promocodes)
      setTotalPages(Math.ceil(count / 10))
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getAllPromos()
  }, [filterStates, debouncedSearch])
  return { allPromos, totalPages, getAllPromos, setAllPromos }
}

export default useAllPromos
