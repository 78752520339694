import { FC, useEffect, useState } from 'react'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-hot-toast'

import CustomGooglePlacesAutoComplete from '../../../../../../components/google-places-auto-complete/GooglePlacesAutoComplete'
import { GetMarketsRes, MarketID } from '../../../../../../types/market.type'
import { Tutor } from '../../../../../../types/tutor.type'
import { api } from '../../../../../../utils'
import { CONSTANT } from '../../../../../../utils/constants'
import {
  AddressState,
  GeocoderResult,
} from '../../../../../parent/components/edit-parent/components/address-form/AddressForm'
import AddressError from '../../../../../parent/components/edit-parent/components/address-form/components/address-error/AddressError'
import AddressModal from '../../../../../parent/components/edit-parent/components/address-form/components/address-modal/AddressModal'
import { TutorStates } from '../../EditTutor'
import StyledTextfield from './../../../../../../components/styled-textfield/StyledTextfield'
import TutorOutsideMarketModal from './components/TutorOutsideMarketModal'

interface EditTutorContactInfoIProps {
  singleTutor?: Tutor
  setNewAddress?: React.Dispatch<React.SetStateAction<AddressState | undefined>>
  tutorState?: TutorStates
  setTutorState?: React.Dispatch<React.SetStateAction<TutorStates>>
  newAddress?: AddressState
}

const initialValues = {
  city: '',
  state: '',
  country: '',
  locality: '',
  zipCode: '',
  stateCode: '',
  addressLine1: '',
  addressLine2: '',
}

const EditTutorContactInfo: FC<EditTutorContactInfoIProps> = ({
  singleTutor,
  setNewAddress,
  tutorState,
  setTutorState,
  newAddress,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [clearFlag, setClearFlag] = useState(false)
  const [addressMode, setAddressMode] = useState<'Edit' | 'Add'>('Add')
  const [addressError, setAddressError] = useState(false)
  const [address, setAddress] = useState<AddressState>(initialValues)
  const [addressModal, setAddressModal] = useState(false)
  const [addressErrorModal, setAddressErrorModal] = useState(false)
  const [showTutorOutsideModal, setShowTutorOutsideModal] = useState(false)
  const [oldMarket, setOldMarket] = useState<MarketID | null>(null)
  const [newMarket, setNewMarket] = useState<MarketID | null>(null)
  const [newAddressSelected, setNewAddressSelected] = useState<boolean>(true)
  const [reset, setReset] = useState<boolean>(false)

  const handleAddress = (placeId: string) => {
    geocodeByPlaceId(placeId)
      .then((results) => getAddress(results[0]))
      .catch((error) => console.error(error))
  }

  const getAddress = async (place: GeocoderResult | null) => {
    // @ts-ignore
    let state, stateCode, City, locality, country, postal, route, street_number

    place?.address_components.map((plc) => {
      plc.types.map((type) => {
        if (type === 'administrative_area_level_1') {
          state = plc.long_name
          stateCode = plc.short_name
        } else if (type === 'administrative_area_level_2') {
          locality = plc.long_name
        } else if (type === 'locality') {
          City = plc.long_name
        } else if (type === 'neighborhood') {
          City = plc.long_name
        } else if (type === 'sublocality_level_1') {
          City = plc.long_name
        } else if (type === 'sublocality_level_2') {
          City = plc.long_name
        } else if (type === 'sublocality_level_3') {
          City = plc.long_name
        } else if (type === 'sublocality_level_4') {
          City = plc.long_name
        } else if (type === 'sublocality_level_5') {
          City = plc.long_name
          // @ts-ignore
        } else if (type === 'administrative_area_level_3' && !City) {
          City = plc.long_name
        } else if (type === 'country') {
          country = plc.long_name
        } else if (type === 'postal_code') {
          postal = plc.long_name
        } else if (type === 'route') {
          route = plc.long_name
        } else if (type === 'street_number') {
          street_number = plc.long_name
        }
      })
    })

    let addressLine1 =
      (street_number ? street_number : '') +
      (route ? ' ' : '') +
      (route ? route : '')

    const tempAddress = {
      state: stateCode,
      stateCode: stateCode,
      city: City,
      country: country,
      latitude: place?.geometry.location.lat(),
      longitude: place?.geometry.location.lng(),
      zipCode: postal ? postal : '',
      addressLine1: addressLine1,
      addressLine2: '',
    }

    if (
      tempAddress.state &&
      tempAddress.addressLine1 &&
      tempAddress.city &&
      tempAddress.zipCode &&
      tempAddress.country
    ) {
      setAddressModal(true)
      // console.log({ tempAddress })
      setAddress({ ...address, ...tempAddress })
      setNewAddress?.({ ...address, ...tempAddress })
      // setNewAddress?.(tempAddress)
      setNewAddressSelected?.(true)
    } else {
      setAddressErrorModal(true)
    }
    setAddressError(false)
  }

  useEffect(() => {
    setOldMarket(singleTutor?.marketId ? singleTutor?.marketId : null)
  }, [singleTutor])

  useEffect(() => {
    if (tutorState?.homeAddressObject) {
      setAddressMode('Edit')
    }
  }, [tutorState?.homeAddressObject])

  const checkMarkets = async () => {
    const result = await api.get<GetMarketsRes>('admin/getMarketDetails', {
      longitude: address.longitude,
      latitude: address.latitude,
    })
    if (result.ok && result.data) {
      if (result.data.data.length) {
        const { data } = result.data
        setNewMarket(data[0].marketId)
        if (data[0].marketId.marketId !== oldMarket?.marketId) {
          setNewMarket(data[0].marketId)
          setShowTutorOutsideModal(true)
        }
      } else {
        if (!oldMarket?.marketName) {
          toast.success('Address saved but tutor remains in no market', {
            id: 'global',
          })
        } else {
          toast.success(
            `This address is not in a valid market. The tutor will remain in the ${oldMarket.marketName} market.`,
            { id: 'global' },
          )
        }
      }
    }
  }

  const isChangeMarket = (isChange: boolean) => {
    setTutorState?.({
      ...tutorState,
      ['isMarketChange']: isChange,
      addressErrorFlag: addressError,
      locationFlag: false,
    })
    setShowTutorOutsideModal(false)
  }

  const errorMessage = (value: string) => {
    if (!value && singleTutor?.tutor.profileStep === 7)
      return `Email is required.`
    else if (value && !CONSTANT.EMAIL_REGEXP.test(value))
      return `Email is not valid.`
  }

  const errorCheck = (value: string) => {
    return !value || !CONSTANT.EMAIL_REGEXP.test(value)
  }

  const resetAddress = () => {
    setReset(true)
    setTutorState?.({ ...tutorState, newSelected: true, locationFlag: false })
  }

  return (
    <div className="without-arrows">
      <h5 className="heading-container">Contact Information</h5>
      <div className="row">
        <div className="form-group mb-0 col-sm-6">
          <div className="row">
            <div className="col-sm-12 p-0">
              <label>First Name</label>
            </div>
          </div>
          <div className="row">
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  placeholder="First Name"
                  required={singleTutor?.tutor.profileStep === 7}
                  className="col-sm-10 m-1"
                  max={64}
                  isError={
                    (!field.value && singleTutor?.tutor.profileStep === 7) ||
                    (field.value && !CONSTANT.namePattern.test(field.value))
                  }
                  errorMessage={
                    !field.value && singleTutor?.tutor.profileStep === 7
                      ? 'First Name is required'
                      : field.value && !CONSTANT.namePattern.test(field.value)
                      ? 'First name is not valid'
                      : ''
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="form-group mb-0 col-sm-6">
          <div className="row">
            <div className="col-sm-12 p-0">
              <label className="ps-0">Last Name</label>
            </div>
          </div>
          <div className="row">
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  placeholder="Last Name"
                  className="col-sm-10 m-1"
                  required={singleTutor?.tutor.profileStep === 7}
                  max={64}
                  isError={
                    (!field.value && singleTutor?.tutor.profileStep === 7) ||
                    (field.value && !CONSTANT.namePattern.test(field.value))
                  }
                  errorMessage={
                    !field.value && singleTutor?.tutor.profileStep === 7
                      ? 'Last Name is required'
                      : field.value && !CONSTANT.namePattern.test(field.value)
                      ? 'Last name is not valid'
                      : ''
                  }
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-0 col-sm-6">
          <div className="row">
            <div className="col-sm-12 p-0">
              <label className="ps-0">Email</label>
            </div>
          </div>
          <div className="row">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  placeholder="Email"
                  className="col-sm-10 m-1"
                  max={256}
                  required={singleTutor?.tutor.profileStep === 7}
                  isError={errorCheck(field.value)}
                  errorMessage={errorMessage(field.value)}
                />
              )}
            />
          </div>
        </div>
        <div className="form-group mb-0 col-sm-6">
          <div className="row">
            <div className="col-sm-12 p-0">
              <label className="ps-0">Phone Number</label>
            </div>
          </div>
          <div className="row">
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  max={10}
                  type="number"
                  placeholder="Phone Number"
                  className="col-sm-10 m-1"
                  required={singleTutor?.tutor.profileStep === 7}
                  isError={
                    (!field.value && singleTutor?.tutor.profileStep === 7) ||
                    (field.value.length < 10 && field.value)
                  }
                  errorMessage={
                    !field.value && singleTutor?.tutor.profileStep === 7
                      ? 'Phone Number is required'
                      : field.value.length < 10 && field.value
                      ? 'Phone number minimum length is 10.'
                      : ''
                  }
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-sm-6">
          <div className="row">
            <div className="col-sm-12 p-0">
              <label className="ps-0">Username</label>
            </div>
          </div>
          <div className="row">
            <Controller
              name="userName"
              control={control}
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  disabled
                  readOnly
                  placeholder="Username"
                  className="col-sm-10 m-1"
                />
              )}
            />
          </div>
        </div>
        <div className="form-group mb-0 col-sm-6">
          <div className="row">
            <div className="col-sm-12 p-0">
              <label className="ps-0 homeAddress">Home Address</label>
            </div>
          </div>
          <div className="row">
            <StyledTextfield className="col-sm-10 m-1" value="Home" disabled />
            <div className="col-sm-10 m-1">
              <CustomGooglePlacesAutoComplete
                singleTutor={singleTutor}
                onChange={handleAddress}
                clearValue={clearFlag}
                setClearValue={setClearFlag}
                addressMode={addressMode}
                tutorState={tutorState}
                setAddressError={setAddressError}
                setTutorState={setTutorState}
                newAddressSelected={newAddressSelected}
                resetAddress={reset}
                // onKeyUp={() => {
                //   setTutorState?.({ ...tutorState, newSelected: true })
                // }}
                defaultValue={tutorState?.homeAddressObject as string}
                onClearVal={() => {
                  setAddress({})
                  setTutorState?.({
                    ...tutorState,
                    addressErrorFlag: true,
                    newSelected: false,
                  })
                }}
                addressError={
                  addressError && singleTutor?.tutor.profileStep === 7
                }
                path="editTutor"
              />
            </div>
            <div className="row ">
              <div className="col-sm-12">
                <label className="homeAddress">Current Address</label>
              </div>
            </div>
            <div className="row">
              <StyledTextfield
                className="col-sm-12 "
                value={
                  address.addressLine1
                    ? `${address?.addressLine1}, ${address?.addressLine2} ${address?.city}, ${address?.state} ${address?.zipCode}`
                    : newAddress?.city && newAddress.state && newAddress.zipCode
                    ? ''
                    : tutorState?.homeAddressObject
                }
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      {addressModal && (
        <AddressModal
          open={addressModal}
          setOpen={setAddressModal}
          address={address}
          setAddress={setAddress}
          setNewAddress={setNewAddress}
          setTutorState={setTutorState}
          setClearFlag={setClearFlag}
          onAdd={checkMarkets}
        />
      )}
      {addressErrorModal && (
        <AddressError
          open={addressErrorModal}
          setOpen={setAddressErrorModal}
          onClose={() => {
            setClearFlag(true)
          }}
        />
      )}
      {showTutorOutsideModal && (
        <TutorOutsideMarketModal
          open={showTutorOutsideModal}
          oldMarket={oldMarket}
          newMarket={newMarket}
          isChangeMarket={isChangeMarket}
          addressNotChange={() => {
            resetAddress()
            setShowTutorOutsideModal(false)
          }}
          onClose={() => {
            setShowTutorOutsideModal(false)
          }}
        />
      )}
    </div>
  )
}

export default EditTutorContactInfo
