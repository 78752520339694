import { Button } from '@mui/material'
import { FC } from 'react'

import { ModalProps } from '../../../pages/parent/components/parent-table/ParentTable'
import { api } from '../../../utils'
import ModalWrapper from '../../modal-wrapper/ModalWrapper'

interface BlockModalProps {
  open: ModalProps
  setOpen: React.Dispatch<React.SetStateAction<ModalProps>>
  modalName: string
  userId?: string
  role?: string
  name?: string
  onBlock?: () => void
}
const BlockModal: FC<BlockModalProps> = ({
  open,
  setOpen,
  modalName,
  userId,
  role,
  onBlock,
  name,
}) => {
  const localName = localStorage.getItem(
    role === 'Parent' ? 'parentName' : 'tutorName',
  )

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const blockUser = async () => {
    const res = await api.put('admin/blockUnblock/' + userId, {
      action: 'BLOCK',
    })
    if (res.ok) {
      handleClose()
      onBlock?.()
    }
  }

  return (
    <ModalWrapper
      open={open.block}
      handleClose={handleClose}
      title={`Block ${role}`}
    >
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p>Are you sure you want to block {name || localName}?</p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button color="success" className="modal-button" onClick={blockUser}>
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default BlockModal
