import { Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'

import ConfirmDelete from '../../../../../components/confirm-delete/ConfirmDelete'
import { FAQ } from '../../../../../types/constants.type'
import { api } from '../../../../../utils'
import AddEditFaq from '../add-edit-faq/AddEditFaq'
import './faq-component.scss'

interface FaqComponentI {
  FAQ: FAQ
  getConstants: () => Promise<void>
  buttonType: 'PARENT' | 'TUTOR'
}

const size = 16

const FaqComponent: FC<FaqComponentI> = ({ FAQ, getConstants, buttonType }) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [editFaq, setEditFaq] = useState(false)

  const deleteFAQ = async () => {
    const formData = new FormData()
    formData.append('type', buttonType)
    const res = await api.delete(
      `admin/deleteFaq/${FAQ._id}`,
      {},
      {
        data: {
          type: buttonType,
        },
      },
    )
    if (res && res.ok) {
      setConfirmDelete(false)
      getConstants()
    }
  }
  return (
    <div className="row mb-20">
      <div className="col-sm-12">
        <span className="question">{FAQ.query}</span>
        <span className="d-flex align-items-center justify-content-end me-5 mb-2">
          <Tooltip title="Delete">
            <span
              className="ms-3 me-3 menu-icon text-center"
              onClick={() => {
                setConfirmDelete(true)
              }}
            >
              <FaTrashAlt size={size} />
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <span
              className="ms-3 me-3 menu-icon text-center"
              onClick={() => {
                setEditFaq(true)
              }}
            >
              <FaPencilAlt size={size} />
            </span>
          </Tooltip>
        </span>
      </div>
      <div className="col-sm-12  ">
        <div
          className="text-letter-spacing changeColorAnchor"
          dangerouslySetInnerHTML={{ __html: FAQ.ans }}
        />
      </div>
      {confirmDelete && (
        <ConfirmDelete
          open={confirmDelete}
          title="FAQs"
          text="the FAQs"
          onClose={() => {
            setConfirmDelete(false)
          }}
          onYes={deleteFAQ}
        />
      )}
      {editFaq && (
        <AddEditFaq
          mode="Edit"
          open={editFaq}
          onClose={() => {
            setEditFaq(false)
          }}
          onSuccess={getConstants}
          buttonType={buttonType}
          editFaq={FAQ}
        />
      )}
    </div>
  )
}

export default FaqComponent
