import dayjs from 'dayjs'
import { FC } from 'react'

import { Tutor } from '../../types/tutor.type'

interface AlternatePricingProps {
  singleTutor: Tutor | null | undefined
}

const AlternatePricing: FC<AlternatePricingProps> = ({ singleTutor }) => {
  return (
    <div>
      <div className="heading-container">
        Alternate Pricing/Partnership Code Opt-Ins
      </div>
      <div className="res-table table-responsive ">
        <table className="table pricing-table">
          <thead>
            <tr className="table-border">
              <th className="pricing-table">Partnership Code</th>
              <th className="pricing-table"> Alternate price</th>
              <th className="pricing-table"> Admin fee</th>
              <th className="pricing-table">Start Date</th>
              <th className="pricing-table">End Date</th>
            </tr>
          </thead>
          <tbody>
            {singleTutor?.partnershipCode?.map((data) => {
              return (
                <tr className="table-border">
                  <td>{data.partnercode}</td>
                  <td className="whieSpaveWrapNo">
                    {data.partnercodeId ? data.partnercodeId.hourlyRate : ''}{' '}
                    {data.lastName ? data.lastName : ''}
                  </td>
                  <td className="whieSpaveWrapNo">
                    {data.partnercodeId && data.partnercodeId.case1.discount
                      ? data.partnercodeId.case1.discount
                      : '-'}
                  </td>
                  <td className="whieSpaveWrapNo">
                    {data.partnercodeId && data.partnercodeId.startTime
                      ? dayjs(data.partnercodeId.startTime)
                          .utc()
                          .format('MM-DD-YYYY')
                      : '-'}
                  </td>
                  <td className="whieSpaveWrapNo">
                    {data.partnercodeId && data.partnercodeId.endTime
                      ? dayjs(data.partnercodeId.endTime)
                          .utc()
                          .format('MM-DD-YYYY')
                      : '-'}
                  </td>
                </tr>
              )
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AlternatePricing
