import { FC } from 'react'

import { TutorElement } from '../../types/changeRequest.type'
import { Tutor } from '../../types/tutor.type'
import { StyledRow2 } from '../custom-bordered-row/StyledRow'

interface PoliciesProps {
  singleTutor?: Tutor | null | undefined
  pendingTutor?: TutorElement
  path?: string
}

const Policies: FC<PoliciesProps> = ({ singleTutor, path, pendingTutor }) => {
  return (
    <div>
      <div className="heading-container row-margin">
        Policies and Preferences
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Do you have your own vehicle that you are willing/able to drive
                to meet tutoring students?"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.ownsVehicle &&
                  singleTutor?.tutor.ownsVehicle == true
                    ? 'yes'
                    : singleTutor?.tutor.ownsVehicle == false
                    ? 'no'
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId?.tutor?.ownsVehicle &&
                  pendingTutor?.userId?.tutor?.ownsVehicle == true
                    ? 'yes'
                    : pendingTutor?.userId?.tutor?.ownsVehicle == false
                    ? 'no'
                    : '-'}
                </>
              )}
            </>
          }
        />
        <StyledRow2
          field="Mode of transportation"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor && singleTutor?.tutor.transportationMode
                    ? singleTutor?.tutor &&
                      singleTutor?.tutor.ownsVehicle == true
                      ? '-'
                      : singleTutor?.tutor.transportationMode
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId &&
                  pendingTutor?.userId.tutor.transportationMode
                    ? pendingTutor.userId &&
                      pendingTutor?.userId.tutor.ownsVehicle == true
                      ? '-'
                      : pendingTutor?.userId.tutor.transportationMode
                    : '-'}
                </>
              )}
            </>
          }
        />
        <StyledRow2
          field="Please confirm that, if approved as a mySylvan Marketplace+
          tutor, you are willing to be contacted via text message from
          parents interested in hiring you for tutoring services."
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor && singleTutor?.tutor.contactable == true
                    ? 'yes'
                    : singleTutor?.tutor &&
                      singleTutor?.tutor.contactable == false
                    ? 'no'
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId &&
                  pendingTutor?.userId.tutor.contactable == true
                    ? 'yes'
                    : pendingTutor?.userId.tutor &&
                      pendingTutor?.userId.tutor.contactable == false
                    ? 'no'
                    : '-'}
                </>
              )}
            </>
          }
        />
        <StyledRow2
          field="Are you interested in becoming certified in the Sylvan method to
          receive access to the Sylvan curriculum and training
          opportunities?"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.sylvanCertification == 0
                    ? 'no'
                    : singleTutor?.tutor.sylvanCertification == 1
                    ? 'yes'
                    : singleTutor?.tutor.sylvanCertification == 2
                    ? 'Unsure - I’d like to learn more!'
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId.tutor.sylvanCertification == 0
                    ? 'no'
                    : pendingTutor?.userId.tutor.sylvanCertification == 1
                    ? 'yes'
                    : pendingTutor?.userId.tutor.sylvanCertification == 2
                    ? 'Unsure - I’d like to learn more!'
                    : '-'}
                </>
              )}
            </>
          }
        />
        <StyledRow2
          field="Although not required to apply, do you own an iPad?"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.ownsIpad == true
                    ? 'yes'
                    : singleTutor?.tutor.ownsIpad == false
                    ? 'no'
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId.tutor.ownsIpad == true
                    ? 'yes'
                    : pendingTutor?.userId.tutor.ownsIpad == false
                    ? 'no'
                    : '-'}
                </>
              )}
            </>
          }
        />
        <StyledRow2
          field="If yes, would you be willing to use your iPad as part of
          delivering in-home tutoring?"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.ownsIpad == false
                    ? '-'
                    : singleTutor?.tutor.willingToUseIpad &&
                      singleTutor?.tutor.willingToUseIpad == 1
                    ? 'yes'
                    : singleTutor?.tutor.willingToUseIpad == 0
                    ? 'no'
                    : singleTutor?.tutor.willingToUseIpad &&
                      singleTutor?.tutor.willingToUseIpad == 2
                    ? 'Unsure - I’d like to learn more!'
                    : '-'}
                </>
              ) : (
                <>
                  {pendingTutor?.userId.tutor.ownsIpad == false
                    ? '-'
                    : pendingTutor?.userId.tutor.willingToUseIpad &&
                      pendingTutor?.userId.tutor.willingToUseIpad == 1
                    ? 'yes'
                    : pendingTutor?.userId.tutor.willingToUseIpad == 0
                    ? 'no'
                    : pendingTutor?.userId.tutor.willingToUseIpad &&
                      pendingTutor?.userId.tutor.willingToUseIpad == 2
                    ? 'Unsure - I’d like to learn more!'
                    : '-'}
                </>
              )}
            </>
          }
        />
      </div>
    </div>
  )
}

export default Policies
