import { Button } from '@mui/material'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useEffect, useState } from 'react'

import { StyledRow } from '../../../../components/custom-bordered-row/StyledRow'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import { Contact } from '../../hooks/contacts.types'

dayjs.extend(utc)
dayjs.extend(tz)

interface ContactDetailsI {
  open: boolean
  onClose: () => void
  contact: Contact
}

const ContactDetails: FC<ContactDetailsI> = ({ contact, onClose, open }) => {
  const [source, setSource] = useState('')
  const toCapitalize = (name: 'PARENT' | 'TUTOR') => {
    return name === 'PARENT' ? 'Parent' : 'Tutor'
  }
  useEffect(() => {
    if (contact && contact.from) {
      if (contact.from == 'PARENT') {
        setSource('Parent')
      } else if (contact.from == 'TUTOR') {
        setSource('Tutor')
      } else if (contact.from == 'LEAD_GEN') {
        setSource('Webform')
      } else if (contact.from == 'COMING_SOON') {
        setSource('Coming Soon')
      } else if (contact.from == 'DELETE_TUTOR') {
        setSource('Delete Tutor')
      } else
        setSource((contact.from as string).replace(/[_-]/g, ' ').toLowerCase())
    }
  }, [])

  return (
    <ModalWrapper open={open} handleClose={onClose} title="Contact Us" wide>
      <StyledRow head field="Field Name" description="Description" />
      <StyledRow
        field="First Name"
        description={contact && contact.firstName ? contact.firstName : '-'}
      />
      <StyledRow
        field="Last Name"
        description={contact && contact.lastName ? contact.lastName : '-'}
      />
      <StyledRow
        field="Email"
        description={contact && contact.email ? contact.email : '-'}
      />
      <StyledRow
        field="Phone Number"
        description={contact && contact.phoneNumber ? contact.phoneNumber : '-'}
      />
      <StyledRow
        field="Zip Code"
        description={contact && contact.location ? contact.location : '-'}
      />
      <StyledRow
        field="User Type"
        description={contact && contact.user ? toCapitalize(contact.user) : '-'}
      />
      <StyledRow field="Source" description={source ? source : '-'} />
      <StyledRow
        field="Market"
        description={contact && contact.market ? contact.market : '-'}
      />
      <StyledRow
        field="Contact Date"
        description={
          contact && contact.createdAt
            ? dayjs(contact.createdAt).format('MM-DD-YYYY')
            : '-'
        }
      />
      <StyledRow
        field="Text"
        description={contact && contact.message ? contact.message : '-'}
      />

      <div className="col-md-10 col-sm-10 text-end mt-4">
        <Button color="success" className="w-25" onClick={onClose}>
          OK
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default ContactDetails
