import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC } from 'react'

import { TutorElement } from '../../types/changeRequest.type'
import { Tutor } from '../../types/tutor.type'
import { StyledRow2 } from '../custom-bordered-row/StyledRow'

dayjs.extend(utc)
dayjs.extend(timezone)

interface BuildProfileProps {
  singleTutor?: Tutor | null | undefined
  pendingTutor?: TutorElement
  path?: string
}

const BuildProfile: FC<BuildProfileProps> = ({
  singleTutor,
  path,
  pendingTutor,
}) => {
  let createdAt: Dayjs | null = null
  if (singleTutor?.createdAt) {
    createdAt = dayjs.utc(singleTutor.createdAt)
  }
  if (singleTutor?.userId?.createdAt) {
    createdAt = dayjs.utc(singleTutor.userId.createdAt)
  }
  if (pendingTutor?.userId?.createdAt) {
    createdAt = dayjs.utc(pendingTutor.userId.createdAt)
  }

  return (
    <div>
      <div className="heading-container row-margin">Build Your Profile</div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Profile Headline"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor && singleTutor?.tutor.profileHeadLine
                    ? singleTutor?.tutor.profileHeadLine
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.profileHeadLine
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor && pendingTutor?.tutor?.profileHeadLine
                    ? pendingTutor?.tutor?.profileHeadLine
                    : pendingTutor?.userId.tutor.profileHeadLine
                    ? pendingTutor.userId.tutor.profileHeadLine
                    : '-'}
                </span>
              )}
            </>
          }
        />

        <StyledRow2
          field="About Tutor"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor && singleTutor?.tutor.aboutMe
                    ? singleTutor?.tutor.aboutMe
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.aboutMe
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor && pendingTutor?.tutor?.aboutMe
                    ? pendingTutor?.tutor?.aboutMe
                    : pendingTutor?.userId.tutor.aboutMe
                    ? pendingTutor.userId.tutor.aboutMe
                    : '-'}
                </span>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Accepting New Families"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor && singleTutor?.tutor.acceptingNewFamilies
                    ? 'Yes'
                    : 'No'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor &&
                    (pendingTutor?.tutor?.acceptingNewFamilies ||
                      pendingTutor?.tutor?.acceptingNewFamilies == false)
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor &&
                  (pendingTutor?.tutor?.acceptingNewFamilies ||
                    pendingTutor?.tutor?.acceptingNewFamilies == false)
                    ? pendingTutor?.tutor?.acceptingNewFamilies
                      ? 'Yes'
                      : 'No'
                    : pendingTutor?.userId.tutor?.acceptingNewFamilies
                    ? 'Yes'
                    : 'No'}
                </span>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Offers Online Tutoring"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor &&
                  singleTutor?.tutor?.isTutorOfferOnlineClasses
                    ? 'Yes'
                    : 'No'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor &&
                    (pendingTutor?.tutor?.isTutorOfferOnlineClasses ||
                      pendingTutor?.tutor?.isTutorOfferOnlineClasses == false)
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor &&
                  (pendingTutor?.tutor?.isTutorOfferOnlineClasses ||
                    pendingTutor?.tutor?.isTutorOfferOnlineClasses == false)
                    ? pendingTutor?.tutor?.isTutorOfferOnlineClasses
                      ? 'Yes'
                      : 'No'
                    : pendingTutor?.userId.tutor?.isTutorOfferOnlineClasses
                    ? 'Yes'
                    : 'No'}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="Offers In-Person Tutoring"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor &&
                  singleTutor?.tutor?.isOfferInPersonTutoring
                    ? 'Yes'
                    : 'No'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor &&
                    (pendingTutor?.tutor?.isOfferInPersonTutoring ||
                      pendingTutor?.tutor?.isOfferInPersonTutoring == false)
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor &&
                  (pendingTutor?.tutor?.isOfferInPersonTutoring ||
                    pendingTutor?.tutor?.isOfferInPersonTutoring == false)
                    ? pendingTutor?.tutor?.isOfferInPersonTutoring
                      ? 'Yes'
                      : 'No'
                    : pendingTutor?.userId.tutor?.isOfferInPersonTutoring
                    ? 'Yes'
                    : 'No'}
                </span>
              )}
            </>
          }
        />
      </div>
      {!!singleTutor?.tutor.onlineSessionLink && (
        <div className="row row-margin view-detail-font">
          <StyledRow2
            field="Online Session Link"
            description={
              singleTutor?.tutor && singleTutor?.tutor?.onlineSessionLink
                ? singleTutor?.tutor?.onlineSessionLink
                : '-'
            }
          />
        </div>
      )}
      {!!(
        pendingTutor?.tutor?.onlineSessionLink ||
        pendingTutor?.userId.tutor?.onlineSessionLink
      ) && (
        <div className="row row-margin view-detail-font">
          <StyledRow2
            field="Online Session Link"
            description={
              pendingTutor?.tutor && pendingTutor?.tutor?.onlineSessionLink
                ? pendingTutor?.tutor?.onlineSessionLink
                : 'Removed'
            }
          />
        </div>
      )}
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Market"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.marketId && singleTutor?.marketId?.marketId
                    ? singleTutor?.marketId.marketName
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor?.marketId ? 'colorChange' : ''
                  }
                >
                  {pendingTutor?.marketId && pendingTutor?.marketId?.marketId
                    ? pendingTutor?.userId?.marketId?.marketName
                    : '-'}
                </span>
              )}
            </>
          }
        />
        {path !== 'pendingProfile' && (
          <StyledRow2
            field="Available on mySylvan Marketplace"
            description={
              singleTutor?.tutor &&
              singleTutor?.tutor?.isAvailableOnMySylvanMarketplace
                ? 'Yes'
                : 'No'
            }
          />
        )}
        <StyledRow2
          field="Register Date"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {`${createdAt ? createdAt.format('MM-DD-YYYY') : '-'} ${
                    singleTutor?.metaData?.createdAtT
                      ? singleTutor?.metaData.createdAtT
                      : 'EDT'
                  }`}
                </>
              ) : (
                <>
                  {`${createdAt ? createdAt.format('MM-DD-YYYY') : '-'} ${
                    pendingTutor?.userId?.metaData?.createdAtT
                      ? pendingTutor?.userId?.metaData.createdAtT
                      : 'EDT'
                  }`}
                </>
              )}
            </>
          }
        />
        {path !== 'pendingProfile' && (
          <StyledRow2
            field="Admin Created"
            description={
              singleTutor?.tutor && singleTutor?.adminCreated ? 'Yes' : 'No'
            }
          />
        )}
      </div>

      <div className="row row-margin view-detail-font">
        <div className="col-sm-12 font-size-20">
          <b>Additional Info</b>
        </div>
      </div>

      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Hourly Rate"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor && singleTutor?.tutor?.hourlyRate
                    ? '$' + singleTutor?.tutor.hourlyRate + '/hr.'
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.hourlyRate
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor?.tutor && pendingTutor?.tutor?.hourlyRate
                    ? '$' + pendingTutor?.tutor.hourlyRate + '/hr.'
                    : pendingTutor?.userId.tutor?.hourlyRate
                    ? '$' + pendingTutor?.userId.tutor.hourlyRate + '/hr.'
                    : '-'}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="Cancelation Policy"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor &&
                  singleTutor?.tutor?.cancellationPolicy &&
                  singleTutor?.tutor?.cancellationPolicy.name
                    ? singleTutor?.tutor.cancellationPolicy.name
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor?.tutor?.cancellationPolicy &&
                    pendingTutor?.tutor?.cancellationPolicy.name
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor &&
                  pendingTutor?.tutor &&
                  pendingTutor?.tutor.cancellationPolicy &&
                  pendingTutor?.tutor.cancellationPolicy.name
                    ? pendingTutor?.tutor.cancellationPolicy.name
                    : pendingTutor &&
                      pendingTutor?.userId &&
                      pendingTutor?.userId.tutor &&
                      pendingTutor?.userId.tutor.cancellationPolicy &&
                      pendingTutor?.userId.tutor.cancellationPolicy.name
                    ? pendingTutor?.userId.tutor.cancellationPolicy.name
                    : '-'}
                </span>
              )}
            </>
          }
        />
      </div>

      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Maximum Distance(in miles)"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor && singleTutor?.tutor?.maxDistance
                    ? singleTutor?.tutor.maxDistance
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.maxDistance
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor?.tutor && pendingTutor?.tutor?.maxDistance
                    ? pendingTutor?.tutor.maxDistance
                    : pendingTutor?.userId &&
                      pendingTutor?.userId.tutor &&
                      pendingTutor?.userId.tutor?.maxDistance
                    ? pendingTutor?.userId.tutor.maxDistance
                    : '-'}
                </span>
              )}
            </>
          }
        />
      </div>
    </div>
  )
}

export default BuildProfile
