import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { APIKeys } from '../../apiKeys.type'
import ApiKeyTableBody from './ApiKeyTableBody'
import { apiKeysHeading } from './data'

interface ApiKeyTableI {
  allKeys: APIKeys[]
  getAllKeys: () => Promise<void>
}

const ApiKeyTable: FC<ApiKeyTableI> = ({ allKeys, getAllKeys }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ '&:hover': { background: 'none' } }}>
            {apiKeysHeading.map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!allKeys.length &&
            allKeys.map((key) => {
              return (
                <ApiKeyTableBody
                  apiKey={key}
                  key={key._id}
                  getAllKeys={getAllKeys}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ApiKeyTable
