import { Button } from '@mui/material'
import { FC, useState } from 'react'
import { FaAngleDoubleDown, FaAngleDoubleUp, FaPencilAlt } from 'react-icons/fa'

import CustomCardWrapper from '../../../../../../components/custom-card-wrapper/CustomCardWrapper'
import { ReferralCases } from '../../../../../../types/constants.type'
import { referralTerms } from '../../data'
import EditRTermAndCondition from '../edit-rTerm-conditions/EditRTermAndCondition'
import './rTerm-and-conditions-component.scss'

interface RTermsConditionI {
  referralCases?: ReferralCases
  caseNumber: string
  selectedPolicy?: string
  onViewClick?: () => void
  onHideClick?: () => void
  getConstants: () => Promise<void>
}
const RTermsAndConditionComponent: FC<RTermsConditionI> = ({
  onViewClick,
  onHideClick,
  caseNumber,
  referralCases,
  selectedPolicy,
  getConstants,
}) => {
  const [editPolicy, setEditPolicy] = useState(false)

  const getAValue = () => {
    if (caseNumber === '1' && referralCases?.case1) {
      return (
        (referralCases?.case1.tutorADiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case1.tutorADiscount.discount.toFixed(2) +
        (referralCases?.case1.tutorADiscount.type == 'PERCENT' ? '%' : '')
      )
    } else if (referralCases?.case2 && caseNumber === '2') {
      return (
        (referralCases?.case2.tutorDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case2.tutorDiscount.discount.toFixed(2) +
        (referralCases?.case2.tutorDiscount.type == 'PERCENT' ? '%' : '')
      )
    } else if (referralCases?.case3 && caseNumber === '3') {
      return (
        (referralCases?.case3.tutorDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case3.tutorDiscount.discount.toFixed(2) +
        (referralCases?.case3.tutorDiscount.type == 'PERCENT' ? '%' : '')
      )
    } else if (referralCases?.case4 && caseNumber === '4') {
      return (
        (referralCases?.case4.parentDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case4.parentDiscount.discount.toFixed(2) +
        (referralCases?.case4.parentDiscount.type == 'PERCENT' ? '%' : '') +
        ' off their next session after the tutor delivers a completed session'
      )
    } else if (referralCases?.case5 && caseNumber === '5') {
      return (
        (referralCases?.case5.parentADiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case5.parentADiscount.discount.toFixed(2) +
        (referralCases?.case5.parentADiscount.type == 'PERCENT' ? '%' : '') +
        ' off their next session after referred family completes the first session'
      )
    }
  }
  const getBValue = () => {
    if (referralCases?.case1 && caseNumber === '1') {
      return (
        (referralCases?.case1.tutorBDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case1.tutorBDiscount.discount.toFixed(2) +
        (referralCases?.case1.tutorBDiscount.type == 'PERCENT' ? '%' : '')
      )
    } else if (referralCases?.case2 && caseNumber === '2') {
      return (
        (referralCases?.case2.parentDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case2.parentDiscount.discount.toFixed(2) +
        (referralCases?.case2.parentDiscount.type == 'PERCENT' ? '%' : '') +
        ' off their first session'
      )
    } else if (referralCases?.case3 && caseNumber === '3') {
      return (
        (referralCases?.case3.parentDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case3.parentDiscount.discount.toFixed(2) +
        (referralCases?.case3.parentDiscount.type == 'PERCENT' ? '%' : '') +
        ' off hourly rate'
      )
    } else if (referralCases?.case4 && caseNumber === '4') {
      return (
        (referralCases?.case4.tutorDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case4.tutorDiscount.discount.toFixed(2) +
        (referralCases?.case4.tutorDiscount.type == 'PERCENT' ? '%' : '')
      )
    } else if (referralCases?.case5 && caseNumber === '5') {
      return (
        (referralCases?.case5.parentBDiscount.type === 'FLAT' ? '$' : '') +
        referralCases?.case5.parentBDiscount.discount.toFixed(2) +
        (referralCases?.case5.parentBDiscount.type == 'PERCENT' ? '%' : '') +
        ' off their first session'
      )
    }
  }
  const getAHeading = () => {
    if (
      (referralCases?.case1 && caseNumber === '1') ||
      (referralCases?.case2 && caseNumber === '2') ||
      (referralCases?.case3 && caseNumber === '3')
    ) {
      return 'Tutor A Discount:'
    } else if (
      (referralCases?.case4 && caseNumber === '4') ||
      (referralCases?.case5 && caseNumber === '5')
    ) {
      return 'Parent A Discount:'
    }
  }
  const getBHeading = () => {
    if (
      (referralCases?.case1 && caseNumber === '1') ||
      (referralCases?.case4 && caseNumber === '4')
    ) {
      return 'Tutor B Discount:'
    } else if (
      (referralCases?.case2 && caseNumber === '2') ||
      (referralCases?.case3 && caseNumber === '3') ||
      (referralCases?.case5 && caseNumber === '5')
    ) {
      return 'Parent B Discount:'
    }
  }

  const getTriggerPoints = () => {
    if (referralCases?.case1 && caseNumber === '1') {
      return referralTerms.case1.triggerPoint
    } else if (referralCases?.case2 && caseNumber === '2') {
      return referralTerms.case2.triggerPoint
    } else if (referralCases?.case3 && caseNumber === '3') {
      return referralTerms.case3.triggerPoint
    } else if (referralCases?.case4 && caseNumber === '4') {
      return referralTerms.case4.triggerPoint
    } else if (referralCases?.case5 && caseNumber === '5') {
      return referralTerms.case5.triggerPoint
    }
  }
  const getNames = () => {
    if (referralCases?.case1 && caseNumber === '1') {
      return referralTerms.case1.policyName
    } else if (referralCases?.case2 && caseNumber === '2') {
      return referralTerms.case2.policyName
    } else if (referralCases?.case3 && caseNumber === '3') {
      return referralTerms.case3.policyName
    } else if (referralCases?.case4 && caseNumber === '4') {
      return referralTerms.case4.policyName
    } else if (referralCases?.case5 && caseNumber === '5') {
      return referralTerms.case5.policyName
    }
  }

  return (
    <CustomCardWrapper className="p-4">
      <div className="col-sm-5">
        <label>Name: </label> <span>{getNames()}</span>
      </div>
      <div className="col-sm-6 text-end">
        <span
          className="mt15 cursor-pointer me-3"
          onClick={() => {
            setEditPolicy(true)
          }}
        >
          <FaPencilAlt size={18} />
        </span>
        {selectedPolicy !== caseNumber ? (
          <Button
            endIcon={<FaAngleDoubleDown />}
            onClick={() => {
              onViewClick?.()
            }}
          >
            View More
          </Button>
        ) : (
          <Button
            endIcon={<FaAngleDoubleUp />}
            onClick={() => {
              onHideClick?.()
            }}
          >
            Hide Detail
          </Button>
        )}
      </div>
      {selectedPolicy === caseNumber && (
        <div className="col-sm-12 policy-text text-start ">
          <div className="row mb21">
            <div className="col-sm-3">Trigger Point:</div>
            <div className="col-sm-9">{getTriggerPoints()}</div>
          </div>
          <div className="row mb21">
            <div className="col-sm-3">{getAHeading()} </div>
            <div className="col-sm-9">{getAValue()}</div>
          </div>
          <div className="row mb21">
            <div className="col-sm-3">{getBHeading()}</div>
            <div className="col-sm-9">{getBValue()}</div>
          </div>
        </div>
      )}
      {editPolicy && (
        <EditRTermAndCondition
          open={editPolicy}
          onClose={() => {
            setEditPolicy(false)
          }}
          caseNumber={caseNumber}
          referralCases={referralCases}
          getAHeading={getAHeading}
          getBHeading={getBHeading}
          getTriggerPoints={getTriggerPoints}
          onSuccess={getConstants}
        />
      )}
    </CustomCardWrapper>
  )
}

export default RTermsAndConditionComponent
