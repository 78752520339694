import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(Button)({
  padding: '8px 0 8px',
  color: '#fff',
  fontSize: '18px',
  borderRadius: '25px',
  minWidth: '100%',
  fontFamily: 'ProximaNova-Reg',
  margin: '15px 0px',
  '& .MuiButton-endIcon': {
    position: 'absolute',
    right: 16,
  },
})
