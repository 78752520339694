import { TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'

import { PartnerCode } from '../../AllPartnershipCode.type'
import AddEditPartnershipCode from '../add-edit-partnership-code/AddEditPartnershipCode'
import './partnership-code.scss'

interface PartnershipCodeTableBodyI {
  partnershipCode: PartnerCode
  getPartnershipCodes: () => Promise<void>
  isSuppressChargesAlready?: number
}

const PartnershipCodeTableBody: FC<PartnershipCodeTableBodyI> = ({
  partnershipCode,
  getPartnershipCodes,
  isSuppressChargesAlready,
}) => {
  const isSuperAdmin = localStorage.getItem('isSuperAdmin')

  const [editCode, setEditCode] = useState(false)

  return (
    <>
      <TableRow className="table-align-center fee-table-padding">
        <TableCell>{partnershipCode.partnercode}</TableCell>
        <TableCell
          sx={{
            maxWidth: '250px',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}
        >
          {partnershipCode.partnerCodeDescription}
        </TableCell>
        <TableCell>{partnershipCode.hourlyRate}</TableCell>
        <TableCell>
          {partnershipCode.case1 && partnershipCode.case1.discount
            ? partnershipCode.case1.discount
            : '-'}
        </TableCell>
        <TableCell>
          {partnershipCode.startTime
            ? dayjs(partnershipCode.startTime).utc().format('MM-DD-YYYY')
            : '-'}
        </TableCell>
        <TableCell>
          {' '}
          {partnershipCode.endTime
            ? dayjs(partnershipCode.endTime).utc().format('MM-DD-YYYY')
            : 'n/a'}
        </TableCell>
        <TableCell>
          {partnershipCode.isRateChargeSuppress ? 'Yes' : 'No'}
        </TableCell>
        <TableCell>
          {partnershipCode.isPreventManualUsage ? 'Yes' : 'No'}
        </TableCell>
        <TableCell>{partnershipCode.isBypassMarket ? 'Yes' : 'No'}</TableCell>
        {isSuperAdmin && (
          <TableCell>
            <span className="cursor-pointer">
              {partnershipCode.isActive && (
                <FaPencilAlt
                  onClick={() => {
                    setEditCode(true)
                  }}
                />
              )}
            </span>
          </TableCell>
        )}
      </TableRow>
      {editCode && (
        <AddEditPartnershipCode
          open={editCode}
          mode="Edit"
          onClose={() => {
            setEditCode(false)
          }}
          editCode={partnershipCode}
          onSuccess={getPartnershipCodes}
          isSuppressChargesAlready={isSuppressChargesAlready}
        />
      )}
    </>
  )
}

export default PartnershipCodeTableBody
