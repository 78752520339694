import { Checkbox } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import StyledDropdown, {
  Options,
} from '../../../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { Tutor } from '../../../../../../types/tutor.type'
import { CONSTANT } from '../../../../../../utils/constants'

interface BuildProfileIProps {
  cancellationPolicyOptions: Options[]
  singleTutor: Tutor
  checkDisable: (check: boolean, isOfferOnlineTutoring: boolean) => void
  isAvailableDisable: boolean
}

const BuildProfile: React.FC<BuildProfileIProps> = ({
  cancellationPolicyOptions,
  singleTutor,
  checkDisable,
  isAvailableDisable,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const maxDistanceOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
    (val) => ({ id: val, option: val.toString(), value: val.toString() }),
  )

  const isOfferInPersonTutoring = watch('isOfferInPersonTutoring')

  return (
    <div className="without-arrows">
      <h5 className="heading-container">Build Your Profile</h5>
      <div className="row">
        <div className="form-group col-sm-6">
          <div className="row p-0">
            <div className="col-sm-12">
              <label htmlFor="profileHeadline" className="pad0">
                Profile Headline
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="profileHeadLine"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Profile Headline"
                    required={singleTutor?.tutor.profileStep === 7}
                    isError={
                      !field.value && singleTutor?.tutor.profileStep === 7
                    }
                    errorMessage={
                      !field.value && singleTutor?.tutor.profileStep === 7
                        ? 'Profile Headline is required.'
                        : ''
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-sm-6">
          <div className="row p-0">
            <div className="col-sm-12">
              <label className="pad0">About you</label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="aboutMe"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="About you"
                    required={singleTutor?.tutor.profileStep === 7}
                    isError={
                      !field.value && singleTutor?.tutor.profileStep === 7
                    }
                    errorMessage={
                      !field.value && singleTutor?.tutor.profileStep === 7
                        ? 'About you is required.'
                        : ''
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-sm-6">
          <div className="row p-0">
            <div className="col-sm-12">
              <span className="lightText">Accepting New Families</span>&nbsp;
              <Controller
                control={control}
                name="acceptingNewFamilies"
                render={({ field }) => (
                  <Checkbox
                    className="p-0"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-sm-12">
              <span className="lightText">Offers Online Tutoring</span>&nbsp;
              <Controller
                control={control}
                name="isTutorOfferOnlineClasses"
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    className="p-0"
                    onChange={(e) => {
                      field.onChange(e.target.checked)
                      setValue('isTutorOfferOnlineClasses', e.target.checked)
                      checkDisable(false, e.target.checked)
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-12">
              <span className="lightText">Offers In-Person Tutoring</span>&nbsp;
              <Checkbox
                checked={
                  isOfferInPersonTutoring ? isOfferInPersonTutoring : false
                }
                className="p-0"
                onChange={(e) => {
                  setValue('isOfferInPersonTutoring', e.target.checked)
                }}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-sm-12">
              <span className="lightText">
                Available on mySylvan Marketplace
              </span>
              &nbsp;
              <Controller
                control={control}
                name="isAvailableOnMySylvanMarketplace"
                render={({ field }) => (
                  <Checkbox
                    className="p-0"
                    checked={field.value}
                    disabled={isAvailableDisable}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-sm-6">
          <div className="row p-0">
            <div className="col-sm-12">
              <label className="pad0">Online Session Link</label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="onlineSessionLink"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Online Session Link"
                    isError={!!errors.onlineSessionLink}
                    errorMessage={errors.onlineSessionLink?.message?.toString()}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row rowM mt20">
        <div className="col-sm-12 font-siz20">
          <b>Additional Info</b>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-sm-12 col-xs-12">
          <div className="row p-0">
            <div className="col-md-6  ">
              <div className="row">
                <div className="col-sm-12">
                  <label htmlFor="hourlyRate" className="pad0">
                    Hourly Rate($/hr.)
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10">
                  <Controller
                    control={control}
                    name="hourlyRate"
                    render={({ field }) => (
                      <StyledTextfield
                        {...field}
                        value={field.value?.toString()}
                        onChange={(e) => {
                          if (
                            CONSTANT.numberRegex.test(e.target.value) ||
                            e.target.value === ''
                          ) {
                            field.onChange(e.target.value)
                          }
                        }}
                        placeholder="Hourly Rate"
                        required={singleTutor?.tutor.profileStep === 7}
                        isError={
                          !field.value && singleTutor?.tutor.profileStep === 7
                        }
                        errorMessage={
                          !field.value && singleTutor?.tutor.profileStep === 7
                            ? 'Hourly rate is required.'
                            : ''
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6  ">
              <div className="row">
                <div className="col-sm-12">
                  <label htmlFor="cancellationPolicy" className="pad0">
                    Cancelation Policy
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10">
                  <Controller
                    control={control}
                    name="cancellationPolicy"
                    render={({ field }) => (
                      <StyledDropdown
                        {...field}
                        option={cancellationPolicyOptions}
                        placeholder="---Select Cancelation Policy---"
                        required={singleTutor?.tutor.profileStep === 7}
                        isError={
                          !field.value && singleTutor?.tutor.profileStep === 7
                        }
                        errorMessage={
                          !field.value && singleTutor?.tutor.profileStep === 7
                            ? 'Cancellation Policy is required.'
                            : ''
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOfferInPersonTutoring && (
        <div className="row">
          <div className="form-group col-sm-6">
            <div className="row p-0">
              <div className="col-sm-12">
                <label htmlFor="maxDistance " className="pad0">
                  What is the maximum distance that you would be willing to
                  travel to meet a student (in miles)?
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10">
                <Controller
                  control={control}
                  name="maxDistance"
                  render={({ field }) => (
                    <StyledDropdown
                      option={maxDistanceOptions}
                      {...field}
                      value={field.value?.toString()}
                      onChange={(e) => setValue('maxDistance', +e.target.value)}
                      placeholder="---Select Distance---"
                      // required={singleTutor?.tutor.profileStep === 7}
                      // isError={
                      //   !field.value && singleTutor?.tutor.profileStep === 7
                      // }
                      // errorMessage={
                      //   !field.value && singleTutor?.tutor.profileStep === 7
                      //     ? 'Max Distance is required.'
                      //     : ''
                      // }
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default BuildProfile
