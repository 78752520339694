import ModalWrapper from '@/components/modal-wrapper/ModalWrapper'
import { Button } from '@mui/material'
import { FC } from 'react'

import './open-market-modal.scss'

interface OpenMarketModalProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const OpenMarketModal: FC<OpenMarketModalProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  return (
    <ModalWrapper handleClose={onClose} {...{ open }} wide>
      <div className="d-flex justify-content-center align-items-center flex-column open-market-modal">
        <div className="w-100">
          <h1 className="text-center open-market-modal__main mb-3">
            Confirmation
          </h1>
        </div>
        <p className="open-market-modal__title text-center">
          This parent is not part of a defined market so will be added to the
          Nationwide market and be able to only do online sessions. Do you want
          to continue with this change?
        </p>
        <div>
          <Button color="success" className="modal-button" onClick={onClose}>
            No, don't add address
          </Button>
          <Button color="success" className="modal-button" onClick={onSuccess}>
            Yes, continue with change
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default OpenMarketModal
