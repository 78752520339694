import { Card, CardContent } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'

import { Address } from '../../../../../../../../types/parent.type'
import { api } from '../../../../../../../../utils/api'
import './address-component.scss'

interface AddressComponentProps {
  address?: Address
  parentId?: string
  getSingleParent: () => void
  setEditAddress: React.Dispatch<React.SetStateAction<string>>
  setAddressMode: React.Dispatch<React.SetStateAction<'Add' | 'Edit'>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<Address | undefined>>
  setLabel: React.Dispatch<React.SetStateAction<string>>
  setNewAddressSelected: React.Dispatch<React.SetStateAction<boolean>>
}

const AddressComponent: FC<AddressComponentProps> = ({
  address,
  parentId,
  setEditAddress,
  setAddressMode,
  setSelectedAddress,
  setLabel,
  setNewAddressSelected,
  getSingleParent,
}) => {
  const [currentAddress, setCurrentAddress] = useState('')
  const deleteAddress = async (label?: string) => {
    if (label?.toLowerCase() === 'home') {
      toast.error('Cannot delete home address')
      return
    }
    const formData = new FormData()
    formData.append('parentId', parentId as string)
    const res = await api.delete(
      `parent/deleteAddress/${address?._id}`,
      {},
      { data: { parentId } },
    )

    if (res.ok) {
      getSingleParent()
    }
  }

  useEffect(() => {
    const currentAddress =
      address?.addressLine1 +
      (address?.addressLine2 ? ', ' + address?.addressLine2 : '') +
      (address?.city ? ', ' + address.city : '') +
      (address?.state ? ', ' + address.state : '') +
      (address?.zipCode ? ', ' + address.zipCode : '')

    setCurrentAddress(currentAddress)
  }, [address])

  return (
    <Card sx={{ background: 'transparent' }}>
      <CardContent>
        <div className="row">
          <div className="col-sm-10">
            <label className="addressText">
              <b>Label:</b>&nbsp;
              {address?.label}
            </label>
            <label className="addressText">
              <b>Address:</b>&nbsp;
              {currentAddress}
            </label>
          </div>

          <div className="col-sm-1 icons">
            <div className="menu-icon">
              <FaPencilAlt
                onClick={() => {
                  setAddressMode('Edit')
                  setEditAddress(currentAddress)
                  setSelectedAddress(address as Address)
                  setLabel(address?.label as string)
                  setNewAddressSelected(false)
                }}
              />
            </div>
            <div
              className="menu-icon"
              onClick={() => deleteAddress(address?.label)}
            >
              <FaTrashAlt />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default AddressComponent
