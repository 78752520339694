import { create } from 'apisauce'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { toast } from 'react-hot-toast'

import routes from '../common/routes'
import { API_URL } from './urls'

dayjs.extend(utc)
dayjs.extend(timezone)

const baseURL = new URL('/api/v1', API_URL).href

const utcoffset = new Date().getTimezoneOffset() * 60000 * -1
const utcoffsetzone = dayjs.tz.guess()

export const api = create({
  baseURL,
  headers: {
    'content-language': 'en',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-Content-Type-Options': 'nosniff',
    utcoffset,
    utcoffsetzone,
  },
})

api.addAsyncRequestTransform(async (request) => {
  let token = localStorage.getItem('token')
  if (!token) return

  token = `Bearer ${token}`
  request.headers['Authorization'] = token
})

const disableNotificationUrls = [
  { method: 'post', url: 'admin/keys', pageUrl: routes.apiKeys },
  { method: 'put', url: 'admin/updateConstants', pageUrl: routes.cmsLogin },
  {
    method: 'put',
    url: 'notifications/readNotification',
    pageUrl: 'any',
  },
  {
    method: 'PUT',
    url: 'notifications/readNotifications',
    pageUrl: 'any',
  },
  {
    method: 'get',
    url: 'notifications/getNotifications',
    pageUrl: 'any',
  },
]

api.addAsyncResponseTransform(async (response) => {
  if (response.status === 401) {
    toast.error(response.data.message, { id: '401' })
  }
  if (!response.ok && response.status !== 401) {
    const { url, method } = response.config ?? {}
    const disabledUrl = disableNotificationUrls.some(
      ({ method: m, url: u, pageUrl }) => {
        return (
          method === m &&
          u === url &&
          location.pathname.split('pages/')[1] === pageUrl
        )
      },
    )
    if (!disabledUrl) {
      if (response?.data?.message) toast.error(response.data.message)
    }
  }

  if (
    ['POST', 'PUT', 'DELETE'].some(
      (status) => response.config?.method?.toUpperCase() === status,
    ) &&
    response.ok
  ) {
    const { url, method } = response.config ?? {}
    const disabledUrl = disableNotificationUrls.some(
      ({ method: m, url: u, pageUrl }) => {
        return (
          method === m &&
          u === url &&
          (location.pathname.split('pages/')[1] === pageUrl ||
            pageUrl === 'any')
        )
      },
    )

    if (!disabledUrl) {
      toast.success(response.data.message, { id: 'global' })
    }
  }
})
