import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material'
import { FC } from 'react'

import { Admin } from '../../admin.type'
import AdminTableBody from './AdminTableBody'
import { adminHeading } from './data'

interface AdminTableI {
  allAdmins: Admin[]
  getAllAdmins: () => Promise<void>
}

const AdminTable: FC<AdminTableI> = ({ allAdmins, getAllAdmins }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            className="table-align-center"
            sx={{ '&:hover': { background: 'none' } }}
          >
            {adminHeading.map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!allAdmins.length &&
            allAdmins.map((admin) => {
              return (
                <AdminTableBody
                  admin={admin}
                  getAllAdmins={getAllAdmins}
                  key={admin._id}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdminTable
