import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'

import { useAppContext } from '../../../../contexts/AppContext'
import { api } from '../../../../utils'
import './all-reservations.scss'
import { Data, ParentReservations, Reservation } from './all-reservations.type'
import CancelReservationModal from './components/cancel-reservation/CancelReservationModal'
import ExtendExp from './components/extend-exp/ExtendExp'

dayjs.extend(utc)
dayjs.extend(tz)

interface ICancelReservation {
  reservationId: string
  parentName: string
  parentId: string
}
interface IExtendExp {
  reservationId: string
  parentId: string
  currentExp: Date
  reservationNum: string
}

function AllReservations() {
  const [reservationsData, setReservationsData] = useState<Data | null>(null)
  const { updateAppState } = useAppContext()
  const [cancelResModalData, setCancelResModalData] =
    useState<ICancelReservation | null>(null)

  const [extendExpData, setExtendExpData] = useState<IExtendExp | null>(null)

  const parentId = localStorage.getItem('parentId')
  const parentName = localStorage.getItem('parentName')
  const isSuperAdmin =
    localStorage.getItem('isSuperAdmin') === 'true' ? true : false
  const isMarketPlaceManager =
    localStorage.getItem('isMarketplaceManager') === 'true' ? true : false

  const getParentData = async () => {
    updateAppState({ loading: true })
    const res = await api.get<ParentReservations>('/admin/getReservations', {
      parentId,
    })
    if (res.ok && res.data) {
      const parentData = res.data.data
      setReservationsData(parentData)
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getParentData()
  }, [])

  const cancelReservation = (reservationId: string) => {
    if (parentId && parentName) {
      setCancelResModalData({ reservationId, parentId, parentName })
    }
  }
  const extendExpDate = (reservation: Reservation) => {
    if (parentId && parentName) {
      setExtendExpData({
        reservationId: reservation._id,
        parentId,
        currentExp: reservation.expirationDate,
        reservationNum: reservation.reservationNumber,
      })
    }
  }

  const shouldShowExtendDateButton = (reservation: Reservation) => {
    let show = true

    if (!isSuperAdmin) show = false
    if (!isMarketPlaceManager) show = false
    if (reservation.hoursReserved === reservation.hoursBillable) show = false
    if (reservation.isCanceled) show = false

    return show
  }

  return (
    <div className="all-reservations">
      <h2 className="all-reservations__title">Reservations for {parentName}</h2>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Reservation Number</TableCell>
              <TableCell>Customer GUID</TableCell>
              <TableCell>
                Hours <br /> Reserved
              </TableCell>
              <TableCell>Reservation Date</TableCell>
              <TableCell>Expiration Date</TableCell>
              <TableCell>
                Hours Upcoming/
                <br />
                In Progress
              </TableCell>
              <TableCell>
                Hours
                <br /> Billable
              </TableCell>
              <TableCell>
                Hours <br />
                Expired
              </TableCell>
              <TableCell>
                Hours
                <br /> Remaining
              </TableCell>
              <TableCell>Canceled</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservationsData?.reservations.length ? (
              reservationsData.reservations.map((res) => (
                <TableRow id={res._id} key={res._id}>
                  <TableCell>{res.reservationNumber}</TableCell>
                  <TableCell>
                    {res.customerGUID ? res.customerGUID : '-'}
                  </TableCell>
                  <TableCell>{res.hoursReserved}</TableCell>
                  <TableCell>
                    {dayjs(res.reservationDate).utc().format('MMM DD, YYYY')}
                  </TableCell>
                  <TableCell>
                    {dayjs(res.expirationDate).format('MMM DD, YYYY')}
                  </TableCell>
                  <TableCell>{res.hoursUpcoming}</TableCell>
                  <TableCell>{res.hoursBillable}</TableCell>
                  <TableCell>{res.hoursExpired}</TableCell>
                  <TableCell>{res.hoursRemaining}</TableCell>
                  <TableCell>{res.isCanceled ? 'yes' : '-'}</TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      disabled={!res.isCancelable}
                      onClick={() => cancelReservation(res._id)}
                    >
                      Cancel Reservation
                    </Button>

                    <Button
                      sx={{ margin: '5px' }}
                      variant="text"
                      disabled={!shouldShowExtendDateButton(res)}
                      onClick={() => extendExpDate(res)}
                    >
                      Extend Exp. Date
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10}>Record not found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {cancelResModalData && (
        <CancelReservationModal
          open={!!cancelResModalData}
          onClose={() => setCancelResModalData(null)}
          data={cancelResModalData}
          onCancelReservation={getParentData}
        />
      )}
      {extendExpData && (
        <ExtendExp
          open={!!extendExpData}
          onClose={() => {
            setExtendExpData(null)
          }}
          data={extendExpData}
          onSuccess={getParentData}
        />
      )}
    </div>
  )
}

export default AllReservations
