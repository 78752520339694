import { FC } from 'react'

import './custom-card-wrapper.scss'

interface CustomCardI {
  children: any
  className?: string
}

const CustomCardWrapper: FC<CustomCardI> = ({ children, className }) => {
  return (
    <div className="col-md-12 all-policy cursor-pointer">
      <div
        className={`dash-pad-inner row  align-items-center ${
          className ? className : ''
        }`}
      >
        {children}
      </div>
    </div>
  )
}

export default CustomCardWrapper
