import { useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppContext } from '../../contexts/AppContext'
import { Student, StudentResponse } from '../../types/child.type'
import { api } from '../../utils'
import ChildTable from './components/child-table/ChildTable'
import './view-child.scss'

const ViewChild = () => {
  const [students, setStudents] = useState<Student[] | null>([])
  const navigate = useNavigate()
  const { updateAppState } = useAppContext()
  const { parentId } = useParams()

  const getChild = async () => {
    updateAppState({ loading: true })
    const res = await api.get<StudentResponse>('parent/getStudents', {
      parentId,
    })

    if (res.ok && res.data) {
      setStudents(
        res.data.data.sort(
          (a, b) =>
            a.firstName.localeCompare(b.firstName) ||
            a.lastName.localeCompare(b.lastName),
        ),
      )
    }
    updateAppState({ loading: false })
  }
  useEffect(() => {
    if (parentId) getChild()
  }, [])

  return (
    <div className="container-fluid">
      <div className="view-child-header">
        <div className="d-flex">
          <div className="col-sm-1 ms-5">
            <FaArrowLeft
              size={25}
              className="back-icon"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="col-sm-10 text-center">
            <h1 className="child-heading">
              Children of {localStorage.getItem('parentName')}
            </h1>
          </div>
        </div>
      </div>
      <div className="detail-body">
        <ChildTable students={students} getChild={getChild} />
      </div>
    </div>
  )
}

export default ViewChild
