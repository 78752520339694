import CustomDatePicker from '@/components/date-picker/CustomDatePicker'
import StyledTextfield from '@/components/styled-textfield/StyledTextfield'
import { useAppContext } from '@/contexts/AppContext'
import { downloadUrl } from '@/pages/dashboard/helpers'
import { FileResponse } from '@/types/file.type'
import { api } from '@/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Pagination } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import StudentTutorFeedbackTable from './components/student-tutor-feedback-table/StudentTutorFeedbackTable'
import './student-tutor-feedback.scss'
import {
  StudentTutorFeedbackResponse,
  StudentTutorFeedBackType,
} from './student-tutor-feedback.type'

const PAGE_LIMIT = 10

interface StudentTutorFeedbackForm {
  startDate: Dayjs | null
  endDate: Dayjs | null
  student: string
  tutor: string
}

const formSchema = Yup.object({
  startDate: Yup.object().nullable(),
  endDate: Yup.object().nullable(),
  student: Yup.string()
    .min(1, 'Student Name is required.')
    .matches(/^[^\s]+ \w+$/, "Student's full name is required."),
  tutor: Yup.string().matches(
    /^(?:[^\s]+ \w+|\s*)$/,
    "Tutor's full name is required.",
  ),
})

const StudentTutorFeedback = () => {
  const { updateAppState } = useAppContext()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const [feedBacks, setFeedBacks] = useState<StudentTutorFeedBackType[]>([])
  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<StudentTutorFeedbackForm>({
    defaultValues: {
      startDate: null,
      endDate: null,
      student: '',
      tutor: '',
    },
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  })

  const handlePageChange = (
    __event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
    getFeedbacks(value)
  }

  const getFeedbacks = async (page = 1) => {
    const formData = getValues()
    updateAppState({ loading: true })
    const query = Object.entries(formData).reduce<{ [key: string]: string }>(
      (acc, value) => {
        if (!value[1]) return acc
        if (value[0] === 'startDate') {
          acc[value[0]] = value[1].format().split('T')[0] + 'T00:00:00.000Z'
        } else if (value[0] === 'endDate') {
          acc[value[0]] = value[1].format().split('T')[0] + 'T23:59:59.999Z'
        } else {
          acc[value[0]] = value[1]
        }
        return acc
      },
      {},
    )
    try {
      const skip = (page - 1) * PAGE_LIMIT
      const result = await api.get<StudentTutorFeedbackResponse>(
        '/admin/getStudentTutorFeedback',
        { ...query, skip, limit: PAGE_LIMIT },
      )
      if (result.ok && result.data) {
        const { paginatedRecords, totalRecords } = result.data.data

        setFeedBacks(paginatedRecords)
        setTotalPages(Math.ceil(totalRecords / 10))
        setShowErrorMsg(!totalRecords)
      }
    } catch (error) {
      console.log(error)
    }
    updateAppState({ loading: false })
  }

  const handleReportDownload = async () => {
    updateAppState({ loading: true })
    const formData = getValues()
    const query = Object.entries(formData).reduce<{ [key: string]: string }>(
      (acc, value) => {
        if (!value[1]) return acc
        if (value[0] === 'startDate') {
          acc[value[0]] = value[1].format().split('T')[0] + 'T00:00:00.000Z'
        } else if (value[0] === 'endDate') {
          acc[value[0]] = value[1].format().split('T')[0] + 'T23:59:59.999Z'
        } else {
          acc[value[0]] = value[1]
        }
        return acc
      },
      {},
    )
    const { skip, limit, ...rest } = query
    const res = await api.get<FileResponse>(
      '/admin/studentTutorFeedbackReport',
      rest,
    )
    if (res.ok && res.data) {
      const url = res.data.data.url
      downloadUrl(url, 'studentTutorFeedbackReport.csv')
    }
    updateAppState({ loading: false })
  }

  const { student } = watch()

  return (
    <>
      <form
        onSubmit={handleSubmit(() => {
          setPage(1)
          getFeedbacks()
        })}
      >
        <div className="d-flex gap-4 align-items-start mt-4">
          <Controller
            name="student"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Student Name *"
                isError={!!errors?.student}
                errorMessage={errors?.student?.message}
                onChange={(value) => field.onChange(value)}
                className="flex-grow-1 student-filter-input"
                innerClass="student-filter-input-inner"
                showErrorMsgOnStart
                errorMsgClass="text-start"
              />
            )}
          />
          <Controller
            name="tutor"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Tutor Name"
                isError={!!errors?.tutor}
                errorMessage={errors?.tutor?.message}
                onChange={(value) => field.onChange(value)}
                className="flex-grow-1 student-filter-input"
                innerClass="student-filter-input-inner"
                showErrorMsgOnStart
                errorMsgClass="text-start"
              />
            )}
          />
          <div className="d-flex gap-2 flex-grow-1 student-filter-input-container">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  placeholder="Select Start Date"
                  date={getValues('startDate')}
                  onChange={(value) => field.onChange(value)}
                  onClear={() => {
                    setValue('startDate', null)
                  }}
                />
              )}
            />
            <p className="d-flex align-items-center m-0">to</p>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  placeholder="Select End Date"
                  date={getValues('endDate')}
                  onChange={(value) => field.onChange(value)}
                  onClear={() => {
                    setValue('endDate', null)
                  }}
                />
              )}
            />
          </div>
          <div className="d-flex align-items-center gap-2">
            <Button type="submit" disabled={!student}>
              Search
            </Button>
            {(!!feedBacks.length || showErrorMsg) && (
              <Button
                type="reset"
                onClick={() => {
                  setTotalPages(0)
                  setFeedBacks([])
                  setShowErrorMsg(false)
                  reset()
                }}
                color="secondary"
              >
                Clear
              </Button>
            )}
          </div>
        </div>
        {showErrorMsg && (
          <p className="student-error-msg">
            Your applied filters did not return any sessions with feedback.
            Please review your filters and try again.
          </p>
        )}
        {!!feedBacks.length && (
          <>
            <div className="row mt-5">
              <div className="cold-md-4">
                <Button onClick={handleReportDownload}>
                  Download to Excel
                </Button>
              </div>
            </div>
            <div className="mt-4">
              {feedBacks && <StudentTutorFeedbackTable data={feedBacks} />}
            </div>
            {totalPages > 1 && (
              <div className="mt-4 d-flex justify-content-end">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </>
        )}
      </form>
    </>
  )
}

export default StudentTutorFeedback
