import { useAppContext } from '@/contexts/AppContext'
import { api } from '@/utils'
import { Button, Pagination } from '@mui/material'
import { useEffect, useState } from 'react'

import FirstSessionTable from './components/FirstSessionTable'
import { FirstSessionReq, PaginatedBooking } from './first-session.type'

const PAGE_LIMIT = 10
const FirstSession = () => {
  const { downloadCsv, updateAppState } = useAppContext()
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  const [firstSessions, setFirstSessions] = useState<PaginatedBooking[]>([])

  const getFirstSession = async () => {
    const skip = (page - 1) * PAGE_LIMIT
    let obj = { limit: PAGE_LIMIT, skip }
    updateAppState({ loading: true })
    const res = await api.get<FirstSessionReq>('admin/parentFirstSession', obj)
    if (res.data) {
      const { paginatedBookings, totalBookings } = res.data.data.bookings
      setFirstSessions(paginatedBookings)
      setTotalPages(Math.ceil(totalBookings / PAGE_LIMIT))
    }
    updateAppState({ loading: false })
  }
  useEffect(() => {
    getFirstSession()
  }, [page])
  return (
    <div className="main">
      <div className="container-fluid content ps-0">
        <div className="mt-4">
          <Button
            className="download-csv-button"
            disabled={!firstSessions.length}
            onClick={() => {
              downloadCsv('firstSession')
            }}
          >
            Download CSV
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <FirstSessionTable firstSessions={firstSessions} />
      </div>
      {firstSessions && firstSessions.length > 0 ? (
        <div className="mt-2 d-flex justify-content-end">
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(_event, value) => setPage(value)}
              color="primary"
            />
          )}
        </div>
      ) : (
        <div className="mt-2 d-flex justify-content-center">
          <p>No booking found</p>
        </div>
      )}{' '}
    </div>
  )
}

export default FirstSession
