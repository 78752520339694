import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { Reason } from '../../../../../../types/constants.type'
import DeclineReasonTableBody from './DeclineReasonTableBody'

interface DeclineReasonTableI {
  reasons: Reason[]
  getConstants: () => Promise<void>
  buttonType: 'PARENT' | 'TUTOR'
}
const DeclineReasonTable: FC<DeclineReasonTableI> = ({
  reasons,
  getConstants,
  buttonType,
}) => {
  return (
    <div className="col-sm-12 col-md-10 offset-md-1">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              <TableCell className="text-center">Reason</TableCell>
              <TableCell className="text-center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reasons.map((reason, index) => {
              return (
                <DeclineReasonTableBody
                  key={index}
                  reason={reason}
                  getConstants={getConstants}
                  buttonType={buttonType}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default DeclineReasonTable
