export const allFeeHeadings = [
  'Market Name',
  'Effective Date',
  'Band 1',
  'Fee',
  'Band 2',
  'Fee',
  'Band 3',
  'Fee',
  'Action',
]
