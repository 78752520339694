import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'

interface CheckUpcomingSessionsProps {
  open: boolean
  handleClose: () => void
  PartnershipCode: string
  isKeepSession: (isKeep: boolean) => void
}

const CheckUpcomingSessions: FC<CheckUpcomingSessionsProps> = ({
  open,
  handleClose,
  PartnershipCode,
  isKeepSession,
}) => {
  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p>
          This tutor has upcoming sessions associated with code
          {PartnershipCode}. Would you like to cancel these sessions?
        </p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={() => {
              isKeepSession(true)
            }}
          >
            Keep Sessions
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={() => {
              isKeepSession(false)
            }}
          >
            Cancel Sessions
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default CheckUpcomingSessions
