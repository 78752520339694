import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { Disputes } from '../../hooks/disputes.types'
import './DisputeTableBody'
import DisputeTableBody from './DisputeTableBody'
import { getDisputeHeading } from './data'

interface DisputeTableI {
  allDisputes: Disputes[]
  getAllDisputes: () => Promise<void>
  status?: 'UNRESOLVED' | 'RESOLVED'
}

const DisputeTable: FC<DisputeTableI> = ({
  allDisputes,
  status,
  getAllDisputes,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ '&:hover': { background: 'none' } }}>
            {getDisputeHeading(status as string).map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!allDisputes.length &&
            allDisputes.map((dispute) => {
              return (
                <DisputeTableBody
                  status={status}
                  dispute={dispute}
                  key={dispute._id}
                  getAllDisputes={getAllDisputes}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DisputeTable
