import { useEffect, useState } from 'react'

import { useAppContext } from '../../../contexts/AppContext'
import { api } from '../../../utils'
import { FeedbackFilterI } from '../Feedbacks'
import { FeedbackRes, Rating } from './feedback.type'

interface useAllFeedbacksI {
  filterStates: FeedbackFilterI | null
  debouncedSearch?: string
  setLoading: (status: boolean) => void
}
const useAllFeedbacks = ({
  filterStates,
  debouncedSearch,
  setLoading,
}: useAllFeedbacksI) => {
  const { updateAppState } = useAppContext()

  const [allFeedbacks, setAllFeedbacks] = useState<Rating[]>([])
  const [totalPages, setTotalPages] = useState(0)

  const getAllFeedbacks = async () => {
    updateAppState({ loading: true })
    const queryObj = {
      startDate: filterStates?.startDate
        ? filterStates.startDate.format().split('T')[0] + 'T00:00:00.000Z'
        : null,
      endDate: filterStates?.endDate
        ? filterStates.endDate.format().split('T')[0] + 'T23:59:59.999Z'
        : null,
      marketId:
        filterStates?.marketId && filterStates.marketId !== 'none'
          ? filterStates?.marketId
          : null,

      skip: filterStates?.skip ? (filterStates.skip - 1) * 10 : 0,
      limit: 10,
      searchParam: debouncedSearch ? debouncedSearch : null,
    }
    const res = await api.get<FeedbackRes>(
      '/admin/feedbackAndReviews',
      queryObj,
    )

    if (res.ok && res.data) {
      setLoading(false)
      const { ratings, count } = res.data.data
      setAllFeedbacks(ratings)
      setTotalPages(Math.ceil(count / 10))
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getAllFeedbacks()
  }, [filterStates, debouncedSearch])
  return { allFeedbacks, totalPages, getAllFeedbacks, setAllFeedbacks }
}

export default useAllFeedbacks
