import { Button } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'

import { StyledRow } from '../../../../components/custom-bordered-row/StyledRow'
import ImageComponent from '../../../../components/image-component/ImageComponent'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import { Student, StudentResponse } from '../../../../types/child.type'
import { Parent } from '../../../../types/parent.type'
import { api } from '../../../../utils'
import OutOfHoursModal from '../out-of-hours-modal/OutOfHoursModal'
import './view-details.scss'

interface ViewDetailsProps {
  open: boolean
  handleClose: () => void
  parentId?: string
  singleParent: Parent | null | undefined
  setSingleParent: React.Dispatch<React.SetStateAction<Parent | null>>
  onRefetchData?: () => void
}
const ParentViewDetails: FC<ViewDetailsProps> = ({
  open,
  parentId,
  singleParent,
  handleClose,
  setSingleParent,
  onRefetchData,
}) => {
  const [students, setStudents] = useState<Student[] | null | undefined>([])
  const [showOutOfHours, setShowOutOfHours] = useState(false)

  useEffect(() => {
    const getChild = async () => {
      const res = await api.get<StudentResponse>('parent/getStudents', {
        parentId,
      })
      if (res.ok && res.data) {
        setStudents(res.data.data)
      }
    }
    if (parentId) getChild()
  }, [open])

  const handleViewReservation = () => {
    window.open('/pages/parent/all-parent-reservations')
  }

  const shouldShowReservationButton = () => {
    if (
      singleParent &&
      Array.isArray(singleParent.partnershipCode) &&
      singleParent.partnershipCode?.length &&
      singleParent.partnershipCode.some((code) => code.isRateChargeSuppress)
    ) {
      return true
    }
    return false
  }

  const handleViewSessions = () => {
    window.open('/pages/parent/all-parent-bookings')
  }

  return (
    <>
      <ModalWrapper
        open={open && !showOutOfHours}
        handleClose={handleClose}
        title="Parent Detail"
        wide
      >
        <div className="w-100 d-flex justify-content-center ">
          <ImageComponent profilePic={singleParent?.profilePicture} />
        </div>
        <StyledRow head details field="Field Name" description="Description" />
        <StyledRow
          details
          field="User Name"
          description={singleParent ? singleParent?.userName : '-'}
        />
        <StyledRow
          details
          field="First Name"
          description={singleParent ? singleParent?.firstName : '-'}
        />
        <StyledRow
          details
          field="Last Name"
          description={singleParent ? singleParent?.lastName : '-'}
        />
        <StyledRow
          details
          field="Email"
          description={singleParent ? singleParent?.email : '-'}
        />
        <StyledRow
          details
          field="Phone Number"
          description={singleParent ? singleParent?.phoneNumber : '-'}
        />
        <StyledRow
          details
          field="Interests - Subjects"
          description={
            <>
              {singleParent
                ? singleParent?.parent?.subjects?.map((subject, index) => {
                    return (
                      <span key={index}>
                        {index === 0 ? '' : ', '}
                        {subject.name}
                      </span>
                    )
                  })
                : ''}
              {singleParent &&
              singleParent?.parent?.interestOtherFactor &&
              singleParent?.parent?.interestOtherFactor !== '-' &&
              singleParent?.parent?.subjects.length
                ? ', '
                : ''}
              {singleParent &&
              singleParent?.parent?.interestOtherFactor &&
              singleParent?.parent?.interestOtherFactor !== '-' ? (
                <>{'Other: ' + singleParent?.parent?.interestOtherFactor}</>
              ) : (
                ''
              )}
            </>
          }
        />
        <StyledRow
          details
          field="Interests - Grades"
          description={
            singleParent
              ? singleParent?.parent?.gradeLevel?.map((grade, index) => {
                  return (
                    <span key={index}>
                      {index === 0 ? '' : ', '}
                      {grade}
                    </span>
                  )
                })
              : ''
          }
        />
        <StyledRow
          details
          field="Referral Code"
          description={singleParent ? singleParent?.referralCode1 : '-'}
        />
        <StyledRow
          details
          field="Referred by Code"
          description={singleParent ? singleParent?.referralCodeUsed : '-'}
        />
        <StyledRow
          details
          field="Referred by Name"
          description={
            singleParent
              ? singleParent?.referralCodeUsedId
                ? singleParent?.referralCodeUsedId.firstName +
                  ' ' +
                  singleParent?.referralCodeUsedId.lastName
                : '-'
              : '-'
          }
        />
        <StyledRow
          details
          field="BYOK Client"
          description={
            singleParent
              ? !singleParent?.referralCodeUsed
                ? '-'
                : singleParent?.byokClient == true
                ? 'Yes'
                : singleParent?.byokClient == false
                ? 'No'
                : ''
              : '-'
          }
        />
        <StyledRow
          details
          field="Partnership Code"
          description={
            <div className="d-flex justify-content-between">
              {singleParent &&
              singleParent?.partnershipCode &&
              singleParent?.partnershipCode[0]
                ? singleParent?.partnershipCode[0]?.partnercode
                : '-'}
              {singleParent && singleParent?.partnershipCode?.length > 0 && (
                <Button
                  color="inherit"
                  size="small"
                  className={`d-flex justify-content-end detail-buttons ${
                    shouldShowReservationButton() ? '' : 'd-none'
                  }`}
                  onClick={handleViewReservation}
                >
                  View Reservations
                </Button>
              )}
            </div>
          }
        />
        {singleParent &&
        singleParent.partnershipCode &&
        singleParent.partnershipCode[0]?.isRateChargeSuppress &&
        (singleParent.parent.numberOfTutoringHours != null ||
          singleParent.parent.numberOfTutoringHours != undefined) &&
        singleParent.parent.numberOfTutoringHours >= 0 ? (
          <StyledRow
            details
            field="Tutoring Hours Available"
            description={
              <div className="d-flex justify-content-between align-items-center">
                {singleParent && !singleParent.parent.isParentOutOfTutoringHours
                  ? singleParent.parent.numberOfTutoringHours
                  : ''}
                {singleParent.parent.numberOfTutoringHours === 0 &&
                  singleParent?.parent?.addresses[0]?.country !== 'Canada' && (
                    <>
                      {singleParent.parent.isParentOutOfTutoringHours ? (
                        'Out of Hours'
                      ) : (
                        <Button
                          color="inherit"
                          size="small"
                          className="d-flex justify-content-end detail-buttons"
                          onClick={() => setShowOutOfHours(true)}
                        >
                          Out of Tutoring Hours
                        </Button>
                      )}
                    </>
                  )}
              </div>
            }
          />
        ) : (
          <></>
        )}
        {singleParent &&
        singleParent?.parent?.sessionCreditsFromExternalApi?.length > 0 ? (
          <StyledRow
            details
            table
            field="Reservations"
            description={
              <>
                <div className="d-flex">
                  <span className="details-res">Res.#</span>
                  <span className="details-hrs">Hours</span>
                  <span className="details-exp">Exp. Date</span>
                </div>
                {singleParent?.parent.sessionCreditsFromExternalApi
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime(),
                  )
                  ?.map((res) => {
                    return (
                      <div key={res._id} className="d-flex">
                        <span className="details-res">
                          {res.reservationNumber}
                        </span>
                        <span className="details-hrs">
                          {res.numberOfTutoringHours}
                        </span>
                        <span className="details-exp">
                          {dayjs(res.endDate).format('MM/DD/YYYY')}
                        </span>
                      </div>
                    )
                  })}
              </>
            }
          />
        ) : (
          <></>
        )}

        <StyledRow
          details
          field="Register Date"
          description={
            singleParent
              ? dayjs
                  .tz(
                    singleParent?.createdAt,
                    singleParent.metaData?.timezoneOffsetZone,
                  )
                  .format('MM/DD/YYYY [at] h:mmA') +
                ' ' +
                (singleParent?.metaData?.createdAtP
                  ? singleParent?.metaData?.createdAtP
                  : 'EDT')
              : '-'
          }
        />
        <StyledRow
          details
          field="Admin Created"
          description={
            singleParent ? (singleParent?.adminCreated ? 'Yes' : 'No') : '-'
          }
        />
        <StyledRow
          details
          field="utm_source"
          description={
            singleParent
              ? singleParent?.userSource && singleParent?.userSource.utm_source
                ? singleParent?.userSource.utm_source
                : '-'
              : '-'
          }
        />
        <StyledRow
          details
          field="utm_content"
          description={
            singleParent
              ? singleParent?.userSource && singleParent?.userSource.utm_content
                ? singleParent?.userSource.utm_content
                : '-'
              : '-'
          }
        />
        <StyledRow
          details
          field="Children"
          description={students?.map((student, index) => {
            return (
              <span key={student._id}>
                {index === 0 ? '' : ', '}
                {student.firstName}&nbsp;{student.lastName}
              </span>
            )
          })}
        />
        <StyledRow
          details
          field="Address"
          description={
            singleParent
              ? singleParent?.parent?.addresses?.map((address) => {
                  return (
                    <div key={address._id} className="mt-2">
                      <div className="d-flex row">
                        <div className="col-sm-4 ms-4">
                          <b>Label :&nbsp;</b>
                        </div>
                        <div className="col-sm-7">{address.label}</div>
                      </div>
                      <div className="d-flex row">
                        <div className="col-sm-4 ms-4">
                          <b>Address :&nbsp;</b>
                        </div>

                        <div className="col-sm-7">
                          {address.addressLine1 ? address.addressLine1 : ''}
                          {address.addressLine2
                            ? ', ' + address.addressLine2
                            : ''}
                          {address.city ? ', ' + address.city : ''}
                          {address.state
                            ? ', ' + address.state
                            : ', ' + address.state}
                          {address.zipCode ? ', ' + address.zipCode : ''}
                        </div>
                      </div>
                    </div>
                  )
                })
              : '-'
          }
        />
        <StyledRow
          details
          field="Additional Notes"
          description={
            singleParent
              ? singleParent?.parent?.adminAdditionalNotes?.map(
                  (note, index) => {
                    return (
                      <div key={index}>
                        <li>{note}</li>
                      </div>
                    )
                  },
                )
              : '-'
          }
        />
        <div className="mt-5 ms-5">
          <Button
            onClick={handleViewSessions}
            color="success"
            className="blue-button me-3"
          >
            View Sessions
          </Button>
          <Button
            color="success"
            className="blue-button ms-3"
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </ModalWrapper>
      <OutOfHoursModal
        open={showOutOfHours}
        onClose={() => setShowOutOfHours(false)}
        parentId={parentId}
        setSingleParent={setSingleParent}
        onRefetchData={onRefetchData}
      />
    </>
  )
}

export default ParentViewDetails
