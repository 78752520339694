import { Radio, RadioGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { CONSTANT } from '../../../../../../utils/constants'

const PoliciesAndPreference = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const ownsVehicle = watch('ownsVehicle')
  const ownsIpad = watch('ownsIpad')
  const sylvanCertification = watch('sylvanCertification')

  return (
    <div>
      <h5 className="heading-container">Policies and Preferences</h5>
      <div className="form-group mb-0 row">
        <div className="col-sm-12">
          <label>
            Do you have your own vehicle that you are willing/able to drive to
            meet tutoring students?
          </label>
        </div>
        <div className="col-sm-12">
          <Controller
            control={control}
            name="ownsVehicle"
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={ownsVehicle != undefined ? ownsVehicle : ''}
                onChange={(e) => {
                  setValue('ownsVehicle', e.target.value === 'true')
                }}
              >
                <div className="row">
                  <div className="col-sm-2 mart20px">
                    <Radio value="true" />
                    <span>Yes</span>
                  </div>
                  <div className="col-sm-2 mart20px">
                    <Radio value="false" />
                    <span>No</span>
                  </div>
                </div>
              </RadioGroup>
            )}
          />
        </div>
        {!ownsVehicle && (
          <div className="form-group mb-0 row">
            <div className="col-sm-12 mat20">
              <label>Mode of transportation</label>
            </div>

            <Controller
              control={control}
              name="transportationMode"
              render={({ field }) => (
                <StyledTextfield
                  {...field}
                  isError={!!errors.transportationMode}
                  errorMessage={errors?.transportationMode?.message?.toString()}
                  className="col-sm-12 m-1"
                />
              )}
            />
          </div>
        )}
      </div>
      <div className="form-group mb-0 row">
        <div className="col-sm-12 mat20">
          <label className="pad0">
            Please confirm that, if approved as a {CONSTANT.PROJECT_NAME}
            &nbsp;tutor, you are willing to be contacted via text message from
            parents interested in hiring you for tutoring services.
          </label>
        </div>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 mart20px">
              <Controller
                control={control}
                name="contactable"
                render={({ field }) => {
                  return (
                    <Radio
                      checked={field.value}
                      onChange={(e) => {
                        setValue('contactable', e.target.checked)
                      }}
                    />
                  )
                }}
              />
              <span>
                Yes, I am willing to receive text messages from&nbsp;
                {CONSTANT.PROJECT_NAME}&nbsp;parents.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group mb-0 row">
        <div className="col-sm-12 mat20">
          <label className="pad0">
            Are you interested in becoming certified in the Sylvan method to
            receive access to the Sylvan curriculum and training opportunities?
          </label>
        </div>

        <div className="col-sm-12">
          <Controller
            control={control}
            name="sylvanCertification"
            render={({ field }) => {
              return (
                <RadioGroup
                  aria-labelledby="sylvanCertification"
                  value={
                    sylvanCertification || sylvanCertification === 0
                      ? sylvanCertification.toString()
                      : ''
                  }
                  name="sylvanCertification"
                  onChange={(e) =>
                    setValue('sylvanCertification', +e.target.value)
                  }
                >
                  <div className="row">
                    <div className="col-sm-2 mart20px">
                      <Radio value="1" />
                      <span>Yes</span>
                    </div>
                    <div className="col-sm-2 mart20px">
                      <Radio value="0" />
                      <span>No</span>
                    </div>
                    <div className="col-sm-4 mart20px">
                      <Radio value="2" />
                      <span>Unsure - I’d like to learn more!</span>
                    </div>
                  </div>
                </RadioGroup>
              )
            }}
          />
        </div>
      </div>
      <div className="form-group mb-0 row">
        <div className="col-sm-12 mat20">
          <label className="pad0">
            Although not required to apply, do you own an iPad?
          </label>
        </div>

        <div className="col-sm-12">
          <Controller
            control={control}
            name="ownsIpad"
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={ownsIpad != undefined ? ownsIpad : ''}
                onChange={(e) => {
                  setValue('ownsIpad', e.target.value === 'true')
                }}
              >
                <div className="row">
                  <div className="col-sm-2 mart20px">
                    <Radio value="true" />
                    <span>Yes</span>
                  </div>
                  <div className="col-sm-2 mart20px">
                    <Radio value="false" />
                    <span>No</span>
                  </div>
                </div>
              </RadioGroup>
            )}
          />
        </div>
      </div>
      {ownsIpad && (
        <div className="form-group mb-0 row">
          <div className="col-sm-12 mat20">
            <label className="pad0">
              If yes, would you be willing to use your iPad as part of
              delivering in-home tutoring?
            </label>
          </div>

          <div className="col-sm-12">
            <Controller
              control={control}
              name="willUseIpad"
              render={({ field }) => {
                return (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={field.value?.toString()}
                    onChange={(e) => setValue('willUseIpad', +e.target.value)}
                  >
                    <div className="row">
                      <div className="col-sm-2 mart20px">
                        <Radio value="1" />
                        <span>Yes</span>
                      </div>
                      <div className="col-sm-2 mart20px">
                        <Radio value="0" />
                        <span>No</span>
                      </div>
                      <div className="col-sm-4 mart20px">
                        <Radio value="2" />
                        <span>Unsure - I’d like to learn more!</span>
                      </div>
                    </div>
                  </RadioGroup>
                )
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default PoliciesAndPreference
