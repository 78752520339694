import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import ImageComponent from '../../../../components/image-component/ImageComponent'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import {
  AdditionalInfo,
  AdditionalNote,
  AlternatePricing,
  BackgroundCheck,
  BuildProfile,
  ContactInfo,
  Education,
  Policies,
} from '../../../../components/view-tutor-details-components'
import { useAppContext } from '../../../../contexts/AppContext'
import { TutorElement } from '../../../../types/changeRequest.type'
import { SingleTutorResponse, Tutor } from '../../../../types/tutor.type'
import {
  CheckTutorDeleteInfoRes,
  TutorDashboardRes,
} from '../../../../types/tutorDashboard.type'
import { api } from '../../../../utils'
import TutorStatistics from './components/TutorStatistics'
import UpcomingSessionModal, {
  DeleteErrorMsg,
} from './components/UpcomingSessionModal'
import './view-details.scss'

interface ViewDetailsProps {
  open: boolean
  handleClose: () => void
  path?: string
  pendingTutor?: TutorElement
}
const TutorViewDetails: FC<ViewDetailsProps> = ({
  open,
  handleClose,
  pendingTutor,
  path,
}) => {
  const { updateAppState, isMarketplaceManager } = useAppContext()
  const [showStatistics, setShowStatistics] = useState(false)
  const [profileLink, setProfileLink] = useState('')
  const [singleTutor, setSingleTutor] = useState<Tutor>()
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<DeleteErrorMsg>('')

  const getSingleTutor = async () => {
    updateAppState({ loading: true })
    const res = await api.get<SingleTutorResponse>('booking/getTutorById', {
      tutorId: localStorage.getItem('tutorId'),
    })
    const adminDashboardRes = await api.get<TutorDashboardRes>(
      'admin/viewTutorDashboard',
      {
        tutorId: localStorage.getItem('tutorId'),
      },
    )

    if (res.ok && res.data) {
      const { data } = res.data
      setSingleTutor(data)
    }
    if (adminDashboardRes.ok && adminDashboardRes.data) {
      const { profileLink } = adminDashboardRes.data.data
      setProfileLink(profileLink)
    }
    updateAppState({ loading: false })
  }
  useEffect(() => {
    if (path !== 'pendingProfile') getSingleTutor()
  }, [])

  const handleSession = () => {
    if (singleTutor) {
      const name = `${singleTutor?.firstName} ${singleTutor?.lastName}`
      localStorage.setItem('tutorId', singleTutor?._id)
      localStorage.setItem('tutorName', name)
      window.open('/pages/tutors/All-tutor-bookings')
    }
  }

  const initializeDeleteTutor = async () => {
    updateAppState({ loading: true })
    const res = await api.get<CheckTutorDeleteInfoRes>(
      `/admin/checkTutorDeleteInfo/${singleTutor?._id}`,
    )
    updateAppState({ loading: false })
    if (!(res.ok && res.data)) return
    const {
      data: { upcomingSessions, isAvailableOnMySylvanMarketplace },
    } = res.data
    const errorMsg: DeleteErrorMsg = upcomingSessions
      ? 'HAS_UPCOMING'
      : isAvailableOnMySylvanMarketplace
      ? 'AVAILABLE_MARKETPLACE'
      : 'CONFIRMATION'
    setDeleteErrorMsg(errorMsg)
  }

  const deleteTutor = async () => {
    updateAppState({ loading: true })
    const res = await api.delete(
      `/admin/deleteTutorAccount/${singleTutor?._id}`,
    )
    if (res.ok) {
      toast.success('Tutor Deleted Successfully!', { id: 'global' })
      getSingleTutor()
    }
    updateAppState({ loading: false })
    setDeleteErrorMsg('')
  }

  const profilePic =
    pendingTutor && pendingTutor?.profilePicture
      ? pendingTutor?.profilePicture
      : pendingTutor?.userId?.profilePicture
  return (
    <>
      {!!singleTutor || !!pendingTutor ? (
        <ModalWrapper
          open={open && !deleteErrorMsg}
          handleClose={handleClose}
          title="Tutor Detail"
          wide
        >
          <div className="view-datail-main">
            {!showStatistics ? (
              <>
                <div className="w-100 d-flex justify-content-center ">
                  {path !== 'pendingProfile' ? (
                    <ImageComponent profilePic={singleTutor?.profilePicture} />
                  ) : (
                    <ImageComponent
                      profilePic={profilePic}
                      isChanged={
                        !!(pendingTutor && pendingTutor?.profilePicture)
                      }
                    />
                  )}
                </div>

                {singleTutor?.profileStatus === 'APPROVED' ? (
                  <>
                    <div className="col-12 text-center">
                      <a
                        href={profileLink + singleTutor.tutor.tutorId}
                        target="_blank"
                        className="tutor-link"
                      >
                        {profileLink + singleTutor.tutor.tutorId}
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <ContactInfo
                  singleTutor={singleTutor}
                  pendingTutor={pendingTutor}
                  path={path}
                />
                <Education
                  singleTutor={singleTutor}
                  pendingTutor={pendingTutor}
                  path={path}
                />
                <Policies
                  singleTutor={singleTutor}
                  pendingTutor={pendingTutor}
                  path={path}
                />
                <BuildProfile
                  singleTutor={singleTutor}
                  pendingTutor={pendingTutor}
                  path={path}
                />
                <BackgroundCheck
                  singleTutor={singleTutor}
                  pendingTutor={pendingTutor}
                  path={path}
                />
                {path !== 'pendingProfile' && (
                  <>
                    <AdditionalInfo singleTutor={singleTutor} />
                    <AlternatePricing singleTutor={singleTutor} />
                  </>
                )}
                <AdditionalNote
                  singleTutor={singleTutor}
                  page="tutor"
                  pendingTutor={pendingTutor}
                  path={path}
                />

                <div className="row mt-4">
                  <div className="col-md-12 col-sm-12 text-center">
                    {path !== 'pendingProfile' ? (
                      <>
                        {isMarketplaceManager && (
                          <Button
                            color="success"
                            onClick={() => initializeDeleteTutor()}
                            className="m-2"
                          >
                            Remove Account
                          </Button>
                        )}
                        <Button
                          color="success"
                          onClick={() => setShowStatistics(true)}
                          className="m-2"
                        >
                          View Statistics
                        </Button>
                        <Button
                          color="success"
                          className="m-2"
                          onClick={handleSession}
                        >
                          View Sessions
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    <Button
                      color="success"
                      className="m-2"
                      onClick={handleClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <TutorStatistics />
            )}
          </div>
        </ModalWrapper>
      ) : (
        <></>
      )}
      <UpcomingSessionModal
        open={!!deleteErrorMsg}
        onClose={() => setDeleteErrorMsg('')}
        deleteErrorMsg={deleteErrorMsg}
        onDelete={() => deleteTutor()}
      />
    </>
  )
}

export default TutorViewDetails
