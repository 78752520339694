import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import { useAppContext } from '../../../contexts/AppContext'
import { api } from '../../../utils'
import AddEditTutorResource from './components/add-edit-tutor-resources/AddEditResource'
import TutorResourceTable from './components/tutor-resources-table/ResourceTable'
import './resources.scss'
import { Resource, TutorResourceRes } from './tutorResources.type'

const Resources = () => {
  const { updateAppState } = useAppContext()
  const [allData, setAllData] = useState<Resource[]>([])
  const [_categories, setCategories] = useState<string[]>([])
  const [addTutorResource, setAddTutorResource] = useState(false)
  const [role, setRole] = useState<'PARENT' | 'TUTOR'>()

  const getResources = async () => {
    updateAppState({ loading: true })
    const res = await api.get<TutorResourceRes>('tutor/getTutorResources')
    if (res && res.data) {
      updateAppState({ loading: false })
      const { data } = res.data
      let arrayContent: Resource[] = []
      let categories: any = []

      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          categories.push(property)
          //@ts-ignore
          data[property].forEach((val) => {
            val.category = property
            arrayContent.push(val)
          })
        }
      }
      setAllData(arrayContent)
      setCategories(categories)
    }
  }
  const getParentResources = async () => {
    updateAppState({ loading: true })
    const res = await api.get<TutorResourceRes>('parent/getParentResources')
    if (res && res.data) {
      updateAppState({ loading: false })
      const { data } = res.data
      let arrayContent: Resource[] = []
      let categories: any = []

      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          categories.push(property)
          //@ts-ignore
          data[property].forEach((val) => {
            val.category = property
            arrayContent.push(val)
          })
        }
      }
      setAllData(arrayContent)
      setCategories(categories)
    }
  }

  useEffect(() => {
    if (window.location.pathname.split('/')[3] === 'parent-resources') {
      setRole('PARENT')
      getParentResources()
    }
    if (window.location.pathname.split('/')[3] === 'tutor-resources') {
      setRole('TUTOR')
      getResources()
    }
  }, [window.location.pathname.split('/')[3]])

  const isMarketPlaceManager =
    localStorage.getItem('isMarketplaceManager') === 'true' ? true : false

  return (
    <div className="mt-5">
      <div className="row">
        <div className="col-lg-3">
          <h3 className="page-heading">
            {role === 'PARENT' ? 'Parent Resources' : 'Tutor Resources'}
          </h3>
        </div>
        <div className="col-sm-9 col-md-9  text-end">
          {isMarketPlaceManager && (
            <Button
              onClick={() => {
                setAddTutorResource(true)
              }}
            >
              {role === 'PARENT' ? 'Add Parent Resource' : 'Add Tutor Resource'}
            </Button>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-12">
          <TutorResourceTable
            allResources={allData}
            getResources={role === 'TUTOR' ? getResources : getParentResources}
            role={role}
          />
        </div>
      </div>
      {addTutorResource && (
        <AddEditTutorResource
          mode="Add"
          role={role}
          open={addTutorResource}
          onClose={() => {
            setAddTutorResource(false)
          }}
          onSuccess={role === 'TUTOR' ? getResources : getParentResources}
        />
      )}
    </div>
  )
}

export default Resources
