const isMarketPlaceManager =
  localStorage.getItem('isMarketplaceManager') === 'true' ? true : false

export const tutorResourceHeading = [
  <>Title</>,
  <>Description</>,
  <>Resource Category</>,
  <>URL</>,
  <>
    Last <br />
    Updated
  </>,

  <>{isMarketPlaceManager ? 'Action' : ''}</>,
]
