import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { CancellationReason } from '../../../../../../types/constants.type'
import CancellationTableBody from './CancellationTableBody'

interface CancellationTableI {
  cancellationReasons: CancellationReason[]
  getConstants: () => Promise<void>
  reasonType: 'PARENT' | 'TUTOR'
}

const CancellationTable: FC<CancellationTableI> = ({
  cancellationReasons,
  getConstants,
  reasonType,
}) => {
  return (
    <div className="col-sm-12 col-md-10 offset-md-1">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              <TableCell>Reason</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cancellationReasons.map((reason) => {
              return (
                <CancellationTableBody
                  reason={reason}
                  getConstants={getConstants}
                  reasonType={reasonType}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CancellationTable
