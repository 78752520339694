import ModalWrapper from "../../modal-wrapper/ModalWrapper";
import { Button } from "@mui/material";
import { FC } from "react";
import "./reset-password-message.scss";

interface ResetPasswordMessageProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetPasswordMessage: FC<ResetPasswordMessageProps> = ({
  open,
  setOpen,
}) => {
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <div>
      <ModalWrapper
        open={open}
        handleClose={handleClose}
        title="Forgot Password?"
      >
        <div className="text-center dialog-text">
          Reset Password Link has been sent to your Email
        </div>
        <div className="text-center mt-4 ">
          <Button
            color="success"
            className="dialog-button"
            onClick={handleClose}
          >
            Ok
          </Button>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default ResetPasswordMessage;
