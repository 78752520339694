import { Button } from '@mui/material'
import { FC } from 'react'

import { TutorModalsProps } from '../../../pages/tutor/components/tutor-table/tutor-table.type'
import { api } from '../../../utils'
import ModalWrapper from '../../modal-wrapper/ModalWrapper'
import { ParamType } from './../../../pages/tutor/AllTutors'

interface StateCertifiedProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<TutorModalsProps>>
  modalName: string
  userId?: string
  role?: string
  name?: string
  checkStatus?: boolean
  getTutorDetails?: (data?: ParamType) => Promise<void>
}
const StateCertified: FC<StateCertifiedProps> = ({
  open,
  setOpen,
  modalName,
  userId,
  checkStatus,
  getTutorDetails,
}) => {
  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }
  const markCertified = async () => {
    const res = await api.put('admin/markTutorAsCertified/' + userId, {
      flag: !checkStatus,
    })
    if (res.ok) {
      handleClose()
      if (getTutorDetails) getTutorDetails()
    }
  }
  return (
    <ModalWrapper
      open={open.stateCertified}
      handleClose={handleClose}
      boldTitle
      title={`${
        !checkStatus ? 'Enable' : 'Disable'
      }   State Certification For Tutor?`}
      wide
    >
      <div className="d-flex justify-content-center align-items-center flex-column blueText">
        <p>
          {`Are you sure you want to ${
            !checkStatus ? 'enable' : 'disable'
          } state certification for this tutor?`}
        </p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={markCertified}
          >
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default StateCertified
