import { FC } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

interface BackArrowI {
  route: string
}

const BackArrow: FC<BackArrowI> = ({ route }) => {
  const navigate = useNavigate()
  return (
    <div className="col-lg-1">
      <FaArrowLeft
        size={25}
        className="back-icon"
        onClick={() => navigate('/pages/' + route)}
      />
    </div>
  )
}

export default BackArrow
