export const emailCheck = [
  'INCOMPLETE',
  'PENDING',
  'ALL',
  'DECLINED',
  'INACTIVE',
]

export const marketIdCheck = [
  'APPROVED',
  'BACKGROUND_CHECK',
  'UNBLOCKED',
  'BLOCKED',
  'DELETED',
]
export const hrStatusCheck = [
  'PENDING',
  'INCOMPLETE',
  'APPROVED',
  'BACKGROUND_CHECK',
  'INACTIVE',
  'DECLINED',
]
export const deleteIconCheck = ['APPROVED', 'PENDING', 'INCOMPLETE']
