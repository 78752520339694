import dayjs, { Dayjs } from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'

import { Notification } from '../../notifications.type'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

interface ManipulatedNotification extends Notification {
  booking: {
    rescheduleData: { startTime1?: Dayjs; endTime1?: Dayjs }
    startTime1?: Dayjs
    endTime1?: Dayjs
    cancelledAt1?: Dayjs
    sessionStartTime1?: Dayjs
    sessionEndTime1?: Dayjs
  } & Notification['booking']
}

export const useManipulatedNotification = (
  notification: ManipulatedNotification,
) => {
  const newNotification = _.cloneDeep(notification)
  if (newNotification.booking?.status) {
    newNotification.booking.status = newNotification.booking.status
      .replace(/[_-]/g, ' ')
      .toLowerCase()
  }
  if (newNotification.booking?.payments?.paymentStatus) {
    newNotification.booking.payments.paymentStatus =
      newNotification.booking.payments.paymentStatus
        .replace(/[_-]/g, ' ')
        .toLowerCase()
  }
  if (newNotification.type) {
    newNotification.type = newNotification.type
      .replace(/[_-]/g, ' ')
      .toLowerCase()
  }
  let duration = -1
  if (newNotification.booking?.slots) {
    duration = newNotification.booking.slots.length / 2
  }

  const { parentTimezoneOffsetZone } = newNotification.booking ?? {}

  if (newNotification.booking?.rescheduleData?.startTime) {
    const { startTime, endTime } = newNotification.booking.rescheduleData
    newNotification.booking.rescheduleData.startTime1 = dayjs(startTime).tz(
      parentTimezoneOffsetZone,
    )
    newNotification.booking.rescheduleData.endTime1 = dayjs(endTime).tz(
      parentTimezoneOffsetZone,
    )
  }
  if (newNotification.booking?.startTime) {
    const { startTime, endTime } = newNotification.booking
    newNotification.booking.startTime1 = dayjs(startTime).tz(
      parentTimezoneOffsetZone,
    )
    newNotification.booking.endTime1 = dayjs(endTime).tz(
      parentTimezoneOffsetZone,
    )
  }
  let cancelledAtAbb = ''
  if (newNotification.booking?.cancelledAt) {
    const { cancelledAt } = newNotification.booking
    newNotification.booking.cancelledAt1 = dayjs(cancelledAt).tz(
      parentTimezoneOffsetZone,
    )
    cancelledAtAbb = dayjs(cancelledAt).tz(parentTimezoneOffsetZone).format('z')
  }
  if (newNotification.booking?.sessionStartTime) {
    const { sessionStartTime, sessionEndTime } = newNotification.booking
    newNotification.booking.sessionStartTime1 = dayjs(sessionStartTime).tz(
      parentTimezoneOffsetZone,
    )
    if (sessionEndTime) {
      newNotification.booking.sessionEndTime1 = dayjs(sessionEndTime).tz(
        parentTimezoneOffsetZone,
      )
    }
  }
  return { ...newNotification, duration, cancelledAtAbb }
}
