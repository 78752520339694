export const grades = [
  {
    id: 0,
    value: 'K-2',
  },
  {
    id: 1,
    value: '3-5',
  },
  {
    id: 2,
    value: '6-8',
  },
  {
    id: 3,
    value: '9-12',
  },
]
