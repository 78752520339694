import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { api, currencyFormat } from '../../../../../utils'
import { Data, TutorDashboard } from '../tutor-dashboard.type'
import '../view-details.scss'

const TutorStatistics = ({}) => {
  const [dashboardData, setDashboardData] = useState<Data | null>(null)

  const getTutorDashboard = async () => {
    const res = await api.get<TutorDashboard>('/admin/viewTutorDashboard', {
      tutorId: localStorage.getItem('tutorId'),
    })
    if (res.ok && res.data) {
      setDashboardData(res.data.data)
    }
  }

  useEffect(() => {
    getTutorDashboard()
  }, [])

  const setDash = (data: any) => {
    if (data) {
      return data
    } else {
      return '-'
    }
  }

  return (
    <div className="statistics">
      <div className="heading-container">Things to know</div>
      <div className="row statistics__row view-rows">
        <div className="col-sm-6 col-lg-6 col-md-6 col-xs-12">
          <div className="row view-rows">
            <div className="col-sm-12 text-center text-bold mt-2">
              <strong className="statistics__label">
                Last day of availability
              </strong>
            </div>
            <div className="col-sm-12 text-center">
              {dashboardData?.lastDayAvailbility?.endTime
                ? dayjs(dashboardData.lastDayAvailbility.endTime).format(
                    'MMMM D, YYYY',
                  )
                : 'No upcoming availability'}
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-6 col-md-6 col-xs-12">
          <div className="row view-rows">
            <div className="col-sm-12 text-center mt-2">
              <strong className="statistics__label">
                Total hours of availability
              </strong>
            </div>
            <div className="col-sm-12 text-center">
              {dashboardData?.totalHoursOfAvailbility || '0'}
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="row statistics__row text-center">
        <div className="col-sm-7 col-lg-7 col-md-7 col-xs-12 mb-2">
          <div className="table-responsive">
            <table className="table statistics__table table-bordered set-td-height">
              <tr className="td-back-color">
                <td className="statistics__heading" colSpan={3}>
                  Session Count (Lifetime)
                </td>
              </tr>
              <tr className="table-border-width">
                <td>Status</td>
                <td>By Tutor</td>
                <td>Total</td>
              </tr>

              <tr className="table-border-width">
                <td>Not Responded Count</td>
                <td>{setDash(dashboardData?.sesssionsCount[1]?.count)}</td>
                <td>{setDash(dashboardData?.sesssionsCount[0]?.count)}</td>
              </tr>
              <tr className="table-border-width">
                <td>Declined</td>
                <td>{setDash(dashboardData?.sesssionsCount[3]?.count)}</td>
                <td>{setDash(dashboardData?.sesssionsCount[2]?.count)}</td>
              </tr>
              <tr className="table-border-width">
                <td>Canceled</td>
                <td>{setDash(dashboardData?.sesssionsCount[5]?.count)}</td>
                <td>{setDash(dashboardData?.sesssionsCount[4]?.count)}</td>
              </tr>
              <tr className="table-border-width">
                <td>Upcoming</td>
                <td>-</td>
                <td>{setDash(dashboardData?.sesssionsCount[6]?.count)}</td>
              </tr>
              <tr className="table-border-width">
                <td>Finished</td>
                <td>-</td>
                <td>{setDash(dashboardData?.sesssionsCount[7]?.count)}</td>
              </tr>
              <tr className="table-border-width">
                <td>Completed</td>
                <td>-</td>
                <td>{setDash(dashboardData?.sesssionsCount[8]?.count)}</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="col-sm-5 col-lg-5 col-md-5 col-xs-12">
          <div className="table-responsive">
            <table className="table statistics__table table-bordered set-td-height">
              <tr className="td-back-color">
                <td className="statistics__heading" colSpan={3}>
                  Earnings
                </td>
              </tr>
              <tr className="table-border-width">
                <td>Week-to-date</td>
                <td>
                  {dashboardData?.weekEarning[0]?.total
                    ? currencyFormat(dashboardData?.weekEarning[0]?.total)
                    : '-'}
                </td>
              </tr>
              <tr className="table-border-width">
                <td>Month-to-date</td>
                <td>
                  {dashboardData?.monthEarning[0]?.total
                    ? currencyFormat(dashboardData?.monthEarning[0]?.total || 0)
                    : '-'}
                </td>
              </tr>
              <tr className="table-border-width">
                <td>Year-to-date</td>
                <td>
                  {dashboardData?.yearEarning[0]?.total
                    ? currencyFormat(dashboardData?.yearEarning[0]?.total || 0)
                    : '-'}
                </td>
              </tr>
              <tr className="table-border-width">
                <td>All Time</td>
                <td>
                  {dashboardData?.allTimeEarning[0]?.total
                    ? currencyFormat(dashboardData?.allTimeEarning[0]?.total) ||
                      0
                    : '-'}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TutorStatistics
