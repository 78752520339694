import { Button, Pagination } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'

import CustomDatePicker from '../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../contexts/AppContext'
import { useDebounce } from '../../hooks'
import DisputeTable from './components/dispute-table/DisputeTable'
import useAllDisputes from './hooks/useAllDisputes'

export interface DisputeFilterI {
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  marketId?: string
  skip?: number | null
  status?: 'RESOLVED' | 'UNRESOLVED'
}
const defaultDisputeFilter: DisputeFilterI = {
  startDate: null,
  endDate: null,
  marketId: '',
  skip: null,
  status: 'UNRESOLVED',
}

const AllDisputes = () => {
  const { marketData } = useAppContext()

  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState('')
  const [disputeFilters, setDisputeFilters] =
    useState<DisputeFilterI>(defaultDisputeFilter)

  const onDateChange = (value: Dayjs | null | undefined, mode?: string) => {
    setPage(1)
    let temp: DisputeFilterI = {
      skip: 0,
    }
    if (mode === 'startDate') {
      temp.startDate = value
    } else {
      temp.endDate = value
    }

    setDisputeFilters({ ...disputeFilters, ...temp })
  }

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
    setDisputeFilters({ ...disputeFilters, skip: value })
  }

  const debouncedSearch = useDebounce({
    value: search,
    milliSeconds: 500,
  })

  const prevSearchRef = useRef('')

  if (prevSearchRef.current !== debouncedSearch) {
    setDisputeFilters((filters) => ({ ...filters, skip: 1 }))
  }
  prevSearchRef.current = debouncedSearch

  const { allDisputes, totalPages, getAllDisputes } = useAllDisputes({
    filterStates: disputeFilters,
    debouncedSearch,
  })

  return (
    <div className="container-fluid content">
      <div className="container-fluid">
        <div className="d-flex flex-wrap mt-4">
          <Button
            className="me-3 mt-3 blue-button"
            variant={
              disputeFilters.status === 'UNRESOLVED' ? 'contained' : 'outlined'
            }
            onClick={() => {
              setDisputeFilters({
                ...disputeFilters,
                status: 'UNRESOLVED',
              })
            }}
          >
            Unresolved
          </Button>
          <Button
            className="me-3 mt-3 blue-button"
            variant={
              disputeFilters.status === 'RESOLVED' ? 'contained' : 'outlined'
            }
            onClick={() => {
              setDisputeFilters({
                ...disputeFilters,
                status: 'RESOLVED',
              })
            }}
          >
            Resolved
          </Button>
        </div>
        <div className="row mt-2">
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={disputeFilters.startDate}
              maxDate={disputeFilters.endDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'startDate')
              }}
              placeholder="Select Start Date"
              mode="startDate"
            />
          </div>
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={disputeFilters.endDate}
              minDate={disputeFilters.startDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'endDate')
              }}
              placeholder="Select End Date"
              mode="endDate"
            />
          </div>

          <div className="col-md-3 mt-2 filters-width">
            <MarketDropdown
              placeholder="--Select Market Name--"
              option={marketData}
              value={disputeFilters.marketId}
              handleChange={(e) => {
                setPage(1)
                let temp: DisputeFilterI = {
                  skip: 0,
                  marketId: e.target.value,
                }
                setDisputeFilters({
                  ...disputeFilters,
                  ...temp,
                })
              }}
            />
          </div>

          <div className=" d-flex col-md-3 col-xl-2  mt-2">
            <SearchBar
              className="search-box"
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setPage(1)
              }}
            />
          </div>
        </div>
        <div className="mt-2">
          <DisputeTable
            allDisputes={allDisputes}
            status={disputeFilters.status}
            getAllDisputes={getAllDisputes}
          />
        </div>
        {allDisputes && allDisputes?.length > 0 ? (
          <>
            {totalPages > 1 && (
              <div className="mt-2 d-flex justify-content-end">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="mt-2 d-flex justify-content-start">
              <p>Record not found</p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AllDisputes
