export const childDetail = [
  { id: 0, heading: 'Name' },
  { id: 1, heading: 'Grade' },
  { id: 2, heading: 'DOB' },
  { id: 3, heading: 'Age' },
  { id: 4, heading: 'Gender' },
  { id: 5, heading: 'School' },
  { id: 6, heading: 'Documents' },
  { id: 7, heading: 'Actions' },
]
