import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../utils'
import { Disputes } from '../../hooks/disputes.types'

interface AddDisputeCommentI {
  open: boolean
  onClose: () => void
  editDispute?: Disputes
  onSuccess: () => void
}
const AddDisputeComment: FC<AddDisputeCommentI> = ({
  open,
  onClose,
  editDispute,
  onSuccess,
}) => {
  const [comment, setComment] = useState('')
  const [reasonError, setReasonError] = useState(false)
  const isValid = () => {
    if (!comment) {
      return false
    } else return true
  }
  const handleComment = async () => {
    const formData = new FormData()
    formData.append('comment', comment)
    if (editDispute) formData.append('disputeId', editDispute?._id)

    const res = await api.post(`admin/commentOnDispute`, formData)
    if (res.ok) {
      onClose()
      onSuccess()
    }
  }
  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="Comment on Dispute"
      wide
    >
      <div className="form-group row align-items-start">
        <div className="col-sm-3 text-left">Comment</div>

        <StyledTextfield
          multiline
          rows={4}
          placeholder="Enter comment"
          className="col-sm-9"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value)
          }}
          onKeyDown={() => {
            setReasonError(true)
          }}
          isError={reasonError && !comment}
          errorMessage={reasonError && !comment ? 'Comment is required.' : ''}
        />
      </div>
      <div className="form-group row">
        <div className="offset-sm-9 col-sm-3 mt-4 text-end me-1">
          <Button
            color={isValid() ? 'success' : 'info'}
            disabled={!isValid()}
            className="w-100"
            onClick={handleComment}
          >
            Add
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddDisputeComment
