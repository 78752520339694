import ModalWrapper from '@/components/modal-wrapper/ModalWrapper'
import { Button } from '@mui/material'
import { FC } from 'react'

interface AddressErrorModalProps {
  open: boolean
  handleClose: () => void
}
const TimeErrorModal: FC<AddressErrorModalProps> = ({ handleClose, open }) => {
  return (
    <ModalWrapper open={open} handleClose={handleClose} showCloseButton={false}>
      <div className="text-center">
        The provided end date may not be before the start date. Please update
        the dates entered.
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Button color="success" className="modal-button" onClick={handleClose}>
          Ok
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default TimeErrorModal
