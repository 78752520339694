import { TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'

import DeleteModal from '../../../../components/modals/delete-modal/DeleteModal'
import { Student } from '../../../../types/child.type'
import { api } from '../../../../utils'
import AddChild from '../../../parent/components/add-child/AddChild'

interface ChildTableBodyProps {
  student: Student
  getChild: () => Promise<void>
}

const ChildTableBody: FC<ChildTableBodyProps> = ({ student, getChild }) => {
  const [editChild, setEditChild] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const deleteChild = async () => {
    const res = await api.delete(`parent/student/${student._id}`)
    if (res.ok) {
      getChild()
      handleDeleteClose()
    }
  }

  const genderCapitalize = (gender: string) => {
    if (gender === 'FEMALE') return 'Female'
    if (gender === 'MALE') return 'Male'
    if (gender === 'OTHER') return 'Other/No Response'
  }

  const getAge = (dateOfBirth?: string | null) => {
    if (!dateOfBirth) {
      return '-'
    }
    const today = dayjs(new Date())
    const birthDate = dayjs(dateOfBirth.split('T')[0])
    let age =
      dayjs(today, 'DD/MM/YYYY').year() - dayjs(birthDate, 'DD/MM/YYYY').year()
    const month =
      dayjs(today, 'DD/MM/YYYY').month() -
      dayjs(birthDate, 'DD/MM/YYYY').month()
    if (
      month < 0 ||
      (month === 0 &&
        dayjs(today, 'DD/MM/YYYY').date() <
          dayjs(birthDate, 'DD/MM/YYYY').date())
    ) {
      age--
    }
    return age
  }

  const getDob = (dob: string) => {
    if (dayjs(dob).format('MMM').toLowerCase() == 'may') {
      return dayjs(student.dob).utc().format('MMM D, YYYY')
    } else {
      return dayjs(student.dob).utc().format('MMM. D, YYYY')
    }
  }

  const handleDeleteClose = () => {
    setDeleteModal(false)
  }

  return (
    <>
      <TableRow sx={{ verticalAlign: 'baseline' }}>
        <TableCell>
          {student.firstName ? student.firstName : ''}&nbsp;
          {student.lastName ? student.lastName : ''}
        </TableCell>
        <TableCell>{student.gradeLevel}</TableCell>
        <TableCell>{student.dob ? getDob(student.dob) : '-'}</TableCell>
        <TableCell>{getAge(student.dob)}</TableCell>
        <TableCell>{genderCapitalize(student.gender)}</TableCell>
        <TableCell>{student.school ? student.school : '-'}</TableCell>
        <TableCell>
          {student.academicReports.length > 0 ? (
            <>
              {student.academicReports.map((report, index) => {
                return (
                  <div
                    className="child-docs cursor-pointer"
                    key={index}
                    onClick={() => {
                      window.open(report.url, '_blank')
                    }}
                  >
                    {report.name}
                  </div>
                )
              })}
            </>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell>
          <span>
            <span
              className="m-3 cursor-pointer"
              onClick={() => {
                setEditChild(true)
              }}
            >
              <FaPencilAlt className="detail-icons" size={20} />
            </span>
            <span
              className="m-3 cursor-pointer"
              onClick={() => {
                setDeleteModal(true)
              }}
            >
              <FaTrashAlt className="detail-icons" size={20} />
            </span>
          </span>
        </TableCell>
      </TableRow>
      {editChild && (
        <AddChild
          student={student}
          modedModal={editChild}
          setModedModal={setEditChild}
          getChild={getChild}
          mode="edit"
          key={editChild + ''}
        />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={handleDeleteClose}
          role="Child"
          onDelete={deleteChild}
        />
      )}
    </>
  )
}

export default ChildTableBody
