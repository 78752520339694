import { Dayjs } from 'dayjs'
import * as Yup from 'yup'

import { BrowseTutorParams } from '../components/useBrowseTutor/browseTutor.type'

export interface SessionFormValues {
  onBehalfOf?: string
  parentName?: string
  tutorName?: string
  childName?: string
  sessionSubject?: SelectType[]
  sessionLocation?: string
  sessionDuration?: string
  sessionDate?: Dayjs | null
  promoCode?: string
  sessionReservation?: string
  autoAccept?: boolean
  flexibleLocation?: boolean | null
  additionalInfo?: string
  sessionPayment?: string
}

export const defaultValues: SessionFormValues = {
  onBehalfOf: '',
  parentName: '',
  tutorName: '',
  childName: '',
  sessionLocation: '',
  sessionDuration: '',
  sessionDate: null,
  promoCode: '',
  additionalInfo: '',
  autoAccept: false,
  sessionReservation: '',
  sessionPayment: '',
  sessionSubject: [],
  flexibleLocation: null,
}

export const sessionSchema = Yup.object({
  onBehalfOf: Yup.string().required(),
  parentName: Yup.string().required('Parent Name is required'),
  tutorName: Yup.string().required('Tutor Name is required'),
  childName: Yup.string().required(),
}).required()

export interface SelectType {
  id: string
  label: string
  value: string
}
export interface SessionParentParams {
  limit?: number
  skip?: number
  type?: string
}

export const paramsInit: SessionParentParams = {
  limit: 100,
  skip: 0,
  type: 'COMPLETE',
}

export const tutorParamsInit: BrowseTutorParams = {
  limit: 100,
  skip: 0,
  searchType: 'BROWSE_TUTORS_ADMIN',
  distance: 120,
}

export interface LocationZoneType {
  label: string
  zipCode: string
  country: string
  city: string
  state: string
  addressLine1: string
  addressLine2: string
  longitude: number
  latitude: number
  parentTimezoneOffsetZone: string
  onlineSession: boolean
}

export interface CreateSessionBooleans {
  isRateChargesSuppress: boolean
  isParenAddSession: boolean
  isAcceptModalShown: boolean
  isAutoAccept: boolean
  isZeroHours: boolean
  subjectError: boolean
  parentTouched: boolean
  tutorTouched: boolean
  onBehalfOfTouched: boolean
}

export const defaultBooleans: CreateSessionBooleans = {
  isAcceptModalShown: false,
  isAutoAccept: false,
  isParenAddSession: false,
  isRateChargesSuppress: false,
  isZeroHours: false,
  subjectError: false,
  parentTouched: false,
  tutorTouched: false,
  onBehalfOfTouched: false,
}
