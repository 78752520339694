import { FC, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const bodyElement = document.querySelector('body')

interface AppendToBodyIProps {
  children: React.ReactElement
}

const AppendToBody: FC<AppendToBodyIProps> = ({ children }) => {
  const elRef = useRef(document.createElement('div'))

  useEffect(() => {
    bodyElement!.appendChild(elRef.current)

    return () => {
      bodyElement!.removeChild(elRef.current)
    }
  }, [])

  return ReactDOM.createPortal(children, elRef.current)
}

export default AppendToBody
