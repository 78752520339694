import { useEffect, useState } from 'react'

import { api } from '../../../utils'
import {
  CurrentMatricesData,
  CurrentMatricesReq,
  HappeningTodayData,
  HappeningTodayReq,
  TutorFunnelData,
  TutorFunnelReq,
  YesterdayNumData,
  YesterdayNumReq,
} from '../dashboard.type'

export const useDashboardData = () => {
  const [happeningTodayData, setHappeningTodayData] =
    useState<HappeningTodayData>()
  const [yesterdayNumData, setYesterdayNumData] = useState<YesterdayNumData>()
  const [currentMatricesData, setCurrentMatricesData] =
    useState<CurrentMatricesData>()
  const [tutorFunnelData, setTutorFunnelData] = useState<TutorFunnelData>()

  useEffect(() => {
    const getData = async () => {
      let getHappeningToday = api.get<HappeningTodayReq>(
        '/admin/whatsHappeningToday',
      )
      let getYesterdayNum = api.get<YesterdayNumReq>('/admin/yesterdaysNumbers')
      let getCurrentMatrices = api.get<CurrentMatricesReq>(
        '/admin/currentMetrics',
      )
      let getTutorFunnel = api.get<TutorFunnelReq>('/admin/tutorFunnel')

      const [happeningToday, yesterdayNum, currentMatrices, tutorFunnel] =
        await Promise.all([
          getHappeningToday,
          getYesterdayNum,
          getCurrentMatrices,
          getTutorFunnel,
        ])

      if (happeningToday.data) {
        const { data } = happeningToday.data
        setHappeningTodayData(data)
      }

      if (yesterdayNum.data) {
        const { data } = yesterdayNum.data
        setYesterdayNumData(data)
      }
      if (currentMatrices.data) {
        const { data } = currentMatrices.data
        setCurrentMatricesData(data)
      }

      if (tutorFunnel.data) {
        const { data } = tutorFunnel.data
        setTutorFunnelData(data)
      }
    }

    getData()
  }, [])

  return {
    happeningTodayData,
    yesterdayNumData,
    currentMatricesData,
    tutorFunnelData,
  }
}
