import { CKEditor } from 'ckeditor4-react'
import { FC } from 'react'

interface CKEditorComponentI {
  onChange?: (data: string) => void
  value?: string
  onBlur?: () => void
  onFocus?: () => void
  noBgColor?: boolean
}

const CKEditorComponent: FC<CKEditorComponentI> = ({
  onChange,
  value,
  onBlur,
  onFocus,
  noBgColor,
}) => {
  return (
    <CKEditor
      config={
        !noBgColor
          ? {
              uiColor: '#008bb2',
              height: '200',
            }
          : {}
      }
      initData={value}
      onChange={(data) => {
        const newData = data.editor.getData()
        onChange?.(newData)
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

export default CKEditorComponent
