import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material'
import { FC } from 'react'

import { Resource } from '../../tutorResources.type'
import ResourceTableBody from './ResourceTableBody'
import { tutorResourceHeading } from './data'
import './resource.scss'

interface ResourceTableI {
  allResources: Resource[]
  getResources: () => Promise<void>
  role?: 'PARENT' | 'TUTOR'
}

const ResourceTable: FC<ResourceTableI> = ({
  allResources,
  getResources,
  role,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            className="table-align-end"
            sx={{ '&:hover': { background: 'none' } }}
          >
            {tutorResourceHeading.map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!allResources.length &&
            allResources.map((resource, index) => {
              return (
                <ResourceTableBody
                  resource={resource}
                  key={index}
                  getResources={getResources}
                  role={role}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ResourceTable
