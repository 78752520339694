import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { IMAGES } from '../../../../utils'
import './category-lister.scss'

interface CategoryListerProps {
  category: { name: string; route: string }
}

const CategoryLister: FC<CategoryListerProps> = ({ category }) => {
  const navigate = useNavigate()

  const routingHandler = () => {
    navigate('/pages/' + category.route)
  }
  return (
    <div className="row cursor-pointer">
      <div className="col-md-12 dash-pad" onClick={routingHandler}>
        <div className="dash-pad-inner">
          {category.name}
          <div className="d-flex justify-content-end">
            <img src={IMAGES.Arrow} className="arrowImg" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryLister
