import { Tooltip } from '@mui/material'
import { FC } from 'react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'

import { subject } from '../../../all-subjects.types'

const size = 15

interface SubCategoriesComponentProps {
  subCategories: subject
  onEdit: (name: subject) => void
  onDelete: (name: subject) => void
}
const SubCategoriesComponent: FC<SubCategoriesComponentProps> = ({
  subCategories,
  onEdit,
  onDelete,
}) => {
  return (
    <div className=" subSubject1 row">
      <div className="col-sm-8 text-start wordBreakAll">
        {subCategories.name}
      </div>
      <div className="col-sm-4 text-center">
        <span className="d-flex flex-wrap align-items-center justify-content-center">
          <Tooltip title="Delete">
            <span
              className="ms-3 me-3 menu-icon text-center"
              onClick={(e) => {
                onDelete(subCategories)
              }}
            >
              <FaTrashAlt size={size} />
            </span>
          </Tooltip>
          <Tooltip title="Edit">
            <span
              className="ms-3 me-3 menu-icon text-center"
              onClick={(e) => {
                onEdit(subCategories)
              }}
            >
              <FaPencilAlt size={size} />
            </span>
          </Tooltip>
        </span>
      </div>
    </div>
  )
}

export default SubCategoriesComponent
