import { Button } from '@mui/material'
import { FC } from 'react'

import { ModalProps } from '../../../pages/parent/components/parent-table/ParentTable'
import { api } from '../../../utils'
import ModalWrapper from '../../modal-wrapper/ModalWrapper'
import './modal-styling.scss'

interface UnblockModalProps {
  open: ModalProps
  setOpen: React.Dispatch<React.SetStateAction<ModalProps>>
  modalName: string
  userId?: string
  role?: string
  onUnblock?: () => void
  name?: string
}
const UnblockModal: FC<UnblockModalProps> = ({
  open,
  setOpen,
  modalName,
  userId,
  role,
  onUnblock,
  name,
}) => {
  const localName = localStorage.getItem(
    role === 'Parent' ? 'parentName' : 'tutorName',
  )

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }
  const unBlockUser = async () => {
    const res = await api.put('admin/blockUnblock/' + userId, {
      action: 'UNBLOCK',
    })
    if (res.ok) {
      handleClose()
      if (userId && onUnblock) {
        onUnblock?.()
      }
    }
  }
  return (
    <ModalWrapper
      open={open.unblock}
      handleClose={handleClose}
      title={`Unblock ${role}`}
    >
      <div className="d-flex justify-content-center align-items-center flex-column">
        <p>Are you sure you want to unblock {name || localName}?</p>
        <div>
          <Button
            color="success"
            className="modal-button"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={unBlockUser}
          >
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default UnblockModal
