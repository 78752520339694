import {
  Button,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
} from '@mui/material'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'

import CustomDatePicker from '../../../../../../components/date-picker/CustomDatePicker'
import { useAppContext } from '../../../../../../contexts/AppContext'
import {
  Booking,
  BookingsRes,
  TutorRelatedStudentsParents,
  TutorRelatedStudentsParentsRes,
} from '../../../../../../types/getBooking.type'
import { api } from '../../../../../../utils'
import { CONSTANT } from '../../../../../../utils/constants'
import ViewSessionsTable from './components/ViewSessionsTable'

const limit = 20
const ViewSession = () => {
  const { updateAppState } = useAppContext()
  const [tutorName, setTutorName] = useState('')
  const [bookings, setBookings] = useState<Booking[]>()
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [parent, setParent] = useState('')
  const [student, setStudent] = useState('')
  const [sessionStatus, setSessionStatus] = useState('')
  const [students, setStudents] = useState<TutorRelatedStudentsParents[]>([])
  const [parents, setParents] = useState<TutorRelatedStudentsParents[]>([])

  const tutorId = localStorage.getItem('tutorId')
  const totalNum = Math.ceil(totalPages / limit)
  const getTutorRelatedStudentsParents = async () => {
    const studentAndParentsRes = await api.get<TutorRelatedStudentsParentsRes>(
      '/admin/getTutorRelatedStudentsParents',
      { tutorId },
    )
    if (studentAndParentsRes.ok && studentAndParentsRes.data) {
      const { parents, students } = studentAndParentsRes.data.data
      setParents(parents)
      setStudents(students)
    }
  }

  const getTutorSessions = async () => {
    updateAppState({ loading: true })
    const queryParams: { [key: string]: any } = {
      tutorId: localStorage.getItem('tutorId'),
      type: 'ALL',
      withCount: 'true',
    }
    if (student) queryParams.studentId = student
    if (parent) queryParams.parentId = parent
    if (sessionStatus) queryParams.status = sessionStatus
    if (startDate) {
      const tempDate = startDate.format().split('T')
      queryParams.startDate = tempDate[0] + 'T00:00:00.000Z'
    }
    if (endDate) {
      const tempDate = endDate.format().split('T')
      queryParams.endDate = tempDate[0] + 'T23:59:59.999Z'
    }

    const res = await api.get<BookingsRes>('admin/getBookings', {
      ...queryParams,
      limit: limit,
      skip: (page - 1) * limit,
    })
    if (res.ok && res.data) {
      const { bookings, count } = res.data.data

      setBookings(bookings)
      setTotalPages(count)
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getTutorSessions()
    const name = localStorage.getItem('tutorName')
    setTutorName(name as string)
  }, [page, parent, student, startDate, endDate, sessionStatus])

  const handleChange = (__event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const clearFilters = () => {
    setParent('')
    setStudent('')
    setSessionStatus('')
    setStartDate(null)
    setEndDate(null)
  }
  const parentDropdown = (
    <Select
      size="small"
      className={`w-100`}
      value={parent}
      onChange={(event) => {
        setParent(event.target.value)
        setPage(1)
      }}
      input={<OutlinedInput />}
      displayEmpty
      MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
    >
      <MenuItem disabled value="">
        --Select Parent--
      </MenuItem>
      {parents.map(({ _id, firstName, lastName }) => {
        return (
          <MenuItem key={_id} value={_id}>
            {`${firstName} ${lastName}`}
          </MenuItem>
        )
      })}
    </Select>
  )
  const studentDropdown = (
    <Select
      size="small"
      className={`w-100`}
      value={student}
      onChange={(event) => {
        setStudent(event.target.value)
        setPage(1)
      }}
      input={<OutlinedInput />}
      displayEmpty
      MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
    >
      <MenuItem disabled value="">
        --Select Student--
      </MenuItem>
      {students.map(({ _id, firstName, lastName }) => {
        return (
          <MenuItem key={_id} value={_id}>
            {`${firstName} ${lastName}`}
          </MenuItem>
        )
      })}
    </Select>
  )

  const sessionStatusDropdown = (
    <Select
      size="small"
      className={`w-100`}
      value={sessionStatus}
      onChange={(event) => {
        setSessionStatus(event.target.value)
        setPage(1)
      }}
      input={<OutlinedInput />}
      displayEmpty
      MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
    >
      <MenuItem disabled value="">
        --Select Session Status--
      </MenuItem>
      {CONSTANT.sessionStatuses?.map((status) => {
        return (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        )
      })}
    </Select>
  )

  useEffect(() => {
    getTutorRelatedStudentsParents()
  }, [])

  return (
    <div className="">
      <div>
        <h1 className="text-center">Sessions for {tutorName}</h1>
        <Row className="mt-4 mb-2">
          <Col md={2}>
            <CustomDatePicker
              date={startDate}
              maxDate={endDate}
              onChange={(value) => {
                setStartDate(value as Dayjs)
                setPage(1)
              }}
              onClear={() => {
                setStartDate(null)
              }}
              placeholder="Select Start Date"
            />
          </Col>
          <Col md={2}>
            <CustomDatePicker
              date={endDate}
              minDate={startDate}
              placeholder="Select End Date"
              onChange={(value) => {
                setEndDate(value as Dayjs)
                setPage(1)
              }}
              onClear={() => {
                setEndDate(null)
              }}
            />
          </Col>
          <Col md={2}>{studentDropdown}</Col>
          <Col md={2}>{parentDropdown}</Col>
          <Col md={3}>{sessionStatusDropdown}</Col>
          <Col md={1}>
            <Button className="px-2" variant="text" onClick={clearFilters}>
              Clear
            </Button>
          </Col>
        </Row>
        <ViewSessionsTable
          bookings={bookings}
          getTutorSessions={getTutorSessions}
        />
      </div>
      {bookings && bookings?.length ? (
        <>
          {totalNum > 1 && (
            <div className="mt-2 d-flex justify-content-end">
              <Pagination
                count={totalNum}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="mt-2 d-flex justify-content-start">
            <p>Record not found</p>
          </div>
        </>
      )}
    </div>
  )
}

export default ViewSession
