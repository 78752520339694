import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import CustomCardWrapper from '../../../../components/custom-card-wrapper/CustomCardWrapper'
import { ConstantResponse } from '../../../../types/constants.type'
import { IMAGES, api } from '../../../../utils'
import './all-term-and-condition.scss'
import UpdateTermAndConditions from './components/update-term-and-conditions/UpdateTermAndConditions'

const AllTermAndCondition = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  const [termAndConditions, setTermAndConditions] = useState('')
  const [updateData, setUpdateData] = useState(false)
  const getConstants = async () => {
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      const { privacyPolicy, termsAndConditions } = constantsRes.data.data
      setPrivacyPolicy(privacyPolicy)
      setTermAndConditions(termsAndConditions)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setUpdateData(true)
            }}
          >
            Update Privacy Policy
          </Button>
        </div>
      </div>

      <CustomCardWrapper className="m-1">
        <a className="t-c-heading" href={privacyPolicy} target="_blank">
          Privacy Policy
          <div className="text-end">
            <img src={IMAGES.Arrow} className="arrowImg" />
          </div>
        </a>
      </CustomCardWrapper>
      <CustomCardWrapper className="m-1">
        <a className="t-c-heading" href={termAndConditions} target="_blank">
          Term and Condition
          <div className="text-end">
            <img src={IMAGES.Arrow} className="arrowImg" />
          </div>
        </a>
      </CustomCardWrapper>

      {updateData && (
        <UpdateTermAndConditions
          open={updateData}
          onClose={() => {
            setUpdateData(false)
          }}
          onSuccess={getConstants}
          privacyPolicy={privacyPolicy}
          termAndCondition={termAndConditions}
        />
      )}
    </div>
  )
}

export default AllTermAndCondition
