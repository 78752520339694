import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import { api } from '../../../utils'
import { Admin, AdminRes } from './admin.type'
import AddEditAdmin from './components/add-edit-admin/AddEditAdmin'
import AdminTable from './components/admin-table/AdminTable'

const AdminList = () => {
  const [allAdmins, setAllAdmins] = useState<Admin[]>([])
  const [addAdmin, setAddAdmin] = useState(false)

  const getAllAdmins = async () => {
    const res = await api.get<AdminRes>('admin/getAdminUsers')
    if (res && res.data) {
      const { data } = res.data
      setAllAdmins(data)
    }
  }
  useEffect(() => {
    getAllAdmins()
  }, [])

  return (
    <div className="container">
      {' '}
      <div className="row align-items-center">
        <div className="col-lg-3 mt-4">
          <h3 className="page-heading">Admin Settings</h3>
        </div>
        <div className="col-sm-9 col-md-9  text-end">
          <Button
            onClick={() => {
              setAddAdmin(true)
            }}
          >
            Add Admin Account
          </Button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-12 col-md-12">
          <AdminTable allAdmins={allAdmins} getAllAdmins={getAllAdmins} />
        </div>
      </div>
      {addAdmin && (
        <AddEditAdmin
          open={addAdmin}
          onClose={() => {
            setAddAdmin(false)
          }}
          mode="Add"
          onSuccess={getAllAdmins}
        />
      )}
    </div>
  )
}

export default AdminList
