import { FC, useState } from 'react'
import { FaSortDown, FaSortUp } from 'react-icons/fa'

import { StyledButton } from '../styled-button/StyledButtons'
import ModalWrapper from './../modal-wrapper/ModalWrapper'
import './custom-time-picker.scss'

const size = '25'
const color = '#014C8C'

interface CustomTimePickerModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  mode: 'start' | 'end' | undefined
  handleTime: (
    hour: number | null,
    minute: number | null,
    timeIndicator: string,
    mode: string,
  ) => void
}

const CustomTimePicker: FC<CustomTimePickerModalProps> = ({
  open,
  setOpen,
  mode,
  handleTime,
}) => {
  const [hour, setHour] = useState<number | null>(null)
  const [minute, setMinute] = useState<number | null>(null)
  const [timeIndicator, setTimeIndicator] = useState<'AM' | 'PM'>('AM')

  const handleClose = () => {
    setOpen(!open)
  }

  const handleSave = () => {
    if ((minute || minute === 0) && hour)
      handleTime(hour, minute, timeIndicator, mode as string)
  }

  const handleHour = (mode: string) => {
    if (mode === 'up') {
      if (hour === 12) setHour(1)
      else setHour((hour as number) + 1)
      if (hour === 11) setTimeIndicator(timeIndicator === 'AM' ? 'PM' : 'AM')
    }
    if (mode === 'down') {
      if (hour === null) {
        setHour(11)
        setTimeIndicator(timeIndicator === 'AM' ? 'PM' : 'AM')
      } else if (hour && hour < 0) {
        setHour(11)
      } else if (hour === 1) setHour(12)
      else setHour((hour as number) - 1)
      if (hour === 12) setTimeIndicator(timeIndicator === 'AM' ? 'PM' : 'AM')
    }
  }
  const handleMinute = (mode: string) => {
    if (mode === 'up') {
      if (minute === 30) {
        setMinute(0)
        if (hour === null) setHour(12)
        else handleHour(mode)
        if (hour === 11) setTimeIndicator(timeIndicator === 'AM' ? 'PM' : 'AM')
      } else {
        setMinute((minute as number) + 30)
        if (hour === null) setHour(12)
      }
    }
    if (mode === 'down') {
      if (minute === 0 || minute === null) {
        setMinute(30)
        if (hour === null) setHour(11)
        else handleHour(mode)
        if (hour === 12) setTimeIndicator(timeIndicator === 'AM' ? 'PM' : 'AM')
      } else {
        setMinute(minute - 30)
        if (hour === null) setHour(11)
      }
    }
  }

  return (
    <ModalWrapper
      open={open}
      sm
      handleClose={handleClose}
      title={mode === 'start' ? 'Start Time' : 'End Time'}
    >
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <div
            className="center-align user-select-none"
            onClick={() => {
              handleHour('up')
            }}
          >
            <FaSortUp size={size} color={color} />
          </div>
          <div className="time-picker-box center-align">
            {hour ? (hour < 10 ? '0' + hour : hour) : 'HH'}
          </div>
          <div
            className="center-align user-select-none"
            onClick={() => {
              handleHour('down')
            }}
          >
            <FaSortDown size={size} color={color} />
          </div>
        </div>
        &nbsp;:&nbsp;
        <div>
          <div
            className="center-align user-select-none"
            onClick={() => {
              handleMinute('up')
            }}
          >
            <FaSortUp size={size} color={color} />
          </div>
          <div className="time-picker-box center-align">
            {minute != null ? (minute < 30 ? '0' + minute : minute) : 'MM'}
          </div>
          <span
            className="center-align user-select-none"
            onClick={() => {
              handleMinute('down')
            }}
          >
            <FaSortDown size={size} color={color} />
          </span>
        </div>
        <div
          className="time-picker-box2 center-align"
          onClick={() => {
            setTimeIndicator(timeIndicator === 'AM' ? 'PM' : 'AM')
          }}
        >
          {timeIndicator}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="schedular-save m-0">
          <StyledButton color="success" onClick={handleSave}>
            Save
          </StyledButton>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default CustomTimePicker
