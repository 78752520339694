export const referralTerms = {
  case1: {
    policyName: 'Tutor A to Tutor B',
    triggerPoint:
      'Tutor B applies, is approved, and delivers a completed session',
  },
  case2: {
    policyName: 'Tutor A to Parent B - Acquantaince',
    triggerPoint: 'Parent B signs up and books/completes a session',
  },
  case3: {
    policyName: 'Tutor A to Parent B - Client',
    triggerPoint: 'Parent B signs up and books sessions',
  },
  case4: {
    policyName: 'Parent A to Tutor B',
    triggerPoint:
      'Tutor B applies, is approved, and delivers a completed session',
  },
  case5: {
    policyName: 'Parent A to Parent B',
    triggerPoint: 'Parent B signs up and books/completes a session',
  },
}
