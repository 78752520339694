import { MenuItem } from '@mui/material'
import Popover from '@mui/material/Popover'
import * as React from 'react'
import { useState } from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { FaPowerOff } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from '../../../../contexts/AppContext'
import { api, clearLocalStorage } from '../../../../utils'
import ChangePassword from '../../../change-password/ChangePassword'
import './user-popover.scss'

const size = 20
const menuItemBg = '#004f66'

const UserPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { updateAppState } = useAppContext()
  const [changePassword, setChangePassword] = useState(false)
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    updateAppState({ loading: true })
    await api.post('/admin/logout')
    clearLocalStorage()
    navigate('/login')
    updateAppState({ currentUser: null, isLoggedIn: false, loading: false })
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <span onClick={handleClick} className="me-3 ms-3">
        <span>
          <AiOutlineUser size={25} />
        </span>
      </span>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="user-profile-container">
          <MenuItem
            sx={{
              '&:hover': {
                background: `${menuItemBg} !important`,
              },
            }}
            onClick={() => {
              setChangePassword(true)
              handleClose()
            }}
            className="popover-menu-item"
          >
            <span className="rotate">
              <IoMdSettings size={size} />
            </span>
            <span className="ps-2">Change Password</span>
          </MenuItem>
          <MenuItem
            sx={{
              '&:hover': {
                background: `${menuItemBg} !important`,
              },
            }}
            onClick={handleLogout}
            className="popover-menu-item"
          >
            <span>
              <FaPowerOff size={size} />
            </span>
            <span className="ps-2">Sign Out</span>
          </MenuItem>
        </div>
      </Popover>
      <ChangePassword
        open={changePassword}
        setOpen={setChangePassword}
        key={changePassword + ''}
      />
    </>
  )
}

export default UserPopover
