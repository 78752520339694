import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { Student } from '../../../../types/child.type'
import { childDetail } from '../data'
import ChildTableBody from './ChildTableBody'
import './child-table.scss'

interface ChildTableProp {
  students: Student[] | null
  getChild: () => Promise<void>
}

const ChildTable: FC<ChildTableProp> = ({ students, getChild }) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              {childDetail.map((heading) => {
                return (
                  <TableCell sx={{ fontWeight: 'bold' }} key={heading.id}>
                    {heading.heading}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          {students && students?.length > 0 && (
            <TableBody>
              {students?.map((data) => (
                <ChildTableBody
                  student={data}
                  key={data._id}
                  getChild={getChild}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}

export default ChildTable
