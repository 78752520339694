import { Dayjs } from 'dayjs'

export interface NewPartnershipCodeI {
  code?: string
  description?: string
  hourlyRate?: string
  adminFee?: string
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  isSuppressRate?: boolean
  preventManual?: boolean
  marketByPass?: boolean
}

export const defaultPartnershipCode: NewPartnershipCodeI = {
  code: '',
  description: '',
  adminFee: '',
  hourlyRate: '',
  endDate: null,
  startDate: null,
  isSuppressRate: false,
  preventManual: false,
  marketByPass: true,
}
