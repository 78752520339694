import styled from '@emotion/styled'
import { FC } from 'react'

import './custom-borders.scss'

interface StyledRowProps {
  field?: string
  description?: any
  details?: boolean
  head?: boolean
  table?: boolean
}

const OuterDiv = styled('div')({
  width: '100%',
  display: 'flex',
  color: '#9d9d9d',
})
const InnerDiv = styled('div')({
  width: '100%',

  border: '1px solid #eceeef',
  wordBreak: 'break-word',
})

export const StyledRow: FC<StyledRowProps> = ({
  field,
  description,
  details,
  head,
  table,
}) => {
  return (
    <OuterDiv>
      <InnerDiv
        style={{
          width: details ? '40%' : '100%',
          padding: head ? '12px 8px' : '6px 8px',
        }}
      >
        {field}
      </InnerDiv>
      <InnerDiv
        style={{
          padding: head ? '12px 8px' : table ? '' : '6px 8px',
        }}
      >
        {description}
      </InnerDiv>
    </OuterDiv>
  )
}

export const StyledRow2: FC<StyledRowProps> = ({ field, description }) => {
  return (
    <div className="col-sm-6 col-lg-6 col-md-6 col-xs-12">
      <div className="row">
        <div className="col-sm-12">
          <b>{field}</b>
        </div>
        <div className="col-sm-12 column-margin">{description}</div>
      </div>
    </div>
  )
}
