import Arrow from '../assets/img/arrow2.png'
import DefaultProfile from '../assets/img/default-profile.png'
import CMSDefaultImage from '../assets/img/default.png'
import DownArrow from '../assets/img/downArrow.png'
import AmericanExpress from '../assets/img/icon_american.png'
import DinersClub from '../assets/img/icon_dinner_club.png'
import Discover from '../assets/img/icon_discover.png'
import Maestro from '../assets/img/icon_maestro.png'
import MasterCard from '../assets/img/icon_mastercard.png'
import Visa from '../assets/img/icon_visa.png'
import JCB from '../assets/img/jcb.jpg'
import AppLoader from '../assets/img/loader.gif'

export const IMAGES = {
  AppLoader,
  DefaultProfile,
  AmericanExpress,
  DinersClub,
  Discover,
  JCB,
  Maestro,
  MasterCard,
  Visa,
  Arrow,
  DownArrow,
  CMSDefaultImage,
}
