import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material'
import { FC } from 'react'

import { Contact } from '../../hooks/contacts.types'
import ContactsTableBody from './ContactsTableBody'
import { contactsHeading } from './data'

interface DisputeTableI {
  allContacts: Contact[]
  getAllContacts: () => Promise<void>
}

const ContactsTable: FC<DisputeTableI> = ({ allContacts, getAllContacts }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ '&:hover': { background: 'none' } }}>
            {contactsHeading.map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!allContacts.length &&
            allContacts.map((contact) => {
              return (
                <ContactsTableBody
                  contact={contact}
                  key={contact._id}
                  getAllContacts={getAllContacts}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ContactsTable
