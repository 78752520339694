import { TableRow, TableCell } from '@mui/material'
import { Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'

import ConfirmDelete from '../../../../../../components/confirm-delete/ConfirmDelete'
import { Reason } from '../../../../../../types/constants.type'
import { api } from '../../../../../../utils'
import AddEditRejectReason from '../add-edit-reject-reason/AddEditRejectReason'
import './reject-reason-table.scss'

interface RejectReasonTableBodyI {
  reason: Reason
  getConstants: () => Promise<void>
}

const size = 15
const RejectReasonTableBody: FC<RejectReasonTableBodyI> = ({
  reason,
  getConstants,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [editReason, setEditReason] = useState(false)

  const deleteReason = async () => {
    const res = await api.delete(`admin/deleteRejectReason/${reason._id}`)
    if (res.ok) {
      setConfirmDelete(false)
      getConstants()
    }
  }
  return (
    <>
      <TableRow className="row-bb cell-no-bb">
        <TableCell className="text-center">{reason.text}</TableCell>
        <TableCell className="d-flex justify-content-center">
          {' '}
          <span className="d-flex align-items-center">
            <Tooltip title="Delete">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={() => {
                  setConfirmDelete(true)
                }}
              >
                <FaTrashAlt size={size} />
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={() => {
                  setEditReason(true)
                }}
              >
                <FaPencilAlt size={size} />
              </span>
            </Tooltip>
          </span>
        </TableCell>
      </TableRow>
      {confirmDelete && (
        <ConfirmDelete
          open={confirmDelete}
          onClose={() => {
            setConfirmDelete(false)
          }}
          onYes={deleteReason}
          text="the reject reason"
          title="Reject Reason"
        />
      )}
      {editReason && (
        <AddEditRejectReason
          mode="Edit"
          open={editReason}
          onClose={() => {
            setEditReason(false)
          }}
          editReason={reason}
          onSuccess={getConstants}
        />
      )}
    </>
  )
}

export default RejectReasonTableBody
