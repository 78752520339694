import { Tutor } from '@/types/tutor.type'
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import { ChangeEvent, FC, useEffect, useRef } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'

import { TutorStates } from '../../pages/tutor/components/edit-tutor/EditTutor'

interface GooglePlacesAutoCompleteProps {
  onChange: (placeId: string) => void
  clearValue: boolean
  addressError?: boolean
  setClearValue: React.Dispatch<React.SetStateAction<boolean>>
  setAddressError?: React.Dispatch<React.SetStateAction<boolean>>
  setNewAddressSelected?: React.Dispatch<React.SetStateAction<boolean>>
  setTutorState?: React.Dispatch<React.SetStateAction<TutorStates>>
  defaultValue: string
  addressMode?: 'Add' | 'Edit'
  newAddressSelected?: boolean
  onClearVal?: () => void
  path?: 'addressForm' | 'editTutor'
  addressTouched?: () => void
  resetAddress?: boolean
  onKeyUp?: any
  tutorState?: TutorStates
  singleTutor?: Tutor
}

const GooglePlacesAutoComplete: FC<GooglePlacesAutoCompleteProps> = ({
  onChange,
  clearValue,
  setClearValue,
  setAddressError,
  addressError,
  defaultValue,
  onClearVal,
  addressTouched,
  addressMode,
  resetAddress,
  setNewAddressSelected,
  path,
  onKeyUp,
  tutorState,
  setTutorState,
  singleTutor,
}) => {
  const {
    ready,
    value,
    clearSuggestions,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete()
  const prevVal = useRef(value)
  const ref = useRef(null)

  useEffect(() => {
    if (value === '' && prevVal.current) {
      onClearVal?.()
    }
    prevVal.current = value
  }, [value])

  useEffect(() => {
    setValue(defaultValue, false)
    clearSuggestions()
  }, [defaultValue])

  useEffect(() => {
    if (resetAddress) {
      setValue(defaultValue, false)
      if (ref.current) {
        //@ts-ignore
        ref.current.blur()
      }
      clearSuggestions()
    }
  }, [resetAddress])

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setAddressError?.(false)
    let temp: TutorStates = {}
    if (e.target.value) temp.addressErrorFlag = false
    if (e.target.value === defaultValue) temp.newSelected = false
    else temp.newSelected = true
    setTutorState?.({ ...tutorState, ...temp })

    setValue(e.target.value)
  }

  const handleSelect = (val: string): void => {
    setValue(val, false)
  }
  useEffect(() => {
    if (clearValue && value !== '') {
      setValue('')
      setClearValue(!clearValue)
    }
  }, [clearValue])

  const renderSuggestions = (): JSX.Element => {
    const suggestions = data.map(({ place_id, description }: any) => (
      <ComboboxOption
        onClick={() => onChange(place_id as string)}
        key={place_id}
        value={description}
      />
    ))

    return <>{suggestions}</>
  }
  const inputClass =
    addressError && !value && addressMode === 'Edit'
      ? 'styled-textfield-error'
      : 'styled-textfield'

  return (
    <Combobox
      aria-label="Enter Public Address"
      onSelect={handleSelect}
      aria-labelledby="demo"
    >
      <ComboboxInput
        ref={ref}
        style={{ width: '100%', height: '36px' }}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Enter Public Address"
        {...(path === 'addressForm'
          ? {
              onFocus: () => {
                addressTouched?.()
              },
            }
          : {
              onKeyUp: () => {
                if (!value) {
                  setAddressError?.(true)
                  setTutorState?.({ ...tutorState, addressErrorFlag: true })
                  setNewAddressSelected?.(false)
                } else
                  setTutorState?.({ ...tutorState, addressErrorFlag: false })
                onKeyUp?.()
              },
            })}
        className={inputClass}
      />

      {path === 'editTutor' && !value && addressError && (
        <span className="d-flex justify-content-end helper-text">
          Address is Required
        </span>
      )}
      {path === 'editTutor' &&
        tutorState?.locationFlag &&
        singleTutor?.tutor.profileStep === 7 && (
          <span className="d-flex justify-content-end helper-text">
            Invalid home address
          </span>
        )}
      {addressError && addressMode === 'Edit' && path !== 'editTutor' && (
        <span className="d-flex justify-content-end helper-text">
          Invalid Address
        </span>
      )}
      <ComboboxPopover>
        <ComboboxList>{status === 'OK' && renderSuggestions()}</ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

export default GooglePlacesAutoComplete
