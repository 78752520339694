import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Checkbox } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import CustomDatePicker from '../../../../../../components/date-picker/CustomDatePicker'
import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../../../utils'
import { PartnerCode } from '../../AllPartnershipCode.type'
import './add-edit-partnership-code.scss'
import {
  NewPartnershipCodeI,
  defaultPartnershipCode,
} from './addEditPartnership.type'

interface AddEditPartnershipCodeI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  mode: 'Add' | 'Edit'
  editCode?: PartnerCode
  isSuppressChargesAlready?: number
}

const schema = Yup.object({
  code: Yup.string()
    .required('Code name is required')
    .matches(
      /^(.*[A-Za-z]+.*)$/,
      'The Partnership Code must contain at least one letter.',
    ),
  description: Yup.string().required('Description is required.'),
  hourlyRate: Yup.string()
    .required('Alternate Hourly Rate is required.')
    .matches(
      /^(?!0$)(?:0*[1-9]\d{0,6})(?:\.\d{1,4})?$/,
      'Alternate Hourly Rate is invalid.',
    ),
  adminFee: Yup.string()
    .required('Alternate Admin Fee is required.')
    .matches(
      /^(?!0$)(?:0*[1-9]\d{0,6})(?:\.\d{1,4})?$/,
      'Alternate Admin Fee is invalid.',
    ),
  isSuppressRate: Yup.boolean(),
  preventManual: Yup.boolean(),
  marketByPass: Yup.boolean(),
}).required()

const AddEditPartnershipCode: FC<AddEditPartnershipCodeI> = ({
  open,
  onClose,
  onSuccess,
  isSuppressChargesAlready,
  mode,
  editCode,
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<NewPartnershipCodeI>({
    resolver: yupResolver(schema),
    defaultValues: defaultPartnershipCode,
    mode: 'all',
  })

  const [showRateError, setShowRateError] = useState(false)
  const [showDateError, setShowDateError] = useState(false)
  const [showTimeError, setShowTimeError] = useState(false)
  const [showEditFields, setShowEditFields] = useState(false)
  const [dateError, setDateError] = useState(false)

  useEffect(() => {
    if (editCode) {
      if (new Date() < new Date(dayjs(editCode.startTime).format())) {
        setShowEditFields(true)
        if (editCode?.startTime)
          setValue('startDate', dayjs(editCode.startTime).utc())
      } else {
        setShowEditFields(false)
      }

      if (editCode?.endTime) setValue('endDate', dayjs(editCode.endTime).utc())
      setValue('description', editCode.partnerCodeDescription)
      setValue('adminFee', editCode.case1.discount.toString())
      setValue('hourlyRate', editCode.hourlyRate.toString())
      setValue('code', editCode.partnercode)
      setValue('isSuppressRate', editCode.isRateChargeSuppress)
      setValue('preventManual', editCode.isPreventManualUsage)
      setValue('marketByPass', editCode.isBypassMarket)
    }
  }, [])

  const makeIsSuppressDisable = () => {
    let isDisable = false
    if (isSuppressChargesAlready == 0) {
      if (editCode && editCode.usedByParent.length > 0) {
        isDisable = true
      } else {
        isDisable = false
      }
    } else {
      if (
        editCode &&
        editCode.usedByParent.length == 0 &&
        editCode.isRateChargeSuppress
      ) {
        isDisable = false
      } else {
        isDisable = true
      }
    }
    return isDisable
  }

  const values = watch()
  const updatePartnerShipCode = async () => {
    const {
      code,
      adminFee,
      description,
      isSuppressRate,
      preventManual,
      marketByPass,
      startDate,
      endDate,
      hourlyRate,
    } = values
    const formData = new FormData()
    let now = dayjs().utc().startOf('day').format()
    let selectedDate = dayjs(startDate)
      .add(1, 'days')
      .utc()
      .endOf('day')
      .format()
    const isBefore = dayjs(selectedDate).isBefore(dayjs(now))

    if (!showEditFields && editCode) {
      if (endDate) {
        let tempEndDate = endDate.format().split('T')[0] + 'T23:59:59.999Z'

        formData.append('endTime', tempEndDate)
      } else {
        if (editCode?.endTime) formData.append('unsetEndTime', 'true')
      }
      if (
        isSuppressRate !== editCode.isRateChargeSuppress &&
        isSuppressRate !== undefined
      ) {
        formData.append('isRateChargeSuppress', isSuppressRate.toString())
      }
      if (
        preventManual != undefined &&
        preventManual !== editCode.isPreventManualUsage
      )
        formData.append('isPreventManualUsage', preventManual.toString())

      if (!!Array.from(formData.entries()).length) {
        const result = await api.put(
          `admin/partnercode/${editCode?._id}`,
          formData,
        )
        if (result.ok) {
          onClose()
          onSuccess()
        }
      } else {
        onClose()
      }
    }

    if (isBefore) {
      setShowTimeError(true)
      return
    } else {
      setShowTimeError(false)
    }

    if (endDate && startDate) {
      if (
        new Date(dayjs(endDate).format()) < new Date(dayjs(startDate).format())
      ) {
        setShowDateError(true)

        return
      } else {
        setShowDateError(false)
      }
    } else if (endDate && editCode?.startTime) {
      if (dayjs(endDate).isBefore(dayjs(editCode?.startTime))) {
        setShowDateError(true)
        return
      } else {
        setShowDateError(false)
      }
    }

    if (values.hourlyRate && values.adminFee) {
      if (parseInt(values.hourlyRate, 10) < parseInt(values.adminFee, 10)) {
        setShowRateError(true)
        return
      } else {
        setShowRateError(false)
      }
    }
    if (mode === 'Add' || showEditFields) {
      let tempDate = startDate?.format().split('T')[0] + 'T00:00:00.000Z'

      if (startDate) formData.append('startTime', tempDate)
      let tempEndDate
      if (endDate) {
        tempEndDate = endDate.format().split('T')[0] + 'T23:59:59.999Z'
      }

      if (mode === 'Add') {
        if (endDate) formData.append('endTime', tempEndDate as string)
      } else {
        if (endDate) formData.append('endTime', tempEndDate as string)
        else formData.append('unsetEndTime', 'true')
      }

      formData.append('partnercode', code as string)
      formData.append('partnerCodeDescription', description as string)
      formData.append('hourlyRate', parseInt(hourlyRate as string).toString())

      if (marketByPass != undefined)
        formData.append('isBypassMarket', marketByPass.toString())
      if (isSuppressRate) {
        formData.append('isRateChargeSuppress', isSuppressRate.toString())
      }
      if (preventManual != undefined)
        formData.append('isPreventManualUsage', preventManual.toString())
      formData.append(
        'case1',
        JSON.stringify({
          discount: parseInt(adminFee as string).toString(),
          type: 'FLAT',
        }),
      )

      let res
      if (mode === 'Add') {
        res = await api.post('admin/addPartnershipCode', formData)
      } else {
        res = await api.put(`admin/partnercode/${editCode?._id}`, formData)
      }
      if (res && res.ok) {
        onClose()
        onSuccess()
      }
    }
  }

  const isButtonValid = () => {
    if (mode === 'Add') {
      return isValid && values.startDate
    } else {
      if (showEditFields) {
        return isValid && values.startDate
      } else return true
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="Partnership Code"
      wide
    >
      <div className="row">
        <form>
          <div className="col-sm-12 col-lg-12 col-md-12">
            {(showEditFields || !editCode) && (
              <>
                <div className={`form-group row align-items-center`}>
                  <div className="col-sm-5 text-left">
                    <label className="add-child__label ">Code</label>
                  </div>
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                      <StyledTextfield
                        {...field}
                        placeholder="Code"
                        isError={!!errors.code}
                        className="col-sm-7"
                        max={25}
                      />
                    )}
                  />
                  {errors.code?.message && (
                    <div className="helper-text">
                      {errors.code?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className={`form-group row align-items-center`}>
                  <div className="col-sm-5 text-left">
                    <label className="add-child__label">Description</label>
                  </div>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <StyledTextfield
                        {...field}
                        placeholder="Code Description"
                        isError={!!errors.description}
                        className="col-sm-7"
                        max={100}
                      />
                    )}
                  />
                  {errors.description?.message && (
                    <div className="helper-text">
                      {errors.description?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className={`form-group row align-items-center`}>
                  <div className="col-sm-5 text-left">
                    <label className="add-child__label">
                      Alternate Hourly Rate
                    </label>
                  </div>
                  <Controller
                    name="hourlyRate"
                    control={control}
                    render={({ field }) => (
                      <StyledTextfield
                        {...field}
                        placeholder="Alternate Hourly Rate"
                        type="number"
                        isError={!!errors.hourlyRate}
                        className="col-sm-7"
                      />
                    )}
                  />
                  {errors.hourlyRate?.message && (
                    <div className="helper-text">
                      {errors.hourlyRate?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className={`form-group row align-items-center`}>
                  <div className="col-sm-5 text-left">
                    <label className="add-child__label">
                      Alternate Admin Fee
                    </label>
                  </div>
                  <Controller
                    name="adminFee"
                    control={control}
                    render={({ field }) => (
                      <StyledTextfield
                        {...field}
                        placeholder="Admin Fee"
                        isError={!!errors.adminFee}
                        type="number"
                        className="col-sm-7"
                      />
                    )}
                  />
                  {errors.adminFee?.message && (
                    <div className="helper-text">
                      {errors.adminFee?.message?.toString()}
                    </div>
                  )}
                </div>
                <div className={`form-group row align-items-center`}>
                  <div className="col-sm-5 text-left">
                    <label className="add-child__label">Start Date</label>
                  </div>
                  <div className="col-sm-7">
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <CustomDatePicker
                          placeholder="mm-dd-yyyy"
                          date={field.value}
                          maxDate={values.endDate}
                          onChange={(value) => {
                            setValue('startDate', value)
                          }}
                          onClear={() => {
                            setValue('startDate', null)
                            setDateError(true)
                          }}
                        />
                      )}
                    />
                    {!values.startDate && dateError && (
                      <div className="helper-text">Start Date is required</div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className={`form-group row align-items-center`}>
              <div className="col-sm-5 text-left">
                <label className="add-child__label">End Date</label>
              </div>
              <div className="col-sm-7">
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <CustomDatePicker
                      placeholder="mm-dd-yyyy"
                      date={field.value}
                      minDate={values.startDate ? values.startDate : dayjs()}
                      onChange={(value) => {
                        setValue('endDate', value)
                      }}
                      onClear={() => {
                        setValue('endDate', null)
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {!showEditFields && editCode && (
              <div className="m-2 mb-3 partner-modal-content text-center">
                Only the End Date of this Partnership Code can be edited because
                the code has already started.
              </div>
            )}
            <div className={`form-group row align-items-center`}>
              <div className="col-sm-5 text-left">
                <label className="add-child__label">
                  Suppress Rates and Charges
                </label>
              </div>
              <Controller
                name="isSuppressRate"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    className="col-sm-7 justify-content-start"
                    checked={field.value}
                    disabled={makeIsSuppressDisable()}
                  />
                )}
              />
            </div>
            <div className="m-2 mb-3 partner-modal-content">
              Checking this flag means parents will not see tutor hourly rates,
              and sessions will be paid for using available tutoring credit
              hours.
            </div>
            <div className={`form-group row align-items-center`}>
              <div className="col-sm-5 text-left">
                <label className="add-child__label">Prevent manual usage</label>
              </div>
              <Controller
                name="preventManual"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    className="col-sm-7 justify-content-start"
                    checked={field.value}
                    disabled={editCode?.isRateChargeSuppress}
                  />
                )}
              />
            </div>
            <div className={`form-group row align-items-center`}>
              <div className="col-sm-5 text-left">
                <label className="add-child__label">Market Bypass Flag</label>
              </div>
              <Controller
                name="marketByPass"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    className="col-sm-7 justify-content-start"
                    checked={field.value}
                    disabled={true}
                  />
                )}
              />
            </div>
            {showRateError && (
              <p className="error-message text-center">
                Alternate Admin Fee may not be higher than the Alternate Hourly
                Rate.
              </p>
            )}
            {showDateError && (
              <p className="error-message text-center">
                End date may not be before the start date.
              </p>
            )}
            {showTimeError && (
              <p className="error-message text-center">
                Start date may not be earlier than today.
              </p>
            )}

            <div className="form-group row pe-0">
              <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-0">
                <Button
                  color={isButtonValid() ? 'success' : 'info'}
                  disabled={!isButtonValid()}
                  className="w-100"
                  onClick={updatePartnerShipCode}
                >
                  Submit{' '}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ModalWrapper>
  )
}

export default AddEditPartnershipCode
