import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BsArrowRightShort } from 'react-icons/bs'
import * as Yup from 'yup'

import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../../../contexts/AppContext'
import { Card } from '../../../../../../types/card.type'
import { Address, Parent } from '../../../../../../types/parent.type'
import { api } from '../../../../../../utils'
import StyledDropdown, {
  Options,
} from './../../../../../../components/styled-dropdown/StyledDropdown'
import { monthsArray } from './../../../data'
import './card-form.scss'

interface cardFormProps {
  mode: 'add' | 'edit'
  parentId: string
  setMode: React.Dispatch<React.SetStateAction<string>>
  cards?: Card[]
  selectedCard?: string
  singleParent?: Parent | null
  onClose?: () => void
}

interface FormValues {
  cardholderName?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zipCode?: string
  expMonth?: string
  expYear?: string
}

const schema = Yup.object({
  cardholderName: Yup.string().required('Cardholder name is required.'),
  addressLine1: Yup.string().required('Address is required.'),
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  zipCode: Yup.string()
    .required('Zip code is required.')
    .min(5, 'Zip code must be at least 5 number.')
    .max(5),
}).required()

const CardForm: FC<cardFormProps> = ({
  mode,
  parentId,
  setMode,
  cards,
  selectedCard,
  singleParent,
  onClose,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [singleCard, setSingleCard] = useState<Card>()
  const [isDefault, setIsDefault] = useState(true)
  const [isDefaultAddress, setIsDefaultAddress] = useState(false)
  const [address, setAddress] = useState<Address>()
  const [yearArray, setYearArray] = useState<Options[]>([])
  const { updateAppState } = useAppContext()
  const [cardNumberState, setCardNumberState] = useState<any>()
  const [cardCVCState, setCardCVCState] = useState<any>()
  const [cardExpState, setCardExpState] = useState<any>()

  useEffect(() => {
    const card = cards?.filter((card) => card._id === selectedCard)
    setSingleCard(card?.[0])
    const address = singleParent?.parent.addresses.filter(
      (address) => address.label === 'Home',
    )

    setAddress(address?.[0])
    const year = dayjs(new Date()).year()

    let yearLength = parseInt(card?.[0]?.expYear as string) - year

    if (yearLength <= 10) {
      yearLength = 10
    }

    let tempArray: Options[] = []
    for (let i = 0; i <= yearLength; i++) {
      tempArray.push({
        id: year + i,
        option: (year + i).toString(),
        value: (year + i).toString(),
      })
    }

    setYearArray([...tempArray])
  }, [singleCard])

  const defaultValues: FormValues = {
    addressLine1: '',
    addressLine2: '',
    cardholderName: '',
    city: '',
    state: '',
    zipCode: '',
    expMonth: '',
    expYear: '',
  }
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setValue('cardholderName', mode === 'edit' ? singleCard?.name : '')
    setValue('addressLine1', mode === 'edit' ? singleCard?.addressLine1 : '')
    setValue('city', mode === 'edit' ? singleCard?.city : '')
    setValue(
      'addressLine2',
      mode === 'edit'
        ? singleCard?.addressLine2
          ? singleCard.addressLine2.trim()
          : ''
        : '',
    )
    setValue('state', mode === 'edit' ? singleCard?.state : '')
    setValue('zipCode', mode === 'edit' ? singleCard?.zipCode : '')
    setValue('expMonth', mode === 'edit' ? singleCard?.expMonth : '')
    setValue('expYear', mode === 'edit' ? singleCard?.expYear : '')
    setIsDefault(
      mode === 'edit' ? (singleCard ? singleCard.isDefault : true) : true,
    )
    const address = singleParent?.parent.addresses.filter(
      (address) => address.label === 'Home',
    )[0]

    if (
      mode === 'edit' &&
      address?.addressLine1 === singleCard?.addressLine1 &&
      address?.city === singleCard?.city &&
      address?.state === singleCard?.state &&
      address?.zipCode === singleCard?.zipCode
    ) {
      setIsDefaultAddress(true)
    }
  }, [singleCard])

  const values = watch()
  console.log({ values })

  const defaultAddressSetter = (isDefaultAddress: boolean) => {
    if (isDefaultAddress) {
      setValue('addressLine1', address?.addressLine1)
      setValue(
        'addressLine2',
        address?.addressLine2 ? address?.addressLine2 : '',
      )
      setValue('city', address?.city)
      setValue('state', address?.state)
      setValue('zipCode', address?.zipCode)
    } else {
      setValue('addressLine1', '')
      setValue('addressLine2', '')
      setValue('city', '')
      setValue('state', '')
      setValue('zipCode', '')
    }
  }

  const errorCheck = () => {
    if (!cardNumberState) {
      setCardNumberState({ empty: true })
    }
    if (!cardCVCState) {
      setCardCVCState({ empty: true })
    }
    if (!cardExpState) {
      setCardExpState({ empty: true })
    }
  }

  const onSubmit = async (values: FormValues) => {
    updateAppState({ loading: true })
    const {
      addressLine1,
      addressLine2,
      cardholderName,
      city,
      state,
      zipCode,
      expMonth,
      expYear,
    } = values
    if (!elements || !stripe) {
      return
    }
    const formData = new FormData()

    if (mode === 'add') {
      const cardNumberElement = elements.getElement(CardNumberElement)

      if (cardNumberElement) {
        const result = await stripe.createToken(cardNumberElement, {
          name: cardholderName,
          address_line1: addressLine1,
          address_line2: addressLine2,
          address_city: city,
          address_state: state,
          address_zip: zipCode?.toString(),
        })
        formData.append('stripeToken', result.token?.id as string)
        formData.append('parentId', parentId)
        formData.append('isDefault', isDefault.toString())
      }
      const res = await api.post('parent/addCard', formData)
      if (res.ok) {
        if (res.ok) {
          onClose?.()
        }
      }
    } else {
      formData.append('name', cardholderName as string)
      formData.append('addressLine1', addressLine1 as string)
      if (addressLine2)
        formData.append(
          'addressLine2',
          (addressLine2 as string) ? (addressLine2 as string) : '',
        )
      formData.append('city', city as string)
      formData.append('state', state as string)
      formData.append('zipCode', zipCode ? zipCode.toString() : '')
      formData.append('expMonth', expMonth ? expMonth.toString() : '')
      formData.append('expYear', expYear ? expYear.toString() : '')
      formData.append('parentId', parentId)
      const updateRes = await api.put(
        `parent/updateCard/${singleCard?._id}`,
        formData,
      )
      if (isDefault) {
        const setDefaultRes = await api.put(
          `admin/parent/${parentId}/setDefaultCard/${singleCard?._id}`,
        )
        if (setDefaultRes.ok && updateRes.ok) {
          onClose?.()
        }
      }
      if (updateRes.ok) {
        onClose?.()
      }
    }
    updateAppState({ loading: false })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="without-arrows">
      <div className="offset-md-1 col-md-10">
        <div className="payment">
          {mode === 'add' ? 'Add New Card' : 'Edit Card'}
        </div>
        <div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label className="card-label">Cardholder name</label>
              <Controller
                name="cardholderName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    whiteBg
                    placeholder="Card Holder Name"
                    className="name-field"
                  />
                )}
              />
              {errors.cardholderName && (
                <span className="helper-text">
                  {errors.cardholderName.message}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label className="card-label">Card Number</label>
              {mode === 'add' ? (
                <>
                  <CardNumberElement
                    className="form-control"
                    onChange={(e) => {
                      setCardNumberState(e)
                    }}
                  />
                  <span className="helper-text mt-1 d-flex justify-content-start w-100">
                    {cardNumberState && cardNumberState.empty
                      ? 'Card number is required.'
                      : ''}
                    {cardNumberState &&
                    !cardNumberState.complete &&
                    !cardNumberState.empty &&
                    !cardNumberState.error
                      ? 'Card Number entered must be minimum 14 digits.'
                      : ''}
                  </span>
                </>
              ) : (
                <StyledTextfield
                  disabled
                  value={'XXXX XXXX XXXX ' + singleCard?.last4Digits}
                />
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className={mode === 'edit' ? 'col-md-4' : 'col-md-6'}>
              {mode === 'add' ? (
                <>
                  <label className="card-label">Expiration Date</label>
                  <CardExpiryElement
                    className="form-control"
                    onChange={(e) => {
                      setCardExpState(e)
                    }}
                  />
                  <span className="helper-text mt-1 d-flex justify-content-start w-100">
                    {cardExpState &&
                    (cardExpState.empty || !cardExpState.complete) &&
                    !cardExpState.error
                      ? 'Expiration month and year is required.'
                      : ''}
                  </span>
                </>
              ) : (
                <>
                  <label className="card-label">Expiration Month</label>
                  <Controller
                    name="expMonth"
                    control={control}
                    render={({ field }) => (
                      <StyledDropdown
                        {...field}
                        option={monthsArray}
                        placeholder="Expiration Month"
                        errorMessage={errors.expMonth?.message?.toString()}
                      />
                    )}
                  />
                </>
              )}
            </div>
            {mode === 'add' ? (
              <></>
            ) : (
              <>
                <div className={mode === 'edit' ? 'col-md-4' : 'col-md-6'}>
                  <label className="card-label">Expiration Year</label>
                  <Controller
                    name="expYear"
                    control={control}
                    render={({ field }) => (
                      <StyledDropdown
                        {...field}
                        option={yearArray}
                        placeholder="Expiration Year"
                      />
                    )}
                  />
                </div>
              </>
            )}
            <div className={mode === 'edit' ? 'col-md-4' : 'col-md-6'}>
              <label className="card-label">CVV</label>
              {mode === 'add' ? (
                <>
                  <CardCvcElement
                    className="form-control"
                    onChange={(e) => {
                      setCardCVCState(e)
                    }}
                  />
                  <span className="helper-text mt-1 d-flex justify-content-start w-100">
                    {cardCVCState && cardCVCState.empty
                      ? 'Please enter the 3-4 digit security code.'
                      : ''}
                    {cardCVCState &&
                    !cardCVCState.complete &&
                    !cardCVCState.empty
                      ? 'CVV entered must be minimum 3 digits.'
                      : ''}
                  </span>
                </>
              ) : (
                <StyledTextfield disabled value="***" />
              )}
            </div>
          </div>
        </div>

        <div className="payment">Add Billing Address</div>
        <div className="d-flex align-items-center mb-3">
          <input
            type="checkbox"
            className="checkbox-input"
            checked={isDefaultAddress}
            onChange={() => {
              setIsDefaultAddress(!isDefaultAddress)
              defaultAddressSetter(!isDefaultAddress)
            }}
          />
          <label className="checkbox-label">
            Billing address is same as home address
          </label>
        </div>
        <div>
          <div className="row mb-3">
            <div className="col-md-8 mb-3">
              <label className="card-label">Address Line 1</label>
              <Controller
                name="addressLine1"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    whiteBg
                    placeholder="Address Line 1"
                  />
                )}
              />
              {errors.addressLine1 && !values.addressLine1 && (
                <span className="helper-text">
                  {errors.addressLine1.message}
                </span>
              )}
            </div>
            <div className="col-md-8 mb-3">
              <label className="card-label">Address Line 2(Optional)</label>
              <Controller
                name="addressLine2"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    whiteBg
                    placeholder="Address Line 2"
                  />
                )}
              />
            </div>
            <div className="col-md-8">
              <label className="card-label">City</label>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <StyledTextfield {...field} whiteBg placeholder="City" />
                )}
              />
              {errors.city && !values.city && (
                <span className="helper-text">{errors.city.message}</span>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label className="card-label">State</label>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <StyledTextfield {...field} whiteBg placeholder="State" />
                )}
              />
              {errors.state && !values.city && (
                <span className="helper-text">{errors.state.message}</span>
              )}
            </div>
            <div className="col-md-4">
              <label className="card-label">Zip Code</label>
              <Controller
                name="zipCode"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    whiteBg
                    max={5}
                    type="number"
                    placeholder="Zip Code"
                  />
                )}
              />
              {errors.zipCode && !values.zipCode && (
                <span className="helper-text">
                  {errors.zipCode?.message?.toString()}
                </span>
              )}
              {values.zipCode && values.zipCode.length < 5 && (
                <span className="helper-text">
                  Zip code must be minimum 5 digits.
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-4 m-2">
          <input
            type="checkbox"
            className="checkbox-input"
            checked={isDefault}
            onChange={() => {
              setIsDefault(!isDefault)
            }}
          />
          <label className="checkbox-label">Default Card</label>
        </div>

        <label className="default-text col-md-6">
          Check this box to set this card as the default card. Uncheck it to not
          set it as the default card
        </label>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="row">
              <div className="col-12 col-lg-4 text-center">
                <Button
                  className="modal-button"
                  color="inherit"
                  onClick={() => {
                    setMode('')
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-12 col-lg-4 text-center">
                <Button
                  className="modal-button"
                  color="inherit"
                  type="submit"
                  onClick={() => {
                    errorCheck()
                  }}
                  endIcon={<BsArrowRightShort />}
                >
                  Save Card
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default CardForm
