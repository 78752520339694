import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import RejectReasons from '../../../../components/styled-dropdown/RejectReasons'
import { ConstantResponse, Reason } from '../../../../types/constants.type'
import { Tutor } from '../../../../types/tutor.type'
import { api } from '../../../../utils'
import { TutorModalsProps } from '../tutor-table/tutor-table.type'

interface RejectTutorProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<TutorModalsProps>>
  modalName: string
  tutorId?: string
  tutorData?: Tutor[] | null | undefined
  onSuccess?: () => void
}

const RejectTutor: FC<RejectTutorProps> = ({
  open,
  setOpen,
  modalName,
  tutorId,
  onSuccess,
}) => {
  const [reason, setReason] = useState('')
  const [rejectReasons, setRejectReasons] = useState<Reason[] | undefined>([])

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const declineTutor = async () => {
    const res = await api.put('admin/approveDeclineTutor/' + tutorId, {
      action: 'DECLINE',
      rejectReason: reason,
    })
    if (res.ok) {
      handleClose()
      onSuccess?.()
    }
  }

  useEffect(() => {
    const getRejectReason = async () => {
      const res = await api.get<ConstantResponse>('admin/getConstants')
      if (res.ok && res.data) {
        const { data } = res
        setRejectReasons(data?.data.rejectReasons)
      }
    }
    getRejectReason()
  }, [open.rejectTutor])

  return (
    <ModalWrapper
      open={open.rejectTutor}
      handleClose={handleClose}
      title="Reject Tutor"
      wide
    >
      <div className="d-flex justify-content-center flex-column text-center">
        <p>
          If you would like to reject this tutor, please pick a reason and click
          Reject.
        </p>
        <p>
          If you do not want to reject this tutor, please click the Cancel
          Button below.
        </p>

        <div className="d-flex justify-content-center text-center align-items-center">
          <div className="col-sm-5">
            <RejectReasons
              option={rejectReasons}
              placeholder="Select a reject reason"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value)
              }}
            />
          </div>
          <div className="col-sm-3 d-flex">
            <Button
              disabled={!reason}
              color={reason ? 'success' : 'info'}
              className="modal-button"
              onClick={declineTutor}
            >
              Reject
            </Button>
            <Button
              color="success"
              className="modal-button"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
        <div></div>
      </div>
    </ModalWrapper>
  )
}

export default RejectTutor
