import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'

interface ConfirmEditProps {
  open: boolean
  onClose: () => void
  onYes: () => void
}

const ConfirmEdit: FC<ConfirmEditProps> = ({ onClose, onYes, open }) => {
  return (
    <ModalWrapper open={open} handleClose={onClose} wide>
      <div className="d-flex justify-content-center align-items-center flex-column modal-text">
        <p className="text-center mt-3">
          You are attempting to edit a fee that is in affect for currently
          active tutors. Do you want to continue?
        </p>
        <div className="d-flex justify-content-center">
          <Button
            color="success"
            className="modal-button auto-accept-button"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            color="success"
            className="modal-button auto-accept-button"
            onClick={onYes}
          >
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmEdit
