export const MONTHS = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const MONTH = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM',
]

export const sortData = (data: any[]) => {
  data.forEach((_element, index) => {
    for (let i = 0; i < data.length - index - 1; i++) {
      if (data[i]._id.year > data[i + 1]._id.year) {
        let temp = data[i + 1]
        data[i + 1] = data[i]
        data[i] = temp
      } else if (
        data[i]._id.month > data[i + 1]._id.month &&
        data[i]._id.year == data[i + 1]._id.year
      ) {
        let temp = data[i + 1]
        data[i + 1] = data[i]
        data[i] = temp
      } else if (
        data[i]._id.day > data[i + 1]._id.day &&
        data[i]._id.month == data[i + 1]._id.month &&
        data[i]._id.year == data[i + 1]._id.year
      ) {
        let temp = data[i + 1]
        data[i + 1] = data[i]
        data[i] = temp
      } else if (
        data[i]._id.hour > data[i + 1]._id.hour &&
        data[i]._id.year == data[i + 1]._id.year &&
        data[i]._id.month == data[i + 1]._id.month &&
        data[i]._id.day == data[i + 1]._id.day
      ) {
        let temp = data[i + 1]
        data[i + 1] = data[i]
        data[i] = temp
      }
    }
  })
  return data
}

export const downloadUrl = (url: string, download: string) => {
  let tempUrl = url
  if (window.location.hostname === 'localhost') {
    tempUrl = tempUrl + '?5naOj0l0ut1G9UwNYgM1O'
  }
  if (url) {
    const element = document.createElement('a')
    element.href = tempUrl
    element.download = download
    element.setAttribute('style', 'display:none;')
    document.body.appendChild(element)
    element.click()
    element.remove()
  }
}
