import {
  Button,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'

import CustomDatePicker from '../../../../components/date-picker/CustomDatePicker'
import { useAppContext } from '../../../../contexts/AppContext'
import {
  Booking,
  BookingsRes,
  TutorsRelatedToParentData,
  TutorsRelatedToParentRes,
} from '../../../../types/getBooking.type'
import { api } from '../../../../utils'
import { CONSTANT } from '../../../../utils/constants'
import BookingDetailModal from '../booking-detail-modal/BookingDetailModal'
import './all-parent-bookings.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

function AllParentBookings() {
  const { updateAppState } = useAppContext()
  const [bookings, setBookings] = useState<Booking[]>([])
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [tutor, setTutor] = useState('')
  const [sessionStatus, setSessionStatus] = useState('')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [tutors, setTutors] = useState<TutorsRelatedToParentData[]>([])
  const [showBooking, setShowBooking] = useState<Booking | null>(null)

  const parentId = localStorage.getItem('parentId')
  const parentName = localStorage.getItem('parentName')

  const limit = 20
  const getTutorsRelatedToParent = async () => {
    const tutorsRes = await api.get<TutorsRelatedToParentRes>(
      '/admin/getTutorsRelatedToParent',
      { parentId },
    )
    if (tutorsRes.ok && tutorsRes.data) {
      const tutors = tutorsRes.data.data
      setTutors(tutors)
    }
  }

  useEffect(() => {
    const getParentBookings = async () => {
      updateAppState({ loading: true })
      const skip = (page - 1) * 20
      const queries: { [key: string]: any } = {
        limit: limit,
        skip,
        parentId,
        type: 'ALL',
        withCount: 'true',
      }
      if (tutor) queries.tutorId = tutor
      if (sessionStatus) queries.status = sessionStatus
      if (startDate) {
        const tempDate = startDate.format().split('T')
        queries.startDate = tempDate[0] + 'T00:00:00.000Z'
      }
      if (endDate) {
        const tempDate = endDate.format().split('T')
        queries.endDate = tempDate[0] + 'T23:59:59.999Z'
      }
      const parentBookingsRes = await api.get<BookingsRes>(
        '/admin/getBookings',
        queries,
      )
      if (parentBookingsRes.ok && parentBookingsRes.data) {
        const { bookings, count } = parentBookingsRes.data.data
        setBookings(bookings)
        setTotalPages(count)
      }
      updateAppState({ loading: false })
    }
    getParentBookings()
  }, [tutor, sessionStatus, startDate, endDate, page])

  useEffect(() => {
    getTutorsRelatedToParent()
  }, [])

  const clearFilters = () => {
    setTutor('')
    setSessionStatus('')
    setStartDate(null)
    setEndDate(null)
  }

  const bookingsElement = bookings.map((booking) => {
    const {
      _id,
      startTime,
      endTime,
      parentAbbreviation,
      studentId,
      tutorId,
      status,
      noResponseByParent,
      noResponseByTutor,
      parentTimezoneOffsetZone,
    } = booking
    let computedStatus = status
      .replace(/[_-]/g, ' ')
      .toLowerCase()
      .replace('cancelled', 'canceled')
    if (noResponseByParent) computedStatus = 'no response by parent'
    if (noResponseByTutor) computedStatus = 'no response by tutor'
    let computedStartTime = dayjs(startTime).tz(parentTimezoneOffsetZone)
    let computedEndTime = dayjs(endTime).tz(parentTimezoneOffsetZone)

    return (
      <TableRow
        onClick={() => setShowBooking(booking)}
        key={_id}
        className="parent-booking-row"
      >
        <TableCell>
          {computedStartTime ? computedStartTime.format('MMM DD, YYYY') : '-'}{' '}
          {computedStartTime.format('h:mmA')}-{computedEndTime.format('h:mmA')}{' '}
          {parentAbbreviation}
        </TableCell>
        <TableCell>{studentId.firstName}</TableCell>
        <TableCell>{`${tutorId?.firstName} ${tutorId?.lastName}`}</TableCell>
        <TableCell>{computedStatus ? computedStatus : '-'}</TableCell>
      </TableRow>
    )
  })

  const tutorDropdown = (
    <Select
      size="small"
      className={`w-100`}
      value={tutor}
      onChange={(event) => setTutor(event.target.value)}
      input={<OutlinedInput />}
      displayEmpty
      MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
    >
      <MenuItem disabled value="">
        --Select Tutor--
      </MenuItem>
      {tutors.map(({ _id, firstName, lastName }) => {
        return (
          <MenuItem key={_id} value={_id}>
            {`${firstName} ${lastName}`}
          </MenuItem>
        )
      })}
    </Select>
  )

  const sessionStatusDropdown = (
    <Select
      size="small"
      className={`w-100`}
      value={sessionStatus}
      onChange={(event) => setSessionStatus(event.target.value)}
      input={<OutlinedInput />}
      displayEmpty
      MenuProps={{ PaperProps: { sx: { maxHeight: 230 } } }}
    >
      <MenuItem disabled value="">
        --Select Session Status--
      </MenuItem>
      {CONSTANT.sessionStatuses?.map((status) => {
        return (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        )
      })}
    </Select>
  )
  const totalNum = Math.ceil(totalPages / limit)
  return (
    <div className="all-parent-bookings mt-30px">
      <div className="all-parent-bookings__title">
        Sessions for {parentName}
      </div>
      <Row className="mt-30px mb-2">
        <Col md={2}>
          <CustomDatePicker
            date={startDate}
            maxDate={endDate}
            onChange={(value) => {
              setStartDate(value as Dayjs)
            }}
            onClear={() => {
              setStartDate(null)
            }}
            placeholder="Select Start Date"
          />
        </Col>
        <Col md={2}>
          <CustomDatePicker
            date={endDate}
            minDate={startDate}
            placeholder="Select End Date"
            onChange={(value) => {
              setEndDate(value as Dayjs)
            }}
            onClear={() => {
              setEndDate(null)
            }}
          />
        </Col>
        <Col md={3}>{tutorDropdown}</Col>
        <Col md={3}>{sessionStatusDropdown}</Col>
        <Col md={2}>
          <Button className="px-2" variant="text" onClick={clearFilters}>
            Clear
          </Button>
        </Col>
      </Row>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              <TableCell className="col-sm-3">Date</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Tutor</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingsElement.length >= 1 ? (
              bookingsElement
            ) : (
              <div className="mt-2 ms-2 d-flex justify-content-start">
                <p className="all-parent-bookings__no-session">
                  No sessions found
                </p>
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalNum > 1 && (
        <div className="mt-2 d-flex justify-content-end">
          <Pagination
            count={totalNum}
            page={page}
            onChange={(_event, value) => setPage(value)}
            color="primary"
          />
        </div>
      )}
      {!!showBooking && (
        <BookingDetailModal
          open={!!showBooking}
          onClose={() => setShowBooking(null)}
          booking={showBooking as Booking}
          bookingState={{ pageType: 'detail' }}
        />
      )}
    </div>
  )
}

export default AllParentBookings
