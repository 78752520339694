import { Button, Rating } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

import { StyledRow } from '../../../../components/custom-bordered-row/StyledRow'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import ConfirmationModal from '../../../../components/modals/confirmation-modal/ConfirmationModal'
import PaymentCard from '../../../../components/payment-card/PaymentCard'
import StyledDropdown from '../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { Reason } from '../../../../types/constants.type'
import { Booking, bookingStates } from '../../../../types/getBooking.type'
import { GeneralRes } from '../../../../types/global.type'
import { PromoRes } from '../../../../types/promo.type'
import { SingleBookingRes } from '../../../../types/single-booking.type'
import { api } from '../../../../utils'
import { cancelBookingType } from '../../../sessions/all-sessions/components/CancelBookingModal'
import { AlteredBooking } from '../../../sessions/all-sessions/components/sessions-table/SessionsTable'
import { getAlteredBooking } from '../../../sessions/hooks/useAlteredSessions'
import './booking-detail-modal.scss'
import AddPromoCodeModal from './components/add-promo-code/AddPromoCodeModal'
import ChangeCardModal from './components/change-card/ChangeCardModal'
import { useAlteredBookingDetail } from './hooks/useAlteredBookingDetail'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(localeData)
dayjs.extend(isSameOrBefore)

interface BookingDetailModalIProps {
  open: boolean
  onClose: () => void
  booking: Booking
  path?: 'parent' | 'tutor' | 'session'
  bookingState?: bookingStates
  data?: FormData
  getAllSessions?: () => Promise<void>
  setBookingState?: React.Dispatch<
    React.SetStateAction<bookingStates | undefined>
  >
  setBooking?: React.Dispatch<
    React.SetStateAction<Booking | AlteredBooking | undefined>
  >
  setCancelBookingType?: React.Dispatch<
    React.SetStateAction<cancelBookingType | undefined>
  >
}

export interface State {
  paymentGo: boolean
  promoCodeName: any
  cards: any
  newData: any
  onBehalf?: string
  isRateChargeSuppress: boolean
  card: any[] | undefined
  paymentHoldPendingStaus: boolean
  isMarketplaceManager: string
  monthArray: any
  declinedReasons?: Reason[]
  allData: any
  allDataCancelPolicy: any
  fd: any
  userCards: any[]
  allCards: any[]
  defaultCardId?: string
  promoToSend: any
  reservationAvailableHours: number
  filteredReservations: any[]
  reservationNumber: string
  rating: string
  shownAddPromoButton: boolean
  totalAmount: number
  adminDiscount: number
  showChangePayment: boolean
}

const defaultState: State = {
  paymentGo: false,
  promoCodeName: undefined,
  cards: undefined,
  newData: undefined,
  onBehalf: undefined,
  isRateChargeSuppress: false,
  card: undefined,
  paymentHoldPendingStaus: false,
  isMarketplaceManager: 'false',
  monthArray: [],
  declinedReasons: undefined,
  allData: [],
  allDataCancelPolicy: undefined,
  fd: undefined,
  userCards: [],
  allCards: [],
  defaultCardId: undefined,
  promoToSend: undefined,
  reservationAvailableHours: 0,
  filteredReservations: [],
  reservationNumber: '',
  rating: '',
  shownAddPromoButton: false,
  totalAmount: 0,
  adminDiscount: 0,
  showChangePayment: false,
}

const BookingDetailModal: FC<BookingDetailModalIProps> = ({
  open,
  onClose,
  booking,
  bookingState,
  data,
  getAllSessions,
  setBookingState,
  setBooking,
  setCancelBookingType,
}) => {
  const [shownAddPromoButton, setShownAddPromoButton] = useState(false)
  const { updateAppState, constants } = useAppContext()
  const [state, setState] = useState(defaultState)
  const [errorModal, setErrorModal] = useState<string>()
  const [declineReason, setDeclineReason] = useState<string>()
  const [addPromoCodeModal, setAddPromoCodeModal] = useState(false)
  const [returnHourModal, setReturnHourModal] = useState(false)

  const onAddPromoCode = () => {
    setAddPromoCodeModal(true)
  }

  const updateState = (state: Partial<State>) => {
    setState((prev) => ({ ...prev, ...state }))
  }

  useEffect(() => {
    const isMarketplaceManager = localStorage.getItem(
      'isMarketplaceManager',
    ) as string
    updateState({ isMarketplaceManager })
  }, [])

  useAlteredBookingDetail({
    state,
    booking,
    bookingState,
    updateState,
    setShownAddPromoButton,
  })

  function checkShowBtn() {
    let flag: boolean
    let current = dayjs()
    let paymentHoldPendingStaus: boolean

    if (new Date(state.allData.startTime).getTime() > new Date().getTime()) {
      let holdFailDates = dayjs(state.allData.startTime)

      if (
        state.allData.payments.paymentStatus === 'hold failed' &&
        (state.allData.status !== 'CANCELLED_BY_PARENT' ||
          state.allData.status !== 'CANCELLED_BY_TUTOR' ||
          state.allData.status !== 'CANCELLED_BY_ADMIN')
      ) {
        flag = true
        paymentHoldPendingStaus = false
      } else if (state.allData.payments.paymentStatus === 'hold pending') {
        flag = true
        paymentHoldPendingStaus = true
      } else {
        flag = false
        paymentHoldPendingStaus = false
      }
    } else {
      flag = false
      paymentHoldPendingStaus = false
    }
    if (state.paymentHoldPendingStaus !== paymentHoldPendingStaus) {
      updateState({ paymentHoldPendingStaus })
    }

    return flag
  }

  const parentName = booking.parentId
    ? `${booking.parentId.firstName} ${booking.parentId.lastName}`
    : '-'
  const childName = booking.studentId
    ? `${booking.studentId.firstName} ${booking.studentId.lastName}`
    : '-'
  const childGrade =
    booking.studentId && booking.studentId.gradeLevel
      ? booking.studentId.gradeLevel
      : '-'

  const academicReports = booking.studentId?.academicReports?.length
    ? booking.studentId.academicReports.map((report, index) => (
        <div key={index}>
          <a href={report.url} className="app-link" target="_blank">
            {report.name}
          </a>
        </div>
      ))
    : '-'

  const tutorName = booking.tutorId
    ? `${booking.tutorId.firstName} ${booking.tutorId.lastName}`
    : '-'

  let rating = '-'
  if (
    bookingState?.pageType === 'ReschudeBooking' &&
    state.allDataCancelPolicy?.tutorRatings?.rating
  ) {
    rating = booking?.tutorRatings?.rating as string
  }
  if (
    bookingState?.pageType !== 'ReschudeBookingflow' &&
    booking.tutorRatings?.rating
  ) {
    rating = booking.tutorRatings.rating
  }

  const feedback = booking.tutorRatings?.feedback
    ? booking.tutorRatings?.feedback
    : '-'

  const subjects = booking.subjects.map((subject, index) => (
    <div className="d-inline" key={index}>
      {subject.name}
      {booking.subjects.length - 1 !== index ? ',' : ''}
    </div>
  ))

  const subSubjects = booking.subjects.map((subject, i) => (
    <div key={i} className="d-inline">
      {subject.subcategories.map((subCat, j) => (
        <div key={j} className="d-inline">
          {subCat.name}
          {j !== subject.subcategories.length - 1 ||
          i !== booking.subjects.length - 1
            ? ','
            : ''}
        </div>
      ))}
    </div>
  ))

  let status1 = ''
  if (booking.status)
    status1 = booking.status.replace(/[_-]/g, ' ').toLowerCase()
  if (booking.noResponseByTutor) status1 = 'no response by tutor'
  if (booking.noResponseByParent) status1 = 'no response by parent'

  let actualDuration: string | React.ReactElement = '-'
  if (
    booking.payments?.paymentStatus !== 'capture failed' &&
    (status1 === 'completed' || status1 === 'finished')
  ) {
    actualDuration = (
      <div>
        {booking.payments?.actualSessionTime
          ? booking.payments.actualSessionTime
          : '-'}{' '}
        {booking.payments?.actualSessionTime
          ? booking.payments.actualSessionTime / 2 <= 1
            ? 'hour'
            : 'hours'
          : ''}
      </div>
    )
  }

  const proposedDuration = (
    <div>
      {booking.payments?.duration ? booking.payments.duration : '-'}
      {booking.payments?.duration
        ? booking.payments.duration / 1 <= 1
          ? ' hour'
          : ' hours'
        : ''}
    </div>
  )

  let originalRescheduleStartTime1: Dayjs | null = null,
    originalRescheduleEndTime1: Dayjs | null = null,
    abb1 = ''

  if (booking.originalRescheduleStartTime) {
    originalRescheduleStartTime1 = dayjs(
      booking.originalRescheduleStartTime,
    ).tz(booking.parentTimezoneOffsetZone)
    originalRescheduleEndTime1 = dayjs(booking.originalRescheduleEndTime).tz(
      booking.parentTimezoneOffsetZone,
    )
    abb1 = dayjs
      .tz(booking.originalRescheduleStartTime, booking.parentTimezoneOffsetZone)
      .format('z')
  }

  let startTime1: Dayjs | null = null,
    endTime1: Dayjs | null = null,
    abb = ''

  if (booking.startTime) {
    startTime1 = dayjs(booking.startTime).tz(booking.parentTimezoneOffsetZone)
    endTime1 = dayjs(booking.endTime).tz(booking.parentTimezoneOffsetZone)
    abb = dayjs
      .tz(booking.startTime, booking.parentTimezoneOffsetZone)
      .format('z')
  }

  const originalDateAndTime = originalRescheduleStartTime1 ? (
    <>
      {originalRescheduleStartTime1.format('MMM D, YYYY')}{' '}
      {originalRescheduleStartTime1
        ? '| ' + originalRescheduleStartTime1.format('h:mmA')
        : '-'}
      {originalRescheduleEndTime1
        ? '-' + originalRescheduleEndTime1.format('h:mmA')
        : ''}
      {` ${abb1}`}
    </>
  ) : (
    !!(startTime1 && endTime1) && (
      <>
        {startTime1.format('MMM D, YYYY')}{' '}
        {startTime1 ? '| ' + startTime1.format('h:mmA') : '-'}
        {endTime1 ? '-' + endTime1.format('h:mmA') : ''} {abb}
      </>
    )
  )

  let rescheduledStartTime1: Dayjs | null = null,
    rescheduledEndTime1: Dayjs | null = null,
    rdAbb = ''

  if (booking?.rescheduleData?.startTime) {
    rescheduledStartTime1 = booking.rescheduleData?.startTime
      ? dayjs(booking.rescheduleData.startTime).tz(
          booking.parentTimezoneOffsetZone,
        )
      : null
    rescheduledEndTime1 = booking.rescheduleData?.endTime
      ? dayjs(booking.rescheduleData.endTime).tz(
          booking.parentTimezoneOffsetZone,
        )
      : null
    if (!booking.rescheduleData.parentAbbreviation) {
      rdAbb = dayjs
        .tz(booking.rescheduleData.startTime, booking.parentTimezoneOffsetZone)
        .format('z')
    }
  }

  const proposedDateAndTime = rescheduledStartTime1 ? (
    <>
      {rescheduledStartTime1.format('MMM D, YYYY')} |{' '}
      {rescheduledStartTime1.format('h:mmA')}
      {rescheduledEndTime1
        ? '-' + rescheduledEndTime1.format('h:mmA')
        : ''}{' '}
      {booking.rescheduleData.parentAbbreviation
        ? booking.rescheduleData.parentAbbreviation
        : `${rdAbb}`}
    </>
  ) : (
    '-'
  )

  let rescheduledDateAndTime = '-'
  if (
    rescheduledStartTime1 &&
    status1 !== 'reschedule rejected by parent' &&
    status1 !== 'reschedule rejected by tutor' &&
    status1 !== 'reschedule request by parent' &&
    status1 !== 'reschedule request by tutor' &&
    booking.startTime === booking.rescheduleData.startTime
  ) {
    rescheduledDateAndTime = `${rescheduledStartTime1.format(
      'MMM D, YYYY',
    )} | ${rescheduledStartTime1.format('h:mmA')}${
      rescheduledEndTime1 ? '-' + rescheduledEndTime1.format('h:mmA') : ''
    } ${
      booking.rescheduleData.parentAbbreviation
        ? booking.rescheduleData.parentAbbreviation
        : rdAbb
    }`
  }

  let sessionStartTime1: Dayjs | null = null,
    sessionEndTime1: Dayjs | null = null

  if (booking.sessionStartTime) {
    sessionStartTime1 = dayjs(booking.sessionStartTime).tz(
      booking.parentTimezoneOffsetZone,
    )
    if (booking.sessionEndTime) {
      sessionEndTime1 = dayjs(booking.sessionEndTime).tz(
        booking.parentTimezoneOffsetZone,
      )
    }
  }

  let actualDateAndTime = '-'
  if (
    ['completed', 'finished', 'session started'].some((s) => status1 === s) &&
    sessionStartTime1
  ) {
    actualDateAndTime = `${sessionStartTime1.format('MMM D, YYYY')} ${
      sessionStartTime1 ? '| ' + sessionStartTime1.format('h:mmA') : ''
    }${
      sessionEndTime1 && (status1 === 'completed' || status1 === 'finished')
        ? '-' + sessionEndTime1.format('h:mmA')
        : ''
    } ${booking.parentAbbreviation}`
  }

  let totalAmount = 0
  if (booking.payments?.amountCharged) {
    totalAmount = totalAmount + booking.payments.amountCharged
  }
  if (booking.payments?.partialAmountLeft) {
    totalAmount = totalAmount + booking.payments.partialAmountLeft
  }
  if (booking.payments?.partialAmountCharged) {
    totalAmount = totalAmount + booking.payments.partialAmountCharged
  }

  let cancelledAt1: Dayjs | null = null

  if (booking.cancelledAt) {
    cancelledAt1 = dayjs(booking.cancelledAt).tz(
      booking.parentTimezoneOffsetZone,
    )
  }

  let adminDiscount = 0
  if (booking.payments?.adminFee?.discount) {
    if (booking.payments.adminFee.type === 'FLAT') {
      adminDiscount =
        booking.payments.adminFee.discount * booking.payments.duration
    } else {
      adminDiscount = booking.payments.adminFee.discount
    }
  }

  const paymentStatus = booking.payments.paymentStatus
    .replace(/[_-]/g, ' ')
    .toLowerCase()

  const handleViewTutor = () => {
    if (!booking.tutorId) return
    localStorage.setItem('tutorId', booking.tutorId._id)
    window.open('/pages/session/view-tutor', '_blank')
  }

  if (errorModal) {
    return (
      <ConfirmationModal
        open={open}
        topMessage={errorModal}
        onClose={onClose}
        declineTitle="Ok"
        noSuccessBtn
      />
    )
  }

  const getBookingById = async () => {
    const res = await api.get<SingleBookingRes>('/admin/getBookingById', {
      bookingId: booking._id,
    })
    if (res.ok && res.data) {
      const alteredBooking = getAlteredBooking(res.data.data)
      setBooking?.(alteredBooking)
    }
  }

  const returnHour = async () => {
    updateAppState({ loading: true })
    const res = await api.put(`/booking/returnCredit/${booking._id}`)
    if (res.ok && res.data) {
      toast.success('Success!')
    }
    onClose()
    updateAppState({ loading: false })
  }

  if (returnHourModal) {
    return (
      <ModalWrapper open={returnHourModal} handleClose={onClose} wide>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <p style={{ color: '#232f84' }}>
            Are you sure you want to return an hour to this parent
          </p>
          <div>
            <Button color="success" className="modal-button" onClick={onClose}>
              No
            </Button>
            <Button
              color="success"
              className="modal-button"
              onClick={() => {
                returnHour()
                setReturnHourModal(false)
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalWrapper>
    )
  }

  if (addPromoCodeModal) {
    return (
      <AddPromoCodeModal
        // @ts-ignore
        booking={booking}
        open={addPromoCodeModal}
        onClose={(isSuccess) => {
          setAddPromoCodeModal(false)
          if (isSuccess) {
            getBookingById()
            getAllSessions?.()
          }
        }}
      />
    )
  }

  if (bookingState?.pageType === 'detail' && state.showChangePayment) {
    return (
      <ChangeCardModal
        open={state.showChangePayment}
        booking={booking}
        onClose={onClose}
        onSuccess={() => updateState({ showChangePayment: false })}
      />
    )
  }

  if (bookingState?.pageType === 'detail' && !state.showChangePayment) {
    return (
      <ModalWrapper
        open={open}
        handleClose={onClose}
        title="Booking Details"
        wide
      >
        <StyledRow field="Field Name" description="Description" />
        <StyledRow field="Parent Name" description={parentName} />
        <StyledRow field="Child Name" description={childName} />
        <StyledRow field="Child Grade" description={childGrade} />
        {!!booking?.studentId?.academicReports && (
          <StyledRow field="Child Document" description={academicReports} />
        )}
        <StyledRow
          field="Tutor Name"
          description={
            booking.tutorId ? (
              <div>
                <a className="app-link" onClick={handleViewTutor}>
                  {tutorName}
                </a>
              </div>
            ) : (
              '-'
            )
          }
        />
        <StyledRow field="Tutor Rating" description={rating} />
        <StyledRow field="Rating Feedback" description={feedback} />
        {!!booking.additionalNotes && (
          <StyledRow
            field="Additional Note"
            description={booking.additionalNotes}
          />
        )}
        <StyledRow
          field="Parent is flexible on their session location."
          description={booking.isFlexibleOnSessionLocation ? 'Yes' : 'No'}
        />
        <StyledRow field="Subject" description={subjects} />
        <StyledRow field="Sub Subject" description={subSubjects} />
        <StyledRow field="Actual Duration" description={actualDuration} />
        <StyledRow field="Proposed Duration" description={proposedDuration} />
        <StyledRow
          field="Multi-session Request"
          description={booking.multiSessionRequest ? 'Yes' : 'No'}
        />
        <StyledRow
          field="Original Date and Time"
          description={originalDateAndTime}
        />
        <StyledRow
          field="Proposed Date and Time"
          description={proposedDateAndTime}
        />
        <StyledRow
          field="Rescheduled Date and Time"
          description={rescheduledDateAndTime}
        />
        <StyledRow
          field="Actual Date and Time"
          description={actualDateAndTime}
        />
        <StyledRow
          field="Charged Amount"
          description={
            booking.payments.amountCharged >= 0
              ? '$' + booking.payments.amountCharged.toFixed(2)
              : '-'
          }
        />
        <StyledRow
          field="2nd Charged Amount"
          description={
            booking.payments.partialAmountCharged
              ? '$' + booking.payments.partialAmountCharged.toFixed(2)
              : '-'
          }
        />
        {!!booking.payments && (
          <StyledRow
            field="Partial Amount Left"
            description={
              booking.payments.partialAmountLeft >= 0
                ? '$' + booking.payments.partialAmountLeft.toFixed(2)
                : '-'
            }
          />
        )}
        {!!booking.payments && (
          <StyledRow
            field="Actual Cost"
            description={'$' + totalAmount.toFixed(2)}
          />
        )}
        {!!booking.payments?.paymentStatus && (
          <StyledRow
            field="Projected Cost"
            description={
              booking.payments.projectedAmount &&
              booking.payments.projectedAmount >= 0
                ? '$' + booking.payments.projectedAmount.toFixed(2)
                : '-'
            }
          />
        )}
        <StyledRow
          field="Partnership Code"
          description={
            booking.partnershipCode?.partnercode
              ? booking.partnershipCode.partnercode
              : '-'
          }
        />
        {!!booking.isBookingUseCredits && (
          <StyledRow
            field="Reservation Number"
            description={booking.reservationNumber}
          />
        )}
        {!!booking.payments?.paymentStatus && (
          <StyledRow
            field="Payment Status"
            description={
              booking.payments.paymentStatus === 'HOLD_SUCCESS'
                ? 'hold success'
                : paymentStatus
            }
          />
        )}
        {!!booking.cancelledAt && (
          <StyledRow
            field="Cancelation Date"
            description={`${cancelledAt1?.format(
              'MM-DD-YYYY h:mmA',
            )} ${cancelledAt1?.format('z')}`}
          />
        )}
        {!!booking.reasonForCancellation && (
          <StyledRow
            field="Cancelation Reason"
            description={booking.reasonForCancellation}
          />
        )}
        {!!booking.additionalDetails && (
          <StyledRow
            field="Cancelation Text"
            description={booking.additionalDetails}
          />
        )}

        <StyledRow
          field="Promo Code"
          description={
            !shownAddPromoButton ? (
              booking.payments?.promocode ? (
                booking.payments.promocode
              ) : (
                '-'
              )
            ) : (
              <div className="d-flex justify-content-end">
                <Button color="inherit" onClick={onAddPromoCode}>
                  Add Code
                </Button>
              </div>
            )
          }
        />
        <StyledRow
          field="Address"
          description={
            <>
              {booking.locationDetails.addressLine1
                ? booking.locationDetails.addressLine1
                : ''}
              {booking.locationDetails.addressLine2
                ? ', ' + booking.locationDetails.addressLine2
                : ''}
              {booking.locationDetails.city
                ? ',' + booking.locationDetails.city
                : ''}
              {booking.locationDetails.state
                ? ', ' + booking.locationDetails.state
                : ''}
              {booking.locationDetails.zipCode
                ? ', ' + booking.locationDetails.zipCode
                : ''}
            </>
          }
        />
        <StyledRow
          field="Online Session"
          description={booking.isSessionOnline ? 'Yes' : 'No'}
        />
        <StyledRow
          field="Requested By"
          description={
            booking.sessionRequestedBy === 'TUTOR' ? 'Tutor' : 'Parent'
          }
        />
        {!!booking.payments.adminFee && (
          <StyledRow
            field="Admin Fee"
            description={
              booking.payments.adminFee?.type && adminDiscount >= 0
                ? (booking.payments.adminFee.type === 'FLAT' ? '$' : '') +
                  adminDiscount.toFixed(2) +
                  (booking.payments.adminFee.type === 'PERCENT' ? '%' : '')
                : '-'
            }
          />
        )}
        {!!(
          booking.payments?.adminEarnings ||
          booking.payments?.adminEarnings === 0
        ) && (
          <StyledRow
            field="Amount Earned By Admin"
            description={'$' + booking.payments.adminEarnings.toFixed(2)}
          />
        )}
        {!!(
          booking.payments?.tutorEarnings ||
          booking.payments?.tutorEarnings === 0
        ) && (
          <StyledRow
            field="Amount Earned By Tutor"
            description={
              booking.payments.tutorEarnings >= 0
                ? '$' + booking.payments.tutorEarnings.toFixed(2)
                : '-'
            }
          />
        )}
        <StyledRow field="Status" description={status1} />
        {!!booking.reasonForDecline && (
          <StyledRow
            field="Decline Reason"
            description={booking.reasonForDecline}
          />
        )}
        {!!booking.sessionFeedback?.comments && (
          <StyledRow
            field="Feedback Text"
            description={booking.sessionFeedback?.comments}
          />
        )}
        {!!booking.sessionFeedback?.documents?.length && (
          <StyledRow
            field="Feedback Document"
            description={booking.sessionFeedback.documents.map((doc, index) => (
              <a key={index} href={doc.url} target="_blank">
                {doc.name}
              </a>
            ))}
          />
        )}
        <div className="col-md-10 col-sm-10 text-end">
          {checkShowBtn() && (
            <Button
              color="success"
              className="view-session-button m-1"
              onClick={() => {
                updateState({ showChangePayment: true })
              }}
            >
              Change Payment Method
            </Button>
          )}
          {!!(
            state.isMarketplaceManager &&
            !booking.isCreditsReturn &&
            booking.isBookingUseCredits &&
            ['Canceled', 'Completed'].some((s) => booking.status.includes(s))
          ) && (
            <Button
              color="success"
              className="view-session-button m-1"
              onClick={() => setReturnHourModal(true)}
            >
              Return Hour
            </Button>
          )}
          <Button
            color="success"
            className="view-session-button m-1"
            onClick={onClose}
          >
            OK
          </Button>
        </div>
      </ModalWrapper>
    )
  }

  const handleCancelBooking = async () => {
    updateAppState({ loading: true })
    const res = await api.put(`/admin/cancelBooking/${booking._id}`, data)
    updateAppState({ loading: false })

    if (res.ok) {
      getAllSessions?.()
    }
    onClose()
  }

  if (bookingState?.pageType === 'ReschudeBooking') {
    return (
      <ConfirmationModal
        title="Cancel Session"
        topMessage="Are you sure you want to cancel the session?"
        open={open}
        onClose={onClose}
        onSuccess={handleCancelBooking}
      />
    )
  }

  const handleCancelBooking1 = async () => {
    setBookingState?.({ ...bookingState, pageType: 'ReschudeBooking' })
  }

  const handleShowClose = () => {
    onClose()
    setBooking?.(booking)
    setCancelBookingType?.('cancel')
  }

  if (bookingState?.pageType === 'noShowTutor') {
    return (
      <ConfirmationModal
        topMessage="Are you sure you want to mark this person as a no-show?"
        open={open}
        onClose={onClose}
        onDecline={handleShowClose}
        onSuccess={handleCancelBooking1}
      />
    )
  }

  if (bookingState?.pageType === 'reject') {
    const declineReasons =
      state.onBehalf === 'Parent'
        ? constants?.parentDeclineReasons
        : constants?.tutorDeclineReasons

    const handleRejectBooking = async () => {
      if (!declineReason) return
      const formData = new FormData()
      formData.append('action', 'REJECT')
      formData.append('reason', declineReason)
      if (state.onBehalf === 'Parent') formData.append('onBehalf', 'Parent')
      updateAppState({ loading: true })
      const data = await acceptRejectBooking({
        data: formData,
        bookingId: booking._id,
      })
      updateAppState({ loading: false })
      if (data) {
        getAllSessions?.()
      }
      onClose()
    }

    return (
      <ModalWrapper
        open={open}
        handleClose={onClose}
        title="Decline Session"
        wide
      >
        <div>
          <div className="my-2" style={{ color: '#9d9d9d' }}>
            <span>On Behalf of:</span>
            <span style={{ marginLeft: '1%' }}>{state.onBehalf}</span>
          </div>
          <StyledDropdown
            value={declineReason}
            option={declineReasons?.map((reason) => ({
              id: reason._id,
              option: reason.text,
              value: reason.text,
            }))}
            onChange={(e) => setDeclineReason(e.target.value)}
            placeholder="---Select Option---"
          />
          <div className="d-flex justify-content-center mt-4">
            <Button color="success" className="modal-button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!declineReason}
              color={declineReason ? 'success' : 'info'}
              className="modal-button"
              onClick={handleRejectBooking}
            >
              Decline Session
            </Button>
          </div>
        </div>
      </ModalWrapper>
    )
  }

  type Action = {
    data: FormData
    bookingId: string
    isRateChargeSuppress?: boolean
    isParentOutOfTutoringHours?: boolean
    onBehalf?: string
  }
  async function acceptRejectBooking(action: Action) {
    let url: string
    if (action.data.get('reschedule')) {
      action.data.delete('reschedule')
      url = `/booking/rescheduleAcceptReject/${action.bookingId}`
    } else if (action.data.get('cardId')) {
      url = `/booking/acceptRejectByParent/${action.bookingId}`
    } else if (
      action.data.get('onBehalf') == 'Parent' &&
      action.data.get('action') == 'REJECT'
    ) {
      action.data.delete('onBehalf')
      url = `/booking/acceptRejectByParent/${action.bookingId}`
    } else {
      if (action.isRateChargeSuppress && !action.isParentOutOfTutoringHours) {
        if (action.onBehalf === 'Parent') {
          url = `/booking/acceptRejectByParent/${action.bookingId}`
        } else {
          url = `/booking/acceptReject/${action.bookingId}`
        }
      } else {
        url = `/booking/acceptReject/${action.bookingId}`
      }
    }
    const res = await api.put(url, action.data)
    if (res.ok && res.data) {
      return res.data
    }
  }

  if (bookingState?.pageType === 'accept') {
    const handleAcceptBooking = async () => {
      const formData = new FormData()
      formData.append('action', 'ACCEPT')

      if (
        (state.onBehalf === 'Parent' && !state.isRateChargeSuppress) ||
        (state.isRateChargeSuppress &&
          state.allData.parentId.parent.isParentOutOfTutoringHours)
      ) {
        if (state.promoCodeName) {
          formData.append('promocode', state.promoCodeName)
        }
        if (state.cards) {
          formData.append('cardId', state.cards)
        } else {
          if (state.onBehalf === 'Parent') {
            return
          }
        }
      }

      if (
        state.onBehalf === 'Parent' &&
        state.isRateChargeSuppress &&
        state.reservationNumber
      ) {
        formData.append('reservationNumber', state.reservationNumber)
      }

      updateAppState({ loading: true })
      const res = await acceptRejectBooking({
        data: formData,
        onBehalf: state.onBehalf,
        bookingId: booking._id,
        isParentOutOfTutoringHours:
          state.allData.parentId.parent.isParentOutOfTutoringHours,
        isRateChargeSuppress: state.isRateChargeSuppress,
      })
      updateAppState({ loading: false })
      if (res) {
        getAllSessions?.()
      }
      onClose()
    }

    const checkPromo = async () => {
      if (state.promoCodeName) {
        const res = await api.get<PromoRes>('/admin/checkPromocode', {
          promocode: state.promoCodeName,
        })
        if (res.ok && res.data) {
          if (res.data.message) {
            toast.success(res.data.message)
          }

          const data = res.data.data
          const promoToSend = data.promocode
          setState((prev) => ({ ...prev, promoToSend }))
          if (promoToSend) {
            setState((prev) => ({ ...prev, paymentGo: false }))
          }
        } else {
          setState((prev) => ({
            ...prev,
            paymentGo: true,
            promoToSend: undefined,
          }))
        }
      } else {
        let paymentGo = !state.cards
        setState((prev) => ({ ...prev, paymentGo }))
      }
    }

    return (
      <ModalWrapper
        open={open}
        handleClose={onClose}
        title="Accept Session"
        wide
        className="accept-session"
      >
        {(!state.isRateChargeSuppress ||
          (state.onBehalf === 'Parent' &&
            booking.parentId?.parent?.isParentOutOfTutoringHours &&
            state.card &&
            state.card.length > 0) ||
          (state.onBehalf === 'Parent' &&
            state.isRateChargeSuppress &&
            booking.parentId?.parent?.isParentOutOfTutoringHours &&
            state.card &&
            state.card.length > 0) ||
          state.onBehalf === 'Tutor') && (
          <div>
            <p className="text-center blueText">
              Are you sure you want to accept this session on behalf of the{' '}
              {state.onBehalf} {state.onBehalf === 'Parent' && '?'}
            </p>
            {!!(state.card?.length && state.onBehalf === 'Parent') && (
              <div className="form-group row mt-4 align-items-start">
                <div className="col-sm-4">
                  <label htmlFor="card">Select Card</label>
                </div>
                <div className="col-sm-8">
                  <div className="row">
                    {!!(state.card && state.card.length == 0) && (
                      <div className="form-group row">
                        <div className="col-sm-4">
                          <label htmlFor="card">Select Card</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="row">
                            <div className="col-sm-11 mart20px bor txtCol">
                              Card is Missing
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {state.card.map((item) => (
                      <PaymentCard
                        key={item.id}
                        card={{ ...item, brand: item.type, _id: item.id }}
                        selectedCard={state.cards}
                        setDefault={async (id) => {
                          const paymentGo = !(
                            state.cards &&
                            (!state.promoCodeName || state.promoToSend)
                          )
                          setState((prev) => ({
                            ...prev,
                            cards: id,
                            paymentGo,
                          }))
                        }}
                        className="w-100"
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {state.onBehalf === 'Parent' && (
              <div className="form-group row align-items-start">
                <div className="col-sm-4">
                  <label htmlFor="promocode">Promo Code</label>
                </div>
                <div className="col-sm-8 p-0">
                  <StyledTextfield
                    name="promocode"
                    onKeyDown={() =>
                      setState((prev) => ({ ...prev, paymentGo: true }))
                    }
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        promoCodeName: e.target.value,
                      }))
                    }
                    placeholder="Promo code Name"
                    onBlur={checkPromo}
                  />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center">
              <Button
                color="success"
                className="modal-button"
                onClick={onClose}
              >
                No
              </Button>
              <Button
                color={state.paymentGo ? 'info' : 'success'}
                className="modal-button"
                disabled={state.paymentGo}
                onClick={handleAcceptBooking}
              >
                Yes
              </Button>
            </div>
          </div>
        )}

        {state.onBehalf === 'Parent' && state.isRateChargeSuppress && (
          <>
            {(booking?.parentId?.parent?.numberOfTutoringHours || 0) > 0 && (
              <>
                <p className="text-center">
                  Are you sure you want to accept this session on behalf of the{' '}
                  {state.onBehalf}
                  <span>?</span>
                  <br />
                  <br />
                </p>
                {state.reservationAvailableHours === 0 && (
                  <>
                    <p className="text-center">
                      This parent does not have any eligible tutoring credit
                      hours for the requested session date. Please contact the
                      parent and/or tutor to consider a qualified date for this
                      session that is within the expiration period.
                    </p>

                    <div className="row">
                      <div className="offset-sm-4 col-md-4">
                        <Button color="success" onClick={onClose}>
                          OK
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {state.reservationAvailableHours >= 1 && (
                  <>
                    {state.allData?.parentId?.parent
                      ?.sessionCreditsFromExternalApi?.length === 1 && (
                      <>
                        {state.allData?.parentId?.parent?.sessionCreditsFromExternalApi.map(
                          (data: any) => (
                            <p key={data._id} className="text-center">
                              Reservation {data?.reservationNumber} will be used
                              for this session. This reservation has{' '}
                              {data?.numberOfTutoringHours} available{' '}
                              {data?.numberOfTutoringHours > 1
                                ? 'hours'
                                : 'hour'}{' '}
                              and is set to expire on{' '}
                              {dayjs(data?.showEndDate).format('MM/DD/YYYY')}.
                            </p>
                          ),
                        )}
                      </>
                    )}
                    {state.allData?.parentId?.parent
                      ?.sessionCreditsFromExternalApi?.length > 1 && (
                      <>
                        <p className="text-center">
                          Please select a reservation to be used for this
                          session
                        </p>
                        <br />
                        <br />
                        <StyledDropdown
                          value={state.reservationNumber}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              reservationNumber: e.target.value,
                            }))
                          }
                          option={state.filteredReservations.map(
                            (data: any) => ({
                              id: data.reservationNumber,
                              option: `${data.reservationNumber}: ${
                                data.numberOfTutoringHours
                              } ${
                                data.numberOfTutoringHours > 1
                                  ? 'hour'
                                  : 'hours'
                              } expiring on ${dayjs(data.showEndDate).format(
                                'MM/DD/YYYY',
                              )}`,
                              value: data.reservationNumber,
                            }),
                          )}
                        />
                      </>
                    )}

                    {state.reservationAvailableHours > 0 && (
                      <div className="d-flex justify-content-center">
                        <Button
                          color="success"
                          className="modal-button"
                          onClick={onClose}
                        >
                          No
                        </Button>
                        <Button
                          color="success"
                          className="modal-button"
                          onClick={handleAcceptBooking}
                        >
                          {state.isRateChargeSuppress
                            ? 'Accept session'
                            : 'Yes'}
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {!!(
              state.allData?.parentId?.parent?.numberOfTutoringHours === 0 &&
              !state.allData?.parentId?.parent?.isParentOutOfTutoringHours
            ) && (
              <>
                <p className="text-center">
                  Are you sure you want to accept this session on behalf of the{' '}
                  {state.onBehalf}
                  <span>?</span>
                  <br />
                  <br />
                  This parent has no tutoring hours available but has not been
                  marked as out of tutoring hours. Please either have the parent
                  marked as out of hours, by an admin or the parent, or ask the
                  parent to purchase more hours.
                </p>
                <div className="row">
                  <div className="offset-sm-4 col-md-4 ">
                    <Button color="success" onClick={onClose}>
                      OK
                    </Button>
                  </div>
                </div>
              </>
            )}

            {!!(
              state.allData?.parentId?.parent?.isParentOutOfTutoringHours &&
              !state.card?.length
            ) && (
              <>
                <p className="text-center">
                  Are you sure you want to accept this session on behalf of the{' '}
                  {state.onBehalf}
                  <span>?</span>
                  <br />
                  <br />
                  This parent is out of available tutoring hours but no credit
                  card is on file. Please have one added to the account, by an
                  admin or the parent, before accepting this session on their
                  behalf.
                </p>
                <div className="row">
                  <div className="offset-sm-4 col-md-4 ">
                    <Button color="success" onClick={onClose}>
                      OK
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </ModalWrapper>
    )
  }

  if (bookingState?.pageType === 'earning') {
    return (
      <ModalWrapper
        open={open}
        handleClose={onClose}
        title="Booking Earning"
        wide
      >
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <StyledRow field="Field Name" description="Description" />
            <StyledRow
              field="Session Date"
              description={dayjs(state.allData.startTime1).format('MM-DD-YYYY')}
            />
            <StyledRow
              field="Amount earned by admin"
              description={
                state.allData.payments?.adminEarnings >= 0
                  ? '$' + state.allData.payments.adminEarnings.toFixed(2)
                  : ''
              }
            />
            <StyledRow
              field="Amount earned by tutor"
              description={
                state.allData.payments?.tutorEarnings >= 0
                  ? '$' + state.allData.payments.tutorEarnings.toFixed(2)
                  : ''
              }
            />
          </div>

          <div className="col-md-10 col-sm-10 d-flex justify-content-end mt-4">
            <Button
              onClick={onClose}
              style={{ width: '125px' }}
              color="success"
            >
              OK
            </Button>
          </div>
        </div>
      </ModalWrapper>
    )
  }

  if (bookingState?.pageType === 'acceptReschedule') {
    const handleAcceptReschedule = async () => {
      const formData = new FormData()
      formData.append('action', 'ACCEPT')
      formData.append('reschedule', 'true')
      const onBehalf =
        state.allData.status1 == 'reschedule request by tutor'
          ? 'PARENT'
          : 'TUTOR'
      formData.append('onBehalf', onBehalf)

      updateAppState({ loading: true })
      const res = await acceptRejectBooking({
        data: formData,
        bookingId: booking._id,
      })
      updateAppState({ loading: false })
      if (res) {
        getAllSessions?.()
      }
      onClose()
    }

    return (
      <ConfirmationModal
        title="Accept Reschedule"
        topMessage="Are you sure you want to accept this reschedule request?"
        open={open}
        onClose={onClose}
        onSuccess={handleAcceptReschedule}
      />
    )
  }

  if (bookingState?.pageType === 'rejectReschedule') {
    const handleRejectReschedule = async () => {
      const formData = new FormData()
      formData.append('action', 'REJECT')
      formData.append('reschedule', 'true')
      const onBehalf =
        state.allData.status1 == 'reschedule request by tutor'
          ? 'PARENT'
          : 'TUTOR'
      formData.append('onBehalf', onBehalf)

      updateAppState({ loading: true })
      const res = await acceptRejectBooking({
        data: formData,
        bookingId: booking._id,
      })
      updateAppState({ loading: false })
      if (res) {
        getAllSessions?.()
      }
      onClose()
    }

    return (
      <ConfirmationModal
        title="Decline Reschedule"
        topMessage="Are you sure you want to decline this reschedule request?"
        open={open}
        onClose={onClose}
        onSuccess={handleRejectReschedule}
      />
    )
  }

  if (bookingState?.pageType === 'ReschudeBookingflow') {
    const handleReschedule = async () => {
      const res = await api.put<GeneralRes>(
        `/admin/rescheduleRequestByAdmin/${booking._id}`,
        // @ts-ignore
        booking.fd,
      )
      if (res.ok && res.data) {
        getAllSessions?.()
        onClose()
      } else if (res.data?.message) {
        setErrorModal(res.data.message)
      }
    }

    return (
      <ConfirmationModal
        title="Reschedule Session"
        topMessage="Are you sure you want to reschedule the session?"
        open={open}
        onClose={onClose}
        onSuccess={handleReschedule}
      />
    )
  }

  if (bookingState?.pageType === 'feedBack') {
    return (
      <ModalWrapper
        open={open}
        handleClose={onClose}
        title="Booking Feedback"
        wide
      >
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <StyledRow field="Field Name" description="Description" />
            <StyledRow
              field="Comment"
              description={
                booking.sessionFeedback.comments
                  ? booking.sessionFeedback.comments
                  : '-'
              }
            />
            <StyledRow
              field="Document"
              description={
                <>
                  {booking.sessionFeedback.documents.map(
                    (data: { [key: string]: any }, index) => (
                      <a key={index} href={data.url}>
                        {data.name ? data.name : '-'} <br />
                      </a>
                    ),
                  )}
                </>
              }
            />
          </div>

          <div className="col-md-10 col-sm-10 d-flex justify-content-end mt-4">
            <Button
              onClick={onClose}
              style={{ width: '125px' }}
              color="success"
            >
              OK
            </Button>
          </div>
        </div>
      </ModalWrapper>
    )
  }

  if (bookingState?.pageType === 'rating') {
    return (
      <ModalWrapper
        open={open}
        handleClose={onClose}
        title="Booking Rating"
        wide
      >
        <div className="modal-content">
          <div className="row mb-4">
            <div className="col-sm-3">Feedback</div>
            <div className="col-sm-9">
              {booking.tutorRatings?.feedback
                ? booking.tutorRatings.feedback
                : '-'}
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-3">Rating</div>
            <div className="col-sm-9">
              <Rating
                name="read-only"
                size="large"
                value={parseInt(state.rating)}
                readOnly
                style={{ fontSize: '41px' }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <Button
              onClick={onClose}
              style={{ width: '125px' }}
              color="success"
            >
              OK
            </Button>
          </div>
        </div>
      </ModalWrapper>
    )
  }

  return null
}

export default BookingDetailModal
