import { FC, useState } from 'react'

import CardDeleteModal from '../../../../../../components/modals/card-delete-modal/CardDeleteModal'
import PaymentCard from '../../../../../../components/payment-card/PaymentCard'
import { Card as CardType } from '../../../../../../types/card.type'
import { api } from '../../../../../../utils'
import './existing-card.scss'

interface CardProps {
  card: CardType
  setMode: React.Dispatch<React.SetStateAction<string>>
  setSelectedCard: React.Dispatch<React.SetStateAction<string>>
  parentId: string
  getCardDetails: () => Promise<void>
}

const ExistingCard: FC<CardProps> = ({
  card,
  setMode,
  parentId,
  getCardDetails,
  setSelectedCard,
}) => {
  const [deleteCard, setDeleteCard] = useState(false)

  const setDefault = async (id: string) => {
    const res = await api.put(`admin/parent/${parentId}/setDefaultCard/${id}`)
    if (res.ok) {
      getCardDetails()
    }
  }

  const onEdit = () => {
    setMode?.('edit')
    setSelectedCard?.(card._id)
  }
  const onDelete = () => {
    setDeleteCard(true)
  }

  return (
    <>
      <PaymentCard
        card={card}
        mode="showCard"
        setDefault={setDefault}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      {deleteCard && (
        <CardDeleteModal
          open={deleteCard}
          setOpen={setDeleteCard}
          parentId={parentId}
          cardId={card._id}
          getCardDetails={getCardDetails}
        />
      )}
    </>
  )
}

export default ExistingCard
