import { useState, useEffect } from 'react'

import { Tutor } from '../../types/tutor.type'

interface GetSingleTutorProps {
  tutorData?: Tutor[] | null | undefined
  tutorId?: string
  dependant?: boolean
}

const useSingleTutor = ({
  tutorData,
  tutorId,
  dependant,
}: GetSingleTutorProps) => {
  const [singleTutor, setSingleParent] = useState<Tutor | null>()
  useEffect(() => {
    var tempArray = tutorData?.filter((tutor) => tutor._id === tutorId)
    setSingleParent(tempArray ? tempArray[0] : null)
  }, [dependant])
  return singleTutor
}

export default useSingleTutor
