import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaArrowLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import routes from '../../../../common/routes'
import ImageComponent from '../../../../components/image-component/ImageComponent'
import { SelectObjectVal } from '../../../../components/styled-react-select/StyledReactSelect'
import { useAppContext } from '../../../../contexts/AppContext'
import { GetCheckSessions } from '../../../../types/checkSessions.type'
import { api, dataURLtoBlob } from '../../../../utils'
import { CONSTANT } from '../../../../utils/constants'
import { AddressState } from '../../../parent/components/edit-parent/components/address-form/AddressForm'
import AdjustImage from '../../../parent/components/edit-parent/components/adjust-image/AdjustImage'
import SelectImage from '../../../parent/components/edit-parent/components/select-image/SelectImage'
import {
  Certificate,
  EducationalDegree,
  LocationDetails,
  Resume,
  SingleTutorResponse,
  Tutor,
} from './../../../../types/tutor.type'
import BackgroundCheck from './components/bg-check/BackgroundCheck'
import BuildProfile from './components/build-profile/BuildProfile'
import CheckUpcomingSessions from './components/check-upcoming-sessions/CheckUpcomingSessions'
import DisableMySylvanModal from './components/disable-mysylvan-modal/DisableMySylvanModal'
import EditAdditionalInfo from './components/edit-additional-info/EditAdditionalInfo'
import EditTutorContactInfo from './components/edit-contact-info/EditTutorContactInfo'
import EduNProfessionalBg from './components/edu-n-professional-bg/EduNProfessionalBg'
import PartnershipCode from './components/partnership-code/PartnershipCode'
import {
  DataPartnershipCodesData,
  PartnershipCodesRes,
} from './components/partnership-code/partnership-code.type'
import PoliciesAndPreference from './components/policies-and-preference/PoliciesAndPreference'
import TutoringMethodError from './components/tutoring-method-error/TutoringMethodError'
import ZeroDistanceError from './components/zero-distance-error/ZeroDistanceError'
import './edit-tutor.scss'
import {
  CancellationPolicyData,
  CancellationPolicyRes,
} from './edit-tutor.type'

export interface EditTutorData {
  firstName?: string
  lastName?: string
  email?: string
  userName?: string
  phoneNumber?: string
  experience?: string
  tutorResume?: Resume
  tutorCertificate?: Certificate
  stateCertified?: boolean
  linkedInProfile?: string
  educationalDegrees?: EducationalDegree[]
  ownsVehicle?: boolean
  contactable?: boolean
  transportationMode?: string
  ownsIpad?: boolean
  willUseIpad?: number
  sylvanCertification?: number
  locationDetails?: LocationDetails
  profileHeadLine?: string
  aboutMe?: string
  acceptingNewFamilies?: boolean
  isTutorOfferOnlineClasses?: boolean
  isAvailableOnMySylvanMarketplace?: boolean
  onlineSessionLink?: string
  hourlyRate?: number
  cancellationPolicy?: string
  maxDistance?: number
  middleName?: string
  tutorFirstName?: string
  disableMiddleName?: boolean
  tutorLastName?: string
  signature?: string
  dob?: Dayjs | null
  utm_source?: string
  utm_content?: string
  deletePartnershipCode?: string
  partnershipCodeId: string[]
  gradeLevel?: SelectObjectVal[]
  subjectName?: SelectObjectVal[]
  subSubject?: SelectObjectVal[]
  oldMarket?: string
  newMarket?: string
  isShownInOtherMarkets?: boolean
  isOfferInPersonTutoring?: boolean
}

const defaultValues: EditTutorData = {
  firstName: '',
  lastName: '',
  email: '',
  userName: '',
  phoneNumber: '',
  experience: '',
  educationalDegrees: [],
  contactable: false,
  transportationMode: '',
  profileHeadLine: '',
  aboutMe: '',
  acceptingNewFamilies: false,
  isTutorOfferOnlineClasses: false,
  isAvailableOnMySylvanMarketplace: false,
  onlineSessionLink: '',
  isShownInOtherMarkets: false,
  cancellationPolicy: '',
  middleName: '',
  tutorFirstName: '',
  disableMiddleName: false,
  tutorLastName: '',
  signature: '',
  dob: null,
  utm_source: '',
  utm_content: '',
  deletePartnershipCode: '',
  partnershipCodeId: [],
  gradeLevel: [],
  subjectName: [],
  subSubject: [],
}

export interface TutorStates {
  isMarketChange?: boolean
  notChanged?: boolean
  locationFlag?: boolean
  homeAddressObject?: string
  addressErrorFlag?: boolean
  newSelected?: boolean
}
const initialState = {
  isMarketChange: false,
  notChanged: false,
  locationFlag: false,
  homeAddressObject: '',
  addressErrorFlag: false,
  newSelected: true,
}

const EditTutor = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { updateAppState } = useAppContext()
  const isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true'

  const [singleTutor, setSingleTutor] = useState<Tutor | undefined>()
  const [cancellationPolicy, setCancellationPolicy] = useState<
    CancellationPolicyData | undefined
  >()
  const [codes, setCodes] = useState<DataPartnershipCodesData | null>(null)
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [certificateImage, setCertificateImage] = useState<File | null>(null)
  const [selectImageUrl, setSelectImageUrl] = useState('')
  const [profileModal, setProfileModal] = useState(false)
  const [imageBase64, setImageBase64] = useState('')
  const [showDisableWarning, setShowDisableWarning] = useState(false)
  const [highestDegreeFlag, setHighestDegreeFlag] = useState(-1)
  const [tutorState, setTutorState] = useState<TutorStates>(initialState)
  const [newAddress, setNewAddress] = useState<AddressState>()
  const [oldAddress, setOldAddress] = useState<LocationDetails>()
  const [resumeDoc, setResumeDoc] = useState<File | undefined>()
  const [checkUpcomingSessions, setCheckUpcomingSessions] = useState(false)
  const [keepSessions, setKeepSessions] = useState<boolean>()
  const [formValues, setFormValues] = useState<EditTutorData>()
  const [isValid, setIsValid] = useState<boolean>(true)
  const [isAvailableDisable, setIsAvailableDisable] = useState<boolean>(true)
  const [isDataSet, setIsDataSet] = useState(false)
  const [tutoringMethodError, setTutoringMethodError] = useState(false)
  const [zeroDistanceError, setZeroDistanceError] = useState(false)

  const methods = useForm<EditTutorData>({
    defaultValues,
    mode: 'all',
  })

  const values = methods.watch()
  useEffect(() => {
    const getSingleTutor = async () => {
      updateAppState({ loading: true })
      const singleTutorReq = api.get<SingleTutorResponse>(
        'booking/getTutorById',
        {
          tutorId: localStorage.getItem('tutorId') as string,
        },
      )
      const cancellationPolicyReq = api.get<CancellationPolicyRes>(
        '/admin/getCancellationPolices',
      )
      const codesReq = api.get<PartnershipCodesRes>(
        '/admin/getPartnershipCodes',
      )
      const [singleTutorRes, cancellationPolicyRes, codesRes] =
        await Promise.all([singleTutorReq, cancellationPolicyReq, codesReq])
      if (singleTutorRes.ok && singleTutorRes.data) {
        const { data } = singleTutorRes
        setSingleTutor(data.data)
      }
      if (cancellationPolicyRes.ok && cancellationPolicyRes.data) {
        const { data } = cancellationPolicyRes.data
        setCancellationPolicy(data)
      }
      if (codesRes.ok && codesRes.data) {
        setCodes(codesRes.data.data)
      }
      updateAppState({ loading: false })
    }
    getSingleTutor()
  }, [])

  useEffect(() => {
    if (isDataSet) return
    methods.setValue('firstName', singleTutor?.firstName)
    methods.setValue('lastName', singleTutor?.lastName)
    methods.setValue('email', singleTutor?.email)
    methods.setValue('userName', singleTutor?.userName)
    methods.setValue('middleName', singleTutor?.tutor.middleName)
    methods.setValue('phoneNumber', singleTutor?.phoneNumber)
    methods.setValue('experience', singleTutor?.tutor.experience)
    methods.setValue('tutorResume', singleTutor?.tutor.resume)
    methods.setValue('tutorCertificate', singleTutor?.tutor.certificate)
    methods.setValue('linkedInProfile', singleTutor?.tutor.linkedInProfile)
    methods.setValue('stateCertified', singleTutor?.tutor.isCertified)
    methods.setValue('signature', singleTutor?.tutor.signature)
    methods.setValue(
      'isShownInOtherMarkets',
      singleTutor?.tutor.notSeenInOtherMarket,
    )

    let educationalDegrees = singleTutor?.tutor.educationalDegrees
    if (!educationalDegrees?.length) {
      educationalDegrees = [
        {
          city: '',
          country: '',
          degree: '',
          intstitutionName: '',
          isHighestDegree: false,
          state: '',
          subject: '',
          year: '',
          _id: '',
        },
      ]
    }
    if (educationalDegrees) {
      if (educationalDegrees.length === 0) {
        setHighestDegreeFlag(0)
      } else {
        educationalDegrees.forEach((degree, index) => {
          if (highestDegreeFlag == -1 && degree.isHighestDegree == true) {
            setHighestDegreeFlag(index)
          }
        })
      }
    }
    methods.setValue('educationalDegrees', educationalDegrees)
    methods.setValue('ownsVehicle', singleTutor?.tutor.ownsVehicle)
    methods.setValue('contactable', singleTutor?.tutor.contactable)
    methods.setValue(
      'transportationMode',
      singleTutor?.tutor.transportationMode,
    )
    methods.setValue(
      'sylvanCertification',
      singleTutor?.tutor.sylvanCertification,
    )
    methods.setValue('ownsIpad', singleTutor?.tutor.ownsIpad)
    methods.setValue('willUseIpad', singleTutor?.tutor?.willingToUseIpad)
    methods.setValue('profileHeadLine', singleTutor?.tutor.profileHeadLine)
    methods.setValue('aboutMe', singleTutor?.tutor.aboutMe)
    methods.setValue(
      'acceptingNewFamilies',
      singleTutor?.tutor.acceptingNewFamilies,
    )
    methods.setValue(
      'isTutorOfferOnlineClasses',
      singleTutor?.tutor.isTutorOfferOnlineClasses,
    )
    methods.setValue(
      'isAvailableOnMySylvanMarketplace',
      singleTutor?.tutor.isAvailableOnMySylvanMarketplace,
    )
    methods.setValue('onlineSessionLink', singleTutor?.tutor.onlineSessionLink)
    methods.setValue('hourlyRate', singleTutor?.tutor.hourlyRate)
    if (singleTutor?.tutor.maxDistance && singleTutor?.tutor.maxDistance > 0)
      methods.setValue('maxDistance', singleTutor?.tutor.maxDistance)
    methods.setValue('tutorFirstName', singleTutor?.tutor.firstName)
    methods.setValue(
      'disableMiddleName',
      singleTutor?.tutor && !singleTutor.tutor.middleName,
    )
    methods.setValue('tutorLastName', singleTutor?.tutor.lastName)
    if (singleTutor?.tutor.dob) {
      let dob: any = new Date(singleTutor?.tutor.dob)
      dob = new Date(dob.getUTCFullYear(), dob.getUTCMonth(), dob.getUTCDate())
      dob = dayjs(dob)
      methods.setValue('dob', dob)
    }
    if (
      singleTutor?.userSource?.utm_source ||
      singleTutor?.userSource?.utm_source != null
    )
      methods.setValue('utm_source', singleTutor?.userSource.utm_source)
    if (
      singleTutor?.userSource?.utm_content ||
      singleTutor?.userSource?.utm_content != null
    )
      methods.setValue('utm_content', singleTutor?.userSource.utm_content)

    methods.setValue(
      'gradeLevel',
      singleTutor?.tutor.gradeLevel.map((val) => ({
        id: val,
        value: val,
        label: val,
      })),
    )

    methods.setValue(
      'subjectName',
      singleTutor?.tutor.subjects.map((s) => ({
        id: s._id,
        value: s._id,
        label: s.name,
      })),
    )
    const subCategories = singleTutor?.tutor.subjects
      .map(
        (s) =>
          s.subcategories?.map((subCat) => ({
            id: subCat._id,
            value: subCat._id,
            label: subCat.name,
          })) || [],
      )
      .flat()

    methods.setValue('subSubject', subCategories)
    methods.setValue(
      'isOfferInPersonTutoring',
      singleTutor?.tutor?.isOfferInPersonTutoring,
    )

    methods.setValue('oldMarket', singleTutor?.marketId?.marketName)
    methods.setValue('newMarket', 'Nationwide')

    const homeAddress = singleTutor?.tutor.locationDetails
    setOldAddress(homeAddress)

    const address =
      homeAddress?.addressLine1 +
      (homeAddress?.addressLine2 ? ', ' + homeAddress?.addressLine2 : '') +
      (homeAddress?.city ? ', ' + homeAddress.city : '') +
      (homeAddress?.state ? ', ' + homeAddress.state : '') +
      (homeAddress?.zipCode ? ', ' + homeAddress.zipCode : '')
    if (address !== 'undefined') {
      setTutorState({
        ...tutorState,
        ['addressErrorFlag']: true,
      })
    }
    methods.setValue('locationDetails', singleTutor?.tutor.locationDetails)
    setTutorState({
      ...tutorState,
      ['homeAddressObject']: address !== 'undefined' ? address : '',
    })
    if (singleTutor) {
      setIsDataSet(true)
      checkIsDisable(true, singleTutor.tutor.isTutorOfferOnlineClasses)
    }
  }, [singleTutor, isDataSet])

  useEffect(() => {
    if (cancellationPolicy && singleTutor) {
      cancellationPolicy.policies.forEach((policy) => {
        if (policy.name === singleTutor.tutor.cancellationPolicy?.name) {
          methods.setValue('cancellationPolicy', policy._id)
        }
      })
    }
  }, [cancellationPolicy, singleTutor])

  const subjectName = methods.watch('subjectName')
  const subSubject = methods.watch('subSubject')
  const gradeLevel = methods.watch('gradeLevel')
  const cancellationPolicyValue = methods.watch('cancellationPolicy')

  const isFormValid = () => {
    let degrees = true
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      experience,
      gradeLevel,
      subjectName,
      subSubject,
      profileHeadLine,
      educationalDegrees,
      aboutMe,
      hourlyRate,
      cancellationPolicy,
      maxDistance,
      dob,
    } = values

    educationalDegrees?.every((deg) => {
      if (
        !deg.city ||
        !deg.country ||
        !deg.degree ||
        !deg.intstitutionName ||
        !deg.state ||
        !deg.subject ||
        !deg.year
      ) {
        degrees = false
      }
    })
    let higherDegree

    higherDegree =
      educationalDegrees &&
      educationalDegrees.some((deg) => {
        return deg.isHighestDegree === true
      })
    if (singleTutor?.tutor.profileStep === 7) {
      const address = !tutorState.addressErrorFlag ? oldAddress : newAddress
      if (tutorState.addressErrorFlag && address) {
        address.addressLine1 = ''
      }

      if (
        !firstName ||
        !lastName ||
        !email ||
        !CONSTANT.EMAIL_REGEXP.test(email as string) ||
        !higherDegree ||
        !degrees ||
        !address?.addressLine1 ||
        !phoneNumber ||
        !experience ||
        !gradeLevel?.length ||
        !subjectName?.length ||
        !subSubject?.length ||
        !profileHeadLine ||
        !aboutMe ||
        !hourlyRate ||
        !cancellationPolicy ||
        (values.isOfferInPersonTutoring &&
          (maxDistance == null || maxDistance == undefined)) ||
        !dob
      ) {
        setIsValid(false)
      } else setIsValid(true)
    } else {
      if (
        (!CONSTANT.EMAIL_REGEXP.test(email as string) && email) ||
        (phoneNumber && phoneNumber.length < 10)
      ) {
        setIsValid(false)
      } else setIsValid(true)
    }
  }
  useEffect(() => {
    isFormValid()
  }, [values])

  useEffect(() => {
    let address = {
      city: oldAddress?.city,
      state: oldAddress?.state,
      zipCode: oldAddress?.zipCode,
      addressLine1: oldAddress?.addressLine1,
      latitude: oldAddress?.location.coordinates[1],
      longitude: oldAddress?.location.coordinates[0],
    }

    if (newAddress) {
      const {
        country,
        label,
        addressLine2,
        locality,
        stateCode,
        ...restAddress
      } = newAddress

      setTutorState({
        ...tutorState,
        ['notChanged']: Object.keys(restAddress).every(
          // @ts-ignore
          (key) => restAddress[key] !== address[key],
        ),
      })
    }
  }, [newAddress, oldAddress])

  const cancellationPolicyOptions =
    cancellationPolicy?.policies.map((policy) => ({
      id: parseInt(policy._id),
      option: policy.name,
      value: policy._id,
    })) ?? []

  const checkIsDisable = (check: boolean, isOfferOnlineTutoring: boolean) => {
    let isAvailableOnMySylvanMarketplaceDisable = true
    if (singleTutor) {
      isAvailableOnMySylvanMarketplaceDisable = true
      if (singleTutor.approvedAt) {
        if (singleTutor.isBlocked || singleTutor.isDeleted) {
          methods.setValue('isAvailableOnMySylvanMarketplace', false)
        } else {
          if (check) {
            if (
              values.isAvailableOnMySylvanMarketplace ||
              isOfferOnlineTutoring
            ) {
              isAvailableOnMySylvanMarketplaceDisable = false
            }
          } else {
            if (isOfferOnlineTutoring) {
              isAvailableOnMySylvanMarketplaceDisable = false
            } else {
              methods.setValue('isAvailableOnMySylvanMarketplace', false)
              isAvailableOnMySylvanMarketplaceDisable = true
            }
          }
        }
      } else {
        methods.setValue('isAvailableOnMySylvanMarketplace', false)
      }
    }

    setIsAvailableDisable(isAvailableOnMySylvanMarketplaceDisable)
  }

  const handleSubmit = methods.handleSubmit(async (data) => {
    setFormValues(data)
    if (data.deletePartnershipCode) {
      const res = await api.get<GetCheckSessions>(
        '/admin/checkTutorUpcomingSessionForPartnerCode',
        {
          tutorId: localStorage.getItem('tutorId'),
          partnershipCodeId: data.deletePartnershipCode,
        },
      )
      if (res && res.data) {
        const { isUpcomingSession } = res.data.data
        setCheckUpcomingSessions(isUpcomingSession)
        if (!isUpcomingSession) editTutor(data)
      }
    } else {
      editTutor(data)
    }
  })
  const editTutor = async (data: EditTutorData) => {
    if (
      !data.isTutorOfferOnlineClasses &&
      data.isAvailableOnMySylvanMarketplace
    ) {
      setShowDisableWarning(true)
      return
    }

    if (!data.isTutorOfferOnlineClasses && !data.isOfferInPersonTutoring) {
      setTutoringMethodError(true)
      return
    }
    if (data.maxDistance === 0) {
      setZeroDistanceError(true)
      return
    }
    const formData = new FormData()
    const address = !tutorState.addressErrorFlag ? oldAddress : newAddress
    if (tutorState.addressErrorFlag && address) {
      address.addressLine1 = ''
    }

    if (
      singleTutor?.tutor.profileStep === 7 &&
      highestDegreeFlag < 0 &&
      data.educationalDegrees &&
      data?.educationalDegrees.length > 0
    ) {
      setHighestDegreeFlag(-2)

      const radioButton = document.getElementsByClassName('radioText')[0]
      const y = radioButton.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: y - 200,
        behavior: 'smooth',
      })
    } else if (
      !tutorState.newSelected &&
      singleTutor?.tutor.profileStep === 7
    ) {
      setTutorState({ ...tutorState, ['locationFlag']: true })
      const homeAddress = document.getElementsByClassName('homeAddress')[0]
      const y = homeAddress.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: y - 200,
        behavior: 'smooth',
      })
    } else {
      if (newAddress)
        formData.append(
          'isMarketChange',
          tutorState.isMarketChange ? 'true' : 'false',
        )

      if (data.firstName) formData.append('firstName', data.firstName)
      if (data.lastName) formData.append('lastName', data.lastName)
      if (data.email) formData.append('email', data.email)
      if (data.phoneNumber) formData.append('phoneNumber', data.phoneNumber)
      if (data.profileHeadLine != undefined)
        formData.append('profileHeadLine', data.profileHeadLine)
      if (data.aboutMe != undefined) formData.append('aboutMe', data.aboutMe)
      if (data.onlineSessionLink != undefined)
        formData.append('onlineSessionLink', data.onlineSessionLink)
      if (data.maxDistance != undefined)
        // @ts-ignore
        formData.append('maxDistance', data.maxDistance as string)
      if (data.stateCertified != undefined) {
        // @ts-ignore
        formData.append('isCertified', data.stateCertified)
      }
      if (resumeDoc) formData.append('resume', resumeDoc)
      if (certificateImage) formData.append('certificate', certificateImage)

      if (cancellationPolicyValue)
        formData.append('cancellationPolicy', cancellationPolicyValue)

      if (data.partnershipCodeId.length > 0) {
        formData.append(
          'partnershipCodeId',
          JSON.stringify(data.partnershipCodeId),
        )
      }
      if (data.hourlyRate)
        formData.append('hourlyRate', data?.hourlyRate.toString())

      if (data.dob) {
        let date = new Date(data.dob.toString())

        formData.append('dob', date.toDateString())
      }

      if (data.acceptingNewFamilies != null)
        formData.append(
          'acceptingNewFamilies',
          data.acceptingNewFamilies.toString(),
        )
      if (data.tutorFirstName)
        formData.append('tutorFirstName', data.tutorFirstName)
      if (data.tutorLastName)
        formData.append('tutorLastName', data.tutorLastName)
      if (data.middleName) formData.append('tutorMiddleName', data.middleName)
      if (data.isTutorOfferOnlineClasses != null)
        formData.append(
          'isTutorOfferOnlineClasses',
          //@ts-ignore
          data.isTutorOfferOnlineClasses,
        )
      if (data.isOfferInPersonTutoring != null)
        formData.append(
          'isOfferInPersonTutoring',
          //@ts-ignore
          data.isOfferInPersonTutoring,
        )

      if (data.signature) formData.append('signature', data.signature)

      if (data.gradeLevel) {
        const tempGrade = gradeLevel?.map((grade) => grade.id)

        formData.append('gradeLevel', JSON.stringify(tempGrade))
      }

      const tempSub = subjectName?.map((sub) => sub.id)
      formData.append('subjects', JSON.stringify(tempSub))

      const tempSubSubject = subSubject?.map((sub) => sub.id)
      formData.append('subSubjects', JSON.stringify(tempSubSubject))
      formData.append('businessModel', 'PER_SESSION_FEE')

      if (data.experience) formData.append('experience', data.experience)

      if (data.linkedInProfile != undefined)
        formData.append('linkedInProfile', data.linkedInProfile)
      if (
        singleTutor?.tutor.isAvailableOnMySylvanMarketplace !=
        data.isAvailableOnMySylvanMarketplace
      ) {
        formData.append(
          'isAvailableOnMySylvanMarketplace',
          data.isAvailableOnMySylvanMarketplace ? 'true' : 'false',
        )
      }
      if (data.contactable != null)
        formData.append('contactable', data.contactable.toString())
      if (data.sylvanCertification != null)
        formData.append(
          'sylvanCertification',
          data.sylvanCertification.toString(),
        )
      if (data.ownsIpad != undefined)
        formData.append('ownsIpad', data.ownsIpad.toString())
      if (data.willUseIpad != undefined)
        formData.append('willingToUseIpad', data.willUseIpad.toString())
      if (data.ownsVehicle != undefined)
        formData.append('ownsVehicle', data.ownsVehicle.toString())

      if (data.ownsVehicle === false && data.transportationMode) {
        formData.append('transportationMode', data.transportationMode)
      } else {
        formData.append('publicCommunityLocations', false + '')
      }

      if (imageBase64)
        formData.append('profilePicture', dataURLtoBlob(imageBase64))

      if (newAddress && tutorState.notChanged) {
        let tempActive = {
          latitude: newAddress.latitude,
          longitude: newAddress.longitude,
          addressLine1: newAddress.addressLine1,
          addressLine2: newAddress.addressLine2,
          city: newAddress.city,
          zipCode: newAddress.zipCode,
          state: newAddress.state,
        }
        formData.append('locationDetails', JSON.stringify(tempActive))
      }
      if (data.deletePartnershipCode) {
        formData.append('deletePartnershipCode', data.deletePartnershipCode)
        if (keepSessions === false)
          formData.append('deleteExistingSessions', 'true')
      }

      let temp = data.educationalDegrees

      if (
        data &&
        data.educationalDegrees?.length &&
        data.educationalDegrees?.length > 1
      ) {
        temp = data.educationalDegrees?.filter((obj) => {
          const values = Object.values(obj)
          return values.some((value) => value)
        })
      }

      let educationalDegree: any[] = []
      if (temp)
        temp.forEach((element: any) => {
          let educationalDegrees = {
            degree: element.degree,
            intstitutionName: element.intstitutionName,
            isHighestDegree: element.isHighestDegree,
            city: element.city,
            subject: element.subject,
            year: element.year.toString(),
            state: element.state,
            country: element.country,
          }
          educationalDegree.push(educationalDegrees)
        })
      if (educationalDegree.length)
        formData.append('educationalDegrees', JSON.stringify(educationalDegree))

      if (!data.utm_source) {
        formData.append('utm_source', '')
      } else {
        formData.append('utm_source', data.utm_source as string)
      }

      if (!data.utm_content) {
        formData.append('utm_content', '')
      } else {
        formData.append('utm_content', data.utm_content)
      }

      if (isSuperAdmin) {
        if (data.isShownInOtherMarkets) {
          // @ts-ignore
          formData.append('notSeenInOtherMarket', data.isShownInOtherMarkets)
        } else {
          formData.append('notSeenInOtherMarket', 'false')
        }
      }

      const editTutorRes = await api.put(
        `/admin/editProfileTutor/${localStorage.getItem('tutorId')}`,
        formData,
      )
      if (editTutorRes.ok) {
        navigate('/pages/' + routes.allTutors)
      }
    }
  }

  return (
    <div className="edit-tutor container-fluid">
      <FaArrowLeft
        size={20}
        color="#0275d8"
        className="cursor-pointer mt-3 ms-3"
        onClick={() => {
          navigate(-1)
        }}
      />
      {!!singleTutor && (
        <div className="edit-tutor-body">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-center mt-3 mb-3">
                <ImageComponent
                  onClick={() => setProfileModal(true)}
                  profilePic={singleTutor?.profilePicture}
                  tempUrl={selectImageUrl}
                />
              </div>
              <>
                <EditTutorContactInfo
                  {...{
                    singleTutor,
                    setNewAddress,
                    tutorState,
                    setTutorState,
                    newAddress,
                  }}
                />
                <EduNProfessionalBg
                  {...{
                    highestDegreeFlag,
                    setHighestDegreeFlag,
                    setResumeDoc,
                    singleTutor,
                    setCertificateImage,
                  }}
                />
                <PoliciesAndPreference />
                <BuildProfile
                  {...{
                    cancellationPolicyOptions,
                    singleTutor,
                    isAvailableDisable,
                  }}
                  checkDisable={(check, isOfferOnlineTutoring) => {
                    checkIsDisable(check, isOfferOnlineTutoring)
                  }}
                />
                <BackgroundCheck {...{ singleTutor }} />
                <EditAdditionalInfo tutor={singleTutor} />
                <PartnershipCode {...{ codes, singleTutor }} />
              </>
              <div className="text-end">
                <Button
                  type="submit"
                  color={!isValid ? 'info' : 'success'}
                  className="edit-tutor__save-button"
                  disabled={!isValid}
                >
                  Save
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      )}
      {profileModal && (
        <SelectImage
          open={profileModal}
          setOpen={setProfileModal}
          setSelectedImage={setSelectedImage}
        />
      )}
      {!!selectedImage && (
        <AdjustImage
          open={!!selectedImage}
          setOpen={setSelectedImage}
          selectedImage={selectedImage}
          setSelectImageUrl={setSelectImageUrl}
          setImageBase64={setImageBase64}
        />
      )}
      {showDisableWarning && (
        <DisableMySylvanModal
          open={showDisableWarning}
          onClose={() => setShowDisableWarning(false)}
        />
      )}
      {checkUpcomingSessions && (
        <CheckUpcomingSessions
          open={checkUpcomingSessions}
          handleClose={() => {
            setCheckUpcomingSessions(false)
          }}
          PartnershipCode="code"
          isKeepSession={(isKeep) => {
            setKeepSessions(isKeep)
            setCheckUpcomingSessions(false)
            editTutor(formValues as EditTutorData)
          }}
        />
      )}
      {tutoringMethodError && (
        <TutoringMethodError
          open={tutoringMethodError}
          handleClose={() => {
            setTutoringMethodError(false)
          }}
        />
      )}
      {zeroDistanceError && (
        <ZeroDistanceError
          open={zeroDistanceError}
          handleClose={() => {
            setZeroDistanceError(false)
          }}
        />
      )}
    </div>
  )
}

export default EditTutor
