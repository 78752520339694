import dayjs from 'dayjs'

export const getDaysInMonth = (month: any, year: any) => {
  let hms = new Date()
  let date = new Date(
    year,
    month,
    1,
    hms.getHours(),
    hms.getMinutes(),
    hms.getSeconds(),
    0,
  )
  let days = []
  while (date.getMonth() === month) {
    days.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return days
}

//Convert millisecond to hour , min , second
export const parseMillisecondsIntoReadableTime = (milliseconds: any) => {
  //Get hours from milliseconds
  let hours = milliseconds / (1000 * 60 * 60)
  let absoluteHours = Math.floor(hours)
  let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours

  //Get remainder from hours and convert to minutes
  let minutes = (hours - absoluteHours) * 60
  let absoluteMinutes = Math.floor(minutes)
  let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes

  //Get remainder from minutes and convert to seconds
  let seconds = (minutes - absoluteMinutes) * 60
  let absoluteSeconds = Math.floor(seconds)
  let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds

  return h + ':' + m
}

export const getDaysInWeek = (date: any) => {
  let days = [],
    curr
  if (date == 1) {
    curr = new Date()
  } else {
    curr = new Date(date)
  }
  let first = curr.getDate() - curr.getDay()
  let firstday = new Date(curr.setDate(first))
  days.push(firstday)
  for (let i = 1; i < 7; i++) {
    let next = new Date(curr.getTime())
    next.setDate(next.getDate() + i)
    days.push(next)
  }
  return days
}

export const getTimeStops = () => {
  let start = '00:00'
  let end = '23:59'
  let startTime = dayjs(start, 'HH:mm')
  let endTime = dayjs(end, 'HH:mm')

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day')
  }

  let timeStops = []

  while (startTime.isBefore(endTime) || startTime.isSame(endTime)) {
    timeStops.push(dayjs(startTime).format('HH:mm'))
    startTime = startTime.add(30, 'minutes')
  }
  return timeStops
}

export const convertTime = (time: any) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ]

  if (time.length > 1) {
    time = time.slice(1)
    time[5] = +time[0] < 12 ? 'AM' : 'PM'
    time[0] = +time[0] % 12 || 12
  }
  return time.join('')
}

export const createDummySlots = () => {
  let dummyslotArray: any = []
  let timeDataArray = getTimeStops()
  timeDataArray.forEach((value) => {
    let obj = {
      startTime: value,
      endTime: '',
      _id: '',
      status: '',
    }
    dummyslotArray.push(obj)
  })
  return dummyslotArray
}

export const calendarConst: any = {
  fullMonth: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  sortMonth: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  fullDay: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  sortDay: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
}
