import ModalWrapper from '@/components/modal-wrapper/ModalWrapper'
import { useAppContext } from '@/contexts/AppContext'
import { api } from '@/utils'
import { Button } from '@mui/material'
import _ from 'lodash'
import { FC } from 'react'

import { Parent } from '../../../../types/parent.type'
import './out-of-hours-modal.scss'

interface OutOfHoursModalProps {
  open: boolean
  onClose: () => void
  parentId?: string
  setSingleParent: React.Dispatch<React.SetStateAction<Parent | null>>
  onRefetchData?: () => void
}

const OutOfHoursModal: FC<OutOfHoursModalProps> = ({
  open,
  onClose,
  parentId,
  setSingleParent,
  onRefetchData,
}) => {
  const { updateAppState } = useAppContext()

  const handleSubmit = async (shouldMark: boolean) => {
    if (shouldMark) {
      const form = new FormData()
      form.append('isParentOutOfTutoringHours', 'true')
      updateAppState({ loading: true })
      const res = await api.put<{ message: string; status: number; data: any }>(
        `/admin/editProfileParent/${parentId}`,
        form,
      )
      if (res.ok && res.data?.data) {
        const data = res.data.data
        setSingleParent((prev) => {
          const newParent = _.cloneDeep(prev)
          if (newParent) {
            newParent.parent.isParentOutOfTutoringHours = true
            newParent.parent.adminAdditionalNotes =
              data.parent.adminAdditionalNotes
          }
          return newParent
        })
        onRefetchData?.()
      }
      onClose()
      updateAppState({ loading: false })
    } else {
      onClose()
    }
  }

  return (
    <ModalWrapper {...{ open }} title="Confirmation" wide handleClose={onClose}>
      <div className="out-of-hours-modal">
        <p className="blueText">
          Flagging this profile as ‘Out of Hours’ for the year means the parent
          can continue by adding a credit card to which future session charges
          will be applied at the tutor’s default partnership hourly rate. Do you
          wish to continue?
        </p>
        <div className="d-flex justify-content-center">
          <Button
            color="success"
            className="modal-button"
            onClick={() => handleSubmit(false)}
          >
            No, don’t mark as out of hours
          </Button>
          <Button
            color="success"
            className="modal-button"
            onClick={() => handleSubmit(true)}
          >
            Yes, parent is out of hours
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default OutOfHoursModal
