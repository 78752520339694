export const CONSTANT = {
  PROJECT_NAME: 'mySylvan Marketplace+',
  headerLogo: 'assets/logo/header_image_new.png',
  footerLogo: 'assets/logo/footer_image_new.png',
  namePattern: /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+[\'-‘’]?)+$/,
  newNamePattern: /^[a-zA-Z-' ]{1,64}$/,
  // namePattern: /^([A-Za-z]+[^0-9-](?:[,.])?(?:\s)?|[A-Za-z]+[\'-])+$/,
  apiKeyName: /^[a-zA-Z ]*$/,
  EMAIL_REGEXP:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  numberRegex: /^\d+$/,
  numberOnly: /^[0-9()\-\s]+$/,

  // /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/
  //   regex(/^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-‘]?)+$/)
  states: [
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arizona', 'AZ'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['District of Columbia', 'DC'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
  ],

  sessionStatuses: [
    'Pending',
    'Pending Request from Tutor',
    'Accepted',
    'Reschedule Request',
    'Reschedule Request By Tutor',
    'Session Started',
    'Finished',
    'Completed',
    'Canceled',
    'Canceled by Tutor',
    'Declined',
    'Declined by Tutor',
    'Dispute Raised',
  ],
}
