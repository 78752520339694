import { Button, Pagination, SelectChangeEvent } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import CustomDatePicker from '../../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../../contexts/AppContext'
import { useDebounce } from '../../../hooks'
import { useAllSessions } from '../hooks/useAllSessions'
import './all-sessions.scss'
import SessionsTable from './components/sessions-table/SessionsTable'
import { FilterButtonTypes, filterButtons } from './data'

export interface ParamType {
  status: FilterButtonTypes
  marketId: string
  page: number
  search: string
  sortBy: string
  startDate: Dayjs | null
  endDate: Dayjs | null
}

const paramsInit: ParamType = {
  status: 'ALL',
  marketId: '',
  page: 1,
  endDate: null,
  startDate: null,
  sortBy: '',
  search: '',
}

function AllSessions() {
  const { marketData, downloadCsv } = useAppContext()
  const [search, setSearch] = useState('')
  const oldFilter: ParamType = JSON.parse(
    localStorage.getItem('SessionFilters') as string,
  )
  const [filterStates, setFilterStates] = useState<ParamType>({
    ...paramsInit,
    status: oldFilter?.status ? oldFilter.status : paramsInit.status,
  })
  const prevSearchRef = useRef('')

  const debouncedSearch = useDebounce({
    value: search,
    milliSeconds: 500,
  })

  if (prevSearchRef.current !== debouncedSearch) {
    setFilterStates((filters) => ({ ...filters, page: 1 }))
  }
  prevSearchRef.current = debouncedSearch

  const { allSessions, totalPages, getAllSessions } = useAllSessions({
    debouncedSearch,
    filterStates,
  })

  const onDateChange = (value: Dayjs | null | undefined, mode?: string) => {
    setFilterStates({ ...filterStates, [mode as string]: value, page: 1 })
  }

  const marketChange = (event: SelectChangeEvent) => {
    setFilterStates({ ...filterStates, marketId: event.target.value, page: 1 })
  }

  return (
    <div className="main">
      <div className="container-fluid content ps-0">
        <div className="mt-4">
          <Button
            className="download-csv-button"
            onClick={() => {
              downloadCsv('session')
            }}
          >
            Download CSV
          </Button>
        </div>
      </div>
      <div className="filter-buttons mt-4">
        {filterButtons.map((button) => {
          return (
            <Button
              key={button.type}
              variant={
                button.type === filterStates.status ? 'contained' : 'outlined'
              }
              className="blue-button"
              onClick={() => {
                setFilterStates({
                  ...filterStates,
                  status: button.type,
                  page: 1,
                })
              }}
            >
              {button.title}
            </Button>
          )
        })}
      </div>
      <div className="row mt-4 w-100">
        <div className="col-md-3 col-lg-2 col-xl-3 mt-2">
          <CustomDatePicker
            date={filterStates.startDate}
            maxDate={filterStates.endDate}
            onChange={onDateChange}
            placeholder="Select Start Date"
            mode="startDate"
            onClear={() => onDateChange(null, 'startDate')}
          />
        </div>
        <div className="col-md-3 col-lg-2 col-xl-3 mt-2">
          <CustomDatePicker
            date={filterStates.endDate}
            minDate={filterStates.startDate}
            onChange={onDateChange}
            placeholder="Select End Date"
            mode="endDate"
            onClear={() => onDateChange(null, 'endDate')}
          />
        </div>

        <div className="col-md-4 col-lg-3 mt-2 col-xl-3">
          <MarketDropdown
            option={marketData}
            value={filterStates.marketId}
            handleChange={marketChange}
            placeholder="--Select Market Name--"
          />
        </div>
        <div className="col-md-3 col-xl-2 offset-lg-2 offset-xl-1 mt-2 d-flex justify-content-end pe-0">
          <SearchBar
            placeholder="Search..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </div>
      </div>
      <div className="mt-4">
        <SessionsTable
          sessionsData={allSessions}
          buttonType={filterStates.status}
          {...{ getAllSessions }}
        />
      </div>
      {allSessions && allSessions.length > 0 ? (
        <div className="mt-2 d-flex justify-content-end">
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={filterStates.page}
              onChange={(_event, value) =>
                setFilterStates({ ...filterStates, page: value })
              }
              color="primary"
            />
          )}
        </div>
      ) : (
        <div className="mt-2 d-flex justify-content-start">
          <p>No booking found</p>
        </div>
      )}
    </div>
  )
}

export default AllSessions
