import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import { api } from '../../../../utils'
import { PartnerCode, PartnershipRes } from './AllPartnershipCode.type'
import AddEditPartnershipCode from './components/add-edit-partnership-code/AddEditPartnershipCode'
import PartnershipCodeTable from './components/partnership-code-table/PartnershipCodeTable'

const AllPartnershipCodes = () => {
  const [addPartnershipCode, setAddPartnershipCode] = useState(false)
  const [partnerShipCodes, setPartnerShipCodes] = useState<PartnerCode[]>([])
  const [isSuppressChargesAlready, setIsSuppressChargesAlready] =
    useState<number>()
  const getPartnershipCodes = async () => {
    const result = await api.get<PartnershipRes>('admin/getPartnershipCodes', {
      getAll: true,
    })
    if (result.ok && result.data) {
      const { partnerCodes, isRateChargeSuppressExistsAllready } =
        result.data.data
      setPartnerShipCodes(partnerCodes)
      setIsSuppressChargesAlready(isRateChargeSuppressExistsAllready)
    }
  }
  useEffect(() => {
    getPartnershipCodes()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setAddPartnershipCode(true)
            }}
          >
            Add Partnership Code
          </Button>
        </div>
      </div>
      <div className="row">
        <PartnershipCodeTable
          partnershipCodes={partnerShipCodes}
          isSuppressChargesAlready={isSuppressChargesAlready}
          getPartnershipCodes={getPartnershipCodes}
        />
      </div>
      {addPartnershipCode && (
        <AddEditPartnershipCode
          isSuppressChargesAlready={isSuppressChargesAlready}
          mode="Add"
          open={addPartnershipCode}
          onClose={() => {
            setAddPartnershipCode(false)
          }}
          onSuccess={getPartnershipCodes}
        />
      )}
    </div>
  )
}

export default AllPartnershipCodes
