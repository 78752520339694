import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import Logo from '../../../../assets/logo/footer_image_new.png'
import ResetPasswordMessage from '../../../../components/modals/reset-password-message/ResetPasswordMessage'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { api } from '../../../../utils/api'
import { SignInResponse } from '../../signin.type'
import './signin-card.scss'

interface FormValues {
  email: string
  password: string
  remember: boolean
}

const schema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[^a-zA-Z])(?=.*[a-z])(?=.*[A-Z])\S{8,}$/,
      'Password is invalid',
    )
    .min(8),
  remember: Yup.boolean().optional().label('Remember me'),
}).required()

const SiginCard = () => {
  const { updateAppState } = useAppContext()
  const navigate = useNavigate()
  const [forgotPassword, setForgotPassword] = useState(false)
  const [remember, setRemember] = useState(false)
  const defaultValues: FormValues = {
    email: '',
    password: '',
    remember: false,
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  })

  useEffect(() => {
    const email = localStorage.getItem('email')
    if (email) {
      setValue('email', email)
      setValue('remember', true)
      setRemember(true)
    }
  }, [])

  const onSubmit = async (values: FormValues) => {
    const { email, password } = values

    updateAppState({ loading: true })
    if (remember) {
      localStorage.setItem('email', email)
    } else {
      localStorage.removeItem('email')
    }
    const res = await api.post<SignInResponse>('/admin/login', {
      email,
      password,
    })
    const { data, status } = res
    if (status === 200) {
      if (data?.data) {
        const currentUser = data.data
        const { isSuperAdmin, isMarketplaceManager, accessToken } = currentUser
        localStorage.setItem('isSuperAdmin', isSuperAdmin ? 'true' : 'false')
        localStorage.setItem(
          'isMarketplaceManager',
          isMarketplaceManager ? 'true' : 'false',
        )
        localStorage.setItem('token', accessToken)
        localStorage.setItem('tokenSession', accessToken)
        updateAppState({ currentUser, isLoggedIn: true })
        navigate('/pages/dashboard')
      }
    }
    updateAppState({ loading: false })
  }

  const handleForgotPassword = async () => {
    setForgotPassword(true)
    updateAppState({ loading: true })
    const res = await api.put('/admin/forgotPassword', {
      email: 'admin1@sylvan.com',
    })
    updateAppState({ loading: false })
  }

  return (
    <div className="auth-block login-auth-block">
      <span className="app-logo">
        <img src={Logo} className="app-logo-image" />
      </span>
      <Card className="d-flex align-items-center justify-content-center signin-card">
        <CardContent className="p-0">
          <h1 className="auth-heading mb-4 text-center">
            <span>Welcome! Please Sign In</span>
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row pb-2 pt-2 d-flex align-items-baseline">
              <div className="col-sm-3">
                <label className="label">Email</label>
              </div>
              <div className="col-sm-9">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <StyledTextfield
                      {...field}
                      placeholder="Email"
                      isError={!!errors.email}
                      errorMessage={errors.email?.message?.toString()}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row pb-2 pt-2 d-flex align-items-baseline">
              <div className="col-sm-3">
                <label className="label">Password</label>
              </div>
              <div className="col-sm-9">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <StyledTextfield
                      placeholder="Password"
                      type="password"
                      {...field}
                      isError={!!errors.password}
                      errorMessage={errors.password?.message?.toString()}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="offset-sm-3 col-sm-9 d-flex align-items-center justify-content-between">
                <FormControlLabel
                  className="checkbox-label"
                  label="Remember Me"
                  control={
                    <Checkbox
                      checked={remember}
                      onChange={(e) => setRemember(e.target.checked)}
                      size="small"
                    />
                  }
                />
                <div
                  role="button"
                  onClick={handleForgotPassword}
                  className="forgotpassword"
                >
                  Forgot Password?
                </div>
              </div>
            </div>
            <div className="row">
              <div className="offset-sm-3 col-sm-9 d-flex align-items-center justify-content-between">
                <Button
                  className="w-100"
                  disabled={!isValid}
                  type="submit"
                  color={!isValid ? 'info' : 'success'}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
      <ResetPasswordMessage open={forgotPassword} setOpen={setForgotPassword} />
    </div>
  )
}

export default SiginCard
