import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { PartnerCode } from '../../AllPartnershipCode.type'
import { partnerShipCode } from '../data'
import PartnershipCodeTableBody from './PartnershipCodeTableBody'
import './partnership-code.scss'

interface PartnershipCodeTableI {
  partnershipCodes: PartnerCode[]
  getPartnershipCodes: () => Promise<void>
  isSuppressChargesAlready?: number
}

const PartnershipCodeTable: FC<PartnershipCodeTableI> = ({
  getPartnershipCodes,
  partnershipCodes,
  isSuppressChargesAlready,
}) => {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{ '&:hover': { background: 'none' } }}
              className="table-align-center"
            >
              {partnerShipCode.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!partnershipCodes?.length &&
              partnershipCodes.map((partnershipCode) => {
                return (
                  <PartnershipCodeTableBody
                    key={partnershipCode._id}
                    getPartnershipCodes={getPartnershipCodes}
                    partnershipCode={partnershipCode}
                    isSuppressChargesAlready={isSuppressChargesAlready}
                  />
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PartnershipCodeTable
