import { Button } from '@mui/material'
import {
  GoogleMap,
  LoadScriptProps,
  Polygon,
  useJsApiLoader,
} from '@react-google-maps/api'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppContext } from '../../contexts/AppContext'
import { api } from '../../utils'
import AddNewAreaModal from './components/add-new-area-modal/AddNewAreaModal'
import GeoFencingTable from './components/geo-fencing-table/GeoFencingTable'
import './geo-fencing.scss'
import {
  AreasCountResponse,
  AreasResponse,
  DatumWithType,
} from './geo-fencing.type'

const LIBRARIES: LoadScriptProps['libraries'] = ['drawing']

function GeoFencing() {
  const [areas, setAreas] = useState<DatumWithType[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  const { updateAppState } = useAppContext()
  const [isAddAreaModalOpen, setIsAddAreaModalOpen] = useState(false)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAnjAy4DscTX_L1AWbofjMUZuM050DLjcw',
    libraries: LIBRARIES,
  })

  const getAreas = useCallback(async () => {
    updateAppState({ loading: true })
    const areasReq = api.get<AreasResponse>('/admin/getAreas', {
      limit: 10,
      skip: (page - 1) * 10,
    })
    const areasCountReq = api.get<AreasCountResponse>('/admin/getAreasCount')
    const [areasRes, areasCountRes] = await Promise.all([
      areasReq,
      areasCountReq,
    ])
    if (areasRes.ok) {
      let areas = areasRes.data?.data
      if (areas) {
        areas = areas.map((area) => {
          const type = area?.areas[0]?.zipCode ? 'zipCode' : 'map'
          return { ...area, type }
        })
        setAreas(areas as DatumWithType[])
      }
    }
    if (areasCountRes.ok) {
      if (areasCountRes.data) {
        const count = areasCountRes.data.data.count
        const pages = Math.ceil(count / 10)
        setTotalPages(pages)
      }
    }
    updateAppState({ loading: false })
  }, [page])

  useEffect(() => {
    getAreas()
  }, [getAreas])

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPage(page)
  }

  type mapPathsType = {
    lng: number
    lat: number
  }[][]

  const mapPaths = useMemo<mapPathsType>(() => {
    const mapPaths: mapPathsType = []

    areas.forEach((area) => {
      area.areas.forEach((a) => {
        a.area.coordinates.forEach((coords) => {
          coords.forEach((coord) => {
            const path = coord.map(([lng, lat]) => ({ lng, lat }))
            mapPaths.push(path)
          })
        })
      })
    })

    return mapPaths
  }, [areas])

  return (
    <div className="geofencing">
      <div className="geofencing__top-bar">
        <h2 className="geofencing__heading">Geofencing</h2>
        <Button onClick={() => setIsAddAreaModalOpen(true)}>
          Add New Area
        </Button>
      </div>
      <div className="geofencing__map">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{ height: '500px' }}
            center={{ lat: 42.432608, lng: -77.133209 }}
            zoom={3}
            options={{
              streetViewControl: true,
              mapTypeControl: true,
            }}
          >
            {mapPaths.map((paths, index) => (
              <Polygon
                key={index}
                paths={paths}
                options={{
                  strokeColor: '#db1825',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: '#db1825',
                  fillOpacity: 0.35,
                }}
              />
            ))}
          </GoogleMap>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className="geofencing__separator" />
      <p className="geofencing__market-message">
        The Nationwide market is not represented on this list because there are
        no market boundaries to maintain.
      </p>

      <GeoFencingTable
        {...{ areas, totalPages, page, getAreas }}
        onPageChange={handlePageChange}
      />

      <AddNewAreaModal
        key={+isAddAreaModalOpen}
        open={isAddAreaModalOpen}
        onClose={() => setIsAddAreaModalOpen(false)}
        onAdd={() => {
          setPage(1)
          getAreas()
        }}
      />
    </div>
  )
}

export default GeoFencing
