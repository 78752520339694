import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import { ConstantResponse, Reason } from '../../../../types/constants.type'
import { api } from '../../../../utils'
import AddEditRejectReason from './components/add-edit-reject-reason/AddEditRejectReason'
import RejectReasonTable from './components/reject-reason-table/RejectReasonTable'

const AllRejects = () => {
  const [allRejects, setAllRejects] = useState<Reason[]>([])
  const [addRejectReason, setAddRejectReason] = useState(false)
  const getConstants = async () => {
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      const { rejectReasons } = constantsRes.data.data
      setAllRejects(rejectReasons)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])
  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setAddRejectReason(true)
            }}
          >
            Add Reject Reason
          </Button>
        </div>
      </div>

      <div className="row">
        {!!allRejects.length && (
          <RejectReasonTable reasons={allRejects} getConstants={getConstants} />
        )}
      </div>
      {addRejectReason && (
        <AddEditRejectReason
          open={addRejectReason}
          onClose={() => {
            setAddRejectReason(false)
          }}
          mode="Add"
          onSuccess={getConstants}
        />
      )}
    </div>
  )
}

export default AllRejects
