import React, { forwardRef } from 'react'

import './styled-textfield.scss'

interface TextfieldProps {
  placeholder?: string
  name?: string
  value?: string
  onChange?:
    | ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
    | undefined
  className?: string | undefined
  isError?: boolean
  errorMessage?: string
  minRows?: number
}
const StyledTextArea = forwardRef<HTMLTextAreaElement, TextfieldProps>(
  (
    {
      onChange,
      placeholder,
      value,
      name,
      className,
      isError,
      minRows,
      errorMessage,
    },
    ref,
  ) => {
    const inputClass = isError ? 'styled-textfield-error' : ''

    return (
      <div className={`d-flex flex-column align-items-baseline ${className} `}>
        <textarea
          className={`${inputClass} w-100 p-2`}
          placeholder={placeholder}
          name={name}
          rows={minRows}
          ref={ref}
          value={value}
          onChange={(e) => {
            onChange?.(e)
          }}
        />
        {!!(isError && errorMessage) ? (
          <span className="helper-text mt-1 d-flex justify-content-end w-100">
            {errorMessage}
          </span>
        ) : (
          ''
        )}
      </div>
    )
  },
)

export default StyledTextArea
