import { useAppContext } from '@/contexts/AppContext'
import { api } from '@/utils'
import { Button, Pagination } from '@mui/material'
import { useEffect, useState } from 'react'

import RejectedCanceledSessionTable from './components/RejectedCanceledSessionTable'
import { PaginatedBooking, RejectedCanceledRes } from './rejected-canceled.type'

const PAGE_LIMIT = 10
const RejectedCanceledSession = () => {
  const { downloadCsv } = useAppContext()
  const [totalPages, setTotalPages] = useState(0)
  const [rejectedCanceledSessions, setRejectedCanceledSessions] = useState<
    PaginatedBooking[]
  >([])
  const [page, setPage] = useState(1)

  const getRejectedCanceledSession = async () => {
    const skip = (page - 1) * PAGE_LIMIT
    let obj = { limit: PAGE_LIMIT, skip }
    const res = await api.get<RejectedCanceledRes>(
      'admin/rejectedCanceledSessions',
      obj,
    )
    if (res.data) {
      const { paginatedBookings, totalBookings } = res.data.data.bookings
      setRejectedCanceledSessions(paginatedBookings)
      setTotalPages(Math.ceil(totalBookings / PAGE_LIMIT))
    }
  }
  useEffect(() => {
    getRejectedCanceledSession()
  }, [page])

  return (
    <div className="main">
      <div className="container-fluid content ps-0">
        <div className="mt-4">
          <Button
            className="download-csv-button"
            disabled={!rejectedCanceledSessions.length}
            onClick={() => {
              downloadCsv('rejectedCanceledSessions')
            }}
          >
            Download CSV
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <RejectedCanceledSessionTable
          rejectedSessions={rejectedCanceledSessions}
        />
      </div>
      {rejectedCanceledSessions && rejectedCanceledSessions.length > 0 ? (
        <div className="mt-2 d-flex justify-content-end">
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(_event, value) => setPage(value)}
              color="primary"
            />
          )}
        </div>
      ) : (
        <div className="mt-2 d-flex justify-content-start">
          <p>No booking found</p>
        </div>
      )}
    </div>
  )
}

export default RejectedCanceledSession
