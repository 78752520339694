import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-calendar/dist/Calendar.css'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'

import './app.scss'
import AppRouter from './components/app-router/AppRouter'
import { AppContextProvider } from './contexts/AppContext'
import './theme/fonts.scss'
import { STRIPE_PUBLISH_KEY } from './utils'

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY)

function App() {
  return (
    <AppContextProvider>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <div className="main">
            <Toaster position="bottom-left" />
            <AppRouter />
          </div>
        </BrowserRouter>
      </Elements>
    </AppContextProvider>
  )
}

export default App
