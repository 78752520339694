import { ReactElement } from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { FaGlobeAsia, FaRegMoneyBillAlt } from 'react-icons/fa'
import { HiServerStack } from 'react-icons/hi2'
import { IoMdSettings } from 'react-icons/io'
import { IoChatbubblesOutline, IoHome } from 'react-icons/io5'

import routes from '../../common/routes'

const size = 16.5

export interface IListItem {
  id: number
  icon: ReactElement
  text: string
  route?: string
  subIcons: { id: number; text: string; route?: string }[]
  pathName?: string
}

const conditionallyRendered = (condition: boolean, object: any) => {
  return condition ? [object] : []
}
export const getListItems = (condition: boolean): IListItem[] => {
  return [
    {
      id: 0,
      icon: <IoHome size={size} />,
      text: 'Dashboard',
      pathName: 'Dashboard',
      route: routes.dashboard,
      subIcons: [],
    },

    {
      id: 1,
      icon: <AiOutlineUser size={size} />,
      text: 'Parent',
      pathName: 'Parent',
      subIcons: [
        { id: 0, text: 'Add Parent', route: routes.addParent },
        { id: 1, text: 'All Parents', route: routes.allParents },
      ],
    },

    {
      id: 2,
      icon: <AiOutlineUser size={size} />,
      text: 'Tutors',
      pathName: 'Tutors',
      subIcons: [
        { id: 0, text: 'Add Tutor', route: routes.addTutor },
        { id: 1, text: 'All Tutors', route: routes.allTutors },
        {
          id: 2,
          text: 'Pending Profile Updates',
          route: routes.pendingProfile,
        },
      ],
    },

    {
      id: 3,
      icon: <FaRegMoneyBillAlt size={size} />,
      text: 'Session',
      pathName: 'Session',
      subIcons: [
        { id: 0, text: 'Create Session', route: routes.createSession },
        { id: 1, text: 'All Sessions', route: routes.allSessions },
      ],
    },

    {
      id: 4,
      icon: <FaGlobeAsia size={size} />,
      pathName: 'geofencing',
      text: 'Geofencing',
      route: '/pages/geofencing',
      subIcons: [],
    },

    {
      id: 5,
      icon: <FaRegMoneyBillAlt size={size} />,
      text: 'Category/Commission',
      pathName: 'category',
      route: routes.allCategories,
      subIcons: [],
    },

    {
      id: 6,
      icon: <HiServerStack size={size} />,
      text: 'Promo Management',
      pathName: 'promo-management',
      route: routes.promoManagement,
      subIcons: [],
    },

    {
      id: 7,
      icon: <HiServerStack size={size} />,
      text: 'Notification',
      route: routes.allNotifications,
      subIcons: [],
    },

    {
      id: 8,
      icon: <FaGlobeAsia size={size} />,
      text: 'CMS',
      pathName: 'CMS',
      subIcons: [
        { id: 0, text: 'FAQ', route: routes.faq },
        { id: 1, text: 'Background Check', route: routes.bgCheck },
        { id: 2, text: 'Contact Info', route: routes.contactInfo },
        { id: 3, text: 'CMS-Login', route: routes.cmsLogin },
        {
          id: 4,
          text: 'Tutor Resources',
          route: routes.tutorResources,
        },
        {
          id: 5,
          text: 'Parent Resources',
          route: routes.parentResources,
        },
      ],
    },

    {
      id: 9,
      icon: <IoChatbubblesOutline size={size} />,
      text: 'Disputes',
      pathName: 'disputes',
      route: routes.disputes,
      subIcons: [],
    },

    {
      id: 11,
      icon: <HiServerStack size={size} />,
      text: 'Reports',
      pathName: 'reports',
      subIcons: [
        { id: 0, text: 'Session Data', route: routes.sessionData },
        { id: 1, text: 'First Session', route: routes.firstSession },
        {
          id: 2,
          text: 'Rejected and Canceled Sessions',
          route: routes.rejectedCanceledSession,
        },
        {
          id: 3,
          text: 'Returning Customers',
          route: routes.returningCustomers,
        },
        {
          id: 4,
          text: "Tutor's Last Availability",
          route: routes.tutorLastAvailability,
        },
        {
          id: 5,
          text: 'Student - Tutor Feedback',
          route: routes.studentTutorFeedback,
        },
      ],
    },

    {
      id: 12,
      icon: <IoChatbubblesOutline size={size} />,
      text: 'Contacts',
      pathName: 'contact',
      route: routes.contacts,
      subIcons: [],
    },

    {
      id: 13,
      icon: <IoChatbubblesOutline size={size} />,
      text: 'Feedbacks',
      pathName: 'feedbacks',
      route: routes.feedbacks,
      subIcons: [],
    },
    ...conditionallyRendered(condition, {
      id: 14,
      icon: <IoMdSettings size={size} />,
      text: 'Admin Settings',
      pathName: 'admin-settings',
      subIcons: [
        { id: 0, text: 'Admin Users', route: routes.adminUsers },
        { id: 1, text: 'API Keys', route: routes.apiKeys },
      ],
    }),
  ]
}
