import { Checkbox } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import CustomDatePicker from '../../../../../../components/date-picker/CustomDatePicker'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { Tutor } from '../../../../../../types/tutor.type'
import { CONSTANT } from '../../../../../../utils/constants'

interface BackgroundCheckProps {
  singleTutor: Tutor
}

const BackgroundCheck: FC<BackgroundCheckProps> = ({ singleTutor }) => {
  const { control, setValue, watch } = useFormContext()

  const disableMiddleName = watch('disableMiddleName')
  const Dob = watch('dob')
  const isMiddleName = watch('disableMiddleName')

  const errorMessage = (value: string, name: string) => {
    if (isMiddleName && name === 'Middle') return
    if (!value && singleTutor?.tutor.profileStep === 7)
      return `${name} Name is required.`

    if (
      name.toLowerCase() !== 'signature' &&
      !CONSTANT.namePattern.test(value) &&
      singleTutor?.tutor.profileStep === 7
    )
      return `${name} name is not valid.`
    if (value.length < 2 && singleTutor?.tutor.profileStep === 7)
      return `${name} name minimum length is 2`
  }

  const errorCheck = (value: string) => {
    return (
      (!value || value.length < 2 || !CONSTANT.namePattern.test(value)) &&
      singleTutor?.tutor.profileStep === 7
    )
  }

  return (
    <div>
      <h5 className="heading-container">Background Check</h5>
      <div className="row">
        <div className="form-group col-sm-6">
          <div className="row p-0">
            <div className="col-sm-12">
              <label htmlFor="five1" className="pad0">
                First Name
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="tutorFirstName"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="First Name"
                    required={singleTutor?.tutor.profileStep === 7}
                    isError={errorCheck(field.value)}
                    errorMessage={errorMessage(field.value, 'First')}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-sm-6 ">
          <div className="row p-0">
            <div className="col-sm-12">
              <label htmlFor="five2" className="pad0">
                Middle Name
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="middleName"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    disabled={disableMiddleName}
                    placeholder="Middle name"
                    required={singleTutor?.tutor.profileStep === 7}
                    isError={errorCheck(field.value)}
                    errorMessage={errorMessage(field.value, 'Middle')}
                  />
                )}
              />
              <div className="d-flex align-items-center">
                <Controller
                  control={control}
                  name="disableMiddleName"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        if (e.target.checked) {
                          setValue('middleName', '')
                        }
                      }}
                    />
                  )}
                />
                <p className="lightText m-0">
                  I confirm I do not have a middle name.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-sm-6">
          <div className="row p-0">
            <div className="col-sm-12">
              <label htmlFor="five3" className="pad0">
                Last Name
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="tutorLastName"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Last Name"
                    required={singleTutor?.tutor.profileStep === 7}
                    isError={errorCheck(field.value)}
                    errorMessage={errorMessage(field.value, 'Last')}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group col-sm-6">
          <div className="row p-0">
            <div className="col-sm-12">
              <label htmlFor="five4" className="pad0">
                Signature
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="signature"
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Signature"
                    required={singleTutor?.tutor.profileStep === 7}
                    isError={errorCheck(field.value)}
                    errorMessage={errorMessage(field.value, 'Signature')}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-sm-6 col-xs-12 ">
          <div className="row p-0">
            <div className="col-sm-12">
              <label htmlFor="DOB" className="pad0">
                Date of Birth
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10">
              <Controller
                control={control}
                name="dob"
                render={({ field }) => (
                  <CustomDatePicker
                    date={field.value}
                    isDob
                    onChange={(date) => {
                      setValue('dob', date as Dayjs)
                    }}
                    placeholder="mm-dd-yyyy"
                    minDate={dayjs(new Date(1941, 1, 1))}
                    onClear={() => setValue('dob', null)}
                  />
                )}
              />
              {singleTutor?.tutor.profileStep === 7 && !Dob ? (
                <span className="helper-text mt-1 d-flex justify-content-end col-sm-10 m-1 p-0">
                  DOB is Required
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BackgroundCheck
