import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Checkbox, IconButton } from '@mui/material'
import {
  DrawingManager,
  GoogleMap,
  LoadScriptProps,
  Polygon,
  useJsApiLoader,
} from '@react-google-maps/api'
import _ from 'lodash'
import { FC, useEffect, useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { GrFormClose } from 'react-icons/gr'
import { Col, Row } from 'reactstrap'
import * as Yup from 'yup'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { GeneralObj } from '../../../../types/global.type'
import { api, checkNumbersOnly } from '../../../../utils'
import { DatumWithType } from '../../geo-fencing.type'
import { GetZipCodeRes } from '../add-new-area-modal/zip-code.type'
import './edit-region-modal.scss'

interface EditRegionModalIProps {
  open: boolean
  onClose: () => void
  onEdit: () => void
  area: DatumWithType
}

interface FormValues {
  marketId: string
  marketName: string
  isAcceptingParents: boolean
  zipCode: { value: string }[]
}

const LIBRARIES: LoadScriptProps['libraries'] = ['drawing']

interface DefaultState {
  latLngArray: {
    zipCode?: string
    id: string
    coordinates: number[][][][]
  }[]
  type: string
  allZipCode: any[]
  coordinatesArrayMap: any[]
  center: GeneralObj | undefined
  paths1: any[]
  deletedIDs: any[]
  addNewZip: any[]
  currentRecord: string
  previousValue: string
  isRemoved: boolean
  zipName: string
  isEdited: boolean
  isAdded: boolean
  newZipName: string
}

const defaultState: DefaultState = {
  latLngArray: [],
  type: '',
  allZipCode: [],
  coordinatesArrayMap: [],
  center: undefined,
  paths1: [],
  deletedIDs: [],
  addNewZip: [],
  currentRecord: '',
  previousValue: '',
  isRemoved: false,
  zipName: '',
  isEdited: true,
  isAdded: false,
  newZipName: '',
}

const EditRegionModal: FC<EditRegionModalIProps> = ({
  open,
  onClose,
  onEdit,
  area,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAnjAy4DscTX_L1AWbofjMUZuM050DLjcw',
    libraries: LIBRARIES,
  })
  const polygonRef = useRef<google.maps.Polygon>()
  const listenerRef = useRef<google.maps.MapsEventListener[]>([])
  const [state, setState] = useState(defaultState)
  const { updateAppState } = useAppContext()

  const updateState = (newState: Partial<DefaultState>) => {
    setState((prev) => ({ ...prev, ...newState }))
  }

  useEffect(() => {
    const newState = _.cloneDeep(state)
    let coordinates: any[] = []
    area.areas.forEach((element) => {
      newState.type = element.zipCode ? 'Zip' : 'Map'
      newState.allZipCode.push(element.zipCode)
      element.area.coordinates.forEach((element2) => {
        element2.forEach((element4) => {
          coordinates.push([element4])
          newState.latLngArray.push({
            zipCode: element.zipCode,
            id: element._id,
            coordinates: coordinates,
          })
          coordinates = []
          element4.forEach((element5) => {
            newState.coordinatesArrayMap.push({
              lng: element5[0],
              lat: element5[1],
            })
            newState.center = {
              lng: element5[0] + (newState.type === 'Map' ? 15 : 0),
              lat: element5[1],
            }
          })
        })
        newState.paths1.push(newState.coordinatesArrayMap)
        newState.coordinatesArrayMap = []
      })
    })
    setState(newState)
  }, [area])

  const zipCode = Yup.array()
    .of(Yup.object({ value: Yup.string().required('Zip code is required.') }))
    .min(1)
    .label('Zip Code')

  let schema = Yup.object({
    marketId: Yup.string().required('Market id is required.'),
    marketName: Yup.string()
      .matches(/^[a-zA-Z0-9-_ ]*$/, {
        message: 'Market name is invalid.',
      })
      .required('Market name is required.'),
    isAcceptingParents: Yup.bool()
      .oneOf([true, false])
      .label('Is Accepting Parents'),
  })

  if (state.type === 'Zip') {
    schema = schema.shape({
      zipCode,
    })
  }

  const defaultValues: FormValues = {
    marketId: area.marketId,
    marketName: area.marketName,
    isAcceptingParents: area.hasOwnProperty('isNotAcceptingParents')
      ? !area.isNotAcceptingParents
      : true,
    zipCode: area.areas.map((code) => ({
      value: code.zipCode as string,
    })),
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'zipCode',
  })

  const onSubmit = async (values: FormValues) => {
    const { marketId, marketName, isAcceptingParents, zipCode } = values
    const isNotAcceptingParent = !isAcceptingParents
    const data = new FormData()
    data.append('marketId', marketId)
    data.append('marketName', marketName)
    data.append('isNotAcceptingParents', isNotAcceptingParent + '')
    data.append(
      'areasToUpdate',
      JSON.stringify(state.type === 'Map' ? state.latLngArray : []),
    )
    if (state.deletedIDs.length) {
      data.append('areasToDelete', JSON.stringify(state.deletedIDs))
    }
    if (state.addNewZip.length) {
      data.append('areasToAdd', JSON.stringify(state.addNewZip))
      if (state.addNewZip[0].coordinates.length) {
        updateAppState({ loading: true })
        const res = await api.put(`/admin/updateAreas/${area._id}`, data)
        if (res.ok && res.data) {
          onClose()
          onEdit()
        }
        updateAppState({ loading: false })
      }
    } else {
      updateAppState({ loading: true })
      const res = await api.put(`/admin/updateAreas/${area._id}`, data)
      if (res.ok && res.data) {
        onClose()
        onEdit()
      }
      updateAppState({ loading: false })
    }
  }

  const removeZipCode = (index: number) => {
    const newState = _.cloneDeep(state)
    const data = watch('zipCode').map((obj) => obj.value)
    if (
      data &&
      data.length !== 0 &&
      data[index] != null &&
      data[index].length === 5
    ) {
      newState.isRemoved = true
      newState.zipName = data[index]
      removeZip(newState)
    }
    setState(newState)
    remove(index)
  }

  const addZipCode = () => {
    append({ value: '' })
  }

  const computePaths = (polyArray: google.maps.LatLng[]) => {
    const newState = _.cloneDeep(state)
    const paths = polyArray.map((path) => [path.lng(), path.lat()])
    paths.push(paths[0])
    const pathsToSet = [[paths]]
    newState.paths1 = [paths.map(([lng, lat]) => ({ lng, lat }))]
    newState.latLngArray[0].coordinates = pathsToSet
    updateState(newState)
  }

  const onPolygonComplete = (poly: google.maps.Polygon) => {
    let polyArray = poly.getPath().getArray()
    computePaths(polyArray)
    poly.setMap(null)
  }

  const handlePolygonEdit = () => {
    if (polygonRef.current) {
      const polyArray = polygonRef.current.getPath().getArray()
      computePaths(polyArray)
    }
  }

  const handlePolygonLoad = (polygon: google.maps.Polygon) => {
    polygonRef.current = polygon
    const path = polygon.getPath()
    listenerRef.current.push(
      path.addListener('set_at', handlePolygonEdit),
      path.addListener('insert_at', handlePolygonEdit),
      path.addListener('remove_at', handlePolygonEdit),
    )
  }

  const handlePolygonUnmount = (_polygon: google.maps.Polygon) => {
    listenerRef.current.forEach((lis) => lis.remove())
    polygonRef.current = undefined
  }

  const removeZip = (newState: DefaultState) => {
    let newList: any = []
    let count1 = 0
    let coordinatesArrayMap: any[] = []
    let elementId = ''

    newState.latLngArray.forEach((element1) => {
      count1++
      if (element1.zipCode === newState.zipName) {
        elementId = element1.id

        newState.latLngArray.slice(0, count1 - 1).forEach((element) => {
          newList.push(element)
        })

        newState.latLngArray
          .slice(count1 + 1, newState.latLngArray.length)
          .forEach((element) => {
            newList.push(element)
          })

        newState.latLngArray = newList
        newList = []
        count1--
      }
    })

    if (elementId !== '') newState.deletedIDs.push(elementId)

    newState.paths1 = []

    newState.latLngArray.forEach((element1) => {
      element1.coordinates.forEach((element2) => {
        element2.forEach((element3) => {
          element3.forEach((element) => {
            coordinatesArrayMap.push({
              lng: element[0],
              lat: element[1],
            })
          })
          newState.paths1.push(coordinatesArrayMap)
          coordinatesArrayMap = []
        })
      })
    })

    count1 = 0

    newState.addNewZip.forEach((element1) => {
      count1++
      if (element1.zipCode === newState.zipName && element1.zipCode !== '') {
        newState.addNewZip.slice(0, count1 - 1).forEach((element) => {
          newList.push(element)
        })
        newState.addNewZip
          .slice(count1, newState.addNewZip.length)
          .forEach((element) => {
            newList.push(element)
          })
        newState.addNewZip = newList
        newList = []
        count1--
      }
    })

    newState.addNewZip.forEach((element1) => {
      element1.coordinates.forEach((element2: any) => {
        element2.forEach((element3: any) => {
          element3.forEach((element: any) => {
            coordinatesArrayMap.push({
              lng: element[0],
              lat: element[1],
            })
          })
          newState.paths1.push(coordinatesArrayMap)
          coordinatesArrayMap = []
        })
      })
    })
  }

  const getZipCodeCoordinate = async (
    newState: DefaultState,
    zipCode: string,
  ) => {
    const url = `https://vanitysoft-boundaries-io-v1.p.rapidapi.com/reaperfire/rest/v1/public/boundary`
    updateAppState({ loading: true })
    const zipRes = await api.get<GetZipCodeRes>(
      url,
      {
        zipcode: zipCode,
        and: false,
        combine: false,
        includepostal: false,
        limit: 30,
        showCenter: false,
        showDetails: false,
      },
      {
        headers: {
          'x-rapidapi-host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com',
          'x-rapidapi-key':
            'DCOzqtzQ8qmshcst78y3HCZuqik5p1F6uGgjsnc1FYAbstHXns',
        },
      },
    )
    if (zipRes.ok && zipRes.data) {
      const data = zipRes.data
      let latLng: any[] = []
      let covetBackendFormat1: any[] = []
      let tempLatLng: any[] = []

      data.features.forEach((feature) => {
        if (feature.geometry.type === 'Polygon') {
          feature.geometry.coordinates.forEach((element1) => {
            let coordinatesArrayMap: any = []
            let coordinatesArrayMap1: any = []
            element1.forEach((element3) => {
              coordinatesArrayMap.push({
                lng: element3[0],
                lat: element3[1],
              })
              coordinatesArrayMap1.push(element3[0])
              coordinatesArrayMap1.push(element3[1])
              latLng.push(coordinatesArrayMap1)
              coordinatesArrayMap1 = []
              newState.center = { lng: element3[0], lat: element3[1] }
            })
            covetBackendFormat1.push(latLng)
            tempLatLng.push(covetBackendFormat1)
            covetBackendFormat1 = []
            latLng = []
            newState.paths1.push(coordinatesArrayMap)
            coordinatesArrayMap = []
          })
        } else {
          feature.geometry.coordinates.forEach((element1) => {
            let coordinatesArrayMap: any[] = []
            let coordinatesArrayMap1: any[] = []
            element1.forEach((element3) => {
              element3.forEach((element2) => {
                coordinatesArrayMap.push({
                  lng: element2[0],
                  lat: element2[1],
                })
                coordinatesArrayMap1.push(element2[0])
                coordinatesArrayMap1.push(element2[1])
                latLng.push(coordinatesArrayMap1)
                coordinatesArrayMap1 = []
                newState.center = { lng: element2[0], lat: element2[1] }
              })
              covetBackendFormat1.push(latLng)
              tempLatLng.push(covetBackendFormat1)
              covetBackendFormat1 = []
              latLng = []
              newState.paths1.push(coordinatesArrayMap)
              coordinatesArrayMap = []
            })
          })
        }
      })
      newState.addNewZip.push({
        zipCode: newState.newZipName,
        coordinates: tempLatLng,
      })
      removeZip(newState)
      newState.isAdded = false
    }
    updateAppState({ loading: false })
  }

  const getZipCode = async (index: number) => {
    const data = watch('zipCode').map((obj) => obj.value)
    const newState = _.cloneDeep(state)

    if (
      data[index] &&
      data[index] != null &&
      data[index].length !== 5 &&
      newState.previousValue !== ''
    ) {
      newState.isRemoved = true
      newState.zipName = newState.previousValue
      removeZip(newState)
    }

    if (
      data[index] &&
      data[1] != null &&
      data[index].length === 5 &&
      newState.previousValue != null &&
      newState.previousValue.length === 5 &&
      newState.previousValue !== data[index]
    ) {
      newState.isRemoved = true
      newState.zipName = newState.previousValue
      removeZip(newState)
      newState.isEdited = false
      newState.isAdded = true

      let count = 0

      newState.latLngArray.forEach((element) => {
        if (element.zipCode === data[index]) count++
      })

      if (count < 1) {
        await getZipCodeCoordinate(newState, data[index])
      }
    }
    if (
      newState.currentRecord === index.toString() &&
      newState.previousValue === data[index]
    ) {
    } else if (
      data[index].length === 5 &&
      newState.previousValue !== data[index]
    ) {
      newState.currentRecord = data[index]
      newState.zipName = newState.previousValue
      newState.newZipName = data && data.length !== 0 ? data[index] : ''
      newState.isAdded = true
      newState.isEdited = false
      await getZipCodeCoordinate(newState, data[index])
    } else if (
      data[index].length !== 5 &&
      newState.previousValue !== data[index] &&
      newState.previousValue.length === 5
    ) {
      newState.zipName = newState.previousValue
      newState.isRemoved = true
      removeZip(newState)
    }
    updateState(newState)
  }

  const getZipCodeFocusIn = (index: number) => {
    const data = watch('zipCode').map((obj) => obj.value)
    updateState({ currentRecord: index.toString(), previousValue: data[index] })
  }

  const paths =
    state.latLngArray?.[0]?.coordinates?.map((arr) =>
      arr.map((arr2) => arr2.map(([lng, lat]) => ({ lng, lat }))),
    )?.[0] || []

  let isSubmitDisabled =
    !isValid || (state.type === 'Map' && !state.paths1.length)

  const clearArea = () => {
    updateState({ paths1: [] })
  }

  return (
    <ModalWrapper open={open} handleClose={onClose} wide title="Edit Region">
      <div className="edit-region-modal">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col sm={4} className="edit-region-modal__label">
              <label
                className="edit-region-modal__label-text"
                htmlFor="marketId"
              >
                Market Id
              </label>
            </Col>
            <Col sm={7}>
              <Controller
                name="marketId"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter market id."
                    onChange={(event) => {
                      const isPassed = checkNumbersOnly(event.target.value)
                      if (isPassed) field.onChange(event)
                      return isPassed
                    }}
                    max={5}
                    isError={!!errors.marketId}
                    errorMessage={errors.marketId?.message?.toString()}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} className="edit-region-modal__label">
              <label
                className="edit-region-modal__label-text"
                htmlFor="marketName"
              >
                Market Name
              </label>
            </Col>
            <Col sm={7}>
              <Controller
                name="marketName"
                control={control}
                render={({ field }) => (
                  <StyledTextfield
                    {...field}
                    placeholder="Enter market name."
                    isError={!!errors.marketName}
                    errorMessage={errors.marketName?.message?.toString()}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} className="edit-region-modal__label">
              <label
                className="edit-region-modal__label-text"
                htmlFor="isAcceptingParents"
              >
                Accepting Parents?
              </label>
            </Col>
            <Col sm={7}>
              <Controller
                name="isAcceptingParents"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    color="success"
                    size="medium"
                    {...field}
                    checked={field.value}
                  />
                )}
              />
            </Col>
          </Row>
          {state.type === 'Zip' && (
            <>
              {fields.map((field, index) => (
                <Row className="mb-3" key={field.id}>
                  <Col sm={4} className="edit-region-modal__label">
                    <label
                      className="edit-region-modal__label-text"
                      htmlFor={`zipCode.${index}.value`}
                    >
                      Zip Code
                    </label>
                  </Col>
                  <Col sm={7}>
                    <Controller
                      control={control}
                      name={`zipCode.${index}.value`}
                      render={({ field }) => (
                        <StyledTextfield
                          {...field}
                          max={5}
                          placeholder="Enter zip code"
                          onChange={(event) => {
                            const isPassed = checkNumbersOnly(
                              event.target.value,
                            )
                            if (isPassed) field.onChange(event)
                            return isPassed
                          }}
                          onBlur={() => {
                            getZipCode(index)
                            field.onBlur()
                          }}
                          onFocus={() => getZipCodeFocusIn(index)}
                          isError={!!errors.zipCode?.[index]}
                          errorMessage={errors.zipCode?.[
                            index
                          ]?.value?.message?.toString()}
                        />
                      )}
                    />
                  </Col>
                  <Col sm={1}>
                    {index !== 0 && (
                      <IconButton onClick={() => removeZipCode(index)}>
                        <GrFormClose size={25} />
                      </IconButton>
                    )}
                  </Col>
                </Row>
              ))}
              <Row className="mt-4 mb-5">
                <Col
                  sm={11}
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button type="button" onClick={() => addZipCode()}>
                    <AiOutlinePlus /> Add Zip Code
                  </Button>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col className="edit-region-modal__map">
              {isLoaded ? (
                <>
                  {state.type === 'Map' ? (
                    <GoogleMap
                      mapContainerStyle={{ height: '500px' }}
                      center={state.center as google.maps.LatLng}
                      zoom={4}
                      options={{
                        streetViewControl: true,
                        mapTypeControl: true,
                      }}
                    >
                      {state.paths1.length ? (
                        state.paths1.map((paths, index) => (
                          <Polygon
                            key={index}
                            paths={paths}
                            options={{
                              editable: true,
                              strokeColor: '#db1825',
                              strokeOpacity: 0.8,
                              strokeWeight: 2,
                              fillColor: '#db1825',
                              fillOpacity: 0.35,
                            }}
                            onLoad={handlePolygonLoad}
                            onUnmount={handlePolygonUnmount}
                            onMouseUp={handlePolygonEdit}
                            onDragEnd={handlePolygonEdit}
                          />
                        ))
                      ) : (
                        <DrawingManager
                          drawingMode={google.maps.drawing.OverlayType.POLYGON}
                          onPolygonComplete={onPolygonComplete}
                          options={{
                            drawingControl: true,
                            drawingControlOptions: {
                              drawingModes: [
                                google.maps.drawing.OverlayType.POLYGON,
                              ],
                            },
                            polygonOptions: {
                              fillColor: '#db1825',
                              fillOpacity: 0.7,
                              strokeWeight: 4,
                              strokeColor: '#db1825',
                              zIndex: 5,
                              strokeOpacity: 0.8,
                              editable: true,
                              draggable: true,
                            },
                          }}
                        />
                      )}
                    </GoogleMap>
                  ) : (
                    <GoogleMap
                      mapContainerStyle={{ height: '500px' }}
                      center={state.center as google.maps.LatLng}
                      zoom={10}
                      options={{
                        streetViewControl: true,
                        mapTypeControl: true,
                      }}
                    >
                      {state.paths1.map((paths, index) => (
                        <Polygon
                          key={index}
                          paths={paths}
                          options={{
                            strokeColor: '#db1825',
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            fillColor: '#db1825',
                            fillOpacity: 0.35,
                          }}
                        />
                      ))}
                    </GoogleMap>
                  )}
                </>
              ) : (
                <p>Loading...</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={11} className="d-flex justify-content-end mt-3">
              {state.type === 'Map' && (
                <Button
                  type="button"
                  disabled={!state.paths1.length}
                  onClick={clearArea}
                  className="px-5 mx-3"
                  color={!state.paths1.length ? 'info' : 'success'}
                >
                  Clear Area
                </Button>
              )}
              <Button
                type="submit"
                disabled={isSubmitDisabled}
                color={isSubmitDisabled ? 'info' : 'success'}
                className="px-5"
              >
                Save Area
              </Button>
            </Col>
          </Row>
        </form>
      </div>
      <DevTool control={control} />
    </ModalWrapper>
  )
}

export default EditRegionModal
