import { TableRow, TableCell, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa'

import ConfirmDelete from '../../../../../../components/confirm-delete/ConfirmDelete'
import { api } from '../../../../../../utils'
import { Fee, ID, SlidingFee } from '../../AllFeeTable.type'
import AddEditFee from '../add-edit-fee/AddEditFee'
import ConfirmEdit from '../confirm-edit/ConfirmEdit'
import './all-fee-table.scss'

const size = 15
interface AllFeeTableBodyProps {
  singleFee: Fee
  getAllFee: () => Promise<void>
}

export interface EditDataI {
  editFee: SlidingFee
  idData: ID
}

const checkDate = (date: Date) => {
  return new Date(date) > new Date()
}

const AllFeeTableBody: FC<AllFeeTableBodyProps> = ({
  singleFee,
  getAllFee,
}) => {
  const [deleteFee, setDeleteFee] = useState(false)
  const [editFee, setEditFee] = useState(false)
  const [confirmEditFee, setConfirmEditEditFee] = useState(false)
  const [selectedFee, setSelectedFee] = useState<SlidingFee>()
  const [editFeeData, setEditFeeData] = useState<EditDataI>()

  const deleteFeeFunc = async () => {
    const res = await api.delete(`admin/deleteSlidingFee/${selectedFee?._id}`)
    if (res && res.ok) {
      setDeleteFee(false)
      getAllFee()
    }
  }
  const dataForEdit = (singleFee: Fee, index: number) => {
    const editData = singleFee.slidingFees.filter((_fee, i) => i === index)[0]

    let tempEdit: EditDataI = {
      idData: singleFee._id,
      editFee: editData,
    }
    setEditFeeData({ ...editFeeData, ...tempEdit })
    if (new Date(editData.effectiveDate) <= new Date()) {
      setConfirmEditEditFee(true)
    } else {
      setEditFee(true)
    }
  }
  return (
    <>
      <TableRow className="not-word-break fee-table-padding ">
        <TableCell>{singleFee._id ? singleFee._id.marketName : '-'}</TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => (
            <p key={index}>
              {dayjs(slidingFee.effectiveDate).utc().format('MM-DD-YYYY')}
            </p>
          ))}
        </TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => (
            <p key={index}>
              {slidingFee.case1.from}-{slidingFee.case1.to}
            </p>
          ))}
        </TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => (
            <p key={index}>
              {slidingFee.case1.type === 'FLAT' && <span>$</span>}
              <span>{slidingFee.case1.discount}</span>
              {slidingFee.case1.type === 'PERCENT' && <span>%</span>}
            </p>
          ))}
        </TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => (
            <p key={index}>
              {slidingFee.case2.from}-{slidingFee.case2.to}
            </p>
          ))}
        </TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => (
            <p key={index}>
              {slidingFee.case2.type === 'FLAT' && <span>$</span>}
              <span>{slidingFee.case2.discount}</span>
              {slidingFee.case2.type === 'PERCENT' && <span>%</span>}
            </p>
          ))}
        </TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => (
            <p key={index}>{slidingFee.case3.from}+</p>
          ))}
        </TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => (
            <p key={index}>
              {slidingFee.case3.type === 'FLAT' && <span>$</span>}
              <span>{slidingFee.case3.discount}</span>
              {slidingFee.case3.type === 'PERCENT' && <span>%</span>}
            </p>
          ))}
        </TableCell>
        <TableCell>
          {singleFee.slidingFees.map((slidingFee, index) => {
            return (
              <p
                key={index}
                className="d-flex align-items-center justify-content-start"
              >
                <Tooltip title="Edit">
                  <span
                    className="ms-3 me-3 menu-icon text-center"
                    onClick={(e) => {
                      dataForEdit(singleFee, index)
                    }}
                  >
                    <FaPencilAlt size={size} />
                  </span>
                </Tooltip>
                {checkDate(slidingFee.effectiveDate) && (
                  <Tooltip title="Delete">
                    <span
                      className="ms-3 me-3 menu-icon text-center"
                      onClick={(e) => {
                        setDeleteFee(true)
                        setSelectedFee(slidingFee)
                      }}
                    >
                      <FaTrashAlt size={size} />
                    </span>
                  </Tooltip>
                )}
              </p>
            )
          })}
        </TableCell>
      </TableRow>
      {deleteFee && (
        <ConfirmDelete
          open={deleteFee}
          onClose={() => {
            setDeleteFee(false)
          }}
          onYes={deleteFeeFunc}
          text="this fee"
          title="Admin Fee"
        />
      )}
      {editFee && (
        <AddEditFee
          mode="Edit"
          editData={editFeeData}
          open={editFee}
          onClose={() => {
            setEditFee(false)
            getAllFee()
          }}
        />
      )}
      {confirmEditFee && (
        <ConfirmEdit
          open={confirmEditFee}
          onClose={() => {
            setConfirmEditEditFee(false)
          }}
          onYes={() => {
            setEditFee(true)
            setConfirmEditEditFee(false)
          }}
        />
      )}
    </>
  )
}

export default AllFeeTableBody
