import { Dialog } from '@mui/material'
import { FC } from 'react'
import { IoClose } from 'react-icons/io5'

import './modal-wrapper.scss'

interface ModalWrapperProps {
  open?: boolean
  handleClose?: () => void
  children: any
  title?: string
  wide?: boolean
  xl?: boolean
  sm?: boolean
  showCloseButton?: boolean
  className?: string
  boldTitle?: boolean
  innerContainerPadding?: string
}

const ModalWrapper: FC<ModalWrapperProps> = ({
  open,
  children,
  handleClose,
  title,
  wide,
  xl,
  sm,
  showCloseButton = true,
  className,
  boldTitle,
  innerContainerPadding,
}) => {
  return (
    <Dialog
      open={open ? open : false}
      keepMounted
      scroll="body"
      disableEnforceFocus // added this prop to resolve ck editor link modal issue
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: wide ? '800px' : xl ? '900px' : sm ? '400px' : '600px',
          },
        },
      }}
    >
      <div className={className}>
        {showCloseButton && (
          <span className="close-icon d-flex justify-content-end me-4 mt-2">
            <IoClose size={40} onClick={handleClose} />
          </span>
        )}
        <div
          className={`${
            innerContainerPadding ? innerContainerPadding : 'pb-5'
          } ps-5 pe-5 ${!showCloseButton ? 'mt-2' : ''}`}
        >
          {!!title && (
            <div className="w-100">
              <h1
                className={`text-center modal-title mb-3 ${
                  boldTitle ? 'modal-title-bold' : ''
                }`}
              >
                {title}
              </h1>
            </div>
          )}
          {children}
        </div>
      </div>
    </Dialog>
  )
}

export default ModalWrapper
