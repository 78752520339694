import { Button, SelectChangeEvent } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import useSingleTutor from '../../../../components/get-single-tutor/GetSingleTutor'
import ImageComponent from '../../../../components/image-component/ImageComponent'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { Tutor } from '../../../../types/tutor.type'
import { api } from '../../../../utils'
import { TutorModalsProps } from '../tutor-table/tutor-table.type'
import MarketDropdown from './../../../../components/styled-dropdown/MarketDropdown'
import ConfirmNationWide from './components/confirm-nation-wide/ConfirmNationWide'

interface AssignMarketIdProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<TutorModalsProps>>
  modalName: string
  tutorData?: Tutor[] | null | undefined
  onChange?: () => void
}

const AssignMarketId: FC<AssignMarketIdProps> = ({
  open,
  setOpen,
  modalName,
  tutorData,
  onChange,
}) => {
  const { marketData } = useAppContext()
  const singleTutor = useSingleTutor({
    tutorData,
    tutorId: localStorage.getItem('tutorId') as string,
    dependant: open.assignMarketId,
  })
  const [marketId, setMarketId] = useState('')
  const [confirmNationWide, setConfirmNationWide] = useState(false)
  const [modalText, setModalText] = useState('')
  const { updateAppState } = useAppContext()

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const marketName = marketData?.filter((market) => {
      if (market._id === marketId) return market
    })

    if (marketName?.[0]?.marketName?.toLowerCase() === 'nationwide') {
      if (
        singleTutor?.tutor.isTutorOfferOnlineClasses &&
        singleTutor?.tutor.isOfferInPersonTutoring
      ) {
        setModalText(
          'This tutor has selected that they offer in-person tutoring. If the tutor is put in the Nationwide market, this setting will be changed to No. Do you want to continue?',
        )
        setConfirmNationWide(true)
      } else if (
        !singleTutor?.tutor.isTutorOfferOnlineClasses &&
        singleTutor?.tutor.isOfferInPersonTutoring
      ) {
        setModalText(
          'This tutor has selected that they offer in-person tutoring and have not selected that they offer online tutoring. If the tutor is put in the Nationwide market, both of these settings will be changed. Do you want to continue?',
        )
        setConfirmNationWide(true)
      } else if (
        !singleTutor?.tutor.isTutorOfferOnlineClasses &&
        !singleTutor?.tutor.isOfferInPersonTutoring
      ) {
        setModalText(
          'This tutor has not selected that they offer online tutoring. If the tutor is put in the Nationwide market, this setting will be changed to Yes. Do you want to continue?',
        )
        setConfirmNationWide(true)
      } else {
        handleChangeMarket()
      }
    } else {
      handleChangeMarket()
    }
  }

  const handleChangeMarket = async () => {
    updateAppState({ loading: true })
    let form = new FormData()
    form.append('marketId', marketId)
    const res = await api.put(
      `/admin/assignMarketId/${localStorage.getItem('tutorId')}`,
      form,
    )
    if (res.ok && res.data) {
      handleClose()
      onChange?.()
    }
    updateAppState({ loading: false })
  }

  const marketChange = (event: SelectChangeEvent) => {
    setMarketId(event.target.value)
  }

  useEffect(() => {
    setMarketId(
      marketId
        ? marketId
        : singleTutor?.marketId && singleTutor?.marketId.marketId
        ? singleTutor?.marketId._id
        : singleTutor?.marketId && singleTutor?.marketId._id
        ? singleTutor?.marketId._id
        : '',
    )
  }, [singleTutor])

  return (
    <ModalWrapper
      open={open.assignMarketId}
      handleClose={handleClose}
      title="Assign Market Id"
      wide
    >
      <div className="w-100 d-flex justify-content-center ">
        <ImageComponent profilePic={singleTutor?.profilePicture} />
      </div>
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <form onSubmit={handleSubmit} className="w-auto">
            <div className="form-group row">
              <div className="col-sm-4 text-left">Name</div>
              <StyledTextfield
                className="col-sm-8"
                disabled
                value={localStorage.getItem('tutorName') as string}
              />
            </div>

            <div className="form-group row">
              <div className="col-sm-4 text-left">Market Name</div>
              <div className="col-sm-8">
                <MarketDropdown
                  option={marketData}
                  value={marketId}
                  placeholder="--Select Market Name--"
                  handleChange={marketChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8 offset-sm-4 text-center button-margin">
                <Button
                  className="w-100"
                  type="submit"
                  color={
                    marketId ||
                    (singleTutor?.marketId && singleTutor?.marketId.marketId)
                      ? 'success'
                      : 'info'
                  }
                >
                  Assign Market
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {confirmNationWide && (
        <ConfirmNationWide
          open={confirmNationWide}
          handleNo={handleClose}
          handleYes={handleChangeMarket}
          modalText={modalText}
        />
      )}
    </ModalWrapper>
  )
}

export default AssignMarketId
