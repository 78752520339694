import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import CustomCardWrapper from '../../../components/custom-card-wrapper/CustomCardWrapper'
import { useAppContext } from '../../../contexts/AppContext'
import { ConstantResponse, ContactClass } from '../../../types/constants.type'
import { api } from '../../../utils'
import UpdateInfo from './components/update-info/UpdateInfo'
import './contact-info.scss'

const ContactInfo = () => {
  const { updateAppState } = useAppContext()
  const [parentContactInfo, setParentContactInfo] = useState<ContactClass>()
  const [tutorContactInfo, setTutorContactInfo] = useState<ContactClass>()

  const [updateInfo, setUpdateInfo] = useState(false)
  const [buttonType, setButtonType] = useState<'PARENT' | 'TUTOR'>('PARENT')

  const getConstants = async () => {
    updateAppState({ loading: true })
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      updateAppState({ loading: false })
      const { parentContact, tutorContact } = constantsRes.data.data
      setParentContactInfo(parentContact)
      setTutorContactInfo(tutorContact)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  return (
    <div className="container mt-5">
      <div className="row marb15">
        <div className="col-sm-6 col-md-6">
          <h1 className="text-start">Contact Info</h1>
        </div>
        <div className="col-sm-6 col-md-6 text-end">
          <Button
            className="me-2 ms-2 mt20"
            onClick={() => {
              setButtonType('PARENT')
              setUpdateInfo(true)
            }}
          >
            Update Parent Info
          </Button>
          <Button
            className="me-2 ms-2 mt20"
            onClick={() => {
              setButtonType('TUTOR')
              setUpdateInfo(true)
            }}
          >
            Update Tutor Info
          </Button>
        </div>
      </div>
      <div className="row">
        <CustomCardWrapper className="pt-4 pb-4 me-2 ms-1">
          <span className="font-20">Parent Info :</span>
          <div className="row martb">
            <div className="col-sm-3">
              <label className="colB">Email address :</label>
            </div>
            <div className="col-sm-8">
              <label className="desc">{parentContactInfo?.email}</label>
            </div>
          </div>
          <div className="row martb">
            <div className="col-sm-3">
              <label className="colB">Phone Number:</label>
            </div>
            <div className="col-sm-8">
              <label className="desc">{parentContactInfo?.phoneNumber}</label>
            </div>
          </div>
        </CustomCardWrapper>
        <CustomCardWrapper className="pt-4 pb-4 me-2 ms-1">
          <span className="font-20">Tutor Info :</span>
          <div className="row martb">
            <div className="col-sm-3">
              <label className="colB">Email address :</label>
            </div>
            <div className="col-sm-8">
              <label className="desc">{tutorContactInfo?.email}</label>
            </div>
          </div>
          <div className="row martb">
            <div className="col-sm-3">
              <label className="colB">Phone Number:</label>
            </div>
            <div className="col-sm-8">
              <label className="desc">{tutorContactInfo?.phoneNumber}</label>
            </div>
          </div>
        </CustomCardWrapper>
      </div>
      {updateInfo && (
        <UpdateInfo
          open={updateInfo}
          onClose={() => {
            setUpdateInfo(false)
          }}
          onSuccess={getConstants}
          editInfo={
            buttonType === 'TUTOR' ? tutorContactInfo : parentContactInfo
          }
          buttonType={buttonType}
        />
      )}
    </div>
  )
}

export default ContactInfo
