import { TableCell, TableHead, TableRow } from '@mui/material'
import { FC } from 'react'

import { FilterButtonTypes } from '../../data'

interface SessionsTableHeadIProps {
  buttonType: FilterButtonTypes
}

const SessionsTableHead: FC<SessionsTableHeadIProps> = ({ buttonType }) => {
  return (
    <TableHead>
      <TableRow sx={{ '&:hover': { background: 'none' } }}>
        <TableCell>Parent Name</TableCell>
        <TableCell>Tutor Name</TableCell>
        <TableCell>Subject</TableCell>
        {buttonType !== 'PENDING_RESCHEDULES' && (
          <TableCell>
            Session <br /> Duration
          </TableCell>
        )}
        {buttonType !== 'PENDING_RESCHEDULES' && (
          <TableCell>Location</TableCell>
        )}
        <TableCell>
          Session Date <br /> and Time
        </TableCell>
        {buttonType === 'PENDING_RESCHEDULES' && (
          <TableCell>
            Proposed Date <br /> and Time
          </TableCell>
        )}
        {buttonType === 'PENDING_RESCHEDULES' && (
          <TableCell>Requested By</TableCell>
        )}
        {buttonType !== 'PENDING_RESCHEDULES' && (
          <TableCell>
            Projected <br /> Cost
          </TableCell>
        )}
        {[
          'FINISHED',
          'CANCELLED',
          'PAYMENT_FAILED',
          'PARTIAL_PAYMENT',
          'COMPLETED',
        ].some((status) => buttonType === status) && (
          <TableCell>
            Actual <br /> Cost
          </TableCell>
        )}
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default SessionsTableHead
