import { useAppContext } from '@/contexts/AppContext'
import BookingDetailModal from '@/pages/parent/components/booking-detail-modal/BookingDetailModal'
import ParentViewDetails from '@/pages/parent/components/view-details/ParentViewDetails'
import TutorViewDetails from '@/pages/tutor/components/view-details/TutorViewDetails'
import { Booking } from '@/types/getBooking.type'
import { Parent, ParentReq } from '@/types/parent.type'
import { SingleBookingRes } from '@/types/single-booking.type'
import { api } from '@/utils'
import { TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useState } from 'react'

import { PaginatedBooking } from '../rejected-canceled.type'

dayjs.extend(utc)
dayjs.extend(timezone)

interface RejectedCanceledSessionTableBodyI {
  rejectedSessions: PaginatedBooking
}

function capitalizeName(name: string) {
  return name.replace(/\b(\w)/g, (s) => s.toUpperCase())
}

const RejectedCanceledSessionTableBody: FC<
  RejectedCanceledSessionTableBodyI
> = ({ rejectedSessions }) => {
  const { updateAppState } = useAppContext()
  const {
    parent,
    student,
    tutor,
    startTime,
    status,
    statusChangedBy,
    statusChangedAt,
    parentTimezoneOffsetZone,
    parentAbbreviation,
  } = rejectedSessions

  const tutorName =
    capitalizeName(tutor.firstName) + ' ' + capitalizeName(tutor.lastName)
  const parentName =
    capitalizeName(parent.firstName) + ' ' + capitalizeName(parent.lastName)
  const studentName =
    capitalizeName(student.firstName) + ' ' + capitalizeName(student.lastName)

  const [singleParent, setSingleParent] = useState<Parent | null>(null)
  const [viewTutorDetail, setViewTutorDetail] = useState(false)
  const [showBooking, setShowBooking] = useState<Booking | null>(null)

  const getSingleParent = async (parentId: string) => {
    updateAppState({ loading: true })
    const singleParentReq = await api.get<ParentReq>(
      'admin/getParentById/' + parentId,
    )
    const { data } = singleParentReq
    if (data) {
      setSingleParent(data.data)
    }
    updateAppState({ loading: false })
  }

  const getSessionById = async (id: string) => {
    updateAppState({ loading: true })
    const res = await api.get<SingleBookingRes>('/admin/getBookingById', {
      bookingId: id,
    })

    if (res.data) {
      const { data } = res.data
      //@ts-ignore
      setShowBooking(data)
    }
    updateAppState({ loading: false })
  }

  return (
    <TableRow className="parent-booking-row">
      <TableCell
        className="cursor-pointer text-underline"
        onClick={() => {
          localStorage.setItem('tutorId', tutor._id)
          setViewTutorDetail(true)
        }}
      >
        {tutorName}
      </TableCell>
      <TableCell
        className="cursor-pointer text-underline"
        onClick={() => {
          getSingleParent(parent._id)
        }}
      >
        {parentName}
      </TableCell>
      <TableCell>{studentName}</TableCell>
      <TableCell
        className="cursor-pointer text-underline"
        onClick={() => {
          getSessionById(rejectedSessions._id)
        }}
      >
        {dayjs(startTime)
          .tz(parentTimezoneOffsetZone)
          .format('MMM DD, YYYY h:mmA')}{' '}
        {parentAbbreviation}
      </TableCell>
      <TableCell>{statusChangedBy}</TableCell>
      <TableCell>
        {dayjs(statusChangedAt)
          .tz(parentTimezoneOffsetZone)
          .format('MMM DD, YYYY h:mmA')}{' '}
        {parentAbbreviation}
      </TableCell>
      <TableCell>{status}</TableCell>
      {singleParent && (
        <ParentViewDetails
          singleParent={singleParent}
          setSingleParent={setSingleParent}
          key={!!singleParent + 'viewDetail'}
          parentId={singleParent._id}
          open={!!singleParent}
          handleClose={() => {
            setSingleParent(null)
          }}
        />
      )}
      {viewTutorDetail && (
        <TutorViewDetails
          open={viewTutorDetail}
          key={viewTutorDetail + 'viewDetail'}
          handleClose={() => {
            setViewTutorDetail(false)
          }}
        />
      )}
      {!!showBooking && (
        <BookingDetailModal
          open={!!showBooking}
          onClose={() => setShowBooking(null)}
          booking={showBooking as Booking}
          bookingState={{ pageType: 'detail' }}
        />
      )}
    </TableRow>
  )
}

export default RejectedCanceledSessionTableBody
