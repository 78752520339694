import { Button, TableCell, TableRow, Tooltip } from '@mui/material/'
import { FC, useState } from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'

import VerifyChanges from '../../../../../../components/modals/verify-changes/VerifyChanges'
import { TutorElement } from '../../../../../../types/changeRequest.type'
import TutorViewDetails from '../../../view-details/TutorViewDetails'

const size = 20
interface PendingProfileTableBodyProps {
  tutor: TutorElement
  getPendingProfiles: () => Promise<void>
}

export interface PendingProfileTableModals {
  viewDetail?: boolean
  accept?: boolean
  reject?: boolean
}
const PendingProfileModalInit = {
  viewDetail: false,
  accept: false,
  reject: false,
}

interface handleModalParams {
  e?: any
  icon?: string
}

const PendingProfileTableBody: FC<PendingProfileTableBodyProps> = ({
  getPendingProfiles,
  tutor,
}) => {
  const { userId } = tutor
  const selectedTutor = userId
  const [modals, setModals] = useState<PendingProfileTableModals>(
    PendingProfileModalInit,
  )
  const [modalName, setModalName] = useState('')

  const handleModal = (data: handleModalParams) => {
    let selectedModal = data.icon ? data.icon : data?.e.target.name

    localStorage.setItem('tutorId', selectedTutor._id)
    setModalName(selectedModal)
    setModals({ ...modals, [selectedModal]: true })
  }

  return (
    <>
      <TableRow>
        <TableCell>{tutor ? selectedTutor.tutor.tutorId : '-'}</TableCell>
        <TableCell>
          {tutor ? selectedTutor.firstName : ''}&nbsp;
          {tutor ? selectedTutor.lastName : ''}
        </TableCell>
        <TableCell>{tutor ? selectedTutor.userName : '-'}</TableCell>
        <TableCell>{tutor ? selectedTutor.email : '-'}</TableCell>
        <TableCell>
          {
            <>
              <Tooltip title="Un Approve">
                <span className="ms-3 me-3 mt-3 menu-icon">
                  <FaTimes
                    size={size}
                    onClick={() => {
                      handleModal({ icon: 'reject' })
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Approve">
                <span
                  className="ms-3 me-3 mt-3 menu-icon"
                  onClick={() => {
                    handleModal({ icon: 'accept' })
                  }}
                >
                  <FaCheck size={size} />
                </span>
              </Tooltip>
              <Tooltip title="view">
                <Button
                  variant="text"
                  className="ms-1 me-1 "
                  name="viewDetail"
                  onClick={(e) => {
                    handleModal({ e })
                  }}
                >
                  View Details
                </Button>
              </Tooltip>
            </>
          }
        </TableCell>
      </TableRow>
      {modals.viewDetail && (
        <TutorViewDetails
          open={modals.viewDetail}
          key={modals.viewDetail + 'viewDetail'}
          handleClose={() => {
            setModals({ ...modals, viewDetail: false })
          }}
          path="pendingProfile"
          pendingTutor={tutor}
        />
      )}
      {(modals.accept || modals.reject) && (
        <VerifyChanges
          open={modals}
          setOpen={setModals}
          modalName={modalName}
          requestId={tutor._id}
          getPendingProfiles={getPendingProfiles}
        />
      )}
    </>
  )
}

export default PendingProfileTableBody
