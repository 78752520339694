import ModalWrapper from '@/components/modal-wrapper/ModalWrapper'
import { Button } from '@mui/material'
import { FC } from 'react'

interface NotCanadianModalProps {
  onClose: () => void
  open: boolean
}

const NotCanadianModal: FC<NotCanadianModalProps> = ({ open, onClose }) => {
  return (
    <ModalWrapper open={open} handleClose={onClose} wide>
      <p className="text-center" style={{ color: '#232f84' }}>
        This parent is not currently qualified to have a Canadian address added
        to their profile. This is only current available for Bright Horizons
        families.
      </p>
      <div className="mt-4 d-flex justify-content-center">
        <Button
          color="success"
          className="modal-button"
          type="submit"
          onClick={onClose}
        >
          OK
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default NotCanadianModal
