import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledTextArea from '../../../../components/styled-textfield/StyledTextArea'
import { useAppContext } from '../../../../contexts/AppContext'
import { Parent } from '../../../../types/parent.type'
import { api } from '../../../../utils'
import { ModalProps } from '../parent-table/ParentTable'
import './additional-note.scss'

interface FormValues {
  additionalNote: string
}

const schema = Yup.object({
  additionalNote: Yup.string().required('Additional Note is required'),
}).required()

interface AdditionalNoteProps {
  open: ModalProps
  setOpen: React.Dispatch<React.SetStateAction<ModalProps>>
  modalName: string
  parentId?: string
  singleParent: Parent | null | undefined
}

const AdditionalNote: FC<AdditionalNoteProps> = ({
  open,
  setOpen,
  modalName,
  parentId,
  singleParent,
}) => {
  const { updateAppState } = useAppContext()

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const defaultValues: FormValues = {
    additionalNote: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  })

  const onSubmit = async (values: FormValues) => {
    updateAppState({ loading: true })
    const { additionalNote } = values
    const formData = new FormData()

    let tempArray = []
    tempArray = singleParent?.parent.adminAdditionalNotes
      ? singleParent.parent.adminAdditionalNotes
      : []

    tempArray.push(additionalNote)
    formData.append('adminAdditionalNotes', JSON.stringify(tempArray))
    const res = await api.put(`/admin/editProfileParent/${parentId}`, formData)

    if (res.ok) {
      handleClose()
    }
    updateAppState({ loading: false })
  }

  return (
    <ModalWrapper
      open={open.additionalNote}
      handleClose={handleClose}
      title="Add Additional Note"
      wide
    >
      <form className="w-auto" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row align-items-baseline">
          <div className="col-sm-3 text-left">Additional Note</div>
          <Controller
            name="additionalNote"
            control={control}
            render={({ field }) => (
              <StyledTextArea
                {...field}
                minRows={3}
                placeholder="Enter Additional Note"
                isError={!!errors.additionalNote}
                className="col-sm-9"
                errorMessage={errors.additionalNote?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="d-flex justify-content-end mt-5">
          <Button
            className="button-width"
            disabled={!isValid}
            type="submit"
            color={!isValid ? 'info' : 'success'}
          >
            Add
          </Button>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default AdditionalNote
