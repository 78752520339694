import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import { ConstantResponse, Reason } from '../../../../types/constants.type'
import { api } from '../../../../utils'
import AddEditDeclineReason from './components/add-edit-decline-reason/AddEditDeclineReason'
import DeclineReasonTable from './components/decline-reason-table/DeclineReasonTable'

const AllDeclines = () => {
  const [buttonType, setButtonType] = useState<'PARENT' | 'TUTOR'>('PARENT')
  const [declineReasons, setDeclineReasons] = useState<Reason[]>([])
  const [parentDeclines, setParentDeclines] = useState<Reason[]>([])
  const [tutorDeclines, setTutorDeclines] = useState<Reason[]>([])

  const [addDeclineReason, setAddDeclineReason] = useState(false)
  const getConstants = async () => {
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      const { declineReasons, parentDeclineReasons, tutorDeclineReasons } =
        constantsRes.data.data
      setParentDeclines(parentDeclineReasons)
      setDeclineReasons(
        buttonType === 'PARENT' ? parentDeclineReasons : tutorDeclineReasons,
      )
      setTutorDeclines(tutorDeclineReasons)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  useEffect(() => {
    if (buttonType === 'PARENT') {
      setDeclineReasons(parentDeclines)
    } else {
      setDeclineReasons(tutorDeclines)
    }
  }, [buttonType])
  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2 align-items-end">
        <div className="col-md-12">
          <BackArrow route={routes.allCategories} />
        </div>
        <div className="col-md-7 offset-md-1">
          <Button
            className={`upper-button ${
              buttonType === 'PARENT' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('PARENT')
            }}
          >
            Parent
          </Button>
          <Button
            className={`upper-button ${
              buttonType === 'TUTOR' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('TUTOR')
            }}
          >
            Tutor
          </Button>
        </div>
        <div className="col-sm-4 col-md-4  text-end">
          <Button
            onClick={() => {
              setAddDeclineReason(true)
            }}
          >
            Add Decline Reason
          </Button>
        </div>
      </div>

      <div className="row">
        {!!declineReasons.length && (
          <DeclineReasonTable
            reasons={declineReasons}
            getConstants={getConstants}
            buttonType={buttonType}
          />
        )}
      </div>
      {addDeclineReason && (
        <AddEditDeclineReason
          open={addDeclineReason}
          onClose={() => {
            setAddDeclineReason(false)
          }}
          mode="Add"
          onSuccess={getConstants}
          buttonType={buttonType}
        />
      )}
    </div>
  )
}

export default AllDeclines
