import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useEffect, useState } from 'react'

import CustomDatePicker from '../../../../../../components/date-picker/CustomDatePicker'
import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { api } from '../../../../../../utils'
import { ReservationExpiration } from '../AllBhExpiration.types'

dayjs.extend(utc)
dayjs.extend(timezone)

interface AddEditExpirationI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  mode: 'Add' | 'Edit'
  editExpiration?: ReservationExpiration
  disableDates?: Dayjs[]
}

const AddEditExpiration: FC<AddEditExpirationI> = ({
  open,
  onClose,
  mode,
  onSuccess,
  editExpiration,
  disableDates,
}) => {
  const [reservationDate, setReservationDate] = useState<Dayjs | null>(null)
  const [expirationDays, setExpirationDays] = useState('')
  const [expDaysError, setExpDaysError] = useState(false)

  const expErrorMsg = () => {
    if (!expirationDays) return 'Expiration days is required'
    else if (parseInt(expirationDays) < 1)
      return 'Expiration days must be a whole number greater than 0.'
    else ''
  }

  useEffect(() => {
    if (mode === 'Edit' && editExpiration) {
      setReservationDate(dayjs(editExpiration.reservationDate).utc())
      setExpirationDays(editExpiration.expirationDays.toString())
    }
  }, [editExpiration])

  const handleAdd = async () => {
    const formData = new FormData()
    formData.append('expirationDays', expirationDays)
    let tempDate = reservationDate?.format().split('T')[0] + 'T00:00:00.000Z'
    formData.append('reservationDate', tempDate)

    let res

    if (mode === 'Add') {
      res = await api.post('admin/addReservationExpiration', formData)
    } else {
      res = await api.put(
        `admin/updateReservationExpiration/${editExpiration?._id}`,
        formData,
      )
    }
    if (res && res.ok) {
      onClose()
      onSuccess()
    }
  }
  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={mode + ' Expiration Record'}
      wide
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <div className="form-group row align-items-center">
            <div className="col-sm-5 text-left">
              <label className="add-child__label">Reservation Date</label>
            </div>

            <div className="col-sm-7">
              <CustomDatePicker
                placeholder="mm-dd-yyyy"
                date={reservationDate}
                minDate={dayjs().add(1, 'days')}
                onChange={(value) => {
                  setReservationDate(value as Dayjs)
                }}
                disableClear
                shouldDisableDate={(value) => {
                  let tempDisable = false
                  disableDates?.forEach((date) => {
                    if (date && value) {
                      if (
                        date.year() === value.year() &&
                        date.month() === value.month() &&
                        date.date() === value.date()
                      ) {
                        tempDisable = true
                      }
                    }
                  })
                  return tempDisable
                }}
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <div className="col-sm-5 text-left">
              <label className="add-child__label">Expiration Days</label>
            </div>

            <div className="col-sm-7">
              <StyledTextfield
                type="number"
                placeholder="Enter Expiration Days"
                onKeyDown={() => {
                  setExpDaysError(true)
                }}
                value={expirationDays}
                onChange={(e) => {
                  setExpirationDays(e.target.value)
                }}
                isError={
                  expDaysError &&
                  (!expirationDays || parseInt(expirationDays) <= 0)
                }
              />
            </div>
            {expDaysError &&
              (!expirationDays || parseInt(expirationDays) <= 0) && (
                <div className="helper-text">{expErrorMsg()}</div>
              )}
          </div>
          <div className="form-group row pe-3">
            <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-0">
              <Button
                color={!expErrorMsg() && reservationDate ? 'success' : 'info'}
                disabled={!(reservationDate && !expErrorMsg())}
                className="w-100"
                onClick={handleAdd}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditExpiration
