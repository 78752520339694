import { Button } from '@mui/material'
import { FC, useState } from 'react'

import CKEditorComponent from '../../../../../components/ckEditor-component/CKEditorComponent'
import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import { api } from '../../../../../utils'
import './edit-description.scss'

interface EditDescriptionI {
  open: boolean
  bgData: string
  buttonType: 'Authorization' | 'Disclosure' | 'FCRA'
  onClose: () => void
  onSuccess: () => void
}

const EditDescription: FC<EditDescriptionI> = ({
  bgData,
  buttonType,
  onClose,
  onSuccess,
  open,
}) => {
  const [editorData, setEditorData] = useState(bgData)
  const [editorError, setEditorError] = useState(false)

  const updateData = async () => {
    const formData = new FormData()
    if (buttonType === 'Authorization') {
      formData.append('ackBackgroundCheck', editorData)
    } else if (buttonType === 'Disclosure') {
      formData.append('backgroundCheckDisclosure', editorData)
    } else {
      formData.append('fairCreditSummary', editorData)
    }

    const res = await api.put('admin/updateConstants', formData)
    if (res.ok) {
      onClose()
      onSuccess()
    }
  }
  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      wide
      title={'Edit ' + buttonType}
    >
      <div className="edit-heading text-center">Description For the Page</div>
      <CKEditorComponent
        value={editorData}
        onChange={setEditorData}
        onFocus={() => {
          setEditorError(true)
        }}
      />
      {editorError && !editorData && (
        <div className="editor-error">
          <small>Description is Required</small>
        </div>
      )}
      <div className="form-group row">
        <div className=" col-sm-3 offset-sm-9 text-center mt-4">
          <Button
            className="w-100"
            color={!!editorData ? 'success' : 'info'}
            disabled={!editorData}
            onClick={updateData}
          >
            Save
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default EditDescription
