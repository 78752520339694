import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'

import CalendarComponent from '../../../../components/calendar-component/CalendarComponent'
import CalendarEvents from '../../../../components/calendar-events/CalendarEvents'
import { useAppContext } from '../../../../contexts/AppContext'
import { api } from '../../../../utils'
import { StyledButton } from './../../../../components/styled-button/StyledButtons'
import RecurringAvailabilityModal from './components/recurring-availability-modal/RecurringAvailabilityModal'
import './tutor-schedule.scss'

dayjs.extend(utc)
dayjs.extend(tz)

const setAndRemoveStateInit = {
  slotArraytoSend: [],
  datesToRemove: [],
}

const TutorSchedules = () => {
  const { updateAppState } = useAppContext()
  const [tutorName, setTutorName] = useState('')
  const [recurringAvailability, setRecurringAvailability] = useState(false)
  const [abbreviation, setAbbreviation] = useState('')
  const [date, setDate] = useState<Date | null | undefined>(new Date())
  const [refresher, setRefresher] = useState<'set' | 'reset'>('set')

  const [setAndRemoveState, setSetAndRemoveState] = useState<any>(
    setAndRemoveStateInit,
  )

  useEffect(() => {
    const name = localStorage.getItem('tutorName')
    setTutorName(name as string)
    const abbreviation = localStorage.getItem('abbreviation')
    setAbbreviation(abbreviation as string)
  }, [tutorName])

  const setAvailability = async () => {
    updateAppState({ loading: true })
    let formData = new FormData()
    const startDate = dayjs
      .utc()
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss[Z]')
    const endDate = dayjs.utc().add(1, 'month').format('YYYY-MM-DDTHH:mm:ss[Z]')
    formData.append('startDate', startDate)
    formData.append('endDate', endDate)
    formData.append('slots', JSON.stringify(setAndRemoveState.slotArraytoSend))
    formData.append(
      'slotsIdToRemove',
      JSON.stringify(setAndRemoveState.datesToRemove),
    )
    formData.append('tutorId', localStorage.getItem('tutorId') as string)

    const res = await api.post('/tutor/setAvailability', formData)
    if (res.ok) updateAppState({ loading: false })
  }

  return (
    <>
      <div className="container-fluid mt-5">
        <div className="d-flex text-center justify-content-center">
          <h1>Schedule for {tutorName}</h1>
        </div>
        <div className="w-100 d-flex justify-content-center m-2">
          <div className="recurring-schedule-button">
            <StyledButton
              color="success"
              endIcon={<FaArrowRight />}
              onClick={() => {
                setRecurringAvailability(true)
              }}
            >
              Set Recurring Availability
            </StyledButton>
          </div>
        </div>

        <div className="d-flex column-view">
          <div className="m-3">
            <h2 className="margin-b50 text-center calendar-heading">
              Choose a date
            </h2>
            <div>
              <CalendarComponent date={date} setDate={setDate} />
            </div>
          </div>
          <div className="m-3">
            <h2 className="margin-b15 text-center calendar-heading">
              Choose a Time
              <br />
              <span className="timeZone text-center">
                Time Zone:[{abbreviation}]
              </span>
            </h2>
            <div>
              <CalendarEvents
                refresher={refresher}
                eventDate={date}
                setSetAndRemoveState={setSetAndRemoveState}
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="schedular-save mt-5 ">
            <StyledButton
              color="inherit"
              onClick={setAvailability}
              disabled={
                setAndRemoveState.slotArraytoSend.length < 1 &&
                setAndRemoveState.datesToRemove.length < 1
              }
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
      <RecurringAvailabilityModal
        onSuccess={() => {
          setRefresher(refresher === 'set' ? 'reset' : 'set')
        }}
        open={recurringAvailability}
        setOpen={setRecurringAvailability}
        key={recurringAvailability + ''}
      />
    </>
  )
}

export default TutorSchedules
