import { IconButton, InputBase, TextFieldProps } from '@mui/material'
import React, { forwardRef, useState } from 'react'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'

import { CONSTANT } from '../../utils/constants'
import './styled-textfield.scss'

interface MTextFieldProps {
  OutlinedTextFieldProps: TextFieldProps
  StandardTextFieldProps: TextFieldProps
  FilledTextFieldProps: TextFieldProps
}

interface TextfieldProps
  extends Partial<Omit<MTextFieldProps, 'errorMessage'>> {
  multiline?: boolean
  placeholder?: string
  name?: string
  type?: string
  value?: string
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  className?: string | undefined
  isError?: boolean
  isTouched?: boolean
  errorMessage?: string
  rows?: number
  disabled?: boolean
  readOnly?: boolean
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  whiteBg?: boolean
  max?: number
  min?: number
  required?: boolean
  innerClass?: string
  showErrorMsgOnStart?: boolean
  errorMsgClass?: string
}
const StyledTextfield: React.FC<TextfieldProps> = forwardRef(
  (
    {
      type,
      onChange,
      placeholder,
      required,
      value,
      name,
      className,
      isError,
      isTouched,
      errorMessage,
      multiline,
      rows,
      disabled,
      readOnly,
      whiteBg = true,
      max,
      onBlur,
      onFocus,
      onKeyDown,
      min,
      innerClass,
      showErrorMsgOnStart,
      errorMsgClass,
      ...props
    },
    ref,
  ) => {
    const [isSeen, setIsSeen] = useState(false)
    const [touched, setTouched] = useState(false)

    const inputClass =
      touched && (isError || errorMessage)
        ? 'styled-textfield-error'
        : isTouched
        ? 'styled-textfield-correction'
        : ''

    const disabledClass = disabled ? 'disabled-class' : ''

    return (
      <div className={`d-flex flex-column align-items-baseline ${className}`}>
        <InputBase
          multiline={multiline ? true : false}
          rows={rows}
          fullWidth
          disabled={disabled}
          inputRef={ref}
          size="small"
          error={isError}
          onFocus={() => {
            setTouched(true)
          }}
          className={`styled-textfield ${inputClass} ${
            whiteBg ? 'white-bg' : ''
          }
          ${multiline ? 'p-2' : ''}
          ${disabled ? 'disabled-class' : ''}
          ${innerClass ? innerClass : ''}
          `}
          placeholder={placeholder}
          required={required}
          type={
            type === 'password' && !isSeen
              ? 'password'
              : type === 'password' && isSeen
              ? 'text'
              : type
          }
          onChange={(e) => {
            const value = e.target.value
            if (max || min || min == 0) {
              if (max) {
                if (value.length <= max) {
                  onChange?.(e)
                }
              } else if (min || min == 0) {
                if (type === 'number') {
                  if (parseInt(value) >= min || !value) {
                    onChange?.(e)
                  }
                } else {
                  if (value.length >= min) {
                    onChange?.(e)
                  } else {
                    onChange?.(e)
                  }
                }
              } else {
                onChange?.(e)
              }
            } else if (max && type === 'number') {
              if (
                (CONSTANT.numberRegex.test(value) || value === '') &&
                value.length <= max
              ) {
                onChange?.(e)
              }
            } else {
              onChange?.(e)
            }
          }}
          value={value}
          endAdornment={
            <>
              {type === 'password' ? (
                <IconButton
                  onClick={(event) => {
                    setIsSeen(!isSeen)
                    event?.stopPropagation()
                  }}
                >
                  {isSeen ? (
                    <BsEyeFill
                      className="styled-textfield-icon-EyeFill"
                      size={25}
                    />
                  ) : (
                    <BsEyeSlashFill
                      className="styled-textfield-icon-EyeSlahFill"
                      size={25}
                    />
                  )}
                </IconButton>
              ) : (
                <></>
              )}
            </>
          }
          inputProps={{
            className: disabledClass,
            readOnly: readOnly,
            onBlur,
            onFocus,
            onKeyDown,
          }}
          {...props}
        />
        {!!(isError && touched && errorMessage) ? (
          <span
            className={`helper-text mt-1 d-flex ${
              showErrorMsgOnStart
                ? 'justify-content-start'
                : 'justify-content-end'
            } w-100 ${errorMsgClass}`}
          >
            {errorMessage}
          </span>
        ) : (
          ''
        )}
      </div>
    )
  },
)

export default StyledTextfield
