import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import './disable-mysylvan-modal.scss'

interface DisableMySylvanModalProps {
  open: boolean
  onClose: () => void
}
const DisableMySylvanModal: FC<DisableMySylvanModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <ModalWrapper open={open} handleClose={onClose} wide>
      <div className="d-flex justify-content-center align-items-center flex-column disable-my-sylvan-modal">
        <p className="text-center">
          Tutors that are available in mySylvan must offer online tutoring. If
          the tutor should no longer be available in mySylvan, please disable
          this flag before making changes to their Offers Online Tutoring flag.
        </p>
        <div>
          <Button color="success" className="modal-button" onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default DisableMySylvanModal
