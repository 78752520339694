import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import { useAppContext } from '../../../contexts/AppContext'
import { ConstantResponse, FAQ } from '../../../types/constants.type'
import { api } from '../../../utils'
import { CONSTANT } from '../../../utils/constants'
import './all-faqs.scss'
import AddEditFaq from './components/add-edit-faq/AddEditFaq'
import FaqComponent from './components/faq-component/FaqComponent'

const AllFAQs = () => {
  const { updateAppState } = useAppContext()
  const [buttonType, setButtonType] = useState<'PARENT' | 'TUTOR'>('PARENT')
  const [allFaqs, setAllFaqs] = useState<FAQ[]>([])
  const [parentFaqs, setParentFaqs] = useState<FAQ[]>([])
  const [tutorFaqs, setTutorFaqs] = useState<FAQ[]>([])

  const [addFaq, setAddFaq] = useState(false)

  const getConstants = async () => {
    updateAppState({ loading: true })
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      updateAppState({ loading: false })
      const { parentFaqs, tutorFaqs } = constantsRes.data.data
      setParentFaqs(parentFaqs)
      setAllFaqs(buttonType === 'PARENT' ? parentFaqs : tutorFaqs)
      setTutorFaqs(tutorFaqs)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  useEffect(() => {
    if (buttonType === 'PARENT') {
      setAllFaqs(parentFaqs)
    } else {
      setAllFaqs(tutorFaqs)
    }
  }, [buttonType])
  return (
    <div className="container mt-4">
      <div className="row mb-4 align-items-center">
        <div className="col-sm-8 col-xs-8 col-md-8">
          <Button
            className={`upper-button ${
              buttonType === 'PARENT' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('PARENT')
            }}
          >
            Parent FAQ
          </Button>
          <Button
            className={`upper-button ${
              buttonType === 'TUTOR' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('TUTOR')
            }}
          >
            Tutor FAQ
          </Button>
        </div>
        <div className="col-sm-4 col-md-4 text-end margin-t20">
          <Button
            onClick={() => {
              setAddFaq(true)
            }}
          >
            Add FAQs
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mb-3">
          <h3 className="text-center heading-faq">
            <u>
              {buttonType === 'PARENT' ? 'Parent' : 'Tutor'} FAQs –{' '}
              {CONSTANT.PROJECT_NAME}
            </u>
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <p className="text-center mb-4">
            {buttonType === 'PARENT'
              ? `Here are questions and answers to help you better understand how ${CONSTANT.PROJECT_NAME} works. As you’ll see, it’s easy to connect with a great tutor and set your child up for success`
              : `We are always looking for amazing individuals to join our community of ${CONSTANT.PROJECT_NAME} tutors. Here are questions we’re commonly asked, so you can make a great decision.`}
          </p>
        </div>
      </div>
      {allFaqs.map((faq) => {
        return (
          <FaqComponent
            key={faq._id}
            FAQ={faq}
            getConstants={getConstants}
            buttonType={buttonType}
          />
        )
      })}
      {addFaq && (
        <AddEditFaq
          mode="Add"
          open={addFaq}
          onClose={() => {
            setAddFaq(false)
          }}
          onSuccess={getConstants}
          buttonType={buttonType}
        />
      )}
    </div>
  )
}

export default AllFAQs
