import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { useAppContext } from '../../../../contexts/AppContext'
import { Tutor, TutorResponse } from '../../../../types/tutor.type'
import { api } from '../../../../utils'
import { ParamType } from '../../AllTutors'

interface GetAllTutorProps {
  filterStates: ParamType | null
  debouncedSearch?: string
  dropDown?: boolean
}
const useAllTutors = ({
  filterStates,
  debouncedSearch,
  dropDown,
}: GetAllTutorProps) => {
  const { updateAppState } = useAppContext()

  const [allTutors, setAllTutors] = useState<Tutor[] | null>()
  const [totalPages, setTotalPages] = useState(0)

  const getTutorDetails = async () => {
    if (dropDown && !debouncedSearch) {
      setAllTutors(null)
      return
    }
    updateAppState({ loading: true })
    const queryObj = {
      status: filterStates?.status ? filterStates.status : 'ALL',
      startDate: filterStates?.startDate
        ? dayjs(filterStates.startDate).format().split('T')[0] +
          'T00:00:00.000Z'
        : null,
      endDate: filterStates?.endDate
        ? dayjs(filterStates.endDate).format().split('T')[0] + 'T23:59:59.999Z'
        : null,
      marketId:
        filterStates?.marketId && filterStates.marketId !== 'none'
          ? filterStates?.marketId
          : null,
      isLive:
        filterStates?.isLive && filterStates.isLive !== 'none'
          ? filterStates?.isLive
          : null,
      acceptingNewFamilies:
        filterStates?.acceptingNewFamilies &&
        filterStates.acceptingNewFamilies !== 'none'
          ? filterStates?.acceptingNewFamilies
          : null,
      skip: filterStates?.skip ? (filterStates.skip - 1) * 10 : 0,
      limit: 10,
      searchParam: debouncedSearch ? debouncedSearch : null,
      sortBy: filterStates?.sortBy ? filterStates.sortBy : null,
    }
    const res = await api.get<TutorResponse>('admin/getTutors', queryObj)

    if (res.ok && res.data) {
      const { tutors, count } = res.data.data
      setAllTutors(tutors)
      setTotalPages(Math.ceil(count / 10))
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getTutorDetails()
    localStorage.setItem('TutorFilters', JSON.stringify(filterStates))
  }, [filterStates, debouncedSearch])
  return { allTutors, totalPages, getTutorDetails, setAllTutors }
}

export default useAllTutors
