import { Button } from '@mui/material'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaTimes } from 'react-icons/fa'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../../../contexts/AppContext'
import { Booking } from '../../../../../../types/getBooking.type'
import { PromoApplyRes, PromoRes } from '../../../../../../types/promo.type'
import { api } from '../../../../../../utils'
import './add-promo-code.scss'

dayjs.extend(utc)
dayjs.extend(timezone)
interface AddPromoCodeProps {
  open: boolean
  onClose: (success?: boolean) => void
  booking: Booking
}
const AddPromoCodeModal: FC<AddPromoCodeProps> = ({
  open,
  onClose,
  booking,
}) => {
  const { updateAppState } = useAppContext()
  const [promoCode, setPromoCode] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [discountAmount, setDiscountAmount] = useState<number | ''>('')
  const [newCost, setNewCost] = useState<number | ''>('')
  const [isValidPromo, setIsValidPromo] = useState(true)

  const checkPromoCode = async () => {
    updateAppState({ loading: true })
    const res = await api.get<PromoRes>('admin/checkPromocode', {
      promocode: promoCode,
    })
    if (res.ok && res.data) {
      const { data } = res.data
      setIsValid(true)
      toast.success(res.data.message)
      let promoDetail = data
      if (promoDetail && promoDetail.type == 'PERCENT') {
        setNewCost(
          booking && booking.payments
            ? ((100 - promoDetail.credits) * booking.payments.projectedAmount) /
                100
            : '',
        )
        setDiscountAmount(
          booking && booking.payments
            ? (promoDetail.credits * booking.payments.projectedAmount) / 100
            : '',
        )
      } else {
        setNewCost(
          booking && booking.payments
            ? booking.payments.projectedAmount - promoDetail.credits > 0
              ? booking.payments.projectedAmount - promoDetail.credits
              : 0
            : 0,
        )
        setDiscountAmount(promoDetail.credits)
      }
    } else {
      setIsValidPromo(false)
    }
    updateAppState({ loading: false })
  }
  const applyPromoCode = async () => {
    updateAppState({ loading: true })
    const res = await api.get<PromoApplyRes>('admin/applyPromocode', {
      promocode: promoCode,
      bookingId: booking._id,
    })
    if (res.ok && res.data) {
      onClose(true)
    }
    updateAppState({ loading: false })
  }
  const removePromoCode = () => {
    setPromoCode('')
    setDiscountAmount('')
    setNewCost('')
    setIsValid(false)
    setIsValidPromo(true)
  }

  let originalRescheduleStartTime1,
    originalRescheduleEndTime1,
    currentStartTime,
    currentEndTime,
    abb1
  if (booking && booking.originalRescheduleStartTime) {
    originalRescheduleStartTime1 = dayjs(booking.originalRescheduleStartTime)
      .tz(booking.parentTimezoneOffsetZone)
      .format('LLL')
    originalRescheduleEndTime1 = dayjs(booking.originalRescheduleEndTime)
      .tz(booking.parentTimezoneOffsetZone)
      .format('LLL')
    currentStartTime = dayjs(booking.startTime)
      .tz(booking.parentTimezoneOffsetZone)
      .format('LLL')
    currentEndTime = dayjs(booking.endTime)
      .tz(booking.parentTimezoneOffsetZone)
      .format('LLL')
    abb1 = dayjs(booking.originalRescheduleStartTime)
      .tz(booking.parentTimezoneOffsetZone)
      .offsetName('short')
  }

  return (
    <ModalWrapper
      wide
      open={open}
      handleClose={onClose}
      title="Add Promo"
      className="add-promo"
    >
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Parent</label>
        </div>
        <div className="col-sm-7 add-promo-content">
          {booking.parentId?.firstName ? booking.parentId?.firstName : ''}&nbsp;
          {booking.parentId?.lastName ? booking.parentId?.lastName : ''}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Tutor</label>
        </div>

        <div className="col-sm-7 add-promo-content">
          {booking.tutorId?.firstName ? booking.tutorId?.firstName : ''}&nbsp;
          {booking.tutorId?.lastName ? booking.tutorId?.lastName : ''}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Session</label>
        </div>
        <div className="col-sm-7 add-promo-content">
          {dayjs(currentStartTime).format('MMM D, YYYY')}
          {currentStartTime
            ? ' | ' + dayjs(currentStartTime).format('h:mmA')
            : '-'}
          {currentEndTime ? '-' + dayjs(currentEndTime).format('h:mmA') : ''}
          &nbsp;
          {abb1}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Original projected session cost</label>
        </div>
        <div className="col-sm-7 add-promo-content">
          {booking.payments &&
          booking.payments.hourlyRate &&
          booking.payments.hourlyRate >= 0
            ? '$' +
              (booking.payments.hourlyRate * booking.payments.duration).toFixed(
                2,
              )
            : '-'}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Original projected session cost</label>
        </div>
        <div className="col-sm-7 add-promo-content">
          {booking.payments && booking.payments.referralDiscount
            ? '$' + booking.payments.referralDiscount.toFixed(2)
            : '-'}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Promo Code</label>
        </div>
        <div className="col-sm-7 add-promo-content">
          <div className="d-flex  align-items-center">
            <StyledTextfield
              placeholder="Enter Code"
              value={promoCode}
              onChange={(e) => {
                setPromoCode(e.target.value)
              }}
            />
            {!!promoCode && (
              <span className="ms-1 cursor-pointer" onClick={removePromoCode}>
                <FaTimes size={15} />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Updated Promo Code discount applied</label>
        </div>
        <div className="col-sm-7 add-promo-content">
          {discountAmount ? '$' + discountAmount.toFixed(2) : '-'}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-5">
          <label>Total projected session cost after discounts</label>
        </div>
        <div className="col-sm-7 add-promo-content">
          {newCost ? '$' + newCost.toFixed(2) : '-'}
        </div>
      </div>
      <div className="col-md-12 col-sm-12 text-center">
        <Button
          className="add-promo-button"
          disabled={
            !promoCode ? true : promoCode && !isValidPromo ? true : false
          }
          color={!promoCode ? 'primary' : 'success'}
          onClick={checkPromoCode}
        >
          Apply Code
        </Button>
        <Button
          className="add-promo-button"
          disabled={!isValid}
          color={!isValid ? 'primary' : 'success'}
          onClick={applyPromoCode}
        >
          Save
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default AddPromoCodeModal
