import ModalWrapper from '@/components/modal-wrapper/ModalWrapper'
import { Button } from '@mui/material'
import { FC } from 'react'

interface ConfirmExpChangesI {
  open: boolean
  onClose: () => void
  onYes: () => void
}
const ConfirmExpChanges: FC<ConfirmExpChangesI> = ({
  open,
  onClose,
  onYes,
}) => {
  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      wide
      showCloseButton={false}
    >
      <div className="d-flex justify-content-center align-items-center flex-column delete-modal mt-4">
        <p className="delete-modal__title">
          Are you sure you want to change the expiration date of this
          reservation? This action cannot be undone.
        </p>
        <div>
          <Button color="success" className="modal-button" onClick={onClose}>
            Cancel Change
          </Button>
          <Button color="success" className="modal-button" onClick={onYes}>
            Change Date
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ConfirmExpChanges
