export const getDisputeHeading = (status: string) => {
  return [
    'Dispute ID',
    'Parent Name',
    'Tutor Name',
    'Dispute Date',
    'Session Date',
    'Market Name',
    'Dispute Reason',
    ...(status === 'RESOLVED' ? ['Resolve Dispute'] : []),
    'Action',
  ]
}
export const getDisputeButtons = (status: string) => {
  return [
    { title: 'Add Comment', name: 'addComment' },
    { title: 'View Detail', name: 'viewDetails' },
    ...(status === 'UNRESOLVED'
      ? [{ title: 'Resolve Dispute', name: 'resolveDispute' }]
      : []),
  ]
}
