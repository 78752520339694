import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'

import useSingleTutor from '../../../../components/get-single-tutor/GetSingleTutor'
import ImageComponent from '../../../../components/image-component/ImageComponent'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../../contexts/AppContext'
import { Tutor } from '../../../../types/tutor.type'
import { api } from '../../../../utils'
import { hrStatusArray } from '../../data'
import { TutorModalsProps } from '../tutor-table/tutor-table.type'

interface AssignHrStatusProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<TutorModalsProps>>
  modalName: string
  tutorId?: string
  tutorData?: Tutor[] | null | undefined
  onChange?: () => void
}

const AssignHrStatus: FC<AssignHrStatusProps> = ({
  open,
  setOpen,
  modalName,
  tutorData,
  tutorId,
  onChange,
}) => {
  const [hrStatus, setHrStatus] = useState('')
  const { updateAppState } = useAppContext()

  const singleTutor = useSingleTutor({
    tutorData,
    tutorId,
    dependant: open.hrStatus,
  })

  useEffect(() => {
    if (singleTutor?.tutor.hrStatus) {
      setHrStatus(singleTutor.tutor.hrStatus)
    }
  }, [singleTutor])

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!tutorId) return
    e.preventDefault()
    updateAppState({ loading: true })
    let form = new FormData()
    form.append('hrStatus', hrStatus)
    const res = await api.put(`/admin/addHrStatus/${tutorId}`, form)
    if (res.ok && res.data) {
      handleClose()
      onChange?.()
    }
    updateAppState({ loading: false })
  }

  const isValid = !!hrStatus

  return (
    <ModalWrapper
      open={open.hrStatus}
      handleClose={handleClose}
      title="Assign HR Status"
      wide
    >
      <div className="w-100 d-flex justify-content-center ">
        <ImageComponent profilePic={singleTutor?.profilePicture} />
      </div>
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <form onSubmit={handleSubmit} className="w-auto">
            <div className="form-group row">
              <div className="col-sm-4 text-left">Name</div>
              <StyledTextfield
                className="col-sm-8"
                disabled
                value={singleTutor?.firstName + ' ' + singleTutor?.lastName}
              />
            </div>

            <div className="form-group row">
              <div className="col-sm-4 text-left">HR Status</div>
              <div className="col-sm-8">
                <StyledDropdown
                  option={hrStatusArray}
                  value={hrStatus}
                  placeholder="--Select HR Status--"
                  onChange={(e) => {
                    setHrStatus(e.target.value)
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8 offset-sm-4 text-center button-margin">
                <Button
                  className="w-100"
                  type="submit"
                  disabled={!isValid}
                  color={!isValid ? 'info' : 'success'}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AssignHrStatus
