import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'

import { useAppContext } from '../../contexts/AppContext'
import './app-sidebar.scss'
import SideBarList from './components/SideBarList'
import { getListItems } from './data'

const SideBar = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [smallScreen, setSmallScreen] = useState(true)
  const { appState, isMarketplaceManager } = useAppContext()

  const sideBarClass =
    appState.collapse && smallScreen ? 'app-sidebar-mobile' : ''

  return (
    <div
      className={`app-sidebar d-sm-block ${sideBarClass} ${
        matches && !smallScreen ? 'app-sidebar-sm' : ''
      }  `}
    >
      <div className="scroller pt-1 pb-5">
        {getListItems(!!isMarketplaceManager).map((listItem) => {
          return (
            <div key={listItem.id}>
              <SideBarList
                listItem={listItem}
                matches={matches && smallScreen && !mobile}
                setSmallScreen={setSmallScreen}
                smallScreen={smallScreen}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SideBar
