import { TutorStates } from '@/pages/tutor/components/edit-tutor/EditTutor'
import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextField from '../../../../../../../../components/styled-textfield/StyledTextfield'
import { AddressState } from '../../AddressForm'

interface AddressModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setClearFlag: React.Dispatch<React.SetStateAction<boolean>>
  modalName?: string
  parentId?: string
  address?: AddressState
  setAddress: React.Dispatch<React.SetStateAction<AddressState>>
  setNewAddress?: React.Dispatch<React.SetStateAction<AddressState | undefined>>
  setTutorState?: React.Dispatch<React.SetStateAction<TutorStates>>
  onAdd?: () => void
}

const AddressModal: FC<AddressModalProps> = ({
  setOpen,
  open,
  address,
  setAddress,
  setNewAddress,
  setClearFlag,
  onAdd,
  setTutorState,
}) => {
  const handleClose = () => {
    setOpen(!open)
    setClearFlag(true)
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      title="Verify Address"
      wide
    >
      <form className="w-auto">
        <div className="form-group row">
          <div className="col-sm-4 text-left">Street Address Line 1</div>
          <StyledTextField
            disabled
            value={address?.addressLine1}
            className="col-sm-8"
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">
            Street Address Line 2<br />
            (Optional)
          </div>
          <div className="col-sm-8">
            <StyledTextField
              placeholder="Address Line 2  (optional)"
              value={address?.addressLine2}
              onChange={(e) => {
                setAddress({ ...address, ['addressLine2']: e.target.value })
                setNewAddress?.({
                  ...address,
                  ['addressLine2']: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">City</div>
          <StyledTextField
            disabled
            value={address?.city}
            className="col-sm-8"
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">State</div>
          <StyledTextField
            value={address?.state}
            disabled
            className="col-sm-8"
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">Zip Code</div>
          <StyledTextField
            value={address?.zipCode}
            disabled
            className="col-sm-8"
          />
        </div>

        <div className="row">
          <div className="col-sm-4 offset-sm-8 text-center button-margin">
            <Button
              className="w-100"
              color="success"
              onClick={() => {
                setOpen(!open)
                setTutorState?.((prevState) => ({
                  ...prevState,
                  notChanged: true,
                  newSelected: true,
                }))
                onAdd?.()
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default AddressModal
