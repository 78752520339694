import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { Rating } from '../../hooks/feedback.type'
import FeedbackTableBody from './FeedbackTableBody'
import { feedbackHeading } from './data'

interface DisputeTableI {
  allFeedbacks: Rating[]
  getAllFeedbacks: () => Promise<void>
}

const FeedbackTable: FC<DisputeTableI> = ({
  allFeedbacks,
  getAllFeedbacks,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ '&:hover': { background: 'none' } }}>
            {feedbackHeading.map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!allFeedbacks.length &&
            allFeedbacks.map((feedback) => {
              return (
                <FeedbackTableBody
                  feedback={feedback}
                  getAllFeedbacks={getAllFeedbacks}
                  key={feedback._id}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FeedbackTable
