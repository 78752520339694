import { Button, Pagination } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'

import CustomDatePicker from '../../components/date-picker/CustomDatePicker'
import { SearchBar } from '../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../contexts/AppContext'
import { useDebounce } from '../../hooks'
import { FileResponse } from '../../types/file.type'
import { api } from '../../utils'
import { downloadUrl } from '../dashboard/helpers'
import ContactsTable from './components/contacts-table/ContactsTable'
import useAllContacts from './hooks/useAllContacts'

export interface ContactsFilterI {
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  skip?: number | null
}
const defaultContactsFilter: ContactsFilterI = {
  startDate: null,
  endDate: null,
  skip: null,
}
const Contacts = () => {
  const { updateAppState } = useAppContext()
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [contactsFilter, setContactsFilters] = useState<ContactsFilterI>(
    defaultContactsFilter,
  )

  const onDateChange = (value: Dayjs | null | undefined, mode?: string) => {
    setPage(1)
    let temp: ContactsFilterI = {
      skip: 0,
    }
    if (mode === 'startDate') {
      temp.startDate = value
    } else {
      temp.endDate = value
    }

    setContactsFilters({ ...contactsFilter, ...temp })
  }

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
    setContactsFilters({ ...contactsFilter, skip: value })
  }
  const debouncedSearch = useDebounce({
    value: search,
    milliSeconds: 500,
  })

  const prevSearchRef = useRef('')

  if (prevSearchRef.current !== debouncedSearch) {
    setContactsFilters((filters) => ({ ...filters, skip: 1 }))
  }
  prevSearchRef.current = debouncedSearch

  const { allContacts, getAllContacts, totalPages } = useAllContacts({
    filterStates: contactsFilter,
    debouncedSearch,
    setLoading(status: boolean) {
      setIsLoading(status)
    },
  })

  const downloadCSV = async () => {
    updateAppState({ loading: true })
    const res = await api.get<FileResponse>('admin/contactReport')
    if (res.ok && res.data) {
      const url = res.data.data.url
      downloadUrl(url, 'contactReport.csv')
    }
    updateAppState({ loading: false })
  }

  return (
    <div className="container-fluid content">
      <div className="container-fluid">
        <div className="d-flex flex-wrap mt-2">
          <Button className="me-3 mt-3 blue-button" onClick={downloadCSV}>
            Download CSV
          </Button>
        </div>
        <div className="row mt-2">
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={contactsFilter.startDate}
              maxDate={contactsFilter.endDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'startDate')
              }}
              placeholder="Select Start Date"
              mode="startDate"
            />
          </div>
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={contactsFilter.endDate}
              minDate={contactsFilter.startDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'endDate')
              }}
              placeholder="Select End Date"
              mode="endDate"
            />
          </div>

          <div className=" d-flex col-md-3 offset-md-3 col-xl-2  mt-2 ">
            <SearchBar
              className="search-box"
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setPage(1)
              }}
            />
          </div>
        </div>
        <div className="mt-4">
          <ContactsTable
            allContacts={allContacts}
            getAllContacts={getAllContacts}
          />
        </div>
        {allContacts && allContacts?.length > 0 ? (
          <>
            {totalPages > 1 && (
              <div className="mt-2 d-flex justify-content-end">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </>
        ) : (
          <>
            {!isLoading && (
              <div className="mt-2 d-flex justify-content-start">
                <p>Record not found</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Contacts
