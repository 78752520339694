import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  timeArray,
  weekCount,
} from '../../../../../components/calendar-events/components/data'
import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import { Parent } from '../../../../../types/parent.type'
import { SessionFormValues } from '../../createSession.type'
import TimeSlotComponent from '../time-slot-component/TimeSlotComponent'

interface ParentSchedularModalProps {
  selectedParent?: Parent
  open?: boolean
  handleClose?: () => void
  mode: 'parent' | 'tutor'
  slotStatusArray?: any
  weekSlotArray?: any
  isTutor: boolean
  promoCodeValueSetter: (value: any) => void
  onAddSession?: (param: string, reserve: string) => void
  onSlotChange?: (data: any, i: any, state: 'day' | 'week') => void
  onDateClear?: () => void
  isRateChargesSuppress: boolean
  calendarOptions?: { startDate: string; endDate: string }
}

const ParentSchedularModal: FC<ParentSchedularModalProps> = ({
  selectedParent,
  open,
  handleClose,
  mode,
  slotStatusArray,
  weekSlotArray,
  isTutor,
  onAddSession,
  onSlotChange,
  promoCodeValueSetter,
  onDateClear,
  isRateChargesSuppress,
  calendarOptions,
}) => {
  const { watch } = useFormContext<SessionFormValues>()
  const [bookingBy, setBookingBy] = useState<'day' | 'week'>('day')

  const sessionPromo = watch('promoCode')
  const sessionReservation = watch('sessionReservation')
  const value = watch()
  const isButtonValid = () => {
    const { sessionDate } = value
    let isSlotSelected = false

    if (bookingBy === 'day') {
      isSlotSelected = slotStatusArray?.some((slot: any) => slot == 2)
    } else {
      timeArray.map((_time, i) =>
        weekCount.map((_week, j) => {
          if (weekSlotArray?.[j]?.[i]?.element === 2) {
            isSlotSelected = true
          }
        }),
      )
    }

    if (!sessionDate || !isSlotSelected) {
      return true
    } else return false
  }

  useEffect(() => {
    isButtonValid()
  }, [value])

  return (
    <ModalWrapper
      wide
      open={open}
      handleClose={handleClose}
      title="Select Session Date and Time"
    >
      <TimeSlotComponent
        isRateChargesSuppress={isRateChargesSuppress}
        onDateClear={onDateClear}
        selectedParent={selectedParent}
        mode={mode}
        calendarOptions={calendarOptions}
        weekSlotArray={weekSlotArray}
        slotStatusArray={slotStatusArray}
        isTutor={isTutor}
        onSlotChange={onSlotChange}
        promoCodeValueSetter={promoCodeValueSetter}
      />
      <div className="form-group row">
        <div className=" col-sm-3 offset-sm-9 text-center mt-5">
          <Button
            disabled={isButtonValid()}
            color={isButtonValid() ? 'info' : 'success'}
            onClick={() => {
              onAddSession?.(
                sessionPromo as string,
                sessionReservation as string,
              )
            }}
          >
            Add Session
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ParentSchedularModal
