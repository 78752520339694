import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'

interface ConfirmDeleteI {
  open: boolean
  onClose: () => void
  onYes: () => void
  dataKey: 'private' | 'public'
}
const UpdateVisibility: FC<ConfirmDeleteI> = ({
  open,
  onClose,
  onYes,
  dataKey,
}) => {
  const titleHeading = dataKey === 'private' ? 'Private' : 'Public'
  return (
    <ModalWrapper open={open} handleClose={onClose} wide>
      <div className="d-flex justify-content-center align-items-center flex-column delete-modal">
        <div className="w-100">
          <h1 className="text-center delete-modal__main mb-3">{`${titleHeading} Rating?`}</h1>
        </div>

        <p className="delete-modal__title">
          Are you sure you want to make rating {dataKey}?
        </p>
        <div>
          <Button color="success" className="modal-button" onClick={onClose}>
            No
          </Button>
          <Button color="success" className="modal-button" onClick={onYes}>
            Yes
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default UpdateVisibility
