import './category-commission.scss'
import CategoryLister from './components/category-lister/CategoryLister'
import { categories } from './data'

const CategoryCommission = () => {
  return (
    <div className="container">
      {categories.map((category) => {
        return <CategoryLister key={category.name} category={category} />
      })}
    </div>
  )
}

export default CategoryCommission
