import { Outlet } from 'react-router-dom'

import SideBar from '../app-sidebar/SideBar'
import TopBar from '../app-topbar/TopBar'
import BackToTop from '../back-to-top/BackToTop'
import Downloader from '../downloader/Downloader'
import './pages-layout.scss'

function PagesLayout() {
  const year = new Date().getFullYear()

  return (
    <div className="pages-layout">
      <TopBar />
      <SideBar />
      <div className="pages-layout__content">
        <div className="pages-layout__content__outlet-container">
          <Outlet />
        </div>
        <footer className="pages-layout__footer">
          &copy; {year} - Sylvan Webadmin
        </footer>
      </div>
      <BackToTop />
      <Downloader />
    </div>
  )
}

export default PagesLayout
