import { useEffect, useState } from 'react'

import { useAppContext } from '../../../contexts/AppContext'
import { api } from '../../../utils'
import { DisputeFilterI } from '../AllDisputes'
import { CountRes, Disputes, DisputesRes } from './disputes.types'

interface useAllDisputesI {
  filterStates: DisputeFilterI | null
  debouncedSearch?: string
}
const useAllDisputes = ({ filterStates, debouncedSearch }: useAllDisputesI) => {
  const { updateAppState } = useAppContext()

  const [allDisputes, setAllDisputes] = useState<Disputes[]>([])
  const [totalPages, setTotalPages] = useState(0)

  const getAllDisputes = async () => {
    updateAppState({ loading: true })
    const queryObj = {
      type: filterStates?.status,
      startDate: filterStates?.startDate
        ? filterStates.startDate.format().split('T')[0] + 'T00:00:00.000Z'
        : null,
      endDate: filterStates?.endDate
        ? filterStates.endDate.format().split('T')[0] + 'T23:59:59.999Z'
        : null,
      marketId:
        filterStates?.marketId && filterStates.marketId !== 'none'
          ? filterStates?.marketId
          : null,

      skip: filterStates?.skip ? (filterStates.skip - 1) * 10 : 0,
      limit: 10,
      searchParam: debouncedSearch ? debouncedSearch : null,
    }
    const res = await api.get<DisputesRes>('admin/disputes', queryObj)
    const countRes = await api.get<CountRes>('admin/disputesCount', {
      status: filterStates?.status,
      startDate: filterStates?.startDate
        ? filterStates.startDate.format().split('T')[0] + 'T00:00:00.000Z'
        : null,
      endDate: filterStates?.endDate
        ? filterStates.endDate.format().split('T')[0] + 'T23:59:59.999Z'
        : null,
      marketId:
        filterStates?.marketId && filterStates.marketId !== 'none'
          ? filterStates?.marketId
          : null,
      searchParam: debouncedSearch ? debouncedSearch : null,
    })

    if (res.ok && res.data && countRes.ok && countRes.data) {
      const { data } = res.data
      setAllDisputes(data)
      const { count } = countRes.data.data
      setTotalPages(Math.ceil(count / 10))
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getAllDisputes()
  }, [filterStates, debouncedSearch])
  return { allDisputes, totalPages, getAllDisputes, setAllDisputes }
}

export default useAllDisputes
