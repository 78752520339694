import { FC } from 'react'

interface SearchedDropdownProps {
  options: any[] | undefined
  className?: string | undefined
  onSelect?: (value: any) => void
}

const SearchedDropDown: FC<SearchedDropdownProps> = ({
  options,
  className,
  onSelect,
}) => {
  return (
    <div className={className}>
      {options?.map((option) => {
        return (
          <div
            className="w-100 p-1 cursor-pointer"
            key={option._id}
            onClick={() => {
              onSelect?.(option)
            }}
          >
            {option.firstName}&nbsp;{option.lastName}
          </div>
        )
      })}
    </div>
  )
}

export default SearchedDropDown
