import dayjs from 'dayjs'
import React, { KeyboardEventHandler, useEffect, useState } from 'react'
import { Button } from 'reactstrap'

import { minTwoDigits } from '../../utils'
import './time-picker.scss'

export type TimePickerObj = {
  hour: number
  minute: number
  second: number
}

interface TimePickerIProps {
  data: TimePickerObj
  onChange: (data: TimePickerObj) => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined
  className?: string
}

interface State {
  hour: number
  minute: number
  second: number
  tempHour: string
  tempMinute: string
  tempSecond: string
}

const TimePicker: React.FC<TimePickerIProps> = ({
  data,
  onChange,
  onKeyDown,
  className,
}) => {
  const date = new Date()
  const hour = date.getHours(),
    minute = date.getMinutes(),
    second = date.getSeconds()
  const defaultState = data || { hour, minute, second }
  const [state, setState] = useState<State>({
    ...defaultState,
    tempHour: minTwoDigits(defaultState.hour),
    tempMinute: minTwoDigits(defaultState.minute),
    tempSecond: minTwoDigits(defaultState.second),
  })

  useEffect(() => {
    onChange({ hour: state.hour, minute: state.minute, second: state.second })
  }, [state])

  const time = state.hour > 12 ? 'PM' : 'AM'

  return (
    <div className={`time-picker ${className}`}>
      <input
        placeholder="HH"
        maxLength={2}
        size={2}
        aria-label="Hours"
        className="time-picker__input"
        value={state.tempHour}
        onKeyDown={onKeyDown}
        onChange={(e) => {
          setState((prev) => ({ ...prev, tempHour: e.target.value }))
        }}
        onBlur={(e) => {
          const date = dayjs()
            .set('hours', parseInt(e.target.value))
            .set('minutes', state.minute)
            .set('seconds', state.second)
          setState({
            hour: date.get('hours'),
            minute: date.get('minutes'),
            second: date.get('seconds'),
            tempHour: minTwoDigits(date.get('hours') % 12),
            tempMinute: minTwoDigits(date.get('minutes')),
            tempSecond: minTwoDigits(date.get('seconds')),
          })
        }}
      />
      <div className="time-picker__spacer">:</div>
      <input
        placeholder="MM"
        aria-label="Minutes"
        maxLength={2}
        size={2}
        className="time-picker__input"
        value={state.tempMinute}
        onKeyDown={onKeyDown}
        onChange={(e) => {
          setState((prev) => ({ ...prev, tempMinute: e.target.value }))
        }}
        onBlur={(e) => {
          const date = dayjs()
            .set('hours', state.hour)
            .set('minutes', parseInt(e.target.value))
            .set('seconds', state.second)
          setState({
            hour: date.get('hours'),
            minute: date.get('minutes'),
            second: date.get('seconds'),
            tempHour: minTwoDigits((date.get('hours') % 12) + ''),
            tempMinute: minTwoDigits(date.get('minutes') + ''),
            tempSecond: minTwoDigits(date.get('seconds') + ''),
          })
        }}
      />
      <Button
        color="primary"
        outline
        className="ms-3"
        onClick={() => {
          const date = dayjs()
            .set('hours', state.hour - (time === 'PM' ? 12 : -12))
            .set('minutes', state.minute)
            .set('seconds', state.second)
          setState({
            hour: date.get('hours'),
            minute: date.get('minutes'),
            second: date.get('seconds'),
            tempHour: minTwoDigits(date.get('hours') % 12),
            tempMinute: minTwoDigits(date.get('minutes')),
            tempSecond: minTwoDigits(date.get('seconds')),
          })
        }}
      >
        {time}
      </Button>
    </div>
  )
}

const TimePickerWrapper: React.FC<TimePickerIProps> = (props) => {
  return props.data && Object.keys(props.data).length ? (
    <TimePicker {...props} />
  ) : null
}

export default TimePickerWrapper
