import CustomDatePicker from '@/components/date-picker/CustomDatePicker'
import { useAppContext } from '@/contexts/AppContext'
import { api } from '@/utils'
import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'

import TutorLastAvailabilityTable, {
  AvailabilityDatesI,
} from './components/last-availability-table/TutorLastAvailabilityTable'
import TimeErrorModal from './components/timeErrorModal/TimeErrorModal'
import {
  LastTutorData,
  TutorLastAvailRes,
} from './tutor-last-availability.type'

const TutorLastAvailability = () => {
  const { updateAppState } = useAppContext()
  const defaultDates: AvailabilityDatesI = {
    startDate: null,
    endDate: null,
  }

  const [availabilityDates, setAvailabilityDates] =
    useState<AvailabilityDatesI>(defaultDates)

  const [tutorLastAvailability, setTutorLastAvailability] = useState<
    LastTutorData[]
  >([])

  const [isDateError, setIsDateError] = useState(false)

  const getTutorLastAvailability = async () => {
    const obj = {
      startDate: (availabilityDates?.startDate as Dayjs)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]'),
      endDate: (availabilityDates?.endDate as Dayjs)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]'),
    }

    if (dayjs(obj.startDate).isAfter(dayjs(obj.endDate))) {
      setIsDateError(true)
      return
    }
    updateAppState({ loading: true })
    const res = await api.get<TutorLastAvailRes>(
      '/admin/tutorLastAvailability',
      obj,
    )
    if (res.data) {
      const { tutors } = res.data?.data
      setTutorLastAvailability(tutors)
    }
    updateAppState({ loading: false })
  }

  const handleDateChange = (
    name: string,
    value: dayjs.Dayjs | null | undefined,
  ) => {
    setAvailabilityDates({ ...availabilityDates, [name]: value })
  }
  const handleDateClear = (name: string) => {
    setAvailabilityDates({ ...availabilityDates, [name]: null })
  }

  return (
    <div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <CustomDatePicker
                placeholder="Select Start Date"
                disableFutures
                date={availabilityDates.startDate}
                onChange={(value) => {
                  handleDateChange('startDate', value)
                }}
                onClear={() => {
                  handleDateClear('startDate')
                }}
              />
            </div>
            <p className="d-flex align-items-center m-0 w-auto">to</p>
            <div className="col-md-4">
              <CustomDatePicker
                placeholder="Select End Date"
                date={availabilityDates.endDate}
                onChange={(value) => {
                  handleDateChange('endDate', value)
                }}
                onClear={() => {
                  handleDateClear('endDate')
                }}
              />
            </div>
            <div className="col-md-2">
              <Button
                onClick={() => {
                  getTutorLastAvailability()
                }}
              >
                Run
              </Button>
            </div>
          </div>
        </div>
        {tutorLastAvailability.length > 0 && (
          <div>
            <TutorLastAvailabilityTable
              lastAvailability={tutorLastAvailability}
              availabilityDates={availabilityDates}
              setIsDateError={setIsDateError}
            />
          </div>
        )}
      </div>
      {isDateError && (
        <TimeErrorModal
          open={isDateError}
          handleClose={() => {
            setIsDateError(false)
            setAvailabilityDates({
              ...availabilityDates,
              startDate: null,
              endDate: null,
            })
          }}
        />
      )}
    </div>
  )
}

export default TutorLastAvailability
