import { Button, Pagination } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

import CustomDatePicker from '../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../contexts/AppContext'
import { useDebounce } from '../../hooks'
import './all-promos.scss'
import AddEditPromo from './components/add-edit-promo/AddEditPromo'
import useAllActivePromos from './components/get-all-promos/useAllActivePromos'
import PromoTable from './components/promo-Table/PromoTable'

export interface PromoFilterI {
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  marketId?: string
  skip?: number | null
  status?: 'ACTIVE' | 'INACTIVE'
}
const defaultPromoFilter: PromoFilterI = {
  startDate: null,
  endDate: null,
  marketId: '',
  skip: null,
  status: 'ACTIVE',
}

const AllPromos = () => {
  const { marketData } = useAppContext()

  const [page, setPage] = useState<number>(1)
  const [addPromo, setAddPromo] = useState(false)
  const [search, setSearch] = useState('')
  const [promoFilters, setPromoFilters] =
    useState<PromoFilterI>(defaultPromoFilter)

  const onDateChange = (value: Dayjs | null | undefined, mode?: string) => {
    setPromoFilters({ ...promoFilters, [mode as string]: value })
  }

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
    setPromoFilters({ ...promoFilters, skip: value })
  }
  const debouncedSearch = useDebounce({
    value: search,
    milliSeconds: 500,
  })

  const prevSearchRef = useRef('')

  if (prevSearchRef.current !== debouncedSearch) {
    setPromoFilters((filters) => ({ ...filters, skip: 1 }))
  }
  prevSearchRef.current = debouncedSearch

  const { allPromos, getAllPromos, totalPages, setAllPromos } =
    useAllActivePromos({
      filterStates: promoFilters,
      debouncedSearch,
    })

  return (
    <div className="container-fluid content">
      <div className="container-fluid">
        <div className="d-flex flex-wrap mt-4">
          {promoFilters.status === 'ACTIVE' ? (
            <>
              <Button
                className="me-3 mt-3 blue-button"
                onClick={() => {
                  setAddPromo(true)
                }}
              >
                Add Promo Code
              </Button>
              <Button
                className="me-3 mt-3 blue-button"
                onClick={() => {
                  setAllPromos([])
                  let tempFilters: PromoFilterI = {
                    ...defaultPromoFilter,
                    status: 'INACTIVE',
                  }
                  setPromoFilters({ ...promoFilters, ...tempFilters })
                }}
              >
                Inactive Promo Code
              </Button>
            </>
          ) : (
            <Button
              className="me-3 mt-3 "
              onClick={() => {
                setAllPromos([])
                let tempFilters: PromoFilterI = {
                  ...defaultPromoFilter,
                  status: 'ACTIVE',
                }
                setPromoFilters({ ...promoFilters, ...tempFilters })
              }}
            >
              Back
            </Button>
          )}
        </div>
        <div className="row mt-2">
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={promoFilters.startDate}
              maxDate={promoFilters.endDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'startDate')
              }}
              placeholder="Select Start Date"
              mode="startDate"
            />
          </div>
          <div className="col-md-3  mt-2">
            <CustomDatePicker
              date={promoFilters.endDate}
              minDate={promoFilters.startDate}
              onChange={onDateChange}
              onClear={() => {
                onDateChange(null, 'endDate')
              }}
              placeholder="Select End Date"
              mode="endDate"
            />
          </div>

          <div className="col-md-3 mt-2 filters-width">
            <MarketDropdown
              placeholder="--Select Market Name--"
              option={marketData}
              value={promoFilters.marketId}
              handleChange={(e) => {
                setPromoFilters({
                  ...promoFilters,
                  marketId: e.target.value,
                })
              }}
            />
          </div>

          <div className=" d-flex col-md-3 col-xl-2  mt-2">
            <span>
              <SearchBar
                className="search-box"
                placeholder="Search..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setPage(1)
                }}
              />
            </span>
            <span
              className="clearSearch mt-2"
              onClick={() => {
                setSearch('')
              }}
            >
              <FaTimes size={20} />
            </span>
          </div>
        </div>
        <div className="mt-2">
          <PromoTable
            allActivePromos={allPromos}
            status={promoFilters.status}
            getAllPromos={getAllPromos}
          />
        </div>
        {allPromos && allPromos?.length > 0 ? (
          <>
            {totalPages > 1 && (
              <div className="mt-2 d-flex justify-content-end">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="mt-2 d-flex justify-content-start">
              {/* <p>Record not found</p> */}
            </div>
          </>
        )}
      </div>
      {addPromo && (
        <AddEditPromo
          open={addPromo}
          onClose={() => {
            setAddPromo(false)
          }}
          onSuccess={getAllPromos}
          mode="Add"
        />
      )}
    </div>
  )
}

export default AllPromos
