import { Parent, ParentReq } from '@/types/parent.type'
import { useEffect, useState } from 'react'

import { useAppContext } from '../../../../contexts/AppContext'
import { Card, CardResponse } from '../../../../types/card.type'
import { api } from '../../../../utils'
import CardForm from './components/add-edit-card/CardForm'
import ExistingCard from './components/existing-card/ExistingCard'
import NewCard from './components/new-card/NewCard'
import './manage-cards.scss'

const ManageCards = () => {
  const { updateAppState } = useAppContext()
  const [mode, setMode] = useState('')
  const [cards, setCards] = useState<Card[]>([])
  const [selectedCard, setSelectedCard] = useState('')
  const [singleParent, setSingleParent] = useState<Parent | null | undefined>()

  const parentId = localStorage.getItem('parentId') as string

  const getCardDetails = async () => {
    updateAppState({ loading: true })
    const res = await api.get<CardResponse>('parent/getCards', {
      parentId: parentId,
    })

    if (res.ok && res.data) {
      const { cards } = res.data.data
      setCards(cards)
    }
    updateAppState({ loading: false })
  }

  const getSingleParent = async () => {
    updateAppState({ loading: true })
    const singleParentReq = await api.get<ParentReq>(
      'admin/getParentById/' + parentId,
    )
    const { data } = singleParentReq
    if (data) {
      setSingleParent(data.data)
    }
    console.log(singleParentReq)
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getSingleParent()
    getCardDetails()
  }, [])

  return (
    <div className="card-name">
      <div className="container-fluid">
        {mode === 'add' || mode === 'edit' ? (
          <>
            <CardForm
              mode={mode}
              parentId={parentId}
              setMode={setMode}
              cards={cards}
              selectedCard={selectedCard}
              singleParent={singleParent}
              onClose={() => {
                setMode('')
                getCardDetails()
              }}
            />
          </>
        ) : (
          <>
            <h2 className="parent-name">
              {localStorage.getItem('parentName')}&nbsp;
              <strong>Wallet</strong>
            </h2>
            <div className="col-md-12">
              <h4 className="card-heading">Select default payment method.</h4>
              <div className="row">
                {cards.map((card) => {
                  return (
                    <ExistingCard
                      key={card._id}
                      card={card}
                      setMode={setMode}
                      parentId={parentId}
                      getCardDetails={getCardDetails}
                      setSelectedCard={setSelectedCard}
                    />
                  )
                })}
              </div>
              <div className="row">
                <NewCard setMode={setMode} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ManageCards
