import { FC } from 'react'

import { TutorElement } from '../../types/changeRequest.type'
import { Tutor } from '../../types/tutor.type'

interface AdditionalNoteProps {
  singleTutor?: Tutor | null | undefined
  pendingTutor?: TutorElement
  path?: string
  page?: 'session' | 'tutor'
}

const AdditionalNote: FC<AdditionalNoteProps> = ({
  singleTutor,
  page,
  path,
  pendingTutor,
}) => {
  return (
    <div>
      <div className="heading-container row-margin">Additional Notes</div>
      {!!singleTutor?.tutor.adminAdditionalNotes?.length &&
        page === 'session' && (
          <div className="col-sm-12">
            <b>Additional Note</b>
          </div>
        )}
      <div className="row view-rows mt-0 view-detail-font">
        <div
          className={
            page === 'session'
              ? 'col-sm-6 col-lg-6 col-md-6 col-xs-12 session-notes'
              : 'col-sm-12 col-lg-12 col-md-12 col-xs-12'
          }
        >
          <div className="row mt-0">
            <div className="col-sm-12">
              {path !== 'pendingProfile' ? (
                <>
                  {!!singleTutor?.tutor.adminAdditionalNotes?.length && (
                    <>
                      {singleTutor?.tutor.adminAdditionalNotes?.map(
                        (data, index) => {
                          return (
                            <ul className="additional-notes-list" key={index}>
                              <li
                                className={
                                  page === 'session' ? 'session-notes' : ''
                                }
                              >
                                {data}
                              </li>
                            </ul>
                          )
                        },
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {!!pendingTutor?.userId &&
                    !!pendingTutor?.userId.tutor &&
                    !!pendingTutor?.userId.tutor.adminAdditionalNotes
                      .length && (
                      <>
                        {pendingTutor?.userId.tutor.adminAdditionalNotes?.map(
                          (data, index) => {
                            return (
                              <ul className="additional-notes-list" key={index}>
                                <li
                                  className={
                                    page === 'session' ? 'session-notes' : ''
                                  }
                                >
                                  {data}
                                </li>
                              </ul>
                            )
                          },
                        )}
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdditionalNote
