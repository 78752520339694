import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import useSingleTutor from '../../../../components/get-single-tutor/GetSingleTutor'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledTextArea from '../../../../components/styled-textfield/StyledTextArea'
import { useAppContext } from '../../../../contexts/AppContext'
import { Tutor } from '../../../../types/tutor.type'
import { api } from '../../../../utils'
import { ModalProps } from '../../../parent/components/parent-table/ParentTable'
import { TutorModalsProps } from './../tutor-table/tutor-table.type'
import './additional-note.scss'

interface FormValues {
  additionalNote: string
}

const schema = Yup.object({
  additionalNote: Yup.string().required('Additional Note is required'),
}).required()

interface AdditionalNoteProps {
  open: TutorModalsProps
  setOpen: React.Dispatch<React.SetStateAction<ModalProps>>
  modalName: string
  tutorId?: string
  tutorData: Tutor[] | null | undefined
}

const AdditionalNote: FC<AdditionalNoteProps> = ({
  open,
  setOpen,
  modalName,
  tutorId,
  tutorData,
}) => {
  const { updateAppState } = useAppContext()

  const handleClose = () => {
    setOpen({ ...open, [modalName]: false })
  }

  const singleTutor = useSingleTutor({
    tutorData,
    tutorId,
    dependant: open.additionalNote,
  })

  const defaultValues: FormValues = {
    additionalNote: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async (values: FormValues) => {
    updateAppState({ loading: true })
    const { additionalNote } = values
    const formData = new FormData()

    let tempArray = []
    tempArray = singleTutor?.tutor.adminAdditionalNotes
      ? singleTutor.tutor.adminAdditionalNotes
      : []

    tempArray.push(additionalNote)
    formData.append('adminAdditionalNotes', JSON.stringify(tempArray))
    const res = await api.put(`/admin/editProfileTutor/${tutorId}`, formData)

    const { data, status } = res
    if (res.ok) {
      handleClose()
    }
    updateAppState({ loading: false })
  }

  return (
    <ModalWrapper
      open={open.additionalNote}
      handleClose={handleClose}
      title="Add Additional Note"
      wide
    >
      <form className="w-auto" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row align-items-baseline">
          <div className="col-sm-3 text-left">Additional Note</div>
          <Controller
            name="additionalNote"
            control={control}
            render={({ field }) => (
              <StyledTextArea
                {...field}
                minRows={3}
                placeholder="Enter Additional Note"
                isError={!!errors.additionalNote}
                className="col-sm-9"
                errorMessage={errors.additionalNote?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="d-flex justify-content-end mt-5">
          <Button
            disabled={!isValid}
            color={!isValid ? 'info' : 'success'}
            type="submit"
            className="button-width"
          >
            Add
          </Button>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default AdditionalNote
