import { TableCell, TableRow, Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import { toast } from 'react-hot-toast'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'

import { api } from '../../../../../utils'
import { Admin } from '../../admin.type'
import AddEditAdmin from '../add-edit-admin/AddEditAdmin'
import ConfirmAdminDelete from '../confirm-admin-delete/ConfirmAdminDelete'

interface AdminTableBodyI {
  admin: Admin
  getAllAdmins: () => Promise<void>
}
const size = 15

const AdminTableBody: FC<AdminTableBodyI> = ({ getAllAdmins, admin }) => {
  const [editAdmin, setEditAdmin] = useState(false)
  const [deleteAdmin, setDeleteAdmin] = useState(false)

  const deleteFunc = async () => {
    const formData = new FormData()
    formData.append('isDeleted', 'true')
    const res = await api.put(`admin/editAdminUser/${admin._id}`, formData)
    if (res && res.ok) {
      toast.success('Deleted Successfully!', { id: 'global' })
      setDeleteAdmin(false)
      getAllAdmins()
    }
  }
  return (
    <>
      <TableRow className="table-align-center">
        <TableCell>
          {admin?.firstName}&nbsp;{admin?.lastName}
        </TableCell>
        <TableCell>{admin.email}</TableCell>
        <TableCell>{admin.isSuperAdmin ? 'Yes' : 'No'}</TableCell>
        <TableCell>
          <span className="d-flex align-items-center justify-content-center">
            <Tooltip title="Edit">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={() => {
                  setEditAdmin(true)
                }}
              >
                <FaPencilAlt size={size} />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span
                className="ms-3 me-3 menu-icon text-center"
                onClick={() => {
                  setDeleteAdmin(true)
                }}
              >
                <FaTrashAlt size={size} />
              </span>
            </Tooltip>
          </span>
        </TableCell>
      </TableRow>
      {editAdmin && (
        <AddEditAdmin
          mode="Edit"
          open={editAdmin}
          onClose={() => {
            setEditAdmin(false)
          }}
          editData={admin}
          onSuccess={getAllAdmins}
        />
      )}
      {deleteAdmin && (
        <ConfirmAdminDelete
          open={deleteAdmin}
          onClose={() => {
            setDeleteAdmin(false)
          }}
          onYes={deleteFunc}
        />
      )}
    </>
  )
}

export default AdminTableBody
