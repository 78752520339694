import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import { useAppContext } from '../../../contexts/AppContext'
import { ConstantResponse } from '../../../types/constants.type'
import { api } from '../../../utils'
import './background-check.scss'
import EditDescription from './components/edit-description/EditDescription'

const BackGroundCheck = () => {
  const { updateAppState } = useAppContext()

  const [authorization, setAuthorization] = useState('')
  const [disclosure, setDisclosure] = useState('')
  const [FCRA, setFCRA] = useState('')
  const [backGroundData, setbackGroundData] = useState('')
  const [buttonType, setButtonType] = useState<
    'Authorization' | 'Disclosure' | 'FCRA'
  >('Authorization')

  const [editBg, setEditBg] = useState(false)

  const getConstants = async () => {
    updateAppState({ loading: true })
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      updateAppState({ loading: false })
      const {
        backgroundCheckDisclosure,
        ackBackgroundCheck,
        fairCreditSummary,
      } = constantsRes.data.data

      setAuthorization(ackBackgroundCheck)
      setDisclosure(backgroundCheckDisclosure)
      setFCRA(fairCreditSummary)
      setbackGroundData(
        buttonType === 'Authorization'
          ? ackBackgroundCheck
          : buttonType === 'Disclosure'
          ? backgroundCheckDisclosure
          : buttonType === 'FCRA'
          ? fairCreditSummary
          : '',
      )
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  useEffect(() => {
    setbackGroundData(
      buttonType === 'Authorization'
        ? authorization
        : buttonType === 'Disclosure'
        ? disclosure
        : buttonType === 'FCRA'
        ? FCRA
        : '',
    )
  }, [buttonType])

  const buttonArray: ['Authorization', 'Disclosure', 'FCRA'] = [
    'Authorization',
    'Disclosure',
    'FCRA',
  ]

  return (
    <div className="container mt-5">
      <div className="row mb-5">
        <div className="col-sm-12 col-xs-12 col-md-12">
          {buttonArray.map((button) => {
            return (
              <Button
                key={button}
                className={`upper-button ${
                  buttonType === button ? 'upper-button-active' : ''
                }`}
                onClick={() => {
                  setButtonType(button)
                }}
              >
                {button}
              </Button>
            )
          })}
        </div>
      </div>
      <div className="row mb mat50">
        <div className="col-sm-10">
          <h1 className="text-center">{buttonType}</h1>
        </div>
        <div className="col-sm-2 text-end">
          <Button
            onClick={() => {
              setEditBg(true)
            }}
          >
            Edit {buttonType}
          </Button>
        </div>
      </div>
      <div className="row">
        <div
          className=" col-sm-12 text-setting changeColorAnchor"
          dangerouslySetInnerHTML={{ __html: backGroundData }}
        />
      </div>
      {editBg && (
        <EditDescription
          bgData={backGroundData}
          buttonType={buttonType}
          open={editBg}
          onClose={() => {
            setEditBg(false)
          }}
          onSuccess={getConstants}
        />
      )}
    </div>
  )
}

export default BackGroundCheck
