import { TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useState } from 'react'

import {
  Booking,
  bookingStates,
} from '../../../../../../../types/getBooking.type'
import BookingDetailModal from '../../../../../../parent/components/booking-detail-modal/BookingDetailModal'
import AddPromoCodeModal from '../../../../../../parent/components/booking-detail-modal/components/add-promo-code/AddPromoCodeModal'
import './view-sessions-table.scss'

dayjs.extend(utc)
dayjs.extend(tz)

interface ViewSessionTableBodyProps {
  booking: Booking
  getTutorSessions?: () => Promise<void>
}

const ViewSessionTableBody: FC<ViewSessionTableBodyProps> = ({
  booking,
  getTutorSessions,
}) => {
  const [bookingModal, setBookingModal] = useState(false)
  const [addPromoCodeModal, setAddPromoCodeModal] = useState(false)

  const startTime1 = dayjs(booking.startTime)
    .tz(booking.parentTimezoneOffsetZone)
    .format('LLL')
  const endTime1 = dayjs(booking.endTime)
    .tz(booking.parentTimezoneOffsetZone)
    .format('LLL')

  let status = booking.status.replace(/[_-]/g, ' ').toLowerCase()
  status = status.replace('cancelled', 'canceled').toLowerCase()
  if (booking.noResponseByParent) {
    status = 'no response by parent'
  }

  if (booking.noResponseByTutor) {
    status = 'no response by tutor'
  }

  const bookingState: bookingStates = {
    pageType: 'detail',
    source: 'parentDetail',
    actionType: 'activeBooking',
  }

  const onAddPromoCode = () => {
    setBookingModal(false)
    setAddPromoCodeModal(true)
  }

  return (
    <>
      <TableRow
        className="cursor-pointer tb-p0"
        onClick={() => {
          setBookingModal(true)
        }}
      >
        <TableCell>
          {booking.startTime ? dayjs(startTime1).format('MMM. D, YYYY') : '-'}
          &nbsp;
          {booking.startTime ? dayjs(startTime1).format('h:mmA') : '-'}
          {booking.endTime ? '-' + dayjs(endTime1).format('h:mmA') : '-'}
          &nbsp;
          {booking.parentAbbreviation}
        </TableCell>
        <TableCell>{booking.studentId.firstName}</TableCell>
        <TableCell>
          {booking.parentId?.firstName} {booking.parentId?.lastName}
        </TableCell>
        <TableCell>{status ? status : '-'}</TableCell>
      </TableRow>
      {bookingModal && (
        <BookingDetailModal
          key={booking + ''}
          booking={booking}
          open={bookingModal}
          bookingState={bookingState}
          path="tutor"
          onClose={() => {
            setBookingModal(false)
          }}
        />
      )}
      {addPromoCodeModal && (
        <AddPromoCodeModal
          booking={booking}
          open={addPromoCodeModal}
          onClose={() => {
            setAddPromoCodeModal(false)
            setBookingModal(true)
            getTutorSessions?.()
          }}
        />
      )}
    </>
  )
}

export default ViewSessionTableBody
