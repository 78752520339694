import { Button } from '@mui/material'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC } from 'react'

import { StyledRow } from '../../../../components/custom-bordered-row/StyledRow'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import { Disputes } from '../../hooks/disputes.types'
import './dispute-details.scss'

dayjs.extend(utc)
dayjs.extend(tz)

interface DisputeDetailsI {
  open: boolean
  onClose: () => void
  dispute: Disputes
}

const DisputeDetails: FC<DisputeDetailsI> = ({ dispute, onClose, open }) => {
  let totalAmount = 0
  let originalStartTime1: any
  let oAbb: any
  let sAbb: any
  let resolvedAt1
  let originalEndTime1
  let sessionEndTime1
  let sessionStartTime1
  if (
    dispute.bookingId &&
    dispute.bookingId.payments &&
    dispute.bookingId.payments.amountCharged
  ) {
    totalAmount = totalAmount + dispute.bookingId.payments.amountCharged
  }
  if (dispute.bookingId.originalStartTime) {
    originalStartTime1 = dayjs(dispute.bookingId.originalStartTime)
      .tz(dispute.bookingId.parentTimezoneOffsetZone)
      .format('LLL')
    oAbb = dayjs(dispute.bookingId.originalStartTime)
      .tz(dispute.bookingId.parentTimezoneOffsetZone)
      .format('z')
  }
  if (dispute.bookingId.originalEndTime) {
    originalEndTime1 = dayjs(dispute.bookingId.originalEndTime)
      .tz(dispute.bookingId.parentTimezoneOffsetZone)
      .format('LLL')
  }
  if (dispute.bookingId.sessionStartTime) {
    sessionStartTime1 = dayjs(dispute.bookingId.sessionStartTime)
      .tz(dispute.bookingId.parentTimezoneOffsetZone)
      .format('LLL')
    sAbb = dayjs(dispute.bookingId.sessionStartTime)
      .tz(dispute.bookingId.parentTimezoneOffsetZone)
      .format('z')
  }
  if (dispute.bookingId.sessionEndTime) {
    sessionEndTime1 = dayjs(dispute.bookingId.sessionEndTime)
      .tz(dispute.bookingId.parentTimezoneOffsetZone)
      .format('LLL')
  }
  if (
    dispute.bookingId &&
    dispute.bookingId.payments &&
    dispute.bookingId.payments.partialAmountLeft
  ) {
    totalAmount = totalAmount + dispute.bookingId.payments.partialAmountLeft
  }
  if (
    dispute.bookingId &&
    dispute.bookingId.parentTimezoneOffsetZone &&
    dispute.resolvedAt
  ) {
    resolvedAt1 = dayjs(dispute.resolvedAt)
      .tz(dispute.bookingId.parentTimezoneOffsetZone)
      .format('LLL')
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="Dispute Details"
      wide
    >
      <StyledRow head field="Field Name" description="Description" />
      <StyledRow
        field="Dispute ID"
        description={dispute.disputeId ? dispute.disputeId : '-'}
      />
      <StyledRow
        field="Parent Name"
        description={
          dispute.parentId && dispute.parentId.firstName
            ? dispute.parentId.firstName + ' ' + dispute.parentId.lastName
            : '-'
        }
      />
      <StyledRow
        field="Child Name"
        description={
          dispute.studentId && dispute.studentId.firstName
            ? dispute.studentId.firstName + ' ' + dispute.studentId.lastName
            : '-'
        }
      />
      <StyledRow
        field="Tutor Name"
        description={
          dispute.tutorId && dispute.tutorId.firstName
            ? dispute.tutorId.firstName + ' ' + dispute.tutorId.lastName
            : '-'
        }
      />
      <StyledRow
        field="Projected Session Date and Start and End Times"
        description={
          <>
            {dispute.bookingId &&
            dispute.bookingId.originalStartTime &&
            dispute.bookingId.originalEndTime
              ? dayjs(originalStartTime1).format('MM-DD-YYYY hh:mmA') +
                (originalEndTime1
                  ? ' - ' + dayjs(originalEndTime1).format('hh:mmA')
                  : '')
              : '-'}
            &nbsp;
            {dispute.bookingId && dispute.bookingId.parentAbbreviation
              ? oAbb
              : ''}
          </>
        }
      />
      <StyledRow
        field="Actual Session Date and Start and End Times"
        description={
          <>
            {dispute.bookingId && dispute.bookingId.sessionStartTime
              ? dayjs(sessionStartTime1).format('MM-DD-YYYY hh:mmA') +
                (sessionEndTime1
                  ? ' - ' + dayjs(sessionEndTime1).format('hh:mmA')
                  : '')
              : '-'}
            &nbsp;
            {dispute.bookingId && dispute.bookingId.parentAbbreviation
              ? sAbb
              : ''}
          </>
        }
      />
      <StyledRow
        field="Location of Session"
        description={
          <>
            {dispute.bookingId &&
            dispute.bookingId.locationDetails &&
            dispute.bookingId.locationDetails ? (
              <>
                {dispute.bookingId.locationDetails.addressLine1
                  ? dispute.bookingId.locationDetails.addressLine1
                  : ''}
                {dispute.bookingId.locationDetails.addressLine2
                  ? ', ' + dispute.bookingId.locationDetails.addressLine2
                  : ''}
                {dispute.bookingId.locationDetails.city
                  ? ', ' + dispute.bookingId.locationDetails.city
                  : ''}
                {dispute.bookingId.locationDetails.state
                  ? ', ' + dispute.bookingId.locationDetails.state
                  : ', ' + dispute.bookingId.locationDetails.state}
                {dispute.bookingId.locationDetails.zipCode
                  ? ', ' + dispute.bookingId.locationDetails.zipCode
                  : ''}
              </>
            ) : (
              <></>
            )}
          </>
        }
      />
      <StyledRow
        field="Subjects"
        description={
          <>
            {dispute.bookingId && dispute.bookingId.subjects
              ? dispute.bookingId.subjects?.map((subject) =>
                  subject.subcategories.map((sub, index) => {
                    return (
                      <span key={index}>
                        {index === 0 ? '' : ' ,'}
                        {sub.name}
                      </span>
                    )
                  }),
                )
              : ''}
          </>
        }
      />
      <StyledRow
        field="Projected Session Cost"
        description={
          <>
            {dispute.bookingId &&
            dispute.bookingId.payments &&
            dispute.bookingId.payments.projectedAmount &&
            dispute.bookingId.payments.projectedAmount >= 0
              ? '$' + dispute.bookingId.payments.projectedAmount.toFixed(2)
              : '-'}
          </>
        }
      />
      <StyledRow
        field="Actual Session Cost"
        description={<>{'$' + totalAmount.toFixed(2)}</>}
      />
      <StyledRow
        field="Feedback left by Tutor"
        description={
          dispute.bookingId &&
          dispute.bookingId.sessionFeedback &&
          dispute.bookingId.sessionFeedback.comments
            ? dispute.bookingId.sessionFeedback.comments
            : '-'
        }
      />
      <StyledRow
        field="Documents sent with tutor feedback"
        description={
          dispute.bookingId &&
          dispute.bookingId.sessionFeedback &&
          !!dispute.bookingId.sessionFeedback.documents.length ? (
            <>
              {dispute.bookingId.sessionFeedback.documents.map(
                (data, index) => {
                  return (
                    <a href={data.url} key={index}>
                      {data.name}
                    </a>
                  )
                },
              )}
            </>
          ) : (
            <>-</>
          )
        }
      />
      {dispute.createdAt && (
        <StyledRow
          field="Dispute Date and Time"
          description={
            <>
              {dispute.createdAt
                ? dayjs(dispute.createdAt).utc().format('MM-DD-YYYY hh:mmA')
                : '-'}
              &nbsp;
              {dispute.bookingId && dispute.bookingId.parentAbbreviation
                ? dispute.bookingId.parentAbbreviation
                : ''}
            </>
          }
        />
      )}
      {dispute.reason && (
        <StyledRow
          field="Dispute Reason"
          description={dispute.reason ? dispute.reason : '-'}
        />
      )}
      <StyledRow
        field="Additional Note"
        description={
          dispute.additionalDetails ? dispute.additionalDetails : '-'
        }
      />
      <StyledRow
        field="Resolution Date"
        description={
          resolvedAt1 ? dayjs(resolvedAt1).format('MM-DD-YYYY') : '-'
        }
      />
      <StyledRow
        field="Resolution Method"
        description={dispute.method ? dispute.method : '-'}
      />
      <StyledRow
        field="Resolve Comment"
        description={dispute.comment ? dispute.comment : ''}
      />
      <StyledRow
        field="Amount to Charge"
        description={dispute.amount >= 0 ? '$' + dispute.amount : '-'}
      />
      <StyledRow
        field="Comments"
        description={
          dispute.comments && !!dispute.comments.length ? (
            <>
              {dispute.comments.map((data, index) => {
                return (
                  <div key={index} className="comment-border">
                    {data.comment}
                  </div>
                )
              })}
            </>
          ) : (
            <>-</>
          )
        }
      />{' '}
      {dispute.adminId && (
        <StyledRow
          field="Admin Email"
          description={
            dispute.adminId && dispute.adminId.email
              ? dispute.adminId.email
              : '-'
          }
        />
      )}
      <div className="col-md-10 col-sm-10 text-end mt-4">
        <Button color="success" className="w-25" onClick={onClose}>
          OK
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default DisputeDetails
