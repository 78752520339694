import { useEffect, useState } from 'react'

import { useAppContext } from '../../../contexts/AppContext'
import { api } from '../../../utils'
import { ParamType } from '../all-sessions/AllSessions'
import { Booking, GetSessionsResponse } from '../sessions.type'

interface UseAllSessionsProps {
  filterStates: ParamType
  debouncedSearch?: string
}

const PAGE_LIMIT = 10

export const useAllSessions = ({
  filterStates,
  debouncedSearch,
}: UseAllSessionsProps) => {
  const [allSessions, setAllSessions] = useState<Booking[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const { updateAppState } = useAppContext()

  const getAllSessions = async () => {
    updateAppState({ loading: true })
    const { page, status, startDate, endDate, marketId } = filterStates
    const skip = (page - 1) * PAGE_LIMIT
    const queryObj: { [key: string]: any } = {
      limit: 10,
      skip,
      type: status,
      withCount: true,
    }
    if (startDate)
      queryObj.startDate = startDate.format().split('T')[0] + 'T00:00:00.000Z'

    if (endDate)
      queryObj.endDate = endDate.format().split('T')[0] + 'T23:59:59.999Z'

    if (marketId && marketId !== 'none') queryObj.marketId = marketId
    if (debouncedSearch) queryObj.searchParam = debouncedSearch
    const res = await api.get<GetSessionsResponse>(
      '/admin/getBookings',
      queryObj,
    )
    if (res.ok && res.data) {
      const { bookings, count } = res.data.data
      setAllSessions(bookings)
      setTotalPages(Math.ceil(count / PAGE_LIMIT))
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getAllSessions()
  }, [filterStates, debouncedSearch])

  return { allSessions, totalPages, getAllSessions }
}
