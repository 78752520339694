import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { Dayjs } from 'dayjs'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { ReservationExpiration } from './../AllBhExpiration.types'
import ExpirationTableBody from './ExpirationTableBody'

interface ExpirationTableI {
  reservationExpiration: ReservationExpiration[]
  getExpirations: () => Promise<void>
  disableDates?: Dayjs[]
}
const ExpirationTable: FC<ExpirationTableI> = ({
  reservationExpiration,
  getExpirations,
  disableDates,
}) => {
  return (
    <div className="col-sm-12 col-md-12">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              className="heading-table"
              sx={{ '&:hover': { background: 'none' } }}
            >
              <TableCell>Reservation Date</TableCell>
              <TableCell>Expiration Days</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!reservationExpiration.length &&
              reservationExpiration.map((reservation) => {
                return (
                  <ExpirationTableBody
                    getExpirations={getExpirations}
                    reservation={reservation}
                    key={reservation._id}
                    disableDates={disableDates}
                  />
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ExpirationTable
