import { SelectChangeEvent } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useState } from 'react'

import CustomDatePicker from '../../../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../../../components/styled-dropdown/MarketDropdown'
import { SearchBar } from '../../../../components/styled-textfield/SearchBar'
import { useAppContext } from '../../../../contexts/AppContext'
import { useDebounce } from '../../../../hooks'
import PendingProfileTable from './components/PendingProfileTable/PendingProfileTable'
import usePendingProfiles from './components/get-pending-profiles/GetPendingProfiles'

export interface PendingProfileFilterStates {
  marketId?: string | null
  searchParam?: string | null
  startDate?: Dayjs | null
  endDate?: Dayjs | null
}

const PendingProfileFilterInit: PendingProfileFilterStates = {
  marketId: '',
  searchParam: '',
  endDate: null,
  startDate: null,
}

const PendingProfileUpdate = () => {
  const { marketData } = useAppContext()
  const [filterStates, setFilterStates] = useState<PendingProfileFilterStates>(
    PendingProfileFilterInit,
  )
  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce({ value: search, milliSeconds: 500 })

  const onDateChange = (value: Dayjs | null | undefined, mode?: string) => {
    setFilterStates({ ...filterStates, [mode ? mode : '']: value })
  }

  const marketChange = (event: SelectChangeEvent) => {
    setFilterStates({ ...filterStates, marketId: event.target.value })
  }

  const { allPendingProfiles, totalPages, getPendingProfiles } =
    usePendingProfiles({
      debouncedSearch,
      filterStates,
    })

  return (
    <div className="main">
      <div className="container-fluid content">
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col-md-3 mt-2">
              <CustomDatePicker
                date={filterStates?.startDate}
                onChange={onDateChange}
                placeholder="Select Start Date"
                mode="startDate"
                onClear={() => onDateChange(null, 'startDate')}
              />
            </div>
            <div className="col-md-3 mt-2">
              <CustomDatePicker
                date={filterStates?.endDate}
                onChange={onDateChange}
                placeholder="Select End Date"
                mode="endDate"
                onClear={() => onDateChange(null, 'endDate')}
              />
            </div>

            <div className="col-md-3 mt-2">
              <MarketDropdown
                option={marketData}
                value={filterStates?.marketId}
                handleChange={marketChange}
                placeholder="--Select Market Name--"
              />
            </div>
            <div className="col-md-3 mt-2">
              <SearchBar
                placeholder="Search..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </div>
          <div className="mt-4">
            <PendingProfileTable
              allPendingProfiles={allPendingProfiles}
              getPendingProfiles={getPendingProfiles}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingProfileUpdate
