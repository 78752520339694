export type FilterButtonTypes =
  | 'ALL'
  | 'NEW'
  | 'PENDING_RESCHEDULES'
  | 'ACCEPTED'
  | 'REJECTED'
  | 'IN_PROGRESS'
  | 'FINISHED'
  | 'CANCELLED'
  | 'PAYMENT_FAILED'
  | 'PARTIAL_PAYMENT'
  | 'COMPLETED'

type FilterButtonTitles =
  | 'Rejected'
  | 'All'
  | 'New Session Request'
  | 'Pending Reschedules'
  | 'Accepted'
  | 'Rejected'
  | 'In Progress'
  | 'Finished'
  | 'Canceled'
  | 'Payment Failed'
  | 'Partial Payment'
  | 'Completed'

export const filterButtons: {
  type: FilterButtonTypes
  title: FilterButtonTitles
}[] = [
  { title: 'All', type: 'ALL' },
  { title: 'New Session Request', type: 'NEW' },
  { title: 'Pending Reschedules', type: 'PENDING_RESCHEDULES' },
  { title: 'Accepted', type: 'ACCEPTED' },
  { title: 'Rejected', type: 'REJECTED' },
  { title: 'In Progress', type: 'IN_PROGRESS' },
  { title: 'Finished', type: 'FINISHED' },
  { title: 'Canceled', type: 'CANCELLED' },
  { title: 'Payment Failed', type: 'PAYMENT_FAILED' },
  { title: 'Partial Payment', type: 'PARTIAL_PAYMENT' },
  { title: 'Completed', type: 'COMPLETED' },
]

export const headings = [
  'Parent Name',
  'Tutor Name',
  'Subject',
  'Session Duration',
  'Location',
  'Session Date and Time',
  'Proposed Date and Time',
  'Projected Cost',
  'Action',
]
