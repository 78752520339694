import { Button, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FC, useState } from 'react'
import {
  FaLock,
  FaPencilAlt,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTrashAlt,
  FaUnlock,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import routes from '../../../../common/routes'
import BlockModal from '../../../../components/modals/block-modal/BlockModal'
import DeleteModal from '../../../../components/modals/delete-modal/DeleteModal'
import UnblockModal from '../../../../components/modals/unblock-modal/UnblockModal'
import { Parent } from '../../../../types/parent.type'
import { api } from '../../../../utils'
import AddChild from '../add-child/AddChild'
import AdditionalNote from '../additional-note/AdditionalNote'
import { headings } from '../data'
import ParentViewDetails from '../view-details/ParentViewDetails'
import './parent-table.scss'

const size = 15
interface ParentTableProps {
  parentData?: Parent[] | null
  buttonType?: string | null
  getParentDetails: () => Promise<void>
  handleSortBy?: (value: string) => void
  sortBy: string
}
export interface ModalProps {
  addChild?: boolean
  viewDetail?: boolean
  additionalNote?: boolean
  editChild?: boolean
  block?: boolean
  unblock?: boolean
  delete?: boolean
  unRejectTutor?: boolean
}
const initialValues = {
  addChild: false,
  viewDetail: false,
  additionalNote: false,
  block: false,
  unblock: false,
  editChild: false,
  delete: false,
}
interface HandleModalParams {
  e?: any
  icon?: string
  parent: Parent
}
const ParentTable: FC<ParentTableProps> = ({
  parentData,
  buttonType,
  getParentDetails,
  handleSortBy,
  sortBy,
}) => {
  const navigate = useNavigate()
  const [modals, setModals] = useState<ModalProps>(initialValues)
  const [modalName, setModalName] = useState('')
  const [parentId, setParentId] = useState('')
  const [singleParent, setSingleParent] = useState<Parent | null>(null)

  const modalHandle = (data: HandleModalParams) => {
    setParentId(data.parent._id)
    setSingleParent(data.parent)
    const parentName = data.parent.firstName + ' ' + data.parent.lastName
    localStorage.setItem('parentId', data.parent._id)
    localStorage.setItem('parentName', parentName)

    if (data.e?.target.name === 'viewChild')
      navigate('/pages/' + routes.viewChild + '/' + data.parent._id)
    if (data.e?.target.name === 'manageCards')
      navigate('/pages/' + routes.cardDetail)
    if (data.icon === 'edit') navigate('/pages/' + routes.editParent)
    setModals({ ...modals, [data.icon ? data.icon : data.e.target.name]: true })
    setModalName(data.icon ? data.icon : data.e.target.name)
  }

  const handleBlockUnblockParent = () => {
    getParentDetails()
  }

  const handleDeleteParent = async () => {
    const res = await api.delete(
      'admin/deleteUser/' + localStorage.getItem('parentId'),
    )
    if (res.ok) {
      handleDeleteClose()
      getParentDetails()
    }
  }

  const handleDeleteClose = () => {
    setModals({ ...open, ['delete']: false })
  }

  let userName = ''

  if (parentData && parentId) {
    const parent = parentData.find((parent) => parent._id === parentId)
    if (parent) {
      userName = parent.userName
    }
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ '&:hover': { background: 'none' } }}>
              {headings.map((heading) => {
                return (
                  <TableCell key={heading}>
                    {heading}
                    {heading === 'Name' ? (
                      <>
                        {sortBy === '' ? (
                          <span
                            onClick={() => {
                              handleSortBy?.('A-Z')
                            }}
                          >
                            <FaSort />
                          </span>
                        ) : sortBy === 'A-Z' ? (
                          <span
                            onClick={() => {
                              handleSortBy?.('Z-A')
                            }}
                          >
                            <FaSortDown />
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              handleSortBy?.('A-Z')
                            }}
                          >
                            <FaSortUp />
                          </span>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {parentData?.map((data) => (
              <TableRow key={data._id} sx={{ verticalAlign: 'baseline' }}>
                <TableCell>
                  {data.firstName ? data.firstName : ''}&nbsp;
                  {data.lastName ? data.lastName : ''}
                </TableCell>
                <TableCell>{data.userName ? data.userName : '-'}</TableCell>
                <TableCell>
                  {data.marketId && data.marketId.marketName
                    ? data.marketId.marketName
                    : '-'}
                </TableCell>
                <TableCell>
                  <span className="d-flex flex-wrap align-items-center">
                    {!data.isDeleted && !data.isBlocked && (
                      <Tooltip title="Block">
                        <span
                          className="ms-3 me-3 menu-icon"
                          onClick={(e) => {
                            modalHandle({ parent: data, icon: 'block' })
                          }}
                        >
                          <FaLock size={size} />
                        </span>
                      </Tooltip>
                    )}
                    {!data.isDeleted && data.isBlocked && (
                      <Tooltip title="Unblock">
                        <span
                          className="ms-3 me-3 menu-icon"
                          onClick={(e) => {
                            modalHandle({ parent: data, icon: 'unblock' })
                          }}
                        >
                          <FaUnlock size={size} />
                        </span>
                      </Tooltip>
                    )}
                    {buttonType !== 'BLOCKED' && !data.isDeleted && (
                      <Tooltip title="Edit">
                        <span
                          className="ms-3 me-3 menu-icon"
                          onClick={(e) => {
                            modalHandle({ parent: data, icon: 'edit' })
                          }}
                        >
                          <FaPencilAlt size={size} />
                        </span>
                      </Tooltip>
                    )}
                    {!data.isDeleted && (
                      <Tooltip title="Delete">
                        <span
                          className="ms-3 me-3 menu-icon"
                          onClick={(e) => {
                            modalHandle({ parent: data, icon: 'delete' })
                          }}
                        >
                          <FaTrashAlt size={size} />
                        </span>
                      </Tooltip>
                    )}
                    {!data.isDeleted && (
                      <Button
                        variant="text"
                        className="ms-1 me-1 mt-1"
                        name="addChild"
                        onClick={(e) => {
                          modalHandle({ e, parent: data })
                        }}
                      >
                        Add Child
                      </Button>
                    )}

                    <Button
                      variant="text"
                      className="ms-1 me-1 mt-1"
                      name="viewChild"
                      onClick={(e) => {
                        modalHandle({ e, parent: data })
                      }}
                    >
                      Show Child
                    </Button>
                    <Button
                      variant="text"
                      className="ms-1 me-1 mt-1"
                      name="viewDetail"
                      onClick={(e) => {
                        modalHandle({ e, parent: data })
                      }}
                    >
                      View Detail
                    </Button>

                    {!!(
                      buttonType === 'COMPLETE' || buttonType === 'INCOMPLETE'
                    ) && (
                      <Button
                        variant="text"
                        className="ms-1 me-1 mt-1"
                        name="manageCards"
                        onClick={(e) => {
                          modalHandle({ e, parent: data })
                        }}
                      >
                        Manage Cards
                      </Button>
                    )}
                    {!data.isDeleted && (
                      <Button
                        variant="text"
                        className="ms-1 me-1 mt-1"
                        name="additionalNote"
                        onClick={(e) => {
                          modalHandle({ e, parent: data })
                        }}
                      >
                        Additional Note
                      </Button>
                    )}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {modals.addChild && (
        <AddChild
          open={modals}
          setOpen={setModals}
          key={modals.addChild + 'addChild'}
          modalName={modalName}
          parentId={parentId}
          mode="add"
        />
      )}

      {modals.additionalNote && (
        <AdditionalNote
          open={modals}
          key={modals.additionalNote + 'AdditionalNote'}
          setOpen={setModals}
          modalName={modalName}
          parentId={parentId}
          singleParent={singleParent}
        />
      )}

      {modals.viewDetail && (
        <ParentViewDetails
          open={modals.viewDetail}
          handleClose={() => {
            setModals({ ...modals, viewDetail: false })
          }}
          key={modals.viewDetail + 'ViewDetails'}
          parentId={parentId}
          singleParent={singleParent}
          setSingleParent={setSingleParent}
          onRefetchData={getParentDetails}
        />
      )}

      {modals.unblock && (
        <UnblockModal
          open={modals}
          setOpen={setModals}
          modalName={modalName}
          key={modals.unblock + 'Unblock'}
          userId={parentId}
          role="Parent"
          onUnblock={handleBlockUnblockParent}
          name={userName}
        />
      )}

      {modals.block && (
        <BlockModal
          open={modals}
          setOpen={setModals}
          modalName={modalName}
          key={modals.block + 'Block'}
          userId={parentId}
          role="Parent"
          onBlock={handleBlockUnblockParent}
          name={userName}
        />
      )}

      {modals.delete && (
        <DeleteModal
          open={modals.delete}
          handleClose={handleDeleteClose}
          key={modals.delete + 'Delete'}
          role="Parent"
          onDelete={handleDeleteParent}
        />
      )}
    </>
  )
}

export default ParentTable
