import {
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
} from '@mui/material'
import { FC } from 'react'

import { PaginatedBooking } from '../rejected-canceled.type'
import RejectedCanceledSessionTableBody from './RejectedCanceledSessionTableBody'
import { rejectedCanceledSessionHeading } from './data'

interface RejectedCanceledSessionTableI {
  rejectedSessions: PaginatedBooking[]
}
const RejectedCanceledSessionTable: FC<RejectedCanceledSessionTableI> = ({
  rejectedSessions,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          {rejectedCanceledSessionHeading.map((heading, index) => {
            return <TableCell key={index}>{heading}</TableCell>
          })}
        </TableHead>
        <TableBody>
          {rejectedSessions.map((rejectedSession) => {
            return (
              <RejectedCanceledSessionTableBody
                rejectedSessions={rejectedSession}
                key={rejectedSession._id}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RejectedCanceledSessionTable
