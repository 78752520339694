import { Button, TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { APIKeys } from '../../apiKeys.type'
import ConfirmRevoke from '../confirm-revoke/ConfirmRevoke'
import { api } from './../../../../../utils/api'

interface ApiKeyTableBodyI {
  apiKey: APIKeys
  getAllKeys: () => Promise<void>
}

const ApiKeyTableBody: FC<ApiKeyTableBodyI> = ({ apiKey, getAllKeys }) => {
  const [confirmRevoke, setConfirmRevoke] = useState(false)
  const revokeKey = async () => {
    const res = await api.put(`admin/keys/${apiKey._id}/revoke`)
    if (res.ok) {
      setConfirmRevoke(false)
      getAllKeys()
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>{apiKey.externalResourceName}</TableCell>
        <TableCell sx={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
          {apiKey.key}
        </TableCell>
        <TableCell>
          {dayjs(apiKey.createdAt)
            .tz('America/New_York')
            .format('MM/DD/YYYY hh:mma z')}
        </TableCell>
        <TableCell>
          {apiKey.revoked ? (
            'Yes'
          ) : (
            <Button
              variant="text"
              onClick={() => {
                setConfirmRevoke(true)
              }}
            >
              Revoke
            </Button>
          )}
        </TableCell>
        <TableCell>
          {apiKey.revokedAt
            ? dayjs(apiKey.revokedAt)
                .tz('America/New_York')
                .format('MM/DD/YYYY hh:mma z')
            : '-'}
        </TableCell>
      </TableRow>
      {confirmRevoke && (
        <ConfirmRevoke
          open={confirmRevoke}
          onClose={() => {
            setConfirmRevoke(false)
          }}
          onYes={revokeKey}
        />
      )}
    </>
  )
}

export default ApiKeyTableBody
