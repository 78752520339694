export const summarySessionStatus = [
  { id: 'ACCEPTED', option: 'ACCEPTED', value: 'ACCEPTED' },
  { id: 'CANCELLED', option: 'CANCELED', value: 'CANCELLED' },
  { id: 'FINISHED', option: 'FINISHED', value: 'FINISHED' },
  { id: 'IN PROGRESS', option: 'IN PROGRESS', value: 'IN PROGRESS' },
  { id: 'NEW', option: 'NEW', value: 'NEW' },
  { id: 'COMPLETED', option: 'COMPLETED', value: 'COMPLETED' },
  {
    id: 'PENDING RESCHEDULE',
    option: 'PENDING RESCHEDULE',
    value: 'PENDING RESCHEDULE',
  },
  { id: 'REJECTED', option: 'REJECTED', value: 'REJECTED' },
]
