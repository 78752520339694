import { Navigate } from 'react-router-dom'
import { useAppContext } from '../../contexts/AppContext'
import SiginCard from './components/signin-card/SiginCard'
import './signin.scss'

const SignIn = () => {
  const {
    appState: { isLoggedIn },
  } = useAppContext()

  if (isLoggedIn) {
    return <Navigate to="/pages/dashboard" />
  }

  return (
    <div className="signin-main d-flex align-items-center justify-content-center flex-column">
      <SiginCard />
    </div>
  )
}

export default SignIn
