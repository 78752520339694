import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from './../../../../../components/modal-wrapper/ModalWrapper'

interface ZeroTutoringHoursProps {
  open: boolean
  onClose: () => void
}
const ZeroTutoringHours: FC<ZeroTutoringHoursProps> = ({ open, onClose }) => {
  return (
    <ModalWrapper open={open} handleClose={onClose}>
      {' '}
      <div className="d-flex justify-content-center align-items-center flex-column modal-text">
        <p className="text-center mt-3">
          This session request cannot be completed because there are no tutoring
          credit hours available.
        </p>
        <div className="d-flex justify-content-center">
          <Button
            color="success"
            className="modal-button auto-accept-button"
            onClick={onClose}
          >
            Ok
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ZeroTutoringHours
