import { yupResolver } from '@hookform/resolvers/yup'
import { Button, MenuItem, OutlinedInput, Select } from '@mui/material'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import TimePicker, {
  TimePickerObj,
} from '../../../../components/time-picker/TimePicker'
import { useAppContext } from '../../../../contexts/AppContext'
import { bookingStates } from '../../../../types/getBooking.type'
import { GeneralObj, GeneralRes } from '../../../../types/global.type'
import { api, minTwoDigits } from '../../../../utils'
import { Booking } from '../../sessions.type'
import { FilterButtonTypes } from '../data'
import { AlteredBooking } from './sessions-table/SessionsTable'

dayjs.extend(timezone)
dayjs.extend(utc)

export type cancelBookingType =
  | 'editActualTimes'
  | 'cancel'
  | 'additionalNote'
  | 'NEW'

interface CancelBookingModalIProps {
  open: boolean
  onClose: () => void
  type: cancelBookingType
  booking: AlteredBooking
  setBookingState: React.Dispatch<
    React.SetStateAction<bookingStates | undefined>
  >
  setBooking: React.Dispatch<React.SetStateAction<Booking | undefined>>
  setBookingData: React.Dispatch<React.SetStateAction<FormData | undefined>>
  buttonType: FilterButtonTypes
  setModals: React.Dispatch<
    React.SetStateAction<{
      details: boolean
    }>
  >
  getAllSessions?: () => Promise<void>
}

const defaultValues = {
  onBehalf: '',
  reason: '',
  show: '',
  additionalDetails: '',
}

const schema = yup.object({
  onBehalf: yup.string().required(),
  reason: yup.string().required(),
  show: yup.string().required(),
  additionalDetails: yup.string(),
})

type CancelBooking = {
  sessionStartTime2: Date
  sessionEndTime2: Date
  sessionStartTime1: string
  sessionEndTime1: string
} & AlteredBooking

interface State {
  showMessage: boolean
  startTime: GeneralObj
  endTime: GeneralObj
  dateS: any
  allData?: CancelBooking
  startTimeCheck?: { hour: number; minute: number; second: number }
  endTimeCheck?: { hour: number; minute: number; second: number }
  statusFlag: boolean
  key: string
  validTime: boolean
}

const defaultState: State = {
  showMessage: false,
  startTime: {},
  endTime: {},
  dateS: undefined,
  allData: undefined,
  startTimeCheck: undefined,
  endTimeCheck: undefined,
  statusFlag: false,
  key: '',
  validTime: false,
}

const CancelBookingModal: FC<CancelBookingModalIProps> = ({
  open,
  onClose,
  type,
  booking,
  setBookingState,
  setBooking,
  setBookingData,
  buttonType,
  setModals,
  getAllSessions,
}) => {
  const [state, setState] = useState(defaultState)
  const { updateAppState } = useAppContext()

  const updateState = (data: Partial<State>) => {
    setState((prev) => ({ ...prev, ...data }))
  }

  const statusFlag = buttonType === 'NEW'

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  useEffect(() => {
    let newState = state
    newState.startTime = {
      hour: new Date().getHours(),
      minute: new Date().getMinutes(),
      second: 0,
    }
    newState.endTime = {
      hour: new Date().getHours(),
      minute: new Date().getMinutes(),
      second: 0,
    }

    newState.allData = booking as CancelBooking

    if (newState.allData.sessionStartTime) {
      newState.allData.sessionStartTime2 = new Date(
        dayjs(booking.sessionStartTime)
          .tz(newState.allData.parentTimezoneOffsetZone)
          .format('LLL'),
      )
      newState.allData.sessionEndTime2 = new Date(
        dayjs(booking.sessionEndTime)
          .tz(newState.allData.parentTimezoneOffsetZone)
          .format('LLL'),
      )
      newState.allData.sessionStartTime1 = dayjs(booking.sessionStartTime)
        .tz(newState.allData.parentTimezoneOffsetZone)
        .format('LLL')
    }

    if (newState.allData.sessionEndTime) {
      newState.allData.sessionEndTime1 = dayjs(booking.sessionEndTime)
        .tz(newState.allData.parentTimezoneOffsetZone)
        .format('LLL')
    }

    newState.startTime = {
      hour: new Date(newState.allData.sessionStartTime1).getHours(),
      minute: new Date(newState.allData.sessionStartTime1).getMinutes(),
      second: 0,
    }
    newState.endTime = {
      hour: new Date(newState.allData.sessionEndTime1).getHours(),
      minute: new Date(newState.allData.sessionEndTime1).getMinutes(),
      second: 0,
    }
    newState.startTimeCheck = {
      hour: new Date(newState.allData.sessionStartTime1).getHours(),
      minute: new Date(newState.allData.sessionStartTime1).getMinutes(),
      second: 0,
    }
    newState.endTimeCheck = {
      hour: new Date(newState.allData.sessionEndTime1).getHours(),
      minute: new Date(newState.allData.sessionEndTime1).getMinutes(),
      second: 0,
    }
    newState.key = type
    newState.statusFlag = buttonType === 'NEW'

    setValue(
      'onBehalf',
      booking.sessionRequestedBy === 'TUTOR' ? 'TUTOR' : 'PARENT',
    )
    if (!newState.statusFlag) setValue('onBehalf', '')
    if (booking.cancelData) {
      const { additionalDetails, onBehalf, reason, show } = booking.cancelData
      setValue('onBehalf', onBehalf)
      setValue('reason', reason)
      setValue('show', show)
      setValue('additionalDetails', additionalDetails)
    }

    updateState(newState)
  }, [booking])

  const handleClose = () => {
    updateState({ showMessage: false })
    onClose()
  }

  const onSubmit = handleSubmit(async (values) => {
    const { additionalDetails, onBehalf, reason, show } = values
    const formData = new FormData()
    formData.append('onBehalf', onBehalf)
    formData.append('reason', reason)
    if (additionalDetails)
      formData.append('additionalDetails', additionalDetails)
    formData.append('noShow', show)
    if (onBehalf === 'TUTOR' && show === 'true') {
      setBookingState({
        pageType: 'noShowTutor',
        source: 'sessionDetail',
        actionType: 'activeBooking',
      })
      const alteredBooking = _.cloneDeep(booking)
      alteredBooking.cancelData = {
        show: 'true',
        onBehalf: 'TUTOR',
        reason,
        additionalDetails,
      }
      setBookingData(formData)
      setBooking(alteredBooking)
      setModals((prev) => ({ ...prev, details: true }))
    } else {
      setBookingState({
        pageType: 'ReschudeBooking',
        source: 'sessionDetail',
        actionType: 'activeBooking',
      })
      setBookingData(formData)
      setBooking(booking)
      setModals((prev) => ({ ...prev, details: true }))
    }
    handleClose()
  })

  if (type !== 'editActualTimes') {
    return (
      <ModalWrapper
        open={open}
        handleClose={handleClose}
        wide
        title={type === 'cancel' ? 'Cancel Session' : 'Add Additional Note'}
      >
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <form className="w-auto" onSubmit={onSubmit}>
              {type === 'cancel' && (
                <div className="form-group row">
                  <div className="col-sm-4 text-left">
                    <label htmlFor="onBehalf" className="add-child__label">
                      On Behalf
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <Controller
                      name="onBehalf"
                      control={control}
                      render={({ field }) => (
                        <StyledDropdown
                          {...field}
                          option={[
                            { id: 'tutor', value: 'TUTOR', option: 'Tutor' },
                            { id: 'parent', value: 'PARENT', option: 'Parent' },
                          ]}
                          placeholder="--- Select option ---"
                          isError={!!errors.onBehalf}
                          className="w-100"
                          disabled={statusFlag}
                          errorMessage={errors.onBehalf?.message?.toString()}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {type === 'cancel' && (
                <div className="form-group row">
                  <div className="col-sm-4 text-left">
                    <label htmlFor="reason" className="add-child__label">
                      Reason
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <Controller
                      name="reason"
                      control={control}
                      render={({ field }) => (
                        <StyledTextfield
                          {...field}
                          max={64}
                          placeholder="Enter Reason"
                          isError={!!errors.reason}
                          errorMessage={errors.reason?.message?.toString()}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {type === 'cancel' && (
                <div className="form-group row">
                  <div className="col-sm-4 text-left">
                    <label htmlFor="show" className="add-child__label">
                      No Show
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <Controller
                      name="show"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={`w-100`}
                          input={<OutlinedInput />}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            --- Select option ---
                          </MenuItem>
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="form-group row">
                <div className="col-sm-4 text-left">
                  <label
                    htmlFor="additionalDetails"
                    className="add-child__label"
                  >
                    Additional Details
                  </label>
                </div>
                <div className="col-sm-8">
                  <Controller
                    name="additionalDetails"
                    control={control}
                    render={({ field }) => (
                      <StyledTextfield
                        {...field}
                        multiline
                        placeholder="Enter Additional Details"
                        isError={!!errors.additionalDetails}
                        className="w-100"
                        rows={4}
                        errorMessage={errors.additionalDetails?.message?.toString()}
                      />
                    )}
                  />
                </div>
              </div>
              {type === 'cancel' && (
                <div className="form-group row">
                  <div className="offset-sm-4 col-sm-8">
                    <Button
                      type="submit"
                      className="w-100 mt-4"
                      color={isValid ? 'success' : 'info'}
                      disabled={!isValid}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
              {type !== 'cancel' && (
                <div className="form-group row">
                  <div className="offset-sm-4 col-sm-8">
                    <Button
                      type="button"
                      className="w-100 mt-4"
                      color={isValid ? 'success' : 'info'}
                      disabled={!isValid}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </ModalWrapper>
    )
  }

  const handleUpdateActualTimes = async () => {
    if (!(state.startTime && state.endTime)) return
    if (!state.showMessage) {
      updateState({ showMessage: true })
      return
    }

    let sHour, sMin, eHour, eMin

    updateState({ dateS: new Date(state.allData!.startTime) })
    let dateToComb = dayjs(state.allData!.sessionStartTime1).format(
      'YYYY-MM-DD',
    )
    sHour = minTwoDigits(state.startTime.hour)
    sMin = minTwoDigits(state.startTime.minute)
    eHour = minTwoDigits(state.endTime.hour)
    eMin = minTwoDigits(state.endTime.minute)

    let startTimeValue = sHour + ':' + sMin
    let tempStart = dayjs(dateToComb + ' ' + startTimeValue)
      .tz(state.allData!.parentTimezoneOffsetZone)
      .format()
    let start = dayjs.utc(tempStart).format('YYYY-MM-DD H:mm:ss Z')
    let endTimeValue = eHour + ':' + eMin
    let tempEnd = dayjs(dateToComb + ' ' + endTimeValue)
      .tz(state.allData!.parentTimezoneOffsetZone)
      .format()
    let end = dayjs.utc(tempEnd).format('YYYY-MM-DD H:mm:ss Z')

    const formData = new FormData()
    formData.append('startTime', start)
    formData.append('endTime', end)

    updateAppState({ loading: true })
    const res = await api.put<GeneralRes>(
      `/admin/booking/${booking._id}/sessionTimes`,
    )
    if (res.ok && res.data) {
      getAllSessions?.()
    }

    updateAppState({ loading: false })
  }

  const disableSave = () => {
    updateState({ validTime: false })
  }

  const endTimeChange = (state: State) => {
    if (!(state.startTime && state.endTime)) {
      updateState({ validTime: false })
      return
    }

    if (
      !(
        state.startTime.hour === state.startTimeCheck?.hour &&
        state.startTime.minute === state.startTimeCheck?.minute &&
        state.endTimeCheck?.hour === state.endTime.hour &&
        state.endTime.minute === state.endTimeCheck?.minute
      )
    ) {
      if (state.allData!.status === 'Finished') {
        if (state.startTime.hour < state.endTime.hour) {
          state.validTime = true
        } else {
          if (
            state.startTime.hour == state.endTime.hour &&
            state.endTime.minute > state.startTime.minute
          ) {
            state.validTime = true
          } else {
            state.validTime = false
          }
        }
      } else {
        state.validTime = true
      }
    } else {
      state.validTime = false
    }

    updateState(state)
  }

  const updateStartTimes = async () => {
    if (!state.startTime) return

    if (!state.showMessage) {
      updateState({ showMessage: true })
      return
    }

    let sHour,
      sMin,
      dateToComb = dayjs(state.allData!.sessionStartTime1).format('YYYY-MM-DD')

    sHour = minTwoDigits(state.startTime.hour)
    sMin = minTwoDigits(state.startTime.minute)

    let startTimeValue = sHour + ':' + sMin
    let tempStart = dayjs(dateToComb + ' ' + startTimeValue)
      .tz(state.allData!.parentTimezoneOffsetZone)
      .format()
    let start = dayjs.utc(tempStart).format('YYYY-MM-DD H:mm:ss Z')

    let formData = new FormData()
    formData.append('startTime', start)
    updateAppState({ loading: true })
    const res = await api.put(
      `/admin/booking/${booking._id}/sessionTimes`,
      formData,
    )
    if (res.ok && res.data) {
    }
    updateAppState({ loading: false })
  }

  const updateActualTimes = async () => {
    if (!(state.endTime && state.startTime)) return

    if (!state.showMessage) {
      updateState({ showMessage: true })
      return
    }

    let sHour, sMin, eHour, eMin

    let formData = new FormData()
    updateState({ dateS: new Date(state.allData!.startTime) })
    let dateToComb = dayjs(state.allData!.sessionStartTime1).format(
      'YYYY-MM-DD',
    )

    sHour = minTwoDigits(state.startTime.hour)
    sMin = minTwoDigits(state.startTime.minute)
    eHour = minTwoDigits(state.endTime.hour)
    eMin = minTwoDigits(state.endTime.minute)

    let startTimeValue = sHour + ':' + sMin
    let tempStart = dayjs(dateToComb + ' ' + startTimeValue)
      .tz(state.allData!.parentTimezoneOffsetZone)
      .format()
    let start = dayjs.utc(tempStart).format('YYYY-MM-DD H:mm:ss Z')
    let endTimeValue = eHour + ':' + eMin
    let tempEnd = dayjs(dateToComb + ' ' + endTimeValue)
      .tz(state.allData!.parentTimezoneOffsetZone)
      .format()
    let end = dayjs.utc(tempEnd).format('YYYY-MM-DD H:mm:ss Z')

    formData.append('startTime', start)
    formData.append('endTime', end)
    const res = await api.put(
      `/admin/booking/${booking._id}/sessionTimes`,
      formData,
    )
    if (res.ok && res.data) {
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      wide
      title="Edit Actual Session Times"
    >
      <div className="row">
        <div className="col-sm-12 col-lg-12 col-md-12">
          <form className="w-auto" onSubmit={onSubmit}>
            {state.showMessage && (
              <div>
                <div className="col-sm-12">
                  <p>Are you sure you want to update the session times?</p>
                </div>
                <div className="row">
                  <div className="col-sm-4 col-md-6 col-xs-6">
                    <Button color="success" onClick={onClose}>
                      No
                    </Button>
                    <Button color="success" onClick={handleUpdateActualTimes}>
                      Yes
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {!state.showMessage && (
              <div className="row">
                <div
                  className="col-4"
                  style={{ fontSize: '20px', color: '#232f84' }}
                >
                  <span>Parent Name:</span>
                  <br />
                  <span>Tutor Name:</span>
                  <br />
                  <span>Session Date:</span>
                  <br />
                  <span>Session Time Zone:</span>
                </div>

                <div
                  className="col-8"
                  style={{ color: 'black', fontSize: '20px', opacity: '0.7' }}
                >
                  <span>{`${booking.parentId.firstName} ${booking.parentId.lastName}`}</span>
                  <br />
                  <span>{`${booking.tutorId.firstName} ${booking.tutorId.lastName}`}</span>
                  <br />
                  <span>
                    {dayjs(booking.sessionStartTime).format('MMM D, YYYY')}
                  </span>
                  <br />
                  <span>{booking.parentAbbreviation}</span>
                </div>

                <div className="mt-4" />

                <div className="col-4 mt-4" style={{ color: '#232f84' }}>
                  <span style={{ fontSize: '22px' }}> Actual Start Time:</span>
                  <br />
                  <br />
                  {booking.status === 'Finished' && (
                    <span style={{ fontSize: '22px' }}>Actual End Time:</span>
                  )}
                </div>

                <div
                  className="col-3 mt-4"
                  style={{ color: 'black', opacity: 0.7 }}
                >
                  <span style={{ fontSize: '21px' }}>
                    {dayjs(state.allData?.sessionStartTime2).format('h:mmA')}
                  </span>
                  <br />
                  <br />
                  {booking.status === 'Finished' && (
                    <span style={{ fontSize: '21px' }}>
                      {dayjs(state.allData?.sessionEndTime2).format('h:mmA')}
                    </span>
                  )}
                </div>

                <div className="col-5">
                  <h4 style={{ color: '#9d9d9d', fontSize: '18px' }}>
                    Change To:
                  </h4>
                  <TimePicker
                    data={state.startTime as TimePickerObj}
                    onChange={(data) => {
                      setState((prev) => {
                        const newState = { ...prev, startTime: data }
                        endTimeChange(newState)
                        return newState
                      })
                    }}
                    onKeyDown={disableSave}
                  />
                  {state.allData?.status === 'Finished' && (
                    <TimePicker
                      data={state.endTime as TimePickerObj}
                      onChange={(data) => {
                        setState((prev) => {
                          const newState = { ...prev, endTime: data }
                          endTimeChange(newState)
                          return newState
                        })
                      }}
                      onKeyDown={disableSave}
                      className="mt-3"
                    />
                  )}
                </div>

                <div className="col-12 text-center mt-4">
                  <Button
                    type="button"
                    style={{ margin: 'auto', width: '300px' }}
                    color={!state.validTime ? 'info' : 'success'}
                    disabled={!state.validTime}
                    onClick={
                      state.allData?.status === 'Finished'
                        ? updateActualTimes
                        : updateStartTimes
                    }
                  >
                    Update Actual Session Times
                  </Button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default CancelBookingModal
