import { Button } from '@mui/material'
import { FC, useState } from 'react'

import AdjustImage from '../adjust-image/AdjustImage'
import ModalWrapper from './../../../../../../components/modal-wrapper/ModalWrapper'
import './select-image.scss'

interface SelectImageProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>
}

const SelectImage: FC<SelectImageProps> = ({
  open,
  setOpen,
  setSelectedImage,
}) => {
  const handleClose = () => {
    setOpen(!open)
  }

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedImage(e.target.files[0])
      handleClose()
    }
  }

  return (
    <>
      <ModalWrapper open={open} handleClose={handleClose} title="Select Image">
        <div className="w-100 text-center modal-height">
          <Button color="success" component="label">
            Upload Image{' '}
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => {
                handleImage(e)
              }}
            />
          </Button>
        </div>
      </ModalWrapper>
    </>
  )
}

export default SelectImage
