import { Button } from '@mui/material'
import { useRef, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import AddEditSubject from './components/add-edit-subject/AddEditSubject'
import SubjectComponent, {
  ForwardRefProps,
} from './components/subject-component/SubjectComponent'

const AllSubjects = () => {
  const [addSubject, setAddSubject] = useState(false)

  const ref = useRef<ForwardRefProps>(null)

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2">
        <BackArrow route={routes.allCategories} />
        <div className="col-sm-12 col-md-10  col-lg-11 text-end">
          <Button
            onClick={() => {
              setAddSubject(true)
            }}
          >
            Add Subject
          </Button>
        </div>
      </div>
      <div className="row">
        <SubjectComponent ref={ref} />
      </div>
      {addSubject && (
        <AddEditSubject
          open={addSubject}
          onClose={() => {
            setAddSubject(false)
            ref?.current?.getAllSubjects()
          }}
          mode="Add"
        />
      )}
    </div>
  )
}

export default AllSubjects
