import { Button } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

import CustomDatePicker from '../../../components/date-picker/CustomDatePicker'
import MarketDropdown from '../../../components/styled-dropdown/MarketDropdown'
import StyledDropdown, {
  Options,
} from '../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../components/styled-textfield/StyledTextfield'
import { useAppContext } from '../../../contexts/AppContext'
import { GeneralObj } from '../../../types/global.type'
import { API_URL, api, sortArrayOfStrings } from '../../../utils'
import { PartnershipRes } from '../../category-commission/components/all-partnership-codes/AllPartnershipCode.type'
import { downloadUrl } from '../../dashboard/helpers'
import { summarySessionStatus } from './data'
import './session-data.scss'
import { SummaryReportRes } from './summary.type'

interface SessionDataFiltersI {
  sessionStartDate: Dayjs | null
  sessionEndDate: Dayjs | null
  createdStartDate: Dayjs | null
  createdEndDate: Dayjs | null
  marketId: string
  parentName: string
  tutorName: string
  promoCode: string
  partnershipId: string
  sessionStatus: string
}

const defaultSessionDataFilters: SessionDataFiltersI = {
  createdEndDate: null,
  createdStartDate: null,
  sessionStartDate: null,
  sessionEndDate: null,
  marketId: '',
  parentName: '',
  partnershipId: '',
  promoCode: '',
  sessionStatus: '',
  tutorName: '',
}

const SessionData = () => {
  const { marketData, updateAppState } = useAppContext()
  const [partnerShipCodes, setPartnerShipCodes] = useState<Options[]>([])
  const [sessionFilters, setSessionFilters] = useState<SessionDataFiltersI>(
    defaultSessionDataFilters,
  )
  const [filterError, setFilterError] = useState('')

  const getAllPartnershipCode = async () => {
    const res = await api.get<PartnershipRes>('admin/getPartnershipCodes', {
      getAll: true,
    })

    if (res.ok && res.data) {
      let partnerships: any = []
      const { partnerCodes } = res.data?.data
      partnerCodes.forEach((val) => {
        if (dayjs().isAfter(dayjs(val.startTime))) {
          partnerships.push({
            id: val._id,
            option: val.partnercode,
            value: val._id,
          })
        }
      })
      setPartnerShipCodes(sortArrayOfStrings(partnerships, 'option'))
    }
  }

  useEffect(() => {
    getAllPartnershipCode()
  }, [])

  const handleDateChange = (
    name: string,
    value: dayjs.Dayjs | null | undefined,
  ) => {
    setSessionFilters({ ...sessionFilters, [name]: value })
  }
  const handleDateClear = (name: string) => {
    setSessionFilters({ ...sessionFilters, [name]: null })
    setFilterError('')
  }

  const checkDisable = () => {
    if (Object.values(sessionFilters).some((val) => val)) {
      return false
    }
    return true
  }

  const downloadCSV = async () => {
    const {
      createdEndDate,
      createdStartDate,
      marketId,
      parentName,
      partnershipId,
      promoCode,
      sessionEndDate,
      sessionStartDate,
      sessionStatus,
      tutorName,
    } = sessionFilters

    if (createdStartDate?.isAfter(createdEndDate)) {
      setFilterError(
        'The Create Date start date must be equal to or before the end date.',
      )
      return
    } else if (sessionStartDate?.isAfter(sessionEndDate)) {
      setFilterError(
        'The Session Date start date must be equal to or before the end date.',
      )
      return
    } else {
      setFilterError('')
    }

    updateAppState({ loading: true })
    let queryObj: GeneralObj = {
      type: sessionStatus ? sessionStatus : 'ALL',
      parentName: parentName ? parentName : null,
      tutorName: tutorName ? tutorName : null,
      promoCode: promoCode ? promoCode : null,
      partnercodeId: partnershipId ? partnershipId : null,
      marketId: marketId ? marketId : null,
    }
    if (createdStartDate) {
      queryObj.createdStartDate = createdStartDate
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]')
    }
    if (createdEndDate) {
      queryObj.createdEndDate = createdEndDate
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]')
    }
    if (sessionStartDate) {
      queryObj.startDate = sessionStartDate
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]')
    }
    if (sessionEndDate) {
      queryObj.endDate = sessionEndDate
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss[.640Z]')
    }
    const res = await api.get<SummaryReportRes>(
      'admin/sessionReport',
      queryObj,
      { baseURL: new URL('/api/v2', API_URL).href },
    )
    if (res.ok && res.data) {
      const { url } = res.data.data
      downloadUrl(url, 'sessionData.csv')
    }
    updateAppState({ loading: false })
  }

  return (
    <div className="container-fluid mt-5">
      <div className="panel panel-default panel-table">
        <div className="panel-body">
          <div className="row ">
            <div className="col-sm-12 col-xs-12 col-md-12 top-heading">
              Use the below options to filter the session data you would like to
              view before downloading. You will not be able to download this
              data until at least one filter has been applied.
            </div>
          </div>
          <div className="session-report">
            <div className="row">
              <div className="col-md-3 tab-border">
                <h4>Created Date Range:</h4>
              </div>
              <div className="col-md-9 tab-border">
                <div className="row">
                  <div className="col-md-4">
                    <CustomDatePicker
                      placeholder="Select Start Date"
                      disableFutures
                      date={sessionFilters.createdStartDate}
                      onChange={(value) => {
                        handleDateChange('createdStartDate', value)
                      }}
                      onClear={() => {
                        handleDateClear('createdStartDate')
                      }}
                    />
                  </div>
                  <p className="d-flex align-items-center m-0 w-auto">to</p>
                  <div className="col-md-4">
                    <CustomDatePicker
                      placeholder="Select End Date"
                      disableFutures
                      date={sessionFilters.createdEndDate}
                      onChange={(value) => {
                        handleDateChange('createdEndDate', value)
                      }}
                      onClear={() => {
                        handleDateClear('createdEndDate')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 tab-border">
                <h4>Session Date Range:</h4>
              </div>
              <div className="col-md-9 tab-border">
                <div className="row">
                  <div className="col-md-4">
                    <CustomDatePicker
                      placeholder="Select Start Date"
                      date={sessionFilters.sessionStartDate}
                      onChange={(value) => {
                        handleDateChange('sessionStartDate', value)
                      }}
                      onClear={() => {
                        handleDateClear('sessionStartDate')
                      }}
                    />
                  </div>
                  <p className="d-flex align-items-center m-0  w-auto">to</p>
                  <div className="col-md-4">
                    <CustomDatePicker
                      placeholder="Select End Date"
                      date={sessionFilters.sessionEndDate}
                      onChange={(value) => {
                        handleDateChange('sessionEndDate', value)
                      }}
                      onClear={() => {
                        handleDateClear('sessionEndDate')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 tab-border">
                <h4>Session Status:</h4>
              </div>
              <div className="col-md-3 tab-border">
                <StyledDropdown
                  placeholder="Select Session Status"
                  option={summarySessionStatus}
                  selectablePlaceholder
                  value={sessionFilters.sessionStatus}
                  onChange={(e) => {
                    setSessionFilters({
                      ...sessionFilters,
                      sessionStatus: e.target.value,
                    })
                  }}
                />
              </div>

              <div className="col-md-3 tab-border">
                <h4>Market:</h4>
              </div>
              <div className="col-md-3 tab-border">
                <MarketDropdown
                  placeholder="Select Market Name"
                  option={marketData}
                  excludeNone
                  selectablePlaceholder
                  value={sessionFilters.marketId}
                  handleChange={(e) => {
                    setSessionFilters({
                      ...sessionFilters,
                      marketId:
                        e.target.value !== 'Select Market Name'
                          ? e.target.value
                          : '',
                    })
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 tab-border">
                <h4> Parent Name:</h4>
              </div>
              <div className="col-md-3 tab-border">
                <StyledTextfield
                  placeholder="Parent Name"
                  value={sessionFilters.parentName}
                  onChange={(e) => {
                    setSessionFilters({
                      ...sessionFilters,
                      parentName: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col-md-3 tab-border">
                <h4> Tutor Name:</h4>
              </div>
              <div className="col-md-3 tab-border">
                <StyledTextfield
                  placeholder="Tutor Name"
                  value={sessionFilters.tutorName}
                  onChange={(e) => {
                    setSessionFilters({
                      ...sessionFilters,
                      tutorName: e.target.value,
                    })
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 tab-border">
                <h4> Promo Code:</h4>
              </div>
              <div className="col-md-3 tab-border">
                <StyledTextfield
                  placeholder="Promo Code"
                  value={sessionFilters.promoCode}
                  onChange={(e) => {
                    setSessionFilters({
                      ...sessionFilters,
                      promoCode: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col-md-3 tab-border">
                <h4>Partnership:</h4>
              </div>
              <div className="col-md-3 tab-border">
                <StyledDropdown
                  placeholder="Select Partnership Code"
                  option={partnerShipCodes}
                  selectablePlaceholder
                  value={sessionFilters.partnershipId}
                  onChange={(e) => {
                    setSessionFilters({
                      ...sessionFilters,
                      partnershipId: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          {!!filterError && (
            <div>
              <h4 className="text-start warning-text m-0">{filterError}</h4>
            </div>
          )}
          <div className="row mt-4">
            <div className="col-md-3 pe-4 ps-4">
              <Button
                className={`w-100 ${checkDisable() ? 'disable-class' : ''}`}
                onClick={downloadCSV}
              >
                Download CSV
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionData
