import { useAppContext } from '@/contexts/AppContext'
import { api } from '@/utils'
import { useEffect, useState } from 'react'

import ReturningCustomersTable from './components/ReturningCustomersTable'
import { Customer, ReturningCustomersRes } from './returning-customers.type'

const ReturningCustomers = () => {
  const [returnedCustomer, setReturnedCustomer] = useState<Customer[]>([])
  const { updateAppState } = useAppContext()

  useEffect(() => {
    const getReturningCustomers = async () => {
      updateAppState({ loading: true })
      const res = await api.get<ReturningCustomersRes>(
        '/admin/returningCustomers',
      )
      if (res.data) {
        const { customers } = res.data.data
        setReturnedCustomer(customers)
      }
      updateAppState({ loading: false })
    }
    getReturningCustomers()
  }, [])

  return (
    <div className="main">
      <div className="mt-4">
        <ReturningCustomersTable customers={returnedCustomer} />
      </div>
    </div>
  )
}

export default ReturningCustomers
