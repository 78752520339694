import { DownloadCsv } from '@/contexts/AppContext'
import { CircularProgress } from '@mui/material'
import { FC } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { BsCheckCircleFill } from 'react-icons/bs'

interface DownloadItemProps extends DownloadCsv {}

const nameMap = {
  session: 'Session Report',
  rejectedCanceledSessions: 'Rejected and Canceled Session Report',
  firstSession: 'First Session Report',
}

const DownloadItem: FC<DownloadItemProps> = ({ status, name }) => {
  return (
    <div className="elem__download">
      <div className="download__info">
        <div className="top__download">
          <div className="download__text">
            <p>{nameMap[name]}</p>
          </div>
          <div className="download__size">
            <div className="stop__download">
              <span>
                {status === 'loading' ? (
                  <div>
                    <CircularProgress size={20} />
                  </div>
                ) : status === 'complete' ? (
                  <BsCheckCircleFill size={24} color="#0F9D58" />
                ) : status === 'error' ? (
                  <AiFillCloseCircle size={24} color="#d32f2f" />
                ) : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DownloadItem
