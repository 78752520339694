import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../../../components/modal-wrapper/ModalWrapper'

interface AddressErrorModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
}

const AddressError: FC<AddressErrorModalProps> = ({
  open,
  setOpen,
  onClose,
}) => {
  const handleClose = () => {
    setOpen(!open)
    onClose?.()
  }
  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <div className="text-center">
        Please select a location with a valid street address
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Button color="success" className="modal-button" onClick={handleClose}>
          ok
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default AddressError
