import { FC } from 'react'

import '../dashboard.scss'
import { TutorFunnelData } from '../dashboard.type'

interface TutorFunnelI {
  tutorFunnelData: TutorFunnelData
}
const TutorFunnel: FC<TutorFunnelI> = ({ tutorFunnelData }) => {
  const rows = [
    {
      field: 'Tutor Registrations',
      description: tutorFunnelData?.tutorRegistrations,
    },
    {
      field: 'Incomplete Tutor Profiles',
      description: tutorFunnelData?.incompleteTutorProfiles,
    },
    {
      field: 'Pending Tutors',
      description: tutorFunnelData?.pendingTutors,
    },
    {
      field: 'Stalled Tutors',
      description: tutorFunnelData?.stalledTutors,
    },
    {
      field: 'Rejected',
      description: tutorFunnelData?.rejected,
    },
    {
      field: 'Background Check',
      description: tutorFunnelData?.backgroundCheck,
    },
    {
      field: 'Approved Tutors',
      description: tutorFunnelData?.approvedTutors,
    },
    {
      field: 'Live Tutors',
      description: tutorFunnelData?.liveTutors,
    },
    {
      field: '% Live of Approved Profiles',
      description: tutorFunnelData?.approvedProfiles,
    },
  ]
  return (
    <div>
      <table className="custom-table">
        {rows.map((row) => {
          return (
            <tr key={row.field}>
              <td className="col-sm-8">{row.field}</td>
              <td className="col-sm-3">
                {!Number.isNaN(row.description) && (
                  <>
                    {row.description}
                    {row.field === '% Live of Approved Profiles' ? '%' : ''}
                  </>
                )}
              </td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export default TutorFunnel
