import { forwardRef } from 'react'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'

import './styled-react-select.scss'

export type SelectObjectVal = { id: string; label: string; value: string }

interface StyledReactSelectIProps {
  value?: SelectObjectVal[]
  onChange?: (value: SelectObjectVal[]) => void
  options?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
  classNamePrefix?: string
  required?: boolean
  className?: string
  placeholder?: string
  max?: number
  isError?: (error: boolean) => void
}

const StyledReactSelect = forwardRef<any, StyledReactSelectIProps>(
  ({ value, onChange, max, isError, ...resProps }, ref) => {
    type SecondVal = { action: string; removedValue: { [key: string]: string } }
    // @ts-ignore
    resProps.options = resProps.options?.filter((val: SelectObjectVal) => {
      return !value?.find((val2) => val2.id === val.id)
    })

    return (
      <Select
        ref={ref}
        isMulti
        value={value}
        onChange={(newVal, secondVal) => {
          if (secondVal?.action === 'remove-value') {
            let filteredVal = value?.filter((val: SelectObjectVal) => {
              return val.id !== (secondVal as SecondVal).removedValue.id
            })
            // @ts-ignore
            isError?.(newVal.length > max)
            if (filteredVal) onChange?.(filteredVal as SelectObjectVal[])
          } else {
            if (max) {
              isError?.(newVal.length > max)
              // @ts-ignore
              if (newVal.length <= max) onChange?.(newVal as SelectObjectVal[])
            } else {
              onChange?.(newVal as SelectObjectVal[])
            }
          }
        }}
        {...resProps}
      />
    )
  },
)

export default StyledReactSelect
