import { TableCell, TableRow, Button, Rating as MUIRating } from '@mui/material'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { api } from '../../../../utils'
import { Rating } from '../../hooks/feedback.type'
import UpdateVisibility from '../update-visibilty/UpdateVisibility'
import './feedback-table.scss'

interface FeedbackTableBodyI {
  feedback: Rating
  getAllFeedbacks: () => Promise<void>
}

const FeedbackTableBody: FC<FeedbackTableBodyI> = ({
  feedback,
  getAllFeedbacks,
}) => {
  const [updateVisibility, setUpdateVisibility] = useState(false)

  const startTime1 = dayjs(feedback.bookingId.startTime)
    .tz(feedback.bookingId.parentTimezoneOffsetZone)
    .format('LLL')

  const createdAt1 = dayjs.utc(feedback.createdAt).format('LLL')

  const updateStatus = async () => {
    const res = await api.put(`admin/updateReviewsVisibility/${feedback._id}`, {
      isPublic: !feedback.isPublic,
    })
    if (res && res.data) {
      setUpdateVisibility(false)
      getAllFeedbacks()
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          {feedback.parentId && feedback.parentId.firstName
            ? feedback.parentId.firstName + ' ' + feedback.parentId.lastName
            : '-'}
        </TableCell>
        <TableCell>
          {feedback.bookingId &&
          feedback.bookingId.studentId &&
          feedback.bookingId.studentId.firstName
            ? feedback.bookingId.studentId.firstName
            : ''}
          {feedback.bookingId &&
          feedback.bookingId.studentId &&
          feedback.bookingId.studentId.lastName
            ? feedback.bookingId.studentId.lastName
            : ''}
        </TableCell>
        <TableCell>
          {feedback.tutorId && feedback.tutorId.firstName
            ? feedback.tutorId.firstName + ' ' + feedback.tutorId.lastName
            : '-'}
        </TableCell>
        <TableCell>
          {feedback.bookingId &&
          feedback.bookingId.marketId &&
          feedback.bookingId.marketId.marketId
            ? feedback.bookingId.marketId.marketId
            : '-'}
        </TableCell>
        <TableCell>
          {feedback.bookingId && feedback.bookingId.startTime
            ? dayjs(startTime1).format('MM-DD-YYYY')
            : '-'}
        </TableCell>
        <TableCell>
          {feedback.bookingId && feedback.bookingId.createdAt
            ? dayjs(createdAt1).format('MM-DD-YYYY')
            : '-'}
        </TableCell>
        <TableCell>
          <>
            <MUIRating
              name="size-medium"
              defaultValue={feedback.rating}
              readOnly
            />
          </>
        </TableCell>
        <TableCell className="feedback">
          {feedback.feedback ? feedback.feedback : '-'}
        </TableCell>
        <TableCell>
          <Button
            variant="text"
            onClick={() => {
              setUpdateVisibility(true)
            }}
          >
            {feedback.isPublic ? 'Mark as Private' : 'Mark as Public'}
          </Button>
        </TableCell>
      </TableRow>
      {updateVisibility && (
        <UpdateVisibility
          open={updateVisibility}
          onClose={() => {
            setUpdateVisibility(false)
          }}
          dataKey={feedback.isPublic ? 'private' : 'public'}
          onYes={updateStatus}
        />
      )}
    </>
  )
}

export default FeedbackTableBody
