import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import { api } from '../../../../../../utils'
import './cancel-reservation-modal.scss'

interface CancelReservationModalProps {
  open: boolean
  onClose: () => void
  onCancelReservation: () => void
  data: {
    reservationId: string
    parentName: string
    parentId: string
  }
}

const CancelReservationModal: FC<CancelReservationModalProps> = ({
  open,
  onClose,
  onCancelReservation,
  data: { reservationId, parentName, parentId },
}) => {
  const cancelReservation = async () => {
    const res = await api.post('/admin/cancelReservation', {
      parentId,
      reservationId,
    })
    if (res.ok) {
      onClose()
      onCancelReservation()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      wide
      title="Cancel Reservation"
    >
      <div className="cancel-reservation d-flex justify-content-center align-items-center flex-column">
        <p className="cancel-reservation__text">
          Are you sure you would like to cancel this reservation for{' '}
          {parentName}? These hours will no longer be available to this parent
          and this action cannot be undone.
        </p>
        <div>
          <Button
            color="success"
            className="modal-button cancel-reservation__button"
            onClick={onClose}
          >
            No, do not cancel reservation
          </Button>
          <Button
            color="inherit"
            className="modal-button cancel-reservation__button"
            onClick={cancelReservation}
          >
            Yes, cancel reservation
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default CancelReservationModal
