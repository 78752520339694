import { useEffect, useState } from 'react'

interface DebounceParams {
  value: string
  milliSeconds: number
}

export const useDebounce = ({ value, milliSeconds }: DebounceParams) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
    }, milliSeconds)

    return () => {
      clearTimeout(timer)
    }
  }, [value, milliSeconds])

  return debouncedValue
}
