import { FC } from 'react'

import '../dashboard.scss'
import { YesterdayNumData } from '../dashboard.type'

interface YesterdaysNumbersI {
  yesterdayNumData: YesterdayNumData
}

const YesterdaysNumbers: FC<YesterdaysNumbersI> = ({ yesterdayNumData }) => {
  const rows = [
    {
      field: 'Requested Sessions',
      description: yesterdayNumData?.requestedSessions,
    },
    {
      field: 'Completed Sessions',
      description: yesterdayNumData?.completedSessions,
    },
    {
      field: 'New Bright Horizons Families',
      description: yesterdayNumData?.newBrightHorizonFamilies,
    },
    {
      field: 'New Bright Horizons Students',
      description: yesterdayNumData?.newBrightHorizonStudents,
    },
    {
      field: 'Total Bright Horizons hours added',
      description: yesterdayNumData?.totalBrightHorizonHoursAdded,
    },
  ]
  return (
    <div>
      <table className="custom-table">
        {rows.map((row) => {
          return (
            <tr key={row.field}>
              <td className="col-sm-8">{row.field}</td>
              <td className="col-sm-3">{row.description}</td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export default YesterdaysNumbers
