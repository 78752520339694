import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { useAppContext } from '../../contexts/AppContext'
import { api } from '../../utils'
import ModalWrapper from '../modal-wrapper/ModalWrapper'
import StyledTextfield from '../styled-textfield/StyledTextfield'

interface ChangePasswordProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
interface FormValues {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

const schema = Yup.object({
  oldPassword: Yup.string()
    .required('Old password Is required.')
    .matches(
      /^(?=.*[^a-zA-Z])(?=.*[a-z])(?=.*[A-Z])\S{8,}$/,
      'Invalid old password',
    )
    .min(8),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[^a-zA-Z])(?=.*[a-z])(?=.*[A-Z])\S{8,}$/,
      'Invalid new password',
    )
    .min(8)
    .required(),
  confirmNewPassword: Yup.string()
    .matches(
      /^(?=.*[^a-zA-Z])(?=.*[a-z])(?=.*[A-Z])\S{8,}$/,
      'Invalid confirm password',
    )
    .min(8)
    .required(),
}).required()

const ChangePassword: FC<ChangePasswordProps> = ({ open, setOpen }) => {
  const { appState } = useAppContext()
  const [role, setRole] = useState('')

  const defaultValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  })

  const handleClose = () => {
    setOpen(!open)
  }

  const onSubmit = async (values: FormValues) => {
    const { oldPassword, confirmNewPassword, newPassword } = values
    const res = await api.post(`/admin/changePassword`, {
      oldPassword,
      newPassword,
      confirmNewPassword,
    })

    const { data, status } = res
  }

  const values = watch()

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      title="Change Password"
      wide
    >
      <form className="container" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group row">
          <div className="col-sm-4 text-left">Old Password</div>
          <Controller
            name="oldPassword"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                type="password"
                placeholder="Old Password"
                isError={!!errors.oldPassword}
                className="col-sm-8"
                errorMessage={errors.oldPassword?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">New Password</div>
          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                type="password"
                placeholder="New Password"
                isError={!!errors.newPassword}
                className="col-sm-8"
                errorMessage={errors.newPassword?.message?.toString()}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col-sm-4 text-left">Confirm New Password</div>
          <Controller
            name="confirmNewPassword"
            control={control}
            render={({ field }) => (
              <StyledTextfield
                {...field}
                placeholder="Confirm New Password"
                isError={!!errors.confirmNewPassword}
                type="password"
                className="col-sm-8"
                errorMessage={errors.confirmNewPassword?.message?.toString()}
              />
            )}
          />
        </div>
        {!(values.newPassword === values.confirmNewPassword) &&
          !!errors.confirmNewPassword && (
            <div className="row">
              <div className="offset-sm-4 col-sm-8 helper-text d-flex justify-content-start">
                Password and confirm password does not match.
              </div>
            </div>
          )}

        <div className="row">
          <div className="col-sm-8 offset-sm-4 text-center button-margin">
            <Button
              className="w-100"
              type="submit"
              disabled={
                !isValid || !(values.newPassword === values.confirmNewPassword)
              }
              color={
                isValid && values.newPassword === values.confirmNewPassword
                  ? 'success'
                  : 'info'
              }
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default ChangePassword
