import dayjs from 'dayjs'
import { useState, useEffect } from 'react'

import { useAppContext } from '../../../../../../contexts/AppContext'
import {
  ChangeRequestResponse,
  TutorElement,
} from '../../../../../../types/changeRequest.type'
import { api } from '../../../../../../utils'
import { PendingProfileFilterStates } from '../../PendingProfileUpdate'

interface GetAllPendingProfilesProps {
  filterStates: PendingProfileFilterStates | null
  debouncedSearch?: string
}
const usePendingProfiles = ({
  filterStates,
  debouncedSearch,
}: GetAllPendingProfilesProps) => {
  const { updateAppState } = useAppContext()

  const [allPendingProfiles, setAllPendingProfiles] = useState<
    TutorElement[] | null
  >()
  const [totalPages, setTotalPages] = useState(0)

  const getPendingProfiles = async () => {
    updateAppState({ loading: true })
    const queryObj = {
      startDate: filterStates?.startDate
        ? new Date(
            filterStates.startDate.startOf('day').toDate().getFullYear(),
            filterStates.startDate.startOf('day').toDate().getMonth(),
            filterStates.startDate.startOf('day').toDate().getDate(),
            0,
            0,
            0,
          ).toUTCString()
        : null,

      endDate: filterStates?.endDate
        ? new Date(
            filterStates.endDate.startOf('day').toDate().getFullYear(),
            filterStates.endDate.startOf('day').toDate().getMonth(),
            filterStates.endDate.startOf('day').toDate().getDate() + 1,
            0,
            0,
            0,
          ).toUTCString()
        : null,

      marketId:
        filterStates?.marketId && filterStates.marketId !== 'none'
          ? filterStates?.marketId
          : null,
      skip: 0,
      limit: 10,
      searchParam: debouncedSearch ? debouncedSearch : null,
    }
    const res = await api.get<ChangeRequestResponse>(
      'admin/changeRequests',
      queryObj,
    )

    if (res.ok && res.data) {
      const { tutors, count } = res.data.data
      const {} = tutors
      setAllPendingProfiles(tutors)
      setTotalPages(Math.ceil(count / 10))
    }
    updateAppState({ loading: false })
  }

  useEffect(() => {
    getPendingProfiles()
  }, [filterStates, debouncedSearch])
  return { allPendingProfiles, totalPages, getPendingProfiles }
}

export default usePendingProfiles
