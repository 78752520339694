import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC, useState } from 'react'
import { Table } from 'reactstrap'

import StyledDropdown from '../../../../../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../../../../../components/styled-textfield/StyledTextfield'
import { BandI } from '../../AddEditFee'
import { feeAndBandHeading } from './data'
import './fee-band-table.scss'

const feeTypeOption = [
  { id: '$', value: 'FLAT', option: '$' },
  { id: '%', value: 'PERCENT', option: '%' },
]

interface FeeAndBandTableProps {
  band1: BandI
  setBand1: React.Dispatch<React.SetStateAction<BandI>>
  band2: BandI
  setBand2: React.Dispatch<React.SetStateAction<BandI>>
  band3: BandI
  setBand3: React.Dispatch<React.SetStateAction<BandI>>
}

export interface BandErrorI {
  min: boolean
  max: boolean
  fee: boolean
}
const defaultErrors: BandErrorI = {
  min: false,
  max: false,
  fee: false,
}

const FeeAndBandTable: FC<FeeAndBandTableProps> = ({
  band1,
  band2,
  band3,
  setBand1,
  setBand2,
  setBand3,
}) => {
  const [band1Error, setBand1Error] = useState<BandErrorI>(defaultErrors)
  const [band2Error, setBand2Error] = useState<BandErrorI>(defaultErrors)
  const [band3Error, setBand3Error] = useState<BandErrorI>(defaultErrors)
  return (
    <div>
      {' '}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              className="padding-0"
              sx={{ '&:hover': { background: 'none' } }}
            >
              {feeAndBandHeading.map((heading, index) => {
                return <TableCell key={index}>{heading}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="padding-0">
              <TableCell>Band 1</TableCell>
              <TableCell>
                <StyledTextfield
                  value={band1.min}
                  onChange={(e) => {
                    setBand1({ ...band1, ['min']: e.target.value })
                  }}
                  onBlur={() => {
                    setBand1Error({ ...band1Error, min: true })
                  }}
                  min={0}
                  placeholder="Min"
                  type="number"
                  isError={!!(!band1.min && band1Error.min)}
                />
              </TableCell>
              <TableCell>
                <StyledTextfield
                  value={band1.max}
                  min={0}
                  onChange={(e) => {
                    setBand1({ ...band1, ['max']: e.target.value })
                  }}
                  onBlur={() => {
                    setBand1Error({ ...band1Error, max: true })
                    if (band1.max) {
                      let band2Min = parseInt(band1.max) + 1
                      setBand2({ ...band2, ['min']: band2Min.toString() })
                    }
                  }}
                  placeholder="Max"
                  type="number"
                  isError={!!(!band1.max && band1Error.max)}
                />
              </TableCell>
              <TableCell>
                <StyledTextfield
                  placeholder="Fee"
                  value={band1.fee}
                  min={0}
                  onChange={(e) => {
                    setBand1({ ...band1, ['fee']: e.target.value })
                  }}
                  onBlur={() => {
                    setBand1Error({ ...band1Error, fee: true })
                  }}
                  type="number"
                  isError={!!(!band1.fee && band1Error.fee)}
                />
              </TableCell>
              <TableCell>
                <StyledDropdown
                  option={feeTypeOption}
                  value={band1.type}
                  className="dropDown-width"
                  onChange={(e) => {
                    setBand1({ ...band1, ['type']: e.target.value })
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="padding-0">
              <TableCell>Band 2</TableCell>
              <TableCell>
                <StyledTextfield
                  disabled
                  placeholder="Min"
                  value={band2.min}
                  type="number"
                />
              </TableCell>
              <TableCell>
                <StyledTextfield
                  placeholder="Max"
                  min={0}
                  value={band2.max}
                  onChange={(e) => {
                    setBand2({ ...band2, ['max']: e.target.value })
                  }}
                  onBlur={() => {
                    setBand2Error({ ...band2Error, max: true })
                    if (band2.max) {
                      let band3Min = parseInt(band2.max) + 1
                      setBand3({ ...band3, ['min']: band3Min.toString() })
                    }
                  }}
                  type="number"
                  isError={!!(!band2.max && band2Error.max)}
                />
              </TableCell>
              <TableCell>
                <StyledTextfield
                  placeholder="Fee"
                  min={0}
                  value={band2.fee}
                  onChange={(e) => {
                    setBand2({ ...band2, ['fee']: e.target.value })
                  }}
                  onBlur={() => {
                    setBand2Error({ ...band2Error, fee: true })
                  }}
                  type="number"
                  isError={!!(!band2.fee && band2Error.fee)}
                />
              </TableCell>
              <TableCell>
                <StyledDropdown
                  option={feeTypeOption}
                  className="dropDown-width"
                  value={band2.type}
                  onChange={(e) => {
                    setBand2({ ...band2, ['type']: e.target.value })
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow className="padding-0 no-border">
              <TableCell>Band 3</TableCell>
              <TableCell>
                <StyledTextfield
                  disabled
                  value={band3.min}
                  min={0}
                  type="number"
                  placeholder="Min"
                />
              </TableCell>
              <TableCell className="text-center vertical-middle">
                and above
              </TableCell>
              <TableCell>
                <StyledTextfield
                  value={band3.fee}
                  min={0}
                  onChange={(e) => {
                    setBand3({ ...band3, ['fee']: e.target.value })
                  }}
                  onBlur={() => {
                    setBand3Error({ ...band3Error, fee: true })
                  }}
                  placeholder="Fee"
                  type="number"
                  isError={!!(!band3.fee && band3Error.fee)}
                />
              </TableCell>
              <TableCell>
                <StyledDropdown
                  option={feeTypeOption}
                  value={band3.type}
                  className="dropDown-width"
                  onChange={(e) => {
                    setBand3({ ...band3, ['type']: e.target.value })
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default FeeAndBandTable
