import dayjs from 'dayjs'
import { FC } from 'react'
import { useEffect, useState } from 'react'

import { TutorElement } from '../../types/changeRequest.type'
import { Tutor } from '../../types/tutor.type'
import { StyledRow2 } from '../custom-bordered-row/StyledRow'

interface BackgroundCheckProps {
  singleTutor?: Tutor | null | undefined
  pendingTutor?: TutorElement
  path?: string
}

const BackgroundCheck: FC<BackgroundCheckProps> = ({
  singleTutor,
  path,
  pendingTutor,
}) => {
  const [dateOfBirth, setDateOfBirth] = useState<Date>()
  useEffect(() => {
    let tempDate
    if (singleTutor && singleTutor.tutor && singleTutor.tutor.dob) {
      tempDate = new Date(
        new Date(singleTutor.tutor.dob).getUTCFullYear(),
        new Date(singleTutor.tutor.dob).getUTCMonth(),
        new Date(singleTutor.tutor.dob).getUTCDate(),
      )
    } else if (
      pendingTutor &&
      pendingTutor.userId &&
      pendingTutor.userId.tutor.dob
    ) {
      tempDate = new Date(
        new Date(pendingTutor.userId.tutor.dob).getUTCFullYear(),
        new Date(pendingTutor.userId.tutor.dob).getUTCMonth(),
        new Date(pendingTutor.userId.tutor.dob).getUTCDate(),
      )
    }
    setDateOfBirth(tempDate)
  }, [singleTutor, pendingTutor])

  return (
    <div>
      <div className="heading-container row-margin">Background Check</div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="First Name"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.firstName
                    ? singleTutor?.tutor.firstName
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.firstName
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor?.tutor && pendingTutor?.tutor?.firstName
                    ? pendingTutor?.tutor.firstName
                    : pendingTutor?.userId &&
                      pendingTutor?.userId.tutor &&
                      pendingTutor?.userId.tutor?.firstName
                    ? pendingTutor?.userId.tutor.firstName
                    : '-'}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="Middle Name"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.middleName
                    ? singleTutor?.tutor.middleName
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.middleName
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor?.tutor && pendingTutor?.tutor?.middleName
                    ? pendingTutor?.tutor.middleName
                    : pendingTutor?.userId &&
                      pendingTutor?.userId.tutor &&
                      pendingTutor?.userId.tutor?.middleName
                    ? pendingTutor?.userId.tutor.middleName
                    : '-'}
                </span>
              )}
            </>
          }
        />
      </div>
      <div className="row row-margin view-detail-font">
        <StyledRow2
          field="Last Name"
          description={
            <>
              {path !== 'pendingProfile' ? (
                <>
                  {singleTutor?.tutor.lastName
                    ? singleTutor?.tutor.lastName
                    : '-'}
                </>
              ) : (
                <span
                  className={
                    pendingTutor && pendingTutor.tutor?.lastName
                      ? 'colorChange'
                      : ''
                  }
                >
                  {pendingTutor?.tutor && pendingTutor?.tutor?.lastName
                    ? pendingTutor?.tutor.lastName
                    : pendingTutor?.userId &&
                      pendingTutor?.userId.tutor &&
                      pendingTutor?.userId.tutor?.lastName
                    ? pendingTutor?.userId.tutor.lastName
                    : '-'}
                </span>
              )}
            </>
          }
        />
        <StyledRow2
          field="DOB"
          description={
            dateOfBirth ? (
              <span
                className={
                  pendingTutor && pendingTutor.tutor?.dob ? 'colorChange' : ''
                }
              >
                {dayjs(dateOfBirth).format('MM-DD-YYYY')}
              </span>
            ) : (
              '-'
            )
          }
        />
      </div>
    </div>
  )
}

export default BackgroundCheck
