import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { FC } from 'react'

import { Customer } from '../returning-customers.type'
import ReturningCustomersTableBody from './ReturningCustomersTableBody'
import { returningCustomersHeading } from './data'

interface ReturningCustomersTableI {
  customers: Customer[]
}
const ReturningCustomersTable: FC<ReturningCustomersTableI> = ({
  customers,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          {returningCustomersHeading.map((heading, index) => {
            return <TableCell key={index}>{heading}</TableCell>
          })}
        </TableHead>
        <TableBody>
          {customers.map((customer) => {
            return (
              <ReturningCustomersTableBody
                customer={customer}
                key={customer._id}
              />
            )
          })}
          {!customers.length && (
            <TableRow sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
              <TableCell
                sx={{
                  borderBottom: 0,
                }}
                colSpan={6}
              >
                <p className="mt-4 d-flex justify-content-center">
                  No customer found
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReturningCustomersTable
