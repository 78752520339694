import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { FC } from 'react'
import { Table } from 'reactstrap'

import { Promocode } from '../../allPromos.types'
import { getPromoHeadings } from '../data'
import PromoTableBody from './promoTableBody'

interface promoTableI {
  allActivePromos: Promocode[]
  getAllPromos: () => Promise<void>
  status?: 'ACTIVE' | 'INACTIVE'
}

const PromoTable: FC<promoTableI> = ({
  allActivePromos,
  status,
  getAllPromos,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ '&:hover': { background: 'none' } }}>
            {getPromoHeadings(status as string).map((heading, index) => {
              return <TableCell key={index}>{heading}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!allActivePromos.length &&
            allActivePromos.map((promo) => {
              return (
                <PromoTableBody
                  key={promo._id}
                  promoCode={promo}
                  status={status}
                  getAllPromos={getAllPromos}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PromoTable
