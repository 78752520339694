export const getPromoHeadings = (status: string) => {
  return [
    <>Promo Code Name</>,
    <>Start Date</>,
    <>End Date</>,
    <>
      Time of Use <br /> Per Parent
    </>,

    ...(status === 'ACTIVE' ? [<>Total Usages</>] : [<>Total Count</>]),
    ...(status === 'ACTIVE'
      ? [
          <>
            Used <br />
            Count
          </>,
        ]
      : [<>Used Count</>]),
    <>Market Name</>,
    <>Credit Type</>,
    <>Credits</>,
    ...(status !== 'ACTIVE' ? [<>status</>] : []),
    <>Action</>,
  ]
}
