import { Card, CardContent } from '@mui/material'
import dayjs from 'dayjs'
import { FC } from 'react'
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'

import { Card as CardType } from '../../types/getBooking.type'
import { IMAGES } from '../../utils'
import './payment-card.scss'

interface PaymentCardProps {
  card: CardType
  mode?: 'changePayment' | 'showCard' | 'createSession'
  onCardSelection?: (id: string) => void
  selectedCard?: string
  setDefault?: (id: string) => Promise<void>
  onEdit?: () => void
  className?: string
  onDelete?: () => void
  greyText?: boolean
}

const PaymentCard: FC<PaymentCardProps> = ({
  card,
  mode,
  onCardSelection,
  selectedCard,
  setDefault,
  onEdit,
  onDelete,
  className,
  greyText,
}) => {
  const cardIcon = (brand: string) => {
    if (brand === 'Visa') return IMAGES.Visa
    if (brand === 'American Express') return IMAGES.AmericanExpress
    if (brand === 'Discover') return IMAGES.Discover
    if (brand === 'Maestro') return IMAGES.Maestro
    if (brand === 'Diners Club') return IMAGES.DinersClub
    if (brand === 'JCB') return IMAGES.JCB
    if (brand === 'MasterCard') return IMAGES.MasterCard
  }

  const isChecked = () => {
    if (mode === 'showCard') return card.isDefault
    return selectedCard === card._id
  }

  return (
    <Card
      className={`
     ${
       mode === 'showCard'
         ? 'col-md-5 mt-3 mb-3 ms-3'
         : mode === 'changePayment'
         ? 'payment-card-container mb-5 ms-2'
         : 'mb-2'
     } ${className}`}
    >
      <CardContent className="d-flex justify-content-around align-items-start w-100">
        <div>
          <input
            type="radio"
            value={card._id}
            name="paymentCard"
            className="checkbox-input cursor-pointer"
            checked={isChecked?.()}
            onChange={(e) => {
              if (mode === 'changePayment' || mode === 'createSession')
                onCardSelection?.(card._id)
              else setDefault?.(card._id)
            }}
          />
        </div>
        <div>
          <div className={`card-number ${greyText && 'grey-text'}`}>
            XXXX XXXX XXXX {card.last4Digits}
          </div>
          <div className={`exp-date flex-nowrap ${greyText && 'grey-text'}`}>
            Expiration Date:{' '}
            {card.exdate
              ? dayjs(card.exdate).format('MMM. YYYY')
              : `${dayjs(card.expMonth).format('MMM')}. ${dayjs(
                  card.expYear,
                ).format('YYYY')}`}
          </div>
          {card.isDefault && mode === 'showCard' ? (
            <div className="status-text">Default</div>
          ) : (
            <></>
          )}
        </div>
        <div>
          <div>
            <img src={cardIcon(card.brand)} />
          </div>
          {mode === 'showCard' ? (
            <div className="d-flex">
              <div className="m-1 cursor-pointer" onClick={onEdit}>
                <FaPencilAlt size={15} color="#F15D25" />
              </div>
              <div className="m-1 cursor-pointer" onClick={onDelete}>
                <FaTrashAlt size={15} color="#F15D25" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default PaymentCard
