import { Table, TableBody, TableContainer } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FC, useState } from 'react'

import { bookingStates } from '../../../../../types/getBooking.type'
import BookingDetailModal from '../../../../parent/components/booking-detail-modal/BookingDetailModal'
import RescheduleBooking from '../../../components/reschedule-booking/RescheduleBooking'
import { useAlteredSessions } from '../../../hooks/useAlteredSessions'
import { Abbreviation, Booking } from '../../../sessions.type'
import { FilterButtonTypes } from '../../data'
import CancelBookingModal, { cancelBookingType } from '../CancelBookingModal'
import SessionsTableHead from './SessionsTableHead'
import SessionsTableRow from './SessionsTableRow'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(isSameOrAfter)

interface SessionsTableProps {
  sessionsData: Booking[]
  buttonType: FilterButtonTypes
  getAllSessions: () => Promise<void>
}

export interface AlteredBooking extends Booking {
  startTime1: Dayjs
  endTime1: Dayjs
  cancelVisible: boolean
  rescheduleVisible: boolean
  acceptSession: boolean
  declineSession: boolean
  oldStatus: string
  rescheduleData: Booking['rescheduleData'] & {
    startTime1: Dayjs
    startTime: string
    endTime1: Dayjs
    endTime: string
    parentAbbreviation: Abbreviation
  }
  rdAbb: string | Dayjs
  editActualTimes: boolean
  totalCost: number
  cancelData?: {
    show: string
    onBehalf: string
    reason: string
    additionalDetails: string
  }
}

export interface InitialModalState {
  details: boolean
  reschedule: boolean
}

const initialModalState: InitialModalState = {
  details: false,
  reschedule: false,
}

const SessionsTable: FC<SessionsTableProps> = ({
  sessionsData,
  buttonType,
  getAllSessions,
}) => {
  const [booking, setBooking] = useState<Booking | AlteredBooking>()
  const [modals, setModals] = useState(initialModalState)
  const [bookingState, setBookingState] = useState<bookingStates>()
  const [cancelBookingType, setCancelBookingType] =
    useState<cancelBookingType>()
  const [bookingData, setBookingData] = useState<FormData>()

  const alteredSessionData = useAlteredSessions({ sessionsData })

  return (
    <>
      <TableContainer>
        <Table>
          <SessionsTableHead {...{ buttonType }} />
          <TableBody>
            {alteredSessionData.map((session) => (
              <SessionsTableRow
                key={session._id}
                {...{
                  session,
                  modals,
                  buttonType,
                  setBooking,
                  setBookingState,
                  setCancelBookingType,
                  setModals,
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!booking && (
        <BookingDetailModal
          // @ts-ignore
          booking={booking as Booking}
          open={modals.details}
          bookingState={bookingState}
          path="session"
          data={bookingData}
          onClose={() => {
            setBooking(undefined)
            setModals({ ...modals, details: false })
          }}
          {...{
            getAllSessions,
            setBookingState,
            setBooking,
            setCancelBookingType,
          }}
        />
      )}
      {modals.reschedule && (
        <RescheduleBooking
          booking={booking as Booking}
          open={modals.reschedule}
          bookingState={bookingState}
          getAllSessions={getAllSessions}
          onClose={() => {
            setBooking(undefined)
            setModals({ ...modals, reschedule: false })
          }}
          {...{ setBookingState, setBooking, setModals }}
        />
      )}
      {cancelBookingType && booking && (
        // @ts-ignore
        <CancelBookingModal
          open={!!cancelBookingType}
          onClose={() => setCancelBookingType(undefined)}
          type={cancelBookingType}
          {...{
            booking,
            setBookingState,
            setBooking,
            setBookingData,
            buttonType,
            setModals,
            getAllSessions,
          }}
        />
      )}
    </>
  )
}

export default SessionsTable
