import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import ModalWrapper from '../../../../../../components/modal-wrapper/ModalWrapper'
import StyledTextfield from '../../../../../../components/styled-textfield/StyledTextfield'
import { Reason } from '../../../../../../types/constants.type'
import { api } from '../../../../../../utils'

interface AddEditRejectReasonI {
  open: boolean
  onClose: () => void
  mode: 'Edit' | 'Add'
  editReason?: Reason
  onSuccess: () => void
}
interface FormValues {
  reason: string
}
const AddEditRejectReason: FC<AddEditRejectReasonI> = ({
  mode,
  open,
  onClose,
  editReason,
  onSuccess,
}) => {
  const schema = Yup.object({
    reason: Yup.string().required('Reject reason is required'),
  }).required()

  const defaultValues: FormValues = {
    reason: '',
  }
  const { setValue, control, watch } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  })

  const [touched, setTouched] = useState(false)
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (mode === 'Edit' && editReason) {
      setValue('reason', editReason.text)
    }
  }, [editReason])

  const value = watch()

  const handleReason = async () => {
    const formData = new FormData()
    formData.append('reason', value.reason)

    let res
    if (mode === 'Add') res = await api.post('admin/addRejectReason', formData)
    else
      res = await api.put(`admin/editRejectReason/${editReason?._id}`, formData)
    if (res.ok) {
      onClose()
      onSuccess()
    }
  }

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title={mode + ' Reject Reason'}
      wide
    >
      <div className="form-group row align-items-start">
        <div className="col-sm-3 text-left">Reject Reason</div>
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <StyledTextfield
              {...field}
              multiline
              rows={4}
              onChange={(e) => {
                field.onChange(e)
                setDirty(true)
              }}
              onBlur={() => {
                setTouched(true)
              }}
              placeholder="Enter Reject Reason"
              className="col-sm-9"
              isError={(touched || dirty) && !field.value}
              errorMessage={
                dirty && !field.value ? 'Reject reason is required' : ''
              }
            />
          )}
        />
      </div>
      <div className="form-group row pe-1">
        <div className="offset-sm-9 col-sm-3 mt-4 text-end pe-2">
          <Button
            color={value.reason ? 'success' : 'info'}
            disabled={!value.reason}
            className="w-100"
            onClick={handleReason}
          >
            {mode}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddEditRejectReason
