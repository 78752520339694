import { Col, Row } from 'reactstrap'

import CurrentMetrics from './components/CurrentMetrics'
import TutorFunnel from './components/TutorFunnel'
import WhatsHappeningToday from './components/WhatsHappeningToday'
import YesterdaysNumbers from './components/YesterdaysNumbers'
import './dashboard.scss'
import {
  CurrentMatricesData,
  HappeningTodayData,
  TutorFunnelData,
  YesterdayNumData,
} from './dashboard.type'
import { useDashboardData } from './hooks/useDashboardData'

function Dashboard() {
  const {
    currentMatricesData,
    happeningTodayData,
    tutorFunnelData,
    yesterdayNumData,
  } = useDashboardData()

  return (
    <div className="dashboard">
      <h2 className="dashboard__heading">Dashboard</h2>
      <Row>
        <Col sm={6}>
          <h2 className="text-start">What's happening today</h2>
          <WhatsHappeningToday
            happeningTodayData={happeningTodayData as HappeningTodayData}
          />
        </Col>
        <Col sm={6}>
          <h2 className="text-start">Yesterday's Numbers</h2>
          <YesterdaysNumbers
            yesterdayNumData={yesterdayNumData as YesterdayNumData}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col sm={6}>
          <h2 className="text-start">Current Metrics</h2>
          <CurrentMetrics
            currentMatricesData={currentMatricesData as CurrentMatricesData}
          />
        </Col>
        <Col sm={6}>
          <h2 className="text-start">Tutor Funnel</h2>
          <TutorFunnel tutorFunnelData={tutorFunnelData as TutorFunnelData} />
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard
