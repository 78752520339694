import { Button } from '@mui/material'
import { FC } from 'react'

import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'

interface KeyExist {
  open: boolean
  onClose: () => void
}

const KeyExist: FC<KeyExist> = ({ open, onClose }) => {
  return (
    <ModalWrapper open={open} handleClose={onClose} wide>
      <div className="d-flex justify-content-center align-items-center flex-column delete-modal mt-4">
        <p className="delete-modal__title">
          This Resource Name is already in use. Please provide a new name.
        </p>
        <div>
          <Button color="success" className="modal-button" onClick={onClose}>
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default KeyExist
