import { createTheme } from '@mui/material/styles'

export const Colors = {
  primary: '#008bb2',
  secondary: '#95defb',
  success: '#4CAF50',
  info: '#00a2ff',
  danger: '#FF5722',
  warning: '#FFC107',
  dark: '#0e1b20',
  light: '#aaa',
  muted: '#abafb3',
  border: '#DDDFE1',
  inverse: '#2F3D4A',
  shaft: '#333',
  dove_gray: '#d5d5d5',
  buyerSite: '#ba6a62',
  AdminSite: '#73c2fb',
  bgSite: '#9ca677',
  textColor: '#ffb12a',
  pinkButton: '#e1134f',
  lightPinkButton: '#EC668D',
  greenButton: '#7bc144',
  userPopover: '#007799',
  blueButton: '#232f84',
  greyButton: '#f0f0f0',
  lightBlue: '#2199e8',
  paginationBlue: '008bb2',
  reactBlue: '#116db4',
  ///////////////
  // Solid Color
  ///////////////
  white: '#fff',
  black: '#000',
  red: '#FF0000',
  green: '#3b8212',
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.reactBlue,
    },
    secondary: {
      main: Colors.danger,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          outline: 'none',
          border: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': { background: 'none' },
        },
      },
      defaultProps: {
        size: 'small',
        color: 'primary',
      },
      variants: [
        {
          props: { color: 'success' },
          style: {
            color: '#EC668D !important',
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px',
          '&:hover': { background: 'none' },
        },
      },
    },
    MuiTable: {
      styleOverrides: { root: { overflow: 'hidden' } },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          fontFamily: 'ProximaNova-Reg,sans-serif',
          '&:hover': {
            backgroundColor: 'lightgray',
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          border: 'none',
          '&:hover': {
            backgroundColor: 'none',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            paddingLeft: '5px',
            textOverflow: 'ellipsis !important',
            color: 'grey',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'ProximaNova-Reg',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          padding: '0px 10px',
          borderBottom: '1px solid black',
          fontFamily: 'ProximaNova-Reg , sans-serif',
          verticalAlign: 'top',
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          whiteSpace: 'nowrap',
          minWidth: 'auto',
          fontFamily: 'sans-serif',
          fontSize: '0.9rem',
          borderRadius: '5px',
          outline: 'none !important',
          transition: 'all 0.1s ease',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: Colors.blueButton,
            color: '#fff',
            '&:hover': {
              transform: 'scale(1.1)',
              backgroundColor: Colors.blueButton,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: Colors.white,
            color: '#232f84 !important',
            '&:hover': {
              backgroundColor: Colors.white,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'inherit' },
          style: {
            backgroundColor: Colors.greenButton,
            color: '#fff',
            '&:hover': {
              transform: 'scale(1.1)',
              backgroundColor: Colors.greenButton,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            backgroundColor: Colors.lightPinkButton,
            color: '#fff',
            '&:hover': {
              transform: 'scale(1.1)',
              color: '#fff',
              backgroundColor: Colors.lightPinkButton,
            },
            ':disabled': {
              cursor: 'not-allowed !important',
              color: '#fff',
              pointerEvents: 'all !important',
              backgroundColor: Colors.lightPinkButton,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            backgroundColor: Colors.pinkButton,
            color: '#fff',
            '&:hover': {
              transform: 'scale(1.1)',
              color: '#fff',
              backgroundColor: Colors.pinkButton,
            },
            ':disabled': {
              cursor: 'not-allowed !important',
              color: '#fff',
              pointerEvents: 'all !important',
              backgroundColor: Colors.pinkButton,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            borderColor: Colors.pinkButton,
            backgroundColor: '#ffffff !important',
            color: Colors.pinkButton,
            '&:hover': {
              borderColor: Colors.pinkButton,
              transform: 'scale(1.1)',
              color: Colors.pinkButton,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: '1px solid',
            backgroundColor: 'transparent',
            color: Colors.black,
            '&:hover': {
              color: '#fff',
              transform: 'scale(1.1)',
              backgroundColor: Colors.blueButton,
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            backgroundColor: Colors.greyButton,
            color: Colors.black,
            '&:hover': {
              backgroundColor: Colors.greyButton,
              transform: 'scale(1.1)',
            },
          },
        },
      ],
    },
    MuiPopover: {
      styleOverrides: {
        root: { top: '10px' },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '20px',
          fontFamily: 'sans-serif',
          '&:hover': {
            background: Colors.lightBlue,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          height: '25px',
          width: '25px',
          '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)':
            {
              backgroundColor: 'transparent',
              color: '#19D5C6',
            },
        },
      },

      // variants: [
      //   {
      //     props: { variant: 'text', color: 'primary' },
      //     style: {
      //       '&:selected': {
      //         background: Colors.reactBlue,
      //       },
      //     },
      //   },
      // ],
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px',
          fontFamily: 'sans-serif',
          border: 'black thin solid',
          padding: '5px',
          backgroundColor: '#ffffff',
          borderRadius: '2px',
          '&:hover': {
            border: 'black thin solid',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
  },
})
export default theme
