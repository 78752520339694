import { Button } from '@mui/material'
import { FC, useState } from 'react'

import CKEditorComponent from '../../../../../components/ckEditor-component/CKEditorComponent'
import ModalWrapper from '../../../../../components/modal-wrapper/ModalWrapper'
import { api } from '../../../../../utils'

interface EditDataModalI {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  editData: string
  type: 'title' | 'body'
  buttonType: 'PARENT' | 'TUTOR'
}

const EditDataModal: FC<EditDataModalI> = ({
  editData,
  onClose,
  onSuccess,
  open,
  type,
  buttonType,
}) => {
  const [editorError, setEditorError] = useState(false)
  const [editorData, setEditorData] = useState<string>(editData)

  const handleEditorChange = (data: string) => {
    setEditorData(data)
  }

  const handleAdd = async () => {
    const formData = new FormData()
    if (buttonType === 'PARENT') {
      if (type === 'title') {
        formData.append('titleTemplateDataForDynamicRendering', editorData)
      } else {
        formData.append('templateDataForDynamicRendering', editorData)
      }
    } else {
      if (type === 'title') {
        formData.append('titleTemplateDataForDynamicRenderingTutor', editorData)
      } else {
        formData.append('templateDataForDynamicRenderingTutor', editorData)
      }
    }

    const res = await api.put('admin/updateConstants', formData)

    if (res.ok) {
      onSuccess()
      onClose()
    }
  }
  const mainTitle = type === 'title' ? 'Edit Title' : 'Edit Content'
  const rowTitle = type === 'title' ? 'Title' : 'Content'
  return (
    <ModalWrapper open={open} handleClose={onClose} wide title={mainTitle}>
      <div className="form-group row align-items-start">
        <div className="col-sm-3 text-left">
          <label className="add-child__label">{rowTitle}</label>
        </div>

        <div className="col-sm-9">
          <CKEditorComponent
            noBgColor
            onChange={handleEditorChange}
            value={editorData}
            onFocus={() => {
              setEditorError(true)
            }}
          />
          {editorError && !editorData ? (
            <div className="editor-error">
              <small>
                {type === 'title'
                  ? 'Title is Required'
                  : 'Description is Required'}
              </small>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="form-group row pe-0">
          <div className=" col-sm-9 offset-sm-3 text-center mt-5 pe-0 ps-3">
            <Button color="success" className="w-100" onClick={handleAdd}>
              Add
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default EditDataModal
