import { Button } from '@mui/material'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { timeArray } from '../../../../components/calendar-events/components/data'
import ModalWrapper from '../../../../components/modal-wrapper/ModalWrapper'
import StyledDropdown from '../../../../components/styled-dropdown/StyledDropdown'
import StyledTextfield from '../../../../components/styled-textfield/StyledTextfield'
import {
  AvailabilityData,
  GetAvailabilityRes,
} from '../../../../types/availability.type'
import { bookingStates } from '../../../../types/getBooking.type'
import { api } from '../../../../utils'
import { Booking } from '../../sessions.type'
import './reschedule-booking.scss'

dayjs.extend(timezone)
dayjs.extend(utc)

type AvailablePayload = {
  id: string
  parentId: string
  checkEndDate: boolean
  startDate: string
  endDate: string
  duration: number | null
  parentTimezoneOffsetZone: string
  bookingId: string
  status: string
}

interface RescheduleBookingIProps {
  open: boolean
  onClose: () => void
  booking: Booking
  bookingState?: bookingStates
  getAllSessions?: () => Promise<void>
  setBookingState?: React.Dispatch<
    React.SetStateAction<bookingStates | undefined>
  >
  setBooking?: React.Dispatch<React.SetStateAction<Booking | undefined>>
  setModals?: React.Dispatch<
    React.SetStateAction<{
      details: boolean
      reschedule: boolean
    }>
  >
}

interface FormValues {
  onBefalf: string
  sessionDuration: any
  sessionDate: dayjs.Dayjs | null
  currentDate: string
}

const defaultValues = {
  onBefalf: '',
  sessionDuration: '',
  sessionDate: null,
  currentDate: '',
}

interface DefaultState {
  allData: any
  submitted: boolean
  duration?: number
  storeCopy: any
  stardate: Date
  dateCreate: any[]
  weekArray: string[]
  bookingDate: Date
  flag: boolean
  slotStatusYear: any[]
  slotTime: string[]
  // slotTimeDublicate: string[]
  slotStatus: any[]
  allids: any[]
  partnerEndDate: any
  initializeData: boolean
  bookingBy: string
  weekCount: number[]
  weekDataBackend: any[]
}

const defaultState: DefaultState = {
  allData: {},
  submitted: false,
  duration: undefined,
  storeCopy: undefined,
  stardate: new Date(),
  dateCreate: [],
  weekArray: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
  slotTime: [
    '00.00',
    '00.30',
    '01.00',
    '01.30',
    '2.00',
    '02.30',
    '03.00',
    '03.30',
    '04.00',
    '04.30',
    '05.00',
    '05.30',
    '06.00',
    '06.30',
    '07.00',
    '07.30',
    '08.00',
    '08.30',
    '09.00',
    '09.30',
    '10.00',
    '10.30',
    '11.00',
    '11.30',
    '12.00',
    '12.30',
    '13.00',
    '13.30',
    '14.00',
    '14.30',
    '15.00',
    '15.30',
    '16.00',
    '16.30',
    '17.00',
    '17.30',
    '18.00',
    '18.30',
    '19.00',
    '19.30',
    '20.00',
    '20.30',
    '21.00',
    '21.30',
    '22.00',
    '22.30',
    '23.00',
    '23.30',
  ],
  // slotTimeDublicate: [
  //   '12:00 AM',
  //   '12:30 AM',
  //   '01:00 AM',
  //   '01:30 AM',
  //   '2:00 AM',
  //   '02:30 AM',
  //   '03:00 AM',
  //   '03:30 AM',
  //   '04:00 AM',
  //   '04:30 AM',
  //   '05:00 AM',
  //   '05:30 AM',
  //   '06:00 AM',
  //   '06:30 AM',
  //   '07:00 AM',
  //   '07:30 AM',
  //   '08:00 AM',
  //   '08:30 AM',
  //   '09:00 AM',
  //   '09:30 AM',
  //   '10:00 AM',
  //   '10:30 AM',
  //   '11:00 AM',
  //   '11:30 AM',
  //   '12:00 PM',
  //   '12:30 PM',
  //   '1:00 PM',
  //   '1:30 PM',
  //   '2:00 PM',
  //   '2:30 PM',
  //   '3:00 PM',
  //   '3:30 PM',
  //   '4:00 PM',
  //   '4:30 PM',
  //   '5:00 PM',
  //   '5:30 PM',
  //   '6:00 PM',
  //   '6:30 PM',
  //   '7:00 PM',
  //   '7:30 PM',
  //   '8:00 PM',
  //   '8:30 PM',
  //   '9:00 PM',
  //   '9:30 PM',
  //   '10:00 PM',
  //   '10:30 PM',
  //   '11:00 PM',
  //   '11:30 PM',
  // ],
  bookingDate: new Date(),
  flag: true,
  slotStatusYear: [],
  slotStatus: [],
  allids: [],
  partnerEndDate: undefined,
  initializeData: false,
  bookingBy: 'day',
  weekCount: [0, 1, 2, 3, 4, 5, 6],
  weekDataBackend: [],
}

const RescheduleBooking: FC<RescheduleBookingIProps> = (props) => {
  const {
    open,
    onClose,
    booking,
    bookingState,
    setBookingState,
    setBooking,
    setModals,
  } = props
  const [state, setState] = useState(defaultState)
  const [availAbleDate, setAvailAbleDate] = useState<AvailabilityData[]>()
  const [availAbleDates, setAvailAbleDates] = useState<AvailabilityData[]>()
  const [calendarOptions, setCalendarOptions] = useState<{
    minDate?: dayjs.Dayjs
    maxDate?: dayjs.Dayjs
  }>({})

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({ defaultValues })

  const sessionDate = watch('sessionDate')
  useEffect(() => {
    const elem = document.getElementById('scrollBaba')
    if (elem) {
      elem.scrollTop = 650
    }
  }, [sessionDate])

  useEffect(() => {
    let newState = _.cloneDeep(state)
    newState.storeCopy = booking

    if (availAbleDate && availAbleDate.length > 0) {
      if (availAbleDate[0].date) {
        let currentDateArray
        if (
          newState.allData?.parentTimezoneOffsetZone &&
          dayjs.tz.guess() === newState.allData.parentTimezoneOffsetZone
        ) {
          if (
            new Date(availAbleDate[0].date).getUTCDate() ==
              new Date().getDate() &&
            new Date(availAbleDate[0].date).getUTCMonth() ==
              new Date().getMonth()
          ) {
            let currentDate = new Date()
            currentDateArray = _.cloneDeep(availAbleDate[0])
            currentDateArray.slots = currentDateArray.slots.filter(
              (value: any) => {
                return (
                  value.endTime / 60000 >
                  (currentDate.getHours() * 3600000 +
                    currentDate.getMinutes() * 60000 +
                    1800000) /
                    60000
                )
              },
            )
            const newAvailableDate = [...availAbleDate]
            newAvailableDate[0] = currentDateArray
            setAvailAbleDate(newAvailableDate)
          }
        }
      }

      type Slot = {
        min: number
        hour: number
        second: number
        id: string
        date?: Date
      }

      let count = 0
      let slots: Slot[] = []

      availAbleDate[0].slots.forEach((element1) => {
        if (element1.status == 'AVAILABLE') {
          let d1 = new Date()
          let milliseconds = element1.startTime
          let houss = milliseconds / 3600000

          let timeString = houss + ''
          let time = timeString.split('.')

          d1.setHours(parseInt(time[0], 10))
          if (time[1] == undefined) d1.setMinutes(0)
          else d1.setMinutes(30)

          let curruntDate = new Date()
          curruntDate.setDate(curruntDate.getDate() + 1)
          slots.push({
            min: d1.getMinutes(),
            hour: d1.getHours(),
            second: d1.getSeconds(),
            id: element1._id,
          })
        }
      })

      newState.slotTime.forEach((element1) => {
        let newTemp1 = element1.split('.')
        let newTemp: number[] = []
        newTemp[0] = parseInt(newTemp1[0], 10)
        newTemp[1] = parseInt(newTemp1[1], 10)

        slots.forEach((element) => {
          if (newTemp[0] == element.hour && newTemp[1] == element.min) {
            newState.slotStatus[count] = 0
            newState.allids[count] = element.id
          }
        })
        count++
      })

      let bookingCount = 0
      newState = initializeWeekData(newState)
      for (let i = 0; i < 7 && bookingCount < availAbleDate.length; i++) {
        if (bookingCount < availAbleDate.length) {
          availAbleDate[bookingCount].slots.forEach((element1) => {
            if (element1.status == 'AVAILABLE') {
              let d1 = new Date()
              let milliseconds = element1.startTime
              let houss = milliseconds / 3600000
              let timeString = houss + ''
              let time = timeString.split('.')

              d1.setHours(parseInt(time[0], 10))
              if (time[1] == undefined) d1.setMinutes(0)
              else d1.setMinutes(30)

              let curruntDate = new Date()
              curruntDate.setDate(curruntDate.getDate() + 1)

              slots.push({
                min: d1.getMinutes(),
                date: availAbleDate[bookingCount].date,
                hour: d1.getHours(),
                second: d1.getSeconds(),
                id: element1._id,
              })
            }
          })

          let count1 = 0
          newState.slotTime.forEach((element1) => {
            let newTemp1 = element1.split('.')
            let newTemp: number[] = []
            newTemp[0] = parseInt(newTemp1[0], 10)
            newTemp[1] = parseInt(newTemp1[1], 10)
            slots.forEach((element) => {
              if (newTemp[0] == element.hour && newTemp[1] == element.min) {
                newState.slotStatusYear[i][count1].element = 0
                newState.slotStatusYear[i][count1].time = {
                  hour: element.hour,
                  min: element.min,
                  second: element.second,
                }
                newState.slotStatusYear[i][count1].id = element.id
                newState.slotStatusYear[i][count1].date = element.date
              }
            })
            count1++
          })
          slots = []
          bookingCount++
        }
      }
      newState.bookingDate.setDate(newState.bookingDate.getDate() + 1)
    }

    if (!newState.initializeData) {
      if (bookingState?.pageType === 'reschedule') {
        newState.allData = booking
        newState.duration = newState.allData.slots.length / 2
        newState.stardate = new Date(newState.allData.startTime)

        for (let i = 0; i <= 6; i++) {
          newState.dateCreate[i] =
            newState.stardate.getDate() +
            '\n' +
            newState.weekArray[newState.stardate.getDay()]
          newState.stardate.setDate(newState.stardate.getDate() + 1)
        }
        newState.stardate = new Date(newState.allData.startTime)
        newState.bookingDate = newState.stardate

        if (newState.flag == true) {
          newState = initializeWeekData(newState)
        }

        let cancelPolicy
        if (newState.allData.cancellationPolicy != undefined) {
          cancelPolicy = newState.allData.cancellationPolicy.cancelsLessThan
        }
        newState.allData.rescheduleVisibleParent = true
        if (
          newState.allData.startTime != undefined &&
          newState.allData.endTime != undefined
        ) {
          let curTime = new Date()
          let timeDifference
          let finalStartTime = new Date(newState.allData.startTime)
          let diff = (curTime.getTime() - finalStartTime.getTime()) / 1000

          diff /= 60 * 60
          timeDifference = Math.abs(Math.round(diff))
          if (cancelPolicy) {
            if (timeDifference > cancelPolicy) {
              newState.allData.rescheduleVisibleParent = true
            } else {
              newState.allData.rescheduleVisibleParent = false
            }
          }
        }
        setValue('sessionDate', dayjs(newState.stardate))
      }

      if (newState.allData.partnershipCode?.partnercodeId?.endTime) {
        newState.partnerEndDate =
          newState.allData.partnershipCode.partnercodeId.endTime
      }

      checkBHEndDate()
      let curruntDate = dayjs()
        .tz(
          newState.allData.parentTimezoneOffsetZone
            ? newState.allData.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .format('YYYY-MM-DDTHH:mm:ss')
      let minDate: dayjs.Dayjs
      let maxDate: dayjs.Dayjs

      if (newState.partnerEndDate) {
        let endDate = newState.partnerEndDate
        minDate = dayjs(curruntDate).startOf('date').utc()
        maxDate = dayjs(endDate).startOf('date').utc()
      } else {
        minDate = dayjs(curruntDate).startOf('date').utc()
        maxDate = dayjs(curruntDate).add(121, 'day').utc().startOf('date')
      }

      setCalendarOptions({ minDate, maxDate })

      setValue('sessionDuration', newState.duration)
      setValue(
        'onBefalf',
        !newState.allData.rescheduleVisibleParent ? 'TUTOR' : '',
      )

      const date = dayjs(newState.stardate).tz(
        newState.allData.parentTimezoneOffsetZone
          ? newState.allData.parentTimezoneOffsetZone
          : 'America/New_York',
      )

      setValue('sessionDate', date)

      newState.initializeData = true
    }

    function checkBHEndDate() {
      if (newState.allData?.reservationEndDate) {
        newState.allData.showEndDate =
          new Date(newState.allData.reservationEndDate).getUTCMonth() +
          1 +
          '/' +
          new Date(newState.allData.reservationEndDate).getUTCDate() +
          '/' +
          new Date(newState.allData.reservationEndDate).getUTCFullYear()

        if (newState.partnerEndDate) {
          if (
            newState.allData.reservationEndDate <
            newState.allData.partnershipCode.partnercodeId.endTime
          ) {
            newState.partnerEndDate = newState.allData.reservationEndDate
          }
        } else {
          newState.partnerEndDate = newState.allData.reservationEndDate
        }
      }
    }

    setState(newState)
  }, [booking, bookingState, availAbleDates])

  const checkSameSlot = () => {
    const {
      bookingBy,
      slotTime,
      slotStatus,
      allids,
      weekDataBackend,
      allData,
    } = state

    let count = -1
    let newSelected: any[] = []

    if (bookingBy === 'day') {
      slotTime.forEach((element) => {
        count = count + 1
        if (slotStatus[count] == 2) {
          newSelected.push(allids[count])
        }
      })
    } else {
      weekDataBackend.forEach((element) => {
        newSelected.push(element.id)
      })
    }

    let oldSelected: any = []
    oldSelected = allData.slots.map((val: any) => {
      return val._id
    })

    let equalNormal =
      JSON.stringify(oldSelected) === JSON.stringify(newSelected)

    let tempStatus: string = allData.status
    if (!tempStatus.includes('Reschedule Rejected')) {
      if (!equalNormal) {
        let resSelected: any = []
        resSelected = allData.rescheduleData.slots.map((val: any) => {
          return val._id
        })
        equalNormal =
          JSON.stringify(resSelected) === JSON.stringify(newSelected)
      }
    }

    return equalNormal
  }

  function checkDateIn120(date: string) {
    return dayjs(date).diff(dayjs(), 'days') < 120
  }

  function initializeWeekData(state: DefaultState) {
    let { slotStatusYear, slotTime, flag, allData, duration } = state

    slotStatusYear = []
    for (let i = 0; i < 7; i++) {
      let newslots: any[] = []
      slotTime.forEach((element) => {
        newslots.push({ element: 1, time: '', id: '' })
      })
      slotStatusYear.push(newslots)
    }

    if (flag == true) {
      flag = false
      if (allData != undefined) {
        let endDate1: any = new Date(allData.startTime)
        endDate1.setMonth(endDate1.getMonth() + 2)
        if (
          allData.reservationEndDate &&
          checkDateIn120(allData.reservationEndDate)
        ) {
          endDate1 = allData.reservationEndDate
        }

        let st: any = new Date(allData.startTime)
        st = new Date(
          st.getUTCFullYear(),
          st.getUTCMonth(),
          st.getUTCDate(),
          0,
          0,
          0,
        )

        let curruntDate = dayjs()
          .tz(
            allData.parentTimezoneOffsetZone
              ? allData.parentTimezoneOffsetZone
              : 'America/New_York',
          )
          .format('YYYY-MM-DDTHH:mm:ss')
        if (
          st.getFullYear() <= dayjs(curruntDate).year() &&
          st.getMonth() <= dayjs(curruntDate).month() &&
          st.getDate() <= dayjs(curruntDate).date()
        ) {
          st = dayjs()
            .tz(
              allData.parentTimezoneOffsetZone
                ? allData.parentTimezoneOffsetZone
                : 'America/New_York',
            )
            .format('YYYY-MM-DDTHH:mm:ss')
        }

        let st1 = new Date(allData.startTime)
        st1 = new Date(
          st1.getUTCFullYear(),
          st1.getUTCMonth(),
          st1.getUTCDate(),
          0,
          0,
          0,
        )
        let payload: any = {
          id: allData.tutorId._id,
          parentId: allData.parentId._id,
          checkEndDate: true,
          startDate: dayjs
            .tz(
              dayjs(st1).format('YYYY-MM-DDTHH:mm:ss'),
              allData.parentTimezoneOffsetZone
                ? allData.parentTimezoneOffsetZone
                : 'America/New_York',
            )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          endDate: dayjs
            .tz(
              dayjs(endDate1).format('YYYY-MM-DDTHH:mm:ss'),
              allData.parentTimezoneOffsetZone
                ? allData.parentTimezoneOffsetZone
                : 'America/New_York',
            )
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          duration: parseFloat(duration as unknown as string),
          parentTimezoneOffsetZone: allData.parentTimezoneOffsetZone,
        }

        let tempStart1 = dayjs(allData.startTime)
          .tz(allData.parentTimezoneOffsetZone)
          .format('LLL')
        let tempStr1 = dayjs(tempStart1).format(`YYYY-MM-DDT[05:00]:ss`)

        payload.startDate = tempStr1
        payload.bookingId = allData._id
        payload.status = 'AVAILABLE'

        let tempStart = dayjs(allData.startTime)
          .tz(allData.parentTimezoneOffsetZone)
          .format('LLL')
        let tempEnd = dayjs(allData.endTime)
          .tz(allData.parentTimezoneOffsetZone)
          .format('LLL')

        let tempStr =
          dayjs(tempStart).format(`MMM D, YYYY [|] h:mmA`) +
          dayjs(tempEnd).format('[-]h:mmA ') +
          allData.parentAbbreviation
        setValue('currentDate', tempStr)

        getAvailableDate(payload)
      }
    }

    return { ...state, slotStatusYear, slotTime, flag, allData, duration }
  }

  async function getAvailableDate(payload: AvailablePayload) {
    const queryObj: { [key: string]: any } = {
      tutorId: payload.id,
      parentId: payload.parentId,
      parentTimezoneOffsetZone: payload.parentTimezoneOffsetZone,
      startDate: payload.startDate,
      endDate: payload.endDate,
    }
    if (payload.duration) queryObj.duration = payload.duration
    if (payload.bookingId) queryObj.bookingId = payload.bookingId
    if (payload.status) queryObj.status = payload.status
    const res = await api.get<GetAvailabilityRes>(
      '/tutor/getAvailability',
      queryObj,
    )
    if (res.ok && res.data) {
      setAvailAbleDate(res.data.data)
      setAvailAbleDates(res.data.data)
    }
  }

  function startDateChange(
    value: dayjs.Dayjs | null | undefined,
    _mode?: string | undefined,
  ) {
    if (value === null || value === undefined) {
      value = dayjs(new Date(0, 0 - 1, 0, 0, 0, 0))
      setValue('sessionDate', null)
    } else {
      setValue('sessionDate', value)
    }

    let newState = _.cloneDeep(state)
    const year = value.year(),
      month = value.month() + 1,
      day = value.date()

    let startDate1: any
    startDate1 = new Date(year, month - 1, day, 0, 0, 0)
    let endDate1: any = new Date(year, month, day, 0, 0, 0)

    let curruntDate = dayjs()
      .tz(
        newState.allData.parentTimezoneOffsetZone
          ? newState.allData.parentTimezoneOffsetZone
          : 'America/New_York',
      )
      .format('YYYY-MM-DDTHH:mm:ss')
    if (
      startDate1.getFullYear() <= dayjs(curruntDate).year() &&
      startDate1.getMonth() <= dayjs(curruntDate).month() &&
      startDate1.getDate() <= dayjs(curruntDate).date()
    ) {
      startDate1 = dayjs()
        .tz(
          newState.allData.parentTimezoneOffsetZone
            ? newState.allData.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    if (
      newState.allData.reservationEndDate &&
      checkDateIn120(newState.allData.reservationEndDate)
    ) {
      endDate1 = newState.allData.reservationEndDate
    }

    newState.bookingDate = new Date(year, month - 1, day, 0, 0, 0)
    for (let i = 0; i <= 6; i++) {
      newState.dateCreate[i] =
        ((newState.bookingDate.getDate() + '').length == 1
          ? '0' + newState.bookingDate.getDate()
          : newState.bookingDate.getDate()) +
        '\n' +
        newState.weekArray[newState.bookingDate.getDay()]
      newState.bookingDate.setDate(newState.bookingDate.getDate() + 1)
    }

    newState.bookingDate = new Date(year, month - 1, day, 0, 0, 0)

    const sessionDuration = watch('sessionDuration')

    let payload: any = {
      id: newState.allData.tutorId._id,
      parentId: newState.allData.parentId._id,
      checkEndDate: true,
      startDate: dayjs
        .tz(
          dayjs(startDate1).format('YYYY-MM-DDTHH:mm:ss'),
          newState.allData.parentTimezoneOffsetZone
            ? newState.allData.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      endDate: dayjs
        .tz(
          dayjs(endDate1).format('YYYY-MM-DDTHH:mm:ss'),
          newState.allData.parentTimezoneOffsetZone
            ? newState.allData.parentTimezoneOffsetZone
            : 'America/New_York',
        )
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss'),
      duration: parseFloat(sessionDuration),
      parentTimezoneOffsetZone: newState.allData.parentTimezoneOffsetZone,
    }

    payload.bookingId = newState.allData._id
    payload.status = 'AVAILABLE'

    getAvailableDate(payload)

    newState = initializeWeekData(newState)
    let count = 0
    newState.slotTime.forEach((_element) => {
      newState.slotStatus[count] = 1
      count++
    })

    setState(newState)
  }

  function slotsChange(data: string) {
    let { bookingBy } = state
    bookingBy = data

    const elem = document.getElementById('scrollBaba')
    if (elem) {
      elem.scrollTop = 650
    }

    setState((prev) => ({ ...prev, bookingBy }))
  }

  function slotChange(data: number, i: number) {
    let { submitted, slotStatus } = state
    submitted = false
    slotStatus = _.cloneDeep(slotStatus)

    const sessionDuration = watch('sessionDuration')

    if (i == 0 || i == 2) {
      let count = 0
      let temp = data
      let time = parseFloat(sessionDuration)
      while (count <= slotStatus.length) {
        if (slotStatus[count] == 2) {
          slotStatus[count] = 0
        }
        count++
      }
      count = 0
      while (time > count && slotStatus[temp] == 0) {
        count += 0.5
        temp++
      }

      if (count == time) {
        while (count > 0) {
          slotStatus[data] = 2
          data++
          submitted = true
          count = count - 0.5
        }
      }
    }

    setState((prev) => ({ ...prev, submitted, slotStatus }))
  }

  function slotChangeWeek(j: number, i: number) {
    let { submitted, weekDataBackend, slotStatusYear } = state
    submitted = false
    weekDataBackend = []
    let count = 0
    let temp = i
    const sessionDuration = watch('sessionDuration')
    let time = parseFloat(sessionDuration)
    for (let ii = 0; ii < 7; ii++) {
      while (count <= slotStatusYear[ii].length - 1) {
        if (slotStatusYear[ii][count].element == 2) {
          slotStatusYear[ii][count].element = 0
        }
        count++
      }
      count = 0
    }
    count = 0
    while (
      time >= count &&
      slotStatusYear[j][temp] &&
      slotStatusYear[j][temp].element == 0
    ) {
      count += 0.5
      temp++
      if (
        slotStatusYear[j][temp] &&
        slotStatusYear[j][temp].element == undefined
      ) {
        count += 0.5
      }
    }

    if (count != 0 && time >= count) count = count + 0.5
    count = count - 0.5
    if (count == time) {
      while (count > 0) {
        slotStatusYear[j][i].element = 2
        weekDataBackend.push(slotStatusYear[j][i])
        i++
        submitted = true
        count = count - 0.5
      }
    }

    setState((prev) => ({
      ...prev,
      submitted,
      weekDataBackend,
      slotStatusYear,
    }))
  }

  const onSubmit = handleSubmit(async (values) => {
    let count = -1,
      slots: any[] = [],
      ids: any[] = [],
      startEndTime = '',
      time: any = [],
      formData = new FormData(),
      d = new Date()

    let { onBefalf, sessionDate } = values
    const {
      bookingBy,
      slotTime,
      slotStatus,
      allids,
      weekDataBackend,
      allData,
    } = state

    formData.append('onBehalf', onBefalf)

    sessionDate = sessionDate as dayjs.Dayjs
    const year = sessionDate.year(),
      month = sessionDate.month() + 1,
      day = sessionDate.date()

    if (bookingBy == 'day') {
      slotTime.forEach((element) => {
        count = count + 1
        if (slotStatus[count] == 2) {
          ids.push(allids[count])
          slots.push(element)
        }
      })

      startEndTime = slots[0]
      time = startEndTime.split('.')
      let dd = new Date(
        year,
        month - 1,
        day,
        parseInt(time[0], 10),
        parseInt(time[1], 10),
        0,
      )
      let newDD = dayjs(dd).format('YYYY-MM-DDTHH:mm:ss')
      formData.append(
        'startTime',
        dayjs
          .tz(newDD, allData.parentTimezoneOffsetZone)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
      )
      startEndTime = slots[slots.length - 1]
      time = startEndTime.split('.')

      if (time[1] == '30') {
        time[0] = parseInt(time[0], 10) + 1 + ''
        time[1] = '00'
      } else if (time[1] == '00') time[1] = '30'
      d = new Date(
        year,
        month - 1,
        day,
        parseInt(time[0], 10),
        parseInt(time[1], 10),
        0,
      )
      let newD = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')
      formData.append(
        'endTime',
        dayjs
          .tz(newD, allData.parentTimezoneOffsetZone)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
      )
    } else if (bookingBy === 'year') {
      weekDataBackend.forEach((element) => {
        ids.push(element.id)
      })

      let newDate = new Date(weekDataBackend[0].date)

      if (new Date().getTimezoneOffset() * 60000 * -1 < 0)
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate() + 1,
          weekDataBackend[0].time.hour,
          weekDataBackend[0].time.min,
          0,
        )
      else
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          weekDataBackend[0].time.hour,
          weekDataBackend[0].time.min,
          0,
        )
      let newDD = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')

      formData.append(
        'startTime',
        dayjs
          .tz(newDD, allData.parentTimezoneOffsetZone)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
      )

      if (weekDataBackend.length == 1) {
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          weekDataBackend[weekDataBackend.length - 1].time.hour,
          weekDataBackend[weekDataBackend.length - 1].time.min + 30,
          0,
        )
      } else {
        newDate = new Date(weekDataBackend[weekDataBackend.length - 1].date)
        d = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          weekDataBackend[weekDataBackend.length - 1].time.hour,
          weekDataBackend[weekDataBackend.length - 1].time.min + 30,
          0,
        )
      }
      if (new Date().getTimezoneOffset() * 60000 * -1 < 0)
        d.setDate(d.getDate() + 1)
      let newD = dayjs(d).format('YYYY-MM-DDTHH:mm:ss')
      formData.append(
        'endTime',
        dayjs
          .tz(newD, allData.parentTimezoneOffsetZone)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
      )
    }

    formData.append('slots', JSON.stringify(ids))

    onClose()
    // @ts-ignore
    setBooking?.({ ...booking, fd: formData })
    setModals?.((prev) => ({ ...prev, details: true }))
    setBookingState?.({
      pageType: 'ReschudeBookingflow',
      source: 'sessionDetail',
      actionType: 'activeBooking',
    })
  })

  return (
    <ModalWrapper
      open={open}
      handleClose={onClose}
      title="Reschedule Session"
      wide
    >
      <form onSubmit={onSubmit}>
        <div className="form-group row">
          <div className="col-sm-5">
            <label htmlFor="onBefalf" className="add-parent__label">
              On Behalf
            </label>
          </div>
          <div className="col-sm-7">
            <div className="row">
              <Controller
                name="onBefalf"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <StyledDropdown
                    placeholder="--Select Option--"
                    {...field}
                    option={[
                      { id: '1', option: 'Parent', value: 'PARENT' },
                      { id: '2', option: 'Tutor', value: 'TUTOR' },
                    ]}
                    disabled={!state.allData.rescheduleVisibleParent}
                    isError={!!errors.onBefalf}
                    errorMessage={errors.onBefalf?.message?.toString()}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-5">
            <label htmlFor="sessionDuration" className="add-parent__label">
              Session Duration
            </label>
          </div>
          <div className="col-sm-7">
            <div className="row">
              <Controller
                name="sessionDuration"
                control={control}
                render={({ field }) => (
                  <StyledTextfield {...field} className="disableTable" />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-5">
            <label htmlFor="currentDate" className="add-parent__label">
              Current Session Date/Time
            </label>
          </div>
          <div className="col-sm-7">
            <div className="row">
              <Controller
                name="currentDate"
                control={control}
                render={({ field }) => (
                  <StyledTextfield {...field} className="disableTable" />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-sm-5">
            <label htmlFor="sessionDate" className="add-parent__label">
              Session Date
            </label>
          </div>
          <div className="col-sm-7 pad0">
            <Controller
              name="sessionDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  className="session-date"
                  format={'MM/DD/YYYY'}
                  placeholder="MM/DD/YYYY"
                  value={field.value}
                  onChange={startDateChange}
                  disabledDate={(current) => {
                    if (calendarOptions.minDate && calendarOptions.maxDate) {
                      return (
                        current.isBefore(calendarOptions.minDate, 'date') ||
                        current.isAfter(calendarOptions.maxDate, 'date')
                      )
                    }
                    return true
                  }}
                  // @ts-ignore
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
              )}
            />
          </div>

          {!!(
            state.allData?.reservationEndDate &&
            checkDateIn120(state.allData?.reservationEndDate)
          ) && (
            <div className="col-sm-12 text-right">
              <p style={{ color: 'red' }} className="text-center mt-2">
                The reservation being used has an expiration date of{' '}
                {state.allData?.showEndDate}
              </p>
            </div>
          )}
        </div>

        <div className="form-group row">
          <div className="col-4"></div>
          <div className="col-8 text-center mb-2">
            Time Zone Displayed [{state.allData.parentAbbreviation}]
          </div>
        </div>

        <div className="form-group row align-items-baseline">
          <div className="col-sm-5 text-left">
            <label className="add-parent__label">Session Time</label>
          </div>
          <div className="col-sm-7">
            <div className="d-flex">
              <Button
                className="time-slot-buttons m-1 ms-0"
                color={'primary'}
                variant={state.bookingBy === 'day' ? 'contained' : 'text'}
                onClick={() => slotsChange('day')}
              >
                Day
              </Button>
              <Button
                className="time-slot-buttons m-1"
                color={'primary'}
                variant={state.bookingBy === 'year' ? 'contained' : 'text'}
                onClick={() => slotsChange('year')}
              >
                Week
              </Button>
            </div>
            <div className="slots-container" id="scrollBaba">
              <div className="d-flex black-border-bottom">
                <span className="time-width">Time</span>
                <span className="d-flex justify-content-center w-100">
                  Slot
                </span>
              </div>

              {state.bookingBy === 'day' ? (
                timeArray.map((data, index) => {
                  return (
                    <div
                      className="d-flex align-items-center black-border-bottom"
                      key={index}
                    >
                      <span className="time-width">{data}</span>
                      <span
                        onClick={() =>
                          slotChange(index, state.slotStatus[index])
                        }
                        className={`slots ms-2 ${
                          state.slotStatus[index] === 2
                            ? 'booked-color'
                            : state.slotStatus[index] === 1
                            ? 'free-color'
                            : state.slotStatus[index] === 0
                            ? 'busy-color'
                            : 'free-color'
                        }`}
                      ></span>
                    </div>
                  )
                })
              ) : (
                <div>
                  <div className="d-flex align-items-center black-border-bottom">
                    <span className="time-width"></span>
                    <span className="row w-100">
                      <div className="col-sm-12 d-flex justify-content-center">
                        {state.dateCreate.map((date, i) => {
                          return (
                            <span key={i} className="slot date-slot col-sm-1">
                              {date}
                            </span>
                          )
                        })}
                      </div>
                    </span>
                  </div>
                  {timeArray.map((time, i) => {
                    return (
                      <div
                        className="d-flex align-items-center black-border-bottom"
                        key={i}
                      >
                        <span className="time-width">{time}</span>
                        <span className="row w-100">
                          <div className="col-sm-12 d-flex justify-content-center">
                            {state.weekCount.map((j) => {
                              return (
                                <span
                                  key={j}
                                  onClick={() => slotChangeWeek(j, i)}
                                  className={`slot col-sm-1 ${
                                    state.slotStatusYear[j][i].element === 2
                                      ? 'booked-color'
                                      : state.slotStatusYear[j][i].element === 1
                                      ? 'free-color'
                                      : state.slotStatusYear[j][i].element === 0
                                      ? 'busy-color'
                                      : 'free-color'
                                  }`}
                                ></span>
                              )
                            })}
                          </div>
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>

            <div className="row align">
              <div className="col-sm-12">
                <div className="d-inline-block">
                  <div className="heightWidth free-color"></div>
                  Busy
                </div>
                <div className="d-inline-block">
                  <div className="heightWidth busy-color"></div>
                  Available
                </div>
                <div className="d-inline-block">
                  <div className="heightWidth booked-color"></div>
                  Selected
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <div className=" col-sm-3 offset-sm-9 text-center mt-4">
            <Button
              type="submit"
              disabled={!isValid || !state.submitted || checkSameSlot()}
              color={
                !isValid || !state.submitted || checkSameSlot()
                  ? 'info'
                  : 'success'
              }
              className="w-100"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </ModalWrapper>
  )
}

export default RescheduleBooking
