import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

import routes from '../../../../common/routes'
import BackArrow from '../../../../components/back-arrow/BackArrow'
import {
  CancellationReason,
  ConstantResponse,
} from '../../../../types/constants.type'
import { api } from '../../../../utils'
import './all-cancellation-reasons.scss'
import AddEditCancellationReason from './components/add-edit-cancellation-reason/AddEditCancellationReason'
import CancellationTable from './components/cancellation-table/CancellationTable'

const AllCancellationReasons = () => {
  const [buttonType, setButtonType] = useState<'PARENT' | 'TUTOR'>('PARENT')
  const [cancellationReason, setCancellationReason] = useState(false)
  const [cancellationReasons, setCancellationReasons] = useState<
    CancellationReason[]
  >([])
  const [parentCancellations, setParentCancellations] = useState<
    CancellationReason[]
  >([])

  const [tutorCancellations, setTutorCancellations] = useState<
    CancellationReason[]
  >([])

  const getConstants = async () => {
    const constantsRes = await api.get<ConstantResponse>('admin/getConstants')

    if (constantsRes && constantsRes.data) {
      const { parentCancellationReasons, tutorCancellationReasons } =
        constantsRes.data.data
      setParentCancellations(parentCancellationReasons)
      setCancellationReasons(
        buttonType === 'PARENT'
          ? parentCancellationReasons
          : tutorCancellationReasons,
      )
      setTutorCancellations(tutorCancellationReasons)
    }
  }

  useEffect(() => {
    getConstants()
  }, [])

  useEffect(() => {
    if (buttonType === 'PARENT') {
      setCancellationReasons(parentCancellations)
    } else {
      setCancellationReasons(tutorCancellations)
    }
  }, [buttonType])

  return (
    <div className="container-fluid">
      <div className="row mt-2 mb-2 align-items-end">
        <div className="col-md-12">
          <BackArrow route={routes.allCategories} />
        </div>
        <div className="col-md-7 offset-md-1">
          <Button
            className={`upper-button ${
              buttonType === 'PARENT' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('PARENT')
            }}
          >
            Parent
          </Button>
          <Button
            className={`upper-button ${
              buttonType === 'TUTOR' ? 'upper-button-active' : ''
            }`}
            onClick={() => {
              setButtonType('TUTOR')
            }}
          >
            Tutor
          </Button>
        </div>
        <div className="col-sm-4 col-md-4  text-end">
          <Button
            onClick={() => {
              setCancellationReason(true)
            }}
          >
            Add Cancelation Reason
          </Button>
        </div>
      </div>
      <div className="row mt-4">
        {!!cancellationReasons.length && (
          <CancellationTable
            cancellationReasons={cancellationReasons}
            reasonType={buttonType}
            getConstants={getConstants}
          />
        )}
      </div>
      {cancellationReason && (
        <AddEditCancellationReason
          reasonType={buttonType}
          open={cancellationReason}
          onClose={() => {
            setCancellationReason(false)
          }}
          onSuccess={getConstants}
          mode="Add"
        />
      )}
    </div>
  )
}

export default AllCancellationReasons
